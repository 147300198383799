import React from 'react'
import { render } from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { create as createSpy } from 'rxjs-spy'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import store from './state/store'
import { GA_ID, HEAP_ID, NODE_ENV, RBN_VERSION, SENTRY_ENV } from 'constants/general'
import { App } from './views/containers'
import * as serviceWorker from './serviceWorker'
import './theme/styles/index.scss'
import { $TSFixMe } from 'types/ts-migrate'

if (NODE_ENV === 'development' || NODE_ENV === 'test') {
  const { worker } = module.require('./mocks/browser')
  worker.start()
}

if (NODE_ENV === 'production') {
  if (GA_ID !== undefined) {
    ReactGA.initialize(GA_ID)
  }
  if (HEAP_ID !== undefined && typeof (window as any).heap !== 'undefined') {
    ;(window as any).heap.load(HEAP_ID)
  }
}

if (NODE_ENV === 'development') {
  createSpy()
  // const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
  // whyDidYouRender(React)
  // whyDidYouRender(React, { include: [/^ConnectFunction$/] })
} else {
  Sentry.init({
    dsn: 'https://e122c83f82e642608deaf9ea49123dfd@o1233260.ingest.sentry.io/6381960',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'api.reos.co.za'],
      }),
    ],
    release: RBN_VERSION,
    normalizeDepth: 20,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
    beforeSend(event: Sentry.Event) {
      // Check if it is an exception, if so, show the report dialog
      if (event.exception?.values !== undefined) {
        Sentry.showReportDialog()
      }
      return event
    },
  })
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if ((window as any).Cypress) {
  ;(window as any).store = store
}

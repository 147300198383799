import React, { FC } from 'react'
import { prop, sortBy } from 'ramda'
import { components } from 'react-select'
import { $TSFixMe } from 'types/ts-migrate'
import { Select } from 'views/components'
import styles from './AgencySelect.module.scss'

export type TAgencyOption = {
  label: string
  agencyName: string
  tradingAs: string
  value: string
}

const AgencyOption: FC<{ data: TAgencyOption; isDisabled: boolean; innerProps: $TSFixMe }> = props => {
  const tradingAs = props.data?.tradingAs
  return !props.isDisabled ? (
    <components.Option {...props} {...props.innerProps}>
      <div className={styles['custom-option']}>
        <strong>{props.data.agencyName}</strong> <br />
        {tradingAs && <small>{tradingAs}</small>}
      </div>
    </components.Option>
  ) : null
}

type AgencySelectProps = {
  agencyOptions: TAgencyOption[]
  onAgencySelection: (agency: any) => void
  value?: TAgencyOption
}

const AgencySelect: FC<AgencySelectProps> = ({ agencyOptions, onAgencySelection, value }) => {
  return (
    <div>
      <Select
        name="agencies"
        label="Select agency"
        options={sortBy(prop('label'), agencyOptions)}
        isSearchable={true}
        onChange={onAgencySelection}
        components={{ Option: AgencyOption }}
        value={value}
      />
    </div>
  )
}

export default AgencySelect

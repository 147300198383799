import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { FormField, FormLoader, TextField, TextInput } from '../../../../components'
import { userApiEvents } from '../../../../../modules/api/user'
import { useDispatch, useSelector } from 'react-redux'
import styles from './SignupForm.module.scss'
import { useEffectOnce } from 'react-use'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { isEmpty, mergeRight } from 'ramda'
import { formUtils } from '../../../../../utils'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
  encryptedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  email: yup.string().required('Email address is required.').email('Email address is invalid'),
  password: yup.string().required('Password is required.').min(9, 'Password must be at least 9 characters.'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const SignupForm = ({ firstName, email, encryptedValue }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const firstNameRef = useRef<$TSFixMe>()

  const initialValues = {
    firstName: '',
    email: '',
    password: '',
  }

  const handleSubmit = (values: any): void => {
    if (encryptedValue) {
      dispatch(userApiEvents.signupFromInvite_request(values))
    } else {
      dispatch(userApiEvents.signup_request(values))
    }
  }

  useEffectOnce(() => {
    firstNameRef.current?.focus()
  })

  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([userApiEvents.signup_request, userApiEvents.signupFromInvite_request]),
  )
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <div className={styles.root}>
      <h2>Sign up</h2>
      <p>Request access to your free account and get started with reOS in under 5 minutes.</p>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, errors, touched, handleChange, handleBlur, submitCount }) => {
          const registerEvent = encryptedValue
            ? userApiEvents.signupFromInvite_request(values)
            : userApiEvents.signup_request(values)
          const generalErrors = getGeneralFormErrorsByEvent(registerEvent)
          const fieldErrors = getFormFieldErrorsByEvent(registerEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

          return (
            <FormLoader
              onSubmit={handleSubmit}
              ButtonContainer={ButtonContainer}
              buttonProps={{ children: 'Request Access' }}
              state={formLoaderState}
              errorText={hasApiErrors ? 'Oops, something went wrong' : 'Resolve errors'}
              persistErrorMessage={!hasApiErrors}
            >
              <FormErrors errors={generalErrors} />
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      id="firstName"
                      name="firstName"
                      placeholder="First name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      ref={(ref: any) => {
                        firstNameRef.current = ref
                      }}
                    />
                  }
                  error={getFieldError('firstName')}
                />
              </FormField>
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.email}
                      id="email"
                      name="email"
                      placeholder="Email address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  error={getFieldError('email')}
                />
              </FormField>
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.password}
                      id="password"
                      name="password"
                      placeholder="Choose a password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  error={getFieldError('password')}
                />
              </FormField>
              <p className="fine-print">
                By clicking &quot;Create my account&quot; you agree to <br />
                reOS{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.reos.co.za/legal">
                  Terms of Service
                </a>{' '}
                &amp;{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://www.reos.co.za/legal">
                  Privacy Policy
                </a>
                .
              </p>
            </FormLoader>
          )
        }}
      </Formik>
    </div>
  )
}

SignupForm.propTypes = propTypes

export default SignupForm

import { ofType } from 'redux-observable'
import { map, pluck, debounceTime, mergeMap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { partyApiConstants, partyApiEvents } from '../../api/party'
import { partySearchEvents } from '.'
import { DEBOUNCE_TIME } from '../constants'
import { portfolioApiEvents } from 'modules/api/portfolio'
import { $TSFixMe } from 'types/ts-migrate'

export const searchParties = (action$: any, state$: any) =>
  action$.pipe(
    ofType(partySearchEvents.partySearchQuery),
    debounceTime(DEBOUNCE_TIME),
    pluck('payload'),
    map(params => partyApiEvents.quickSearch_request(params)),
    tag('PartySearch/epics/searchParties'),
  )

export const partySearchWithPortfolioStatus = (action$: any, state$: any, { get, catchRestError }) =>
  action$.pipe(
    ofType(partySearchEvents.partySearchWithPortfolioStatus),
    debounceTime(DEBOUNCE_TIME),
    mergeMap((action: $TSFixMe) => {
      const partySearchResponse = get(partyApiConstants.ENDPOINTS.QUICK_SEARCH, state$, action.payload).pipe(
        pluck('response'),
        mergeMap((results: $TSFixMe) => {
          const portfolioIds = results
            .map((party: $TSFixMe) => {
              return (party?.person || party?.company).accounts
                .map((account: $TSFixMe) => account?.portfolioId)
                .filter((id: string | undefined) => id !== undefined)
            })
            .flat()

          return [
            partyApiEvents.quickSearch_success({ results, params: action.payload }),
            portfolioApiEvents.getStatus_request(portfolioIds),
          ]
        }),
        catchRestError(action),
      )

      return partySearchResponse
    }),
    tag('PartySearch/epics/partySearchWithPortfolioStatus'),
  )

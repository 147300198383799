import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../../views/components/atoms/Button/Button'
import DimmerLoader from '../../views/components/molecules/DimmerLoader/DimmerLoader'
import Header from '../../views/components/molecules/Header/Header'
import ControlledTooltip from '../../views/components/atoms/Tooltip/ControlledTooltip'
import styles from './FileTable.module.scss'
import Table from '../../views/components/organisms/Table/Table'

import UppyWrapper from './UppyWrapper'
import * as Requests from './Requests'

import { dateUtils } from '../../utils'
import { useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'

const propTypes = {
  forOwner: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }),
  onError: PropTypes.func,
  className: PropTypes.string,
}

const ActionButton = ({ tooltip, icon, action, disabled = false }: any): React.ReactElement => (
  // @ts-expect-error
  <ControlledTooltip body={tooltip} place="above">
    <Button disabled={disabled} ghost className={styles[icon]} onClick={action} />
  </ControlledTooltip>
)

const FileTable = (props: any): React.ReactElement => {
  const {
    authToken,
    data = null,
    forOwner,
    onError,
    showDateTime,
    showDateTimeBeneath = false,
    allowRenaming,
    allowRemove,
    displayAndEditExtension,
    documentServiceEndpoint,
    className,
  } = props

  const [removing, setRemoving] = useState([])

  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const readFileList = useCallback(
    async forOwner => {
      // do the read
      const response = await Requests.listFiles({ documentServiceEndpoint, authToken }, forOwner)
      if (response.status !== 200) {
        if (onError) {
          onError({ status: response.status, message: 'Could not rename file' })
          return
        }
      }

      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const files = response.json.documents.map((doc: any) => {
        return {
          attached_at: doc.attachedAt,
          id: doc.documentId,
          type: doc.filetype,
          name: doc.filename,
        }
      })

      return files
    },
    [documentServiceEndpoint, authToken, onError],
  )

  // const abortController = new AbortController()
  // const abortControllerSignal = abortController.signal

  const existingData = React.useRef({
    data,
    fetchParams: [],
    pending_changes: {},
  })
  const [currentResults, setCurrentResults] = React.useState(data)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const __setCurrentResults = (r: any): void => {
    existingData.current.data = r
    setCurrentResults(r)
  }

  // this will trigger on load of component
  // we can use this to load the initial dataset from the server
  // (if it was not already passed to the component from outside)

  const refreshFunction = useCallback(async () => {
    try {
      if (!data && forOwner && forOwner.classId && forOwner.className) {
        const result = await readFileList(forOwner)
        __setCurrentResults(result)
      }
    } catch (exception) {
      // if(abortControllerSignal.aborted === false) {
      console.error(exception)
      // }
    }
  }, [data, forOwner, readFileList])

  useEffect(() => {
    void refreshFunction()
  }, [refreshFunction])

  const setExtension = (file: any): void => {
    if (!file.extension) {
      const idx = `${file.name as string}`.lastIndexOf('.')
      if (displayAndEditExtension === false && idx > -1) {
        const filename = file.name.substring(0, idx)
        const extension = file.name.substring(idx)
        file.extension = extension
        file.name = filename
      } else {
        file.extension = ''
      }
    }
  }

  const onRename = (file: any): void => {
    const result = existingData.current.data.map((f: any) => {
      return {
        ...f,
        isEditing: f.id === file.id,
        name: f.id === file.id ? file.name : f.name,
        extension: f.id === file.id ? file.extension : f.extension,
      }
    })
    __setCurrentResults(result)
  }

  const onNameChange = (file: any, evt: any): void => {
    const value = evt.target.value
    existingData.current.pending_changes[file.id] = value
  }

  const onRenameSave = async (file: any): Promise<any> => {
    const name = existingData.current.pending_changes[file.id]
    if (name) {
      const extension = file.extension
      const response = await Requests.rename(props, { ...file, name: `${name as string}${extension as string}` })

      if (response.status !== 200) {
        if (onError) {
          onError({ status: response.status, message: 'Could not rename file' })
          return
        }
      }
    }

    const result = existingData.current.data.map((f: any) => {
      return {
        ...f,
        name: f.id === file.id ? name ?? f.name : f.name,
        isEditing: false,
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete existingData.current.pending_changes[file.id]
    __setCurrentResults(result)
  }

  const onRemove = async (file: any): Promise<void> => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setRemoving([...removing, file.id])
    await Requests.remove(props, file)
    setRemoving(removing.filter(r => r !== file.id))
    void refreshFunction()
  }

  const onDownload = async (file: any): Promise<any> => {
    // get the pre-signed url
    const response = await Requests.download(props, file)
    const signedResponse = response.json
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const type = signedResponse.filetype
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const filename = signedResponse.filename
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const signedUrl = signedResponse.url

    if (response.status !== 200) {
      if (onError) {
        onError({ status: response.status })
        return
      }
    }

    // download the actual file
    const downloadResponse = await window.fetch(signedUrl)
    if (downloadResponse.status !== 200) {
      if (onError) {
        onError({ status: downloadResponse.status })
        return
      }
    }

    try {
      const downloadBlob = await downloadResponse.blob()
      const url = window.URL.createObjectURL(new Blob([downloadBlob], { type }))
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = filename
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    } catch (exception) {
      if (onError) {
        onError({ exception })
      }
    }
  }

  // add the successful files
  const onComplete = (files: any): void => {
    // const successful = files.successful.map((file) => {
    //   return {
    //     id: file.response.body.fileId,
    //     name: file.name,
    //     type: file.type,
    //     updated_at: new Date().getTime()
    //   };
    // });

    // const combinedResults = existingData.current.data
    //   ? [ ...existingData.current.data, ...successful ]
    //   : [ ...successful ]

    void refreshFunction()
  }

  const formatFileType = (type: any): string => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/jpg':
      case 'image/gif':
      case 'image/jiff':
      case 'image/bmp':
        return 'Image File'
      case 'application/pdf':
        return 'PDF Document'
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingm':
        return 'Word Document'
      default:
        return type
    }
  }

  const formatDataFile = (props: any, file: any): any => {
    setExtension(file)

    const { id, name, type, isEditing, attached_at: attachedAt, updated_at: updatedAt } = file

    const time = updatedAt || attachedAt
    const timeString = new Date(time).toDateString().replace(/^\(*\)/g, '')

    return {
      id,
      fileDetails: (
        <span className={styles['file-details']}>
          {isEditing === true ? (
            <input
              className={styles['file-entry-input']}
              defaultValue={name}
              onChange={onNameChange.bind(null, file)}
              readOnly={false}
            />
          ) : (
            <strong className={styles.filename}>{name}</strong>
          )}
          <span className={styles.subtext}>{formatFileType(type)}</span>
        </span>
      ),
      timeDetails: (
        <span className={'time-details'}>
          <strong
            className={!showDateTimeBeneath ? styles['timeago-custom-style'] : styles['timeago-custom-style-below']}
          >
            {dateUtils.timeAgo(time)}
          </strong>
          <span className={!showDateTimeBeneath ? styles.timestring : styles['timestring-below']}>{timeString}</span>
        </span>
      ),
      actions: (
        <span className={styles.actions}>
          {allowRenaming ? (
            isEditing !== true ? (
              <ActionButton tooltip="Rename" icon="edit" action={() => onRename(file)} />
            ) : (
              <ActionButton tooltip="Rename Save" icon="save" action={async () => await onRenameSave(file)} />
            )
          ) : null}
          <ActionButton tooltip="Download" icon="download" action={async () => await onDownload(file)} />
          {allowRemove ? (
            <ActionButton tooltip="Remove" icon="remove" action={async () => await onRemove(file)} />
          ) : null}
        </span>
      ),
    }
  }

  const classes = classNames(styles.root, {
    [className]: className,
  })

  return (
    <section className={classes}>
      <Header className={styles.header} tag="h4" text="Documents" />
      <Table type="default">
        <Table.Body>
          {currentResults
            ?.map((file: any) => formatDataFile(props, file))
            .map((file: any, i: number) => (
              <DimmerLoader key={`file-container-${i}`} loading={removing.includes(file.id as never)} bgColor={'appBg'}>
                <Table.Row key={i}>
                  <Table.Cell style={{ paddingLeft: '15px', paddingRight: '0px' }}>
                    <div style={{ width: '100%' }}>
                      {file.fileDetails}
                      {showDateTimeBeneath && showDateTime === true ? (
                        <div>
                          <hr />
                          {file.timeDetails}
                        </div>
                      ) : null}
                    </div>
                  </Table.Cell>
                  {!showDateTimeBeneath && showDateTime === true ? (
                    <Table.Cell style={{ paddingLeft: '15px', paddingRight: '0px' }}>{file.timeDetails}</Table.Cell>
                  ) : null}
                  <Table.Cell style={{ paddingLeft: '0px', paddingRight: '0px', justifyContent: 'flex-end' }}>
                    {file.actions}
                  </Table.Cell>
                </Table.Row>
              </DimmerLoader>
            ))}
          {!isReadOnly && (
            <Table.Row key={'add-new'}>
              <Table.Cell style={{ paddingLeft: '15px', paddingRight: '0px' }}>
                <UppyWrapper
                  forOwner={forOwner}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ forOwner: any; onComplete: (files: any) =>... Remove this comment to see the full error message
                  onComplete={onComplete}
                  parentProps={props}
                />
              </Table.Cell>
              <Table.Cell style={{ paddingRight: '30px', justifyContent: 'flex-end' }}>&nbsp;</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </section>
  )
}

FileTable.propTypes = propTypes

export default FileTable

import { createSelector } from 'reselect'
import { getLeaseSettingsDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'
import { chatbotEvents } from '../state'
import { get } from 'lodash-es'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import * as chatbotSelectors from '../selectors'
import * as agencyApiSelectors from '../../../api/agency/selectors'
import { userApiSelectors } from '../../../api/user'

const initialState = {
  notes: '',
  customLeaseIdentifier: '',
  autoSendPaymentReminderSms: null,
  autoSendOwnerMonthlyEmail: null,
  segments: [],
}

const getState = (state: any) => state

export const { leaseSettingsDialog } = createChatbotDialog('leaseSettings')
  .withInitialState(initialState)
  .withEvents({
    settingsSaved: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: get(payload, 'settings', {}),
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendSettings_success]: ({ payload }: any) => ({
      $merge: payload,
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when('settingsSaved', ({ payload }: any) => ({
    $merge: {
      ...payload,
    },
  }))
  .setup()

export const getLeaseSettingsInitialValues = createSelector(
  [getState, getLeaseSettingsDialog],
  (state, dialogValues) => {
    const defaultSegmentId = userApiSelectors.getCurrentAgencyId(state)
    const portfolioId = chatbotSelectors.getCurrentPortfolioId(state)
    const portfolioSegmentIds = portfolioApiSelectors
      .getSegmentIdsByPortfolioId(state)(portfolioId)
      .filter((id: any) => id !== defaultSegmentId)
    return {
      ...dialogValues,
      autoSendPaymentReminderSms:
        dialogValues.autoSendPaymentReminderSms !== null ? dialogValues.autoSendPaymentReminderSms : true,
      autoSendOwnerMonthlyEmail:
        dialogValues.autoSendOwnerMonthlyEmail !== null ? dialogValues.autoSendOwnerMonthlyEmail : true,
      segments: portfolioSegmentIds.map((id: any) => agencyApiSelectors.getSegmentById(state)(id)),
    }
  },
)

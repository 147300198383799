import React from 'react'

const SvgRefresh = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M9.463 8.433A9.961 9.961 0 0116 6c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L21 16h3a8 8 0 00-13.54-5.772l-.997-1.795zm13.074 15.134A9.961 9.961 0 0116 26c-5.523 0-10-4.477-10-10 0-2.136.67-4.116 1.81-5.74L11 16H8a8 8 0 0013.54 5.772l.997 1.795z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgRefresh

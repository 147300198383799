import React from 'react'
import { AdhocInvoiceProvider } from '..'
import AdhocInvoiceFocusMode from './AdhocInvoiceFocusMode/AdhocInvoiceFocusMode'

const AdhocInvoiceRoute = (props: any): React.ReactElement => (
  <AdhocInvoiceProvider {...props}>
    <AdhocInvoiceFocusMode />
  </AdhocInvoiceProvider>
)

export default AdhocInvoiceRoute

export const NAMESPACE = 'adhocInvoice'

export const emptyInitialValues = {
  isDepositInvoice: false,
  customer: {
    id: null,
    text: '',
    partyTag: null,
    secondaryText: '',
    portfolioId: null,
  },
  invoiceType: {
    name: null,
    value: null,
  },
  dueDate: '',
  vat: false,
  beneficiaries: [],
}

import React from 'react'
import { pipe, prop, reverse, sortBy } from 'ramda'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from 'constants/routes'
import { walletApiSelectors } from '../api/wallet'
import { layoutEvents } from '../Layout/state'
import { uiEvents } from '../ui'

const BadReferencesProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props
  const { params } = match
  const id = params?.id

  /** @todo get allocated and unallocated bad references using selectors */
  const unallocatedReferences = useSelector(walletApiSelectors.getUnallocatedBadReferences)
  const allocatedReferences = useSelector(walletApiSelectors.getAllocatedBadReferences)

  const currentReference = useSelector(state => walletApiSelectors.getReferenceByTxId(state)(id))

  const filters = [
    {
      key: 'unallocated',
      label: 'Unallocated',
      // @ts-expect-error
      data: pipe(sortBy(prop('dateReceived')), reverse)(unallocatedReferences),
      count: unallocatedReferences.length,
    },
    {
      key: 'allocated',
      label: 'Allocated',
      // @ts-expect-error
      data: pipe(sortBy(prop('allocatedAt')), reverse)(allocatedReferences),
      count: allocatedReferences.length,
    },
  ]

  const badReferencesStore = {
    match,
    filters,
    currentReference,
    isLoading: false,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    openReference: (id: any) => {
      dispatch(uiEvents.redirect(ROUTES.badReferencesView.replace(':id', id)))
      dispatch(layoutEvents.openPrimaryPanel('badReferences'))
      dispatch(layoutEvents.closeSidebar('global'))
    },
    closeReference: () => {
      dispatch(uiEvents.redirect(ROUTES.badReferences))
      dispatch(layoutEvents.closePrimaryPanel('badReferences'))
      dispatch(layoutEvents.openSidebar('global'))
    },
    ...props,
  }
  return <context.Provider value={badReferencesStore}>{children}</context.Provider>
})

export default BadReferencesProvider

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { agencyApiSelectors } from '../../modules/api/agency'
import { userApiSelectors, userApiEvents } from '../../modules/api/user'
import { uiEvents } from '../../modules/ui'
import { accountingApiEvents, accountingApiSelectors } from '../api/accounting'
import { ROUTES } from 'constants/routes'
import { portfolioApiEvents, portfolioApiSelectors } from '../api/portfolio'
import { reconApiEvents, reconApiSelectors } from '../api/recon'
import { reportingApiEvents, reportingApiSelectors } from '../api/reporting'

const DashboardProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = useStore()
  const dispatch = useDispatch()
  const state = store.getState()

  useSelector(agencyApiSelectors.agency)

  const currentAgencyId = userApiSelectors.getCurrentAgencyId(state)

  const dashboardStore = {
    currentAgencyId,
    logoUrl: agencyApiSelectors.getAgencyLogo(state)(currentAgencyId),
    agencyName: agencyApiSelectors.getAgencyName(state)(currentAgencyId),
    agencyTrading: agencyApiSelectors.getAgencyTradingName(state)(currentAgencyId),
    agencyTheme: agencyApiSelectors.getAgencyTheme(state)(currentAgencyId),
    userName: userApiSelectors.getUserFirstName(state),
    userEmail: userApiSelectors.getUserEmail(state),
    fetchPortfolioStats: () => dispatch(portfolioApiEvents.portfolioStats_request()),
    fetchAccountingStats: () => dispatch(accountingApiEvents.accountingStats_request()),
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    fetchReconStats: () => dispatch(reconApiEvents.stats_request()),
    portfolioStats: portfolioApiSelectors.getStats(state),
    accountingStats: accountingApiSelectors.getStats(state),
    reconStats: reconApiSelectors.getStats(state),
    fetchAgencyPerformanceSummaryChart: () =>
      dispatch(reportingApiEvents.agencyPerformanceSummaryChart_request({ granularity: 'monthly' })),
    monthlyAgencyPerformanceSummaryChart: useSelector(reportingApiSelectors.getMonthlyPerformanceSummary),
    fetchBankStatement: () => dispatch(accountingApiEvents.bankStatementJson_request()),
    fetchDepositBankStatement: () => dispatch(accountingApiEvents.depositBankStatementJson_request({})),
    fetchDepositSummaryStats: () => dispatch(reportingApiEvents.depositSummaryStats_request()),
    bankStatementJson: useSelector(accountingApiSelectors.getBankStatementJson),
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    openBankStatement: () => dispatch(uiEvents.redirect(ROUTES.bankStatement)),
    openDepositBankStatement: () => dispatch(uiEvents.redirect(ROUTES.depositBankStatement)),
    depositBankStatementJson: useSelector(accountingApiSelectors.getDepositBankStatementJson),
    logout: () => dispatch(userApiEvents.logout_request()),
    ...props,
  }
  return <context.Provider value={dashboardStore}>{children}</context.Provider>
})

export default DashboardProvider

import { connect } from 'react-redux'
import store from '../../../state/store'
import { userApiSelectors, userApiEvents } from '../../../modules/api/user'
import { agencyApiEvents } from '../../../modules/api/agency'
import { uiSettingsSelectors } from '../../../modules/ui/settings'
import { OnboardingAgencyDetailsForm } from '../../components'
import { apiStateSelectors } from '../../../modules/api/apiState'

const mapStateToProps = (state: any) => ({
  initialValues: uiSettingsSelectors.getProfileInitialValues(state),
  isLoading: apiStateSelectors.isLoading(state)([agencyApiEvents.agency_request]),
  isSubmitting: apiStateSelectors.isLoading(state)([agencyApiEvents.updateAgency_request]),
  currentAgencyId: userApiSelectors.getCurrentAgencyId(state),
  isValidSupportStaff: userApiSelectors.isValidSupportStaff(state),
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSubmit: (values: any) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(store.getState())
      if (!currentAgencyId) {
        dispatch(userApiEvents.createAgency_request(values))
      } else {
        dispatch(agencyApiEvents.updateAgency_request({ id: currentAgencyId, values }))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingAgencyDetailsForm)

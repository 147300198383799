import React, { useEffect, useRef } from 'react'
import { subscribe } from 'react-contextual'
import PropTypes from 'prop-types'
import { SearchFilterProvider } from '../../../providers'
import Filters from './Filters/Filters'
import SearchField from './SearchField/SearchField'
import styles from './SearchFilter.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import cx from 'classnames'
import { useEffectOnce } from 'react-use'
import { StringParam, useQueryParam } from 'use-query-params'

export const propTypes = {
  pathname: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  query: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      extraKeys: PropTypes.object,
      label: PropTypes.string,
      count: PropTypes.number,
      onClick: PropTypes.func,
      hideCount: PropTypes.bool,
    }),
  ).isRequired,
  onClose: PropTypes.func,
}

interface SearchFilterProps extends $TSFixMe {
  pathname: string
  filter: $TSFixMe
  className?: string
}

function SearchFilter({
  className = '',
  pathname,
  redirect,
  filters,
  onClose,

  // SearchFilterProvider props
  filter,

  isSearchOpen,
  toggleSearchOpen,
  activeFilterIndex,
  setActiveFilterIndex,
  searchQuery,
  setSearchQuery,
}: SearchFilterProps): React.ReactElement {
  const searchFieldRef = useRef<any>(null)

  const [filterParam] = useQueryParam('filter', StringParam)

  useEffectOnce(() => {
    if (!filterParam) {
      setActiveFilterIndex(0)
    }
  })

  filters = filters.map((filter: $TSFixMe) => {
    const extraKeys = filter.extraKeys ? Object.keys(filter.extraKeys) : []
    let extraParams = ''

    if (extraKeys.length > 0) {
      extraParams = extraKeys.reduce(
        (paramString, key) => paramString + `&${key}=${filter.extraKeys[key] as string}`,
        '',
      )
    }

    return {
      ...filter,
      onClick: () => redirect(`${pathname}?filter=${filter.key as string}${extraParams}`),
    }
  })

  useEffect(() => {
    if (filter) {
      const index = filters.findIndex((f: any) => f.key === filter)
      if (index > -1) {
        setActiveFilterIndex(index)
      }
    }
  }, [filter, setActiveFilterIndex, filters])

  useEffect(() => {
    if (isSearchOpen) {
      setTimeout(() => {
        if (searchFieldRef !== null) {
          searchFieldRef.current?.focus()
        }
      }, 300)
    }
  }, [isSearchOpen])

  const handleSetActiveFilter = (index: number, callback: any): void => {
    setActiveFilterIndex(index)
    if (typeof callback === 'function') {
      callback()
    }
  }

  const handleSearchClose = (): void => {
    toggleSearchOpen()
    setSearchQuery('')
  }

  return (
    <div className={cx([styles.root, className])}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {!isSearchOpen && (
            <>
              <div className={styles['expanded-filters']}>
                <Filters filters={filters} activeFilter={activeFilterIndex} setActiveFilter={handleSetActiveFilter} />
              </div>
            </>
          )}
          <SearchField
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            query={searchQuery}
            isOpen={isSearchOpen}
            onChange={(e: any) => setSearchQuery(e.target.value)}
            onOpen={toggleSearchOpen}
            onClose={handleSearchClose}
            setRef={(ref: any) => {
              searchFieldRef.current = ref
            }}
          />
        </div>
      </div>
    </div>
  )
}

SearchFilter.propTypes = propTypes

export default subscribe(SearchFilterProvider, (props: any) => ({
  ...props,
}))(SearchFilter)

import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { useInterval } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'

import { apiStateSelectors } from '../../../../api/apiState'
import { walletApiEvents, walletApiSelectors } from '../../../../api/wallet'
import { FormLoader, TextField, TextInput } from '../../../../../views/components'
import { walletTransferEvents } from '../../state'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  from: yup.string().required('Required.'),
  to: yup.string().required('Required.'),
})

const TransferReferenceLookup = ({ onSuccess }: any) => {
  const dispatch = useDispatch()
  const [wallets, setWallets] = useState({ from: null, to: null })
  const isLookingUpReferences = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletTransferEvents.walletLookup_request]),
  )
  const getReferenceLookupByReference = useSelector(walletApiSelectors.getReferenceLookupByReference)
  const getGlobalBalanceAsset = useSelector(walletApiSelectors.getGlobalBalanceAsset)
  const fromWallet: $TSFixMe = getReferenceLookupByReference(wallets.from)
  const toWallet: $TSFixMe = getReferenceLookupByReference(wallets.to)
  const fromBalance = getGlobalBalanceAsset(fromWallet?.walletRef) || { balance: 0 }
  const toBalance = getGlobalBalanceAsset(toWallet?.walletRef) || { balance: 0 }
  const toWalletExists = useSelector(state => walletApiSelectors.walletExists(state)(toWallet?.walletRef))

  useEffect(() => {
    if (wallets.to && wallets.from) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      dispatch(walletTransferEvents.walletLookup_request(wallets))
    }
  }, [dispatch, wallets])

  useEffect(() => {
    if (fromWallet && toWallet && fromBalance && toWalletExists) {
      onSuccess({ fromWallet, toWallet, fromBalance, toBalance })
    }
  }, [onSuccess, fromWallet, toWallet, fromBalance, toBalance, toWalletExists])

  // poll balances in case of wallet creation
  useInterval(
    () => {
      dispatch(walletApiEvents.balance_request({ partyId: toWallet.walletRef }))
    },
    wallets.to && wallets.from && !toWalletExists ? 2000 : null,
  )

  return (
    <Formik
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      initialValues={{ from: '', to: '' }}
      validationSchema={validationSchema}
      onSubmit={setWallets}
    >
      {({ handleChange, handleSubmit, submitCount, errors, values }) => {
        const searchFormLoaderState = isLookingUpReferences
          ? 'submitting'
          : submitCount > 0 && !isEmpty(errors)
          ? 'error'
          : undefined

        return (
          <FormLoader
            onSubmit={handleSubmit}
            state={searchFormLoaderState}
            submittingText="Fetching wallets for confirmation"
            buttonProps={{ children: 'Lookup Wallets' }}
          >
            <div className="rbn--row" style={{ marginBottom: '20px' }}>
              <div className="rbn--col rbn-col-md-4">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="from"
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'from' does not exist on type 'FormikErro... Remove this comment to see the full error message
                      value={values.from}
                      onChange={handleChange}
                    />
                  }
                  label="From Wallet"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'from' does not exist on type 'FormikErro... Remove this comment to see the full error message
                  error={errors.from}
                />
              </div>
              <div className="rbn--col rbn-col-md-4">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="to"
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'to' does not exist on type 'FormikErro... Remove this comment to see the full error message
                      value={values.to}
                      onChange={handleChange}
                    />
                  }
                  label="To Wallet"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'to' does not exist on type 'FormikErrors... Remove this comment to see the full error message
                  error={errors.to}
                />
              </div>
              <div className="rbn--col rbn-col-md-4"></div>
            </div>
          </FormLoader>
        )
      }}
    </Formik>
  )
}

export default TransferReferenceLookup

import React, { useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { useEffectOnce } from 'react-use'
import { StringParam, useQueryParam } from 'use-query-params'
import { Link } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { usePortfolioProperty } from 'modules/api/portfolio/hooks'
import { InvoicesProvider } from '../../../../providers'
import { ListingTemplate } from '../../..'
import SearchFilter from './SearchFilter'
import ActiveInvoiceTable from './ActiveInvoiceTable/ActiveInvoiceTable'
import OpenBulkActions from './OpenBulkActions'
import { TableProvider } from '../../../organisms/Table'

const ActiveInvoices = ({ isActiveLoading, fetchCreditNoteReasons, fetchReconInvoices, fetchInvoiceTypes }: any) => {
  useEffectOnce(() => {
    fetchCreditNoteReasons()
    fetchReconInvoices()
    fetchInvoiceTypes()
  })

  const [portfolioId] = useQueryParam('portfolioId', StringParam)
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  const portfolioUrl = ROUTES.portfolioEdit.replace(':id', portfolioId)
  const { propertyStreetAddress } = usePortfolioProperty(portfolioId)

  return useMemo(() => {
    const title = propertyStreetAddress && (
      <>
        Outbox for lease: <Link to={portfolioUrl}>{propertyStreetAddress}</Link>
      </>
    )

    return (
      <>
        <Helmet>
          <title>reOS | Invoices | Active</title>
        </Helmet>
        <TableProvider>
          <ListingTemplate
            loading={isActiveLoading}
            Header={<SearchFilter />}
            Body={<ActiveInvoiceTable title={title} />}
            Footer={<OpenBulkActions />}
          />
        </TableProvider>
      </>
    )
  }, [isActiveLoading, propertyStreetAddress, portfolioUrl])
}

export default subscribe(
  [InvoicesProvider],
  ({ fetchCreditNoteReasons, fetchReconInvoices, isActiveLoading, fetchInvoiceTypes }: any) => ({
    fetchCreditNoteReasons,
    fetchReconInvoices,
    isActiveLoading,
    fetchInvoiceTypes,
  }),
)(ActiveInvoices)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get, isEmpty, isEqual } from 'lodash-es'
import keyboardKey from 'keyboard-key'
import { eventStack } from '../../../../utils'
import { getUnhandledProps, htmlPropUtils } from '../../../utils'
import TextField from '../../atoms/TextField/TextField'
import { AggregateListItem, List, ListDivider } from '../List/List'
import styles from './Search.module.scss'
import { OnOff, TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import Label from 'views/components/atoms/Label/Label'
import TextInput from 'views/components/atoms/TextField/TextInput'

export const propTypes = {
  /** Class name */
  className: PropTypes.string,

  /** Minimum characters to query results. */
  minCharacters: PropTypes.number,

  /** Message to display when there are no resutls. */
  noResultsMessage: PropTypes.node,

  /** Controls whether or not the results message is displayed. */
  isOpen: PropTypes.bool,

  /** Displays a loading indicator. */
  isLoading: PropTypes.bool,

  /** Changes layout of search dropdown. */
  hasIdentification: PropTypes.bool,

  /** Disable input */
  disabled: PropTypes.bool,

  /**
   * Results:
   * - Group results by arrays i.e. [[{...}, {...}], {...}, {...}]
   * - One group - [[{...}, {...}]]
   */
  resultSets: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string.isRequired,
        secondaryText: PropTypes.string,
        children: PropTypes.node,
      }),
    ),
  ),

  /** Present default options when resultSets is empty. */
  defaultResultSets: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        secondaryText: PropTypes.string,
      }),
    ),
  ),

  /** Whether a "no results" message should be shown if no results are found. */
  showNoResults: PropTypes.bool,

  /** Current value of the search input. Creates a controlled component. */
  value: PropTypes.string,

  /** Whether or not state.value should be update on result selection. */
  updateValueOnSelect: PropTypes.bool,

  /** Input label. */
  label: PropTypes.string,

  /**
   * Called on blur.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props
   */
  onBlur: PropTypes.func,

  /**
   * Called on Focus.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props
   */
  onFocus: PropTypes.func,

  /**
   * Called on mousedown.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props
   */
  onMouseDown: PropTypes.func,

  /**
   * Called when a result is selected.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onResultSelect: PropTypes.func,

  /**
   * Called when search input changes.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onSearchChange: PropTypes.func,

  error: PropTypes.string,

  focusOnMount: PropTypes.bool,
}

class Search extends Component {
  static propTypes = propTypes

  static defaultProps = {
    minCharacters: 1,
    noResultsMessage: 'No results found.',
    showNoResults: true,
    updateValueOnSelect: false,
    resultSets: [],
    defaultResultSets: [],
    focusOnMount: false,
  }

  dropdownRef: any
  isMouseDown: any
  searchFieldRef: any

  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: false,
      isFocused: false,
      value: '',
      selectedSet: 0,
      selectedIndex: 0,
      windowHeight: window.outerHeight,
      isDropdownOverflowingWindow: false,
    }
  }

  componentDidMount(): void {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { isOpen, value, focusOnMount } = this.props

    if (focusOnMount) {
      // delay focus because some components are animated
      const { searchFieldRef } = this
      setTimeout(() => {
        if (searchFieldRef) {
          searchFieldRef?.focus()
        }
      }, 300)
    }

    const state = { isOpen }
    if (value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type '{ isOpen:... Remove this comment to see the full error message
      state.value = value
    }
    this.setState(state)
    document.addEventListener('scroll', this.updateDropdownOverflow)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.updateDropdownOverflow)
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  // }

  componentDidUpdate(prevProps: any, prevState: any): void {
    // Value state
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.setState({ value: this.props.value })
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'Reado... Remove this comment to see the full error message
    if (!prevState.isFocused && this.state.isFocused) {
      if (!this.isMouseDown) {
        this.tryOpen()
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.state.isOpen) {
        eventStack.sub('keydown', [this.moveSelectionOnKeyDown, this.selectItemOnEnter])
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'Reado... Remove this comment to see the full error message
    } else if (prevState.isFocused && !this.state.isFocused) {
      if (!this.isMouseDown) {
        this.close()
      }
      eventStack.unsub('keydown', [this.moveSelectionOnKeyDown, this.selectItemOnEnter])
    }

    /** Opened / closed */
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (!prevState.isOpen && this.state.isOpen) {
      this.open()
      eventStack.sub('click', this.close)
      eventStack.sub('keydown', [this.closeOnEscape, this.moveSelectionOnKeyDown, this.selectItemOnEnter])
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    } else if (prevState.isOpen && !this.state.isOpen) {
      this.close()
      eventStack.unsub('click', this.close)
      eventStack.unsub('keydown', [this.closeOnEscape, this.moveSelectionOnKeyDown, this.selectItemOnEnter])
    }

    // Dropdown window overflow
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowHeight' does not exist on type 'Re... Remove this comment to see the full error message
    if (prevState.windowHeight !== this.state.windowHeight || !isEqual(prevProps.resultSets, this.props.resultSets)) {
      this.updateDropdownOverflow()
    }
  }

  updateDropdownOverflow = () => {
    this.setState({ isDropdownOverflowingWindow: false })
    if (!this.dropdownRef) {
      return false
    }
    const dropdownDimensions = this.dropdownRef.getBoundingClientRect()
    const dropDownOffset = dropdownDimensions.height + dropdownDimensions.top
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowHeight' does not exist on type 'Re... Remove this comment to see the full error message
    const isDropdownOverflowingWindow = dropDownOffset > this.state.windowHeight
    this.setState({ isDropdownOverflowingWindow })
  }

  /**
   * Document event handlers
   */

  moveSelectionOnKeyDown = (e: any) => {
    switch (keyboardKey.getCode(e)) {
      case keyboardKey.ArrowDown:
        e.preventDefault()
        this.moveSelectionBy(e, 1)
        break
      case keyboardKey.ArrowUp:
        e.preventDefault()
        this.moveSelectionBy(e, -1)
        break
      default:
        break
    }
  }

  selectItemOnEnter = (e: any) => {
    if (keyboardKey.getCode(e) !== keyboardKey.Enter) {
      return false
    }

    const result = this.getSelectedResult()
    e.preventDefault()

    if (result) {
      this.setState({ value: result.text })
      this.handleResultSelect(result, e)
    }

    this.close()
  }

  closeOnEscape = (e: any) => {
    if (keyboardKey.getCode(e) !== keyboardKey.Escape) {
      return false
    }
    e.preventDefault()
    this.close()
  }

  /**
   * Component event handlers
   */

  handleMouseDown = (e: any): void => {
    this.isMouseDown = true
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMouseDown' does not exist on type 'Rea... Remove this comment to see the full error message
    const { onMouseDown } = this.props
    if (onMouseDown) {
      onMouseDown(e, this.props)
    }
    eventStack.sub('mouseup', this.handleDocumentMouseUp)
  }

  handleDocumentMouseUp = (): void => {
    this.isMouseDown = false
    eventStack.unsub('mouseup', this.handleDocumentMouseUp)
  }

  handleSearchChange = (e: any): void => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSearchChange' does not exist on type '... Remove this comment to see the full error message
    const { onSearchChange, minCharacters } = this.props
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { isOpen } = this.state
    const query = e.target.value

    // prevent propagating to this.props.onChange()
    e.stopPropagation()

    if (onSearchChange) {
      onSearchChange(e, { ...this.props, value: query })
    }

    if (query < minCharacters) {
      this.close()
    } else if (!isOpen) {
      this.tryOpen()
    }

    this.setState({ value: query })
  }

  handleBlur = (e: any): void => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { onBlur } = this.props
    if (onBlur) {
      onBlur(e, this.props)
    }
    this.setState({ isFocused: false })
  }

  handleFocus = (e: any): void => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e, this.props)
    }
    this.setState({ isFocused: true })
    this.tryOpen()
  }

  handleInputClick = (e: any): void => {
    // prevent closeOnDocumentClick()
    e.nativeEvent.stopImmediatePropagation()
    this.tryOpen()
  }

  handleItemClick = ({ result, resultsIndex, index }: any, e: any) => {
    e.nativeEvent.stopImmediatePropagation()
    this.handleResultSelect(result, e)
    this.close()
  }

  handleResultSelect = (result: any, e: any): void => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onResultSelect' does not exist on type '... Remove this comment to see the full error message
    const { onResultSelect, updateValueOnSelect } = this.props
    if (onResultSelect) {
      onResultSelect(e, { ...this.props, result })
    }
    if (updateValueOnSelect) {
      this.setState({ value: result.text })
    }
    this.close()
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
  tryOpen = (currentValue = this.state.value) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'minCharacters' does not exist on type 'R... Remove this comment to see the full error message
    const { minCharacters } = this.props
    if ((!this.isDefaultResultSetsEmpty() && this.isResultSetsEmpty()) || currentValue.length >= minCharacters) {
      this.open()
    }
  }

  open = () => this.setState({ isOpen: true })

  close = () =>
    this.setState({
      isOpen: false,
      selectedSet: 0,
      selectedIndex: 0,
    })

  /**
   * Getters
   */

  getSelectedResult = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedSet' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedSet, selectedIndex } = this.state
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSets' does not exist on type 'Read... Remove this comment to see the full error message
    const { resultSets } = this.props
    return get(resultSets[selectedSet], selectedIndex)
  }

  /**
   * Setters
   */

  moveSelectionBy = (e: any, offset: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedSet' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedSet, selectedIndex } = this.state
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSets' does not exist on type 'Read... Remove this comment to see the full error message
    const { resultSets } = this.props
    const lastSet = resultSets.length - 1
    const lastIndex = resultSets[selectedSet].length - 1

    let nextSet = selectedSet
    let nextIndex = selectedIndex + offset

    if (nextIndex > lastIndex) {
      nextSet = selectedSet + 1
      nextIndex = 0
      if (nextSet > lastSet) {
        nextSet = 0
      }
    } else if (nextIndex < 0) {
      nextSet = selectedSet - 1
      nextIndex = lastIndex
      if (nextSet < 0) {
        nextSet = lastSet
        nextIndex = resultSets[lastSet].length - 1
      }
    }

    this.setState({ selectedSet: nextSet, selectedIndex: nextIndex })
    this.scrollSelectedItemIntoView()
  }

  /**
   * Behaviour
   */

  /** @todo test on touch devices */
  scrollSelectedItemIntoView = () => {
    const dropdown = document.querySelector<HTMLElement>(`.${styles.dropdown}`)
    const item = document.querySelector<HTMLLIElement>(`.${styles.dropdown} li[class*='item__highlighted']`)
    if (!item || !dropdown) {
      return
    }
    const isOutOfTopView = item.offsetTop < dropdown.scrollTop
    const isOutoFBottomView = item.offsetTop + item.clientHeight > dropdown.scrollTop + dropdown.clientHeight

    if (isOutOfTopView) {
      dropdown.scrollTop = item.offsetTop
    } else if (isOutoFBottomView) {
      dropdown.scrollTop = item.offsetTop + item.clientHeight - dropdown.clientHeight
    }
  }

  /**
   * Utils
   */

  isResultSetsEmpty = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSets' does not exist on type 'Read... Remove this comment to see the full error message
    return this.props.resultSets.filter((rs: any) => !isEmpty(rs)).length === 0
  }

  isDefaultResultSetsEmpty = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultResultSets' does not exist on typ... Remove this comment to see the full error message
    return this.props.defaultResultSets.filter((rs: any) => !isEmpty(rs)).length === 0
  }

  getResultSetLengths = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSets' does not exist on type 'Read... Remove this comment to see the full error message
    return this.props.resultSets.map((rs: any) => rs.length)
  }

  renderMenuContent = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showNoResults' does not exist on type 'R... Remove this comment to see the full error message
    const { showNoResults, isLoading } = this.props
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { isOpen } = this.state
    if (this.isResultSetsEmpty() && this.isDefaultResultSetsEmpty()) {
      return showNoResults && isOpen && !isLoading ? this.renderNoResults() : null
    }
    return this.renderResults()
  }

  renderNoResults = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'noResultsMessage' does not exist on type... Remove this comment to see the full error message
    const { noResultsMessage } = this.props
    return (
      <div className={styles['no-results']}>
        <List>
          <AggregateListItem text={noResultsMessage} />
        </List>
      </div>
    )
  }

  renderResults = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'resultSets' does not exist on type 'Read... Remove this comment to see the full error message
    const { resultSets, defaultResultSets } = this.props
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedSet' does not exist on type 'Rea... Remove this comment to see the full error message
    const { selectedSet, selectedIndex } = this.state

    const results = !this.isResultSetsEmpty() ? resultSets : defaultResultSets

    const classes = classNames(styles.dropdown, {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isDropdownOverflowingWindow' does not ex... Remove this comment to see the full error message
      [styles['is-overflowing-window']]: this.state.isDropdownOverflowingWindow,
    })

    return (
      <div
        className={classes}
        ref={node => {
          this.dropdownRef = node
        }}
      >
        <List interactive>
          {results.map((results: any, resultsIndex: number) => (
            <div key={resultsIndex}>
              {results.map((result: any, i: any) => (
                <AggregateListItem
                  key={i}
                  highlighted={selectedSet === resultsIndex && selectedIndex === i}
                  onClick={this.handleItemClick.bind(null, { result, resultsIndex, index: i })}
                  text={result.text}
                  secondaryText={result.secondaryText}
                >
                  {result.children ?? result.children}
                </AggregateListItem>
              ))}
              {resultsIndex + 1 < resultSets.length && <ListDivider />}
            </div>
          ))}
        </List>
      </div>
    )
  }

  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isLoading' does not exist on type 'Reado... Remove this comment to see the full error message
      isLoading,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasIdentification' does not exist on type 'Reado... Remove this comment to see the full error message
      hasIdentification,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type 'Readon... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
      error,
    } = this.props

    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
      isOpen,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      value,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFocused' does not exist on type 'Reado... Remove this comment to see the full error message
      isFocused,
    } = this.state

    const classes = classNames([styles.root, className], {
      [styles.open]: isOpen,
      [styles.loading]: isLoading && isFocused,
      [styles.hasIdentification]: hasIdentification,
    })

    const unhandled = getUnhandledProps(Search, this.props)

    const [htmlInputProps] = htmlPropUtils.partitionHTMLProps(unhandled, {
      htmlProps: htmlPropUtils.htmlInputAttrs,
    })

    return (
      <div className={classes} onMouseDown={this.handleMouseDown}>
        <TextField
          {...htmlInputProps}
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              value={value}
              name={htmlInputProps.name}
              onChange={this.handleSearchChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onClick={this.handleInputClick}
              autoComplete={OnOff.off}
              placeholder={htmlInputProps.placeholder}
              disabled={disabled}
              ref={(ref: any) => {
                this.searchFieldRef = ref
              }}
            />
          }
          label={label}
          disabled={disabled}
          error={error}
        />
        {this.renderMenuContent()}
      </div>
    )
  }
}

export default Search

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { Heading, InfoList } from '../../../../../views/components'
import EditIcon from '../../../../../views/components/atoms/Svgs/Edit'
import ContactsProvider from '../../../state/ContactsProvider'
import styles from './ContactInfo.module.scss'

const propTypes = {
  pathname: PropTypes.string,
  redirect: PropTypes.func,
  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    lastModified: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
    bank: PropTypes.node,
    accountNumber: PropTypes.string,
    idNumber: PropTypes.string,
  }),
}

const ContactInfo = ({ pathname, redirect, contactInfo }: any) => {
  const infoListItem = useMemo(
    () => [
      {
        label: 'Email',
        value: contactInfo.email,
      },
      {
        label: 'Number',
        value: contactInfo.contactNumber,
      },
      {
        label: 'ID',
        value: contactInfo.idNumber,
        isVisible: contactInfo.idNumber,
      },
      {
        label: 'Passport',
        value: contactInfo.passportNumber,
        isVisible: contactInfo.passportNumber,
      },
      {
        label: 'Tax',
        value: contactInfo.taxNumber,
        isVisible: contactInfo.taxNumber,
      },
      {
        label: 'Reg',
        value: contactInfo.companyRegistration,
        isVisible: contactInfo.companyRegistration,
      },
      {
        label: 'VAT',
        value: contactInfo.vatNumber,
        isVisible: contactInfo.vatNumber,
      },
    ],
    [contactInfo],
  )

  const bankAccountItems = useMemo(
    () => [
      {
        label: 'Bank',
        value: contactInfo.bank,
      },
      {
        label: 'Account',
        value: contactInfo.accountNumber,
      },
    ],
    [contactInfo],
  )

  const addressLine2 = contactInfo.address.addressLine2 ? `, ${contactInfo.address.addressLine2}` : ''
  const province = contactInfo.address.province ? `, ${contactInfo.address.province}` : ''

  return (
    <div className={styles.root}>
      <header>
        <button className={styles.edit} onClick={() => redirect(`${pathname}/edit`)}>
          <EditIcon />
        </button>
        <Heading as="h2" className={styles.name}>
          {contactInfo.partyName}
        </Heading>
        {contactInfo.partyName !== contactInfo.companyName && (
          <Heading as="h3" className={styles['sub-title']}>
            {contactInfo.companyName}
          </Heading>
        )}
        <small className={styles.lastModified}>Last Updated: {contactInfo.lastModified}</small>
      </header>
      <section>
        <Heading as="h3">Contact Details</Heading>
        {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
        <InfoList items={infoListItem} />
      </section>

      {bankAccountItems.filter(item => !!item.value).length > 0 && (
        <section>
          <Heading as="h3">Linked Bank Account</Heading>
          {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
          <InfoList spacing="none" items={bankAccountItems} />
        </section>
      )}

      {contactInfo.address && (
        <section>
          <Heading as="h3">Address</Heading>
          <p>
            {contactInfo.address.addressLine1}
            {addressLine2}
            {contactInfo.address.addressLine1 || addressLine2 ? <br /> : ''}
            {contactInfo.address.city}
            {province}
            {contactInfo.address.city || province ? <br /> : ''}
            {contactInfo.address.postalCode}
            {contactInfo.address.postalCode ? <br /> : ''}
            {contactInfo.address.country}
          </p>
        </section>
      )}
    </div>
  )
}

ContactInfo.propTypes = propTypes

export default subscribe(ContactsProvider, ({ location, redirect, currentContactInfo: contactInfo }: any) => ({
  pathname: location.pathname,
  redirect,
  contactInfo,
}))(ContactInfo)

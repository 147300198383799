import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { walletApiEvents, walletApiSelectors } from 'modules/api/wallet'
import { accountingApiEvents } from 'modules/api/accounting'
import { contactsEvents, contactsSelectors } from 'modules/contacts/state'
import { path } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  partyId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountType: PropTypes.string,
}

const DepositAccountProvider = moduleContext()(({ context, children, ...props }: any) => {
  const { partyId, accountId, accountType } = props
  const store = useStore()
  const dispatch = useDispatch()
  const state = store.getState()
  const getWalletAsset = (partyId: any, accountId: any, accountType: string) =>
    walletApiSelectors.getBalanceAsset(state)(partyId, accountId, accountType)

  const depositAccountBalance = walletApiSelectors.getDepositAccountBalance(state)(partyId, accountId)

  useEffect(() => {
    dispatch(walletApiEvents.balance_request({ partyId }))
    dispatch(accountingApiEvents.depositStatementJson_request({ id: partyId, accountId }))
  }, [partyId, accountId, dispatch])

  const depositStore = {
    depositAccountBalance,
    getWalletAsset,
    depositTransferModal: useSelector(contactsSelectors.depositTransferModal),
    openDepositTransferModal: () => {
      const selectedPartyAccountAsset = path(['name'], getWalletAsset(partyId, accountId, accountType))
      if (selectedPartyAccountAsset) {
        dispatch(
          contactsEvents.openDepositTransferModal({
            partyId,
            account: accountId,
            asset: selectedPartyAccountAsset,
          }),
        )
      }
    },
    handleTransfer: ({ body, params }: any) => {
      dispatch(
        walletApiEvents.transfer_request({
          body,
          params,
          meta: {
            partyId,
            amount: body.amount,
            account: body.account,
          },
        }),
      )
    },
    closeDepositTransferModal: () => dispatch(contactsEvents.closeDepositTransferModal()),

    // Deposit Top Up Request
    openDepositTopupRequest: (partyId: $TSFixMe, portfolioId: $TSFixMe) =>
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2.
      dispatch(contactsEvents.depositTopupRequestOpened({ partyId, portfolioId })),
    ...props,
  }

  return <context.Provider value={depositStore}>{children}</context.Provider>
})

DepositAccountProvider.propTypes = propTypes

export default DepositAccountProvider

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { PortfoliosProvider } from '../../../providers'
// import { NoContent } from '../../../components'
import Portfolios from './Portfolios/Portfolios'

@subscribe(PortfoliosProvider, ({ getPortfolios, viewingInvoice, searchQuery }) => ({
  getPortfolios,
  viewingInvoice,
  searchQuery,
}))
class PortfoliosPage extends Component {
  componentDidMount(): void {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'getPortfolios' does not exist on type 'R... Remove this comment to see the full error message
    this.props.getPortfolios(this.props.searchQuery)
  }

  render(): null | React.ReactElement {
    return <Portfolios />
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PortfoliosPage.propTypes = {
  getPortfolios: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
}

export default PortfoliosPage

import React from 'react'
import { matchPath } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { FocusMode } from '../../../../views/components'
import { ContactsProvider } from '../..'
import EditContactForm from './EditContactForm'

const EditContact = ({ id, currentParty, match, goBack, fetchParty }: any) => {
  if (!currentParty) {
    fetchParty(id)
    return null
  }
  const editMatchPath = matchPath(match.url, {
    path: '/contacts/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/edit',
    exact: true,
    strict: false,
  })

  return (
    <FocusMode
      isOpen={editMatchPath !== null}
      title="Edit Contact"
      ContentComponent={EditContactForm}
      onClose={() => goBack()}
    />
  )
}

export default subscribe(ContactsProvider, ({ match, currentParty, id, goBack, fetchParty }: any) => ({
  match,
  currentParty,
  id,
  goBack,
  fetchParty,
}))(EditContact)

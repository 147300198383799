import createSelector from 'selectorator'
import { head, identity, path, pipe, uniqBy, values } from 'ramda'

import { NAMESPACE } from '../constants'
import { $TSFixMe } from 'types/ts-migrate'

export const apiState = createSelector([NAMESPACE], identity)

const getSlice = (key: string): $TSFixMe => createSelector([`${NAMESPACE}.${key}`], identity)

const loading = getSlice('loading')

const complete = getSlice('complete')

export const isLoading = createSelector(
  [loading],
  loading => (events: any) => events.some((action: any) => path([action.type.replace('_request', '')], loading)),
)

export const isComplete = createSelector(
  [complete],
  complete => (events: any) => events.some((action: any) => !path([action.type.replace('_success', '')], complete)),
)

const errors = getSlice('errors')

/**
 * Get errors for a specifc Redux action (event).
 * EG: getErrorsByEvent(state)(userApiEvents.login_request)
 *
 * Get errors for a specific Redux action (event) with a payload
 * EG: getErrorsByEvent(state)(userApiEvents.login_request({ email: 'test@example.com', password: '123123' }))
 */
export const getErrorsByEvent = createSelector([errors], errors => (event: any) => {
  const eventName = event.type.replace('_request', '')
  const eventErrors = path([eventName], errors)
  const payload = event?.payload
  return payload ? path([JSON.stringify(payload)], eventErrors) : eventErrors
})

const formatApiErrorResponse = (errors: any): $TSFixMe => {
  const getFieldKey = (key: any): string => key.split('.')[0]
  const errorsArray = uniqBy((e: any) => getFieldKey(e.key), errors)
  const formattedErrors: $TSFixMe = {}
  errorsArray.forEach((e: any) => {
    formattedErrors[getFieldKey(e.key)] = e.message
  })
  return formattedErrors
}

export const getGeneralFormErrorsByEvent = createSelector([identity], state => (event: any) => {
  let errors: $TSFixMe = getErrorsByEvent(state)(event) || []
  errors = Array.isArray(errors) ? errors : pipe(values, head)(errors)
  return errors.filter((error: any) => error.key === 'general').map((error: any) => error.message)
})

export const getFormFieldErrorsByEvent = createSelector([identity], state => (event: any) => {
  const errors: $TSFixMe = getErrorsByEvent(state)(event) || []
  return formatApiErrorResponse(errors.filter((err: any) => err.key !== 'general'))
})

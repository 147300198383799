import React, { Component } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  duration: PropTypes.number,
  onTimeOut: PropTypes.func,
  reset: PropTypes.bool,
  onReset: PropTypes.func,
}

class Timer extends Component {
  timer: any
  constructor(props: any) {
    super(props)
    this.state = {
      start: Date.now() + 1000,
      diff: 0,
      minutes: '00',
      seconds: '00',
    }

    this.tick = this.tick.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.reset) {
      this.resetTimer()
    }
  }

  componentDidMount(): void {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'duration' does not exist on type 'Readon... Remove this comment to see the full error message
    const diff = this.props.duration - (((Date.now() - this.state.start) / 1000) | 0)
    this.setMinutesAndSeconds(diff)
    this.startTimer()
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  startTimer() {
    this.setState({ start: Date.now() })
    this.timer = setInterval(this.tick, 1000)
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  resetTimer() {
    this.stopTimer()
    this.startTimer()
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onReset' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onReset()
  }

  setDiff() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'duration' does not exist on type 'Readon... Remove this comment to see the full error message
    this.setState({ diff: this.props.duration - (((Date.now() - this.state.start) / 1000) | 0) })
  }

  setMinutesAndSeconds(diff: any) {
    if (diff >= 0) {
      const mins = (diff / 60) | 0
      const secs = diff % 60 | 0
      const minutes = mins < 10 ? `0${mins}` : mins
      const seconds = secs < 10 ? `0${secs}` : secs

      this.setState({ minutes, seconds })
    }
  }

  tick() {
    this.setDiff()
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'diff' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    this.setMinutesAndSeconds(this.state.diff)

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'duration' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.duration - this.state.diff >= this.props.duration) {
      clearInterval(this.timer)
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onTimeOut' does not exist on type 'Reado... Remove this comment to see the full error message
      this.props.onTimeOut()
    }
  }

  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'minutes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { minutes, seconds } = this.state

    return (
      <span>
        {minutes}:{seconds}
      </span>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Timer.propTypes = propTypes

export default Timer

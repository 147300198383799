import { combineReducers } from 'redux'
import { uiInvoiceReducer } from './invoices'
import { propertySearchReducer } from './PropertySearch'
import { partySearchReducer } from './PartySearch'

const reducer = combineReducers({
  invoices: uiInvoiceReducer,
  propertySearch: propertySearchReducer,
  partySearch: partySearchReducer,
})

export default reducer

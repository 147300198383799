import { createSlice } from '@reduxjs/toolkit'
import createSelector from 'selectorator'
import { identity } from 'ramda'
// import { identity } from 'lodash-es'
import { NAMESPACE, emptyInitialValues } from '../constants'
import { $TSFixMe } from 'types/ts-migrate'

const adhocInvoiceSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    initialValues: emptyInitialValues,
  },
  reducers: {
    initialValuesUpdateRequested: (state, { payload }) => {
      state.initialValues = payload
    },
    initialValuesResetRequested: state => {
      state.initialValues = emptyInitialValues
    },
  },
})

/**
 * Selector Helpers
 */
const getSlice = (key: string): $TSFixMe => createSelector([`${NAMESPACE}.${key}`], identity)

const selectors = {}
// const getState = state => state
// @ts-expect-error ts-migrate(2339) FIXME: Property 'getAdhocInvoiceState' does not exist on ... Remove this comment to see the full error message
selectors.getAdhocInvoiceState = createSelector([NAMESPACE], identity)
// @ts-expect-error ts-migrate(2339) FIXME: Property 'getInitialValues' does not exist on type... Remove this comment to see the full error message
selectors.getInitialValues = getSlice('initialValues')

/**
 * Exports
 */
const { reducer, actions: events } = adhocInvoiceSlice

export { events as adhocInvoiceEvents, reducer as adhocInvoiceReducer, selectors as adhocInvoiceSelectors }

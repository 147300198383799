import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button } from '../../../../../../views/components'
import styles from './AccountDetails.module.scss'
import { useDispatch } from 'react-redux'
import { uiEvents } from 'modules/ui'
import PortfolioStatusLabel from 'modules/portfolios/components/PortfolioStatusLabel'

const propTypes = {
  paymentReference: PropTypes.string,
  accountTag: PropTypes.string,
  accountAddress: PropTypes.string,
  accountMeta: PropTypes.string,
  status: PropTypes.oneOf(['good', 'bad']),
  partyHasBankDetails: PropTypes.bool,
  portfolioId: PropTypes.string,
}

const defaultProps = {}

const AccountDetails = ({ paymentReference, accountTag, accountAddress, accountMeta, status, portfolioId }: any) => {
  const classes = cx([styles.root, [styles[`status-${status}`]]])
  const dispatch = useDispatch()

  const openPortfolio = useCallback(() => {
    dispatch(uiEvents.redirect(`/leases/${portfolioId}`))
  }, [portfolioId])

  return (
    <div className={classes}>
      <header>
        <strong>{accountTag} Wallet</strong>
        <span>{paymentReference}</span>
      </header>
      <section className={styles['account-meta']}>
        {portfolioId && (
          <div className={styles['account-actions']}>
            <PortfolioStatusLabel
              labelProps={{ as: Button, onClick: openPortfolio }}
              portfolioId={portfolioId}
              useSummary
              statusPrefix="View"
              statusSuffix="lease"
            />
          </div>
        )}
      </section>
      <section className={styles.address}>
        {accountAddress} <br />
        {accountMeta && <small>{accountMeta}</small>}
      </section>
    </div>
  )
}

AccountDetails.propTypes = propTypes
AccountDetails.defaultProps = defaultProps

export default AccountDetails

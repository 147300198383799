import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Switch from '../../atoms/Switch/Switch'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import Svg from '../../atoms/Svg/Svg'
import styles from './ToggleTooltip.module.scss'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  body: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  place: PropTypes.string,
  style: PropTypes.object,
}

const defaultProps = {
  active: false,
  onChange: () => {},
  onClose: () => {},
  place: 'above',
  width: 'auto',
}

class ToggleTooltip extends Component {
  constructor(props: any) {
    super(props)
    this.renderBody = this.renderBody.bind(this)
  }

  renderBody() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'body' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { body, title, active, onChange, onClose, style } = this.props

    return (
      <div className={styles.root} style={style}>
        <div className={styles.header}>
          <Switch name="toggle" label="&nbsp;" checked={!!active} onChange={onChange} />
          <strong>{title}</strong>
          <span className={styles.close}>
            <Svg name="close" width="10px" onClick={onClose} />
          </span>
        </div>
        <div className={styles.body}>{body}</div>
      </div>
    )
  }

  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { children, active, isOpen, onClose, place } = this.props
    const classes = classNames({
      'Popover-active': active,
    })

    return (
      <Tooltip
        isOpen={isOpen}
        padding="none"
        size="md"
        className={classes}
        body={this.renderBody()}
        place={place}
        contrast="light"
        onOuterAction={onClose}
        tipSize={0.01}
      >
        {children}
      </Tooltip>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ToggleTooltip.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ToggleTooltip.defaultProps = defaultProps

export default ToggleTooltip

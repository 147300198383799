import { connect } from 'react-redux'
import { SettingsProvider } from '../../providers'
import { uiEvents } from '../../../modules/ui'
import { $TSFixMe } from 'types/ts-migrate'

const mapStateToProps = (state: any): $TSFixMe => ({})

const mapDispatchToProps = (dispatch: any): $TSFixMe => ({
  redirect: (path: any) => dispatch(uiEvents.redirect(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProvider)

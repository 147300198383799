const { REACT_APP_BASE_URL = '', REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'integrations'

const BASE = `${REACT_APP_BASE_URL}/integrations/v1`
const BASE_V2 = `${REACT_APP_API_URL}/agency/integrations`

export const ENDPOINTS = {
  INTEGRATIONS_LIST: `${BASE_V2}/list`,
  INTEGRATION_OAUTH_DETAILS: `${BASE_V2}/property_inspect`,
  ACTIVATE_INTEGRATION: `${BASE_V2}/`,
  DEACTIVATE_INTEGRATION: `${BASE_V2}/:integrationType`,
  TPN_AUTHORIZE: `${BASE}/tpn/authorize`,
  TPN_CONSUMER_CAN_ACCESS_CREDEX: `${BASE}/tpn/consumer/can-access-credex`,
  TPN_CONSUMER_ENQUIRY: `${BASE}/tpn/consumer/enquiry`,
  TPN_CONSUMER_ENQUIRY_HISTORY: `${BASE}/tpn/consumer/enquiry/history`,
  TPN_CONSUMER_MODULES: `${BASE}/tpn/consumer/modules`,
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationCentreComponent from '../../../../views/components/organisms/NotificationCentre/NotificationCentre'
import { notificationSelectors, notificationEvents } from '../..'

const NotificationCentre = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(notificationSelectors.getNotifications)
  const handleDismiss = (e: any, i: any): void => {
    dispatch(notificationEvents.clearNotification(i))
  }

  return <NotificationCentreComponent notifications={notifications} onDismiss={handleDismiss} />
}

export default NotificationCentre

import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../atoms/Button/Button'
import FilterIcon from '../../../atoms/Icons/Controls/Configure'
import ContextMenu from '../../../molecules/ContextMenu/ContextMenu'
import styles from './DataFiltersMenu.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  onFilterSelect: PropTypes.func.isRequired,
}

const DropdownButton = ({ state = 'open' }) => (
  <Button className={styles[`${state}-button`]} size="sm" secondary pill>
    <FilterIcon />
  </Button>
)

const DataFiltersMenu = ({ filters, onFilterSelect }: $TSFixMe) => {
  return (
    <div className={styles.root}>
      <ContextMenu
        name="filter-menu"
        items={filters.map(({ label, options }: $TSFixMe) => ({ label, subOptions: options }))}
        dropDownOpenIcon={<DropdownButton state="open" />}
        dropDownCloseIcon={<DropdownButton state="close" />}
        position="left"
        showInput={false}
        onSubmenuOptionClick={onFilterSelect}
      />
    </div>
  )
}

DataFiltersMenu.propTypes = propTypes

export default DataFiltersMenu

export const UUIDRegex = '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})'

export const ROUTES = {
  base: '/',
  login: '/login',
  signup: '/user-signup',
  invitation: '/invitation',
  forgotPassword: '/forgot',
  resetPassword: '/reset/:token',
  verifyEmail: '/verify/:userId/:verifyId',
  dashboard: '/dashboard',
  bankStatement: '/bank-statement',
  depositBankStatement: '/deposit-statement',
  commissionStatement: '/commission-statement',
  portfolioView: `/leases/:id${UUIDRegex}`,
  portfolioStatement: `/leases/:id${UUIDRegex}/statement`,
  portfolioEdit: '/leases/:id/edit',
  editProperty: '/leases/:id/property/edit',
  portfolios: '/leases',
  adhocInvoice: '/invoices/new',
  invoices: '/invoices',
  invoiceView: '/invoices/:type/:id?',
  payments: '/payments',
  newContact: '/contacts/new',
  editContact: `/contacts/:id${UUIDRegex}/edit`,
  contactStatement: `/contacts/:id${UUIDRegex}/:accountId${UUIDRegex}/statement`,
  customerDepositStatement: `/contacts/:id${UUIDRegex}/:accountId${UUIDRegex}/deposit-statement`,
  contactView: `/contacts/:id${UUIDRegex}?`,
  contacts: '/contacts',
  settings: '/settings',
  segments: '/settings/segments',
  segmentView: '/settings/segments/:id',
  inviteUser: '/users/invite',
  badReferences: '/admin/bad-references',
  badReferencesView: '/admin/bad-references/:id',
  walletTransfer: '/admin/wallet-transfer',
  agencies: '/admin/agencies',
  adminReporting: '/admin/reporting',
  importInvoices: '/import-invoices',
  importInvoiceView: '/import-invoices/:id',
  properties: '/properties',
  communications: '/communications/email',
  emailLog: 'communications/email',
  integrations: '/integrations',
  integrationRedirect: '/integrations/:id',
  reporting: '/reporting',
  importContacts: '/import-contacts',
  enableUser: '/enable-user',
}

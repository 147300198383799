import React from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import SupportStaffProvider from '../../SupportStaffProvider'

const propTypes = {
  isValidSupportStaff: PropTypes.bool.isRequired,
  openAgencySelectionModal: PropTypes.func.isRequired,
}

const SupportStaffButton = ({ isValidSupportStaff, openAgencySelectionModal }: any) => {
  return !isValidSupportStaff ? null : (
    <button id="support-staff-button" onClick={openAgencySelectionModal}>
      Support Login
    </button>
  )
}

SupportStaffButton.propTypes = propTypes

export default subscribe([SupportStaffProvider], ({ isValidSupportStaff, openAgencySelectionModal }: any) => ({
  isValidSupportStaff,
  openAgencySelectionModal,
}))(SupportStaffButton)

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { onboardingSelectors } from '.'
import { uiEvents } from '../ui'

const OnboardingProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const getStartedSteps = useSelector(onboardingSelectors.getStartedSteps)

  const store = {
    getStartedSteps,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    ...props,
  }

  return <context.Provider value={store}>{children}</context.Provider>
})

export default OnboardingProvider

import { createChatbotDialog } from './ChatbotDialog'
import { createSelector } from 'reselect'
import { mergeDeepRight, pathOr } from 'ramda'
import { state, getLeaseCommissionDialog } from '../selectors'
import { partyApiSelectors } from '../../../api/party'
import { chatbotEvents } from '../state'
import { portfolioApiEvents, portfolioApiConstants, portfolioApiSelectors } from '../../../api/portfolio'
import { getRentAmount } from './LeaseRentAndFees'
import { $TSFixMe } from 'types/ts-migrate'
import { agencyApiSelectors } from 'modules/api/agency'
import { chatbotSelectors } from '..'

const { commissionUndertakings } = portfolioApiConstants

const initialState = {
  managementFee: {
    type: commissionUndertakings.variable,
    monthlyRentAmount: '',
    netAmount: {
      value: '',
      vatable: false,
    },
    percentage: {
      value: '',
      vatable: false,
    },
    splits: [],
  },
  procurementFee: {
    type: commissionUndertakings.variable,
    monthlyRentAmount: '',
    netAmount: {
      value: '',
      vatable: false,
    },
    percentage: {
      value: '',
      vatable: false,
    },
    splits: [],
  },
  id: null,
}

const commissionReducer = (commission: $TSFixMe) => {
  const managementFee = mergeDeepRight(initialState.managementFee, pathOr({}, ['managementFee'], commission))
  const procurementFee = mergeDeepRight(initialState.procurementFee, pathOr({}, ['procurementFee'], commission))

  return {
    $merge: {
      managementFee,
      procurementFee,
    },
  }
}

export const { leaseCommissionDialog } = createChatbotDialog('leaseCommission')
  .withInitialState(initialState)
  .withEvents({
    commissionSaved: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any, state: $TSFixMe) => {
      const managementFee = mergeDeepRight(
        initialState.managementFee,
        pathOr({}, ['managementFee'], payload?.commission),
      )
      const procurementFee = mergeDeepRight(
        initialState.procurementFee,
        pathOr({}, ['procurementFee'], payload?.commission),
      )

      return {
        $merge: {
          managementFee: {
            ...managementFee,
            netAmount: {
              ...managementFee.netAmount,
              vatable: state?.managementFee?.netAmount?.vatable || initialState.managementFee.netAmount.vatable,
            },
            percentage: {
              ...managementFee.percentage,
              vatable: state?.managementFee?.percentage?.vatable || initialState.managementFee.percentage.vatable,
            },
          },
          procurementFee: {
            ...procurementFee,
            netAmount: {
              ...procurementFee.netAmount,
              vatable: state?.procurementFee?.netAmount?.vatable || initialState.procurementFee.netAmount.vatable,
            },
            percentage: {
              ...procurementFee.percentage,
              vatable: state?.procurementFee?.percentage?.vatable || initialState.procurementFee.percentage.vatable,
            },
          },
        },
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendCommission_success]: ({ payload }) => commissionReducer(payload.commission),
    // @ts-expect-error
    // [chatbotEvents.resetCurrentPortfolio]: () => ({ $merge: initialState }),
    [chatbotEvents.resetCurrentPortfolio]: (action: any, state: any) => ({
      $merge: {
        ...initialState,
        managementFee: {
          ...initialState.managementFee,
          netAmount: {
            ...initialState.managementFee.netAmount,
            vatable: state?.managementFee?.netAmount?.vatable || initialState.managementFee.netAmount.vatable,
          },
          percentage: {
            ...initialState.managementFee.percentage,
            vatable: state?.managementFee?.percentage?.vatable || initialState.managementFee.percentage.vatable,
          },
        },
        procurementFee: {
          ...initialState.procurementFee,
          netAmount: {
            ...initialState.procurementFee.netAmount,
            vatable: state?.procurementFee?.netAmount?.vatable || initialState.procurementFee.netAmount.vatable,
          },
          percentage: {
            ...initialState.procurementFee.percentage,
            vatable: state?.procurementFee?.percentage?.vatable || initialState.procurementFee.percentage.vatable,
          },
        },
      },
    }),
  })
  .when('commissionSaved', ({ payload }: any) => ({
    $merge: {
      ...payload,
    },
  }))
  .setup()

export const getCommissionInitialValues = createSelector(
  state,
  getLeaseCommissionDialog,
  (s, { managementFee, procurementFee }) => {
    const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(s)
    const portfolioCommission = portfolioApiSelectors.getCommissionByPortfolioId(s)(currentPortfolioId)

    const globalVat = agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(s)

    const monthlyRentAmount = getRentAmount(s)
    /** @todo this could be a reusable selector. Also used elsewhere */
    const shapeSplits = (splits: $TSFixMe) => {
      return splits.map((split: $TSFixMe) => {
        const { agentPartyId, splitPercentage } = split
        return {
          agent: {
            label: partyApiSelectors.getPartyNameById(s)(agentPartyId),
            value: agentPartyId,
          },
          splitPercentage,
        }
      })
    }

    return {
      managementFee: {
        ...managementFee,
        netAmount: {
          ...managementFee.netAmount,
          vatable:
            typeof portfolioCommission?.managementFee?.netAmount?.vatable !== 'undefined'
              ? portfolioCommission?.managementFee?.netAmount?.vatable
              : globalVat,
        },
        percentage: {
          ...managementFee.percentage,
          vatable:
            typeof portfolioCommission?.managementFee?.percentage?.vatable !== 'undefined'
              ? portfolioCommission?.managementFee?.percentage?.vatable
              : globalVat,
        },
        monthlyRentAmount,
        splits: shapeSplits(managementFee.splits),
      },
      procurementFee: {
        ...procurementFee,
        netAmount: {
          ...procurementFee.netAmount,
          vatable:
            typeof portfolioCommission?.procurementFee?.netAmount?.vatable !== 'undefined'
              ? portfolioCommission?.procurementFee?.netAmount?.vatable
              : globalVat,
        },
        percentage: {
          ...procurementFee.percentage,
          vatable:
            typeof portfolioCommission?.procurementFee?.percentage?.vatable !== 'undefined'
              ? portfolioCommission?.procurementFee?.percentage?.vatable
              : globalVat,
        },
        monthlyRentAmount,
        splits: shapeSplits(procurementFee.splits),
      },
    }
  },
)

export const getManagementFee = createSelector(getLeaseCommissionDialog, ({ managementFee }) => managementFee)

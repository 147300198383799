import { connect } from 'react-redux'
import { get, memoize } from 'lodash-es'
import { invoiceApiEvents, invoiceApiSelectors } from '../../../modules/api/invoice'
import { partyApiEvents, partyApiSelectors } from '../../../modules/api/party'
import { reconApiConstants, reconApiEvents, reconApiSelectors } from '../../../modules/api/recon'
import { uiEvents } from '../../../modules/ui'
import { uiInvoiceEvents, uiInvoiceSelectors } from '../../../modules/ui/invoices'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../modules/api/portfolio'
import { InvoicesProvider } from '../../providers'
import { layoutEvents } from '../../../modules/Layout/state'
import { agencyApiSelectors } from '../../../modules/api/agency'
import { userApiSelectors } from '../../../modules/api/user'
import queryString from 'query-string'
import { apiStateSelectors } from '../../../modules/api/apiState'
import { mapObjIndexed, path, pipe, values } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { bankingApiEvents, bankingApiSelectors } from 'modules/api/banking'

const mapStateToProps = (state: any, ownProps: any) => {
  const { match } = ownProps
  const invoiceType = memoize(() => get(match, 'params.type'))()
  const viewingInvoice = uiInvoiceSelectors.getCurrentInvoice(state)
  const params = memoize(() => match.params)()
  const currentInvoice =
    params.type === 'active' || params.type === 'history'
      ? reconApiSelectors.getInvoiceById(state)(viewingInvoice)
      : invoiceApiSelectors.getInvoiceById(state)(viewingInvoice)
  const invoiceTypes = invoiceApiSelectors.getInvoiceTypes(state)
  const editInvoiceInitialValues = currentInvoice
    ? {
        customer: currentInvoice.customer,
        deliverAutomatically: false /** @todo add to backend */,
        amount: currentInvoice.amount,
        invoiceType: currentInvoice.invoiceType,
        beneficiaries: currentInvoice.beneficiaries,
      }
    : {
        customer: '',
        deliverAutomatically: false,
        amount: '',
        invoiceType: '',
        beneficiaries: [],
      }

  const onceOffInvoiceInitialValues = {
    customer: '',
    invoiceType: '',
    dueDate: '',
    vat: false,
    amount: '',
    beneficiaries: [],
  }

  const propertyOwnerId = portfolioApiSelectors.getPrimaryLandlordByPortfolioId(state)(currentInvoice?.portfolioId)
  const propertyOwnerName = propertyOwnerId ? partyApiSelectors.getPartyNameById(state)(propertyOwnerId) : null
  const propertyOwnerBankDetails = propertyOwnerId
    ? partyApiSelectors.getPartyBankDetails(state)(propertyOwnerId)
    : null

  const animations = uiInvoiceSelectors.animations(state)

  return {
    // General
    invoiceType,
    params,
    partyTags: partyApiSelectors.getPartyTags(state),
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
    isReadOnly: userApiSelectors.isReadOnlyRole(state),
    currentAgencyId: userApiSelectors.getCurrentAgencyId(state),

    getOwnerAccountFromDraftInvoiceId: (invoiceId: string) => {
      const invoice = invoiceApiSelectors.getInvoiceById(state)(invoiceId)
      const ownerId = portfolioApiSelectors.getPrimaryLandlordByPortfolioId(state)(invoice.portfolioId)
      return partyApiSelectors.getPartyAccountByPortfolioId(state)(ownerId, invoice.portfolioId, 'Owner')
    },

    getPartyAccount: (partyId: string, portfolioId: string, preferredTag: string) => {
      return partyApiSelectors.getPartyAccountByPortfolioId(state)(partyId, portfolioId, preferredTag)
    },

    getOwnerAccountFromActiveInvoiceId: (invoiceId: string) => {
      const invoice = reconApiSelectors.getInvoiceById(state)(invoiceId)
      const ownerId = portfolioApiSelectors.getPrimaryLandlordByPortfolioId(state)(invoice.portfolioId)
      return partyApiSelectors.getPartyAccountByPortfolioId(state)(ownerId, invoice.portfolioId, 'Owner')
    },

    // EasyPay
    easyPayData: reconApiSelectors.getEasyPayData(state),

    animations,

    getInvoiceTypeByValue: (value: any) => invoiceApiSelectors.getInvoiceTypeByValue(state)(value),

    // Draft Invoices
    isDraftsLoading: apiStateSelectors.isLoading(state)([invoiceApiEvents.invoices_request]),
    isDraftInvoiceViewLoading: apiStateSelectors.isLoading(state)([invoiceApiEvents.invoice_request]),
    isSaveAndSendSubmitting: apiStateSelectors.isLoading(state)([invoiceApiEvents.sendInvoices_request]),
    isSaveSubmitting: apiStateSelectors.isLoading(state)([invoiceApiEvents.invoiceUpdate_request]),
    isDraftRemoved: invoiceApiSelectors.isDraftRemoved(state)(viewingInvoice),
    draftInvoiceFilters: uiInvoiceSelectors.draftInvoiceFilters(state),
    draftInvoicesCount: invoiceApiSelectors.getDraftInvoicesCount(state),
    draftInvoicesInSetMode: uiInvoiceSelectors.getDraftInvoicesInSetMode(state),
    failedSendInvoices: animations?.bulkSendErrors,
    failedDeleteInvoices: pipe(
      mapObjIndexed((errors: any, key: any) => {
        const parsedKey = JSON.parse(key)
        const invoiceIds = path(['invoiceIds'], parsedKey)
        return { invoiceIds, errors }
      }),
      values,
      // @ts-expect-error
    )(apiStateSelectors.getErrorsByEvent(state)(invoiceApiEvents.deleteInvoices_request)),

    // Active invoices
    isActiveLoading: apiStateSelectors.isLoading(state)([reconApiEvents.invoices_request]),
    isActiveInvoiceViewLoading: apiStateSelectors.isLoading(state)([reconApiEvents.invoice_request]),
    activeInvoiceFilters: uiInvoiceSelectors.activeInvoiceFilters(state),
    getActiveInvoiceSelectionBalance: (ids: any) => reconApiSelectors.getInvoiceSelectionTotal(state)(ids),
    getFilteredActiveInvoices: (query: any) => reconApiSelectors.getFilteredInvoices(state)(query),
    canInvoiceBeNudged: (invoiceId: any) => uiInvoiceSelectors.canInvoiceBeNudged(state)(invoiceId),
    getLastNudgeTime: (invoiceId: any) => uiInvoiceSelectors.getLastNudgeTime(state)(invoiceId),
    activeInvoicesCount: reconApiSelectors.getInvoicesCount(state),
    getFilteredDraftInvoices: (query: any) => invoiceApiSelectors.getFilteredInvoices(state)(query),
    getInvoiceBeneficiariesForPaymentRequest: (invoiceId: any) =>
      reconApiSelectors.getInvoiceBeneficiariesForPaymentRequest(state)(invoiceId),
    failedPaymentApprovals: pipe(
      mapObjIndexed((errors: any, key: any) => {
        const parsedKey = JSON.parse(key)
        const invoiceId = path(['params', 'invoiceId'], parsedKey)
        return { invoiceId, errors }
      }),
      values,
      // @ts-expect-error
    )(apiStateSelectors.getErrorsByEvent(state)(reconApiEvents.payment_request)),
    getInvoiceBeneficiaries: (id: any) => reconApiSelectors.getInvoiceParties(state)(id),

    // History
    isHistoryLoading: apiStateSelectors.isLoading(state)([reconApiEvents.archived_request]),
    isHistoryInvoiceViewLoading: apiStateSelectors.isLoading(state)([reconApiEvents.invoice_request]),
    historicInvoices: reconApiSelectors.getDoneInvoices(state),
    archivedPeriod: reconApiSelectors.getArchivedPeriod(state),

    // Payment validation
    getBankingPartyById: (id: any) => bankingApiSelectors.getBankingPartyById(state)(id),
    isPaymentValidating: apiStateSelectors.isLoading(state)([bankingApiEvents.partiesErrors_request]),
    getBankingValidationErrorsByPartyId: (partyId: any) =>
      path(['errors'], bankingApiSelectors.getBankingPartyById(state)(partyId)),

    // General
    agencyBankDetails: agencyApiSelectors.getCurrentAgencyBankDetails(state),
    getPartyById: (id: string) => partyApiSelectors.getPartyById(state)(id),
    getPartyBankDetails: (id: string) => partyApiSelectors.getPartyBankDetails(state)(id),
    getPartyDetailsById: (id: any) => partyApiSelectors.getPartyDetailsById(state)(id),
    getPartyNameById: (id: any) => partyApiSelectors.getPartyNameById(state)(id),
    getPartyEmailById: (id: any) => partyApiSelectors.getPartyEmailById(state)(id),
    getPartyTelNumberById: (id: any) => partyApiSelectors.getPartyTelNumberById(state)(id),
    getCustomerSummary: (customerId: any, accountId: any) =>
      reconApiSelectors.getCustomerSummary(state)(customerId, accountId),
    getCustomerActiveInvoices: (customerId: any, accountId: any) =>
      reconApiSelectors.getCustomerActiveInvoices(state)(customerId, accountId),
    getCustomerRecentActivity: (customerId: any, accountId: any) =>
      reconApiSelectors.getCustomerRecentActivity(state)(customerId, accountId),
    getCustomerActiveInvoicesTotal: (customerId: any, accountId: any) =>
      reconApiSelectors.getCustomerActiveInvoicesTotal(state)(customerId, accountId),
    userRole: userApiSelectors.getUserRole(state),
    getGeneralFormErrorsByEvent: apiStateSelectors.getGeneralFormErrorsByEvent(state),
    getFormFieldErrorsByEvent: apiStateSelectors.getFormFieldErrorsByEvent(state),
    getCurrentAgencyGlobalVatEnabled: agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state),

    // UI
    isNewInvoiceModalOpen: uiInvoiceSelectors.isNewInvoiceModalOpen(state),
    trySubmitNewInvoice: uiInvoiceSelectors.trySubmitNewInvoice(state),
    viewingInvoice,
    editingInvoice: uiInvoiceSelectors.isEditingInvoice(state),
    currentInvoice,
    editInvoiceInitialValues,
    onceOffInvoiceInitialValues,
    invoiceTypes,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  // UI
  redirect: (route: any) => dispatch(uiEvents.redirect(route)),

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  toggleNewInvoiceModal: () => dispatch(uiInvoiceEvents.toggleNewModal()),
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  toggleEdit: () => dispatch(uiInvoiceEvents.toggleEdit()),

  openInvoice: (invoiceId: string, type: string, redirect = true) => {
    const { portfolioId }: $TSFixMe = queryString.parse(queryString.extract(window.location.href))

    dispatch(uiInvoiceEvents.invoiceOpened({ type, invoiceId }))
    dispatch(layoutEvents.openPrimaryPanel('invoices'))
    dispatch(layoutEvents.closeSidebar('global'))
    if (redirect) {
      dispatch(
        uiEvents.redirect(
          `/invoices/${type}/${invoiceId}${portfolioId ? `?portfolioId=${portfolioId as string}` : ''}`,
        ),
      )
    }
  },

  closeInvoice: (id: string, type: string, redirect = true) => {
    const { portfolioId }: $TSFixMe = queryString.parse(queryString.extract(window.location.href))

    dispatch(uiInvoiceEvents.closeInvoice({ id }))
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(uiInvoiceEvents.resetDraftSetMode())
    dispatch(layoutEvents.openSidebar('global'))
    dispatch(layoutEvents.closePrimaryPanel('invoices'))
    dispatch(layoutEvents.closeSecondaryPanel('invoices'))
    if (redirect) {
      dispatch(uiEvents.redirect(`/invoices/${type}${portfolioId ? `?portfolioId=${portfolioId as string}` : ''}`))
    }
  },

  // Open Contact
  openContact: (id: string) => {
    dispatch(uiInvoiceEvents.closeInvoice({ id }))
    dispatch(layoutEvents.closePrimaryPanel('invoices'))
    dispatch(layoutEvents.closeSecondaryPanel('invoices'))
    dispatch(
      layoutEvents.init({
        key: 'contacts',
        initialState: {
          sidebar: { open: false, width: 0 },
          primaryPanel: { open: false, width: 600 },
          secondaryPanel: { open: false, width: 375 },
        },
      }),
    )
    dispatch(partyApiEvents.party_request(id))
    dispatch(uiEvents.redirect(`/contacts/${id}`))
    dispatch(layoutEvents.openPrimaryPanel('contacts'))
    dispatch(layoutEvents.closeSidebar('global'))
  },

  // EasyPay
  fetchEasyPayData: (reference: any) => dispatch(reconApiEvents.easypayData_request({ reference })),

  // Draft Invoices
  getInvoices: () => dispatch(invoiceApiEvents.invoices_request({ tags: '' })),

  enterDraftSetMode: (ids: any) => dispatch(uiInvoiceEvents.enterDraftSetMode(ids)),
  removeFromDraftSetMode: (id: any) => dispatch(uiInvoiceEvents.removeFromDraftSetMode(id)),
  bulkActionDelete: (ids: any) => dispatch(uiInvoiceEvents.bulkActionDelete(ids)),
  bulkActionRestore: (ids: any) => dispatch(uiInvoiceEvents.bulkActionRestore(ids)),
  bulkActionSend: (ids: any) => dispatch(uiInvoiceEvents.bulkActionSend(ids)),
  updateInvoice: (id: any, values: any) => dispatch(invoiceApiEvents.invoiceUpdate_request({ id, values })),
  sendInvoices: (ids: any) => dispatch(invoiceApiEvents.sendInvoices_request(ids)),
  deleteInvoices: (ids: any) => dispatch(invoiceApiEvents.deleteInvoices_request(ids)),
  restoreInvoices: (ids: any) => dispatch(invoiceApiEvents.restoreInvoices_request(ids)),
  saveAndSendInvoice: (id: any, values: any) => dispatch(uiInvoiceEvents.saveAndSendInvoice({ id, values })),

  // Active Invoices
  fetchInvoiceTypes: () => dispatch(invoiceApiEvents.invoiceTypes_request()),
  fetchCreditNoteReasons: () => dispatch(reconApiEvents.creditNoteReasons_request()),

  fetchReconInvoices: () => {
    reconApiConstants.invoiceStatuses.forEach(s => {
      dispatch(reconApiEvents.invoices_request({ tags: s }))
    })
  },

  fetchInvoiceActivity: (customerId: any, accountId: any) =>
    dispatch(reconApiEvents.customerAccountActivity_request({ customerId, accountId })),
  fetchInvoiceParty: (customerId: any) => dispatch(partyApiEvents.party_request(customerId)),
  createPayment: (invoiceId: any, beneficiaries: any) =>
    dispatch(reconApiEvents.payment_request({ body: beneficiaries, params: { invoiceId } })),
  nudge: (invoiceIds: any) => dispatch(reconApiEvents.invoicesNudge_request({ invoiceIds })),
  updateBeneficiaries: (invoiceId: any, beneficiaries: any) =>
    dispatch(reconApiEvents.updateBenficiaries_request({ body: beneficiaries, params: { invoiceId } })),
  bulkActionsApprovePayments: (ids: any) => dispatch(uiInvoiceEvents.bulkActionApprovePayments(ids)),
  bulkActionNudgeInvoices: (ids: any) => dispatch(uiInvoiceEvents.bulkActionNudgeInvoices(ids)),

  // History
  fetchHistoricInvoices: (startDate: any, endDate: any) => {
    dispatch(reconApiEvents.archived_request({ startDate, endDate }))
  },

  // Portfolio
  fetchPortfolio: (id: any) => dispatch(portfolioApiEvents.portfolio_request(id)),

  // Payment validation
  fetchPartyErrors: (partyId: any) => dispatch(bankingApiEvents.partyErrors_request({ partyId })),

  fetchPartiesErrors: (partyIds: any) => dispatch(bankingApiEvents.partiesErrors_request({ party_ids: partyIds })),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesProvider)

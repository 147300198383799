import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { portfolioApiSelectors } from '../../api/portfolio'
import Label, { LabelProps } from '../../../views/components/atoms/Label/Label'
import { $TSFixMe } from 'types/ts-migrate'

type Status = 'renewal' | 'expired' | 'terminated' | 'deleted' | 'expiring' | 'active' | 'draft'

type PortfolioStatusLabelProps<C extends React.ElementType> = {
  className?: string
  portfolioId?: string
  status?: Status
  statusPrefix?: string
  statusSuffix?: string
  useSummary?: boolean
  labelProps?: LabelProps<C>
}

const PortfolioStatusLabel = ({
  className,
  portfolioId,
  status,
  statusPrefix,
  statusSuffix,
  useSummary = false,
  labelProps,
}: PortfolioStatusLabelProps<$TSFixMe>) => {
  const portfolioStatus: $TSFixMe = useSelector(state =>
    portfolioApiSelectors.getPortfolioStatusLabel(state)(portfolioId, useSummary),
  )

  const statusLabel = status || portfolioStatus

  const backgroundColor = useMemo(() => {
    switch (statusLabel.toLowerCase()) {
      case 'renewal':
        return '#00B663'
      case 'expired':
      case 'terminated':
      case 'deleted':
        return '#b10012'
      case 'expiring':
        return '#eb0018'
      case 'active':
        return '#35383b'
      case 'draft':
      default:
        return '#c62482'
    }
  }, [statusLabel])

  const style = {
    color: 'white',
    opacity: '.64',
    backgroundColor,
  }

  return (
    statusLabel && (
      <Label circular style={style} className={className} {...labelProps}>
        {statusPrefix && `${statusPrefix} `}
        {statusLabel}
        {statusSuffix && ` ${statusSuffix}`}
      </Label>
    )
  )
}

export default PortfolioStatusLabel

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '../../../../views/components/atoms/Button/Button'
import styles from './BankAccount.module.scss'

const propTypes = {
  className: PropTypes.string,
  balance: PropTypes.string.isRequired,
  viewStatement: PropTypes.func.isRequired,
}

const BankAccount = ({ className, balance, viewStatement, disabled }: any) => {
  const classes = cx([styles.root, className])

  return (
    <section className={classes}>
      <header>
        <div className={styles.amounts}>
          <h4 className={styles.title}>Transactional Account</h4>
          <p>
            <strong>{balance}</strong>
          </p>
        </div>
        <div className={styles.actions}>
          <Button disabled={disabled || !balance} onClick={viewStatement}>
            View Statement
          </Button>
        </div>
      </header>
    </section>
  )
}

BankAccount.propTypes = propTypes

export default BankAccount

import React from 'react'
import { animated, useSpring } from 'react-spring'
import styles from './Sidebar.module.scss'
import { useLayout } from '..'
import { animationConfig } from 'views/constants'

function Sidebar({ children, isStTablet }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type '{}'.
  const { sidebar, primaryPanel, secondaryPanel } = useLayout()
  const style = useSpring({
    reverse: sidebar.open && !primaryPanel.open && !secondaryPanel.isOpen,
    config: animationConfig,
    to: { transform: `translateX(-${sidebar.width}px)` },
    from: { transform: 'translateX(0px)' },
  })

  return (
    <animated.div style={{ height: '100%', ...style }}>
      <div className={styles.root}>{children}</div>
    </animated.div>
  )
}

export default Sidebar

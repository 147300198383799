import React from 'react'
// import { subscribe } from 'react-contextual'
import { ListingTemplate } from '../../../views/components'
import ReportingTable from './ReportingTable/ReportingTable'

const ReportingContent = () => {
  return (
    <ListingTemplate
      loading={false}
      /** @todo replace temp height with better spacing solution */
      Header={<div style={{ minHeight: '71px' }}></div>}
      Body={<ReportingTable />}
    />
  )
}

// export default subscribe(
//   [ReportingProvider], ({
//     // isLoading
//   }) => ({
//     // isLoading
//   })
// )(ReportingContent)

export default ReportingContent

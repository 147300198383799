import React from 'react'

const SvgDocumentDownload = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M17 16h3l-4 4-4-4h3v-4h2v4zm2-8H9v16h14V12h-4V8zM7 6.992C7 6.444 7.447 6 7.999 6H20l5 5v13.993A1 1 0 0124.007 26H7.993A1 1 0 017 25.008V6.992z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgDocumentDownload

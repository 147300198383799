import React from 'react'
import PropTypes from 'prop-types'

const RevokedInvitation = ({ adminEmail }: any) => {
  return (
    <div>
      <h2>Invitation Revoked</h2>
      <p>Your invite was revoked. Please contact admin ({adminEmail}) to request another invite.</p>
    </div>
  )
}

RevokedInvitation.propTypes = {
  adminEmail: PropTypes.string.isRequired,
}

export default RevokedInvitation

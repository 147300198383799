import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { subscribe } from 'react-contextual'
import OnboardingProvider from '../../OnboardingProvider'
import styles from './GetStarted.module.scss'
import ControlledTooltip from '../../../../views/components/atoms/Tooltip/ControlledTooltip'

const propTypes = {
  redirect: PropTypes.func,
  getStartedSteps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      route: PropTypes.string,
      complete: PropTypes.bool,
    }),
  ),
}

const GetStarted = ({ redirect, getStartedSteps }: any) => {
  const dispatch = useDispatch()

  return (
    <div className={styles.root}>
      <h2>Get Started</h2>
      <p>Follow these four easy steps and we’ll have you up and running in just a few minutes.</p>

      {getStartedSteps.length > 0 && (
        <ol className={styles.steps}>
          {getStartedSteps.map((step: any, i: any) => {
            const handleClick = () => (step.isDisabled ? () => {} : dispatch(redirect(step.route)))
            const body = (
              <div>
                <button onClick={handleClick} className={step.isDisabled ? styles.disabled : ''}>
                  <h3>{step.title}</h3>
                  {!step.complete && <p>{step.description}</p>}
                </button>
              </div>
            )

            return (
              <li className={step.complete ? styles.complete : undefined} key={i}>
                {step.isDisabled ? (
                  // @ts-expect-error
                  <ControlledTooltip body="Please complete step 1 and 2." place="above">
                    {body}
                  </ControlledTooltip>
                ) : (
                  body
                )}
              </li>
            )
          })}
        </ol>
      )}
    </div>
  )
}

GetStarted.propTypes = propTypes

export default subscribe(OnboardingProvider, ({ redirect, getStartedSteps }: any) => ({
  redirect,
  getStartedSteps,
}))(GetStarted)

import React, { forwardRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { $TSFixMe } from 'types/ts-migrate'
import styles from './PercentageInput.module.scss'
import classNames from 'classnames'
import ControlledTooltip from '../Tooltip/ControlledTooltip'
import { isNil } from 'ramda'

type Props = {
  id?: string
  className?: string
  disabled?: boolean
  name?: string
  value?: number | string
  placeholder?: string
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  autoComplete?: 'on' | 'off'
  allowNegative?: boolean
  inputTooltipText?: string
  onLeadingIconClick?: (e: $TSFixMe) => void
  onTrailingIconClick?: (e: $TSFixMe) => void
  onChange?: (e: $TSFixMe) => void
  onBlur?: (e: $TSFixMe) => void
  onFocus?: (e: $TSFixMe) => void
}

export type Ref = HTMLInputElement

const PercentageInput = forwardRef<Ref, Props>((props, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFocus = (e: $TSFixMe) => {
    setIsFocused(true)
    if (typeof props.onFocus === 'function') {
      props.onFocus(e)
    }
  }

  const handleBlur = (e: $TSFixMe) => {
    setIsFocused(false)
    if (typeof props.onBlur === 'function') {
      props.onBlur(e)
    }
  }

  const handleTrailingIconClick = (e: $TSFixMe) => {
    if (typeof props.onTrailingIconClick === 'function') {
      props.onTrailingIconClick(e)
      inputRef?.current?.focus()
    }
  }

  const classes = classNames(styles.root, {
    [styles['is-focused']]: isFocused,
    [styles['has-trailing-icon']]: props.trailingIcon,
    [styles['trailing-icon-interactive']]: props.onTrailingIconClick,
  })

  return !props.trailingIcon ? (
    <div className={classes}>
      <NumericFormat
        onValueChange={props.onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
        placeholder="0%"
        suffix="%"
        decimalScale={2}
        allowNegative={props.allowNegative}
      />
    </div>
  ) : (
    <ControlledTooltip
      body={props.inputTooltipText}
      toggleOnFocus={true}
      isFocused={!isNil(props.inputTooltipText) && isFocused}
    >
      <div className={classes}>
        <div className={styles['input-container']}>
          <NumericFormat
            onValueChange={props.onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={props.value}
            placeholder="0%"
            suffix="%"
            decimalScale={2}
            allowNegative={props.allowNegative}
          />
          {props.trailingIcon && (
            <i className={`${styles.icon} ${styles['icon-trailing']}`} onClick={handleTrailingIconClick}>
              {props.trailingIcon}
            </i>
          )}
        </div>
      </div>
    </ControlledTooltip>
  )
})

PercentageInput.displayName = 'PercentageInput'

export default PercentageInput

import { isEmpty } from 'ramda'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { apiStateSelectors } from '../../../../modules/api/apiState'
import { userApiEvents } from '../../../../modules/api/user'
import { uiEvents } from '../../../../modules/ui/state'
import { NoContent } from '../../../components'
import styles from './VerifyEmail.module.scss'

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      verifyId: PropTypes.string,
    }),
  }),
}

const VerifyEmail = ({ match }: any) => {
  const dispatch = useDispatch()
  const { userId, verifyId } = match.params

  const generalErrors = useSelector(state =>
    apiStateSelectors.getGeneralFormErrorsByEvent(state)(userApiEvents.verifyEmail_request({ id: userId, verifyId })),
  )

  const verifyEmail = useCallback(() => {
    userId && verifyId && dispatch(userApiEvents.verifyEmail_request({ id: userId, verifyId }))
  }, [userId, verifyId, dispatch])

  useEffect(() => {
    if (!isEmpty(generalErrors)) {
      dispatch(uiEvents.redirect(`/login?error=${generalErrors[0]}`))
    }
  }, [generalErrors, dispatch])

  useEffectOnce(() => {
    verifyEmail()
  })

  return (
    <div className={styles.root}>
      <NoContent
        heading="Verifying your email address..."
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
        subHeading={
          <>
            You will be redirected shortly!{' '}
            <button
              onClick={e => {
                e.preventDefault()
                dispatch(
                  uiEvents.redirect(
                    '/login?error=Email Verification failed. Please login and re-send email verification from your profile.',
                  ),
                )
              }}
            >
              Click here if nothing happens.
            </button>
          </>
        }
      />
    </div>
  )
}

VerifyEmail.propTypes = propTypes

export default VerifyEmail

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { currencyUtils } from '../../../../utils'
import { AggregateTable, VatIcon } from '../../../../views/components'
import { uiEvents } from '../../../ui'
import styles from './BulkImportInvoicesTable.module.scss'
import { customPropTypes } from '../../../../views/utils'
import { invoiceApiSelectors } from '../../../api/invoice'

const BulkImportInvoicesTable = ({ id, data = [] }: any): React.ReactElement => {
  const dispatch = useDispatch()

  const columns = [
    {
      Header: 'Invoice to',
      accessor: 'invoiceTo',
    },
    {
      Header: 'Invoice Type',
      accessor: 'invoiceType',
    },
    {
      Header: 'Property',
      accessor: 'property',
    },
    {
      Header: 'VAT',
      accessor: 'vat',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
  ]

  const handleRowClick = useCallback(
    ({ id }: { id: string }) => {
      dispatch(uiEvents.redirect(`/import-invoices/${id}`))
    },
    [dispatch],
  )

  const getInvoiceTypeNameByValue = useSelector(invoiceApiSelectors.getInvoiceTypeNameByValue)

  const transformData = useCallback(() => {
    return data.map(invoice => ({
      id: invoice.id,
      invoiceTo: invoice?.customerName,
      invoiceType: getInvoiceTypeNameByValue(invoice?.invoiceType),
      amount: currencyUtils.formatCurrency(invoice?.amount),
      vat: <VatIcon enabled={invoice?.vat} />,
      property: invoice?.propertyAddress,
      highlighted: id === invoice.id,
    }))
  }, [data, id, getInvoiceTypeNameByValue])

  return (
    <div className={styles.root}>
      <AggregateTable rowHeight={80} columns={columns} data={transformData()} onRowClick={handleRowClick} />
    </div>
  )
}

BulkImportInvoicesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  id: customPropTypes.uuid,
}

export default BulkImportInvoicesTable

import { pick } from 'lodash-es'

export const makeClasses = (propsArray: any) => {
  return propsArray
    .map((item: any, i: any) => {
      if (i === 0) {
        return item || ''
      }
      return item ? '-' + item : ''
    })
    .join('')
}

export const removeLoadingScreen = () => {
  // Don't cache loadingScreen (assign to variable) to prevent hot module reload errors
  if (document.getElementById('loadingScreen') !== null) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    document.getElementById('loadingScreen').parentNode.removeChild(document.getElementById('loadingScreen'))
  }
}

export const filterObjectKeys = (keys: any, object: any) => {
  const filteredKeys = Object.keys(object).filter(p => keys.indexOf(p) < 0)
  return pick(object, filteredKeys)
}

export const getLastFourDigits = (number: string) => {
  return number.substring(number.length - 4, number.length)
}

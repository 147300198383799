import createSelector from 'selectorator'
import { NAMESPACE } from './constants'
import { RootState } from 'src/state/store'
import { TIntegration, TTpnConsumerEnquiryResponse, TTpnConsumerModule } from './types'
import { $TSFixMe } from 'types/ts-migrate'
import { identity } from 'ramda'

const getState = (state: RootState) => state

export const getSlice = (key: string): $TSFixMe => createSelector([`api.${NAMESPACE}.${key}`], identity)

export const getIntegrationsList = createSelector<RootState, TIntegration[]>([getState], (state: RootState) =>
  state.api[NAMESPACE].integrations.filter(
    integration => integration.type !== 'TPNConnect' && integration.type !== 'DigitalContracts',
  ),
)

export const getConsumerCanAccessCredex = createSelector<RootState, {}>(
  [getState],
  (state: RootState) => state.api[NAMESPACE].tpnCanUserAccessCredex,
)

export const getConsumerModulesList = createSelector<RootState, TTpnConsumerModule[]>(
  [getState],
  (state: RootState) => state.api[NAMESPACE].tpnConsumerModules,
)

export const getTpnConsumerEnquiry = getSlice('tpnConsumerEnquiry')

export const getConsumerEnquiryResponse = createSelector<RootState, TTpnConsumerEnquiryResponse>([
  getTpnConsumerEnquiry,
])

import React from 'react'
import { subscribe } from 'react-contextual'
import { ListingTemplate } from '../../../views/components'
import { useEffectOnce } from 'react-use'
import { useDispatch } from 'react-redux'
import { AgenciesSearchFilter, AgenciesTable } from '..'
import AgenciesProvider from '../AgenciesProvider'
import { agencyApiEvents } from '../../api/agency'
import { $TSFixMe } from 'types/ts-migrate'

function AgenciesContent({ isLoading }: $TSFixMe) {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(agencyApiEvents.agencyListing_request())
  })

  return <ListingTemplate loading={isLoading} Header={<AgenciesSearchFilter />} Body={<AgenciesTable />} />
}

export default subscribe([AgenciesProvider], ({ isLoading }: $TSFixMe) => ({
  isLoading,
}))(AgenciesContent)

import React, { useCallback } from 'react'
import { useSelector, useStore } from 'react-redux'
import { anyPass, equals, isEmpty, isNil, omit, reject } from 'ramda'
import { PartyForm } from '../../../../views/components'
import { contactsSelectors } from '../..'
import { subscribe } from 'react-contextual'
import ContactsProvider from '../../state/ContactsProvider'
import { partyApiEvents, partyApiSelectors } from '../../../api/party'
import { apiStateSelectors } from '../../../api/apiState'
import { $TSFixMe } from 'types/ts-migrate'
import { rejectObjectEmptyOrNullValues } from 'modules/api/party/utils'

const transformValues = (partyType: any) => (partyTypeindex: number, formValues: any) => {
  let values
  const isIdNumberEmpty = formValues.idNumber === ''
  if (partyType === 'person' && isIdNumberEmpty) {
    values = { person: omit(['partyType', 'idNumber'], { ...rejectObjectEmptyOrNullValues(formValues) }) }
  } else if (partyType === 'person') {
    values = { person: omit(['partyType'], { ...rejectObjectEmptyOrNullValues(formValues) }) }
  } else {
    // values = { company: omit(['partyType'], { ...rejectObjectEmptyOrNullValues(formValues) }) }
    values = { company: omit(['partyType'], { ...formValues }) }
  }
  return { id: formValues.id, values }
}

const EditContactForm = ({ currentParty, updateParty, goBack, isReadOnly }: $TSFixMe) => {
  const store = useStore()
  const state = store.getState()
  const initialValues = contactsSelectors.getInitialValuesForEdit(state)(currentParty.id)
  const currentPartyType = partyApiSelectors.getPartyTypeById(state)(currentParty.id)

  const onSubmit = useCallback(
    payload => {
      updateParty({ id: currentParty.id, values: payload.values })
      goBack()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentParty.id],
  )

  const isSubmitting = useSelector(state => apiStateSelectors.isLoading(state)([partyApiEvents.updateParty_request]))
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  const getGeneralErrors = (payload: any) => getGeneralFormErrorsByEvent(partyApiEvents.updateParty_request(payload))
  const getFieldErrors = (payload: any) => getFormFieldErrorsByEvent(partyApiEvents.updateParty_request(payload))

  return (
    <div>
      <PartyForm
        contactInitialValues={initialValues}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | "person" | "company" | undefined' is... Remove this comment to see the full error message
        partyType={currentPartyType}
        handleSubmit={onSubmit}
        isSubmitting={isSubmitting}
        getGeneralErrors={getGeneralErrors}
        getFieldErrors={getFieldErrors}
        transformValues={transformValues(currentPartyType)}
        allowSearchable={false}
        isDisabled={isReadOnly}
        isEditingContact={true}
      />
    </div>
  )
}

export default subscribe(ContactsProvider, ({ currentParty, updateParty, goBack, isReadOnly }: any) => ({
  currentParty,
  updateParty,
  goBack,
  isReadOnly,
}))(EditContactForm)

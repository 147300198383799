import React, { useMemo, useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { get } from 'lodash-es'
import { FocusMode, PortfolioPage, SidebarNav } from '../../../../views/components'
import PortfoliosProvider from '../../PortfoliosProvider'
import { useSelector } from 'react-redux'
import { portfolioApiSelectors } from '../../../api/portfolio'
import { portfolioSelectors } from '../../state'
import { getCurrentLeaseType } from 'modules/ui/ChatBot/Dialogs/LeaseTypeSelection'
import { pathOr, split, replace, isEmpty, trim } from 'ramda'

const PortfolioWizard = ({
  match,
  goBack,
  currentPortfolioId,
  redirect,
  setCurrentPortfolioId,
  resetCurrentPortfolio,
  pathname,
  PortfolioWizardSidebarItems,
  streetAddress,
  buildingAddress,
}: any) => {
  const url = get(match, 'url')
  const portfolioId = get(match, 'params.id')

  const leaseType = useSelector(getCurrentLeaseType)

  const getTitle = () => {
    const prefix = pathOr('', [0], split('Contract', leaseType))

    const propertyAddress = buildingAddress || streetAddress
    const title = portfolioId === 'new' ? 'New Lease' : !propertyAddress ? 'Edit Lease' : propertyAddress

    const isTitleEmpty = isEmpty(trim(replace(',', '', title)))

    return `${!isTitleEmpty && prefix ? prefix + ': ' : ''}${!isTitleEmpty ? title : ''}`
  }

  const isNewPartyModalOpen = useSelector(portfolioSelectors.isNewPartyModalOpen)

  useEffect(() => {
    if (portfolioId === 'new') {
      resetCurrentPortfolio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])

  const ContentComponent = useMemo(
    () =>
      function PortfolioContent() {
        return (
          <PortfolioPage
            url={url}
            portfolioId={portfolioId}
            currentPortfolioId={currentPortfolioId}
            redirectTo={redirect}
            setCurrentPortfolioId={setCurrentPortfolioId}
          />
          // eslint-disable-next-line react-hooks/exhaustive-deps
        )
      },
    [url, portfolioId, currentPortfolioId],
  )

  const isDraftPortfolio = useSelector(state => portfolioApiSelectors.isDraftPortfolio(state)(portfolioId))

  // eslint-disable-next-line react/display-name
  const SidebarComponent = useMemo(
    () =>
      function PortfolioSidebar() {
        return (
          <SidebarNav
            url={pathname}
            items={PortfolioWizardSidebarItems}
            onClick={redirect}
            isDraftPortfolio={isDraftPortfolio}
          />
        )
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, PortfolioWizardSidebarItems],
  )

  return useMemo(
    () => (
      <FocusMode
        isOpen
        title={getTitle()}
        ContentComponent={ContentComponent}
        SidebarComponent={SidebarComponent}
        onClose={() => {
          redirect(`/leases/${portfolioId}`)
        }}
        disableScroll={isNewPartyModalOpen}
      />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [getTitle, ContentComponent, SidebarComponent, portfolioId],
  )
}

export default subscribe(
  PortfoliosProvider,
  ({
    goBack,
    currentPortfolioId,
    redirect,
    setCurrentPortfolioId,
    resetCurrentPortfolio,
    pathname,
    PortfolioWizardSidebarItems,
    streetAddress,
    buildingAddress,
  }: any) => ({
    goBack,
    currentPortfolioId,
    redirect,
    setCurrentPortfolioId,
    resetCurrentPortfolio,
    pathname,
    PortfolioWizardSidebarItems,
    streetAddress,
    buildingAddress,
  }),
)(PortfolioWizard)

import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import cx from 'classnames'
import styles from './Statement.module.scss'

const propTypes = {
  context: PropTypes.shape({
    statement: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          timestamp: PropTypes.string,
          description: PropTypes.string,
          credit: PropTypes.string,
          balance: PropTypes.string,
        }),
      ),
      openingBalance: PropTypes.string,
      closingBalance: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
}

const defaultProps = {}

const Statement = ({
  context: {
    statement: { items, openingBalance, closingBalance, startDate, endDate },
  },
}: any) => {
  return (
    <div className={styles.table}>
      <div className={cx([styles.row, styles['row--head']])}>
        <div>Date</div>
        <div>Details</div>
        <div>Debit</div>
        <div>Credit</div>
        <div>Balance</div>
      </div>

      <div className={cx([styles.row, styles['row--balance']])}>
        <div>{startDate}</div>
        <div>Opening balance</div>
        <div>{openingBalance}</div>
      </div>

      {items.map((item: any, i: any) => (
        <div className={styles.row} key={i}>
          <div>{item.date.split(' ')[0]}</div>
          <div>{item.description}</div>
          <div>{pathOr('', ['debit'], item)}</div>
          <div>{pathOr('', ['credit'], item)}</div>
          <div>{item.balance}</div>
        </div>
      ))}

      <div className={cx([styles.row, styles['row--balance']])}>
        <div>{endDate}</div>
        <div>Closing balance</div>
        <div>{closingBalance}</div>
      </div>
    </div>
  )
}

Statement.propTypes = propTypes
Statement.defaultProps = defaultProps

export default Statement

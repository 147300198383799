import React from 'react'
import cx from 'classnames'
import { pick } from 'lodash-es'
import { childrenUtils, getElementType } from '../../../utils'
import styles from './Segment.module.scss'

type TProps = {
  children?: React.ReactNode
  className?: string
  content?: string | React.ReactNode
  horizontal?: boolean
  size?: 'sm' | 'md' | 'lg'
  state?: 'danger' | 'info' | 'warning' | 'default'
  interactive?: boolean
  onClick?: (e: any) => void
}

const SegmentGroup: React.FC<TProps> = props => {
  const {
    children,
    className,
    content,
    horizontal,
    size = 'md ',
    state = 'default',
    interactive = false,
    onClick,
  } = props
  const classes = cx(styles.segments, styles[`size-${size}`], styles[`state-${state}`], className, {
    [styles.horizontal]: !!horizontal,
    [styles.interactive]: !!interactive,
  })

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  const ElementType = getElementType(SegmentGroup, props)
  const spreadProps = pick(props, ['onClick'])

  return (
    <ElementType {...spreadProps} className={classes}>
      {childrenUtils.isNil(children) ? content : children}
    </ElementType>
  )
}

export default SegmentGroup

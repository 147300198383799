import React, { useCallback, useState } from 'react'
import { isEmpty, pathOr } from 'ramda'
import { FormErrors, Tabs } from '../../..'
import NewProperty from './NewProperty'
import SearchProperties from './SearchProperties'
import { withFormik } from 'formik'
import PropertyPreview from './PropertyPreview'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { propertyApiEvents } from '../../../../../modules/api/property'
import { getSelectedPlace } from 'modules/ui/ChatBot/Dialogs/PropertyDetails'
import styles from './PropertyForm.module.scss'
import RadioGroup from '../../../molecules/RadioGroup/RadioGroup'
import Radio from '../../../atoms/Radio/Radio'
import { getCurrentLeaseType } from 'modules/ui/ChatBot/Dialogs/LeaseTypeSelection'
import { leaseTypes } from 'modules/api/portfolio/constants'
import { portfolioApiEvents } from '../../../../../modules/api/portfolio'
import { $TSFixMe } from 'types/ts-migrate'

const PropertyForm = ({
  currentPortfolioId,
  handleConfirmation,
  handleNewPropertySubmit,
  isReadonly,
  clearSelection,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  isActivePortfolio,
  handleManagedSelected,
  handleUnmanagedSelected,
}: any) => {
  const [tab, setTab] = useState(0)
  const [requestPayload, setRequestPayload] = useState(null)

  const placeId = useSelector(getSelectedPlace)
  const leaseType = useSelector(state => getCurrentLeaseType(state))

  const _handleNewPropertySubmit = useCallback(
    (selectedPlaceId, buildingName, unitNumber) => {
      setRequestPayload({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ placeId: any; unitNumber: any;... Remove this comment to see the full error message
        placeId: selectedPlaceId || placeId,
        unitNumber,
        buildingName,
      })
      handleNewPropertySubmit(selectedPlaceId || placeId, buildingName, unitNumber)
    },
    [placeId, handleNewPropertySubmit],
  )

  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)
  const createPropertyEvent = propertyApiEvents.createProperty_request(requestPayload)
  const generalErrors = requestPayload === null ? [] : getGeneralFormErrorsByEvent(createPropertyEvent)
  const fieldErrors = requestPayload === null ? {} : getFormFieldErrorsByEvent(createPropertyEvent)

  const createPortfolioErrors = useSelector(state =>
    apiStateSelectors.getGeneralFormErrorsByEvent(state)(portfolioApiEvents.createPortfolio_request),
  )

  const tabs = [
    {
      title: 'Create new property',
      content: (
        <NewProperty
          generalErrors={generalErrors}
          fieldErrors={fieldErrors}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          isReadOnly={isReadonly}
          handleSubmit={(e: any) => {
            e.preventDefault()
            const placeId = pathOr('', ['selectedPlace', 'placeId'], values)
            _handleNewPropertySubmit(placeId, values.buildingName, values.unitNumber)
          }}
        />
      ),
    },
    {
      title: 'Search existing properties',
      // @ts-expect-error
      content: <SearchProperties isReadonly={isReadonly} />,
    },
  ]

  const onLeaseTypeChange = (type: string) => {
    type === leaseTypes.managed ? handleManagedSelected() : handleUnmanagedSelected()
  }

  return (
    <span>
      {!currentPortfolioId && (
        <RadioGroup
          name="leaseType"
          className={styles['radio-group']}
          stackRadios={false}
          label="Lease Type"
          defaultSelected={leaseType}
          onChange={e => onLeaseTypeChange(e.target.value)}
        >
          <Radio name="managed" value={leaseTypes.managed} label="Managed" allowUncheck={false} />
          <Radio name="unmanaged" value={leaseTypes.unmanaged} label="Unmanaged" allowUncheck={false} />
        </RadioGroup>
      )}
      {generalErrors.length === 0 && isEmpty(fieldErrors) && values.streetAddress ? (
        <>
          <FormErrors errors={createPortfolioErrors} />
          <PropertyPreview
            values={values}
            clearSelection={clearSelection}
            handleConfirmation={(e: $TSFixMe) => {
              e.preventDefault()
              handleConfirmation(values)
            }}
            isActivePortfolio={isActivePortfolio}
          />
        </>
      ) : (
        <>
          <Tabs tabs={tabs} selectedTab={tab} onTabChange={tab => setTab(tab)} />
        </>
      )}
    </span>
  )
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ enableReinitialize: true; mapP... Remove this comment to see the full error message
export default withFormik({
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
})(PropertyForm)

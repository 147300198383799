import { connect } from 'react-redux'
import { agencyApiEvents, agencyApiSelectors } from '../../../modules/api/agency'
import { uiEvents } from '../../../modules/ui'
import { userApiSelectors } from '../../../modules/api/user'
import { SignupPage } from '../../components'
import { $TSFixMe } from 'types/ts-migrate'

const mapStateToProps = (state: any): $TSFixMe => ({
  isAuthenticated: userApiSelectors.isAuthenticated(state),
  signupInvite: agencyApiSelectors.signupInvite(state),
})

const mapDispatchToProps = (dispatch: any): $TSFixMe => ({
  getInvite: (token: any) => dispatch(agencyApiEvents.signupInvite_request({ token })),

  loginRedirect: (e: any) => {
    e.preventDefault()
    dispatch(uiEvents.redirect('/login'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage)

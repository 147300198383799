import React from 'react'

const SearchOff = ({ fill = '#141719', ...props }) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path
        fill={fill}
        fillOpacity={0.56}
        d="M25.512 23.166l-3.508-3.51A8.757 8.757 0 0 0 19.649 7.5 8.748 8.748 0 0 0 7.5 9.856a8.757 8.757 0 0 0 2.355 12.155c2.96 2 6.836 2 9.795 0l3.509 3.51c.655.639 1.7.639 2.354 0 .65-.65.65-1.705 0-2.355zM14.76 7.684a7.079 7.079 0 0 1 7.077 7.08c0 3.91-3.168 7.08-7.077 7.08a7.079 7.079 0 0 1-7.077-7.08 7.087 7.087 0 0 1 7.077-7.08z"
      />
    </g>
  </svg>
)

export default SearchOff

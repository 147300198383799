import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import { get } from 'lodash-es'
import { useHover } from 'react-use'
import Switch from '../../../../atoms/Switch/Switch'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import CurrencyField from '../../../../molecules/CurrencyField/CurrencyField'
import styles from '../Beneficiary.module.scss'
import SettledBeneficiary from '../SettledBeneficiary/SettledBeneficiary'
import { getDepositBeneficiaryBankDetails } from './depositBeneficiaryUtils'

type DepositBeneficiaryProps = {
  placeholder: string
  amountName?: string
  value: number | string
  index: number
  reference: string | ReactNode
  transfer: boolean
  errors?: string
  vatApplied: boolean
  disableTransferToggle?: boolean
  disableCurrencyField?: boolean
  isBeneficiarySettled: boolean
  amountPayable: number
  inputTooltipText: string
  isEditing?: boolean
  agencyName: string
  agencyBankDetails?: any
  managedByValue?: 'owner' | 'agency'
  propertyOwnerId?: string
  propertyOwnerName?: string
  propertyOwnerBankDetails?: any
  Meta?: FC
  includeVatCalculator?: boolean
  onNewBeneficiaryAmountChange: () => void
  onTransferChange: (index: number, transfer: boolean) => void
  onVatChange: () => void
}

const DepositBeneficiary: FC<DepositBeneficiaryProps> = props => {
  const {
    placeholder,
    amountName,
    value,
    index,
    reference,
    transfer,
    isEditing = false,
    vatApplied,
    includeVatCalculator = true,
    agencyName,
    agencyBankDetails,
    Meta,
    errors,
    disableTransferToggle = false,
    disableCurrencyField = true,
    isBeneficiarySettled,
    amountPayable,
    inputTooltipText,
    managedByValue = 'owner',
    propertyOwnerId,
    propertyOwnerBankDetails,
    onNewBeneficiaryAmountChange,
    onTransferChange,
    onVatChange,
  } = props

  const classes = cx(styles.root, {
    [styles['is-editing']]: isEditing,
  })

  const managedByDescription = getDepositBeneficiaryBankDetails(
    managedByValue,
    agencyBankDetails,
    propertyOwnerId,
    propertyOwnerBankDetails,
  )

  const [details, isHovering] = useHover(
    <div className={styles.details}>
      <div>
        {disableTransferToggle ? (
          <strong>{transfer ? 'Managed by reOS' : `Self-managed by ${agencyName}`}</strong>
        ) : (
          <>
            <Switch
              className={styles['transfer-toggle']}
              label={transfer ? 'Managed by reOS' : 'Self-managed by Owner'}
              checked={transfer}
              onChange={() => onTransferChange(index, !transfer)}
            />
          </>
        )}
      </div>
      <div>{!transfer && managedByDescription}</div>
      {reference}
    </div>,
  )

  return (
    <li className={classes}>
      <Tooltip
        isOpen={!disableTransferToggle && isHovering}
        body={
          transfer
            ? 'The deposit amount is transferred to a reOS managed tenant deposit wallet.'
            : managedByValue === 'agency'
            ? 'The deposit amount is transferred to your external agency account.'
            : "The deposit amount is transferred to owner's account."
        }
      >
        {details}
      </Tooltip>
      {isBeneficiarySettled ? (
        <SettledBeneficiary amountPayable={amountPayable} />
      ) : (
        <div className={styles['input-wrapper']}>
          <CurrencyField
            disabled={disableCurrencyField}
            includeVatControl
            includeVatCalculator={includeVatCalculator}
            name={amountName}
            placeholder={placeholder}
            value={value}
            onChange={onNewBeneficiaryAmountChange}
            error={get(errors, 'amount')}
            onToggleChange={onVatChange}
            vatApplied={vatApplied}
            inputTooltipText={inputTooltipText}
          />
          {Meta && (
            <div className={styles.meta}>
              <Meta />
            </div>
          )}
        </div>
      )}
    </li>
  )
}

export default DepositBeneficiary

import { map, mergeMap, pluck } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { communicationsApiEvents } from '.'
import { ENDPOINTS } from '../constants'

export const apiFetchEmails = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(communicationsApiEvents.emails_request),
    mergeMap((action: { payload: any }) =>
      get(ENDPOINTS.FETCH_EMAILS, state$, action.payload).pipe(
        pluck('response'),
        map(response => {
          return communicationsApiEvents.emails_success({ response, params: action.payload })
        }),
        catchRestError(action),
      ),
    ),
    tag('communications/epics/apiFetchEmails'),
  )

export const apiResendEmail = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(communicationsApiEvents.resend_request),
    mergeMap(action => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const { params, body } = action.payload
      return post(ENDPOINTS.RESEND_EMAIL, state$, body, { id: params.id }).pipe(
        pluck('response'),
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        map(response => communicationsApiEvents.resend_success({ response, params: action.payload })),
        catchRestError(action),
      )
    }),
    tag('communications/epics/apiResendEmail'),
  )

import React from 'react'

const Property = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M25.676 13.257l-9.167-7.083a.833.833 0 0 0-1.018 0l-9.167 7.083a.833.833 0 0 0-.324.66v11.25c0 .46.373.832.833.832h18.334c.46 0 .833-.373.833-.833v-11.25a.833.833 0 0 0-.324-.659z"
    />
  </svg>
)

export default Property

import React, { HTMLAttributes } from 'react'
import styles from './Divider.module.scss'

interface DividerProps extends HTMLAttributes<HTMLElement> {
  children?: React.ReactNode
}
const Divider = ({ children }: DividerProps) =>
  children ? <span className={styles.root}>{children}</span> : <span className={styles.root} />

export default Divider

import React from 'react'
import Heading from '../../../atoms/Typography/Heading/Heading'
import { Paragraph } from '../../..'

const ThemePreview = ({ color, name }: any) => (
  <div className="rbn--row">
    <div className="rbn--col">
      <svg width="400" height="300" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <rect strokeOpacity=".3" stroke="#9E9EA4" fill="#FFF" x=".5" y=".5" width="399" height="299" rx="8" />
          <path fill="#15151A" d="M24 208h168v16H24z" />
          <rect
            fillOpacity=".8"
            fill={color}
            transform="matrix(1 0 0 -1 0 484)"
            x="250"
            y="214"
            width="120"
            height="56"
            rx="4"
          />
          <path fillOpacity=".2" fill="#15151A" d="M24 232h120v10H24z" />
          <path fill="#1867F9" d="M24 266h87v10H24z" />
          <path d="M8 0h384a8 8 0 018 8v176H0V8a8 8 0 018-8z" fillOpacity=".08" fill={color} />
          <path d="M8 0h384a8 8 0 018 8H0a8 8 0 018-8z" fill={color} />
          <text
            fontFamily="Inter-Medium, Inter"
            fontSize="20"
            fontWeight="400"
            letterSpacing=".15"
            fill="#050505"
            fillOpacity=".89"
          >
            <tspan x="24" y="43">
              Theme Preview
            </tspan>
          </text>
        </g>
      </svg>
    </div>

    <div className="rbn--col">
      <Heading as="h4" weight="heavy">
        {name} theme
      </Heading>
      <Paragraph>
        Choose a colour theme to make your brand pop across all system-generated communications like invoices,
        statements, lease activation and reminder emails.
      </Paragraph>
    </div>
  </div>
)

export default ThemePreview

import React from 'react'
import { matchPath } from 'react-router-dom'
import { SearchFilterProvider } from '../../../views/providers'
import { PortfoliosPage, PortfolioWizard, ConfirmPortfolioActivation, EditProperty } from '.'
import { PropertyApiProvider } from '../../api/property'
import PortfoliosProvider from '../PortfoliosProvider'
import { PortfolioStatement } from '../../accounting/components'
import { ROUTES } from '../../../constants/routes'
import { ContactsProvider } from '../../contacts'

const PortfoliosRoute = (props: any) => {
  const focusMatchPath = matchPath(props.location.pathname, {
    path: ROUTES.portfolioEdit,
    exact: false,
    strict: false,
  })

  const editPropertyMatchPath = matchPath(props.location.pathname, {
    path: ROUTES.editProperty,
    exact: false,
    strict: false,
  })

  return (
    <PropertyApiProvider>
      <PortfoliosProvider {...props}>
        <ContactsProvider {...props}>
          <SearchFilterProvider location={props.location}>
            <PortfoliosPage />
            <PortfolioStatement />
          </SearchFilterProvider>
          {focusMatchPath && (
            <>
              <PortfolioWizard match={focusMatchPath} />
              <ConfirmPortfolioActivation />
            </>
          )}
          {editPropertyMatchPath && <EditProperty />}
        </ContactsProvider>
      </PortfoliosProvider>
    </PropertyApiProvider>
  )
}

export default PortfoliosRoute

import React from 'react'
import { subscribe } from 'react-contextual'
import { $TSFixMe } from 'types/ts-migrate'
import { SearchFilter } from '../../../views/components'
import AgenciesProvider from '../AgenciesProvider'

const AgenciesSearchFilter = (props: $TSFixMe) => <SearchFilter {...props} />

export default subscribe([AgenciesProvider], ({ location, redirect }: $TSFixMe) => ({
  pathname: location.pathname,
  redirect,
  filters: [],
}))(AgenciesSearchFilter)

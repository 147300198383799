import {
  formatDistance,
  format,
  isValid,
  lastDayOfMonth,
  getMonth,
  getYear,
  subYears,
  addYears,
  getDate,
  addDays,
  parse,
} from 'date-fns'
import locale from 'date-fns/locale/en-US'
import { times } from 'lodash-es'

// @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
export const monthNames = times(12, (n: any) => locale.localize.month(n))

export const timeAgo = (date: any, addSuffix = true) => {
  return isValid(new Date(date)) ? formatDistance(new Date(date), Date.now(), { addSuffix }) : '-'
}

export const nowOr = (date: any, fn: any) => {
  return date.getTime() < new Date().getTime() ? 'Now' : fn(date)
}

export const formatStandard = (date: any) => (isValid(new Date(date)) ? format(new Date(date), 'dd MMM yyyy') : '-')

export const getCurrentTaxSeason = (): { start: Date; end: Date } => {
  const today = Date.now()

  const start = new Date(`${getYear(subYears(today, 1))}-03-01`)
  const lastDayOfFeb = lastDayOfMonth(new Date(`${getYear(today)}-02`))
  const end = new Date(`${getYear(today)}-02-${getDate(lastDayOfFeb)}`)
  return { start, end }
}

export const isBetween = (toCheck: string, numberOne: number, numberTwo: number) => {
  return (parseInt(toCheck) - numberOne) * (parseInt(toCheck) - numberTwo) <= 0
}

export const transformTwoDigitYearToFour = (year: string) => {
  const today = Date.now()
  const currentYear = getYear(today).toString().slice(2)
  if (parseInt(year) <= parseInt(currentYear)) {
    return `20${year}`
  } else {
    return `19${year}`
  }
}

import React from 'react'
import Svg from '../Svg/Svg'
import styles from './VoiceLoading.module.scss'

const VoiceLoading = ({ text }: any) => (
  <div className={styles.root}>
    <Svg name="loading" width="56px" height="38px" />
  </div>
)

export default VoiceLoading

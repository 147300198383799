import { connect } from 'react-redux'
import { PortfolioDeclineForm } from '../../components'

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({
  handleSubmit: (values: any) => {
    // chatbotCommands.declineCurrentPortfolio.invoke(values)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioDeclineForm)

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { path, pathOr, map } from 'ramda'
import { subscribe } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { stringUtils } from '../../../../utils'
import { SearchFilterProvider } from '../../../../views/providers'
import { AggregateTable, ControlledTooltip, Header, NoContent } from '../../../../views/components'
import SegmentsIcon from '../../../../views/components/atoms/Icons/Segments/Segments'
import { SegmentsProvider } from '../..'
import styles from './SegmentsTable.module.scss'
import { uiEvents } from '../../../ui'
import { ROUTES } from 'constants/routes'
import { agencyApiEvents } from '../../../api/agency'
import DeleteSegmentConfirmation from '../DeleteSegmentConfirmation/DeleteSegmentConfirmation'
import { $TSFixMe } from 'types/ts-migrate'

const SegmentsTable = ({ filters, primaryPanel, searchQuery, activeFilterIndex }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const filter: $TSFixMe = pathOr({}, [activeFilterIndex], filters)

  const [segmentToDelete, setSegmentToDelete] = useState(null)

  useEffect(() => {
    setData(filter.segments)
  }, [filter.segments])

  const handleDeleteSegment = useCallback(
    ({ id, isDeletable }) => {
      isDeletable && dispatch(agencyApiEvents.deleteSegment_request({ segmentId: id }))
      setSegmentToDelete(null)
    },
    [dispatch, setSegmentToDelete],
  )

  const filteredSegments = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const nameColumn = {
    Header: 'Segment Name',
    accessor: 'name',
    style: {
      minWidth: '400px',
      textAlign: 'left',
      justifyContent: 'flex-start',
      paddingLeft: '16px',
    },
  }

  const actionsColumn = {
    Header: '',
    accessor: 'actions',
    style: {
      justifyContent: 'flex-end',
    },
  }

  // const usersColumn = {
  //   Header: 'Users',
  //   accessor: 'users'
  // }

  // const leaseAcountColumn = {
  //   Header: 'Count',
  //   accessor: 'portfolioCount',
  //   style: {
  //     maxWidth: '250px'
  //   }
  // }

  const columns = path(['open'], primaryPanel)
    ? [nameColumn, actionsColumn]
    : [
        nameColumn,
        actionsColumn,
        // usersColumn,
        // leaseAcountColumn
      ]

  const redirect = useCallback(
    path => {
      dispatch(uiEvents.redirect(path))
    },
    [dispatch],
  )

  const onRowClick = useCallback(
    ({ id }) => {
      redirect(ROUTES.segmentView.replace(':id', id))
    },
    [redirect],
  )

  const segmentsData = useMemo(() => {
    const buildSegmentObj = (segment: any): $TSFixMe => {
      return {
        id: segment.id,
        name: segment.name,
        actions: (
          <>
            {segment.isDeletable && (
              <div>
                {/* @ts-expect-error */}
                <ControlledTooltip body="Delete segment">
                  <button className={styles['delete-segment']} onClick={() => setSegmentToDelete(segment)} />
                </ControlledTooltip>
              </div>
            )}
            <div>
              {/* @ts-expect-error */}
              <ControlledTooltip body="Edit segment">
                <button className={styles['edit-segment']} onClick={() => onRowClick(segment)} />
              </ControlledTooltip>
            </div>
          </>
        ),
      }
    }

    return searchQuery ? map(buildSegmentObj, filteredSegments) : map(buildSegmentObj, data)
  }, [data, filteredSegments, searchQuery, onRowClick])

  const addSegmentButton = (
    <button className={styles['no-segments-button']} onClick={() => redirect(ROUTES.segmentView.replace(':id', 'new'))}>
      Add a new segment
    </button>
  )

  // return useMemo(() => (
  return (
    <>
      <div className={styles.root}>
        <Header icon={<SegmentsIcon />} text="Book Segments" />

        {segmentsData.length > 0 ? (
          <AggregateTable rowHeight={80} columns={columns} data={segmentsData} interactive={false} />
        ) : (
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
          <NoContent heading={<p>No segments found. {addSegmentButton}</p>} />
        )}
      </div>
      <DeleteSegmentConfirmation
        isOpen={segmentToDelete !== null}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'never'.
        segmentName={segmentToDelete?.name}
        onConfirm={() => handleDeleteSegment(segmentToDelete)}
        onCancel={() => setSegmentToDelete(null)}
      />
    </>
  )
  // ), [columns, segmentsData])
}

export default subscribe(
  [SegmentsProvider, SearchFilterProvider],
  ({ filters, primaryPanel }: any, { searchQuery, activeFilterIndex }: any) => ({
    filters,
    primaryPanel,
    searchQuery,
    activeFilterIndex,
  }),
)(SegmentsTable)

import React, { useEffect, useState } from 'react'
import { subscribe } from 'react-contextual'
import { format } from 'date-fns'
import { Button, DateRangePicker, Heading } from '../../../../../views/components'
import ViewStatementProvider from '../ViewStatementProvider'
import styles from './ViewStatementSidebar.module.scss'
import { downloadBase64 } from 'utils/fileUtils'
import { $TSFixMe } from 'types/ts-migrate'

interface ViewStatementSidebarProps extends $TSFixMe {
  pdfFileName: string
  csvFileName: string
  startDate: string
  endDate: string
}

const ViewStatementSidebar = ({
  downloadStatement,
  downloadStatementCsv,
  statementPdf,
  statementCsv,
  pdfFileName,
  csvFileName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onParamChange,
}: ViewStatementSidebarProps): React.ReactElement => {
  useEffect(() => {
    onParamChange(startDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  const [requestedPdfDownload, setRequestedPdfDownload] = useState(false)
  useEffect(() => {
    if (statementPdf && requestedPdfDownload) {
      const fileName = `${pdfFileName}_${startDate}_${endDate}.pdf`
      statementPdf && downloadBase64(statementPdf, 'pdf', fileName)
      setRequestedPdfDownload(false)
    }
  }, [statementPdf, pdfFileName, requestedPdfDownload, startDate, endDate])

  const [requestedCsvDownload, setRequestedCsvDownload] = useState(false)
  useEffect(() => {
    if (statementCsv && requestedCsvDownload) {
      const fileName = `${csvFileName}_${startDate}_${endDate}.csv`
      statementCsv && downloadBase64(statementCsv, 'csv', fileName)
      setRequestedCsvDownload(false)
    }
  }, [statementCsv, csvFileName, requestedCsvDownload, startDate, endDate])

  return (
    <div className="root">
      <div className="rbn--grid">
        <div className="rbn--row">
          <div className="rbn--col">
            <Heading as="h4" weight="medium">
              Select period or date range
            </Heading>
            <div className={`${styles['date-range']}`}>
              <DateRangePicker
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                onChange={(date: any, isStartDate: any) =>
                  isStartDate ? setStartDate(format(date, 'yyyy-MM-dd')) : setEndDate(format(date, 'yyyy-MM-dd'))
                }
                inline={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="rbn--grid">
        <div className="rbn--row">
          <div className="rbn--col">
            <div className={styles['download-buttons']}>
              {downloadStatement && (
                <Button
                  loading={requestedPdfDownload}
                  ghost
                  block
                  className={styles.pdf}
                  onClick={() => {
                    setRequestedPdfDownload(true)
                    downloadStatement(startDate, endDate)
                  }}
                >
                  Download PDF
                </Button>
              )}
              {downloadStatementCsv && (
                <Button
                  loading={requestedCsvDownload}
                  ghost
                  block
                  className={styles.csv}
                  onClick={() => {
                    setRequestedCsvDownload(true)
                    downloadStatementCsv(startDate, endDate)
                  }}
                >
                  Download CSV
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default subscribe(
  [ViewStatementProvider],
  ({
    downloadStatement,
    downloadStatementCsv,
    statementPdf,
    statementCsv,
    pdfFileName,
    csvFileName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onParamChange,
  }: any) => ({
    downloadStatement,
    downloadStatementCsv,
    statementPdf,
    statementCsv,
    pdfFileName,
    csvFileName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onParamChange,
  }),
)(ViewStatementSidebar)

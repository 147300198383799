import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  primaryText: PropTypes.string,
  meta: PropTypes.string,
}

const defaultProps = {}

const ActiveLeaseCell = ({ primaryText, meta }: any) => {
  return (
    <div>
      {primaryText ? (
        <>
          <strong>{primaryText}</strong> <br />
          {meta}
        </>
      ) : (
        <>
          <strong>None</strong> <br />
          {meta}
        </>
      )}
    </div>
  )
}

ActiveLeaseCell.propTypes = propTypes
ActiveLeaseCell.defaultProps = defaultProps

export default ActiveLeaseCell

import React from 'react'
import classNames from 'classnames'
import type { PolymorphicComponentProps } from 'types/PolymorphicComponentProps'
import styles from './Label.module.scss'

interface Props {
  className?: string
  active?: boolean
  basic?: boolean
  circular?: boolean
  empty?: boolean
  horizontal?: boolean
  size?: 'sm' | 'md' | 'lg'
  onClick?: (event: React.MouseEvent<any>) => void
  children?: React.ReactNode
}

export type LabelProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>

const Label = <C extends React.ElementType = 'span'>({
  as,
  className,
  active = false,
  basic = false,
  circular = false,
  empty = false,
  horizontal = false,
  size = 'md',
  onClick = () => {},
  children,
  ...props
}: LabelProps<C>) => {
  const Component = as ?? 'span'
  const classes = classNames([
    styles.label,
    className,
    styles[`size-${size}`],
    {
      [styles.active]: active,
      [styles.basic]: basic,
      [styles.circular]: circular,
      [styles.empty]: empty,
      [styles.horizontal]: horizontal,
    },
  ])

  const componentProps = {
    active,
    basic,
    circular,
    empty,
    horizontal,
    size,
    onClick,
  }

  return (
    <Component className={classes} {...componentProps} {...props}>
      {children}
    </Component>
  )
}

export default Label

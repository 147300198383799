import React from 'react'
import PropTypes from 'prop-types'
import { DimmerLoader, Modal } from '../../../../views/components'
import styles from './ConfirmationActivationModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { agencyApiEvents } from '../../../api/agency'
import { apiStateSelectors } from '../../../api/apiState'
import { $TSFixMe } from 'types/ts-migrate'

const ConfirmationActivationModal = ({ isOpen, onClose, agencyName, agencyId }: $TSFixMe) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([agencyApiEvents.activateAgency_request]))

  const handleConfirm = () => {
    dispatch(agencyApiEvents.activateAgency_request({ id: agencyId }))
    onClose()
  }

  return (
    <Modal
      // @ts-expect-error
      className={styles.root}
      isOpen={isOpen}
      padding="sm"
      width="sm"
      theme="dark"
      showClose={false}
    >
      {/* @ts-expect-error */}
      <Modal.Header title="Are you sure?" />
      <Modal.Body
        actions={[
          { label: 'Yes, activate', onClick: handleConfirm },
          { label: 'Cancel', onClick: onClose },
        ]}
      >
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>You are about to activate {agencyName}</div>
          </>
        </DimmerLoader>
      </Modal.Body>
    </Modal>
  )
}

ConfirmationActivationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  agencyName: PropTypes.string.isRequired,
  agencyId: PropTypes.string.isRequired,
}

export default ConfirmationActivationModal

import React from 'react'
import PropTypes from 'prop-types'
import styles from './SettledBeneficiary.module.scss'
import { currencyUtils } from '../../../../../../utils'

const propTypes = {
  amountPayable: PropTypes.number,
}

const SettledBeneficiary = (props: any) => {
  const { amountPayable } = props

  return (
    <div className={styles.root}>
      <span className={styles['text-wrapper']}>
        <span className={styles.header}>Settled</span>
        <span className={styles.body}>{currencyUtils.formatCurrency(amountPayable)} paid</span>
      </span>
      <span className={styles['check-icon']} />
    </div>
  )
}

SettledBeneficiary.propTypes = propTypes

export default SettledBeneficiary

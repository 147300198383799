import {
  pipe,
  flatten,
  path,
  reject,
  map,
  sum,
  pathOr,
  filter,
  add,
  mergeDeepRight,
  prop,
  props,
  includes,
  propOr,
  isEmpty,
} from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { rentalInvoicePaymentReferences } from './constants'
import { TCreateApplicationRequest } from './types'

export const getTenantAndLandlordIdsFromPortfolio = (portfolio: any): $TSFixMe =>
  pipe(
    prop('parties'),
    // @ts-expect-error
    props(['tenants', 'owners']),
    // @ts-expect-error
    map(props(['primary', 'secondary'])),
    flatten,
    map(prop('partyId')),
    filter((id: any) => !!id),
  )(portfolio)

/**
 * Check if a portfolio is rolling over month to month
 *
 * @param {object} portfolio portfolio or summary object
 * @returns {bool} portfolio lease term rollover status
 */
export const isPortfolioMonthToMonth = (portfolio: any): $TSFixMe =>
  pathOr(false, ['leaseTerms', 'rolloverMonthToMonth'], portfolio)

/**
 * Get the total amount of all payment rules, excluding rent
 *
 * @param {array} paymentRules list of payment rules
 * @param {number} commissionAmount commission amount value
 * @returns {number} summed value of all payment rule amounts
 */
export const getPaymentRulesAmountExRent = (paymentRules: any, commissionAmount: any): $TSFixMe =>
  pipe(
    filter(
      (pr: any) =>
        pr.beneficiary.value.partyTag !== 'Owner' &&
        pr.beneficiary.value.reference !== rentalInvoicePaymentReferences.rentalIncome &&
        pr.beneficiary.value.reference !== rentalInvoicePaymentReferences.commission,
    ),
    map((pr: any) => pathOr(0, ['beneficiary', 'value', 'amount'], pr)),
    sum,
    add(commissionAmount),
  )(paymentRules)

export const getAllPartyIds = (parties: any): $TSFixMe => {
  if (isEmpty(parties)) {
    return []
  }
  const primaryId = pathOr(null, ['primary', 'partyId'], parties)
  // @ts-expect-error
  const secondaryIds: $TSFixMe = pipe(propOr([], 'secondary'), map(prop('partyId')))(parties)
  return [primaryId, ...secondaryIds].filter(id => !!id)
}

/**
 * Set a tenant or landlord to primary.
 *
 * @param {object} parties tenants or owners from portfolio.parties
 * @param {string} parties.lastFetched last fetched time
 * @param {object} parties.primary primary party
 * @param {string} parties.primary.partyId Party ID
 * @param {Array<{partyId: String}>} parties.secondary secondary parties
 * @param {string} partyId party ID to set as primary
 *
 * @returns new portfolio parties for use in portfolio.parties.owners or portfolio.parties.tenants
 */
export const setPrimaryParty = (parties: any, partyId: any): $TSFixMe => {
  const primaryId = path(['primary', 'partyId'], parties)
  // @ts-expect-error
  const secondaryIds: $TSFixMe = pipe(prop('secondary'), map(prop('partyId')))(parties)

  if (includes(partyId, secondaryIds)) {
    const newSecondaries = pipe(
      filter((id: any) => id !== partyId),
      map((id: any) => ({
        partyId: id,
      })),
    )(secondaryIds)

    return {
      ...parties,
      primary: { partyId },
      secondary: [...newSecondaries, { partyId: primaryId }],
    }
  } else {
    return false
  }
}

/**
 * Remove a secondary party
 *
 * @param {object} parties tenants or owners from portfolios.parties
 * @param partyId
 * @param {string} parties.lastFetched last fetched time
 * @param {object} parties.primary primary party
 * @param {string} parties.primary.partyId Party ID
 * @param {Array<{partyId: String}>} parties.secondary secondary parties
 *
 * @returns new portfolio parties for use in portfolio.parties.owners or portfolio.parties.tenants
 */
export const removeSecondaryParty = (parties, partyId): $TSFixMe => ({
  ...parties,
  secondary: parties.secondary.filter((s: any) => s.partyId !== partyId),
})

/**
 * Add a secondary party
 * @todo remove duplicates
 *
 * @param {object} parties tenants or owners from portfolios.parties
 * @param partyId
 * @param {string} parties.lastFetched last fetched time
 * @param {object} parties.primary primary party
 * @param {string} parties.primary.partyId Party ID
 * @param {Array<{partyId: String}>} parties.secondary secondary parties
 *
 * @returns new portfolio parties for use in portfolio.parties.owners or portfolio.parties.tenants
 */
export const addSecondaryParty = (parties, partyId): $TSFixMe => ({
  ...parties,
  secondary: parties.secondary.concat([{ partyId }]),
})

/**
 * Adds a party to parties.primary if empty, else parties.secondary
 * @todo remove duplicates
 *
 * @param {object} parties tenants or owners from portfolios.parties
 * @param partyId
 * @param {string} parties.lastFetched last fetched time
 * @param {object} parties.primary primary party
 * @param {string} parties.primary.partyId Party ID
 * @param {Array<{partyId: String}>} parties.secondary secondary parties
 *
 * @returns new portfolio parties for use in portfolio.parties.owners or portfolio.parties.tenants
 */
export const addParty = (parties: any, partyId: any): $TSFixMe => {
  if (parties?.primary?.partyId) {
    return addSecondaryParty(parties, partyId)
  } else {
    return {
      ...parties,
      primary: { partyId },
    }
  }
}

import React, { useCallback, useState } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import { partyApiSelectors } from '../api/party'
import { portfolioApiSelectors } from '../api/portfolio'
import { walletApiEvents, walletApiSelectors } from '../api/wallet'
import { apiStateSelectors } from '../api/apiState'
import { $TSFixMe } from 'types/ts-migrate'
import { bankingApiEvents, bankingApiSelectors } from 'modules/api/banking'

const propTypes = {
  entity: PropTypes.oneOf(['portfolio', 'party']).isRequired,
  id: PropTypes.string.isRequired,
  /** Required for contacts view. The are multiple accounts in the account selector */
  tenantAccountId: PropTypes.object,
}

type RefundProps = {
  tenantId?: string
  tenantAccount?: $TSFixMe // use API type
  tenantName?: string
  tenantDetails?: $TSFixMe
  depositAccountBalance?: number
  asset?: $TSFixMe // use API type
}

/**
 * Supports deposit refund state for both portfolios and parties
 */
const RefundProvider = moduleContext()(({ context, children, ...props }: $TSFixMe) => {
  const { entity, id, tenantAccountId = false } = props
  const [isOpen, setIsOpen] = useState(false)
  const [requestPayload, setRequestPayload] = useState({})
  const dispatch = useDispatch()

  const fetchPartyErrors = useCallback(
    (partyId: string) => {
      dispatch(bankingApiEvents.partyErrors_request({ partyId }))
    },
    [dispatch],
  )
  const getPartyAccountByPortfolioId = useSelector(partyApiSelectors.getPartyAccountByPortfolioId)
  const getPartyAccountByAccountId = useSelector(partyApiSelectors.getPartyAccountByAccountId)
  const getPartyNameById = useSelector(partyApiSelectors.getPartyNameById)
  const getPartyDetailsById = useSelector(partyApiSelectors.getPartyDetailsById)
  const getDepositAccountBalance = useSelector(walletApiSelectors.getDepositAccountBalance)
  const getBalanceAsset = useSelector(walletApiSelectors.getBalanceAsset)
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)
  const generalErrors = getGeneralFormErrorsByEvent(walletApiEvents.refund_request(requestPayload))
  const getPartyBankingErrors = useSelector(bankingApiSelectors.getBankingPartyById)
  const fieldErrors = getFormFieldErrorsByEvent(walletApiEvents.refund_request(requestPayload))
  const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

  const handleRequestRefund = useCallback(
    ({ body, params }) => {
      setRequestPayload({ body, params })
      dispatch(walletApiEvents.refund_request({ body, params }))
    },
    [dispatch, setRequestPayload],
  )

  let refundProps: RefundProps = {}

  switch (entity) {
    case 'portfolio': {
      const getPrimaryTenantByPortfolioId = useSelector(portfolioApiSelectors.getPrimaryTenantByPortfolioId)
      const portfolioTenantId = getPrimaryTenantByPortfolioId(id)
      const portfolioTenantAccount = getPartyAccountByPortfolioId(portfolioTenantId, id, 'Tenant')

      refundProps = {
        tenantId: portfolioTenantId as string,
        tenantAccount: portfolioTenantAccount,
        tenantName: getPartyNameById(portfolioTenantId),
        tenantDetails: getPartyDetailsById(portfolioTenantId),
        depositAccountBalance: getDepositAccountBalance(portfolioTenantId, portfolioTenantAccount?.accountId),
        asset: getBalanceAsset(portfolioTenantId, portfolioTenantAccount?.accountId, 'Tenant'),
      }
      break
    }
    case 'party':
      if (tenantAccountId) {
        const tenantAccount = getPartyAccountByAccountId(id, tenantAccountId)
        refundProps = {
          tenantId: id,
          tenantAccount,
          tenantName: getPartyNameById(id),
          tenantDetails: getPartyDetailsById(id),
          depositAccountBalance: getDepositAccountBalance(id, tenantAccount?.accountId),
          asset: getBalanceAsset(id, tenantAccount?.accountId, 'Tenant'),
        }
      }
      break

    default:
      break
  }

  const partyBankingErrors: { [key: string]: string } | null = refundProps.tenantId
    ? getPartyBankingErrors(refundProps.tenantId)
    : null

  const handleOpen = useCallback(() => {
    if (refundProps.tenantId) {
      fetchPartyErrors(refundProps.tenantId)
    }
    setIsOpen(true)
  }, [setIsOpen, fetchPartyErrors, refundProps])

  const store = {
    refund: {
      handleClose: () => setIsOpen(false),
      handleOpen,
      requestRefund: handleRequestRefund,
      isOpen,
      hasApiErrors,
      generalErrors,
      partyBankingErrors,
      fieldErrors,
      isSubmitting: useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.refund_request])),
      isLoading: useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.balance_request])),
      ...refundProps,
    },
    ...props,
  }

  return <context.Provider value={store}>{children}</context.Provider>
})

RefundProvider.propTypes = propTypes

export default RefundProvider

import React from 'react'
import { moduleContext } from 'react-contextual'

const InvoicesProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = {
    navItems: [
      {
        text: 'Draft invoices',
        route: '/invoices/drafts?filter=notset',
      },
      {
        text: 'Active invoices',
        route: '/invoices/active?filter=overdue',
      },
      {
        text: 'Payments',
        route: '/payments?filter=all',
      },
      {
        text: 'History',
        route: '/invoices/history',
      },
    ],
    ...props,
  }
  return <context.Provider value={store}>{children}</context.Provider>
})

export default InvoicesProvider

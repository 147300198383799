import React, { useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { StringParam, useQueryParam } from 'use-query-params'
import { useEffectOnce } from 'react-use'
import { InvoicesProvider } from '../../../../providers'
import { ListingTemplate } from '../../..'
import SearchFilter from './SearchFilter'
import DraftInvoicesTable from './DraftInvoicesTable/DraftInvoicesTable'
import TableProvider from '../../../organisms/Table/TableProvider'
import DraftInvoicesBulkActions from './DraftInvoicesBulkActions'
import { ROUTES } from 'constants/routes'
import { Link } from 'react-router-dom'
import { usePortfolioProperty } from 'modules/api/portfolio/hooks'

/**
 * @todo move portfolio property into custom hook in portfolio API service or connectors or something like that?
 */
const DraftInvoices = ({ isDraftsLoading, getInvoices }: any) => {
  useEffectOnce(() => {
    getInvoices()
  })

  const [portfolioId] = useQueryParam('portfolioId', StringParam)
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  const portfolioUrl = ROUTES.portfolioEdit.replace(':id', portfolioId)
  const { propertyStreetAddress } = usePortfolioProperty(portfolioId)

  return useMemo(() => {
    const title = propertyStreetAddress && (
      <>
        Outbox for lease: <Link to={portfolioUrl}>{propertyStreetAddress}</Link>
      </>
    )

    return (
      <>
        <Helmet>
          <title>reOS | Invoices | Drafts</title>
        </Helmet>
        <TableProvider>
          <ListingTemplate
            loading={isDraftsLoading}
            Header={<SearchFilter />}
            Body={<DraftInvoicesTable title={title} />}
            Footer={<DraftInvoicesBulkActions />}
          />
        </TableProvider>
      </>
    )
  }, [isDraftsLoading, propertyStreetAddress, portfolioUrl])
}

export default subscribe([InvoicesProvider], ({ getInvoices, isDraftsLoading }: any) => ({
  getInvoices,
  isDraftsLoading,
}))(DraftInvoices)

import React from 'react'

const SvgPlus = ({ fill = '#191923', width = 32, height = 32, ...props }): React.ReactElement => (
  <svg width={width} height={height} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path fill={fill} fillRule="nonzero" d="M15 15V9h2v6h6v2h-6v6h-2v-6H9v-2z" />
    </g>
  </svg>
)

export default SvgPlus

import React from 'react'
import PropTypes from 'prop-types'
import { List, AggregateListItem } from '../../../molecules/List/List'
import CheckIcon from '../../../atoms/Icons/Controls/Check'
import { LinearProgress } from '../../..'
import styles from './SidebarNav.module.scss'

const propTypes = {
  onClick: PropTypes.func,
  url: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      hasCheckmark: PropTypes.bool,
      route: PropTypes.string,
      isActive: PropTypes.func,
      progress: PropTypes.number,
      Icon: PropTypes.element,
    }),
  ).isRequired,
  isDraftPortfolio: PropTypes.bool.isRequired,
}

const defaultProps = {}

const SidebarNav = ({ onClick, url, items, isDraftPortfolio }: any): React.ReactElement => {
  return (
    <div className={styles.root}>
      <List interactive>
        {items.map((item: any, i: any) => {
          const itemText =
            item.hasCheckmark && isDraftPortfolio ? (
              <>
                {item.Icon && <item.Icon />} {item.label}
                {item.progress === 100 && (
                  <span className={styles.completed}>
                    <CheckIcon />
                  </span>
                )}
              </>
            ) : (
              <>
                {item.Icon && <item.Icon />} {item.label}
              </>
            )

          return (
            <React.Fragment key={i}>
              <AggregateListItem
                key={i}
                text={itemText}
                active={item.isActive(url)}
                onClick={() => onClick(item.route)}
              />
              {!isDraftPortfolio && (
                <LinearProgress
                  percent={100}
                  strokeColor="rgba(0,0,0,0.10)"
                  trailColor="rgba(0,0,0,0)"
                  strokeWidth={0.3}
                />
              )}
              {isDraftPortfolio && item.progress !== undefined && (
                <LinearProgress
                  percent={item.progress}
                  strokeColor="#30D4AF"
                  trailColor="rgba(0,0,0,0.10)"
                  strokeWidth={0.3}
                />
              )}
            </React.Fragment>
          )
        })}
      </List>
    </div>
  )
}

SidebarNav.propTypes = propTypes
SidebarNav.defaultProps = defaultProps

export default SidebarNav

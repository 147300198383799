import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './SummaryWidget.module.scss'

const propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentageDiff: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
}

const SummaryWidget = ({ title, color, amount, percentageDiff, actions }: any) => {
  const percentageClass = cx([styles.percentage], {
    [styles['is-negative']]: percentageDiff < 0,
  })

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>
          {color && <i style={{ backgroundColor: color }} />}
          {title}
        </span>
      </div>
      <div className={styles.amount}>
        <strong>{amount}</strong>
        {percentageDiff ? (
          <span className={percentageClass}>
            {percentageDiff >= 0 && '+'}
            {percentageDiff}%
          </span>
        ) : null}
      </div>
      {actions.length > 0 && (
        <div className={styles.actions}>
          {actions.map((a: any, i: any) => (
            <span key={i}>
              <button key={i} className={styles.action} onClick={a.onClick}>
                {a.label}
              </button>
              {i < actions.length - 1 && ', '}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

SummaryWidget.propTypes = propTypes

export default SummaryWidget

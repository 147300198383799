import React, { useState } from 'react'
import { DimmerLoader, Modal, FormField, Checkbox, Button } from 'views/components'
import styles from './ConfirmAcceptApplicationModal.module.scss'

type Props = {
  party: string
  reconStatus: string
  isOpen: boolean
  isLoading: boolean
  onAccept: () => void
  onCancel: () => void
}

const ConfirmAcceptApplicationModal = ({ party, reconStatus, isOpen, isLoading, onAccept, onCancel }: Props) => {
  const [isConfirmPartyNameChecked, setIsConfirmPartyNameChecked] = useState(false)

  const acceptanceAdditionalMessage = (reconStatus: string) => {
    switch (reconStatus) {
      case 'DraftInvoice':
        return 'Note that you can send or delete your Draft Application Fee Invoice from Drafts'
      case 'InvoiceSent':
        return 'Note that reOS will credit note the active Application Fee invoice. Should the tenant still be due to pay an Application Fee, you can raise a new Application Fee invoice in the lease setup process.'
      case 'PartiallyPaid':
        return 'Note that reOS will credit note the active Application Fee invoice. Should the tenant still be due to pay an Application Fee, you can raise a new Application Fee invoice in the lease setup process.'
      default:
        return ''
    }
  }

  return (
    <Modal
      // @ts-expect-error
      className={styles.root}
      isOpen={isOpen}
      padding="sm"
      width="sm"
      theme="dark"
      showClose={false}
    >
      {/* @ts-expect-error */}
      <Modal.Header title="Accept Application Confirmation" />
      <Modal.Body>
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>
              <p>
                By accepting, reOS will add this applicant as a tenant to the lease. Please confirm if you are happy to
                proceed by checking the applicant's name below:
              </p>
              <FormField>
                <Checkbox
                  name="confirmPartyName"
                  label={party}
                  checked={isConfirmPartyNameChecked}
                  onChange={(e: any) => setIsConfirmPartyNameChecked(e.checked)}
                />
              </FormField>
              <small>
                <i>{acceptanceAdditionalMessage(reconStatus)}</i>
              </small>
            </div>
          </>
        </DimmerLoader>
        <div className="Modal_Actions">
          <Button disabled={!isConfirmPartyNameChecked} key="Yes, accept" link onClick={onAccept}>
            Yes, accept
          </Button>
          <Button key="Cancel" link onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmAcceptApplicationModal

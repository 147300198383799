import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import BadReferencesProvider from '../../BadReferencesProvider'
import { CurrencyText, InfoList } from '../../../../views/components'
import { useDispatch, useSelector } from 'react-redux'
import { walletApiEvents, walletApiSelectors } from '../../../api/wallet'
import { agencyApiEvents, agencyApiSelectors } from '../../../api/agency'
import styles from './ViewBadReferences.module.scss'
import { useEffectOnce } from 'react-use'
import { $TSFixMe } from 'types/ts-migrate'

const ViewAllocatedReference = ({ currentReference }: any) => {
  const dispatch = useDispatch()
  const { allocatedToRef } = currentReference

  useEffectOnce(() => {
    dispatch(agencyApiEvents.agencyListing_request())
  })

  useEffect(() => {
    dispatch(walletApiEvents.referenceLookup_request({ reference: allocatedToRef }))
  }, [dispatch, allocatedToRef])

  const referenceLookup: $TSFixMe = useSelector(state =>
    walletApiSelectors.getReferenceLookupByReference(state)(allocatedToRef),
  )
  const agencyId = referenceLookup?.agencyId
  const agencyFromListing = useSelector(state => agencyApiSelectors.getAgencyFromListingById(state)(agencyId))
  const agencyName = (
    <>
      <strong>{agencyFromListing?.agencyName}</strong> - Trading as <strong>{agencyFromListing?.tradingAs}</strong>
    </>
  )
  const infoItems = [
    {
      label: 'Allocated to reference',
      value: allocatedToRef,
    },
    {
      label: 'Party ID',
      value: referenceLookup?.partyId,
    },
    {
      label: 'Account Type',
      value: referenceLookup?.accountType,
    },
    {
      label: 'Agency',
      value: agencyName,
    },
    {
      label: 'Property',
      value: referenceLookup?.propertyAddress,
    },
    {
      label: 'ClickUp Ref',
      value: currentReference?.clickUpRef,
      onClick: () => {},
    },
    {
      label: 'Notes',
      value: currentReference?.notes,
    },
  ]
  return (
    <div>
      <div className={styles['title-text']}>
        <h2>Reference used: {currentReference?.referenceUsed} </h2>
        <span>
          {' '}
          <strong>Transaction ID:</strong> {currentReference?.txId.substr(0, 9)}{' '}
        </span>
        <span>
          <strong>Amount:</strong> <CurrencyText>{currentReference?.amount}</CurrencyText>
        </span>
      </div>
      <h3>Allocation Details</h3>
      {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
      <InfoList items={infoItems} spacing="lg" />
    </div>
  )
}

ViewAllocatedReference.propTypes = {
  currentReference: PropTypes.object,
}

export default subscribe([BadReferencesProvider], () => ({}))(ViewAllocatedReference)

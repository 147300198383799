import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { userApiSelectors } from '../../../modules/api/user'
import { $TSFixMe } from 'types/ts-migrate'

interface PrivateRouteProps extends RouteProps {
  isAuthenticated?: boolean
  isAuthorised?: boolean
}

const PrivateRoute = ({
  component: Component,
  isAuthenticated = false,
  isAuthorised = true,
  ...rest
}: PrivateRouteProps): React.ReactElement => (
  <Route
    {...rest}
    render={(properties: any) =>
      isAuthenticated ? (
        isAuthorised ? (
          // @ts-expect-error ts-migrate(2339) FIXME: JSX element type 'Component' does not have any construct or call signatures.
          <Component {...properties} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: properties.location },
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: properties.location },
          }}
        />
      )
    }
  />
)

const mapStateToProps = (state: any, props: PrivateRouteProps): { isAuthenticated: $TSFixMe } => ({
  ...props,
  isAuthenticated: userApiSelectors.isAuthenticated(state),
})

export default connect(mapStateToProps, null)(PrivateRoute)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { partyApiEvents } from 'modules/api/party'

type PartySearchState = {
  isSearching: boolean
  queryByTags: {
    [key: string]: string
  }
}
const { actions, reducer } = createSlice({
  name: 'ui/partySearch',
  initialState: {
    isSearching: false,
    queryByTags: {},
  } as PartySearchState,
  reducers: {
    partySearchQuery: (state, { payload: { query, tags } }: PayloadAction<{ query: string; tags: string }>) => {
      state.isSearching = true
      state.queryByTags[tags] = query
    },
    partySearchWithPortfolioStatus: (
      state,
      { payload: { query, tags } }: PayloadAction<{ query: string; tags: string }>,
    ) => {
      state.isSearching = true
      state.queryByTags[tags] = query
    },
    partySelected: (state, { payload }: PayloadAction<string>) => {},
  },
  extraReducers: {
    // @ts-expect-error
    [partyApiEvents.quickSearch_success]: state => {
      state.isSearching = false
    },
  },
})

export { actions as partySearchEvents, reducer as partySearchReducer }

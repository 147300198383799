const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'invoiceApi'

export const ENDPOINTS = {
  INVOICE_BASE: `${REACT_APP_API_URL}/invoices`,
  INVOICE_ENTITY: `${REACT_APP_API_URL}/invoices/:id/entity`,
  FETCH_INVOICE_TYPES: `${REACT_APP_API_URL}/invoices/invoice_types`,
  SEND_INVOICES: `${REACT_APP_API_URL}/invoices/send`,
  DELETE_INVOICES: `${REACT_APP_API_URL}/invoices/delete`,
  RESTORE_INVOICES: `${REACT_APP_API_URL}/invoices/restore`,
  NOTIFICATIONS: `${REACT_APP_API_URL}/invoices/notifications`,
  FETCH_DRAFT_INVOICES: `${REACT_APP_API_URL}/invoices/portfolio/:id/drafts`,
  BULK_INVOICES: `${REACT_APP_API_URL}/invoices/bulk_invoices`,
}

export const depositInvoiceTypes = ['ServiceDeposit', 'DamageDeposit', 'DepositTopUp', 'KeyDeposit']

export const defaultAgencyInvoiceTypes = [
  'Commission',
  'ProcurementFee',
  'ApplicationFee',
  'LeaseFee',
  'RenewalFee',
  'IncomingInspection',
  'OutgoingInspection',
  'AdministrationFee',
  'CashDepositFee',
  'LatePaymentFee',
  'CreditCheckFee',
]

export const adhocOnlyInvoiceTypes = ['DepositHandover']

export const DEBOUNCE_TIME = 1000

import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { find, findIndex, pathOr } from 'ramda'
import { Helmet } from 'react-helmet'
// import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import PortfoliosProvider from '../../PortfoliosProvider'
import DepositProvider from '../../../depositManagement/DepositProvider'
import styles from './PortfolioView.module.scss'
import PropertyInfo from './PropertyInfo/PropertyInfo'
import PortfolioInfo from './PortfolioInfo/PortfolioInfo'
import ContextMenu from '../../../../views/components/molecules/ContextMenu/ContextMenu'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../../modules/api/portfolio'
import { partyApiSelectors } from '../../../../modules/api/party'
import Edit from '../../../../views/components/atoms/Icons/Controls/Edit'
import Refresh from '../../../../views/components/atoms/Icons/Controls/Refresh'
import Delete from '../../../../views/components/atoms/Icons/Controls/Delete'
import Statement from '../../../../views/components/atoms/Icons/Doc/Statement'
import Withdraw from '../../../../views/components/atoms/Icons/Finance/Withdraw'
import Payments from '../../../../views/components/atoms/Icons/Descriptive/Payments'
import RefundProvider from '../../../depositManagement/RefundProvider'
import { DimmerLoader } from '../../../../views/components'
import { propertyApiEvents } from '../../../api/property'

const propTypes = {}

const PortfolioView = ({
  portfolioViewId,
  redirect,
  closeProperty,
  viewData,
  selectedPortfolio,
  portfolioStatus,
  openEditPortfolio,
  openRenewLease,
  openEditProperty,
  openPortfolioStatement,
  openDepositTopupRequest,
  openDepositTransferModal,
  depositAccountBalance,
  deleteDraftLease,
  openPortfolio,
  openLeaseTermination,
  isPortfolioViewLoading,
  resetCurrentPortfolio,
}: any) => {
  const dispatch = useDispatch()
  const portfolio = viewData.portfolios[selectedPortfolio]

  const getSummaryFieldById = useSelector(portfolioApiSelectors.getSummaryFieldById)

  useEffect(() => {
    const propertyId = getSummaryFieldById(portfolioViewId, ['propertyId'])
    resetCurrentPortfolio()
    dispatch(portfolioApiEvents.portfolio_request(portfolioViewId))
    if (propertyId) {
      dispatch(propertyApiEvents.property_request({ id: propertyId }))
      dispatch(propertyApiEvents.propertyPortfolios_request({ id: propertyId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioViewId, dispatch])

  const tenantId = useSelector(state => portfolioApiSelectors.getPrimaryTenantByPortfolioId(state)(portfolioViewId))
  const tenantDetails = useSelector(state => partyApiSelectors.getPartyDetailsById(state)(tenantId))
  const tenantAccount = find((a: any) => a.portfolioId === portfolioViewId && a.tag === 'Tenant')(
    pathOr([], ['accounts'], tenantDetails),
  )

  const partyId = pathOr(null, ['partyId'], tenantAccount)
  const accountId = pathOr(null, ['accountId'], tenantAccount)

  const isPortfolioTerminated = useSelector(state =>
    portfolioApiSelectors.isPortfolioTerminated(state)(portfolioViewId),
  )

  const depositStatementPath = partyId && accountId ? `/contacts/${partyId}/${accountId}/deposit-statement` : null

  const handleDepositTransferRequest = useCallback(() => {
    openDepositTopupRequest(portfolioViewId)
  }, [portfolioViewId, openDepositTopupRequest])

  const getSelectedPortfolioIndex = (portfolioId: any) =>
    findIndex((portfolio: any) => portfolio.id === portfolioId, viewData.leaseSelectorOptions)

  let selectedPortfolioIndex = getSelectedPortfolioIndex(portfolioViewId)

  let leaseOptions: any = []

  switch (portfolioStatus) {
    case 'active':
    case 'renewal':
      leaseOptions = [
        {
          label: 'Lease statement',
          element: <Statement />,
          onSelect: () => openPortfolioStatement(portfolioViewId),
        },
        {
          label: portfolioStatus === 'renewal' ? 'Edit renewal' : 'Renew lease',
          element: <Refresh />,
          onSelect: () => openRenewLease(portfolioViewId),
        },
        {
          label: 'Edit lease',
          element: <Edit />,
          onSelect: () => openEditPortfolio(portfolioViewId),
        },

        {
          label: 'Terminate lease',
          element: <Delete />,
          onSelect: () => openLeaseTermination(portfolioViewId),
        },
      ]
      break
    case 'expiring':
      leaseOptions = [
        {
          label: 'Lease statement',
          element: <Statement />,
          onSelect: () => openPortfolioStatement(portfolioViewId),
        },
        {
          label: 'Renew lease',
          element: <Refresh />,
          onSelect: () => openRenewLease(portfolioViewId),
        },
        {
          label: 'Edit lease',
          element: <Edit />,
          onSelect: () => openEditPortfolio(portfolioViewId),
        },
        {
          label: 'Terminate lease',
          element: <Delete />,
          onSelect: () => openLeaseTermination(portfolioViewId),
        },
      ]
      break
    case 'expired':
      leaseOptions = [
        {
          label: 'Lease statement',
          element: <Statement />,
          onSelect: () => openPortfolioStatement(portfolioViewId),
        },
        {
          label: 'Renew lease',
          element: <Refresh />,
          onSelect: () => openRenewLease(portfolioViewId),
        },
      ]
      break
    case 'terminated':
      leaseOptions = [
        {
          label: 'Lease statement',
          element: <Statement />,
          onSelect: () => openPortfolioStatement(portfolioViewId),
        },
      ]
      break
    case 'draft':
      leaseOptions = [
        {
          label: 'Edit lease',
          element: <Edit />,
          onSelect: () => openEditPortfolio(portfolioViewId),
        },
        {
          label: 'Edit property',
          element: <Edit />,
          onSelect: () => openEditProperty(portfolioViewId),
        },
        {
          label: 'Delete draft',
          element: <Delete />,
          onSelect: () => {
            deleteDraftLease(portfolioViewId)
            if (viewData.leaseSelectorOptions.length > 1) {
              const firstAvailabeLeaseId = viewData.leaseSelectorOptions.find((x: any) => x.id !== portfolioViewId).id
              openPortfolio(firstAvailabeLeaseId)
              selectedPortfolioIndex = getSelectedPortfolioIndex(firstAvailabeLeaseId)
            } else {
              closeProperty()
            }
          },
        },
      ]
      break
    default:
      leaseOptions = []
  }

  return (
    <>
      <Helmet>
        <title>reOS | Properties | {viewData.title}</title>
      </Helmet>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; className: any; loadi... Remove this comment to see the full error message */}
      <DimmerLoader className={styles.root} loading={isPortfolioViewLoading} renderChildren={false} bgColor={'appBg'}>
        <header className={styles.header}>
          <button className={styles.close} onClick={closeProperty}>
            &nbsp;
          </button>
          <ContextMenu name="leaseContextMenu" items={leaseOptions} position="left" showInput={false} />
        </header>
        <div className={styles.body}>
          <div className="rbn--row">
            <div className="rbn--col rbn-col-md-8">
              <RefundProvider portfolioId={portfolioViewId}>
                <PortfolioInfo
                  redirect={redirect}
                  selectedPortfolio={pathOr(0, ['leaseSelectorOptions', selectedPortfolioIndex], viewData)}
                  onLeaseChange={(data: any) => redirect(`/leases/${data.id}`)}
                  leaseSelectorOptions={viewData.leaseSelectorOptions}
                  portfolio={portfolio}
                  tenantId={tenantId}
                />
              </RefundProvider>
            </div>
            <div className="rbn--col rbn-col-md-8">
              <PropertyInfo viewData={viewData} />
            </div>
          </div>
        </div>
      </DimmerLoader>
    </>
  )
}

PortfolioView.propTypes = propTypes

export default subscribe(
  [PortfoliosProvider, DepositProvider],
  (
    {
      portfolioViewId,
      redirect,
      closeProperty,
      viewData,
      selectedPortfolio,
      portfolioStatus,
      openEditPortfolio,
      openEditProperty,
      openPortfolioStatement,
      openRenewLease,
      deleteDraftLease,
      openPortfolio,
      openLeaseTermination,
      isPortfolioViewLoading,
      resetCurrentPortfolio,
    }: any,
    { openDepositTopupRequest, openDepositTransferModal, depositAccountBalance }: any,
  ) => ({
    portfolioViewId,
    redirect,
    closeProperty,
    viewData,
    selectedPortfolio,
    portfolioStatus,
    openEditPortfolio,
    openRenewLease,
    openEditProperty,
    openPortfolioStatement,
    openDepositTopupRequest,
    openDepositTransferModal,
    depositAccountBalance,
    deleteDraftLease,
    openPortfolio,
    openLeaseTermination,
    isPortfolioViewLoading,
    resetCurrentPortfolio,
  }),
)(PortfolioView)

import { createSelector } from 'reselect'
import { NAMESPACE } from './constants'

const state = (state: any) => state

const creditNoteState = (state: any) => state[NAMESPACE]

export const getInvoiceId = createSelector(creditNoteState, s => s.invoiceId)

export const isOpen = createSelector(state, s => !!getInvoiceId(s))

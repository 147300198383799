import { connect } from 'react-redux'
import { chatbotSelectors } from '../../../modules/ui/ChatBot'
import { portfolioApiEvents } from '../../../modules/api/portfolio'
import { PortfolioActivationForm } from '../../components'

const mapStateToProps = (state: any) => ({
  items: chatbotSelectors.getActivationItems(state).immediate,
  monthlyItems: chatbotSelectors.getActivationItems(state).monthly,
})

const mapDispatchToProps = (dispatch, { portfolioId }: any) => ({
  fetchInvoiceSchedules: () => dispatch(portfolioApiEvents.invoiceSchedules_request(portfolioId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioActivationForm)

import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from './constants'
import * as selectors from './selectors'
import * as epics from './epics'

const walletTransferSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  reducers: {
    walletLookup_request: () => {},
    walletLookup_success: () => {},
  },
})

const { reducer, actions: events } = walletTransferSlice

export { events, reducer, selectors, epics }

import React from 'react'
import styles from './Segment.module.scss'

type TProps = {
  icon?: React.ReactNode
  text: string
  secondaryText?: string
  onClick?: (e: any) => void
}

const SegmentLabel = ({ icon, text, secondaryText, onClick = (e: any) => e }: TProps) => {
  return (
    <div className={styles['segment-label']}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <div className={styles.text}>
        <strong>{text}</strong>
        {secondaryText && <span>{secondaryText}</span>}
      </div>
    </div>
  )
}

export default SegmentLabel

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { customPropTypes } from '../../../../../utils'
import { FundDistribution } from '../../../../../components'
import { useSelector } from 'react-redux'
import { agencyApiSelectors } from 'modules/api/agency'
import { portfolioApiSelectors } from 'modules/api/portfolio'
import { partyApiSelectors } from 'modules/api/party'

const propTypes = {
  errors: PropTypes.any,
  invoiceType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  invoiceStatus: PropTypes.string,
  balance: PropTypes.number,
  availableFunds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onNewBeneficiaryAdded: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onBeneficiariesOrderChange: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any, propName: any, componentName: a... Remove this comment to see the full error message
      partyId: customPropTypes.uuid,
      partyTag: PropTypes.string,
      reference: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vat: PropTypes.bool,
    }),
  ),
  beneficiariesHash: PropTypes.string,
  portfolioId: PropTypes.string,
}

const defaultProps = {
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  beneficiaries: [],
}

const PaymentAllocation = (props: any): React.ReactElement => {
  const {
    errors,
    invoiceType,
    invoiceStatus,
    balance,
    onNewBeneficiaryAdded,
    onNewBeneficiaryAmountChange,
    onBeneficiariesOrderChange,
    beneficiaries,
    beneficiariesHash,
    portfolioId,
  } = props

  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)

  const getPrimaryLandlordByPortfolioId = useSelector(portfolioApiSelectors.getPrimaryLandlordByPortfolioId)
  const propertyOwnerId = getPrimaryLandlordByPortfolioId(portfolioId)
  const propertyOwnerName = useSelector((state: any) => partyApiSelectors.getPartyNameById(state)(propertyOwnerId))
  const propertyOwnerBankDetails = useSelector((state: any) =>
    partyApiSelectors.getPartyBankDetails(state)(propertyOwnerId),
  )

  const classes = cx('root', {
    'status-ready': invoiceStatus === 'Ready',
  })

  return (
    <div className={classes}>
      {/* @ts-expect-error */}
      <FundDistribution
        readonly
        errors={errors.beneficiaries}
        invoiceType={invoiceType}
        state={invoiceStatus === 'Short' ? 'warning' : 'default'}
        headerText="Payment allocation"
        invoiceStatus={invoiceStatus}
        balance={balance}
        onNewBeneficiaryAdded={onNewBeneficiaryAdded}
        onNewBeneficiaryAmountChange={onNewBeneficiaryAmountChange}
        onBeneficiariesOrderChange={onBeneficiariesOrderChange}
        beneficiaries={beneficiaries}
        agencyName={agencyName}
        agencyBankDetails={agencyBankDetails}
        propertyOwnerName={propertyOwnerName}
        propertyOwnerId={propertyOwnerId}
        propertyOwnerBankDetails={propertyOwnerBankDetails}
        beneficiariesKey={beneficiariesHash}
      />
    </div>
  )
}

PaymentAllocation.propTypes = propTypes
PaymentAllocation.defaultProps = defaultProps

export default PaymentAllocation

import { identity } from 'ramda'
import createSelector from 'selectorator'
import { NAMESPACE } from '../constants'

export const getCommunicationsState = createSelector([`api.${NAMESPACE}`], identity)

/**
 * Get a slice of the communications state
 * EG: getSlice('period')
 *
 * @param {string} key Communications state object key
 */
export const getSlice = (key: any) => createSelector([`api.${NAMESPACE}.${key}`], identity)

/**
 * Get an object containing the period from and to dates
 *
 * @returns {Object} From and To dates
 */
export const getCommunicationsPeriod = getSlice('period')

/**
 * Get a list of emails for the period
 *
 * @returns {Array} List of emails in period
 */
export const getCommunicationsEmails = getSlice('emails')

/**
 * Get a list of email filters with emails
 *
 * @returns {Array} List of emails filters
 */
export const getEmailFilters = createSelector([getCommunicationsEmails], emails => {
  const monthlyEmails = emails?.filter((email: any) => email.templateCategory === 'Monthly Emails')
  const notificationEmails = emails?.filter((email: any) => email.templateCategory === 'Notification Emails')
  const accountActivationEmails = emails?.filter((email: any) => email.templateCategory === 'Account Activation Emails')

  return [
    {
      key: 'all',
      label: 'All',
      emails,
      count: emails?.length,
    },
    {
      key: 'monthly',
      label: 'Monthly Emails',
      emails: monthlyEmails,
      count: monthlyEmails?.length,
    },
    {
      key: 'notification',
      label: 'Notification Emails',
      emails: notificationEmails,
      count: notificationEmails?.length,
    },
    {
      key: 'accountActivation',
      label: 'Account Activation Emails',
      emails: accountActivationEmails,
      count: accountActivationEmails?.length,
    },
  ]
})

import { of } from 'rxjs'
import { delay, map, mapTo, mergeMap, pluck } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { get, omit } from 'lodash-es'
import { events as uiInvoiceEvents } from '../../ui/invoices/state'
import { invoiceApiEvents } from '.'
import * as transformers from './transformers'
import { ENDPOINTS } from './constants'
import { pathOr } from 'ramda'
import { multiUserOperators } from '../../multiUser/state'
import { AnyAction } from 'redux'

/**
 * @todo move to payments feature module?
 */
export const bulkActionDelete = (action$: any, state$: any) =>
  action$.pipe(
    ofType(uiInvoiceEvents.bulkActionDelete),
    pluck('payload'),
    map(invoiceIds => invoiceApiEvents.deleteInvoices_request(invoiceIds)),
    tag('invoice/epic/bulkActionDelete'),
  )

export const bulkActionRestore = (action$: any, state$: any) =>
  action$.pipe(
    ofType(uiInvoiceEvents.bulkActionRestore),
    pluck('payload'),
    map(invoiceIds => invoiceApiEvents.restoreInvoices_request(invoiceIds)),
    tag('invoice/epic/bulkActionRestore'),
  )

/**
 * @todo move to payments feature module?
 */
export const bulkActionSend = (action$: any, state$: any) =>
  action$.pipe(
    ofType(uiInvoiceEvents.bulkActionSend),
    delay(500), // delay for animation
    pluck('payload'),
    map(invoiceIds => invoiceApiEvents.sendInvoices_request(invoiceIds)),
    tag('invoice/epic/bulkActionSend'),
  )

export const apiFetchInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.invoices_request),
    mergeMap((action: { payload: any[] }) =>
      get(ENDPOINTS.INVOICE_BASE, state$, action.payload).pipe(
        pluck('response', 'invoices'),
        map(res => invoiceApiEvents.invoices_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/apiFetchInvoices'),
  )

export const apiFetchInvoice = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.invoice_request),
    mergeMap((action: { payload: any }) =>
      get(ENDPOINTS.INVOICE_ENTITY, state$, action.payload).pipe(
        pluck('response'),
        map(res => invoiceApiEvents.invoice_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/apiFetchInvoice'),
  )

export const updateInvoice = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.invoiceUpdate_request),
    mergeMap((action: AnyAction) => {
      const { id, values } = action.payload
      const payload = omit(
        {
          ...values,
          customer: values.customer.partyId,
          customerTag: values.customer.customerTag,
        },
        ['balance', 'invoiceTypeName', 'property', 'reoccurring', 'tags'],
      )
      return put(ENDPOINTS.INVOICE_ENTITY, state$, transformers.transformUpdateInvoiceRequest(payload), { id }).pipe(
        pluck('response'),
        map(res => invoiceApiEvents.invoiceUpdate_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/updateInvoice'),
  )

export const createOnceOffInvoice = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.createOnceOffInvoice_request),
    mergeMap((action: AnyAction) => {
      const { values, sendAfterCreate } = action.payload
      return post(ENDPOINTS.INVOICE_BASE, state$, transformers.transformNewInvoiceRequest(values)).pipe(
        pluck('response'),
        map(res =>
          invoiceApiEvents.createOnceOffInvoice_success(
            transformers.transformNewInvoiceResponse(res),
            action?.meta && action.meta,
          ),
        ),
        /** @todo I don't think this is working as intended... */
        mergeMap(event => {
          const id = sendAfterCreate ? get(event, 'payload.id', false) : false
          if (id) {
            return of(event, invoiceApiEvents.sendInvoices_request([id]))
          }
          return of(event)
        }),
        catchRestError(action, false),
      )
    }),
    tag('invoice/epics/createOnceOffInvoice'),
  )

export const fetchInvoiceTypes = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.invoiceTypes_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_INVOICE_TYPES, state$).pipe(
        pluck('response', 'invoiceTypeSummaries'),
        map(invoiceApiEvents.invoiceTypes_success),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/fetchInvoiceTypes'),
  )

export const sendInvoices = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.sendInvoices_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.SEND_INVOICES, state$, { invoiceIds: action.payload }).pipe(
        pluck('response', 'invoiceResults'),
        map(invoiceApiEvents.sendInvoices_success),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/sendInvoices'),
  )

export const deleteInvoices = (action$: any, state$: any, { removeWithBody, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.deleteInvoices_request),
    delay(500), // delay for animation
    mergeMap((action: AnyAction) =>
      removeWithBody(ENDPOINTS.DELETE_INVOICES, state$, { invoiceIds: action.payload }).pipe(
        pluck('response', 'invoiceResults'),
        map(invoiceApiEvents.deleteInvoices_success),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/deleteInvoices'),
  )

export const restoreInvoices = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.restoreInvoices_request),
    delay(500), // delay for animation
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.RESTORE_INVOICES, state$, { invoiceIds: action.payload }).pipe(
        pluck('response', 'invoiceResults'),
        map(invoiceApiEvents.restoreInvoices_success),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/restoreInvoices'),
  )

export const fetchNotifications = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.notifications_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.NOTIFICATIONS, state$).pipe(
        pluck('response'),
        map(invoiceApiEvents.notifications_success),
        catchRestError(action),
      ),
    ),
    tag('invoice/epics/fetchNotifications'),
  )

export const fetchDraftInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.draftInvoices_request),
    mergeMap((action: AnyAction) => {
      const { id } = action.payload
      return get(ENDPOINTS.FETCH_DRAFT_INVOICES, state$, { id }).pipe(
        pluck('response'),
        map(res => {
          const draftInvoices = pathOr([], ['invoices'], res)
          return invoiceApiEvents.draftInvoices_success({ draftInvoices, id })
        }),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/fetchDraftInvoices'),
  )

/** @todo add test */
export const saveBulkInvoices = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.saveBulkInvoices_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      /** @todo might need a different transformer */
      return post(ENDPOINTS.BULK_INVOICES, state$, transformers.transformSaveBulkInvoiceRequest(body), params).pipe(
        pluck('response'),
        mapTo(invoiceApiEvents.saveBulkInvoices_success(null)),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/saveBulkInvoices'),
  )

/** @todo add test */
export const fetchBulkInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.fetchBulkInvoices_request),
    mergeMap((action: AnyAction) => {
      return get(ENDPOINTS.BULK_INVOICES, state$, action.payload).pipe(
        pluck('response'),
        map(res => invoiceApiEvents.fetchBulkInvoices_success(transformers.transformGetBulkInvoicesResponse(res))),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/fetchBulkInvoices'),
  )

/** @todo add test */
export const deleteBulkInvoices = (action$: any, state$: any, { remove, catchRestError }: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.deleteBulkInvoices_request),
    mergeMap((action: AnyAction) => {
      return remove(ENDPOINTS.BULK_INVOICES, state$).pipe(
        pluck('response'),
        map(invoiceApiEvents.deleteBulkInvoices_success),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/deleteBulkInvoices'),
  )

import { Epic, ofType } from 'redux-observable'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { isOfType } from 'typesafe-actions'
import { ENDPOINTS } from './constants'
import { AccountingEvent, events } from './state'
import { multiUserOperators } from '../../multiUser/state'
import { accountingApiTransformers } from '.'
import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { $TSFixMe } from 'types/ts-migrate'
import { Base64PdfResponse } from './types/Base64PdfResponse'
import { JsonResponse } from './types/JsonResponse'
import { Base64CsvResponse } from './types/Base64CsvResponse'
import { EpicDependencies, RootState } from 'src/state/store'

export const apiFetchBankStatementJson: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.bankStatementJson_request.toString())),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap(action =>
      get(ENDPOINTS.FETCH_BANK_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map((response: JsonResponse) => events.bankStatementJson_success({ response })),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchBankStatementJson'),
  )

export const apiFetchBankStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.bankStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.FETCH_BANK_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64PdfResponse) => events.bankStatementPdf_success({ response })),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchBankStatementPdf'),
  )

// export const apiFetchCustomerStatements = (
//   action$: Observable<AnyAction>,
//   state$: $TSFixMe,
//   { get, catchRestError }: $TSFixMe,
// ) =>
//   action$.pipe(
//     ofType(events.customerStatements_request),
//     mergeMap((action: AnyAction) =>
//       get(ENDPOINTS.FETCH_CUSTOMER_STATEMENTS, state$, action.payload).pipe(
//         pluck('response'),
//         map(events.customerStatements_success),
//         catchRestError(action),
//       ),
//     ),
//     tag('accounting/epics/apiFetchCustomerStatements'),
//   )

export const apiFetchCustomerStatementJson: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.customerStatementJson_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.FETCH_CUSTOMER_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map((response: JsonResponse) =>
          events.customerStatementJson_success({
            response,
            customerId: action.payload.customerId,
            accountId: action.payload.accountId,
          }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchCustomerStatementJson'),
  )

export const apiFetchCustomerStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.customerStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.FETCH_CUSTOMER_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64PdfResponse) =>
          events.customerStatementPdf_success({
            response,
            customerId: action.payload.customerId,
            accountId: action.payload.accountId,
          }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchCustomerStatementPdf'),
  )

export const apiFetchPortfolioStatement: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.portfolioStatement_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.LEASE_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map((response: JsonResponse) =>
          events.portfolioStatement_success({ response, portfolioId: action.payload.portfolioId }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchPortfolioStatement'),
  )

export const apiFetchPortfolioStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.portfolioStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.LEASE_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64PdfResponse) =>
          events.portfolioStatementPdf_success({ response, portfolioId: action.payload.portfolioId }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchPortfolioStatementPdf'),
  )

export const apiFetchDepositStatementJson: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.depositStatementJson_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.DEPOSIT_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map((response: JsonResponse) =>
          events.depositStatementJson_success({ response, accountId: action.payload.accountId }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchDepositStatementJson'),
  )

export const apiFetchDepositStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.depositStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.DEPOSIT_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64PdfResponse) =>
          events.depositStatementPdf_success({ response, accountId: action.payload.accountId }),
        ),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchDepositStatementPdf'),
  )

export const apiFetchDepositBankStatementJson: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.depositBankStatementJson_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.DEPOSIT_BANK_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map(events.depositBankStatementJson_success),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchDepositBankStatementJson'),
  )

export const apiFetchDepositBankStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.depositBankStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.DEPOSIT_BANK_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map(events.depositBankStatementPdf_success),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchDepositBankStatementPdf'),
  )

export const apiFetchCommissionStatementJson: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.commissionStatementJson_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.COMMISSION_STATEMENT_JSON, state$, action.payload).pipe(
        pluck('response'),
        map((response: JsonResponse) => events.commissionStatementJson_success({ response })),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchCommissionStatementJson'),
  )

export const apiFetchCommissionStatementPdf: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.commissionStatementPdf_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.COMMISSION_STATEMENT_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64PdfResponse) => events.commissionStatementPdf_success({ response })),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchCommissionStatementPdf'),
  )

export const apiFetchCommissionStatementCsv: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.commissionStatementCsv_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.COMMISSION_STATEMENT_CSV, state$, action.payload).pipe(
        pluck('response'),
        map((response: Base64CsvResponse) => events.commissionStatementCsv_success({ response })),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiFetchCommissionStatementCsv'),
  )

export const apiGetStats: Epic<AccountingEvent, AccountingEvent, RootState, EpicDependencies> = (
  action$,
  state$,
  { get, catchRestError },
) =>
  action$.pipe(
    filter(isOfType(events.accountingStats_request.toString())),
    mergeMap(action =>
      get(ENDPOINTS.GET_STATS_ACCOUNTING, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.accountingStats_success(accountingApiTransformers.transformStats(res))),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiGetStats'),
  )

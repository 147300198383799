import React, { ButtonHTMLAttributes, FC } from 'react'
import classNames from 'classnames'
import styles from './Button.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { PolymorphicComponentProps } from 'types/PolymorphicComponentProps'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode | undefined
  size?: 'xs' | 'sm' | 'md' | 'lg'
  contrast?: boolean
  link?: boolean
  ghost?: boolean
  block?: boolean
  pill?: boolean
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  loading?: boolean
  className?: string
}

type ButtonProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>

const Button = <C extends React.ElementType = 'button'>({
  as,
  type = 'button',
  size = 'md',
  contrast,
  link = false,
  ghost = false,
  block = false,
  pill = false,
  secondary = false,
  tertiary = false,
  children,
  disabled = false,
  loading = false,
  onClick,
  className,
  ...props
}: ButtonProps<C>) => {
  const Component = as ?? 'button'
  const styleNames = classNames([styles.root, styles[`size-${size}`]], className, {
    [styles.contrast]: contrast,
    [styles.disabled]: disabled,
    [styles.loading]: loading,
    [styles.link]: link,
    [styles.ghost]: ghost,
    [styles.block]: block,
    [styles.pill]: pill,
    [styles.secondary]: secondary,
    [styles.tertiary]: tertiary,
  })

  const isDisabled = loading || disabled

  return (
    <Component
      className={styleNames}
      type={disabled && type === 'submit' ? 'button' : type}
      onClick={disabled ? () => {} : onClick}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button

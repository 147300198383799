import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { chatbotSelectors, chatbotEvents } from '../../../modules/ui/ChatBot'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ["On activation, I'll go ahead and do the following"],

  // actions: (chatbotSelectors.getCurrentPortfolioStatus(state) === 'in progress' ? [{
  //   text: 'Send for approval',
  //   action: () => chatbotCommands.sendForApproval.invoke()
  // },
  //   text: 'Activate.',
  //   action: () => chatbotCommands.approveCurrentPortfolio.invoke()
  // }] : [((chatbotSelectors.getCurrentPortfolioStatus(state) === 'declined') ? {
  //   text: 'Resend for approval.',
  //   action: () => chatbotCommands.sendForApproval.invoke()
  // } : {
  //   text: 'Decline.',
  //   action: () => chatbotEvents.portfolioRedirect('/leases/:id/edit/decline')
  // }), {
  //   text: 'Activate.',
  //   action: () => chatbotCommands.approveCurrentPortfolio.invoke()
  // }])
  actions:
    chatbotSelectors.getCurrentPortfolioStatus(state) === 'in progress'
      ? [
          {
            text: 'Activate',
            action: () => chatbotEvents.currentPortfolioActivationConfirmationTriggered(),
          },
        ]
      : [],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (actionProvider: any) => {
    const action = actionProvider()
    if (get(action, 'type', false) || get(action, 'meta', false)) {
      dispatch(action)
    }
  },
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

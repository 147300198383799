import React from 'react'
import PropTypes from 'prop-types'

const ExpiredInvitation = ({ invitation, adminEmail }: any) => {
  const expiredTime = '2 days ago'

  return (
    <div>
      <h2>Invitation Expired</h2>
      <p>
        Your invite expired {expiredTime}. Please contact admin ({adminEmail}) to request another invite.
      </p>
    </div>
  )
}

ExpiredInvitation.propTypes = {
  invitation: PropTypes.object,
  adminEmail: PropTypes.string,
}

export default ExpiredInvitation

import React from 'react'
import { moduleContext } from 'react-contextual'

const NotificationCentreProvider = moduleContext()(({ context, children, ...props }: any) => {
  const notificationCentreStore = {
    ...props,
  }
  return <context.Provider value={notificationCentreStore}>{children}</context.Provider>
})

export default NotificationCentreProvider

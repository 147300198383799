import React from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import CommunicationsProvider from '../CommunicationsProvider'
import { ListingTemplate, TableProvider } from '../../../views/components'
import { EmailLogSearchFilter, EmailLogTable } from '..'
import { useEffectOnce } from 'react-use'
import { lastDayOfMonth, format } from 'date-fns'

const propTypes = {
  match: PropTypes.object,
  userRole: PropTypes.string,
  isLoading: PropTypes.bool,
  fetchEmails: PropTypes.func,
}

const CommunicationsPage = ({ isLoading, fetchEmails }: any) => {
  const filterStartDate = new Date().setDate(1)
  const filterEndDate = lastDayOfMonth(new Date())

  useEffectOnce(() => {
    fetchEmails(format(filterStartDate, 'yyyy-MM-dd'), format(filterEndDate, 'yyyy-MM-dd'))
  })

  return (
    <>
      <Helmet>
        <title>reOS | Communications</title>
      </Helmet>
      <TableProvider>
        <ListingTemplate loading={isLoading} Header={<EmailLogSearchFilter />} Body={<EmailLogTable />} />
      </TableProvider>
    </>
  )
}

CommunicationsPage.propTypes = propTypes

export default subscribe([CommunicationsProvider], ({ isLoading, fetchEmails }: any) => ({
  isLoading,
  fetchEmails,
}))(CommunicationsPage)

import React, { useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { InvoicesProvider } from '../../../../providers'
import { ListingTemplate } from '../../..'
import { TableProvider } from '../../../organisms/Table'
import HistoryTable from './HistoryTable/HistoryTable'
import SearchFilter from './SearchFilter'
import { addMonths, format, subMonths } from 'date-fns'

function History({ fetchHistoricInvoices, isHistoryLoading }: any) {
  useEffect(() => {
    fetchHistoricInvoices(
      format(subMonths(new Date(), 3), 'yyyy-MM-dd'),
      format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>reOS | Invoices | History</title>
      </Helmet>
      <TableProvider>
        <ListingTemplate loading={isHistoryLoading} Header={<SearchFilter />} Body={<HistoryTable />} />
      </TableProvider>
    </>
  )
}

export default subscribe([InvoicesProvider], ({ fetchHistoricInvoices, isHistoryLoading }: any) => ({
  fetchHistoricInvoices,
  isHistoryLoading,
}))(History)

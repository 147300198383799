import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FieldArray, Form, withFormik } from 'formik'
import { get } from 'lodash-es'
import * as yup from 'yup'
import { Button, Divider, Grid, Segment } from '../../../../components'
import InvoicePanel from './InvoicePanel/InvoicePanel'
import styles from './InvoicesForm.module.scss'
import { leaseTypes } from 'modules/api/portfolio/constants'
import { $TSFixMe } from 'types/ts-migrate'
import RouterPromptModal from '../../Modals/RouterPromptModal/RouterPromptModal'

const propTypes = {
  agencyId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  currentPortfolioId: PropTypes.string,
  handleSubmit: PropTypes.func,
  leaseType: PropTypes.string,
  initialValues: PropTypes.object,
  emptyInvoice: PropTypes.object,
  fetchInvoiceTypes: PropTypes.func,
  monthlyInvoiceTypes: PropTypes.arrayOf(PropTypes.object),
  onceOffInvoiceTypes: PropTypes.arrayOf(PropTypes.object),
  getInvoiceTypeNameByValue: PropTypes.func,
  invoiceParties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultBeneficiaries: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  handleInvoiceTemplateChanged: PropTypes.func,
  getPartyTagsById: PropTypes.func,
  fetchPartyTags: PropTypes.func,
  /** List of party tags for selection */
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  easyPayData: PropTypes.object,
  fetchEasyPayData: PropTypes.func,
  agencyName: PropTypes.string.isRequired,
  agencyBankDetails: PropTypes.object,
  isActivePortfolio: PropTypes.bool,
  isDraftPortfolio: PropTypes.bool,
  propertyOwnerName: PropTypes.string,
  propertyOwnerBankDetails: PropTypes.object,
  getPartyBankDetails: PropTypes.func,
  redirect: PropTypes.func,
  selectedtenant: PropTypes.string,
  isReadOnly: PropTypes.bool,
  currentAgencyId: PropTypes.func,
  getPartyNameById: PropTypes.func,
  getInvoiceTypeByValue: PropTypes.func,
  isCurrentAgencyGlobalVatEnabled: PropTypes.bool,
}

const defaultProps = {}

const clearanceSchema = yup.object({
  type: yup.string(),
  value: yup.object({
    grossAmount: yup.string(), // string for currency formatting
    vat: yup.boolean(),
  }),
})

const validationSchema = yup.object().shape({
  invoices: yup.array(
    yup.object().shape({
      autoDeliver: yup.boolean(),
      category: yup.string().required('required'),
      invoicePartyId: yup.string().required('required'),
      interval: yup.string().required('required.'),
      netAmount: yup.string(),
      vatable: yup.boolean(),
      paymentRules: yup
        .array()
        .of(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Lazy<OptionalObjectSchema<{ bene... Remove this comment to see the full error message
          yup.lazy(function (pr) {
            return pr.beneficiary.type === 'EasyPayBeneficiary'
              ? yup.object({
                  beneficiary: yup.object({
                    type: yup.string(),
                    value: yup.object({
                      beneficiaryId: yup.string(),
                      beneficiaryTag: yup.string(),
                      easyPayReference: yup.string(),
                      amount: yup.string(), // string for currency formatting
                      name: yup.string(),
                    }),
                  }),
                  clearance: clearanceSchema,
                })
              : yup.object({
                  beneficiary: yup.object({
                    type: yup.string(),
                    value: yup.object({
                      partyId: yup.string(),
                      partyTag: yup.string(),
                      reference: yup.string(),
                      amount: yup.string(), // string for currency formatting
                      vat: yup.boolean(),
                      name: yup.string(),
                    }),
                  }),
                  clearance: clearanceSchema,
                })
          }),
        )
        .test('paymentRules', 'Payment allocations exceed invoice amount, please adjust.', function (val) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'parent' does not exist on type 'Validate... Remove this comment to see the full error message
          const { parent } = this.options
          const netAmount = parseFloat(parent.netAmount)
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const paymentRulesTotal = val.reduce((acc, val) => {
            let amount = parseFloat(val.beneficiary.value.amount)
            if (amount === undefined || isNaN(amount)) {
              amount = 0
            }
            return acc + amount
          }, 0)
          if (!netAmount && !paymentRulesTotal) {
            return true
          }
          if (netAmount && !paymentRulesTotal) {
            return true
          }
          return paymentRulesTotal <= netAmount
        }),
      description: yup.string(),
    }),
  ),
})

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
  // @ts-ignore FIXME: Property 'initialValues' does not exist on type 'object'
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-ignore FIXME: Property 'initialValues' does not exist on type 'object'
    props.handleSubmit(values)
  },
})
class InvoicesForm extends Component<$TSFixMe, $TSFixMe> {
  componentDidMount(): void {
    const { fetchInvoiceTypes, fetchPartyTags } = this.props
    fetchInvoiceTypes()
    fetchPartyTags()
  }

  disableEdit = (i: any) => {
    this.props.setFieldValue(`invoices[${i}].editing`, false)
  }

  enabledEdit = (i: any) => {
    this.props.setFieldValue(`invoices[${i}].editing`, true)
  }

  toggleEdit = (index: number) => {
    this.props.values.invoices.forEach((inv: any, i: any) => {
      if (i === index) {
        this.enabledEdit(i)
      } else {
        this.disableEdit(i)
      }
    })
  }

  addInvoice = (push: any) => {
    const { emptyInvoice, values } = this.props
    push({ ...emptyInvoice, interval: 'BeginningOfMonth' })
    values.invoices.forEach((inv: any, i: any) => {
      this.disableEdit(i)
    })
  }

  addOnceoffInvoice = (push: any) => {
    const { emptyInvoice, values } = this.props
    push({ ...emptyInvoice, interval: 'OnceOff', isEmpty: true })
    values.invoices.forEach((inv: any, i: any) => {
      this.disableEdit(i)
    })
  }

  isCurrentInvoiceTemplateEmpty = () => {
    const { values, emptyInvoice } = this.props
    if (values.invoices.length === 0) {
      return false
    }

    const currentInvoice = values.invoices.filter((inv: any) => inv.editing)
    return currentInvoice[0] === emptyInvoice
  }

  handleSubmit = () => {
    this.props.handleSubmit()
  }

  render(): null | React.ReactElement {
    const {
      agencyId,
      ownerId,
      ownerName,
      currentPortfolioId,
      leaseType,
      monthlyInvoiceTypes,
      onceOffInvoiceTypes,
      invoiceParties,
      getInvoiceTypeNameByValue,
      defaultBeneficiaries,
      values,
      errors,
      handleInvoiceTemplateChanged,
      handleInvoiceTemplateRemoved,
      getPartyTagsById,
      partyTags,
      easyPayData,
      fetchEasyPayData,
      agencyName,
      agencyBankDetails,
      propertyOwnerName,
      propertyOwnerBankDetails,
      getPartyBankDetails,
      getPartyNameById,
      getInvoiceTypeByValue,
      isCurrentAgencyGlobalVatEnabled,
      // touched,
      dirty,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      validateForm,
      isActivePortfolio,
      isDraftPortfolio,
      selectedTenant,
      redirect,
      isReadOnly,
    } = this.props

    return (
      <div className={styles.root}>
        <RouterPromptModal
          when={dirty}
          isValid={isValid}
          onSave={this.handleSubmit}
          onDiscard={() => {
            return true
          }}
        />
        {selectedTenant !== null ? (
          <Form className={styles.form} onSubmit={this.handleSubmit}>
            <fieldset disabled={isReadOnly}>
              <div className={styles.body}>
                <FieldArray
                  name="invoices"
                  render={arrayHelpers => {
                    const monthlyInvoices: any = []
                    const onceoffInvoices: any = []
                    values.invoices.forEach((invoice: any, index: number) => {
                      if (invoice.interval === 'OnceOff') {
                        onceoffInvoices.push(
                          <InvoicePanel
                            key={index}
                            agencyId={agencyId}
                            ownerId={ownerId}
                            ownerName={ownerName}
                            invoiceTypes={onceOffInvoiceTypes}
                            getInvoiceTypeNameByValue={getInvoiceTypeNameByValue}
                            invoiceParties={invoiceParties}
                            defaultBeneficiaries={defaultBeneficiaries}
                            invoice={invoice}
                            index={index}
                            toggleEdit={this.toggleEdit}
                            handleInvoiceTemplateChanged={handleInvoiceTemplateChanged}
                            handleInvoiceTemplateRemoved={(id: any) => {
                              arrayHelpers.handleRemove(index)
                              id && handleInvoiceTemplateRemoved(id)
                            }}
                            getPartyTagsById={getPartyTagsById}
                            partyTags={partyTags}
                            // Formik helpers
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            validateForm={validateForm}
                            arrayHelpers={arrayHelpers}
                            errors={get(errors, `invoices[${index}]`, false)}
                            isSubmitting={isSubmitting}
                            easyPayData={easyPayData}
                            fetchEasyPayData={fetchEasyPayData}
                            agencyName={agencyName}
                            agencyBankDetails={agencyBankDetails}
                            getPartyBankDetails={getPartyBankDetails}
                            propertyOwnerId={ownerId}
                            propertyOwnerName={propertyOwnerName}
                            propertyOwnerBankDetails={propertyOwnerBankDetails}
                            portfolioId={currentPortfolioId}
                            getPartyNameById={getPartyNameById}
                            getInvoiceTypeByValue={getInvoiceTypeByValue}
                            isCurrentAgencyGlobalVatEnabled={isCurrentAgencyGlobalVatEnabled}
                          />,
                        )
                      } else if (leaseType !== leaseTypes.unmanaged) {
                        monthlyInvoices.push(
                          <InvoicePanel
                            agencyId={agencyId}
                            ownerId={ownerId}
                            ownerName={ownerName}
                            key={index}
                            invoiceTypes={monthlyInvoiceTypes}
                            getInvoiceTypeNameByValue={getInvoiceTypeNameByValue}
                            invoiceParties={invoiceParties}
                            defaultBeneficiaries={defaultBeneficiaries}
                            invoice={invoice}
                            index={index}
                            toggleEdit={this.toggleEdit}
                            handleInvoiceTemplateChanged={handleInvoiceTemplateChanged}
                            handleInvoiceTemplateRemoved={(id: any) => {
                              arrayHelpers.handleRemove(index)
                              id && handleInvoiceTemplateRemoved(id)
                            }}
                            getPartyTagsById={getPartyTagsById}
                            partyTags={partyTags}
                            // Formik helpers
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            validateForm={validateForm}
                            arrayHelpers={arrayHelpers}
                            errors={get(errors, `invoices[${index}]`, false)}
                            isSubmitting={isSubmitting}
                            easyPayData={easyPayData}
                            fetchEasyPayData={fetchEasyPayData}
                            agencyName={agencyName}
                            agencyBankDetails={agencyBankDetails}
                            propertyOwnerId={ownerId}
                            propertyOwnerName={propertyOwnerName}
                            propertyOwnerBankDetails={propertyOwnerBankDetails}
                            getPartyBankDetails={getPartyBankDetails}
                            portfolioId={currentPortfolioId}
                            getPartyNameById={getPartyNameById}
                            getInvoiceTypeByValue={getInvoiceTypeByValue}
                            isCurrentAgencyGlobalVatEnabled={isCurrentAgencyGlobalVatEnabled}
                          />,
                        )
                      }
                    })
                    return (
                      <React.Fragment>
                        <Divider>
                          <div className={styles['divider-center-label']}>
                            <h2>
                              {leaseType === leaseTypes.unmanaged ? 'Once off invoices' : 'Monthly recurring invoices'}
                            </h2>
                          </div>
                        </Divider>
                        {monthlyInvoices}
                        {!this.isCurrentInvoiceTemplateEmpty() && leaseType !== leaseTypes.unmanaged && (
                          <Segment.Group
                            interactive
                            className={styles['add-invoice']}
                            onClick={this.addInvoice.bind(null, arrayHelpers.push)}
                          >
                            <Segment>
                              <Grid>
                                <Grid.Column xs={12} md={11}>
                                  <h4>Add another monthly recurring invoice &#43;</h4>
                                </Grid.Column>
                              </Grid>
                            </Segment>
                          </Segment.Group>
                        )}
                        {isDraftPortfolio && (
                          <>
                            {leaseType !== leaseTypes.unmanaged && (
                              <Divider>
                                <div className={styles['divider-center-label']}>
                                  <h2>Once off invoices</h2>
                                </div>
                              </Divider>
                            )}
                            {onceoffInvoices}
                            {!this.isCurrentInvoiceTemplateEmpty() && (
                              <Segment.Group
                                interactive
                                className={styles['add-invoice']}
                                onClick={this.addOnceoffInvoice.bind(null, arrayHelpers.push)}
                              >
                                <Segment>
                                  <Grid>
                                    <Grid.Column xs={12} md={11}>
                                      <h4>Add another once off invoice &#43;</h4>
                                    </Grid.Column>
                                  </Grid>
                                </Segment>
                              </Segment.Group>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    )
                  }}
                />
              </div>
              <div className={styles.footer}>
                <Button loading={isSubmitting} type="submit">
                  {isActivePortfolio ? 'Save' : "Done, let's continue"}
                </Button>
              </div>
            </fieldset>
          </Form>
        ) : (
          <>
            <p>You need to add a tenant before you can configure invoices.</p>
            <Button onClick={() => redirect(`/leases/${currentPortfolioId}/edit/tenants`)}>Add tenant</Button>
          </>
        )}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
InvoicesForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
InvoicesForm.defaultProps = defaultProps

export default InvoicesForm

import { ofType } from 'redux-observable'
import { pluck, map, mergeMap, mapTo } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { partyApiEvents, partyApiSelectors } from '../../api/party'
import { portfolioApiEvents, portfolioApiSelectors, portfolioApiUtils } from '../../api/portfolio'
import { events } from './state'
import { adhocInvoiceEvents } from '../../adhocInvoice/state'
import { uiEvents } from '../../ui'
import { chatbotEvents } from '../../ui/ChatBot'
import { path, map as _map, pipe, pathOr } from 'ramda'
import { TApplicationsResponse } from 'modules/api/portfolio/types'
import { $TSFixMe } from 'types/ts-migrate'
import { reconApiEvents } from 'modules/api/recon'
import { invoiceApiEvents } from 'modules/api/invoice'

// export const prefetchTenantsWhenPropertiesAreFetched = (action$, state$) =>
//   action$.pipe(
//     ofType(propertyApiEvents.properties_success),
//     pluck('payload'),
//     map(properties => properties.map(p => p.tenantId).filter(id => !!id)),
//     map(tenantIds => partyApiEvents.parties_request(tenantIds)),
//     tag('module/properties/prefetchTenantsWhenPropertiesAreFetched')
//   )

// export const prefetchOwnersWhenPropertiesAreFetched = (action$, state$) =>
//   action$.pipe(
//     ofType(propertyApiEvents.properties_success),
//     pluck('payload'),
//     map(properties => properties.map(p => p.ownerId).filter(id => !!id)),
//     map(ownerIds => partyApiEvents.parties_request(ownerIds)),
//     tag('module/properties/prefetchOwnersWhenPropertiesAreFetched')
//   )

export const fetchTenantsAndLandlordsWhenPortfolioIsFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.portfolio_success),
    pluck('payload'),
    map(portfolio => {
      const ids = portfolioApiUtils.getTenantAndLandlordIdsFromPortfolio(portfolio)
      return partyApiEvents.parties_request(ids)
    }),
    tag('module/properties/fetchTenantsAndLandlordsWhenPortfolioIsFetched'),
  )

export const fetchApplicationsWhenPortfolioIsLoaded = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.portfolio_success),
    pluck('payload'),
    map((portfolio: $TSFixMe) => portfolioApiEvents.getApplications_request({ id: portfolio.id })),
    tag('module/properties/fetchApplicationsWhenPortfolioIsLoaded'),
  )

export const fetchApplicationPartiesWhenPortfolioIsFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.getApplications_success),
    pluck('payload'),
    map((res: TApplicationsResponse) => {
      const ids = res.applications.map(app => app.partyId)
      return partyApiEvents.parties_request(ids)
    }),
    tag('module/properties/fetchApplicationPartiesWhenPortfolioIsFetched'),
  )

export const openDepositTopupRequestModal = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.depositTopupRequestOpened),
    pluck('payload'),
    map(portfolioId => {
      const state = state$.value
      const tenantId = portfolioApiSelectors.getTenantByPortfolioId(state)(portfolioId)
      const tenantName = partyApiSelectors.getPartyNameById(state)(tenantId)
      return {
        isDepositInvoice: true,
        customer: {
          id: tenantId,
          text: tenantName,
          partyTag: 'Tenant',
        },
        portfolioId,
        invoiceType: {
          label: 'Deposit Top Up',
          value: 'DepositTopUp',
        }, // waiting on backend to include this invoice type
        dueDate: '',
        vat: false,
        amount: '',
        beneficiaries: [
          {
            beneficiary: {
              type: 'DepositBeneficiary',
              value: {
                partyId: tenantId,
                reference: `${tenantName} Deposit Top Up`,
                beneficiaryTag: 'TenantDepositAccount',
                amount: '',
                vat: false,
                transfer: true,
              },
            },
          },
        ],
      }
    }),
    mergeMap(initialValues => [
      uiEvents.redirect('/invoices/new'),
      adhocInvoiceEvents.initialValuesUpdateRequested(initialValues),
    ]),
    tag('module/properties/openDepositTopupRequestModal'),
  )

/**
 * @todo maybe move all portfolio party fetching logic here so it's one place?
 */
export const prefetchCommissionSplitPartiesOnRenewal = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.setCurrentPortfolio),
    pluck('payload'),
    map(pipe(pathOr([], ['commission', 'managementFee', 'splits']), _map(path(['agentPartyId'])))),
    map(partyApiEvents.parties_request),
    tag('module/properties/prefetchCommissionSplitPartiesOnRenewal'),
  )

export const refetchSummariesWhenTenantsOrOwnersUpdated = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.updateOwnerParties_success, portfolioApiEvents.updateTenantParties_success),
    mapTo(portfolioApiEvents.portfolioSummaries_request()),
    tag('module/properties/refetchSummariesWhenTenantsOrOwnersUpdated'),
  )

/**
 * Fetch invoices per application where there isn't a associated recon status (yet)
 * @link https://app.clickup.com/t/2z0u6eb?comment=90050003706141
 */
export const fetchApplicationInvoices = (action$: any, state$: any) =>
  action$.pipe(
    ofType(reconApiEvents.applicationInvoiceByPortfolio_success),
    mergeMap((action: $TSFixMe) => {
      const portfolioId = action?.meta?.portfolioId
      const applications = portfolioApiSelectors.getAllApplicationsByPortfolioId(state$.value)(portfolioId)
      const applicationIds = applications.map(({ applicationId }) => applicationId)
      const reconStatusApplicationIds = action.payload.map(({ applicationId }) => applicationId)
      const applicationIdsWithoutStatus = applicationIds.filter(id => !reconStatusApplicationIds.includes(id))
      return applicationIdsWithoutStatus.map(id => invoiceApiEvents.invoice_request({ id }))
    }),
    tag('module/portfolios/fetchApplicationInvoices'),
  )

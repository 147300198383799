import { ofType } from 'redux-observable'
import { delay, map, mergeMap, pluck } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { filter as _filter, flatten, join, map as Rmap, path, pathOr, pipe, uniq } from 'ramda'
import { ENDPOINTS } from './constants'
import { events } from './state'
import * as selectors from './selectors'
import * as transformers from './transformers'
import { events as uiInvoiceEvents } from '../../ui/invoices/state'
import { multiUserOperators } from '../../multiUser/state'
import { bankingApiConstants, bankingApiEvents } from '../banking'
import { invoiceApiTransformers } from '../invoice'
import { Action, AnyAction } from 'redux'
import { $TSFixMe } from 'types/ts-migrate'
import { TApplicationInvoiceStatus } from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { reconApiSelectors } from '.'
import { depositInvoiceTypes } from '../invoice/constants'
import { addDays, format, subDays } from 'date-fns'

export const apiFetchInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.invoices_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_INVOICES, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.invoices_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchInvoices'),
  )

export const apiFetchInvoice = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.invoice_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_INVOICE, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.invoice_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchInvoice'),
  )

export const apiFetchCreditNoteReasons = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.creditNoteReasons_request),
    mergeMap(action =>
      get(ENDPOINTS.FETCH_CREDIT_NOTE_REASONS, state$).pipe(
        pluck('response', 'creditNoteReasonSummaries'),
        map(events.creditNoteReasons_success),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchCreditNoteReasons'),
  )

export const apiCreditNoteInvoice = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(events.creditNote_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.CREDIT_NOTE_INVOICE, state$, transformers.transformCreditNoteRequest(body), params).pipe(
        pluck('response'),
        map(res =>
          events.creditNote_success(transformers.transformInvoiceResponse(res), {
            id: params.id,
            creditNoteAmount: body.amount,
          }),
        ),
        catchRestError(action, false),
      )
    }),
    tag('recon/epics/apiCreditNoteInvoice'),
  )

export const apiUpdateDepositBeneficiaryOnCreditNoteSuccess = (
  action$: any,
  state$: any,
  { post, catchRestError }: any,
) =>
  action$.pipe(
    ofType(events.creditNote_success),
    mergeMap((action: AnyAction) => {
      const { meta } = action
      // find invoice using selector and invoice ID from credit success payload
      const invoice = reconApiSelectors.getInvoiceById(state$.value)(meta.id)

      // check if it's a credit note for a deposit invoice
      const isDepositInvoice = depositInvoiceTypes.includes(invoice.invoiceType)

      if (!isDepositInvoice) {
        return []
      }

      const beneficiaryAmount = invoice.beneficiaries?.[0]?.beneficiary?.value?.amount || 0

      /** Calculate the new amount */
      const newAmount = beneficiaryAmount - meta.creditNoteAmount

      // update deposit beneficiary
      const beneficiaries = invoice.beneficiaries?.map((b: $TSFixMe) => {
        return {
          ...b,
          beneficiary: {
            ...b.beneficiary,
            value: {
              ...b.beneficiary.value,
              amount: newAmount,
            },
          },
        }
      })
      return [
        events.updateBenficiaries_request({
          body: beneficiaries,
          params: { invoiceId: meta.id },
        }),
      ]
    }),
    tag('recon/epics/apiUpdateDepositBeneficiaryOnCreditNoteSuccess'),
  )

export const apiCreatePayment = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(events.payment_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.CREATE_PAYMENT, state$, transformers.transformCreatePaymentRequest(body), params).pipe(
        pluck('response'),
        map(res => events.payment_success(transformers.transformInvoiceResponse(res))),
        catchRestError(action),
      )
    }),
    tag('recon/epics/apiCreatePayment'),
  )

export const apiUpdateBeneficiaries = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(events.updateBenficiaries_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return put(
        ENDPOINTS.UPDATE_BENEFICIARIES,
        state$,
        transformers.transformUpdateBeneficiariesRequest(body),
        params,
      ).pipe(
        pluck('response'),
        map((res): Action => {
          return events.updateBenficiaries_success(transformers.transformUpdateBeneficiariesResponse(res), {
            invoiceId: params.invoiceId,
          })
        }),
        catchRestError(action),
      )
    }),
    tag('recon/epics/apiUpdateBeneficiaries'),
  )

export const apiNudge = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(events.invoicesNudge_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.NUDGE, state$, action.payload).pipe(
        pluck('response'),
        map(events.invoicesNudge_success),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiNudge'),
  )

export const apiFetchNotifications = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.notifications_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.NOTIFICATIONS, state$, action.payload).pipe(
        pluck('response'),
        map(events.notifications_success),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchNotifications'),
  )

export const apiFetchCustomerActiveInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.customerActiveInvoices_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.CUSTOMER_OPEN_INVOICES, state$, action.payload).pipe(
        pluck('response'),
        map(response =>
          events.customerActiveInvoices_success(transformers.transformActiveInvoices(response), action.payload),
        ),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchCustomerActiveInvoices'),
  )

export const apiFetchCustomerAccountActivity = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.customerAccountActivity_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.CUSTOMER_ACCOUNT_ACTIVITY, state$, action.payload).pipe(
        pluck('response'),
        map(response =>
          events.customerAccountActivity_success(
            transformers.transformCustomerAccountActivity(response),
            action.payload,
          ),
        ),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiFetchCustomerAccountActivity'),
  )

export const bulkActionApprovePayments = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(uiInvoiceEvents.bulkActionApprovePayments),
    delay(500), // delay for animation
    pluck('payload'),
    mergeMap((invoiceIds: string[]) => {
      const beneficiaryPartyIds = pipe(
        Rmap(selectors.getInvoiceParties(state$.value)),
        flatten,
        _filter((id: any) => id),
        uniq,
        join(','),
      )(invoiceIds)

      return invoiceIds
        .map((invoiceId: any) => ({
          invoiceId,
          beneficiaries: selectors.getInvoiceBeneficiariesForPaymentRequest(state$.value)(invoiceId),
          beneficiaryPartyIds,
        }))
        .filter(({ beneficiaries }: any) => beneficiaries.length > 0)
    }),
    mergeMap(({ invoiceId, beneficiaries, beneficiaryPartyIds }) => {
      const payload = { party_ids: beneficiaryPartyIds }
      return get(bankingApiConstants.ENDPOINTS.FETCH_PARTIES_ERRORS, state$, payload)
        .pipe(
          pluck('response'),
          map(response => bankingApiEvents.partiesErrors_success({ response, params: payload })),
          catchRestError(bankingApiEvents.partiesErrors_request({ party_ids: beneficiaryPartyIds })),
        )
        .pipe(
          map(action => {
            const response = path(['payload', 'response'], action)
            return response !== null ? action : events.payment_request({ body: beneficiaries, params: { invoiceId } })
          }),
        )
    }),
    tag('recon/epic/bulkActionApprovePayments'),
  )

export const bulkActionNudgeInvoices = (action$: any, state$: any) =>
  action$.pipe(
    ofType(uiInvoiceEvents.bulkActionNudgeInvoices),
    delay(500), // delay for animation
    pluck('payload'),
    map(invoiceIds => events.invoicesNudge_request({ invoiceIds })),
    tag('recon/epic/bulkActionNudgeInvoices'),
  )

export const apiFetchEasyPayData = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.easypayData_request),
    mergeMap((action: AnyAction) => {
      const { reference } = action.payload
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return get(`${ENDPOINTS.EASYPAY}?reference=${reference}`, state$).pipe(
        pluck('response'),
        map(res => events.easypayData_success(res, { reference })),
        catchRestError(action),
      )
    }),
    tag('recon/epics/apiFetchEasyPayData'),
  )

export const fetchActiveInvoices = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.invoicesActive_request),
    mergeMap((action: AnyAction) => {
      const { id } = action.payload
      return get(ENDPOINTS.FETCH_ACTIVE_INVOICES, state$, { id }).pipe(
        pluck('response'),
        map(res => {
          const activeInvoices = pathOr([], ['invoices'], res)
          return events.invoicesActive_success({ activeInvoices, id })
        }),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/fetchActiveInvoices'),
  )

export const apiBulkInvoiceUpload = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(events.bulkInvoiceUpload_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.BULK_INVOICE_UPLOAD, state$, action.payload).pipe(
        pluck('response'),
        map((res: $TSFixMe) => {
          return res?.errors
            ? events.bulkInvoiceUpload_failed(res.errors)
            : events.bulkInvoiceUpload_success(invoiceApiTransformers.transformGetBulkInvoicesResponse(res))
        }),
        catchRestError(action),
      ),
    ),
    tag('recon/epics/apiBulkInvoiceUpload'),
  )

export const fetchStats = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.stats_request),
    mergeMap(action => {
      return get(ENDPOINTS.STATS, state$).pipe(
        pluck('response'),
        map(res => events.stats_success(transformers.transformStats(res))),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/fetchStats'),
  )

export const fetchApplicationInvoiceStatusByPortfolioId = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.applicationInvoiceByPortfolio_request),
    mergeMap((action: PayloadAction<TApplicationInvoiceStatus[]>) => {
      return get(ENDPOINTS.APPLICATION_INVOICES_STATUS, state$, action.payload).pipe(
        pluck('response'),
        map((res: TApplicationInvoiceStatus[]) => events.applicationInvoiceByPortfolio_success(res, action.payload)),
        catchRestError(action),
      )
    }),
    tag('invoice/epics/fetchApplicationInvoiceStatusByPortfolioId'),
  )

export const apiFetchArchived = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.archived_request),
    mergeMap((action: AnyAction) => {
      const { startDate, endDate } = action.payload
      const startDateMinusOneDay = format(subDays(new Date(startDate), 1), 'yyyy-MM-dd')
      const endDatePlusOneDay = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd')
      return get(ENDPOINTS.ARCHIVED, state$, { startDate: startDateMinusOneDay, endDate: endDatePlusOneDay }).pipe(
        pluck('response'),
        map(res => events.archived_success(transformers.transformInvoiceResponse(res), { startDate, endDate })),
        catchRestError(action),
      )
    }),
    tag('recon/epics/apiFetchArchived'),
  )

import { useState } from 'react'
import { $TSFixMe } from 'types/ts-migrate'

/**
 * Example usage:
 * const [open, toggleOpen] = useToggle()
 *
 * @param {boolean} defaultVal default value
 */
function useToggle(defaultVal = false): $TSFixMe {
  const [state, setState] = useState(defaultVal)

  const toggle = (): void => setState(!state)

  return [state, toggle]
}

export default useToggle

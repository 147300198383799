import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { currencyUtils, dateUtils } from '../../../../utils'
import Button from '../../../../views/components/atoms/Button/Button'
import Table from '../../../../views/components/organisms/Table/Table'
import styles from './CommissionAccount.module.scss'

const { formatCurrency } = currencyUtils

const propTypes = {
  className: PropTypes.string,
  balance: PropTypes.number.isRequired,
  pending: PropTypes.number,
  onWithdraw: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      agentName: PropTypes.string,
      propertyAddress: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
  isReadOnly: PropTypes.bool,
}

const CommissionAccount = ({
  className,
  currentAgencyId,
  balance,
  isReadOnly,
  pending,
  onWithdraw,
  items = [],
}: any) => {
  const classes = cx([styles.root, className])

  return (
    <section className={classes}>
      <header>
        <div className={styles.amounts}>
          <h4 className={styles.title}>Commission Wallet</h4>
          <p>
            <strong>{formatCurrency(balance)}</strong>
            {pending && <span>{formatCurrency(pending)} Pending</span>}
          </p>
        </div>
        <div className={styles.actions}>
          <Button disabled={!balance || balance === 0 || isReadOnly} onClick={onWithdraw}>
            Withdraw Funds
          </Button>
        </div>
      </header>
      {items.length > 0 && (
        <div className={styles['statement-summary']}>
          <Table type="simple">
            <Table.Head>
              <Table.Row>
                <Table.Cell head>Date</Table.Cell>
                <Table.Cell head>Description</Table.Cell>
                <Table.Cell head style={{ justifyContent: 'flex-end' }}>
                  Amount
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {items.map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <small>{dateUtils.formatStandard(item.date)}</small>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>{item.agentName}</strong> &nbsp; {item.propertyAddress}
                  </Table.Cell>
                  <Table.Cell style={{ justifyContent: 'flex-end' }}>{formatCurrency(item.amount)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </section>
  )
}

CommissionAccount.propTypes = propTypes

export default CommissionAccount

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { isEmpty } from 'lodash-es'
import { apiStateSelectors } from 'modules/api/apiState'
import { userApiEvents } from 'modules/api/user'
import { Formik } from 'formik'
import { formUtils } from 'utils/index'
import styles from './EnableUserForm.module.scss'
import { FormErrors, FormField, FormLoader, TextField, TextInput } from 'views/components'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required.').email('Email is invalid'),
  clickupRef: yup.string().required('ClickUp Ref is required.'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const EnableUserForm = () => {
  const dispatch = useDispatch()

  const isSubmitting = useSelector(state => apiStateSelectors.isLoading(state)([userApiEvents.enableUser_request]))
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <div className={styles.root}>
      <h2>Enable a user</h2>
      <p>This will enable a user into the onboarding process.</p>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          reason: 'Activation request',
          isTester: false,
          clickupRef: '',
        }}
        onSubmit={values => {
          dispatch(
            userApiEvents.enableUser_request({
              email: values.email,
              reason: values.reason,
              isTester: values.isTester,
              clickupRef: values.clickupRef,
            }),
          )
        }}
      >
        {({ errors, touched, handleChange, handleBlur, handleSubmit, submitCount, values }) => {
          const enableUserEvent = userApiEvents.enableUser_request(values)
          const generalErrors = getGeneralFormErrorsByEvent(enableUserEvent)
          const fieldErrors = getFormFieldErrorsByEvent(enableUserEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          return (
            <>
              <FormErrors errors={generalErrors} />
              <FormLoader
                persistSuccessMessage
                onSubmit={handleSubmit}
                state={formState}
                ButtonContainer={ButtonContainer}
                buttonProps={{ children: 'Enable User' }}
                successText={'User enabled'}
                errorText={hasApiErrors ? 'Problem enabling user' : 'Resolve errors'}
                persistErrorMessage={!hasApiErrors}
              >
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.email}
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                    }
                    error={touched.email && errors.email}
                  />
                </FormField>
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.text}
                        name="clickupRef"
                        placeholder="ClickUp Ref"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.clickupRef}
                      />
                    }
                    error={touched.clickupRef && errors.clickupRef}
                  />
                </FormField>
              </FormLoader>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default EnableUserForm

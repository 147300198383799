import React, { useCallback, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Divider, FormField, TextArea, TextField, TextInput } from '../../../../../views/components'
import PortfolioSettingsSegmentsTable from './PortfolioSettingsSegmentsTable'
import SearchSegments from './SearchSegments'
import styles from './PortfolioSettingsForm.module.scss'
import { filter, find, map, pipe } from 'ramda'
import { jsonStringSearch } from 'utils/string'
import { useEffectOnce } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { chatbotEvents, chatbotSelectors } from '../../../../ui/ChatBot'
import { getLeaseSettingsInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseSettings'
import { agencyApiEvents, agencyApiSelectors } from '../../../../api/agency'
import { portfolioApiSelectors } from '../../../../api/portfolio'
import { userApiSelectors } from '../../../../api/user'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const transformSegmentFormSearchResult = (segment: any) => ({
  id: segment?.id,
  text: segment?.name,
})

const transformSegmentForTableRow = (segment: any) => ({
  id: segment?.id,
  name: segment?.name,
  users: '',
})

const PortfolioSettingsForm = () => {
  const dispatch = useDispatch()
  const notesRef = useRef<$TSFixMe>()

  useEffectOnce(() => {
    notesRef.current?.focus()
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(agencyApiEvents.getSegments_request())
  })

  const [query, setQuery] = useState('')
  const currentPortfolioId = useSelector(chatbotSelectors.getCurrentPortfolioId)
  const defaultSegmentId = useSelector(userApiSelectors.getCurrentAgencyId)
  const initialValues = useSelector(getLeaseSettingsInitialValues)
  const segments = useSelector(agencyApiSelectors.getSegments)
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const isActivePortfolio = useSelector(state => portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId))

  const segmentSearchResults = [
    pipe(
      map(transformSegmentFormSearchResult),
      filter((segment: any) => segment?.id !== defaultSegmentId),
      filter((result: any) => jsonStringSearch(query, result)),
      // @ts-expect-error
    )(segments),
  ]

  const handleQueryChange = useCallback(
    ({ target }) => {
      setQuery(target.value)
    },
    [setQuery],
  )

  const handleSubmit = (values: any): void => {
    dispatch(chatbotEvents.settingsValuesSubmitted(values))
  }

  return (
    <div className={styles.root}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleChange, handleBlur, handleSubmit, setFieldValue, values }) => {
          const handleSegmentAdded = (e: any, { result }: any) => {
            // @ts-expect-error
            const segment = find((s: any) => s.id === result.id, segments)
            setFieldValue('segments', [...values.segments, segment])
          }

          const handleSegmentRemoved = (segmentId: any): void => {
            setFieldValue(
              'segments',
              values.segments.filter(({ id }: any) => id !== segmentId),
            )
          }

          return (
            <form className={styles.form} onSubmit={handleSubmit}>
              <fieldset disabled={isReadOnly}>
                <div className={styles.body}>
                  <div className="rbn--row">
                    <div className="rbn--col-md-5">
                      <FormField>
                        <TextField
                          inputComponent={
                            <TextArea
                              name="notes"
                              placeholder="e.g. Owner insurance details"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              ref={(ref: any) => {
                                notesRef.current = ref
                              }}
                            />
                          }
                          label="Lease notes"
                        />
                      </FormField>
                    </div>
                    <div className="rbn--col-md-3">
                      <FormField>
                        <TextField
                          inputComponent={
                            <TextInput
                              type={TextFieldTypes.text}
                              name="customLeaseIdentifier"
                              value={values.customLeaseIdentifier}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          }
                          label="Custom lease ID"
                        />
                      </FormField>
                    </div>
                  </div>

                  <Divider />

                  {/* currently only support one segment per portfolio */}
                  {values.segments.length > 0 ? (
                    <h2 className={styles['search-segments-title']}>Lease Segment</h2>
                  ) : (
                    <SearchSegments
                      onResultSelect={handleSegmentAdded}
                      onSearchChange={handleQueryChange}
                      resultSets={segmentSearchResults}
                    />
                  )}

                  {values.segments.length > 0 ? (
                    <PortfolioSettingsSegmentsTable
                      data={map(transformSegmentForTableRow, values.segments)}
                      onRemove={handleSegmentRemoved}
                    />
                  ) : (
                    <p>
                      This lease is not added to any segments. Add it to a segment by clicking the plus icon to the
                      right.
                    </p>
                  )}

                  {/* <Divider>
                  <h3>Communication settings</h3>
                </Divider> */}

                  {/** @todo fix checkbox bug: not changing checked state (see LeaseTermForm for reference) */}
                  {/* <FormField padCheckbox={false}>
                  <Checkbox
                    name='autoSendPaymentReminderSms'
                    label="Auto-send payment reminder SMS'"
                    checked={values.autoSendPaymentReminderSms}
                    onChange={e => setFieldValue('autoSendPaymentReminderSms', e.checked)}
                    onBlur={handleBlur}
                  />
                </FormField> */}

                  {/** @todo reintroduce once the backend emails owner statements */}
                  {/* <FormField padCheckbox={false}>
                  <Checkbox
                    name='autoSendOwnerMonthlyEmail'
                    label='Send the Owner monthly statement emails'
                    checked={values.autoSendOwnerMonthlyEmail}
                    onChange={e => setFieldValue('autoSendOwnerMonthlyEmail', e.checked)}
                    onBlur={handleBlur}
                  />
                </FormField> */}
                </div>
                <div className={styles.footer}>
                  <Button type="submit">{isActivePortfolio ? 'Save' : "Done, let's continue"}</Button>
                </div>
              </fieldset>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PortfolioSettingsForm

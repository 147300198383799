import { ofType } from 'redux-observable'
import { mapTo } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators'
import { userApiEvents } from '../../api/user'
import { uiEvents } from '../../ui'

export const refreshOnAgencyLogin = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.agencyLogin_success),
    mapTo(uiEvents.reloadRequested()),
    tag('supportStaff/epic/refreshOnAgencyLogin'),
  )

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { animationFrameScheduler, interval } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { Helmet } from 'react-helmet'
import { get, isEmpty } from 'lodash-es'
import { subscribe } from 'react-contextual'
import classNames from 'classnames'
import { withFormik } from 'formik'
import { InvoicesProvider } from '../../../../providers'
import { CurrencyField, DimmerLoader, InvoiceCard, Segment, TextInput } from '../../..'
import PaymentAllocation from './PaymentAllocation/PaymentAllocation'
import TaskList from '../../../../components/atoms/Svgs/TaskList'
import styles from './ViewReconciledInvoice.module.scss'
import TextField from '../../../atoms/TextField/TextField'
import { pathOr } from 'ramda'
import { hashCode } from 'utils/string'
import { $TSFixMe } from 'types/ts-migrate'
import FileTable from 'modules/documents/FileTable'
import DownloadInvoicePdfButton from 'modules/invoices/DownloadInvoicePdfButton/DownloadInvoicePdfButton'
import CreditNotesList from 'modules/invoices/CreditNotesList/CreditNotesList'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  invoice: PropTypes.object,
}

class ViewReconciledInvoice extends Component<$TSFixMe, $TSFixMe> {
  handleNewBeficiary: any
  state = {
    submissionProgress: 0,
    dirty: false,
    isNudgeTooltipOpen: false,
    beneficiariesHash: null,
  }

  componentDidMount(): void {
    this.setState({
      beneficiariesHash: hashCode(JSON.stringify(get(this.props, 'invoice.beneficiaries', ''))),
    })
  }

  cancelPaymentApproval = false

  handleClose = (): void => {
    const { closeInvoice, values } = this.props
    closeInvoice(values.id, 'history')
  }

  getBeneficiaries = (): $TSFixMe => get(this.props, 'values.beneficiaries', [])

  handleBeneficiaryAmountChange = (beneficiaryIndex: number, e: any): void => {
    const { setFieldValue } = this.props
    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === beneficiaryIndex)
    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, { ...beneficiary, ...{ amount: e.target.value } })
    this.setState({ dirty: true })
  }

  handleBeneficiaryOrderChange = (beneficiaries: any): void => {
    const { values, updateBeneficiaries } = this.props
    updateBeneficiaries(values.id, beneficiaries)
    this.setState({ dirty: true })
  }

  customerHasFunds = (): boolean => {
    return parseFloat(this.props.values.customerAvailableFunds) > 0
  }

  updateSubmissionProgress = (onComplete: $TSFixMe): $TSFixMe => {
    return interval(100, animationFrameScheduler)
      .pipe(
        map(i => i * 5),
        takeWhile(i => i <= 100),
        takeWhile(() => !this.cancelPaymentApproval),
      )
      .subscribe(i => this.setState({ submissionProgress: i }), null, onComplete)
  }

  handleNudge = (): void => {
    const { nudge, currentInvoice } = this.props
    nudge([currentInvoice.id])
  }

  render(): null | React.ReactElement {
    const {
      errors,
      currentInvoice: invoice,
      isHistoryInvoiceViewLoading,
      handleSubmit,
      values,
      // setFieldValue,
      // handleBlur,
      // handleChange,
      dirty,
      getPartyNameById,
      isReadOnly,
    } = this.props

    if (isEmpty(values) || !invoice) {
      return null
    }

    const styleNames = classNames('root')

    /**
     * @todo the api should return the value and name
     */
    const haxInvoiceValue = (values.invoiceTypeName || values.invoiceType || '').replace(' ', '')

    const creditNotes = values.creditNotes || []

    return (
      <div className={styleNames}>
        <Helmet>
          <title>
            reOS | Invoices | History | {`${values.customerName as string} - ${values.invoiceType as string}`}
          </title>
        </Helmet>
        {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; loading: any; style: { ... Remove this comment to see the full error message */}
        <DimmerLoader loading={isHistoryInvoiceViewLoading} style={{ minHeight: '300px' }}>
          <InvoiceCard
            invoice={invoice}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; invoice: any; contextua... Remove this comment to see the full error message
            contextualMenuOptions={[]}
            onClose={this.handleClose.bind(null, dirty)}
          >
            <section className={styles['invoice-data']}>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={isReadOnly}>
                  <Segment.Group>
                    {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; horizontal: true; onC... Remove this comment to see the full error message */}
                    <Segment horizontal style={{ flexWrap: 'nowrap' }}>
                      <Segment.Label
                        icon={<TaskList />}
                        text={values.customerName}
                        secondaryText={values.invoiceType}
                      />
                      <div className={styles.amount}>
                        <CurrencyField
                          disabled
                          includeVatControl
                          includeVatCalculator={false}
                          vatApplied={values.vatAmount > 0}
                          name="amount"
                          placeholder="Enter amount"
                          value={values.grossAmount}
                        />
                      </div>
                    </Segment>
                    {values.description && (
                      <Segment horizontal>
                        <TextField
                          inputComponent={<TextInput type={TextFieldTypes.text} value={values.description} disabled />}
                          label="Description"
                        />
                      </Segment>
                    )}
                  </Segment.Group>

                  <PaymentAllocation
                    errors={errors}
                    invoiceType={{ name: values.invoiceType, value: haxInvoiceValue }}
                    invoiceStatus={values.invoiceStatus}
                    balance={values.balance}
                    availableFunds={values.customerAvailableFunds}
                    beneficiaries={this.getBeneficiaries().map((b: any) => {
                      const partyName = getPartyNameById(pathOr('', ['beneficiary', 'value', 'partyId'], b))
                      return Object.assign({}, b, {
                        beneficiary: {
                          ...b.beneficiary,
                          value: {
                            ...b.beneficiary.value,
                            name: partyName,
                          },
                        },
                        isRemovable: false,
                        isDraggable: false,
                      })
                    })}
                    onNewBeneficiaryAdded={this.handleNewBeficiary}
                    onNewBeneficiaryAmountChange={this.handleBeneficiaryAmountChange}
                    onBeneficiariesOrderChange={this.handleBeneficiaryOrderChange}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ errors: any; invoiceType: { name: any; val... Remove this comment to see the full error message
                    beneficiariesKey={this.state.beneficiariesHash}
                    portfolioId={values.portfolioId}
                  />

                  <Segment.Group>
                    <Segment horizontal>
                      <Segment.Label text="Credit notes" />
                    </Segment>
                    <CreditNotesList creditNotes={creditNotes} />
                  </Segment.Group>
                </fieldset>

                {invoice?.id && (
                  <Segment.Group>
                    <FileTable
                      className={styles.documents}
                      showDateTime={true}
                      showDateTimeBeneath={true}
                      allowRenaming={true}
                      allowRemove={true}
                      displayAndEditExtension={false}
                      onError={() => null}
                      // @ts-expect-error
                      forOwner={{ className: 'invoice', classId: invoice.id }}
                    />
                  </Segment.Group>
                )}

                <footer>
                  <div className={styles['action-buttons']}>
                    <DownloadInvoicePdfButton invoiceId={invoice.id} invoiceNumber={invoice.invoiceNumber} />
                  </div>
                </footer>
              </form>
            </section>
          </InvoiceCard>
        </DimmerLoader>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ViewReconciledInvoice.propTypes = propTypes

export default subscribe(
  [InvoicesProvider],
  ({
    currentInvoice,
    closeInvoice,
    getPartyNameById,
    createPayment,
    nudge,
    updateBeneficiaries,
    isHistoryInvoiceViewLoading,
    isReadOnly,
  }: any) => ({
    currentInvoice,
    closeInvoice,
    getPartyNameById,
    createPayment,
    nudge,
    updateBeneficiaries,
    isHistoryInvoiceViewLoading,
    isReadOnly,
  }),
)(
  withFormik({
    enableReinitialize: true,
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    mapPropsToValues: props => props.currentInvoice,
    handleSubmit: (values, { props, setSubmitting }) => {
      const { id, beneficiaries } = values
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      props.createPayment(
        id,
        beneficiaries.map(({ beneficiary }: any) => ({
          beneficiaryId: beneficiary.value.id,
          amount: beneficiary.value.amount,
        })),
      )
    },
  })(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof ViewReconciledInvoice' is... Remove this comment to see the full error message
    ViewReconciledInvoice,
  ),
)

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { useInterval } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { CurrencyField, CurrencyText, FormLoader, InfoBox, InfoList } from '../../../../../views/components'
import { formatCurrency } from 'utils/currency'
import { walletApiEvents, walletApiSelectors } from '../../../../api/wallet'
import styles from '../WalletTransferContent.module.scss'
import { apiStateSelectors } from '../../../../api/apiState'
import { notificationEvents } from '../../../../notificationCenter'
import { assets } from 'modules/api/wallet/constants'
import { agencyApiEvents, agencyApiSelectors } from 'modules/api/agency'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  data: PropTypes.shape({
    fromWallet: PropTypes.object,
    toWallet: PropTypes.object,
    fromBalance: PropTypes.object,
    toBalance: PropTypes.object,
  }),
}

const WalletTransferConfrimation = ({ data }: any) => {
  const dispatch = useDispatch()
  const { fromWallet, toWallet, fromBalance, toBalance } = data
  const [submitted, setSubmitted] = useState(false)
  const [transferred, setTransferred] = useState(false)
  const isTransferring = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletApiEvents.walletTransfer_request]),
  )
  const newFromBalance = useSelector(state => walletApiSelectors.getGlobalBalanceAsset(state)(fromWallet.walletRef))
  const newToBalance = useSelector(state => walletApiSelectors.getGlobalBalanceAsset(state)(toWallet.walletRef))

  const getAgency = useCallback(id => dispatch(agencyApiEvents.agency_request({ id })), [dispatch])

  const fromWalletAgency: $TSFixMe = useSelector(state => agencyApiSelectors.getAgencyById(state)(fromWallet.agencyId))
  const toWalletAgency: $TSFixMe = useSelector(state => agencyApiSelectors.getAgencyById(state)(toWallet.agencyId))

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .required('Required.')
          .test('amount', function (amount) {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            if (amount > data.fromBalance.balance) {
              return this.createError({
                path: this.path,
                message: `Can't be greater than wallet balance of ${formatCurrency(data.fromBalance.balance)}`,
              })
            }
            return true
          }),
      }),
    [data.fromBalance],
  )

  // poll balance after submission
  useInterval(
    () => {
      dispatch(walletApiEvents.balance_request({ partyId: fromWallet.walletRef }))
      dispatch(walletApiEvents.balance_request({ partyId: toWallet.walletRef }))
    },
    submitted && !isTransferring && !transferred ? 2000 : null,
  )

  useEffect(() => {
    if (fromWallet.agencyId) {
      getAgency(fromWallet.agencyId)
    }
    if (toWallet.agencyId) {
      getAgency(toWallet.agencyId)
    }
    if (newToBalance?.balance !== toBalance?.balance && newFromBalance?.balance !== fromBalance?.balance) {
      setTransferred(true)
      dispatch(
        notificationEvents.addNotification(
          {
            type: 'success',
            heading: 'Transfer successful!',
            children: (
              <p>
                Funds transferred from {fromWallet.reference} to {toWallet.reference}. Check updated balances above.
              </p>
            ),
          },
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          { autoDismiss: true },
        ),
      )
    }
  }, [
    toWallet.agencyId,
    fromWallet.agencyId,
    newFromBalance.balance,
    newToBalance.balance,
    fromBalance.balance,
    toBalance.balance,
    dispatch,
    fromWallet.reference,
    toWallet.reference,
  ])

  const handleSubmit = useCallback(
    values => {
      setSubmitted(true)
      const payload = {
        fromAccount: fromWallet.walletRef,
        fromParty: fromWallet.partyId,
        agency: toWallet.agencyId,
        transfers: [
          {
            toAccount: toWallet.accountId,
            toParty: toWallet.partyId,
            asset: fromBalance.name,
            amount: values.amount,
            description: `${fromWallet.reference} => ${toWallet.reference}`,
          },
        ],
      }
      dispatch(walletApiEvents.walletTransfer_request(payload))
    },
    [dispatch, fromWallet, toWallet, fromBalance],
  )

  return (
    <div className={styles['transfer-info']}>
      <div className="rbn--row" style={{ marginBottom: '20px' }}>
        <div className="rbn--col rbn-col-md-4">
          <h3>From Wallet</h3>
          {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
          <InfoList
            items={[
              {
                label: 'Account Type',
                value: fromWallet.accountType,
              },
              {
                label: 'Contact',
                value: data.fromWallet.partyFullName || fromWallet.partyId,
              },
              {
                label: 'Lease',
                value: fromWallet.propertyAddress,
              },
              {
                label: 'Payment Reference',
                value: fromWallet.reference,
              },
              {
                label: 'Balance',
                value: transferred ? (
                  <CurrencyText>{newFromBalance.balance}</CurrencyText>
                ) : (
                  <CurrencyText>{fromBalance.balance}</CurrencyText>
                ),
              },
              {
                label: 'Asset',
                value: fromBalance.name,
              },
              {
                label: 'Agency ID',
                value: fromWalletAgency?.id,
              },
              {
                label: 'Agency T/A Name',
                value: fromWalletAgency?.agency.agencyDetails.trading,
              },
              {
                label: 'Agency Company Name',
                value: fromWalletAgency?.agency.agencyDetails.companyName,
              },
            ]}
          />
        </div>
        <div className="rbn--col rbn-col-md-4">
          <h3>To Wallet</h3>
          {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
          <InfoList
            items={[
              {
                label: 'Account Type',
                value: toWallet.accountType,
              },
              {
                label: 'Contact',
                value: data.toWallet.partyFullName || toWallet.partyId,
              },
              {
                label: 'Lease',
                value: toWallet.propertyAddress,
              },
              {
                label: 'Payment Reference',
                value: toWallet.reference,
              },
              {
                label: 'Balance',
                value: transferred ? (
                  <CurrencyText>{newToBalance.balance}</CurrencyText>
                ) : (
                  <CurrencyText>{toBalance.balance}</CurrencyText>
                ),
              },
              {
                label: 'Asset',
                value: fromBalance.name,
              },
              {
                label: 'Agency ID',
                value: toWalletAgency?.id,
              },
              {
                label: 'Agency T/A Name',
                value: toWalletAgency?.agency.agencyDetails.trading,
              },
              {
                label: 'Agency Company Name',
                value: toWalletAgency?.agency.agencyDetails.companyName,
              },
            ]}
          />
        </div>
      </div>
      {fromBalance.name === assets.dummy ? (
        <InfoBox type="error">Unable to transfer dummy funds.</InfoBox>
      ) : fromWalletAgency?.id !== toWalletAgency?.id ? (
        <InfoBox type="error">Unable to transfer between agencies.</InfoBox>
      ) : (
        !transferred && (
          <Formik initialValues={{ amount: 0 }} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ handleSubmit, setFieldValue, submitCount, values, errors }) => {
              const formLoaderState =
                isTransferring || (submitted && !transferred)
                  ? 'submitting'
                  : submitCount > 0 && !isEmpty(errors)
                  ? 'error'
                  : undefined

              return (
                <FormLoader
                  onSubmit={handleSubmit}
                  state={formLoaderState}
                  submittingText="Transferring funds..."
                  buttonProps={{ children: 'Transfer' }}
                >
                  <div className="rbn--row" style={{ marginBottom: '20px' }}>
                    <div className="rbn--col-md-4">
                      <CurrencyField
                        label="Amount"
                        id="amount"
                        name="amount"
                        placeholder="Enter amount"
                        onChange={({ target }: any) => setFieldValue('amount', target.value)}
                        value={values.amount}
                        error={errors.amount}
                      />
                    </div>
                    <div className="rbn--col-md-4"></div>
                    <div className="rbn--col-md-4"></div>
                  </div>
                </FormLoader>
              )
            }}
          </Formik>
        )
      )}
    </div>
  )
}

WalletTransferConfrimation.propTypes = propTypes

export default WalletTransferConfrimation

import React from 'react'
import { moduleContext } from 'react-contextual'

const PortfoliosProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = {
    navItems: [
      // { text: 'Overview', route: '/invoices' },
      // { text: 'Open', route: '/invoices/active' },
      // { text: 'History', route: '/invoices/history' }
    ],
    ...props,
  }
  return <context.Provider value={store}>{children}</context.Provider>
})

export default PortfoliosProvider

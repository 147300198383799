import { ofType } from 'redux-observable'
import { mapTo, map, ignoreElements } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { userApiEvents } from '../api/user'
import { uiEvents } from '.'

export const redirectOnboardingVerification = (action$: any) =>
  action$.pipe(
    ofType(userApiEvents.signup_success),
    mapTo(uiEvents.redirect('/dashboard')),
    tag('ui/epic/redirectOnboardingVerification'),
  )

export const reload = (action$: any) =>
  action$.pipe(
    ofType(uiEvents.reloadRequested),
    map(() => window.location.reload()),
    ignoreElements(),
    tag('ui/epic/reload'),
  )

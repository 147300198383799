import update from 'immutability-helper'

// https://github.com/kolodny/immutability-helper#adding-your-own-commands

update.extend('$auto', function (value, object) {
  return object ? update(object, value) : update({}, value)
})

update.extend('$autoArray', function (value, object) {
  return object ? update(object, value) : update([], value)
})

export default update

import React from 'react'
import { Segment, CurrencyText } from 'views/components'
import styles from './CreditNotesList.module.scss'

type CreditNote = {
  amount: number
  reason: string /** @todo use type from API */
  description?: string
  beneficiaries: Array<{
    beneficiaryId: string
    amount: number
  }>
}

type Props = {
  creditNotes: CreditNote[]
}

const CreditNotesList = ({ creditNotes }: Props): React.ReactElement | null => {
  return creditNotes.length === 0 ? null : (
    <Segment>
      <div className={styles.root}>
        <ul>
          {creditNotes.map((creditNote: CreditNote, i: number) => (
            <li key={`credit-note-${i}`}>
              <span>
                <strong>{creditNote.reason.replace(/([A-Z])/g, ' $1').trim()}</strong>
                <br />
                {creditNote?.description && <small>{creditNote.description}</small>}
              </span>
              <span>
                <CurrencyText>{creditNote.amount}</CurrencyText>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Segment>
  )
}

export default CreditNotesList

import { connect } from 'react-redux'
import { OnboardingVerificationForm } from '../../components'
import { userApiEvents, userApiSelectors } from '../../../modules/api/user'
import { getCurrentUserDetailsInitialValues } from '../../../modules/ui/ChatBot/Dialogs/UserDetails'
import { smsApiEvents } from '../../../modules/api/sms'
import { apiStateSelectors } from '../../../modules/api/apiState'
import { omit } from 'ramda'

const mapStateToProps = (state: any) => ({
  initialValues: getCurrentUserDetailsInitialValues(state),
  otpErrors: userApiSelectors.getOtpErrors(state),
  isLoading: apiStateSelectors.isLoading(state)([userApiEvents.currentUserDetails_request]),
  isSubmitting: apiStateSelectors.isLoading(state)([userApiEvents.saveDetails_request]),
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCurrentUserDetails: () => dispatch(userApiEvents.currentUserDetails_request()),
    getOtp: (cellnumber: any) => dispatch(smsApiEvents.getOtp_request({ cellnumber })),
    validateOtp: (otp: any, userDetails: any) => {
      dispatch(userApiEvents.saveDetails_request(userDetails))
      dispatch(userApiEvents.validateOtp_request({ otp }))
    },
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    resendEmailVerification: () => dispatch(userApiEvents.resendEmailVerification_request()),
    handleSubmit: (values: any) => {
      const isIdNumberEmpty = values.idNumber === ''
      if (isIdNumberEmpty) {
        dispatch(userApiEvents.saveDetails_request(omit(['idNumber'], values)))
      } else {
        dispatch(userApiEvents.saveDetails_request(values))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingVerificationForm)

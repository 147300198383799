import React from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { PortfoliosProvider } from '../../../../providers'
import { SearchFilter, ListingTemplate } from '../../..'
import { TableProvider } from '../../../organisms/Table'
import PortfolioTable from '../PortfolioTable/PortfolioTable'

function Portfolios({
  // PortfoliosProvider
  location,

  redirect,
}: any) {
  return (
    <TableProvider>
      <ListingTemplate
        loading={false}
        Header={<SearchFilter location={location} redirect={redirect} filters={[]} />}
        Body={<PortfolioTable />}
      />
    </TableProvider>
  )
}

Portfolios.propTypes = {
  location: PropTypes.object,
  redirect: PropTypes.func,
}

export default subscribe([PortfoliosProvider], ({ location, redirect }: any) => ({
  // PortfoliosProvider
  location,
  redirect,
}))(Portfolios)

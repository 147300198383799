import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { path, pathOr } from 'ramda'
import Select from '../../../atoms/Select/Select'
import TextField from '../../../atoms/TextField/TextField'
import Button from '../../../atoms/Button/Button'
import styles from './EasyPayBeneficiary.module.scss'
import CurrencyField from '../../CurrencyField/CurrencyField'
import CurrencyText from '../../../atoms/Typography/CurrencyText/CurrencyText'
import TextInput from 'views/components/atoms/TextField/TextInput'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  beneficiaryTagGroups: PropTypes.object,
  beneficiaryTagGroupsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  state: PropTypes.shape({
    easyPayReference: PropTypes.string,
    beneficiaryTag: PropTypes.string,
  }),
  setState: PropTypes.func,
  onNewBeneficiaryAdded: PropTypes.func.isRequired,
  fetchEasyPayData: PropTypes.func.isRequired,
  easyPayData: PropTypes.object.isRequired,
  unallocatedAmount: PropTypes.number.isRequired,
}

const EasyPayBeneficiary = (props: any) => {
  const {
    beneficiaryTagGroups,
    beneficiaryTagGroupsForSelect,
    onNewBeneficiaryAdded,
    state,
    setState,
    fetchEasyPayData,
    easyPayData,
    unallocatedAmount,
  } = props

  const [tagGroupSelection, setTagGroupSelection] = useState(null)
  const [nextStep, setNextStep] = useState('benficiaryTagGroup')
  const [error, setError] = useState(undefined)
  const [isRequestingEasyPayData, setIsRequestingEasyPayData] = useState(false)
  const [currentEasyPayReference, setCurrentEasyPayReference] = useState(null)

  const isEasyPayReferenceDataValid = (data: any) => pathOr(false, ['request', 'success'], data)

  useEffect(() => {
    const data = pathOr({}, [state.easyPayReference], easyPayData)
    setIsRequestingEasyPayData(false)

    if (isEasyPayReferenceDataValid(data)) {
      setNextStep('amount')
    } else {
      setError(path(['request', 'response_message'], data))
    }
  }, [easyPayData, state.easyPayReference, onNewBeneficiaryAdded, state])

  const renderNextField = () => {
    if (nextStep === 'benficiaryTagGroup') {
      return (
        <Select
          defaultMenuIsOpen
          onChange={(val: any) => {
            if (beneficiaryTagGroups[val.value].length === 1) {
              setState({ beneficiaryTag: val.value })
              setNextStep('easyPayReference')
            } else {
              setTagGroupSelection(val.value)
              setNextStep('beneficiaryTag')
            }
          }}
          name="beneficiaryTagGroups"
          placeholder="Select EasyPay Beneficiary Tag"
          options={beneficiaryTagGroupsForSelect}
        />
      )
    }

    if (nextStep === 'beneficiaryTag') {
      return (
        <Select
          menuIsOpen={!state.beneficiaryTag}
          onChange={({ value }: any) => {
            setState({ beneficiaryTag: value })
            setNextStep('easyPayReference')
          }}
          name="beneficiaryTag"
          placeholder="Select EasyPay Beneficiary Tag"
          // @ts-expect-error ts-migrate(2538) FIXME: Type 'null' cannot be used as an index type.
          options={beneficiaryTagGroups[tagGroupSelection]}
        />
      )
    }

    if (nextStep === 'easyPayReference') {
      return (
        <TextField
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              name="easyPayReference"
              onChange={({ target }: any) => setCurrentEasyPayReference(target.value)}
              placeholder="EasyPay reference"
              ref={(ref: any) => {
                ref?.focus()
              }}
            />
          }
          error={error}
        />
      )
    }

    if (nextStep === 'amount') {
      return (
        <CurrencyField
          name="amount"
          placeholder="Amount to pay beneficiary"
          value={state.amount}
          onChange={({ target }: any) => setState({ amount: target.value || 0 })}
          error={
            state.amount > unallocatedAmount ? (
              <span>
                Can&apos;t exceed unallocated amount of <CurrencyText>{unallocatedAmount}</CurrencyText>
              </span>
            ) : (
              false
            )
          }
        />
      )
    }
  }

  const isStateValid = () => state.beneficiaryTag && state.easyPayReference && state.amount <= unallocatedAmount

  const buttonClasses = cx({
    [styles['add-button']]:
      (nextStep === 'easyPayReference' && error) ?? (nextStep === 'amount' && state.amount > unallocatedAmount),
  })

  return (
    <div className={styles.root}>
      {isRequestingEasyPayData ? (
        <p>Validating EasyPay reference...</p>
      ) : (
        <div className={styles.form}>
          <div className={styles.input}>{renderNextField()}</div>

          {nextStep === 'easyPayReference' && (
            <Button
              disabled={!currentEasyPayReference}
              secondary
              size="sm"
              onClick={() => {
                setState({ easyPayReference: currentEasyPayReference })
                fetchEasyPayData(currentEasyPayReference)
                setIsRequestingEasyPayData(true)
              }}
              className={buttonClasses}
            >
              Add amount
            </Button>
          )}

          {nextStep === 'amount' && (
            <Button
              disabled={!isStateValid()}
              secondary
              size="sm"
              onClick={() => onNewBeneficiaryAdded({ type: 'EasyPayBeneficiary', value: state })}
              className={buttonClasses}
            >
              Add Beneficiary
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

EasyPayBeneficiary.propTypes = propTypes

export default EasyPayBeneficiary

import { createSlice } from '@reduxjs/toolkit'
import { uniq, uniqBy } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'
import { PayloadAction } from 'typesafe-actions'

const initialState: $TSFixMe = {
  byId: {},
  allIds: [],
  invitations: [],
  invitationsByToken: {},
  signupInvite: null,
  users: [],
  themes: [],
  agencyListing: [],
  agencyStatsChartDataForDays: {},
  invitableParties: [],
  segments: [],
}

const agencySlice = createSlice({
  name: `${NAMESPACE}Api`,
  initialState,
  reducers: {
    agency_request: (state, { payload }) => {},
    agency_success: (state, { payload }) => {
      state.allIds = uniq([...state.allIds, payload.id])
      state.byId[payload.id] = payload
    },
    invitations_request: (state, { payload }) => {},
    invitations_success: (state, { payload }) => {
      state.invitations = payload
    },
    invite_request: (state, { payload }) => {},
    invite_success: {
      reducer: (state, { payload, meta }) => {
        state.invitationsByToken[meta.token] = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    sendInvite_request: (state, { payload }) => {},
    sendInvite_success: (state, { payload }) => {},
    sendPartyInvite_request: (state, { payload }) => {},
    sendPartyInvite_success: (state, { payload }) => {},
    resendInvite_request: (state, { payload }) => {},
    resendInvite_success: (state, { payload }) => {
      state.invitations = state.invitations.map(invite => (invite.partyId === payload.partyId ? payload : invite))
    },
    revokeInvite_request: (state, { payload }) => {},
    revokeInvite_success: (state, { payload }) => {
      /** @todo update revokedAt with response? */
      state.invitations = state.invitations.map(invite => (invite.partyId === payload.partyId ? payload : invite))
      // state.invitations = state.invitations.filter(invite => invite.partyId !== payload.partyId)
    },
    signupInvite_request: (state, { payload }) => {},
    signupInvite_success: (state, { payload }) => {},
    users_request: (state, { payload }) => {},
    users_success: (state, { payload }) => {
      state.users = uniqBy((m: any) => m.userId, [...state.users, payload])
    },
    themes_request: (state, { payload }) => {},
    themes_success: (state, { payload }) => {
      state.themes = payload
    },
    updateBranding_request: (state, { payload }) => {},
    updateBranding_success: (state, { payload }) => {},
    themeSet: (state, { payload }) => {},
    logoUpdated: (state, { payload }) => {},
    updateAgency_request: (state, { payload }) => {},
    updateAgency_success: (state, { payload }) => {
      state.byId[payload.id] = payload
    },
    agencyListing_request: () => undefined,
    agencyListing_success: (state, { payload }) => {
      state.agencyListing = payload
    },
    invitableParties_request: (state, { payload }) => {},
    invitableParties_success: (state, { payload }) => {
      state.invitableParties = payload
    },
    getSegments_request: (state, { payload }) => {},
    getSegments_success: (state, { payload }) => {
      state.segments = payload
    },
    createSegment_request: (state, { payload }) => {},
    createSegment_success: (state, { payload }) => {
      state.segments = payload
    },
    updateSegment_request: (state, { payload }) => {},
    updateSegment_success: (state, { payload }) => {
      state.segments = payload
    },
    deleteSegment_request: (state, { payload: { segmentId } }) => {},
    deleteSegment_success: (state, { payload }) => {
      state.segments = payload
    },
    deactivateAgency_request: (state, { payload }) => {},
    deactivateAgency_success: (state, { payload }) => {
      state.agencyListing = state.agencyListing.map((agency: $TSFixMe) =>
        agency.agencyId === payload.id
          ? {
              ...agency,
              isActive: false,
            }
          : agency,
      )
    },
    activateAgency_request: (state, { payload }) => {
      state.agencyListing = state.agencyListing.map((agency: $TSFixMe) =>
        agency.agencyId === payload.id
          ? {
              ...agency,
              isActive: true,
            }
          : agency,
      )
    },
    activateAgency_success: (state, { payload }) => {},
    addToGroup_request: (
      state,
      { payload }: { payload: { params: { groupId: string }; body: { agencyId: string; percentRoyalty: number } } },
    ) => {},
    addToGroup_success: (state, { payload }) => {},
    removeFromGroup_request: (state, { payload }: $TSFixMe) => {},
    removeFromGroup_success: (state, { payload }: $TSFixMe) => {},
    /** @todo move this out of API module */
    existingInviteNotification: () => {},
    failedInviteNotification: () => {},
  },
})

const { reducer, actions: events } = agencySlice

export { events, initialState, reducer }

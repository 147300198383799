import React, { useState } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { stringUtils } from '../../utils'
import { useToggle } from '../hooks'

const propTypes = {
  location: PropTypes.object.isRequired,
}

const SearchFilterProvider = moduleContext()(({ context, children, location }: any) => {
  const [isSearchOpen, toggleSearchOpen] = useToggle(false)
  const [activeFilterIndex, setActiveFilterIndex] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type '{}'.
  const { filter = false } = stringUtils.parseQueryString(location.search)

  const value = {
    filter,
    isSearchOpen,
    toggleSearchOpen,
    activeFilterIndex,
    setActiveFilterIndex,
    searchQuery,
    setSearchQuery,
  }

  return <context.Provider value={value}>{children}</context.Provider>
})

SearchFilterProvider.propTypes = propTypes

export default SearchFilterProvider

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { prop } from 'ramda'
import { apiStateSelectors } from '../../../api/apiState'
import { reportingApiEvents, reportingApiSelectors } from '../../../api/reporting'
import { DateRangePicker, DimmerLoader, Header, Modal, Table, Select, Switch } from '../../../../views/components'
import { format, startOfMonth, endOfMonth, subMonths, set } from 'date-fns'
import { getCurrentTaxSeason } from 'utils/date'
import ReportingIcon from '../../../../views/components/atoms/Icons/Descriptive/ReportsFill'
import DownloadIcon from '../../../../views/components/atoms/Icons/Doc/DocumentDownload'
import PdfIcon from '../../../../views/components/atoms/Icons/Doc/PdfAlt'
import styles from './ReportingTable.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { userApiSelectors } from 'modules/api/user'

const ReportingTable = () => {
  const dispatch = useDispatch()
  const dateFormat = 'yyyy-MM-dd'
  const sendDataToGTM = useGTMDispatch()

  const [selectedReport, setSelectedReport] = useState<$TSFixMe>(null)
  const [selectedOwners, setSelectedOwners] = useState<$TSFixMe>([])
  const [isIncludeDraftsSelected, setIsIncludeDraftsSelected] = useState<$TSFixMe>(false)

  const defaultDates = {
    start: format(startOfMonth(Date.now()), dateFormat),
    end: format(endOfMonth(Date.now()), dateFormat),
  }

  const { start: taxStart, end: taxEnd } = getCurrentTaxSeason()

  useEffectOnce(() => {
    dispatch(reportingApiEvents.properties_request())
    dispatch(reportingApiEvents.quickSearch_request({ tags: 'Owner' }))
  })

  const downloadReport = useCallback(
    action => {
      dispatch(action)
    },
    [dispatch],
  )

  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)
  const isValidSupportStaff = useSelector(userApiSelectors.isValidSupportStaff)

  const reports = [
    {
      id: 'accountsRecievable',
      name: 'Accounts Receivable',
      dateParams: null,
      action: reportingApiEvents.accountsReceivable_request,
      actionArgs: { dataType: 'excel' },
      isVisible: true,
    },
    {
      id: 'agencyStatement',
      name: 'Agency Commission Report',
      dateParams: defaultDates,
      // action: reportingApiEvents.commissionSplit_request({ dataType: 'excel', startDate, endDate }),
      action: reportingApiEvents.commissionSplit_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isOwnerRole || isValidSupportStaff,
    },
    {
      id: 'agentCommissionReport',
      name: 'Agent Commission Report',
      dateParams: defaultDates,
      action: reportingApiEvents.agentCommissionReport_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isOwnerRole || isValidSupportStaff,
    },
    {
      id: 'rentRoll',
      name: 'Rent Roll',
      dateParams: defaultDates,
      // action: reportingApiEvents.rentRoll_request({ dataType: 'excel', startDate, endDate }),
      action: reportingApiEvents.rentRoll_request,
      actionArgs: { dataType: 'excel' },
      isVisible: true,
    },
    {
      id: 'cashFlow',
      name: 'Cash Flow',
      dateParams: defaultDates,
      // action: reportingApiEvents.cashFlow_request({ dataType: 'excel', startDate, endDate }),
      action: reportingApiEvents.cashFlow_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isValidSupportStaff,
    },
    {
      id: 'deposits',
      name: 'Deposit Report',
      dateParams: defaultDates,
      // action: reportingApiEvents.deposits_request({ dataType: 'excel', startDate, endDate }),
      action: reportingApiEvents.deposits_request,
      actionArgs: { dataType: 'excel' },
      isVisible: true,
    },
    {
      id: 'tenancySchedule',
      name: 'Tenancy Schedule',
      dateParams: null,
      action: reportingApiEvents.tenancySchedule_request,
      actionArgs: { dataType: 'excel', shouldIncludeDrafts: !isIncludeDraftsSelected },
      isVisible: true,
    },
    {
      id: 'cashDepositStatement',
      name: 'Cash Deposit Statement',
      dateParams: {
        start: format(startOfMonth(subMonths(Date.now(), 3)), dateFormat),
        end: format(endOfMonth(Date.now()), dateFormat),
      },
      action: reportingApiEvents.cashDepositStatement_request,
      actionArgs: { dataType: 'csv' },
      pdfActionArgs: { dataType: 'pdf' },
      isVisible: true,
    },
    {
      id: 'propertyTaxStatement',
      name: 'Property Tax Statement',
      dateParams: {
        start: format(taxStart, dateFormat),
        end: format(taxEnd, dateFormat),
      },
      ownerParams: {
        owners: selectedOwners,
      },
      action: reportingApiEvents.propertyTaxStatement_request,
      pdfActionArgs: { dataType: 'pdf' },
      isVisible: true,
    },
    {
      id: 'availableFunds',
      name: 'Available Funds',
      dateParams: null,
      action: reportingApiEvents.availableFunds_request,
      actionArgs: { dataType: 'excel' },
      isVisible: true,
    },
  ].filter(item => !!item?.isVisible)

  const loadingEvents = reports.map(prop('action'))
  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)(loadingEvents))

  const ownersForSelect = useSelector(state => reportingApiSelectors.getOwnersForSelect(state))

  useEffect(() => {
    if (!isLoading) {
      setSelectedReport(null)
      setSelectedOwners([])
    }
  }, [isLoading])

  return (
    <div className={styles.root}>
      <Header icon={<ReportingIcon />} text="Reporting" />
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell head>Report</Table.Cell>
            <Table.Cell head>&nbsp;</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {reports.map((report, i) => (
            <Table.Row key={`report-row-${i}`}>
              <Table.Cell>{report.name}</Table.Cell>
              {report.pdfActionArgs ? (
                <Table.Cell className={styles['action-cell']}>
                  <button
                    type="button"
                    onClick={() => setSelectedReport({ ...report, actionArgs: report.pdfActionArgs })}
                  >
                    <PdfIcon width={28} />
                  </button>
                </Table.Cell>
              ) : null}
              {report.id !== 'propertyTaxStatement' && (
                <Table.Cell className={styles['action-cell']}>
                  <button type="button" onClick={() => setSelectedReport(report)}>
                    <DownloadIcon />
                  </button>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        isOpen={selectedReport?.id}
        padding="sm"
        showClose={true}
        onClose={() => setSelectedReport(null)}
      >
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message */}
        <Modal.Header title={selectedReport?.name} />
        <Modal.Body
          actions={
            isLoading
              ? []
              : selectedReport?.id === 'propertyTaxStatement'
              ? [
                  {
                    label: 'Download',
                    onClick: () => {
                      selectedOwners.map((owner: $TSFixMe) => {
                        const ownerId = owner.value
                        const ownerName = owner.label
                        downloadReport(
                          selectedReport?.action({
                            start: selectedReport.dateParams.start,
                            finish: selectedReport.dateParams.end,
                            ownerId,
                            ownerName,
                          }),
                        )
                      }),
                        sendDataToGTM({
                          event: 'download_report',
                          report_name: selectedReport?.name,
                        })
                    },
                  },
                ]
              : [
                  {
                    label: 'Download',
                    onClick: () => {
                      downloadReport(
                        selectedReport?.action({
                          ...selectedReport.actionArgs,
                          ...selectedReport.dateParams,
                        }),
                      ),
                        sendDataToGTM({
                          event: 'download_report',
                          report_name: selectedReport?.name,
                        })
                    },
                  },
                ]
          }
        >
          <DimmerLoader loading={isLoading} fadeStyle="flat" bgColor="white">
            <>
              {selectedReport?.dateParams?.start && selectedReport?.dateParams?.end ? (
                <>
                  {!selectedReport?.ownerParams && (
                    <p>
                      Select a date range and click the button below to download{' '}
                      {selectedReport.actionArgs.dataType === 'pdf' ? 'a PDF' : 'an Excel'} version of this report.
                    </p>
                  )}
                  {selectedReport?.info && selectedReport.info}
                  <DateRangePicker
                    startLabel={selectedReport?.ownerParams ? 'Select a start date' : ''}
                    endLabel={selectedReport?.ownerParams ? 'Select a end date' : ''}
                    startDate={selectedReport.dateParams.start}
                    endDate={selectedReport.dateParams.end}
                    onChange={(date: $TSFixMe, isStartDate: boolean) => {
                      isStartDate
                        ? setSelectedReport({
                            ...selectedReport,
                            dateParams: {
                              ...selectedReport.dateParams,
                              start: format(new Date(date), dateFormat),
                            },
                          })
                        : setSelectedReport({
                            ...selectedReport,
                            dateParams: {
                              ...selectedReport.dateParams,
                              end: format(new Date(date), dateFormat),
                            },
                          })
                    }}
                    inline={false}
                  />
                </>
              ) : (
                <p>Click the button below to download an Excel version of this report.</p>
              )}
              {selectedReport?.id === 'tenancySchedule' && (
                <div className={styles['include-drafts']}>
                  <Switch
                    label="Include Draft Leases"
                    checked={isIncludeDraftsSelected}
                    onChange={() => setIsIncludeDraftsSelected(!isIncludeDraftsSelected)}
                  />
                </div>
              )}
              {selectedReport?.ownerParams && (
                <div className={styles['owner-select']}>
                  <Select
                    isMulti
                    isSearchable
                    name="owners"
                    label="Search Owner(s)"
                    value={selectedOwners}
                    onChange={(selectedOwner: $TSFixMe) => setSelectedOwners(selectedOwner)}
                    options={ownersForSelect}
                  />
                </div>
              )}
            </>
          </DimmerLoader>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ReportingTable

import React from 'react'

const ClearRed = (props: any) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={30} height={30} {...props}>
    <defs>
      <path id="b" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10 10-4.477 10-10" />
      <filter id="a" width="190%" height="190%" x="-45%" y="-35%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius={0.5} result="shadowSpreadOuter1" />
        <feOffset dy={2} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5} />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g transform="translate(5 3)" fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#FF1900" fillOpacity={0.9} stroke="#FFF" strokeOpacity={0.2} xlinkHref="#b" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.667 6.667l6.666 6.666m0-6.666l-6.666 6.666"
      />
    </g>
  </svg>
)

export default ClearRed

import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.string,
}

const ModalTitle = ({ children }: any) => <h3 className="Modal_Title">{children}</h3>

ModalTitle.propTypes = propTypes

export default ModalTitle

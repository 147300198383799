import React from 'react'
import PropTypes from 'prop-types'
import { FastField } from 'formik'
import { Divider, FormField, Radio, RadioGroup } from '../../../../../../views/components'

const propTypes = {
  portfolioId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  depositAccountBalance: PropTypes.number.isRequired,
}

const DepositFunds = ({ portfolioId, setFieldValue, depositAccountBalance }: any) => {
  return (
    depositAccountBalance > 0 && (
      <div>
        <Divider>
          <h3>Deposit funds</h3>
        </Divider>
        <p>
          Funds in this tenant&apos;s deposit wallet can be released to the tenants linked bank account or held to
          settle any outstanding invoices/fees/fines.
        </p>
        <FormField>
          <FastField
            name="releaseDeposit"
            render={({ field }: any) => (
              <RadioGroup
                {...field}
                name="releaseDeposit"
                defaultSelected="false"
                stackRadios={true}
                // label='Deposit funds'
                onChange={e => setFieldValue('releaseDeposit', e.target.value)}
              >
                {/** @todo can only release funds if party has bank account */}
                <Radio name="releaseFunds" value="true" label="Release funds" />
                <Radio name="holdFunds" value="false" label="Hold Funds" />
              </RadioGroup>
            )}
          />
        </FormField>
      </div>
    )
  )
}

DepositFunds.propTypes = propTypes

export default DepositFunds

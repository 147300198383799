import { connect } from 'react-redux'
import { chatbotEvents, chatbotSelectors } from '../../../modules/ui/ChatBot'
import { getCommissionInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseCommission'
import { getRentAmount } from 'modules/ui/ChatBot/Dialogs/LeaseRentAndFees'
// import { partySearchEvents } from '../../../modules/ui/PartySearch'
import { CommissionForm } from '../../components'
import { partyApiSelectors } from '../../../modules/api/party'
import { isEmpty } from 'ramda'
import { portfolioApiSelectors } from '../../../modules/api/portfolio'
import { userApiSelectors } from '../../../modules/api/user'

const mapStateToProps = (state: any) => {
  /** @todo create selector for agents */
  const defaultResultSets = partyApiSelectors.partiesResultsByTags(state)(['Agent', 'Agency'])
  const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)

  return {
    initialValues: getCommissionInitialValues(state),
    rentAmount: getRentAmount(state),
    defaultResultSets: !isEmpty(defaultResultSets) ? [defaultResultSets] : [],
    isActivePortfolio: portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId),
    portfolioRentInvoice: portfolioApiSelectors.getRentInvoiceTemplateByPortfolioId(state)(currentPortfolioId),
    isReadOnly: userApiSelectors.isReadOnlyRole(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSubmit: (values: any) => {
      dispatch(chatbotEvents.commissionValuesSubmitted(values))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionForm)

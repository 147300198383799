import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './Pill.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

type Props = {
  onRemove: () => void
  children: string
  className?: string
}

const Pill: FC<Props> = ({ onRemove, children, className }: $TSFixMe) => {
  const classes = classNames([styles.root, className])

  return (
    <span className={classes}>
      {children}
      <button onClick={onRemove}>X</button>
    </span>
  )
}

export default Pill

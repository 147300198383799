import { $TSFixMe } from 'types/ts-migrate'
import * as yup from 'yup'

export const identityNumberValidation = yup
  .string()
  .required('required.')
  .test('idNumber', function (idNumber: $TSFixMe) {
    // structure: (YYMMDD GSSS CAZ)

    // return true if the value is empty
    if (idNumber === '' || idNumber === null || idNumber === undefined) {
      return true
    }

    // check that value submitted is a number
    if (isNaN(idNumber)) {
      return this.createError({ message: 'Value supplied is not a valid number.' })
    }

    // check length of 13 digits
    if (idNumber.length != 13) {
      return this.createError({ message: 'Number supplied does not have 13 digits.' })
    }

    // check that YYMMDD group is a valid date
    const yy = idNumber.substring(0, 2)
    const mm = idNumber.substring(2, 4)
    const dd = idNumber.substring(4, 6)

    const dob = new Date(yy, mm - 1, dd)

    // check values - add one to month because Date() uses 0-11 for months
    if (!((dob.getFullYear() + '').substring(2, 4) == yy && dob.getMonth() == mm - 1 && dob.getDate() == dd)) {
      return this.createError({ message: 'Date in first 6 digits is invalid.' })
    }

    // check that GSSS group is a valid sequence
    if (idNumber.substring(6, 10) < 0) {
      return this.createError({ message: 'GSSS group is invalid.' })
    }

    // ensure third to last digit is a 1 or a 0
    if (idNumber.substring(10, 11) > 1) {
      return this.createError({ message: 'Third to last digit can only be a 0 or 1.' })
    }

    // ensure second to last digit is a 8 or a 9
    if (idNumber.substring(11, 12) < 8) {
      return this.createError({ message: 'Second to last digit can only be a 8 or 9.' })
    }

    // calculate check bit (Z) using the Luhn algorithm
    let ncheck = 0
    let beven = false

    for (var c = idNumber.length - 1; c >= 0; c--) {
      var cdigit = idNumber.charAt(c),
        ndigit = parseInt(cdigit, 10)

      if (beven) {
        if ((ndigit *= 2) > 9) ndigit -= 9
      }

      ncheck += ndigit
      beven = !beven
    }

    if (ncheck % 10 !== 0) {
      return this.createError({ message: 'Checkbit is incorrect.' })
    }

    return ncheck % 10 === 0
  })

export const vatNumberValidation = (fieldName: string) =>
  yup
    .string()
    .matches(/^[0-9]*$/, {
      message: 'must be a number.',
      excludeEmptyString: true,
    })
    .test(fieldName, 'is invalid', function (vatNumber: $TSFixMe) {
      const checkVatNumberLength = vatNumber ? vatNumber.toString().length > 0 : false
      const digits = vatNumber ? vatNumber.split('').filter((char: $TSFixMe) => !isNaN(parseInt(char))) : []

      if (!checkVatNumberLength) {
        return true
      }

      // As per SARS
      // A VAT Number is a unique number, which comprises of 10 digits and starts with the number 4
      if (parseInt(digits[0]) !== 4) {
        return this.createError({ path: fieldName, message: 'First digit should be the number 4.' })
      }
      if (digits.length !== 10) {
        return this.createError({ path: fieldName, message: 'Length does not equal to 10 digits.' })
      }

      if (digits.length === 10 && parseInt(digits[0]) === 4) {
        const [subjectDigits, checkDigit] = [digits.slice(0, 9), parseInt(digits[9])]
        const result = subjectDigits.reduce((sum: $TSFixMe, digit: $TSFixMe, index: $TSFixMe) => {
          if (index % 2 !== 0) {
            return sum + parseInt(digit)
          } else if (parseInt(digit) < 5) {
            return sum + parseInt(digit) * 2
          } else {
            return sum + 1 + ((parseInt(digit) * 2) % 10)
          }
        }, 0)

        const calculatedDigit =
          parseInt(result.toString().slice(1)) === 0 ? 0 : 10 - parseInt(result.toString().slice(1))
        return calculatedDigit === checkDigit
      } else {
        return false
      }
    })

// Function to validate the check digit using the Luhn algorithm
const validateLuhn = (value: string) => {
  let sum = 0
  for (let i = 0; i < value.length; i++) {
    let digit = parseInt(value[i], 10)
    if (i % 2 === value.length % 2) {
      digit *= 2
      if (digit > 9) digit -= 9
    }
    sum += digit
  }
  return sum % 10 === 0
}

export const taxNumberValidation = (fieldName: string) =>
  yup
    .string()
    // .length(10, 'Must be exactly 10 characters long')
    .matches(/^[01239]/, {
      message: 'Must start with 0, 1, 2, 3, or 9',
      excludeEmptyString: true,
    })
    .test(fieldName, 'is invalid', function (taxNumber: $TSFixMe) {
      // return true if the value is empty
      if (taxNumber === '' || taxNumber === null || taxNumber === undefined) {
        return true
      }

      // check length of 13 digits
      if (taxNumber.length != 10) {
        return this.createError({ message: 'Number supplied does not have 10 digits.' })
      }

      return validateLuhn(taxNumber)
    })

import React from 'react'
import { animated, useSpring } from 'react-spring'
import useLayout from '../useLayout'
import styles from './SecondaryPanel.module.scss'
import { animationConfig } from '../../../views/constants'

function SecondaryPanel({ children }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'secondaryPanel' does not exist on type '... Remove this comment to see the full error message
  const { secondaryPanel } = useLayout()

  const style = useSpring({
    reverse: secondaryPanel.isOpen,
    config: animationConfig,
    to: { transform: `translateX(-${secondaryPanel.width}px)` },
    from: { transform: 'translateX(0px)' },
  })

  return (
    <animated.div style={{ height: '100%', ...style }}>
      <div className={styles.root} style={{ width: secondaryPanel.width }}>
        {children}
      </div>
    </animated.div>
  )
}

export default SecondaryPanel

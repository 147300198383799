import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { subscribe } from 'react-contextual'
import cx from 'classnames'
import { Button, ControlledTooltip, Header } from 'views/components'
import { $TSFixMe } from 'types/ts-migrate'
import { RootState } from 'src/state/store'
import styles from './DepositAccount.module.scss'
import { accountingApiSelectors } from 'modules/api/accounting'
import { useEffectOnce } from 'react-use'
import { userApiSelectors } from 'modules/api/user'
import { pathOr } from 'ramda'
import { format, isValid } from 'date-fns'
import { partyApiSelectors } from 'modules/api/party'
import { walletApiEvents, walletApiSelectors } from 'modules/api/wallet'
import DepositAccountProvider from './DepositAccountProvider'
import RefundProvider from 'modules/depositManagement/RefundProvider'
import { DepositTransferModal } from 'modules/depositManagement/components'
import { apiStateSelectors } from 'modules/api/apiState'
import { get } from 'lodash-es'
import { uiEvents } from 'modules/ui'

const propTypes = {
  className: PropTypes.string,
  isTenant: PropTypes.bool,
  partyId: PropTypes.string,
  portfolioId: PropTypes.string,
  accountId: PropTypes.string,
  accountType: PropTypes.string,
}

const defaultProps = {}

const DepositAccount = ({
  className,
  partyId,
  portfolioId,
  accountId,
  accountType,
  // DepositAccountProvider
  openDepositTransferModal,
  openDepositTopupRequest,
  depositTransferModal,
  closeDepositTransferModal,
  handleTransfer,
  // RefundProvider
  openRefundModal,
}: any) => {
  const dispatch = useDispatch()
  const classes = cx([styles.root, className])

  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)
  const hasDepositAccount = useSelector(state => walletApiSelectors.hasDepositAccount(state)(partyId, accountId))
  const depositAccountBalance = useSelector(state =>
    walletApiSelectors.getDepositAccountBalance(state)(partyId, accountId),
  )
  const partyName = useSelector(state => partyApiSelectors.getPartyNameById(state)(partyId))
  const partyAddress = useSelector(state => partyApiSelectors.getPartyAddressById(state)(partyId))
  const isTransferSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletApiEvents.transfer_request]),
  )
  const isTransferLoading = useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.balance_request]))

  useEffectOnce(() => {
    if (partyId && accountId) {
      dispatch(walletApiEvents.balance_request({ partyId }))
    }
  })

  const redirectTo = `/contacts/${partyId}`
  const handleViewStatement = (): void => {
    dispatch(uiEvents.redirect(`/contacts/${partyId}/${accountId}/deposit-statement?redirectTo=${redirectTo}`))
  }

  const handleDepositTransferRequest = useCallback(() => {
    openDepositTopupRequest(partyId, portfolioId, accountType)
  }, [partyId, portfolioId, accountType, openDepositTopupRequest])

  const statement = useSelector((state: RootState) =>
    accountingApiSelectors.getDepositStatementJsonById(state)(accountId as string),
  )
  const statementItems = pathOr([], ['context', 'statement', 'items'], statement)

  const partyHasBankDetails = useSelector(state => partyApiSelectors.partyHasBankDetails(state)(partyId))

  return (
    hasDepositAccount && (
      <>
        <section className={classes}>
          <Header
            className={styles.header}
            tag="h4"
            text="Deposit Wallet"
            trailingIcon={
              <Button pill tertiary size="sm" onClick={handleViewStatement}>
                View Statement
              </Button>
            }
          />
          <ul>
            {statementItems.map((item: any, i: any) => (
              <li key={i}>
                <span>{isValid(new Date(item.date)) ? format(new Date(item.date), 'dd MMM') : ''}</span>
                <span>{item.description}</span>
                <span>{item.balance}</span>
              </li>
            ))}
          </ul>
          <div className={styles.actions}>
            <Button pill ghost size="sm" onClick={handleDepositTransferRequest}>
              Request Top up
            </Button>
            {depositAccountBalance > 0 && isOwnerRole && (
              <>
                {partyHasBankDetails ? (
                  <Button pill ghost size="sm" onClick={openRefundModal}>
                    Refund deposit
                  </Button>
                ) : (
                  // @ts-expect-error Type '{ children: Element; body: string; place: "above"; }' is missing (ts. 2739)
                  <ControlledTooltip body="No bank details" place="above">
                    <Button pill ghost size="sm" onClick={() => {}}>
                      Refund deposit
                    </Button>
                  </ControlledTooltip>
                )}
                <Button pill ghost size="sm" onClick={openDepositTransferModal}>
                  Transfer
                </Button>
              </>
            )}
          </div>
        </section>
        <DepositTransferModal
          isOpen={!!depositTransferModal}
          onClose={closeDepositTransferModal}
          contactName={partyName}
          propertyAddress={partyAddress}
          onTransfer={handleTransfer}
          partyId={partyId}
          account={accountId}
          asset={get(depositTransferModal, ['asset'])}
          depositAccountBalance={depositAccountBalance}
          isSubmitting={isTransferSubmitting}
          isLoading={isTransferLoading}
        />
      </>
    )
  )
}

DepositAccount.propTypes = propTypes
DepositAccount.defaultProps = defaultProps

export default subscribe(
  [DepositAccountProvider, RefundProvider],
  (
    {
      openDepositTransferModal,
      openDepositTopupRequest,
      depositTransferModal,
      closeDepositTransferModal,
      handleTransfer,
    }: $TSFixMe,
    { refund: { handleOpen } }: $TSFixMe,
  ) => ({
    openDepositTransferModal,
    openDepositTopupRequest,
    depositTransferModal,
    closeDepositTransferModal,
    handleTransfer,
    openRefundModal: handleOpen,
  }),
)(DepositAccount)

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ListingTemplate } from '../../../../views/components'
import { PortfoliosTable, PropertiesSearchFilter } from '..'
import { portfolioApiEvents } from '../../../api/portfolio'
import { propertyApiEvents } from '../../../api/property'
import { invoiceApiEvents } from '../../../api/invoice'
import { apiStateSelectors } from '../../../api/apiState'

function Portfolios() {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(propertyApiEvents.properties_request())
    // dispatch(portfolioApiEvents.portfolios_request())
    dispatch(portfolioApiEvents.portfolioSummaries_request())
    dispatch(invoiceApiEvents.invoiceTypes_request())
  })

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([portfolioApiEvents.portfolioSummaries_request]),
  )

  return <ListingTemplate loading={isLoading} Header={<PropertiesSearchFilter />} Body={<PortfoliosTable />} />
}

export default Portfolios

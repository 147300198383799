import React, { FC, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { integrationsApiEvents, integrationsApiSelectors } from 'modules/api/integrations'
import { TTpnConsumerModule } from 'modules/api/integrations/types'
import { Loader, Switch, Tooltip } from 'views/components'
import CheckIcon from '../../../../../views/components/atoms/Icons/Controls/Check'
import styles from './TpnConsumerModuleSelection.module.scss'
import { apiStateSelectors } from 'modules/api/apiState'
import { $TSFixMe } from 'types/ts-migrate'
import { isEmpty } from 'ramda'

type Props = {
  selectedModules: (modules: any) => void
  selectedModulesByName: (modules: any) => void
  nationalId: string
}

const TpnConsumerModuleSelection: FC<Props> = ({ selectedModules, selectedModulesByName, nationalId }) => {
  const dispatch = useDispatch()
  const [isModuleIncluded, setIsModuleIncluded] = useState({})

  useEffectOnce(() => {
    if (nationalId !== null) {
      dispatch(integrationsApiEvents.tpnConsumerModules_request({ nationalId, RSAId: true }))
    }
    setIsModuleIncluded({ TPN: true })
  })

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([integrationsApiEvents.tpnConsumerModules_request]),
  )

  const consumerModulesList = useSelector(integrationsApiSelectors.getConsumerModulesList)

  const consumerModulesListFiltered = consumerModulesList.filter(module => {
    return !['HAWK', 'TPN_CrimCheck', '5874', 'TPN_IDV', 'TPN_STMT'].includes(module.id)
  })

  const moveObject = (arr: $TSFixMe, targetKey: string, targetValue: string, newIndex: number) => {
    const target = arr.find(value => value[targetKey] === targetValue)
    const newArray = arr.filter(value => value[targetKey] != targetValue)

    newArray.splice(newIndex, 0, target)
    return newArray
  }

  const consumerModulesListOrdered = consumerModulesListFiltered.find(module => module['id'] === 'TPN_RentCheck')
    ? moveObject(consumerModulesListFiltered, 'id', 'TPN_RentCheck', 1)
    : consumerModulesListFiltered

  const handleModuleToggle = (id: string) => {
    if (id === 'TPN_RentCheck' && isModuleIncluded['TPN']) {
      setIsModuleIncluded({
        TPN: false,
        ['TPN_RentCheck']: true,
      })
    } else {
      setIsModuleIncluded({
        ...isModuleIncluded,
        TPN: true,
        [id]: !isModuleIncluded[id],
      })
    }
  }

  useEffect(() => {
    let moduleArr = []
    let moduleArrByName = []
    for (const [key, value] of Object.entries(isModuleIncluded)) {
      const selectedModule =
        !isEmpty(consumerModulesListFiltered) && consumerModulesListFiltered.find(item => item.id === key)
      if (value) {
        // @ts-expect-error 'selectedModule' is possibly 'undefined'
        moduleArr.push(selectedModule.id)
        // @ts-expect-error 'selectedModule' is possibly 'undefined'
        moduleArrByName.push(selectedModule.name)
      }
    }
    selectedModules(moduleArr)
    selectedModulesByName(moduleArrByName)
  }, [isModuleIncluded])

  return isLoading || isEmpty(consumerModulesListOrdered) ? (
    <div className={styles['loading-modules']}>
      <Loader />
    </div>
  ) : (
    <div className={styles.root}>
      {consumerModulesListOrdered.map((module: TTpnConsumerModule, i: number) => (
        <div className={styles.module} key={i}>
          <div className={styles.header}>
            <h3>{module.name}</h3>
            <div className={styles.meta}>
              <span className={styles.price}>{module.price}</span>
            </div>
          </div>
          <p>{module.tooltip}</p>
          {module.id === 'TPN' && (
            <small>
              This report is mandatory if <i>&nbsp;RentCheck Enquiry&nbsp;</i> is not included.
            </small>
          )}
          <Switch
            className="integration-toggle"
            label={isModuleIncluded[module.id] ? 'Included' : 'Include'}
            checked={isModuleIncluded[module.id]}
            onChange={() => handleModuleToggle(module.id)}
            disabled={module.id === 'TPN' || (module.id !== 'TPN_RentCheck' && isModuleIncluded['TPN_RentCheck'])}
          />
        </div>
      ))}
    </div>
  )
}

export default TpnConsumerModuleSelection

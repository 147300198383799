import React from 'react'
import { createSelector } from 'reselect'
import { get, map, reduce, isEmpty } from 'lodash-es'
import { decamelize } from 'humps'
import { subDays, format, isDate } from 'date-fns'
import { partyApiSelectors } from '../../api/party'
import {
  getPortfolioById,
  getPrimaryLandlordByPortfolioId,
  getPrimaryTenantByPortfolioId,
} from '../../api/portfolio/selectors'
import { currencyUtils } from '../../../utils'
import { portfolioApiSelectors } from '../../api/portfolio'
import PropertiesIcon from '../../../views/components/atoms/Icons/Property/Properties'
import ContactIcon from '../../../views/components/atoms/Icons/User/Contact'
import ContactsIcon from '../../../views/components/atoms/Icons/User/Contacts'
import TermIcon from '../../../views/components/atoms/Icons/Controls/Calendar'
import RentIcon from '../../../views/components/atoms/Icons/Property/Room'
import SettingsIcon from '../../../views/components/atoms/Icons/Controls/Settings'
import CommissionIcon from '../../../views/components/atoms/Icons/Finance/Withdraw'
import InvoicesIcon from '../../../views/components/atoms/Icons/Doc/Invoice'
import RenewalIcon from '../../../views/components/atoms/Icons/Controls/Refresh'
import TerminationIcon from '../../../views/components/atoms/Icons/Controls/Delete'
import ActivateIcon from '../../../views/components/atoms/Icons/Controls/CheckDouble'
import Label from '../../../views/components/atoms/Label/Label'
import { $TSFixMe } from 'types/ts-migrate'
import { StringLiteralLike } from 'typescript'
import { PortfolioWizardStep, PortfolioWizardStepNames } from './types'

export const state = (state: any): $TSFixMe => state
export const chatbotDialogs = (state: any): $TSFixMe => state.chatbot.dialogs
export const chatbotPortfolio = (state: any): $TSFixMe => state.chatbot.portfolio
export const chatbotForms = createSelector(state, s => s.chatbot.forms)

export const getLeaseTypeSelectionDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseTypeSelection)
export const getPropertyDetailsDialog = createSelector(chatbotDialogs, dialogs => dialogs.propertyDetails)
export const getOwnerDetailsDialog = createSelector(chatbotDialogs, dialogs => dialogs.ownerDetails)
export const getTenantDetailsDialog = createSelector(chatbotDialogs, dialogs => dialogs.tenantDetails)
export const getLeaseTermsDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseTerms)
export const getLeaseRentAndFeesDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseRentAndFees)
export const getLeaseSettingsDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseSettings)
export const getLeaseCommissionDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseCommission)
export const getLeaseInvoicesDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseInvoices)
export const getLeaseConfirmationDialog = createSelector(chatbotDialogs, dialogs => dialogs.leaseConfirmation)

// Onboarding
export const getCurrentUserDetailsDialog = createSelector(chatbotDialogs, dialogs => dialogs.userDetails)
export const getAgencyDetailsDialog = createSelector(chatbotDialogs, dialogs => dialogs.agencyDetails)
export const getAgencyBrandingDialog = createSelector(chatbotDialogs, dialogs => dialogs.agencyBranding)
export const getOnboardingInvitesDialog = createSelector(chatbotDialogs, dialogs => dialogs.onboardingInvites)

export const getCurrentPortfolioId = createSelector(chatbotPortfolio, s => get(s, 'currentPortfolioId'))
export const isActivationConfirmationOpen = createSelector(chatbotPortfolio, s => get(s, 'activationConfirmationOpen'))

export const getDialogChanges = createSelector(
  chatbotDialogs,
  dialogs => (dialog: any) => get(dialogs, [dialog, 'changes'], {}),
)

export const hasSelectedLeaseType = createSelector(
  getLeaseTypeSelectionDialog,
  leaseTypeDialog => !!get(leaseTypeDialog, 'leaseType', false),
)

export const getCurrentPortfolioStatus = createSelector(state, state => {
  const portfolio = getPortfolioById(state)(getCurrentPortfolioId(state))
  if (portfolio === undefined || isEmpty(portfolio)) {
    return 'in progress'
  }
  return get(portfolio, 'tags.status', '')
})

const hasSelectedProperty = createSelector(
  getPropertyDetailsDialog,
  dialog => !!(dialog.selectedProperty || dialog.selectedPlace),
)

const getPartyNames = (state: any, parties: any): string => {
  const names = parties.map((party: any) => {
    return partyApiSelectors.getPartyNameById(state)(party.partyId)
  })
  return names.join(', ')
}
const getPartyEmails = (state: any, parties: any): StringLiteralLike => {
  const emails = parties.map((party: any) => {
    return partyApiSelectors.getPartyEmailById(state)(party.partyId)
  })
  return emails.join(', ')
}

export const getActivationItems = createSelector(state, state => {
  const currentPortfolioId = getCurrentPortfolioId(state)
  const primaryOwnerId = getPrimaryLandlordByPortfolioId(state)(currentPortfolioId)
  const primaryTenantId = getPrimaryTenantByPortfolioId(state)(currentPortfolioId)
  const ownerEmail: string = partyApiSelectors.getPartyEmailById(state)(primaryOwnerId)
  const tenantEmail: string = partyApiSelectors.getPartyEmailById(state)(primaryTenantId)
  const leaseInvoices = getLeaseInvoicesDialog(state)?.invoices || []
  const invoiceTemplateSchedules = portfolioApiSelectors.getInvoiceSchedulesByPortfolioId(state)(currentPortfolioId)
  const landlords = portfolioApiSelectors.getAllOwnerPartyIdsByPortfolioId(state)(currentPortfolioId)
  const tenants = portfolioApiSelectors.getAllTenantPartyIdsByPortfolioId(state)(currentPortfolioId)
  const tenantCount: number = tenants.length
  const landlordCount: number = landlords.length
  const items = { immediate: [], monthly: [] }
  const nameOwner: string = partyApiSelectors.getPartyNameById(state)(primaryOwnerId)
  const namePrimaryTenant: string = partyApiSelectors.getPartyNameById(state)(primaryTenantId)
  const getPartyName = partyApiSelectors.getPartyNameById(state)

  leaseInvoices.forEach((inv: any) => {
    const category: string = decamelize(inv.category, { separator: ' ' })
    const accountType: string = inv.invoicePartyAccountType
    // @todo fetch invoice types and use the selector above instead of decamelize
    const netAmount: string = inv.netAmount ? currencyUtils.formatCurrency(inv.netAmount) : '(set on send)'
    if (inv.interval !== 'OnceOff') {
      const schedule = invoiceTemplateSchedules.find((s: any) => s.invoiceTemplateId === inv.id)
      const nextSend = get(schedule, ['dates', 0, 'sendAt'], '')
      const nextGeneration =
        nextSend && isDate(new Date(nextSend)) ? format(subDays(new Date(nextSend), 7), 'yyyy-MM-dd') : false
      items.monthly.push({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        mainText: `Generate a draft invoice for ${accountType}, ${getPartyName(
          inv.invoicePartyId,
        )}, on the 10th of each month for ${category} of ${netAmount}`,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        secondaryText: nextGeneration ? `Next generation -> ${nextGeneration}` : '',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        checked: true,
      })
    } else {
      // needs to be tenant or owner
      items.immediate.push({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        mainText:
          `Generate a once-off draft invoice for the ${accountType}, ${
            getPartyName(inv.invoicePartyId) as string
          }, for ${category} ` +
          (inv.netAmount
            ? `of ${currencyUtils.formatCurrency(inv.netAmount) as string}`
            : ' with the amount set on send'),
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        secondaryText: 'Generate -> immediately',
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        checked: true,
      })
    }
  })

  tenantCount === 1
    ? items.immediate.push({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        mainText: `Send the Tenant, ${namePrimaryTenant}, a lease activation notification`,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        secondaryText: `Send -> Immediately / ${tenantEmail}`,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        checked: true,
      })
    : items.immediate.push({
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        mainText: `Send Tenants, ${getPartyNames(state, tenants)}, a lease activation notification`,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
        secondaryText: `Send -> Immediately / ${getPartyEmails(state, tenants) as string}`,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
        checked: true,
      })
  return items
})

const getOwnerDetailsProgress = createSelector(state, s => {
  const ownerDialog = getOwnerDetailsDialog(s)
  return ownerDialog.selectedParty ? 100 : 0
})

const getApplicationsProgress = createSelector(state, s => (portfolioId: string) => {
  const applications = portfolioApiSelectors.getApplicationsByPortfolioId(s)(portfolioId)
  if (applications?.find(application => application.status === 'Accepted')) {
    return 100
  } else if (!isEmpty(applications)) {
    return 50
  } else {
    return 0
  }
})

const getTenantDetailsProgress = createSelector(state, s => {
  const tenantsDialog = getTenantDetailsDialog(s)
  return tenantsDialog.selectedParty ? 100 : 0
})

const getLeaseTermsProgress = createSelector(state, s => {
  const { term } = getLeaseTermsDialog(s)
  const progress = reduce(
    term,
    (result: any, val: any) => {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return val ? result + 100 / Object.keys(term).length : result
    },
    0,
  )
  return progress
})

const getLeaseSettingsProgress = createSelector(state, s => {
  const { notes, customLeaseIdentifier } = getLeaseSettingsDialog(s)
  if (notes && customLeaseIdentifier) {
    return 100
  } else if (notes || customLeaseIdentifier) {
    return 50 // other two options are always valid
  } else {
    return 0
  }
})

const getLeaseRentProgress = createSelector(state, s => {
  const leaseDialog = getLeaseRentAndFeesDialog(s)
  const progress = reduce(
    leaseDialog,
    (result: any, val: any, key: any) => {
      const objPropsLength = Object.keys(leaseDialog).length
      if (typeof val === 'object') {
        if (isNaN(parseInt(val.netAmount))) {
          return result
        } else {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return result + 100 / objPropsLength
        }
      } else if (isNaN(parseInt(val))) {
        return result
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return result + 100 / objPropsLength
      }
    },
    0,
  )
  return progress
})

const getLeaseCommissionProgress = createSelector(state, s => {
  const { managementFee } = getLeaseCommissionDialog(s)
  const managementAmount = get(managementFee, 'netAmount.value', false)
  const managementPercentage = get(managementFee, 'percentage.value', false)
  const managementSplitCount = get(managementFee, 'splits', []).length
  let managementProgress = 0
  if (managementAmount || managementPercentage) {
    if (managementSplitCount > 0) {
      managementProgress = 100
    } else {
      managementProgress = 50
    }
  }

  return managementProgress
})

export const getPortfolioNavbarItems = createSelector(state, (state): PortfolioWizardStep[] => {
  const currentPortfolio = getCurrentPortfolioId(state)
  const selectedProperty = hasSelectedProperty(state)
  const hasSavedState = (dialog: any): $TSFixMe => dialogSavedStateSelector(state)(dialog) !== undefined
  const hasLeaseType = hasSelectedLeaseType(state)
  const isDraftPortfolio = portfolioApiSelectors.isDraftPortfolio(state)(currentPortfolio)
  const isPortfolioTerminated = portfolioApiSelectors.isPortfolioTerminated(state)(currentPortfolio)
  const isPortfolioExpired = portfolioApiSelectors.isPortfolioExpired(state)(currentPortfolio)
  const allLandlords = portfolioApiSelectors.getAllOwnerPartyIdsByPortfolioId(state)(currentPortfolio)
  const allApplications = portfolioApiSelectors.getAllApplicationsByPortfolioId(state)(currentPortfolio)
  const allTenants = portfolioApiSelectors.getAllTenantPartyIdsByPortfolioId(state)(currentPortfolio)
  const acceptedApplication = portfolioApiSelectors.getAcceptedApplicationByPortfolioId(state)(currentPortfolio)
  /**
   * @todo temp code please remove
   * allows the property button to get marked off
   */
  const hasProperty = getPropertyDetailsDialog(state).selectedProperty !== null
  const items: PortfolioWizardStep[] = [
    selectedProperty
      ? {
          name: PortfolioWizardStepNames.property,
          dependencies: [],
          route: '/leases/:id/edit/property',
          label: 'Property',
          activeWhen: ['/leases/:id/edit/property'],
          hasCheckmark: hasSavedState('propertyDetails') || hasProperty,
          progress: hasProperty ? 100 : 0,
          Icon: () => <PropertiesIcon />,
          isVisible: true,
        }
      : {
          name: PortfolioWizardStepNames.property,
          dependencies: [],
          route: '/leases/:id/edit',
          label: 'Property',
          activeWhen: ['/leases/:id/edit'],
          hasCheckmark: hasSavedState('propertyDetails') || hasProperty,
          progress: hasProperty ? 100 : 0,
          Icon: () => <PropertiesIcon />,
          isVisible: true,
        },
    {
      name: PortfolioWizardStepNames.owners,
      dependencies: [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/property/owner',
      label: 'Owners',
      hasCheckmark: hasSavedState('ownerDetails'),
      progress: getOwnerDetailsProgress(state),
      Icon: () => (
        <>
          <span>
            <ContactIcon />
            <span style={{ position: 'absolute', left: '15px', top: '15px', margin: '0' }}>
              <Label
                circular
                size="sm"
                style={{ minWidth: '20px', backgroundColor: 'rgba(229,227,229)', lineHeight: '17px' }}
              >
                {allLandlords.length}
              </Label>
            </span>
          </span>
        </>
      ),
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.applications,
      dependencies: [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/applications',
      label: 'Applications',
      hasCheckmark: getApplicationsProgress(state)(currentPortfolio) === 100,
      progress: getApplicationsProgress(state)(currentPortfolio),
      Icon: () => (
        <>
          <span>
            <ContactsIcon />
            <span style={{ position: 'absolute', left: '15px', top: '15px', margin: '0' }}>
              <Label
                circular
                size="sm"
                style={{ minWidth: '20px', backgroundColor: 'rgba(229,227,229)', lineHeight: '17px' }}
              >
                {allApplications.length}
              </Label>
            </span>
          </span>
        </>
      ),
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.tenants,
      dependencies:
        getApplicationsProgress(state)(currentPortfolio) === 50
          ? [PortfolioWizardStepNames.property, PortfolioWizardStepNames.applications]
          : [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/tenants',
      label: 'Tenants',
      hasCheckmark: hasSavedState('tenantDetails'),
      progress: getTenantDetailsProgress(state),
      Icon: () => (
        <>
          <span>
            <ContactsIcon />
            <span style={{ position: 'absolute', left: '15px', top: '15px', margin: '0' }}>
              <Label
                circular
                size="sm"
                style={{ minWidth: '20px', backgroundColor: 'rgba(229,227,229)', lineHeight: '17px' }}
              >
                {allTenants.length}
              </Label>
            </span>
          </span>
        </>
      ),
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.lease,
      dependencies: [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/term',
      label: 'Lease',
      hasCheckmark: hasSavedState('leaseTerm'),
      progress: getLeaseTermsProgress(state),
      Icon: () => <TermIcon />,
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.rent,
      dependencies: [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/rent-and-fees',
      label: 'Rent',
      hasCheckmark: hasSavedState('leaseRentAndFees'),
      progress: getLeaseRentProgress(state),
      Icon: () => <RentIcon />,
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.settings,
      dependencies: [PortfolioWizardStepNames.property],
      route: '/leases/:id/edit/settings',
      label: 'Settings',
      hasCheckmark: hasSavedState('leaseSettings'),
      progress: getLeaseSettingsProgress(state),
      Icon: () => <SettingsIcon />,
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.commission,
      dependencies: [PortfolioWizardStepNames.property, PortfolioWizardStepNames.rent],
      route: '/leases/:id/edit/commission',
      label: 'Commission',
      hasCheckmark: hasSavedState('leaseCommission'),
      progress: getLeaseCommissionProgress(state),
      Icon: () => <CommissionIcon />,
      isVisible: true,
    },
    {
      name: PortfolioWizardStepNames.invoices,
      dependencies: [
        PortfolioWizardStepNames.property,
        PortfolioWizardStepNames.tenants,
        PortfolioWizardStepNames.owners,
        PortfolioWizardStepNames.rent,
        PortfolioWizardStepNames.lease,
        PortfolioWizardStepNames.commission,
      ],
      route: '/leases/:id/edit/invoices',
      label: 'Invoices',
      hasCheckmark: hasSavedState('leaseInvoices'),
      progress: (getLeaseInvoicesDialog(state)?.invoices || []).length > 0 ? 100 : 0,
      Icon: () => <InvoicesIcon />,
      isVisible: true,
    },
  ]

  if (isDraftPortfolio) {
    items.push({
      name: PortfolioWizardStepNames.activation,
      dependencies:
        getApplicationsProgress(state)(currentPortfolio) === 50
          ? [
              PortfolioWizardStepNames.property,
              PortfolioWizardStepNames.applications,
              PortfolioWizardStepNames.tenants,
              PortfolioWizardStepNames.owners,
              PortfolioWizardStepNames.rent,
              PortfolioWizardStepNames.lease,
              PortfolioWizardStepNames.settings,
              PortfolioWizardStepNames.commission,
              PortfolioWizardStepNames.invoices,
            ]
          : [
              PortfolioWizardStepNames.property,
              PortfolioWizardStepNames.tenants,
              PortfolioWizardStepNames.owners,
              PortfolioWizardStepNames.rent,
              PortfolioWizardStepNames.lease,
              PortfolioWizardStepNames.settings,
              PortfolioWizardStepNames.commission,
              PortfolioWizardStepNames.invoices,
            ],
      route: '/leases/:id/edit/activation',
      label: 'Activate',
      hasCheckmark: getLeaseConfirmationDialog(state).isActivated,
      progress: getLeaseConfirmationDialog(state).isActivated ? 100 : 0,
      Icon: () => <ActivateIcon />,
      isVisible: true,
    })
  } else if (!isPortfolioTerminated) {
    items.push({
      name: PortfolioWizardStepNames.renewal,
      dependencies: [],
      route: '/leases/:id/edit/renewal',
      label: 'Renewal',
      hasCheckmark: false,
      progress: getLeaseConfirmationDialog(state).isActivated ? 100 : 0,
      Icon: () => <RenewalIcon />,
      isVisible: true,
    })
    if (!isPortfolioExpired) {
      items.push({
        name: PortfolioWizardStepNames.termination,
        dependencies: [],
        route: '/leases/:id/edit/termination',
        label: 'Termination',
        hasCheckmark: false,
        progress: getLeaseConfirmationDialog(state).isActivated ? 100 : 0,
        Icon: () => <TerminationIcon />,
        isVisible: true,
      })
    }
  }

  const id = currentPortfolio || 'new'

  return items
    .map(({ name, dependencies, route, label, hasCheckmark, progress, activeWhen = [], Icon, isVisible }) => ({
      name,
      dependencies,
      route: route.replace(':id', id),
      label,
      hasCheckmark,
      progress,
      activeWhen: map(activeWhen, (route: any) => route.replace(':id', id)),
      Icon,
      isVisible,
    }))
    .map(({ name, dependencies, route, label, hasCheckmark, activeWhen, progress, Icon, isVisible }) => ({
      name,
      dependencies,
      route,
      label,
      hasCheckmark,
      progress,
      isActive: (url: any) => activeWhen.concat([route]).includes(url),
      Icon,
      isVisible,
    }))
    .filter(({ isVisible }) => isVisible)
})

export const dialogSavedStateSelector = createSelector(
  chatbotPortfolio,
  portfolio => (dialog: any) => portfolio.savedDialogs[dialog],
)

import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce, useSearchParam } from 'react-use'
import { IntegrationsPage } from '..'
import { getItem } from 'utils/localStorage'
import { $TSFixMe } from 'types/ts-migrate'
import { integrationsApiEvents } from 'modules/api/integrations'

const IntegrationsRoute = (props: $TSFixMe) => {
  const dispatch = useDispatch()
  const code = useSearchParam('code')
  const state = getItem('state')
  const syncExistingPropertiesItem = getItem('syncExistingProperties')
  const syncExistingProperties = syncExistingPropertiesItem === 'true' ? true : false
  useEffectOnce(() => {
    if (code && state) {
      dispatch(
        integrationsApiEvents.activateIntegration_request({
          integrationType: 'PropertyInspect',
          code,
          syncExistingProperties: syncExistingProperties,
          redirectUrl: `${window.location.origin}/integrations`,
        }),
      )
    }
  })

  return <IntegrationsPage />
}

export default IntegrationsRoute

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { map, pathOr, prop, uniqBy } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { toCents } from 'utils/currency'
import { userApiEvents } from '../user'
import { TApplicationInvoiceStatus } from './types'

const initialState: $TSFixMe = {
  invoices: [],
  creditNoteReasons: [],
  notifications: [],
  customerActiveInvoicesByAccount: {},
  customerAccountActivity: {},
  easypayData: {},
  activeInvoicesById: {},
  bulkInvoices: [],
  bulkInvoiceUploadErrors: [],
  stats: {},
  applicationInvoicesStatus: {},
  archivedPeriod: {},
}

const slice = createSlice({
  name: 'reconApi',
  initialState,
  reducers: {
    invoices_request: (state, { payload }) => {},
    invoices_success: (state, { payload }) => {
      state.invoices = uniqBy((inv: any) => inv.id, [...state.invoices, ...payload])
    },
    invoice_request: (state, { payload }) => {},
    invoice_success: (state, { payload }) => {
      state.invoices = uniqBy(prop('id'), [...state.invoices, payload])
    },
    creditNoteReasons_request: state => {},
    creditNoteReasons_success: (state, { payload }) => {
      state.creditNoteReasons = payload
    },
    creditNote_request: {
      prepare: (payload, meta) => ({ payload, meta, error: null }),
      reducer: (state, { payload }) => {},
    },
    creditNote_success: {
      prepare: (payload, meta) => ({ payload, meta, error: null }),
      reducer: (state, { payload }) => {
        state.invoices = state.invoices.map(inv => {
          const updatedInvoice = payload.find((p: any) => p.id === inv.id)
          return updatedInvoice || inv
        })
      },
    },
    payment_request: (state, { payload }) => {},
    payment_success: (state, { payload }) => {
      state.invoices = state.invoices.map(inv => {
        const updatedInvoice = payload.find((p: any) => p.id === inv.id)
        return updatedInvoice || inv
      })
    },
    updateBenficiaries_request: (state, { payload }) => {},
    updateBenficiaries_success: {
      reducer: (state, action: PayloadAction<$TSFixMe, string, $TSFixMe>) => {
        const { payload, meta } = action
        state.invoices = state.invoices.map((inv: { id: any }) => {
          const updatedInvoice = payload.invoices.find((p: any) => p.id === inv.id)
          return updatedInvoice || inv
        })
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    invoicesNudge_request: (state, { payload }) => {},
    invoicesNudge_success: (state, { payload }) => {},
    notifications_request: state => {},
    notifications_success: (state, { payload }) => {
      state.notifications = payload
    },
    customerActiveInvoices_request: (state, { payload }) => {},
    customerActiveInvoices_success: {
      reducer: (state, action: PayloadAction<$TSFixMe, string, $TSFixMe>) => {
        const {
          payload,
          meta: { customerId, accountId },
        } = action
        state.customerActiveInvoicesByAccount[customerId] = {
          ...pathOr({}, [customerId], state.customerActiveInvoicesByAccount),
          [accountId]: payload,
        }
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    customerAccountActivity_request: (state, { payload }) => {},
    customerAccountActivity_success: {
      reducer: (state, { payload, meta: { customerId, accountId } }) => {
        state.customerAccountActivity[customerId] = {
          ...pathOr({}, [customerId], state.customerAccountActivity),
          [accountId]: payload,
        }
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    easypayData_request: (state, { payload }) => {},
    easypayData_success: {
      reducer: (state, { payload, meta }) => {
        if (meta?.reference) {
          state.easypayData[meta.reference] = payload
        }
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    invoicesActive_request: (state, { payload }) => {},
    invoicesActive_success: (state, { payload }) => {
      state.activeInvoicesById[payload.id] = payload.activeInvoices
    },
    bulkInvoiceUpload_request: (state, { payload }) => {},
    bulkInvoiceUpload_success: (state, { payload }) => {},
    bulkInvoiceUpload_failed: (state, { payload }) => {
      state.bulkInvoiceUploadErrors = payload
    },
    stats_request: (state, { payload }) => {},
    stats_success: (state, { payload }) => {
      state.stats = payload
    },
    applicationInvoiceByPortfolio_request: (state, { payload }: PayloadAction<{ portfolioId: string }>) => {},
    applicationInvoiceByPortfolio_success: {
      reducer: (
        state,
        { payload, meta }: PayloadAction<TApplicationInvoiceStatus[], string, { portfolioId: string }>,
      ) => {
        state.applicationInvoicesStatus[meta.portfolioId] = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    archived_request: (state, { payload: { startDate, endDate } }) => {},
    archived_success: {
      reducer: (state, { payload, meta }) => {
        state.archivedPeriod = meta
        state.invoices = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [userApiEvents.refreshToken_success]: state => {
      state.invoices = []
    },
    // [portfolioApiEvents.sendApplicationInvoice_success]: (
    /** @todo temporarily making a string because imports are breaking tests for some weird reason. */
    'portfolioApi/sendApplicationInvoice_success': (
      state,
      action: PayloadAction<{ portfolioId: string; applicationId: string; invoiceId: string; status: string }>,
    ) => {
      const portfolioStatusSlice = state.applicationInvoicesStatus[action.payload.portfolioId] || []
      state.applicationInvoicesStatus[action.payload.portfolioId] = [...portfolioStatusSlice, action.payload]
    },
  },
})

const { reducer, actions: events } = slice

export { events, initialState, reducer }

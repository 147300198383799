import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const bookSegmentsSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  reducers: {
    createSegment_request: (state, { payload }) => {},
    createSegment_success: (state, { payload }) => {},
    updateSegment_request: (state, { payload }) => {},
    updateSegment_success: (state, { payload }) => {},
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = bookSegmentsSlice

export { events as bookSegmentsEvents, reducer as bookSegmentsReducer }

import { createSlice } from '@reduxjs/toolkit'
import { reconApiEvents } from 'modules/api/recon'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'

export interface CreditNoteInitialState {
  invoiceId: null | string
}

const initialState: CreditNoteInitialState = {
  invoiceId: null,
}

const creditNoteSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    openCreditNoteRequested: (state, { payload }: { payload: string }) => {
      state.invoiceId = payload
    },
    closeCreditNoteRequested: state => {
      state.invoiceId = null
    },
  },
  extraReducers: {
    // @ts-expect-error
    [reconApiEvents.creditNote_success]: state => {
      state.invoiceId = null
    },
  },
})

const { reducer, actions: events } = creditNoteSlice

export { events as creditNoteEvents, reducer as creditNoteReducer }

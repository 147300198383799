import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import CloseIcon from '../../../atoms/Svgs/Close'
import SearchInputOverlay from '../../../organisms/SearchInputOverlay/SearchInputOverlay'
import { animationConfig } from '../../../../constants'
import { AggregateTable, TableProvider } from '../../Table'
import styles from './SegmentsDrawer.module.scss'
import { ROUTES } from '../../../../../constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { userApiEvents, userApiSelectors } from '../../../../../modules/api/user'
import { agencyApiEvents, agencyApiSelectors } from '../../../../../modules/api/agency'
import { jsonStringSearch } from '../../../../../utils/string'

export const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  userRole: PropTypes.string,
  redirect: PropTypes.func,
}

const SegmentsDrawer = ({ open, onClose, userRole, redirect, agencyCreated }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const currentSegments = useSelector(userApiSelectors.getCurrentSegments)
  const defaultSegment = useSelector(userApiSelectors.getCurrentAgencyId)
  // const currentSegmentsWithoutDefault = useSelector(userApiSelectors.getCurrentSegmentsWithoutDefault)

  useEffect(() => {
    if (open) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      dispatch(agencyApiEvents.getSegments_request())
      // refresh token to potentially get new token memberships
      dispatch(userApiEvents.refreshToken_request({ selectedSegments: currentSegments }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dispatch])

  const style = useSpring({
    reverse: !open,
    config: animationConfig,
    to: { transform: 'translateX(0)' },
    from: { transform: 'translateX(110%)' },
  })

  const [query, setQuery] = useState('')

  const columns = [
    {
      Header: 'Segment',
      accessor: 'segment',
    },
  ]

  const segments = useSelector(agencyApiSelectors.getSegments)
  const segmentMemberships = useSelector(userApiSelectors.getSegmentMembershipsWithoutDefault)
  const currentSegmentIds = useSelector(userApiSelectors.getCurrentSegments)

  const data = useMemo(() => {
    const _data = segmentMemberships
      .map((id: any) => {
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        const segment = segments.find((segment: any) => segment.id === id)
        return (
          segment && {
            id: segment.id,
            segment: segment.name,
          }
        )
      })
      .filter((segment: any) => segment !== undefined)

    return query ? _data.filter((d: any) => jsonStringSearch(query, d)) : _data
  }, [segmentMemberships, segments, query])

  const [selection, setSelection] = useState([])

  const handleApplyFilters = useCallback(() => {
    const selectedSegments = selection.length === 0 ? [defaultSegment] : selection.filter(id => id !== defaultSegment)
    dispatch(userApiEvents.refreshToken_request({ selectedSegments }))
  }, [selection, dispatch, defaultSegment])

  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)

  return (
    <animated.div id="rbn-segments" className={styles.root} style={style}>
      <button className={styles.close} onClick={onClose}>
        <CloseIcon />
      </button>
      <header>
        <SearchInputOverlay
          name="search-segments"
          placeholder="Search segments..."
          onChange={setQuery}
          iconFill="#fff"
          className={styles.search}
        >
          <h1>Filter Segments</h1>
        </SearchInputOverlay>
        <p>
          The Filter Segments Panel allows you to quickly filter your account to only show leases and invoices from the
          selected segment(s).
          {isOwnerRole && (
            <>
              You can manage segments{' '}
              <button
                onClick={() => {
                  redirect(ROUTES.segments)
                  onClose()
                }}
              >
                here
              </button>
            </>
          )}
        </p>
      </header>
      <div className={styles.body}>
        <TableProvider>
          {data.length > 0 && (
            <AggregateTable
              enableSelection
              columns={columns}
              interactive={false}
              data={data}
              initialSelection={currentSegmentIds}
              onSelectionChange={setSelection}
            />
          )}
        </TableProvider>
      </div>
      <button className={styles['apply-filters']} type="button" onClick={handleApplyFilters}>
        Apply filters
      </button>
    </animated.div>
  )
}

SegmentsDrawer.propTypes = propTypes

export default SegmentsDrawer

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useSelector, useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { events as userApiEvents } from '../../modules/api/user/state'
import { userApiSelectors } from '../../modules/api/user'
import { events as agencyApiEvents } from '../../modules/api/agency/state'
import { agencyApiSelectors } from '../../modules/api/agency'
import { pathOr } from 'ramda'
import { uiEvents } from '../../modules/ui'

const SettingsProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(userApiEvents.team_request())
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(agencyApiEvents.invitations_request())
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(agencyApiEvents.invitableParties_request())
  })

  const userRole = useSelector(state => userApiSelectors.getUserRole(state))
  const location = useSelector(state => pathOr('', ['router', 'location'], state))
  const teamMembers = useSelector(userApiSelectors.getTeam)
  const agentInvitations = useSelector(agencyApiSelectors.invitations)
  const invitableParties = useSelector(agencyApiSelectors.getInvitableParties)

  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const agentMembers = invitableParties.map((agent: any) => {
    const { person } = agent
    const agentObj = {
      userId: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      emailAddress: person.emailAddress,
      cellNumber: person.cellNumber,
    }
    return agentObj
  })

  const store = {
    userRole,
    location,
    teamMembers,
    agentInvitations,
    agentMembers,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    requestRevokeAccess: (id: any) => dispatch(userApiEvents.revokeAccess_request({ userId: id })),
    requestRevokeInvite: (id: any) => dispatch(agencyApiEvents.revokeInvite_request({ partyId: id })),
    requestSendPartyInvite: (partyId: any, otp: any) =>
      dispatch(agencyApiEvents.sendPartyInvite_request({ params: { partyId }, body: { otp } })),
    resendInvite: (partyId: any) => dispatch(agencyApiEvents.resendInvite_request({ partyId })),
    navItems: [
      { text: 'Profile', route: '/settings/profile' },
      { text: 'Business Details', route: '/settings/business-details' },
      { text: 'Branding', route: '/settings/branding' },
      { text: 'Users', route: '/settings/users' },
    ],
    ...props,
  }
  return <context.Provider value={store}>{children}</context.Provider>
})

export default SettingsProvider

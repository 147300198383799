import React, { useMemo } from 'react'
import { AggregateTable, CurrencyText, Header, Loader } from 'views/components'
// import PropTypes from 'prop-types'
import styles from './BadReferencesTable.module.scss'
import SettingIcon from 'components/atoms/Icons/Controls/Settings'
import { map } from 'ramda'
import { formatStandard } from 'utils/date'
import { subscribe } from 'react-contextual'
import { SearchFilterProvider } from 'views/providers'
import BadReferencesProvider from '../../BadReferencesProvider'
import { stringUtils } from '../../../../utils'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import { walletApiEvents } from '../../../api/wallet'
import { $TSFixMe } from 'types/ts-migrate'

const BadReferencesTable = ({
  searchQuery,
  activeFilterIndex,
  filters,
  openReference,
  currentReference,
}: any): React.ReactElement => {
  const filter = filters[activeFilterIndex]

  const transIdCol = {
    Header: 'Transaction ID',
    accessor: 'txId',
    style: {
      maxWidth: '700px',
    },
  }

  const refCol = {
    Header: 'Reference used',
    accessor: 'referenceUsed',
    style: {
      maxWidth: '250px',
    },
  }

  const allocationCol = {
    Header: 'Allocation Details',
    accessor: 'allocatedToRef',
    style: {
      maxWidth: '250px',
    },
  }

  const dateCol = {
    Header: 'Date',
    accessor: 'dateReceived',
    style: {
      maxWidth: '250px',
    },
  }

  const amountCol = {
    Header: 'Amount',
    accessor: 'amount',
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '20px',
      maxWidth: '220px',
    },
  }

  // Previous const columns = [{}]
  const columns =
    filter?.key === 'unallocated'
      ? [transIdCol, refCol, dateCol, amountCol]
      : [transIdCol, refCol, allocationCol, dateCol, amountCol]

  const allocatedRow = (ref: any): $TSFixMe => {
    return {
      ...ref,
      dateReceived: (
        <div>
          <strong>Recieved: {formatStandard(ref.dateReceived)}</strong> <br />
          <small>Allocated: {formatStandard(ref.allocatedAt)} </small>
        </div>
      ),
      amount: <CurrencyText>{ref.amount}</CurrencyText>,
      allocatedToRef: (
        <div>
          <strong>To: {ref.allocatedToRef} </strong> <br />
          <small>By: {ref.allocatedBy} </small>
        </div>
      ),
      highlighted: currentReference?.txId === ref.txId,
    }
  }

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([
      walletApiEvents.unallocatedBadReferences_request,
      walletApiEvents.allocatedBadReferences_request,
    ]),
  )

  const unAllocatedRow = (ref: any): $TSFixMe => {
    return {
      ...ref,
      dateReceived: formatStandard(ref.dateReceived),
      amount: <CurrencyText>{ref.amount}</CurrencyText>,
      highlighted: currentReference?.txId === ref.txId,
    }
  }

  const buildRow = (ref: any): $TSFixMe => (filter?.key === 'unallocated' ? unAllocatedRow(ref) : allocatedRow(ref))

  const data = filter.data
  const filteredData = useMemo(() => {
    return data.filter((inv: any) => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const tableData = searchQuery ? map(buildRow, filteredData) : map(buildRow, data)

  return (
    <div className={styles.root}>
      <Header icon={<SettingIcon />} text="Bad references" />
      {isLoading ? (
        <Loader />
      ) : (
        <AggregateTable
          rowHeight={80}
          columns={columns}
          data={tableData}
          interactive={true}
          onRowClick={row => openReference(row.txId)}
        />
      )}
    </div>
  )
}

BadReferencesTable.propTypes = {}

export default subscribe(
  [SearchFilterProvider, BadReferencesProvider],
  ({ searchQuery, activeFilterIndex }: any, { filters, openReference, currentReference }: any) => ({
    searchQuery,
    activeFilterIndex,
    filters,
    openReference,
  }),
)(BadReferencesTable)

import { connect } from 'react-redux'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { dispatch, push }: any) => ({
  actions: [],

  messages: ['Down to business! I just need a few more details from you.'],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

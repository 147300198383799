import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { SegmentsContent, SegmentsProvider } from '../..'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../../views/components'

const SegmentsPage = () => {
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="segments"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: false, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Book Segments</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutPrimaryPanel>{/* {params.id ? <ViewContact /> : null} */}</LayoutPrimaryPanel>
          <LayoutContent>
            <TableProvider>
              <SegmentsContent />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(SegmentsProvider, ({ match }: any) => ({
  match,
}))(SegmentsPage)

import React, { FC } from 'react'
import styles from './Image.module.scss'

type Props = {
  src: string
  alt?: string
  width?: string
}

const Image: FC<Props> = ({ ...props }) => {
  return (
    <div className={styles.root}>
      <img {...props} alt={props.alt || ''} />
    </div>
  )
}

export default Image

import { uiEvents } from 'modules/ui'
import { getPortfolioNavbarItems } from 'modules/ui/ChatBot/selectors'
import { PortfolioWizardStep, PortfolioWizardStepNames } from 'modules/ui/ChatBot/types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InfoBox, VoiceRobin } from 'views/components'
import styles from './PortfolioRequiredSteps.module.scss'

type Props = {
  stepName: PortfolioWizardStepNames
  children: React.ReactChild
}

const PortfolioRequiredSteps = ({ stepName, children }: Props) => {
  const dispatch = useDispatch()
  const steps = useSelector(getPortfolioNavbarItems)
  const step = steps.find(({ name }) => name === stepName)
  const dependencies = step?.dependencies.map(dep => steps.find(s => s.name === dep)).filter(s => s !== undefined) || []
  const incompleteDependencies = dependencies.filter(dep => !dep?.hasCheckmark)

  const redirect = useCallback(
    (route: string) => {
      dispatch(uiEvents.redirect(route))
    },
    [dispatch],
  )

  return (
    <div className={styles.root}>
      {incompleteDependencies.length > 0 ? (
        <>
          <header className={styles.header}>
            <VoiceRobin text="Wait a tick!" />
            <VoiceRobin text="You still need to complete the following steps - " />
          </header>
          <ul>
            {incompleteDependencies.map(dep => (
              <li key={dep?.name}>
                <Button link onClick={() => dep?.route && redirect(dep.route)}>
                  {dep?.label} -&gt;
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        children
      )}
    </div>
  )
}

export default PortfolioRequiredSteps

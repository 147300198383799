import React from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilter } from '../../../../views/components'
import CommunicationsProvider from '../../CommunicationsProvider'

const EmailLogSearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([CommunicationsProvider], ({ location, redirect, emailFilters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters: emailFilters,
}))(EmailLogSearchFilter)

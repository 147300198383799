import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/images/logo.... Remove this comment to see the full error message
import logo from '../../../../theme/images/logo.svg'
import { Card, Layout, LayoutHeader, LayoutBody, LoginForm } from '../../../components'
import styles from './LoginPage.module.scss'

const propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
  redirect: PropTypes.func,
  passwordReset: PropTypes.bool,
  hasSessionTimedOut: PropTypes.bool,
}

const LoginPage = (props: any) => {
  const from = get(props, 'location.state.from', '/dashboard')
  return props.isAuthenticated ? (
    <Redirect to={from} />
  ) : (
    <div className={styles.root}>
      <Helmet>
        <title>reOS | Login</title>
      </Helmet>
      <Layout>
        <LayoutHeader>
          <div className={styles.header}>
            <img width="96px" src={logo} alt="reOS" />
          </div>
        </LayoutHeader>
        <LayoutBody>
          <div className={styles['form-container']}>
            <div style={{ width: '360px' }}>
              {props.hasSessionTimedOut && (
                <p style={{ textAlign: 'center' }}>Your session timed out, please login to continue.</p>
              )}
              <Card on style={{ width: '360px' }}>
                <LoginForm passwordReset={props.passwordReset} />
              </Card>
              <p className={styles.footer}>
                <button type="button" onClick={() => props.redirect('/forgot')}>
                  Forgot password?
                </button>
                {/* <button type="button" onClick={() => props.redirect('/user-signup')}>
                  Request access.
                </button> */}
              </p>
            </div>
          </div>
        </LayoutBody>
      </Layout>
    </div>
  )
}

LoginPage.propTypes = propTypes

export default LoginPage

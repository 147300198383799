import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import { TextField, FormField, Grid, VoiceUser, TextArea } from '../../../../components'
import styles from './PortfolioDeclineForm.module.scss'

const propTypes = {
  handleSubmit: PropTypes.func,
}

const defaultProps = {}

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.handleSubmit(values)
  },
})
class PortfolioDeclineForm extends Component {
  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      values,
      // errors,
      // touched,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleChange' does not exist on type 'Re... Remove this comment to see the full error message
      handleChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleBlur' does not exist on type 'Read... Remove this comment to see the full error message
      handleBlur,
    } = this.props

    return (
      <div className={styles.root}>
        <form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Column xs={12} md={9}>
              <FormField>
                <TextField
                  inputComponent={
                    <TextArea
                      name="reason"
                      placeholder="e.g. The rental amount is incorrect."
                      value={values.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Reason for Declining"
                />
              </FormField>
            </Grid.Column>
          </Grid>

          <div className={styles.footer}>
            <VoiceUser type="submit">Decline</VoiceUser>
          </div>
        </form>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PortfolioDeclineForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PortfolioDeclineForm.defaultProps = defaultProps

export default PortfolioDeclineForm

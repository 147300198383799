import React from 'react'

const AddOn = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fillRule="evenodd"
      d="M22 15h-5v-5c0-1.323-2-1.323-2 0v5h-5c-1.323 0-1.323 2 0 2h5v5c0 1.323 2 1.323 2 0v-5h5c1.323 0 1.323-2 0-2z"
      opacity={0.8}
    />
  </svg>
)

export default AddOn

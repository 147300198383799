/**
 * Party benficiary utils
 *
 * @namespace partyBeneficiary
 */
export const partyBeneficiary = {
  type: 'PartyBeneficiary',

  /**
   * Create an Party beneficiary
   *
   * @memberof partyBeneficiary
   * @method create Creates an Party beneficiary object
   * @param {Object} values values
   * @param {string} values.partyId Party ID
   * @param {string} values.partyTag Party tag
   * @param {string} values.reference Payment reference
   * @param {number} values.amount amount to allocate
   * @param {boolean} values.vat Vatable
   */
  create: ({ partyId, partyTag, reference, amount = 0, vat = false }: any) => ({
    beneficiary: {
      type: 'PartyBeneficiary',
      value: { partyId, partyTag, reference, amount, vat },
    },
  }),
}

/**
 * EasyPay benficiary utils
 *
 * @namespace easyPayBeneficiary
 */
export const easyPayBeneficiary = {
  type: 'EasyPayBeneficiary',

  /**
   * Create an EasyPay beneficiary
   *
   * @memberof easyPayBeneficiary
   * @method create Creates an EasyPay beneficiary object
   * @param {Object} values values
   * @param {string} values.id beneficiary ID
   * @param {string} values.easyPayReference EasyPay reference
   * @param {number} values.amount amount to allocate
   * @param {boolean} values.vat Vatable
   */
  create: ({ id = null, easyPayReference, beneficiaryTag, amount = 0, vat = false }: any) => ({
    beneficiary: {
      type: 'EasyPayBeneficiary',
      value: { id, easyPayReference, beneficiaryTag, amount, vat },
    },
  }),
}

/**
 * Deposit benficiary utils
 *
 * @namespace depositBeneficiary
 */
export const depositBeneficiary = {
  type: 'DepositBeneficiary',

  /**
   * Create a deposit beneficiary
   *
   * @memberof depositBeneficiary
   * @method create Creates a DepositBeneficiary object
   * @param {Object} values values
   * @param {string} values.id beneficiary ID
   * @param {string} values.partyId Party ID
   * @param {string} values.accountId party account ID
   * @param {string} values.reference deposit payment reference
   * @param {string} values.beneficiaryTag benficiary tag
   * @param {number} values.amount amount to allocate
   * @param {boolean} values.vat Vatable
   * @param {boolean} values.transfer Transfer to managed tenant deposit account
   */
  create: ({
    id = null,
    partyId = null,
    accountId = null,
    reference,
    beneficiaryTag,
    amount = 0,
    vat = false,
    transfer = true,
  }: any) => ({
    beneficiary: {
      type: 'DepositBeneficiary',
      value: { id, partyId, accountId, reference, beneficiaryTag, amount, vat, transfer },
    },
  }),
}

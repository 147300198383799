import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TextField.module.scss'
import { ErrorPosition, ITextField } from 'components/atoms/TextField/text-field.types'

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.bool]),
  noOutline: PropTypes.bool,
  /** @todo refactor with theme context */
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  dark: PropTypes.boolean,
  inputComponent: PropTypes.node,
})

const defaultProps: ITextField = {
  name: '',
  id: '',
  dark: false,
  errorPosition: ErrorPosition.top,
}

class TextField extends Component<ITextField> {
  constructor(props: ITextField) {
    super(props)
  }

  render(): null | React.ReactElement {
    const { label, disabled, name, className, error, noOutline, dark, errorPosition, inputComponent } = this.props
    const id = this.props.id ?? name

    const classes = classNames([styles.root, className], {
      [styles['is-disabled']]: !!disabled,
      [styles['has-error']]: error,
      [styles['no-outline']]: !!noOutline,
      [styles.dark]: dark,
    })

    return (
      <div className={classes}>
        {label && (
          <label htmlFor={id}>
            {label}
            {error && errorPosition === 'top' && ` - ${error as string}`}
          </label>
        )}

        {!label && error && errorPosition === 'top' && <label>{error}</label>}

        {inputComponent}

        {error && errorPosition === ErrorPosition.bottom && <label>{error}</label>}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
TextField.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
TextField.defaultProps = defaultProps

export default TextField

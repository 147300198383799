import React from 'react'

const OptionsOff = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g opacity={0.5} fill="none" fillRule="evenodd">
      <path d="M7 7h18v18H7z" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M16 12.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
      />
    </g>
  </svg>
)

export default OptionsOff

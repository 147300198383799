import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { $TSFixMe } from 'types/ts-migrate'
import Button from 'views/components/atoms/Button/Button'
import Modal from 'views/components/molecules/Modal/Modal'

type Props = {
  when: boolean
  isValid?: boolean
  onSave: () => void
  onDiscard: () => void
}

const RouterPromptModal: FC<Props> = props => {
  const { when, isValid = true, onSave, onDiscard } = props

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname)
        setShowPrompt(true)
        return 'true'
      })
    } else {
      history.block(() => {})
    }

    return () => {
      history.block(() => {})
    }
  }, [history, when])

  const handleSave = useCallback(async () => {
    if (onSave) {
      const canRoute: $TSFixMe = await Promise.resolve(onSave())
      if (canRoute) {
        history.block(() => {})
        history.push(currentPath)
      }
    }
  }, [currentPath, history, onSave])

  const handleDiscard = useCallback(async () => {
    if (onDiscard) {
      const canRoute: $TSFixMe = await Promise.resolve(onDiscard())
      if (canRoute) {
        history.block(() => {})
        history.push(currentPath)
      }
    }
    setShowPrompt(false)
  }, [currentPath, history, onDiscard])

  return showPrompt ? (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Modal showClose={false} isOpen={showPrompt}>
      <h3 className="Modal_Title">Review changes</h3>
      <div className="Modal_Content">
        {isValid ? (
          <p>Before you go, would you like to save or discard the changes you made?</p>
        ) : (
          <p>
            Before you go, you have invalid changes that can't be saved. Would you like to discard the changes you made?
          </p>
        )}
      </div>
      <div className="Modal_Actions">
        {isValid ? (
          <Button link onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button link onClick={() => setShowPrompt(false)}>
            Cancel
          </Button>
        )}
        <Button link onClick={handleDiscard}>
          Discard
        </Button>
      </div>
    </Modal>
  ) : null
}

export default RouterPromptModal

import { morphism } from 'morphism'
import { $TSFixMe } from 'types/ts-migrate'
import { currencyUtils } from '../../../utils'
const { toCents } = currencyUtils

const transformStatsSchema = {
  commissionLast30Days: ({ commissionLast30Days }: any) => ({
    ...commissionLast30Days,
    total: toCents(commissionLast30Days.total).getAmount(),
  }),
  fundsCollectedInLast30Days: ({ fundsCollectedInLast30Days }: any) => ({
    ...fundsCollectedInLast30Days,
    total: toCents(fundsCollectedInLast30Days.total).getAmount(),
  }),
}

export const transformStats = (source: any): $TSFixMe => morphism(transformStatsSchema, source)

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Updates.module.scss'

const updateShape = PropTypes.shape({
  count: PropTypes.number,
  color: PropTypes.oneOf(['blue', 'green', 'yellow', 'orange', 'red']),
  title: PropTypes.string,
  onClick: PropTypes.func,
})

const propTypes = {
  invoiceUpdates: PropTypes.arrayOf(updateShape),
  paymentUpdates: PropTypes.arrayOf(updateShape),
}

const Update = ({ handleClick, update, index }: any) => (
  <li key={index}>
    <button onClick={handleClick}>
      <span className={`${styles.count} ${styles[update.color]}`}>{update.count}</span>
      {update.title}
    </button>
  </li>
)

const UpdatesSection = ({ title, updates }: any) =>
  updates.length > 0 && (
    <div className={styles.root}>
      <h2>{title}</h2>
      <ul>
        {updates.map((u: any, i: any) => {
          const handleClick = (e: any): void => {
            e.preventDefault()
            u.onClick()
          }
          return <Update key={i} update={u} index={i} handleClick={handleClick} />
        })}
      </ul>
    </div>
  )

const Updates = ({ invoiceUpdates = [], paymentUpdates = [] }) => (
  <>
    {/* @ts-expect-error ts-migrate(2786) FIXME: 'UpdatesSection' cannot be used as a JSX component... Remove this comment to see the full error message */}
    <UpdatesSection title="Draft Invoices" updates={invoiceUpdates} />
    {/* @ts-expect-error ts-migrate(2786) FIXME: 'UpdatesSection' cannot be used as a JSX component... Remove this comment to see the full error message */}
    <UpdatesSection title="Active Invoices" updates={paymentUpdates} />
  </>
)

Updates.propTypes = propTypes

export default Updates

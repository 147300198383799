import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { get } from 'lodash-es'
import { Tooltip } from '../../..'
import styles from './Navigation.module.scss'

const navItemPropType = {
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

const propTypes = {
  redirect: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  navSections: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ...navItemPropType,
        basepath: PropTypes.string,
        icon: PropTypes.node,
        subNavItems: PropTypes.arrayOf(
          PropTypes.shape({
            ...navItemPropType,
          }),
        ),
      }),
    ),
  ).isRequired,
}

const Navigation = ({ redirect, pathname, navSections }: any) => {
  const [mouseOverItem, setMouseOverItem] = useState(null)

  return (
    <div className={styles.navigation}>
      <nav>
        {navSections.map((section: any, i: any) => (
          <ul key={i}>
            {section.map((item: any, index: number) => {
              let clickHandler = (): void => {}
              if (item.route) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                clickHandler = (): void => redirect(item.route)
              } else if (typeof item.onClick === 'function') {
                clickHandler = item.onClick
              }
              const classes = cx({
                [styles.open]: pathname.includes(get(item, 'basepath')),
                [styles.active]: !item.subNavItems && item.route === pathname,
                [styles.disabled]: item.disabled,
              })

              const navItem = (
                <li
                  key={index}
                  className={classes}
                  onMouseOver={() => setMouseOverItem(item.text)}
                  onMouseLeave={() => setMouseOverItem(null)}
                >
                  <button onClick={item.disabled ? () => {} : clickHandler}>
                    {item.icon}
                    {item.text}
                  </button>
                  {item.subNavItems && item.subNavItems.length > 0 && (
                    <ul>
                      {item.subNavItems.map((subNavItem: any, sni: any) => (
                        <li key={sni}>
                          <button
                            onClick={() => !subNavItem.disabled && redirect(subNavItem.route)}
                            className={subNavItem.route === pathname ? styles.active : undefined}
                          >
                            {subNavItem.text}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )

              return !item.disabled ? (
                navItem
              ) : (
                <Tooltip
                  place="right"
                  body="Unavailable until you add your business details."
                  isOpen={mouseOverItem === item.text}
                >
                  {navItem}
                </Tooltip>
              )
            })}
          </ul>
        ))}
      </nav>
    </div>
  )
}

Navigation.propTypes = propTypes

export default Navigation

import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { pathOr } from 'ramda'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { uiEvents } from '../../../ui'
import { RootState } from 'src/state/store'

const BankStatement = (): React.ReactElement => {
  const dispatch = useDispatch()

  const fetchBankStatement = useCallback(
    (startDate, endDate, json = true) => {
      const event = json ? accountingApiEvents.bankStatementJson_request : accountingApiEvents.bankStatementPdf_request
      dispatch(
        event({
          start_date: startDate,
          end_date: endDate,
        }),
      )
    },
    [dispatch],
  )

  const onClose = useCallback(() => dispatch(uiEvents.redirect('/dashboard')), [dispatch])

  const fetchBankStatementPdf = useCallback(
    (startDate, endDate) => fetchBankStatement(startDate, endDate, false),
    [fetchBankStatement],
  )

  const statementData = useSelector((state: RootState) => accountingApiSelectors.getBankStatementJson(state))
  const statementPdf = useSelector((state: RootState) => accountingApiSelectors.getBankStatementPdf(state))
  const pdfFileName: string = `${pathOr('', ['context', 'agency', 'name'], statementData) as string} Bank Statement`

  return useMemo(() => {
    return (
      <ViewStatementProvider
        isOpen
        onClose={onClose}
        onParamChange={fetchBankStatement}
        statementData={statementData}
        downloadStatement={fetchBankStatementPdf}
        statementPdf={statementPdf}
        pdfFileName={pdfFileName}
      >
        <ViewStatement title="Transactional Account Statement" />
      </ViewStatementProvider>
    )
  }, [onClose, fetchBankStatement, fetchBankStatementPdf, statementData, statementPdf, pdfFileName])
}

export default BankStatement

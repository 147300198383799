import createSelector from 'selectorator'
import { identity, path } from 'ramda'
import { NAMESPACE } from './constants'
import { $TSFixMe } from 'types/ts-migrate'

/**
 * Get a slice of the banking API state
 * EG: getSlice('byId')
 *
 * @param {string} key Banking API state object key
 */
export const getSlice = (key: string): $TSFixMe => createSelector([`api.${NAMESPACE}.${key}`], identity)

/**
 * Get a banking party object by ID
 *
 * @returns {object|undefined}
 */
export const getBankingPartyById = createSelector(
  [getSlice('byId')],
  bankingParties =>
    (id: any): $TSFixMe =>
      path([id], bankingParties),
)

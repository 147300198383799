import { push } from 'connected-react-router'

const reduxHistoryPushMiddleware = ({ metaKey = 'pushToRoute', matcher }: any = {}) => {
  let reMatcher: any
  if (matcher && typeof matcher === 'string') {
    reMatcher = new RegExp(matcher)
  }

  return (store: any) => (next: any) => (action: any) => {
    const hasMetaKey = action?.meta?.[metaKey] && typeof action.meta[metaKey] === 'string'

    if (reMatcher?.test(action.type) && hasMetaKey) {
      store.dispatch(push(action.meta[metaKey]))
      return next(action)
    } else if (hasMetaKey) {
      // history.push(action.meta[metaKey])
      store.dispatch(push(action.meta[metaKey]))
      return next(action)
    }

    return next(action)
  }
}

export default reduxHistoryPushMiddleware

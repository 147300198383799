import { leaseTypeSelectionDialog } from './Dialogs/LeaseTypeSelection'
import { propertyDetailsDialog } from './Dialogs/PropertyDetails'
import { ownerDetailsDialog } from './Dialogs/OwnerDetails'
import { tenantDetailsDialog } from './Dialogs/TenantDetails'
import { leaseTermDialog } from './Dialogs/LeaseTerm'
import { leaseRentAndFeesDialog } from './Dialogs/LeaseRentAndFees'
import { leaseSettingsDialog } from './Dialogs/LeaseSettings'
import { leaseCommissionDialog } from './Dialogs/LeaseCommission'
import { leaseConfirmationDialog } from './Dialogs/LeaseConfirmation'
import { leaseInvoicesDialog } from './Dialogs/LeaseInvoices'
import { userDetailsDialog } from './Dialogs/UserDetails'
import { agencyDetailsDialog } from './Dialogs/AgencyDetails'

export const chatbotModel = {
  portfolio: {
    currentPortfolioId: null,
    savedDialogs: {},
    activationConfirmationOpen: false,
  },
  dialogs: {
    leaseTypeSelection: leaseTypeSelectionDialog.model,
    propertyDetails: propertyDetailsDialog.model,
    ownerDetails: ownerDetailsDialog.model,
    tenantDetails: tenantDetailsDialog.model,
    leaseSettings: leaseSettingsDialog.model,
    leaseTerm: leaseTermDialog.model,
    leaseRentAndFees: leaseRentAndFeesDialog.model,
    leaseCommission: leaseCommissionDialog?.model,
    leaseConfirmation: leaseConfirmationDialog.model,
    leaseInvoices: leaseInvoicesDialog.model,
    userDetails: userDetailsDialog.model,
    agencyDetails: agencyDetailsDialog.model,
  },
}

import { createSelector } from 'reselect'
import { getLeaseRentAndFeesDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'
import { chatbotEvents } from '../state'
import { get } from 'lodash-es'
import { portfolioApiEvents } from '../../../api/portfolio'
import { $TSFixMe } from 'types/ts-migrate'

const initialState = {
  keyDepositAmount: '',
  serviceDepositAmount: '',
  damageDepositAmount: '',
  monthlyRentAmount: '',
  leaseFee: {
    netAmount: '',
    vatable: false,
  },
  applicationFee: {
    netAmount: '',
    vatable: false,
  },
  firstMonthRentAmount: '',
}

export const { leaseRentAndFeesDialog } = createChatbotDialog('leaseRentAndFees')
  .withInitialState(initialState)
  .withEvents({
    rentAndFeesSaved: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [chatbotEvents.setGlobalVat]: ({ payload }: any) => ({
      $merge: {
        leaseFee: {
          vatable: payload,
        },
        applicationFee: {
          vatable: payload,
        },
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendRentAndFees_success]: () => ({ $merge: {} }),
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any, state: $TSFixMe) => {
      const container = get(payload, 'contractContainer') || payload
      const terms = get(container, 'value.terms', false)

      return {
        $merge: {
          keyDepositAmount: get(terms, 'keyDepositAmount', ''),
          serviceDepositAmount: get(terms, 'serviceDepositAmount', ''),
          damageDepositAmount: get(terms, 'damageDepositAmount', ''),
          monthlyRentAmount: get(terms, 'monthlyRentAmount', ''),
          leaseFee: {
            netAmount: get(terms, 'leaseFee.netAmount', ''),
            vatable: get(terms, 'leaseFee.vatable', state?.leaseFee?.vatable || false),
          },
          applicationFee: {
            netAmount: get(terms, 'applicationFee.netAmount', ''),
            vatable: get(terms, 'applicationFee.vatable', state?.applicationFee?.vatable || false),
          },
          firstMonthRentAmount: get(terms, 'firstMonthRentAmount', ''),
          depositHeldByLandlord: get(terms, 'depositHeldByLandlord', false),
        },
      }
    },
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: (action: any, state: any) => ({
      $merge: {
        ...initialState,
        leaseFee: {
          ...initialState.leaseFee,
          vatable: state?.leaseFee?.vatable || initialState.leaseFee.vatable,
        },
        applicationFee: {
          ...initialState.applicationFee,
          vatable: state?.applicationFee?.vatable || initialState.applicationFee.vatable,
        },
      },
    }),
  })
  .when('rentAndFeesSaved', ({ payload }: any) => ({
    $merge: {
      ...payload,
    },
  }))
  .setup()

export const getRentAmount = createSelector(getLeaseRentAndFeesDialog, dialog => get(dialog, 'monthlyRentAmount', 0))
export const getDamageDepositAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'damageDepositAmount', 0),
)
export const getServiceDepositAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'serviceDepositAmount', 0),
)
export const getKeyDepositAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'keyDepositAmount', 0),
)
export const getFirstMonthRentAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'firstMonthRentAmount', 0),
)
export const getLeaseFeeAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'leaseFee.netAmount', 0),
)
export const getApplicationFeeAmount = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'applicationFee.netAmount', 0),
)
export const getLeaseFeeVatEnabled = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'leaseFee.vatable', false),
)
export const getApplicationFeeVatEnabled = createSelector(getLeaseRentAndFeesDialog, dialog =>
  get(dialog, 'applicationFee.vatable', false),
)

import { createChatbotDialog } from './ChatbotDialog'
import { createSelector } from 'reselect'
import { getLeaseTypeSelectionDialog } from '../selectors'
import { pathOr } from 'ramda'
import { chatbotEvents } from '../state'
import { leaseTypes } from '../../../api/portfolio/constants'

const initialState = {
  leaseType: leaseTypes.managed,
}

export const { leaseTypeSelectionDialog } = createChatbotDialog('leaseTypeSelection')
  .withInitialState(initialState)
  .withEvents({
    managedLeaseSelected: () => ({}),
    unmanagedLeaseSelected: () => ({}),
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: {
        leaseType: pathOr(leaseTypes.managed, ['tags', 'leaseType'], payload),
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when('managedLeaseSelected', (_: any) => ({
    $merge: {
      leaseType: leaseTypes.managed,
    },
  }))
  .when('unmanagedLeaseSelected', (_: any) => ({
    $merge: {
      leaseType: leaseTypes.unmanaged,
    },
  }))
  .setup()

export const getCurrentLeaseType = createSelector(getLeaseTypeSelectionDialog, dialog => dialog.leaseType)

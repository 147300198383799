import { createSlice } from '@reduxjs/toolkit'
import { restfulErrorEvent } from '../../../utils/restful'
import { invoiceApiEvents } from '../../api/invoice'
import { reconApiEvents } from '../../api/recon'
import { NAMESPACE } from '../constants'

const bulkImportInvoicesSlice = createSlice({
  name: NAMESPACE,
  initialState: {
    invoices: [],
    importing: [],
    imported: [],
    failed: [],
    hasSavedInvoices: false,
  },
  reducers: {
    bulkCreateInvoices: (state, { payload }) => {},
    updateBulkInvoice: {
      reducer: (state, { payload, meta: { invoiceViewId } }) => {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
        state.invoices = state.invoices.map(invoice => (invoice.id === invoiceViewId ? payload : invoice))
      },
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(payload: any, meta: any) => { payload: any;... Remove this comment to see the full error message
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [invoiceApiEvents.fetchBulkInvoices_success]: (state, { payload }) => {
      state.invoices = payload.invoices
      if (payload.invoices.length > 0) {
        state.hasSavedInvoices = true
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [invoiceApiEvents.deleteBulkInvoices_request]: (state, { payload }) => {
      state.invoices = []
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [reconApiEvents.bulkInvoiceUpload_success]: (state, { payload }) => {
      state.invoices = payload.invoices
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [invoiceApiEvents.createOnceOffInvoice_request]: (state, { payload, meta }) => {
      if (meta?.id !== undefined) {
        state.importing = state.importing.concat([meta.id])
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [invoiceApiEvents.createOnceOffInvoice_success]: (state, { payload, meta }) => {
      /** @todo remove invoice from state */
      if (meta?.id !== undefined) {
        state.invoices = state.invoices.filter(invoice => invoice.id !== meta.id)
        state.importing = state.importing.filter((id: string) => id !== meta.id)
        state.imported = state.imported.concat([meta.id])
      }
    },
    // @ts-expect-error
    [restfulErrorEvent]: (state, { payload, meta }) => {
      if (
        meta?.initAction?.type === invoiceApiEvents.createOnceOffInvoice_request.toString() &&
        meta?.initAction?.meta?.id
      ) {
        state.importing = state.importing.filter(id => id !== meta.initAction.meta.id)
        state.failed = state.failed.concat([meta.initAction.meta.id])
      }
    },
  },
})

const { reducer, actions: events } = bulkImportInvoicesSlice

export { events, reducer }

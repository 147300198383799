import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import ContextMenu from '../../../molecules/ContextMenu/ContextMenu'
import styles from './InvoiceCard.module.scss'

const propTypes = {
  invoice: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onContextMenuItemSelect: PropTypes.func,
  contextMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isHidden: PropTypes.bool,
    }),
  ),
  headerBefore: PropTypes.node,
  children: PropTypes.node,
}

const InvoiceCard = ({ invoice, onClose, contextMenuOptions, headerBefore, children }: any) => {
  const invoiceNumber = get(invoice, 'number', get(invoice, 'invoiceNumber', ''))
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.title}>
          {headerBefore && <div>{headerBefore}</div>}
          <h3>{invoiceNumber}</h3>
        </div>
        <div className={styles.controls}>
          <button className={styles.close} onClick={onClose}>
            Close
          </button>
          {contextMenuOptions && (
            <div className={styles.menu}>
              <ContextMenu items={contextMenuOptions} position="left" showInput={false} />
            </div>
          )}
        </div>
      </header>
      <div>{children}</div>
    </div>
  )
}

InvoiceCard.propTypes = propTypes

export default InvoiceCard

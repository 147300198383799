import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SvgChevronUp from '../../../atoms/Icons/Arrows/ChevronUp'
import SvgChevronDown from '../../../atoms/Icons/Arrows/ChevronDown'
import styles from './SortButton.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([false, 'asc', 'desc']),
}

const SortButton = ({ onClick, order }: $TSFixMe) => {
  const classes = classNames(styles.root, {
    [styles.active]: order !== false,
  })

  return (
    <div className={classes}>
      <button onClick={onClick}>
        {(!order || order === 'asc') && <SvgChevronDown />}
        {order === 'desc' && <SvgChevronUp />}
      </button>
    </div>
  )
}
SortButton.propTypes = propTypes
export default SortButton

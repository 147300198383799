import React, { useEffect, useState } from 'react'
import styles from './EditReference.module.scss'
import EditIcon from '../../../../atoms/Svgs/Edit'
import CheckIcon from '../../../../atoms/Icons/Controls/Check'
import ControlledTooltip from '../../../../atoms/Tooltip/ControlledTooltip'
import { isEmpty, trim } from 'ramda'

const EditReference = (props: any) => {
  const { reference, onReferenceUpdate } = props

  const [editingReference, setEditingReference] = useState(false)
  const [updatedReference, setUpdatedReference] = useState(null)
  const [allowSave, setAllowSave] = useState(true)

  useEffect(() => {
    setUpdatedReference(reference)
  }, [reference])

  const saveUpdatedReference = () => {
    setEditingReference(!editingReference)
    onReferenceUpdate(updatedReference)
  }

  const onUpdatedReferenceChange = (e: any) => {
    const { value } = e.target
    isEmpty(trim(value)) ? setAllowSave(false) : setAllowSave(true)
    setUpdatedReference(value)
  }

  return (
    <span className={styles['amount-text']}>
      {editingReference ? (
        <input
          className={styles['inline-input']}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
          value={updatedReference}
          onChange={e => onUpdatedReferenceChange(e)}
        />
      ) : (
        <span>{reference}</span>
      )}
      {editingReference ? (
        allowSave ? (
          <CheckIcon className={styles['edit-button']} width={20} height={20} onClick={() => saveUpdatedReference()} />
        ) : (
          <CheckIcon className={styles['edit-button-disabled']} width={20} height={20} />
        )
      ) : (
        // @ts-expect-error
        <ControlledTooltip body="Edit reference">
          <EditIcon
            className={styles['edit-button']}
            width={20}
            height={20}
            onClick={() => setEditingReference(!editingReference)}
          />
        </ControlledTooltip>
      )}
    </span>
  )
}

export default EditReference

import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { superSupportAccessEmails } from 'constants/general'
import { $TSFixMe } from 'types/ts-migrate'
import {
  LayoutProvider,
  Layout,
  LayoutBody,
  // LayoutPrimaryPanel,
  LayoutContent,
} from '../../../../views/components'
import { SupportStaffProvider } from '../../../supportStaff'
import WalletTransferContent from './WalletTransferContent'
import WalletTransferProvider from './WalletTransferProvider'

const WalletTransferPage = ({ path, isAccessAllowed }: $TSFixMe) => {
  return !isAccessAllowed(superSupportAccessEmails) ? (
    <Redirect
      to={{
        pathname: '/dashboard',
        state: { from: path },
      }}
    />
  ) : (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="walletTransfer"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: false, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Admin | Wallet Transfer</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutContent>
            <WalletTransferContent />
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(
  [WalletTransferProvider, SupportStaffProvider],
  ({ match }: $TSFixMe, { isAccessAllowed }: $TSFixMe) => ({
    path: match.path,
    isAccessAllowed,
  }),
)(WalletTransferPage)

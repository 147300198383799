import React, { Component } from 'react'
import classNames from 'classnames'
import styles from './Checkbox.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

type TProps = {
  id?: string
  disabled?: boolean
  label?: string | React.ReactElement
  name: string
  placeholder?: string
  checked?: boolean
  error?: React.ReactNode
  onChange?: (e: $TSFixMe) => void
  onBlur?: (e: $TSFixMe) => void
  className?: string
}

type TState = {
  isFocused: boolean
  checked: boolean
}

class Checkbox extends Component<TProps, TState> {
  constructor(props: any) {
    super(props)
    this.state = {
      isFocused: false,
      checked: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidMount(): void {
    const { checked = false } = this.props
    this.setState({ checked })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any): void {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked })
    }
  }

  handleChange(e: any): void {
    const { checked } = e.target
    const { onChange } = this.props
    this.setState({ checked: !checked })
    if (typeof onChange === 'function') {
      const newEvent = { ...e, checked: checked }
      onChange(newEvent)
    }
  }

  handleFocus(e: any): void {
    this.setState({ isFocused: true })
  }

  handleBlur(e: any): void {
    this.setState({ isFocused: false })
  }

  render(): null | React.ReactElement {
    const { label, name, error, disabled, className } = this.props
    const { isFocused, checked } = this.state
    const id = this.props.id || name

    const classes = classNames([styles.root, className], {
      [styles['is-focused']]: isFocused,
      [styles['is-checked']]: checked,
      [styles['has-error']]: error,
      [styles['is-disabled']]: disabled,
    })

    return (
      <div className={classes}>
        <label htmlFor={id}>
          <input
            id={id}
            disabled={disabled}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <span>
            {label && label}
            {error && ` - ${error as string}`}
          </span>
        </label>
      </div>
    )
  }
}

export default Checkbox

import React from 'react'

const SvgComplexFill = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M2 5h24v24H2z" />
      <path
        d="M11 24h3v-6.058l-4-3.488-4 3.488V24h3v-4h2v4zm12 2H5a1 1 0 01-1-1v-7.513a1 1 0 01.343-.754L8 13.544V9a1 1 0 011-1h14a1 1 0 011 1v16a1 1 0 01-1 1zm-5-10v2h2v-2h-2zm0 4v2h2v-2h-2zm0-8v2h2v-2h-2zm-4 0v2h2v-2h-2z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgComplexFill

import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import {
  PortfolioActivated,
  PortfolioActivation,
  PortfolioActivationForm,
  PortfolioApprovalRequestSent,
  PortfolioCommission,
  PortfolioCommissionForm,
  PortfolioDecline,
  PortfolioDeclined,
  PortfolioDeclineForm,
  PortfolioExitGate,
  PortfolioExitGateForm,
  PortfolioInvoicesForm,
  PortfolioPropertyAddress,
  PortfolioPropertyForm,
  PortfolioPropertyOwner,
  PortfolioRenewal,
  PortfolioRentAndFees,
  PortfolioRentAndFeesForm,
  PortfolioSettings,
  PortfolioTenants,
  PortfolioTerm,
  PortfolioTermForm,
  PortfolioTermination,
  PortfolioApplications,
} from '../../../containers'
import { DimmerLoader } from '../../../../views/components'
import styles from './PortfolioPage.module.scss'
import { subscribe } from 'react-contextual'
import PortfoliosProvider from '../../../../modules/portfolios/PortfoliosProvider'

/** Slowly moving components into the portfolios module */
import {
  ApplicationsTable,
  PartiesTable,
  PortfolioRenewalForm,
  PortfolioSettingsForm,
  PortfolioTerminationForm,
  UnmanagedCommissionForm,
  UnmanagedRentAndFeesForm,
  PortfolioRequiredSteps,
} from '../../../../modules/portfolios/components'
import { $TSFixMe } from 'types/ts-migrate'
import { leaseTypes } from 'modules/api/portfolio/constants'
import { PortfolioWizardStepNames } from 'modules/ui/ChatBot/types'

interface PortfolioPageProps extends $TSFixMe {
  url: string
}

const PortfolioPage = (props: PortfolioPageProps): React.ReactElement => {
  const {
    redirect,
    url,
    portfolioId,
    currentPortfolioId,
    setCurrentPortfolioId,
    isPortfolioViewLoading,
    tenants,
    owners,
    removeLandlordFromLease,
    removeTenantFromLease,
    makeLandlordPrimary,
    makeTenantPrimary,
    addLandlordToLease,
    addTenantToLease,
    portfolioStatus,
    currentLeaseType,
  } = props

  const validateMatched = portfolioId === 'new' || portfolioId.split('-').length === 5

  useEffect(() => {
    if (validateMatched) {
      setCurrentPortfolioId(portfolioId, currentPortfolioId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.root}>
      {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; loading: any; }' is n... Remove this comment to see the full error message */}
      <DimmerLoader loading={isPortfolioViewLoading} bgColor={'transparent'}>
        {/**
         * @todo reintroduce lease type selection. Hard coding ManagedContract for now. See `LeaseTypeSelection.js`
         */}
        <Route
          strict
          exact
          path={`(${url}|${url}/property)`}
          render={() => (
            // @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
            <PortfolioPropertyAddress>
              <PortfolioPropertyForm />
            </PortfolioPropertyAddress>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/property/owner`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.owners}>
              <PortfolioPropertyOwner>
                <PartiesTable
                  portfolioId={portfolioId}
                  partyType="owner"
                  parties={owners}
                  redirect={redirect}
                  removePartyFromLease={removeLandlordFromLease}
                  makePartyPrimary={makeLandlordPrimary}
                  addPartyToLease={addLandlordToLease}
                  portfolioStatus={portfolioStatus}
                />
              </PortfolioPropertyOwner>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/applications`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.applications}>
              <PortfolioApplications>
                <ApplicationsTable
                  portfolioId={portfolioId}
                  tenants={tenants}
                  redirect={redirect}
                  portfolioStatus={portfolioStatus}
                />
              </PortfolioApplications>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/tenants`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.tenants}>
              <PortfolioTenants>
                <PartiesTable
                  portfolioId={portfolioId}
                  partyType="tenant"
                  parties={tenants}
                  redirect={redirect}
                  removePartyFromLease={removeTenantFromLease}
                  makePartyPrimary={makeTenantPrimary}
                  addPartyToLease={addTenantToLease}
                  portfolioStatus={portfolioStatus}
                />
              </PortfolioTenants>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/term`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.lease}>
              <PortfolioTerm>
                <PortfolioTermForm />
              </PortfolioTerm>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/rent-and-fees`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.rent}>
              <PortfolioRentAndFees>
                {currentLeaseType === leaseTypes.managed ? <PortfolioRentAndFeesForm /> : <UnmanagedRentAndFeesForm />}
              </PortfolioRentAndFees>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/settings`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.settings}>
              <PortfolioSettings>
                <PortfolioSettingsForm />
              </PortfolioSettings>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/commission`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.commission}>
              <PortfolioCommission>
                {currentLeaseType === leaseTypes.managed ? <PortfolioCommissionForm /> : <UnmanagedCommissionForm />}
              </PortfolioCommission>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/invoices`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.invoices}>
              <PortfolioInvoicesForm />
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/activation`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.activation}>
              <PortfolioActivation>
                <PortfolioActivationForm portfolioId={portfolioId} />
              </PortfolioActivation>
            </PortfolioRequiredSteps>
          )}
        />
        <Route strict exact path={`${url}/approval-sent`} render={() => <PortfolioApprovalRequestSent />} />
        <Route strict exact path={`${url}/activated`} render={() => <PortfolioActivated />} />
        <Route
          strict
          exact
          path={`${url}/decline`}
          render={() => (
            <PortfolioDecline>
              <PortfolioDeclineForm />
            </PortfolioDecline>
          )}
        />
        <Route strict exact path={`${url}/declined`} render={() => <PortfolioDeclined />} />
        <Route
          strict
          exact
          path={`${url}/renewal`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.renewal}>
              <PortfolioRenewal>
                <PortfolioRenewalForm portfolioId={portfolioId} />
              </PortfolioRenewal>
            </PortfolioRequiredSteps>
          )}
        />
        <Route
          strict
          exact
          path={`${url}/termination`}
          render={() => (
            <PortfolioRequiredSteps stepName={PortfolioWizardStepNames.termination}>
              <PortfolioTermination>
                <PortfolioTerminationForm />
              </PortfolioTermination>
            </PortfolioRequiredSteps>
          )}
        />
      </DimmerLoader>
    </div>
  )
}

export default subscribe(
  [PortfoliosProvider],
  ({
    redirect,
    isPortfolioViewLoading,
    tenants,
    owners,
    removeLandlordFromLease,
    removeTenantFromLease,
    makeLandlordPrimary,
    makeTenantPrimary,
    addLandlordToLease,
    addTenantToLease,
    portfolioStatus,
    currentLeaseType,
  }: any) => ({
    redirect,
    isPortfolioViewLoading,
    tenants,
    owners,
    removeLandlordFromLease,
    removeTenantFromLease,
    makeLandlordPrimary,
    makeTenantPrimary,
    addLandlordToLease,
    addTenantToLease,
    portfolioStatus,
    currentLeaseType,
  }),
)(PortfolioPage)

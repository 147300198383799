import React from 'react'
import Button from '../../../views/components/atoms/Button/Button'
import { subscribe } from 'react-contextual'
import { CreditNoteProvider } from '..'
import { InvoicesProvider } from '../../../views/providers'
import { $TSFixMe } from 'types/ts-migrate'

const CreditNoteButton = ({ openCreditNote, currentInvoice, className }: $TSFixMe) => (
  <Button className={className} size="sm" secondary onClick={() => openCreditNote(currentInvoice.id)}>
    Add credit note
  </Button>
)

export default subscribe(
  [CreditNoteProvider, InvoicesProvider],
  ({ openCreditNote }: any, { currentInvoice }: any) => ({
    openCreditNote,
    currentInvoice,
  }),
)(CreditNoteButton)

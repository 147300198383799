import React from 'react'

const SvgPdfAlt = ({ width = 32, height = 32, ...props }: any) => (
  <svg width={width} height={height} {...props}>
    <g fill="none" fillRule="evenodd" stroke="#6C6C72" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M9.246 27.248h-3a1.5 1.5 0 01-1.5-1.5v-19.5a1.5 1.5 0 011.5-1.5h10.629a1.5 1.5 0 011.061.44l5.871 5.871a1.5 1.5 0 01.439 1.061v4.628" />
      <path d="M24.246 12.248h-6a1.5 1.5 0 01-1.5-1.5v-6M12.246 27.248v-7.5M12.246 19.748H13a2.25 2.25 0 110 4.5h-.75m5.996 3a3 3 0 003-3v-1.5a3 3 0 00-3-3v7.5zm6 0v-6a1.5 1.5 0 011.5-1.5h1.5M24.246 24.248h2.25" />
    </g>
  </svg>
)

export default SvgPdfAlt

import React, { FC, ReactChild, useState } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { sumBy } from 'lodash-es'
import { isEmpty, mergeRight, path, pathOr } from 'ramda'
import { format, isValid as isDateValid } from 'date-fns'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { arrayUtils, currencyUtils, formUtils } from '../../../../utils'
import { PartySearch } from '../../../../views/containers'
import { Button, DatePicker, FormField, FormLoader, SaveChangesModal, Segment } from '../../../../views/components'
import CalendarOff from '../../../../views/components/atoms/Svgs/CalendarOff'
import AdhocInvoiceProvider from '../../AdhocInvoiceProvider'
import { apiStateSelectors } from '../../../api/apiState'
import { invoiceApiEvents } from '../../../api/invoice'
import PaymentAllocation from './PaymentAllocation/PaymentAllocation'
import styles from './AdhocInvoiceForm.module.scss'
import { defaultAgencyInvoiceTypes, depositInvoiceTypes } from 'modules/api/invoice/constants'
import { userApiSelectors } from '../../../api/user'
import { $TSFixMe } from 'types/ts-migrate'
import { portfolioApiEvents, portfolioApiSelectors } from 'modules/api/portfolio'
import {
  transformDepositBeneficiaryForManagedByChange,
  transformDepositBeneficiaryForTransferChange,
} from 'views/components/organisms/FormFragments/FundDistribution/DepositBeneficiary/depositBeneficiaryUtils'
import { agencyApiSelectors } from 'modules/api/agency'
import { invoiceTypes } from 'utils/invoiceTypes'

const propTypes = {
  updateInvoice: PropTypes.func,
  currentAgencyId: PropTypes.string.isRequired,
  fetchInvoiceTypes: PropTypes.func,
  invoiceTypesForSelect: PropTypes.any,
  closeInvoice: PropTypes.func,
  getPartyNameById: PropTypes.func,
  getPartyBankDetails: PropTypes.func,
  initialValues: PropTypes.shape({
    isDepositInvoice: PropTypes.bool,
    customer: PropTypes.shape({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
      id: PropTypes.PropTypes.string,
      text: PropTypes.string,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
      partyTag: PropTypes.PropTypes.string,
      secondaryText: PropTypes.string,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
      portfolioId: PropTypes.PropTypes.string,
    }),
    invoiceType: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    dueDate: PropTypes.string,
    vat: PropTypes.bool,
    amount: PropTypes.string,
    beneficiaries: PropTypes.array /** @todo add shape */,
  }),
  onSubmit: PropTypes.func,
  createInvoice: PropTypes.func,
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  fetchPortfolio: PropTypes.func,
  onDepositBeneficiaryTransferChange: PropTypes.func,
}

const validationSchema = yup.object().shape({
  customer: yup.object().test('customer', function (customer) {
    if (customer.id === null) {
      return this.createError({
        path: this.path,
        message: 'Required',
      })
    }
    return true
  }),
  invoiceType: yup.object().test('invoiceType', function (invoiceType) {
    if (invoiceType.value === null) {
      return this.createError({
        path: this.path,
        message: 'Required',
      })
    }
    return true
  }),
  amount: yup.number().required('Required'),
  beneficiaries: yup
    .array(
      yup.object().shape({
        amount: yup.number(),
        balance: yup.number(),
        beneficiaryCategory: yup.string(),
        id: yup.string(),
        partyId: yup.string(),
        partyTag: yup.string(),
        reference: yup.string(),
        vat: yup.boolean(),
      }),
    )
    .test('beneficiaries', function (beneficiaries) {
      const beneficiariesTotal = sumBy(beneficiaries, (b: any) => b.beneficiary.value.amount)
      if (beneficiariesTotal > this.parent.amount) {
        return this.createError({
          path: this.path,
          message: `Beneficiary payments can't exceed invoice total of ${currencyUtils.formatCurrency(
            this.parent.amount,
          )}`,
        })
      }
      return true
    }),
})

const ButtonContainer: FC<{ beforeButton: ReactChild }> = ({ beforeButton, children }) => (
  <footer>
    <div className={styles['form-footer']}>
      {beforeButton}
      <div className={styles['action-button']}>{children}</div>
    </div>
  </footer>
)

const transformFormValues = (values: any, sendAfterCreate = true) => {
  const payload = {
    ...values,
    amount: parseFloat(values.amount),
    customer: values.customer.id,
    customerTag: values.customer.partyTag,
    invoiceType: values.invoiceType.value,
    beneficiaries: values.beneficiaries.map((b: any) => ({
      ...b,
      amount: parseFloat(b.amount),
    })),
  }
  if (path(['customer', 'portfolioId'], values)) {
    payload.portfolioId = values.customer.portfolioId
  }
  return {
    values: payload,
    sendAfterCreate,
  }
}

const AdhocInvoiceForm = ({
  invoiceTypesForSelect,
  getPartyNameById,
  partyTags,
  initialValues,
  onSubmit,
  createInvoice,
  closeInvoice,
  fetchInvoiceTypes,
  updateInvoice,
  currentAgencyId,
  isSubmitting,
}: any) => {
  useEffectOnce(() => {
    fetchInvoiceTypes()
  })

  const dispatch = useDispatch()

  const [savePromptOpen, setSavePromptOpen] = useState(false)
  const [beneficiariesInState, setBeneficiariesInState] = useState(initialValues.beneficiaries)

  const createInvoiceEvent = invoiceApiEvents.createOnceOffInvoice_request
  const generalErrors = useSelector(state => apiStateSelectors.getGeneralFormErrorsByEvent(state)(createInvoiceEvent))
  const fieldErrors = useSelector(state => apiStateSelectors.getFormFieldErrorsByEvent(state)(createInvoiceEvent))
  const isReadonly = useSelector(userApiSelectors.isReadOnlyRole)
  const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

  const getPrimaryLandlordByPortfolioId = useSelector(portfolioApiSelectors.getPrimaryLandlordByPortfolioId)
  const getCurrentAgencyGlobalVatEnabled = useSelector(agencyApiSelectors.getCurrentAgencyGlobalVatEnabled)
  const currentAgencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const [isDefaultAgencyPaymentRule, setIsDefaultAgencyPaymentRule] = useState(false)

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        const payload = transformFormValues(values)
        onSubmit(payload)
        resetForm()
      }}
    >
      {({ handleSubmit, values, setFieldValue, setFieldTouched, errors, resetForm, submitCount, touched, isValid }) => {
        const handleSaveConfirmation = (): void => {
          const { id, ...payload } = values
          updateInvoice(id, payload)
          setSavePromptOpen(false)
          closeInvoice('drafts')
        }

        const handleSave = (values: any) => {
          createInvoice(values)
          closeInvoice('drafts')
        }

        const handleDiscardConfirmation = (): void => {
          resetForm()
          setSavePromptOpen(false)
          closeInvoice('drafts')
        }

        const handleNewBeneficiary = (beneficiary: any, index: number): void => {
          const vatApplied =
            beneficiary.beneficiary.value.partyTag === 'Agency' && getCurrentAgencyGlobalVatEnabled ? true : false
          setFieldValue(
            'beneficiaries',
            arrayUtils.insert(
              index,
              {
                ...beneficiary,
                beneficiary: {
                  ...beneficiary.beneficiary,
                  value: {
                    ...beneficiary.beneficiary.value,
                    vat: vatApplied,
                  },
                },
              },
              values.beneficiaries,
            ),
          )
          setBeneficiariesInState(arrayUtils.insert(index, beneficiary, beneficiariesInState))
        }

        const handleBeneficiaryRemoved = (index: number): void => {
          setFieldValue('beneficiaries', arrayUtils.removeByIndex(index, values.beneficiaries))
        }

        const handleBeneficiaryAmountChange = (beneficiaryIndex: number, e: any): void => {
          const beneficiary =
            values.beneficiaries && values.beneficiaries.find((v: any, i: any) => i === beneficiaryIndex)
          setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
            ...beneficiary,
            beneficiary: {
              ...beneficiary.beneficiary,
              value: {
                ...beneficiary.beneficiary.value,
                amount: e.target.value,
              },
            },
          })
        }

        const handleBeneficiaryReferenceChange = (beneficiaryIndex: number, reference: any, type: any): void => {
          const beneficiary = values.beneficiaries.find((v: any, i: any) => i === beneficiaryIndex)
          setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
            ...beneficiary,
            beneficiary: {
              ...beneficiary.beneficiary,
              value: {
                ...beneficiary.beneficiary.value,
                reference,
              },
            },
          })
        }

        const handleBeneficiaryOrderChange = (beneficiaries: any): void => {
          setFieldValue('beneficiaries', beneficiaries)
        }

        const handleInvoiceTypeChange = (data: any): void => {
          setFieldValue('invoiceType', data)
          // @todo remove this hack
          setTimeout(() => setFieldTouched('invoiceType', true))

          if (depositInvoiceTypes.includes(data.value)) {
            const { customer } = values
            const partyName = getPartyNameById(customer.id)
            setFieldValue('isDepositInvoice', true)

            const beneficiary = {
              beneficiary: {
                type: 'DepositBeneficiary',
                value: {
                  amount: values.amount,
                  partyId: customer?.id,
                  beneficiaryTag: 'TenantDepositAccount',
                  reference: `${partyName} ${data.value}`,
                  transfer: true,
                  vat: false,
                },
              },
            }

            setFieldValue('beneficiaries', [beneficiary])
          } else if (defaultAgencyInvoiceTypes.includes(data.value)) {
            setFieldValue('isDepositInvoice', false)
            setIsDefaultAgencyPaymentRule(true)

            const beneficiary = {
              beneficiary: {
                type: 'PartyBeneficiary',
                value: {
                  amount: values.amount,
                  name: currentAgencyName,
                  partyId: currentAgencyId,
                  partyTag: 'Agency',
                  reference: `${data.label}`,
                  vat: getCurrentAgencyGlobalVatEnabled,
                },
              },
            }

            setFieldValue('beneficiaries', [beneficiary])
          } else if (
            values.beneficiaries.some((b: any) => b.beneficiary.partyTag === 'Agency') ||
            !defaultAgencyInvoiceTypes.includes(data.value)
          ) {
            setFieldValue('beneficiaries', [])
          } else {
            if (values.beneficiaries.some((b: any) => b.beneficiary.type === 'DepositBeneficiary')) {
              setFieldValue('beneficiaries', [])
            }
          }

          // Enable VAT if the following conditions are met
          if (invoiceTypes.includes(data.value) && getCurrentAgencyGlobalVatEnabled) {
            setFieldValue('vat', true)
          } else {
            setFieldValue('vat', false)
          }
        }

        const getBeneficiaries = () => pathOr([], ['beneficiaries'], values)

        const handleDepositBeneficiaryTransferChange = (index: number, transfer: any): void => {
          const beneficiary: $TSFixMe = getBeneficiaries().find((v: any, i: any) => i === index)
          const primaryOwnerId =
            getPrimaryLandlordByPortfolioId(values.portfolioId || values.customer?.portfolioId) || undefined
          const partyName = getPartyNameById(values.customer?.id)
          const invoiceTypeName = pathOr('', ['invoiceType', 'label'], values)

          const transformedBeneficiary = transformDepositBeneficiaryForTransferChange(
            beneficiary,
            transfer,
            currentAgencyId,
            partyName,
            invoiceTypeName,
            primaryOwnerId,
          )

          setFieldValue(`beneficiaries[${index}]`, transformedBeneficiary)
        }

        const handleVatChange = (vatApplied: any) => setFieldValue('vat', vatApplied)

        const handleBeneficiaryVatChange = (i: any, vatApplied: any): void => {
          setFieldValue(`beneficiaries[${i}].beneficiary.value.vat`, vatApplied)
        }

        const handleAmountChange = (e: any): void => {
          if (values.isDepositInvoice) {
            handleBeneficiaryAmountChange(0, e)
          }
          if (isDefaultAgencyPaymentRule) {
            handleBeneficiaryAmountChange(0, e)
          }
          setFieldValue('amount', e.target.value)
        }

        const handleDescriptionChange = ({ target }: any) => {
          setFieldValue('description', target.value)
        }

        const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

        const formLoaderState = isSubmitting
          ? 'submitting'
          : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
          ? 'error'
          : undefined

        const errorText = hasApiErrors
          ? generalErrors.length > 0
            ? generalErrors[0]
            : 'Oops, something went wrong'
          : 'Resolve errors'

        return (
          <div className={styles.root}>
            <SaveChangesModal
              isOpen={savePromptOpen}
              onSave={handleSaveConfirmation}
              onDiscard={handleDiscardConfirmation}
            />
            <section className={styles['invoice-data']}>
              <FormLoader
                onSubmit={handleSubmit}
                state={formLoaderState}
                buttonProps={{ children: 'Send invoice' }}
                successText="Invoice submitted successfully"
                errorText={errorText}
                ButtonContainer={({ children }) => (
                  <ButtonContainer
                    beforeButton={
                      <Button
                        disabled={!isValid}
                        size="sm"
                        secondary
                        className={styles['save-button']}
                        onClick={() => handleSave(transformFormValues(values, false))}
                      >
                        Save draft
                      </Button>
                    }
                  >
                    {children}
                  </ButtonContainer>
                )}
                persistErrorMessage={!hasApiErrors}
                isDisabled={isReadonly}
              >
                <FormField>
                  <PartySearch
                    splitByAccount
                    includePortfolioStatus
                    label="Invoice"
                    name="customer"
                    placeholder="Search for a party to invoice..."
                    value={values.customer.text}
                    onResultSelect={(val: any) => {
                      if (val.portfolioId) {
                        dispatch(portfolioApiEvents.portfolio_request(val.portfolioId))
                      }
                      setFieldValue('customer', val)
                    }}
                    // defaultResultSets={defaultResultSets}
                    // onBlur={handleBlur}
                    tags=""
                    error={getFieldError('customer')}
                    focusOnMount
                    disabled={path(['invoiceType', 'value'], initialValues) === 'DepositTopUp' ? true : false}
                  />
                </FormField>

                <PaymentAllocation
                  errors={mergeRight(errors, fieldErrors)}
                  invoiceTypes={invoiceTypesForSelect}
                  invoiceType={values.invoiceType}
                  onInvoiceTypeChange={handleInvoiceTypeChange}
                  amount={values.amount ? values.amount.toString() : ''}
                  onAmountChange={handleAmountChange}
                  beneficiaries={values.beneficiaries.map((b: any) => {
                    const partyId = pathOr('', ['beneficiary', 'value', 'partyId'], b)
                    return {
                      ...b,
                      beneficiary: {
                        ...b.beneficiary,
                        value: {
                          ...b.beneficiary.value,
                          name: getPartyNameById(partyId),
                        },
                      },
                      isDraggable: false,
                    }
                  })}
                  onNewBeneficiaryAdded={handleNewBeneficiary}
                  onBeneficiaryRemoved={handleBeneficiaryRemoved}
                  onNewBeneficiaryAmountChange={handleBeneficiaryAmountChange}
                  onBeneficiariesOrderChange={handleBeneficiaryOrderChange}
                  onBeneficiaryReferenceChange={handleBeneficiaryReferenceChange}
                  partyTags={partyTags}
                  vatApplied={values.vat}
                  onVatChange={handleVatChange}
                  onBeneficiaryVatChange={handleBeneficiaryVatChange}
                  onDepositBeneficiaryTransferChange={handleDepositBeneficiaryTransferChange}
                  onDescriptionChange={handleDescriptionChange}
                  portfolioId={values?.portfolioId || values?.customer?.portfolioId}
                  customerId={values.customer?.id}
                  customerTag={values.customer?.partyTag}
                />

                <Segment.Group>
                  <Segment horizontal>
                    <Segment.Label icon={<CalendarOff />} text="Payment Due" />
                    <div className={styles['due-date']}>
                      <DatePicker
                        date={values.dueDate ? new Date(values.dueDate) : new Date()}
                        onChange={(date: any) =>
                          setFieldValue('dueDate', format(isDateValid(date) ? date : Date.now(), 'yyyy-MM-dd'))
                        }
                      />
                    </div>
                  </Segment>
                </Segment.Group>
              </FormLoader>
            </section>
          </div>
        )
      }}
    </Formik>
  )
}
AdhocInvoiceForm.propTypes = propTypes

export default subscribe(
  [AdhocInvoiceProvider],
  ({
    updateInvoice,
    currentAgencyId,
    fetchInvoiceTypes,
    invoiceTypesForSelect,
    closeInvoice,
    getPartyNameById,
    initialValues,
    onSubmit,
    createInvoice,
    partyTags,
    getPartyAccount,
    isSubmitting,
  }: any) => ({
    updateInvoice,
    currentAgencyId,
    fetchInvoiceTypes,
    invoiceTypesForSelect,
    closeInvoice,
    getPartyNameById,
    initialValues,
    onSubmit,
    createInvoice,
    partyTags,
    getPartyAccount,
    isSubmitting,
  }),
)(AdhocInvoiceForm)

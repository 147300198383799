import { createSelector } from 'reselect'
import { userApiEvents, userApiSelectors } from '../../../api/user'
import { state, getCurrentUserDetailsDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'

export const { userDetailsDialog } = createChatbotDialog('userDetails')
  .withInitialState({
    firstName: '',
    lastName: '',
    idNumber: '',
    passport: '',
    dateOfBirth: '',
    cellNumber: '',
    otpFailed: false,
  })
  .withEvents({
    detailsSubmitted: (payload: any) => payload,
  })
  .reduce({
    [userApiEvents.currentUserDetails_success.toString()]: ({ payload }: any) => ({
      $merge: payload,
    }),
    [userApiEvents.saveDetails_success.toString()]: ({ payload }: any) => ({
      $merge: payload,
    }),
    /**
     * @todo Reimpl this logic
    [statusEvents.restErrorSet]: ({ payload }) => {
      if (payload.type === 'api/user/validate_otp') {
        return {
          $merge: {
            otpFailed: true
          }
        }
      }
    }
    */
  })
  .when('detailsSubmitted', ({ payload }: any) => ({
    $merge: {
      ...payload,
    },
  }))
  .setup()

export const getCurrentUserDetailsInitialValues = createSelector(
  state,
  getCurrentUserDetailsDialog,
  (s, dialogValues) => ({
    ...dialogValues,
    emailAddress: userApiSelectors.getUserEmail(s),
    cellVerified: userApiSelectors.cellVerified(s),
    emailVerified: userApiSelectors.emailVerified(s),
  }),
)

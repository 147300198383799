import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './FormField.module.scss'

type Props = {
  padCheckbox?: boolean
  children: React.ReactNode
}

const FormField: FC<Props> = ({ children, padCheckbox = false, ...props }) => {
  const classes = classNames(styles.root, {
    [styles['pad-checkbox']]: padCheckbox,
  })

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  )
}

export default FormField

import React from 'react'
import PropTypes from 'prop-types'
import styles from './SummaryWidgetGroup.module.scss'

const propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

const SummaryWidgetGroup = ({ children }: any) => {
  return <div className={styles.root}>{children}</div>
}

SummaryWidgetGroup.propTypes = propTypes

export default SummaryWidgetGroup

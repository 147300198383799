import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { DashboardProvider, DashboardPage } from '..'

const DashboardRoute = (props: any) => (
  <DashboardProvider {...props}>
    <SearchFilterProvider location={props.location}>
      <DashboardPage />
    </SearchFilterProvider>
  </DashboardProvider>
)

export default DashboardRoute

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './PortfolioExitGateForm.module.scss'
import { Button } from '../../../../components'

const propTypes = {
  unfinishedSteps: PropTypes.array,
  redirectTo: PropTypes.func,
}

const defaultProps = {}

class PortfolioExitGateForm extends Component {
  render(): null | React.ReactElement {
    return (
      <div className={styles.root}>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'unfinishedSteps' does not exist on type ... Remove this comment to see the full error message */}
        {this.props.unfinishedSteps.map((i: any) => (
          <div key={`step-${i}`}>
            <Button
              name="inputName"
              placeholder="Error"
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirectTo' does not exist on type 'Read... Remove this comment to see the full error message
              onClick={() => this.props.redirectTo(i.route)}
            >
              {i.label} -&gt;
            </Button>
          </div>
        ))}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PortfolioExitGateForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PortfolioExitGateForm.defaultProps = defaultProps

export default PortfolioExitGateForm

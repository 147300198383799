import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'smsApi',
  initialState: {},
  reducers: {
    sendSms_request: (state, { payload }) => {},
    sendSms_success: (state, { payload }) => {},
    getOtp_request: (state, { payload }) => {},
    getOtp_success: (state, { payload }) => {},
  },
})

const { reducer, actions: events } = slice

export { events, reducer }

import React from 'react'

const SvgReportsFill = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path d="M7 16h4v9H7v-9zm14-4h4v13h-4V12zm-7-6h4v19h-4V6z" fillOpacity={0.64} fill="#191923" fillRule="nonzero" />
    </g>
  </svg>
)

export default SvgReportsFill

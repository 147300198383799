import React, { useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'ramda'
import { Routes } from '../../routes'
import './App.module.scss'
import { LayoutProvider } from '../../../modules/Layout'
import { ApiStateProvider } from '../../../modules/api/apiState'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { useEffectOnce } from 'react-use'
import { getJWTRefreshToken, getJWTToken, getMisc } from 'utils/auth'
import { userApiEvents, userApiSelectors } from '../../../modules/api/user'
import { useDispatch, useSelector } from 'react-redux'
import { agencyApiSelectors } from '../../../modules/api/agency'
import { $TSFixMe } from 'types/ts-migrate'

const App = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    const authToken = getJWTToken()
    const refreshToken = getJWTRefreshToken()
    const miscellaneous = getMisc()
    if (authToken && refreshToken) {
      dispatch(userApiEvents.userResumed({ authToken, refreshToken, miscellaneous }))
    }
    /**
     * @todo look into removing this. I think it slows down the app quite significantly.
     */
  })

  const isAuthenticated = useSelector(userApiSelectors.isAuthenticated)
  const userId = useSelector(userApiSelectors.getUserId)
  const userName = useSelector(userApiSelectors.getUserFirstName)
  const userEmail = useSelector(userApiSelectors.getUserEmail)
  const userAgencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const agency: $TSFixMe = useSelector(agencyApiSelectors.getCurrentAgencyDetails)
  const onboardingProgress = useSelector(userApiSelectors.getOnboardingProgress)
  const userRole = useSelector(userApiSelectors.getUserRole)

  // GTM Config
  const gtmParams = {
    id: 'GTM-WQSQ3DF9',
    dataLayer: {
      agency_name: agency?.companyName,
      user_role: userRole,
    },
    dataLayerName: 'userAuthenticatedLayer',
  }

  useEffect(() => {
    if (window?.Intercom !== undefined && !isEmpty(agency)) {
      window.Intercom('boot', {
        app_id: 'g8nf5co6',
        email: userEmail,
        name: userName,
        user_id: userId,
        created_at: getUnixTime(Date.now()),
        custom_launcher_selector: '#intercom-help',
        company: {
          id: userAgencyId,
          name: agency?.companyName,
          trading_as: agency?.trading,
          registration_number: agency?.registrationNumber,
          ffc_number: agency?.ffcNumber,
          vat_number: agency?.vatNumber,
        },
        // custom attributes
        ...Object.keys(onboardingProgress).map(key => ({
          [`onboarding_${key}`]: onboardingProgress[key],
        })),
        user_role: userRole,
      })
    }
  }, [isAuthenticated, agency, userEmail, userName, userId, onboardingProgress, userAgencyId])

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; stateKey: string; }' is... Remove this comment to see the full error message
    <LayoutProvider stateKey="global">
      <GTMProvider state={gtmParams}>
        <ApiStateProvider>
          <Helmet>
            <title>reOS</title>
          </Helmet>
          <Routes />
        </ApiStateProvider>
      </GTMProvider>
    </LayoutProvider>
  )
}

export default App

import React from 'react'
import { subscribe } from 'react-contextual'
import { InvoicesProvider } from '../../../../providers'
import { SearchFilter } from '../../..'

const HistorySearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([InvoicesProvider], ({ location, redirect }: any) => ({
  pathname: location.pathname,
  redirect,
  filters: [],
}))(HistorySearchFilter)

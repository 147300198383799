import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import {
  Segment,
  FormField,
  CommissionSplit,
  CurrencyField,
  PercentageField,
  Select,
} from '../../../../../views/components'
import { commissionUndertakings, leaseTypes } from 'modules/api/portfolio/constants'
import styles from './CommissionSegment.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

/** @todo handle initial mount focus with ref */
const propTypes = {
  /** Name of commission object. EG: 'managementFee' or 'procurementFee' */
  name: PropTypes.string.isRequired,
  /** Label of commission segment. EG: 'Management Fee' or 'Procurement Fee' */
  label: PropTypes.string.isRequired,
  leaseType: PropTypes.oneOf([leaseTypes.managed, leaseTypes.unmanaged]).isRequired,
  /** Use to calculate unmanaged commission */
  leaseTermInMonths: PropTypes.number,
  rentAmount: PropTypes.number.isRequired,
  values: PropTypes.shape({
    netAmount: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        agent: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      }),
    ),
  }),
  defaultResultSets: PropTypes.arrayOf(PropTypes.array),
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  getFieldError: PropTypes.func,
  errors: PropTypes.object,
}

const CommissionSegment = (props: $TSFixMe) => {
  const {
    name,
    label,
    leaseType,
    leaseTermInMonths,
    rentAmount,
    values,
    defaultResultSets,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    getFieldError,
    errors,
  } = props

  const typeOptions = [
    {
      value: commissionUndertakings.variable,
      label: 'Percentage',
    },
    {
      value: commissionUndertakings.fixed,
      label: 'Fixed amount',
    },
  ]

  return (
    <Segment.Group className={styles.root}>
      <Segment.Group horizontal>
        {/* @ts-expect-error */}
        <Segment style={{ width: '40%' }}>
          <h3 style={{ fontSize: '20px' }}>{label}</h3>
        </Segment>
        <Segment>
          <Select
            noOutline={true}
            style={{ marginTop: '10px' }}
            name={`${name}.type`}
            error={getFieldError(`${name}.type`)}
            value={typeOptions.find(option => option.value === values.type)}
            onChange={(data: $TSFixMe) => {
              setFieldValue(`${name}.type`, data.value)
              if (rentAmount && data.value !== values.type) {
                if (data.value === commissionUndertakings.fixed && values?.percentage?.value) {
                  const netAmount = rentAmount * (values.percentage.value / 100)
                  setFieldValue(`${name}.netAmount.value`, netAmount)
                } else if (data.value === commissionUndertakings.variable) {
                  const percentage = (values.netAmount.value / rentAmount) * 100
                  setFieldValue(`${name}.percentage.value`, percentage)
                  setFieldValue(`${name}.percentage.vatable`, values[name]?.percentage?.vatable || false)
                }
              }
            }}
            onBlur={setFieldTouched}
            options={typeOptions}
          />
        </Segment>
      </Segment.Group>
      <Segment.Group horizontal>
        <Segment>
          <div className="rbn--row">
            <div className="rbn--col-md">
              <FormField>
                <CurrencyField
                  disabled
                  name={`${name}.rentAmount`}
                  label="Rental amount"
                  error={getFieldError(`${name}.rentAmount`)}
                  value={rentAmount}
                  handleBlur={handleBlur}
                />
              </FormField>
            </div>
            <div className="rbn--col-md">
              <FormField>
                {/** @todo convert to VAT Input Control */}
                {values.type === commissionUndertakings.fixed ? (
                  <CurrencyField
                    includeVatControl
                    vatApplied={values?.netAmount?.vatable}
                    name={`${name}.netAmount`}
                    label={`Commission ${values?.netAmount?.vatable ? '(incl. VAT)' : ''}`}
                    error={getFieldError(`${name}.netAmount`)}
                    onFocus={() => setFieldTouched(`${name}.netAmount`)}
                    value={values?.netAmount?.value || 0}
                    onToggleChange={(vatApplied: boolean, value: $TSFixMe) => {
                      setFieldValue(`${name}.netAmount.vatable`, vatApplied)
                    }}
                    onChange={(e: $TSFixMe) => {
                      setFieldValue(`${name}.netAmount.value`, e.target.value)
                      // getRentInvoicePaymentRulesTotal(e.target.value)
                    }}
                    handleBlur={handleBlur}
                    errorPosition="bottom"
                  />
                ) : (
                  <PercentageField
                    includeVatControl
                    vatApplied={values?.percentage?.vatable}
                    name={`${name}.percentage`}
                    label={`Percentage ${values?.percentage?.vatable ? '(incl. VAT)' : ''}`}
                    amount={rentAmount}
                    error={getFieldError(`${name}.percentage`)}
                    onFocus={() => setFieldTouched(`${name}.percentage`)}
                    value={values?.percentage?.value || 0}
                    onChange={(e: $TSFixMe) => {
                      setFieldValue(`${name}.percentage.value`, parseFloat(e.floatValue))
                      // const commissionAmount = values.monthlyRentAmount / 100 * parseFloat(e.target.value)
                      // getRentInvoicePaymentRulesTotal(commissionAmount)
                    }}
                    onToggleChange={(vatApplied: boolean, value: $TSFixMe) =>
                      setFieldValue(`${name}.percentage.vatable`, vatApplied)
                    }
                    handleBlur={handleBlur}
                    errorPosition="bottom"
                  />
                )}
              </FormField>
            </div>
          </div>
        </Segment>
      </Segment.Group>
      {leaseType === leaseTypes.unmanaged && values.type === commissionUndertakings.variable && (
        <Segment.Group>
          <Segment>Calculated based on lease term of {leaseTermInMonths} months.</Segment>
        </Segment.Group>
      )}
      <Segment.Group>
        <Segment>
          <CommissionSplit
            feeName={name}
            values={values}
            leaseType={leaseType}
            leaseTermInMonths={leaseTermInMonths}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            errors={pathOr({}, [...name.split('.'), 'splits'], errors)}
            defaultResultSets={defaultResultSets}
            type={name}
          />
        </Segment>
      </Segment.Group>
    </Segment.Group>
  )
}

CommissionSegment.propTypes = propTypes

export default CommissionSegment

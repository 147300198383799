import { find, get, memoize } from 'lodash-es'
import { identity, isEmpty } from 'ramda'
import createSelector from 'selectorator'
import { $TSFixMe } from 'types/ts-migrate'

// const getReportingApiState = createSelector([NAMESPACE], identity)

const getSlice = (key: string): $TSFixMe => createSelector([`api.reporting.${key}`], identity)

export const getAccountsReceivable = getSlice('accountsReceivable')

export const getCommissionSplit = getSlice('commissionSplit')

export const getDepositSummaryStats = getSlice('depositSummaryStats')

export const getPerformanceSummary = getSlice('performanceSummary')

export const getProperties = getSlice('properties')

export const getParties = getSlice('parties')

export const getMonthlyPerformanceSummary = createSelector([getPerformanceSummary], summary => {
  return summary?.monthly || []
})

export const getPropertiesForSelect = createSelector([getProperties], properties => {
  return Object.values(properties).map((property: $TSFixMe) => ({
    value: property.id,
    label: property.AddressPrimaryText,
  }))
})

export const getPropertyById = createSelector(
  [getProperties],
  properties => (id: any) => properties.find((p: any) => p.id === id),
)

export const getOwnersForSelect = createSelector([getParties], parties => {
  return Object.values(parties).map((party: $TSFixMe) => ({
    value: party.id,
    label:
      party.company && !isEmpty(party.company.tradingAs) && party.company.tradingAs !== undefined
        ? party.company.companyName + ' t/a ' + party.company.tradingAs
        : party.company
        ? party.company.companyName
        : party.person.firstName + ' ' + party.person.lastName,
  }))
})

export const getOwnerById = createSelector(
  [getParties],
  parties => (id: any) => Object.values(parties).find((p: any) => p.id === id),
)

import React, { FC, useState } from 'react'
import classNames from 'classnames'
import Cleave from 'cleave.js/react'
import 'cleave.js/dist/addons/cleave-phone.za'
import styles from './CleaveField.module.scss'

type Props = {
  type: 'date' | 'phone'
  id: string
  name: string
  className?: string
  label: string
  placeholder: string
  value: string
  error?: React.ReactNode | string | boolean
  disabled?: boolean
  onChange: (e: any) => void
}

const CleaveField: FC<Props> = ({
  type,
  id,
  name,
  className,
  label,
  placeholder,
  value,
  error,
  disabled,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const classes = classNames([styles.root, className], {
    [styles['is-focused']]: isFocused,
    [styles['has-error']]: error,
  })

  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <div className={classes}>
      <label htmlFor={id}>
        {label}
        {error && ` - ${error as string}`}
      </label>
      <Cleave
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        options={
          type === 'date'
            ? { date: true, delimiter: '-', datePattern: ['Y', 'm', 'd'] }
            : { phone: true, phoneRegionCode: 'ZA' }
        }
        onChange={onChange}
        onFocus={handleFocus}
      />
    </div>
  )
}

export default CleaveField

import { createSelector } from 'reselect'
import { get, uniq } from 'lodash-es'
import { createChatbotDialog } from './ChatbotDialog'
import { partyApiSelectors } from '../../../api/party'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import { getCurrentPortfolioId, getLeaseInvoicesDialog, state } from '../selectors'
import { getSelectedOwner } from './OwnerDetails'
import { getSelectedTenant } from './TenantDetails'
import { chatbotEvents } from '../state'
import { paymentRuleClearanceTypes } from 'modules/api/portfolio/constants'
import { $TSFixMe } from 'types/ts-migrate'

const initialState: $TSFixMe = {
  removedInvoiceIds: [],
  updatedInvoiceIds: [],
  invoices: [],
}

export const { leaseInvoicesDialog } = createChatbotDialog('leaseInvoices')
  .withInitialState(initialState)
  .withEvents({
    leaseInvoiceTemplatesSaved: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      invoices: {
        $set: get(payload, 'invoiceTemplates', []),
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.invoiceTemplateRemoved]: ({ payload }: any, state: any) => ({
      $merge: {
        removedInvoiceIds: uniq(state.removedInvoiceIds.concat([payload])),
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.invoiceTemplateChanged]: ({ payload }: any, state: any) => ({
      $merge: {
        updatedInvoiceIds: uniq([...state.updatedInvoiceIds, payload]),
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateInvoiceTemplate_success]: ({ payload }: any, state: any) => ({
      $merge: {
        updatedInvoiceIds: [],
        invoices: payload.invoiceTemplates,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendCommission_success]: ({ payload }, state) => ({
      $merge: {
        invoices: payload.invoiceTemplates,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.removeInvoiceTemplate_success]: ({ payload }: any, state) => ({
      $merge: {
        invoices: payload.invoiceTemplates,
        removedInvoiceIds: [],
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.addInvoiceTemplate_success]: ({ payload }: any, state) => ({
      $merge: {
        invoices: payload.invoiceTemplates,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateTenantParties_success]: ({ payload }: any, state) => ({
      $merge: {
        invoices: state.invoices.map(inv => {
          if (inv.id === payload.id) {
            return payload
          } else {
            return inv
          }
        }),
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateOwnerParties_success]: ({ payload }: any, state) => ({
      $merge: {
        invoices: payload.invoiceTemplates,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendRentAndFees_success]: ({ payload }, state) => ({
      $merge: {
        invoices: payload.invoiceTemplates,
      },
    }),
  })
  .when('leaseInvoiceTemplatesSaved', ({ payload }: any) => {
    const newState = {
      invoices: payload.invoices.map((i: any) => {
        const invoice = {
          category: i.category,
          id: i.id,
          autoDeliver: i.autoDeliver,
          interval: i.interval,
          invoicePartyId: i.invoicePartyId,
          invoicePartyAccountType: i.invoicePartyAccountType,
          netAmount: i.netAmount,
          vatable: i.vatable,
          paymentRules: i.paymentRules.map((p: any) => {
            let clearance = {}
            if (parseFloat(p.beneficiary.value.amount) > 0) {
              clearance = {
                type: paymentRuleClearanceTypes.fixed,
                value: { grossAmount: p.beneficiary.value.amount, vat: p.beneficiary.value.vat },
              }
            } else {
              clearance = {
                type: paymentRuleClearanceTypes.notset,
                value: { notSet: true },
              }
            }
            return {
              ...p,
              clearance,
            }
          }),
          description: i.description,
        }

        return invoice
      }),
    }

    return {
      $merge: {
        ...newState,
      },
    }
  })
  .setup()

export const getInvoicesInitialValues = createSelector(state, getLeaseInvoicesDialog, (s, dialog) => {
  const invoices: any[] = []
  const currentPortfolioId = getCurrentPortfolioId(s)
  const portfolioStatus = portfolioApiSelectors.getPortfolioStatusLabel(s)(currentPortfolioId)

  dialog.invoices.forEach((inv: any) => {
    const { id, invoicePartyId, invoicePartyAccountType, category, interval, vatable, paymentRules, description } = inv
    const ownerId = getSelectedOwner(s)
    const tenantId = getSelectedTenant(s)

    let _invoicePartyAccountType = invoicePartyAccountType

    if (!_invoicePartyAccountType) {
      _invoicePartyAccountType = invoicePartyId === ownerId ? 'Owner' : invoicePartyId === tenantId ? 'Tenant' : null
    }

    if (_invoicePartyAccountType === null) {
      throw new Error('Invalid invoicePartyAccountType')
    }

    invoices.push({
      id,
      editing: false,
      autoDeliver: true,
      category,
      invoicePartyId,
      invoicePartyAccountType: _invoicePartyAccountType,
      interval,
      netAmount: inv.netAmount,
      vatable,
      paymentRules: paymentRules.map((pr: any) => {
        /** @todo update/api - implement `type`s */
        const { beneficiary, clearance } = pr
        const name = partyApiSelectors.getPartyNameById(s)(beneficiary.value.partyId)
        return {
          beneficiary: {
            ...beneficiary,
            value: {
              ...beneficiary.value,
              name,
            },
          },
          clearance,
        }
      }),
      description,
    })
  })

  return { invoices: invoices.filter(inv => (portfolioStatus !== 'draft' ? inv.interval !== 'OnceOff' : true)) }
})

export const getInvoices = createSelector(getLeaseInvoicesDialog, dialog => dialog.invoices)

export const getRemovedInvoices = createSelector(getLeaseInvoicesDialog, dialog => dialog.removedInvoiceIds)

export const getUpdatedInvoiceIds = createSelector(getLeaseInvoicesDialog, dialog => dialog.updatedInvoiceIds)

const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'agency'

export const invitationStatuses = {
  revoked: 'Revoked',
  expired: 'Expired',
  pending: 'Pending',
}

export const ENDPOINTS = {
  GET_AGENCY: `${REACT_APP_API_URL}/agency/:id/entity`,
  GET_INVITATIONS: `${REACT_APP_API_URL}/agency/invitations`,
  GET_INVITE: `${REACT_APP_API_URL}/agency/invitations/for_token`,
  SEND_INVITE: `${REACT_APP_API_URL}/agency/invitations`,
  RESEND_INVITE: `${REACT_APP_API_URL}/agency/invitations/:partyId/resend`,
  SEND_PARTY_INVITE: `${REACT_APP_API_URL}/agency/parties/:partyId/invitations`,
  GET_USERS: `${REACT_APP_API_URL}/agency/members`,
  REVOKE_INVITE: `${REACT_APP_API_URL}/agency/invitations/:partyId`,
  GET_THEMES: `${REACT_APP_API_URL}/agency/themes`,
  SET_THEME: `${REACT_APP_API_URL}/agency/themes`,
  UPDATE_LOGO: `${REACT_APP_API_URL}/agency/logo`,
  UPDATE_AGENCY: `${REACT_APP_API_URL}/agency/:id/entity`,
  AGENCY_LISTING: `${REACT_APP_API_URL}/agency/listing`,
  AGENCY_STATS: `${REACT_APP_API_URL}/agency/stats`,
  AGENCY_STATS_CHART: `${REACT_APP_API_URL}/agency/stats/chart`,
  INVITABLE_PARTIES: `${REACT_APP_API_URL}/agency/parties`,
  SEGMENT_BASE: `${REACT_APP_API_URL}/agency/segments`,
  SEGMENT_ENTITY: `${REACT_APP_API_URL}/agency/segments/:segmentId`,
  DEACTIVATE: `${REACT_APP_API_URL}/agency/:id/deactivate`,
  ACTIVATE: `${REACT_APP_API_URL}/agency/:id/activate`,
  AGENCY_TO_AGENCY_GROUP: `${REACT_APP_API_URL}/agency/groups/:groupId/agencies`,
  REMOVE_AGENCY_FROM_AGENCY_GROUP: `${REACT_APP_API_URL}/agency/v2/groups/:groupId/remove_agency/:agencyId`,
}

export const DEBOUNCE_TIME = 1000

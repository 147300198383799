import React, { useMemo } from 'react'
import { subscribe } from 'react-contextual'
import PropTypes from 'prop-types'
import { FastField, Formik } from 'formik'
import * as yup from 'yup'
import { find, path, pathOr, pipe, propEq } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  addDays,
  addMonths,
  addYears,
  differenceInMonths,
  format,
  isAfter,
  isBefore,
  lastDayOfMonth,
  parseISO,
  subDays,
  subMonths,
} from 'date-fns'
import { Button, CurrencyField, DateRangePicker, FormField, InfoBox } from 'views/components'
import { commissionUndertakings, leaseTypes } from '../../../../api/portfolio/constants'
import styles from './PortfolioRenewalForm.module.scss'
import { chatbotSelectors } from '../../../../ui/ChatBot'
import { formUtils } from '../../../../../utils'
import { getCommissionInitialValues } from '../../../../ui/ChatBot/Dialogs/LeaseCommission'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../../api/portfolio'
import { partyApiSelectors } from '../../../../api/party'
import { getCurrentLeaseType } from '../../../../ui/ChatBot/Dialogs/LeaseTypeSelection'
import { apiStateSelectors } from '../../../../api/apiState'
import CommissionSegment from '../CommissionSegment/CommissionSegment'
import NextSteps from './NextSteps'
// @ts-expect-error
import WarningSvg from '../../../../../theme/icons-v2/Controls/Warning.svg'
import { buildCommissionValidationObject } from '../../../utils/commissionUtils'
import PortfoliosProvider from '../../../PortfoliosProvider'
import { useEffectOnce } from 'react-use'
import pluralize from 'pluralize'
import { invoiceApiSelectors } from '../../../../api/invoice'
import { reconApiSelectors } from '../../../../api/recon'
import { calculateLeaseRenewsAtDate, get10thOfLeaseEndMonth } from './portfolioRenewalFormUtils'
import { getDamageDepositAmount } from '../../../../ui/ChatBot/Dialogs/LeaseRentAndFees'
import { $TSFixMe } from 'types/ts-migrate'
import { userApiSelectors } from '../../../../api/user'
import { uiEvents } from 'modules/ui'
import { agencyApiSelectors } from 'modules/api/agency'

const propTypes = {
  portfolioId: PropTypes.string.isRequired,
}

const invoiceLink = (count, label, handleActiveInvoices) => {
  return (
    <button onClick={() => handleActiveInvoices()}>
      {count} {label} {pluralize('invoice', count)}
    </button>
  )
}

const getValidationSchema = (leaseType: string) =>
  yup.object().shape({
    newRentAmount: yup.number().required('required').min(1, 'Must be greater than R0'),
    depositTopUp: yup.number().required('required'),
    newCommission:
      leaseType === leaseTypes.managed
        ? yup.object().shape({
            managementFee: buildCommissionValidationObject('newCommission.managementFee'),
          })
        : yup.object().shape({
            procurementFee: buildCommissionValidationObject(
              'newCommission.procurementFee',
              ['once-off'],
              leaseTypes.unmanaged,
            ),
          }),
    newLeaseTerms: yup.object().test('newLeaseTerms', function (newLeaseTerms) {
      if (isBefore(parseISO(newLeaseTerms.endDate), new Date())) {
        return this.createError({
          path: `${this.path}.endDate`,
          message: 'Unable to renew a lease ending before today',
        })
      }

      const termsEndDate = pathOr(format(new Date(), 'yyyy-MM-dd'), ['terms', 'endDate'], this.parent)

      if (isBefore(parseISO(newLeaseTerms.startDate), parseISO(termsEndDate))) {
        return this.createError({
          path: `${this.path}.startDate`,
          message: 'Unable to renew a lease starting before previous end date',
        })
      }

      return true
    }),
  })

const getInitialCommissionSegmentValues = (key: $TSFixMe, commissionDialog: $TSFixMe) =>
  pathOr(
    {
      type: commissionUndertakings.fixed,
      monthlyRentAmount: 0,
      netAmount: {
        value: 0,
        vatable: false,
      },
      percentage: {
        value: 0,
        vatable: false,
      },
      frequency: 'monthly',
      splits: [],
    },
    [key],
    commissionDialog,
  )

const PortfolioRenewalForm = ({
  redirect,
  fetchDraftInvoices,
  fetchActiveInvoices,
  openActiveInvoices,
  openDraftInvoices,
  fetchInvoiceSchedules,
}): React.ReactElement => {
  const dispatch = useDispatch()
  const currentPortfolioId = useSelector(chatbotSelectors.getCurrentPortfolioId)
  const leaseType = useSelector(getCurrentLeaseType)
  const isRenewing = useSelector(state => apiStateSelectors.isLoading(state)([portfolioApiEvents.renewal_request]))
  const isCancellingRenewal = useSelector(state =>
    apiStateSelectors.isLoading(state)([portfolioApiEvents.cancelRenewal_request]),
  )
  const isRenewalComplete = useSelector(state =>
    apiStateSelectors.isComplete(state)([portfolioApiEvents.renewal_success]),
  )
  const isExpired = useSelector(s => portfolioApiSelectors.isPortfolioExpired(s)(currentPortfolioId))
  const renewal: $TSFixMe = useSelector(s =>
    portfolioApiSelectors.getPortfolioFieldById(s)(currentPortfolioId, ['renewal']),
  )
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const commissionDialog = useSelector(getCommissionInitialValues)
  const termsDialog = useSelector(chatbotSelectors.getLeaseTermsDialog)
  const rentInvoice = useSelector(state =>
    portfolioApiSelectors.getRentInvoiceTemplateByPortfolioId(state)(currentPortfolioId),
  )
  const damageDepositAmount = useSelector(getDamageDepositAmount)
  const commissionName = leaseType === leaseTypes.managed ? 'managementFee' : 'procurementFee'
  const commissionLabel = leaseType === leaseTypes.managed ? 'Management Fee' : 'Procurement Fee'

  const draftInvoices = useSelector(state =>
    invoiceApiSelectors.getDraftInvoicesForPortfolio(state)(currentPortfolioId),
  )
  const activeInvoices = useSelector(state =>
    reconApiSelectors.getActiveInvoicesForPortfolio(state)(currentPortfolioId),
  )
  const isMonthToMonth = useSelector(state => portfolioApiSelectors.isPortfolioMonthToMonth(state)(currentPortfolioId))

  const isCurrentAgencyGlobalVatEnabled = useSelector(state =>
    agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state),
  )

  useEffectOnce(() => {
    if (!!currentPortfolioId) {
      fetchDraftInvoices()
      fetchActiveInvoices()
      fetchInvoiceSchedules()
    }
  })

  const terms = pathOr(
    {
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      rolloverMonthToMonth: true,
    },
    ['term'],
    termsDialog,
  )

  const newLeaseStartDate = addDays(new Date(terms.endDate), 1)
  const newLeaseEndDate = addYears(lastDayOfMonth(new Date(terms.endDate)), 1)

  const commissionValues = {
    managementFee: getInitialCommissionSegmentValues('managementFee', commissionDialog),
    procurementFee: getInitialCommissionSegmentValues('procurementFee', commissionDialog),
  }

  const leaseRenewsAt = calculateLeaseRenewsAtDate(new Date(terms.endDate), isExpired)

  const getPartyNameById = useSelector(partyApiSelectors.getPartyNameById)

  /** @todo this could be a reusable selector. Also used elsewhere */
  const shapeSplits = (splits: $TSFixMe) => {
    return splits.map((split: $TSFixMe) => {
      const { agentPartyId, splitPercentage } = split
      return {
        agent: {
          label: getPartyNameById(agentPartyId),
          value: agentPartyId,
        },
        splitPercentage,
      }
    })
  }

  const initialValues = {
    terms, // add terms for validation. Props a better way
    newRentAmount: pathOr(0, ['newRentAmount'], renewal),
    monthlyRentAmount: pathOr(0, ['newRentAmount'], renewal), // duplicated on top of `newRentAmount` for use in CommissionSplit
    depositTopUp: damageDepositAmount > 0 ? pathOr(0, ['depositTopUp'], renewal) : 0,
    leaseRenewsAt: leaseRenewsAt, // Allow the renewsAt schedule to be recalculated so ignore previously saved date
    renewalFee: pathOr(0, ['renewalFee'], renewal),
    renewalFeeVatable: pathOr(isCurrentAgencyGlobalVatEnabled, ['renewalFeeVatable'], renewal),
    newLeaseTerms: pathOr(
      {
        ...terms,
        startDate: format(newLeaseStartDate, 'yyyy-MM-dd'),
        endDate: format(newLeaseEndDate, 'yyyy-MM-dd'),
      },
      ['newLeaseTerms'],
      renewal,
    ),
    newCommission: renewal
      ? {
          [commissionName]: {
            ...renewal.newCommission[commissionName],
            /** @todo duplicated again for validation. Needs a refactor */
            monthlyRentAmount: pathOr(0, ['newRentAmount'], renewal),
            splits: shapeSplits(renewal.newCommission[commissionName].splits || []),
          },
        }
      : { [commissionName]: commissionValues[commissionName] },
  }

  const defaultResultSets = useSelector(state => partyApiSelectors.partiesResultsByTags(state)(['Agent', 'Agency']))
  const validationSchema = useMemo(() => getValidationSchema(leaseType), [leaseType])

  /**
   * @todo Commission transformations is ugly. Needs a clean up.
   * Should be transformed in one place and consistently.
   */
  const handleSubmit = (values: any, { setSubmitting }: any): void => {
    dispatch(
      portfolioApiEvents.renewal_request({
        body: values,
        params: { id: currentPortfolioId },
      }),
    )
    setSubmitting(false)
    isRenewalComplete && isAfter(new Date(), get10thOfLeaseEndMonth(new Date(terms.endDate)))
      ? redirect(`/leases/${currentPortfolioId}?filter=active`)
      : redirect(`/leases/${currentPortfolioId}?filter=renewals`)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        submitCount,
      }) => {
        const handleAmountChange = ({ target }: any) => {
          setFieldValue('newRentAmount', target.value)
          setFieldValue(`newCommission.${commissionName}.monthlyRentAmount`, target.value)
        }

        const handleTopupChange = ({ target }: any) => setFieldValue('depositTopUp', target.value)

        const handleRenewFeeChange = ({ target }: any) => setFieldValue('renewalFee', target.value)

        const handleDateChange = (date: any, isStartDate: any): void => {
          if (isStartDate) {
            setFieldValue('newLeaseTerms.startDate', format(date, 'yyyy-MM-dd'))
          } else {
            setFieldValue('newLeaseTerms.endDate', format(date, 'yyyy-MM-dd'))
          }
        }

        const handleRenewalCancellation = (): void => {
          dispatch(portfolioApiEvents.cancelRenewal_request({ id: currentPortfolioId }))
        }

        const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

        const termInMonths = differenceInMonths(
          new Date(values.newLeaseTerms.endDate),
          new Date(values.newLeaseTerms.startDate),
        )

        const communicationsDate = new Date(values.leaseRenewsAt)
        const existingRenewalsAt = pathOr(values.leaseRenewsAt, ['leaseRenewsAt'], renewal)
        const previousCommunicationsDate =
          existingRenewalsAt !== values.leaseRenewsAt ? new Date(existingRenewalsAt) : null

        const the10thOfLeaseEndMonth = get10thOfLeaseEndMonth(new Date(terms.endDate))

        return (
          <form onSubmit={handleSubmit} className={styles.root}>
            {isMonthToMonth && isAfter(new Date(), the10thOfLeaseEndMonth) && draftInvoices.length > 0 && (
              <InfoBox type="warning" className={styles.warning}>
                <header>
                  <img src={WarningSvg} alt="Warning" />
                  <p>
                    <strong>WARNING! There are out-of-date draft invoices that need your attention.</strong>
                  </p>
                </header>
                <p>
                  This lease is set to switch to month-to-month on expiry and draft invoices for next month have already
                  been created on out-of-date information. Please update the draft invoice for next month's rent.
                </p>
                <p>
                  There are <strong>currently</strong> {invoiceLink(draftInvoices.length, 'draft', openDraftInvoices)}{' '}
                  that need your attention.
                  {/* {activeInvoices.length > 0 && (
                    <>
                      &nbsp;There are also {invoiceLink(activeInvoices.length, 'outstanding', openActiveInvoices)}{' '}
                      associated with this lease.
                    </>
                  )} */}
                </p>
              </InfoBox>
            )}
            <fieldset disabled={isReadOnly}>
              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <FastField
                      name="newRentAmount"
                      render={({ field }: any) => (
                        <CurrencyField
                          {...field}
                          label="New rental amount"
                          error={getFieldError('newRentAmount')}
                          onChange={handleAmountChange}
                        />
                      )}
                    />
                  </FormField>
                </div>
                {damageDepositAmount > 0 && (
                  <div className="rbn--col-md-4">
                    <FormField>
                      <FastField
                        name="depositTopUp"
                        render={({ field }) => (
                          <CurrencyField
                            {...field}
                            label="Deposit topup"
                            error={getFieldError('depositTopUp')}
                            onChange={handleTopupChange}
                          />
                        )}
                      />
                    </FormField>
                  </div>
                )}
              </div>
              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      includeVatControl
                      vatApplied={values.renewalFeeVatable}
                      name="renewalFee"
                      label={`Renewal fee ${values.renewalFeeVatable ? '(incl. VAT)' : ''}`}
                      value={values.renewalFee}
                      error={getFieldError('renewalFee')}
                      onChange={handleRenewFeeChange}
                      onToggleChange={(vatApplied: $TSFixMe) => {
                        setFieldValue('renewalFeeVatable', vatApplied)
                      }}
                    />
                  </FormField>
                </div>
              </div>
              <FormField>
                <DateRangePicker
                  startDate={new Date(values.newLeaseTerms.startDate)}
                  endDate={new Date(values.newLeaseTerms.endDate)}
                  startLabel="Start date"
                  endLabel="End date"
                  onChange={handleDateChange}
                  error={path(['newLeaseTerms'], errors)}
                />
              </FormField>

              <CommissionSegment
                name={`newCommission.${commissionName}`}
                label={commissionLabel}
                rentAmount={values.newRentAmount}
                leaseType={leaseType}
                leaseTermInMonths={termInMonths}
                values={{ ...values.newCommission[commissionName], monthlyRentAmount: values.newRentAmount }}
                defaultResultSets={[defaultResultSets]}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
                getFieldError={getFieldError}
                errors={errors}
              />

              <NextSteps
                oldLeaseEndDate={terms.endDate}
                newLeaseStartDate={values.newLeaseTerms.startDate}
                newLeaseEndDate={values.newLeaseTerms.endDate}
                communicationsDate={communicationsDate}
                previousCommunicationsDate={previousCommunicationsDate}
              />

              <div className={styles.footer}>
                <Button type="submit" loading={isRenewing}>
                  {renewal ? 'Update' : isAfter(new Date(), the10thOfLeaseEndMonth) ? 'Confirm' : 'Schedule'} renewal
                </Button>
                {renewal && (
                  <Button tertiary type="button" onClick={handleRenewalCancellation} loading={isCancellingRenewal}>
                    Cancel renewal
                  </Button>
                )}
              </div>
            </fieldset>
          </form>
        )
      }}
    </Formik>
  )
}

PortfolioRenewalForm.propTypes = propTypes

export default subscribe(
  [PortfoliosProvider],
  ({
    redirect,
    fetchDraftInvoices,
    fetchActiveInvoices,
    openActiveInvoices,
    openDraftInvoices,
    fetchInvoiceSchedules,
  }) => ({
    redirect,
    fetchDraftInvoices,
    fetchActiveInvoices,
    openActiveInvoices,
    openDraftInvoices,
    fetchInvoiceSchedules,
  }),
)(PortfolioRenewalForm)

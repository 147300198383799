const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'wallet'

export const ENDPOINTS = {
  BALANCE: `${REACT_APP_API_URL}/wallet/:partyId/balances`,
  PAYOUT: `${REACT_APP_API_URL}/wallet/payout`,
  PAYMENTS: `${REACT_APP_API_URL}/wallet/payments_history`,
  REVERSE: `${REACT_APP_API_URL}/wallet/payments/reverse`,
  DEMAND: `${REACT_APP_API_URL}/wallet/payments/demand`,
  TRANSFER: `${REACT_APP_API_URL}/wallet/accounts/:partyId/transfer`,
  WALLET_TRANSFER: `${REACT_APP_API_URL}/wallet/transfer`,
  BULK_WALLET_TRANSFER: `${REACT_APP_API_URL}/wallet/bulk_wallet_transfer/:fromWalletRef`,
  CREATE_WALLET: `${REACT_APP_API_URL}/wallet/create`,
  REFUND: `${REACT_APP_API_URL}/wallet/accounts/:partyId/refund`,
  POP: `${REACT_APP_API_URL}/wallet/request_pop`,
  GET_UNALLOCATED_BAD_REFERENCES: `${REACT_APP_API_URL}/wallet/bad_references`,
  GET_ALLOCATED_BAD_REFERENCES: `${REACT_APP_API_URL}/wallet/bad_references/allocated`,
  ALLOCATE_BAD_REFERNECE: `${REACT_APP_API_URL}/wallet/bad_references/:txId`,
  REFERENCE_LOOKUP: `${REACT_APP_API_URL}/wallet/reference/:reference`,
}

export const transferReasons = {
  unsettledInvoice: 'UnsettledInvoice',
  damageClaim: 'DamageClaim',
  leaseEnding: 'LeaseEnding',
  other: 'Other',
}

export const assets = {
  dummy: 'zar_dummy#phoenix.coin',
  zar: 'zar#phoenix.coin',
}

import React, { useCallback } from 'react'
import cx from 'classnames'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { useEffectOnce } from 'react-use'
import { DashboardProvider, CommissionAccount } from '../..'
import { isEmpty, path, pathOr } from 'ramda'
import useBreakpoints from '../../../../views/hooks/useBreakpoints'
import styles from './DashboardPage.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { walletApiSelectors, walletApiEvents } from '../../../api/wallet'
import { WithdrawFundsModal } from '../../../../views/components'
import { agencyApiSelectors } from '../../../api/agency'
import BankAccount from '../BankAccount/BankAccount'
import DepositAccount from '../DepositAccount/DepositAccount'
import DashboardSummaryWidgets from './DashboardSummaryWidgets'
import DashboardPerformanceSummary from './DashboardPerformanceSummary'
import { userApiSelectors } from '../../../api/user'
import { reportingApiSelectors } from 'modules/api/reporting'

const DashboardPage = ({
  currentAgencyId,
  agencyName,
  agencyTrading,
  // agencyStatsChartDataForDays,
  fetchBankStatement,
  bankStatementJson,
  openBankStatement,
  fetchDepositBankStatement,
  fetchDepositSummaryStats,
  openDepositBankStatement,
  depositBankStatementJson,
  fetchPortfolioStats,
  fetchAccountingStats,
  fetchReconStats,
}: any) => {
  const agencyId = useSelector(userApiSelectors.getCurrentAgencyId)

  useEffectOnce(() => {
    if (agencyId) {
      fetchPortfolioStats()
      fetchAccountingStats()
      fetchReconStats()
      fetchBankStatement()
      fetchDepositBankStatement()
      fetchDepositSummaryStats()
    }
  })

  const { isMobile } = useBreakpoints()

  const classes = cx([styles.root])

  const dispatch = useDispatch()

  const commissionAccountBalance = useSelector(state => walletApiSelectors.getGlobalBalance(state)(currentAgencyId))
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)
  const globalBalance = useSelector(state => walletApiSelectors.getGlobalBalanceAsset(state)(currentAgencyId))
  const withdrawalModalOpen = useSelector(state => walletApiSelectors.getWithdrawalModalStatus(state)())
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const depositSummaryStats = useSelector(state => reportingApiSelectors.getDepositSummaryStats(state))

  const bankStatementBalance = pathOr('0', ['context', 'statement', 'closingBalance'], bankStatementJson)

  const closeConfirmation = () => dispatch(walletApiEvents.withdrawalModalRequestClosed())

  const handlePayout = useCallback(() => {
    const asset = path(['name'], globalBalance)
    const amount = path(['balance'], globalBalance)
    if (asset && amount) {
      dispatch(
        walletApiEvents.payout_request({
          party: currentAgencyId,
          asset,
          amount,
        }),
      )
    }
  }, [dispatch, globalBalance, currentAgencyId])

  return (
    <>
      <Helmet>
        <title>reOS | Dashboard</title>
      </Helmet>
      <div className={classes}>
        {!isMobile && (
          <header>
            {isEmpty(agencyTrading) ? (
              <h1>{agencyName}</h1>
            ) : !isEmpty(agencyTrading) ? (
              <>
                <h1>{agencyTrading}</h1>
                <h2>{agencyName}</h2>
              </>
            ) : (
              <h1>{agencyName}</h1>
            )}
          </header>
        )}
        <div className={styles.body}>
          <section>
            <DashboardSummaryWidgets />
          </section>
          <section className={styles['chart-section']}>
            <DashboardPerformanceSummary />
          </section>
          <section>
            <h2>Account Summary</h2>
            <BankAccount
              balance={bankStatementBalance.replace(/([A-Z])(\d)/g, '$1 $2')}
              viewStatement={openBankStatement}
              // @ts-expect-error
              disabled={!currentAgencyId}
            />
            <DepositAccount
              balance={pathOr('0', ['ClosingBalance'], depositSummaryStats)}
              viewStatement={openDepositBankStatement}
              // @ts-expect-error
              disabled={true}
            />
            <CommissionAccount
              balance={commissionAccountBalance}
              onWithdraw={() => dispatch(walletApiEvents.withdrawalModalRequestOpened())}
              isReadOnly={isReadOnly}
              // pending={9000000}
              // items={items}
            />
          </section>
        </div>
      </div>
      <WithdrawFundsModal
        isOpen={withdrawalModalOpen}
        balance={commissionAccountBalance}
        bankName={pathOr('', ['bank'], agencyBankDetails)}
        number={pathOr('', ['accountNumber'], agencyBankDetails)}
        onClose={closeConfirmation}
        onCancel={closeConfirmation}
        onConfirm={handlePayout}
      />
    </>
  )
}

export default subscribe(
  [DashboardProvider],
  ({
    currentAgencyId,
    agencyName,
    agencyTrading,
    fetchAgencyStatsChart,
    fetchBankStatement,
    bankStatementJson,
    openBankStatement,
    fetchDepositBankStatement,
    fetchDepositSummaryStats,
    openDepositBankStatement,
    depositBankStatementJson,
    fetchPortfolioStats,
    fetchAccountingStats,
    fetchReconStats,
  }: any) => ({
    currentAgencyId,
    agencyName,
    agencyTrading,
    fetchAgencyStatsChart,
    fetchBankStatement,
    bankStatementJson,
    openBankStatement,
    fetchDepositBankStatement,
    fetchDepositSummaryStats,
    openDepositBankStatement,
    depositBankStatementJson,
    fetchPortfolioStats,
    fetchAccountingStats,
    fetchReconStats,
  }),
)(DashboardPage)

import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../views/components'
import ReportingProvider from '../ReportingProvider'
import ReportingContent from './ReportingContent'
import styles from './ReportingPage.module.scss'

const ReportingPage = () => {
  return (
    <div className={styles.root}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message */}
      <LayoutProvider
        stateKey="segments"
        initialProps={{
          sidebar: { open: false, width: 0 },
          primaryPanel: { open: false, width: 752 },
          secondaryPanel: { open: false, width: 375 },
        }}
      >
        <Helmet>
          <title>reOS | Reporting</title>
        </Helmet>
        <Layout>
          <LayoutBody>
            <LayoutPrimaryPanel>{/* {params.id ? <ViewContact /> : null} */}</LayoutPrimaryPanel>
            <LayoutContent>
              <TableProvider>
                <ReportingContent />
              </TableProvider>
            </LayoutContent>
          </LayoutBody>
        </Layout>
      </LayoutProvider>
    </div>
  )
}

export default subscribe(ReportingProvider, ({ match }: any) => ({
  match,
}))(ReportingPage)

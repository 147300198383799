import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { first, get } from 'lodash-es'
import {
  AutoDeliverControl,
  CurrencyField,
  FormField,
  FormFieldButton,
  FundDistribution,
  Grid,
  Segment,
  Select,
  TextInput,
} from '../../../../../components'
import { arrayUtils } from '../../../../../../utils'
import CalendarOff from '../../../../atoms/Svgs/CalendarOff'
import Trash from '../../../../atoms/Svgs/Trash'
import EditOff from '../../../../atoms/Svgs/EditOff'
import styles from './InvoicePanel.module.scss'
import { paymentRuleClearanceTypes } from 'modules/api/portfolio/constants'
import { hashCode } from 'utils/string'
import { defaultAgencyInvoiceTypes, depositInvoiceTypes } from 'modules/api/invoice/constants'
import { head, lensPath, map as rMap, pathOr, pipe, set, sum } from 'ramda'
import TextField from '../../../../atoms/TextField/TextField'
import FundDistributionFooter from '../../../FormFragments/FundDistribution/FundDistributionFooter/FundDistributionFooter'
import { PARTY_TAGS } from 'modules/api/party/constants'
import { $TSFixMe } from 'types/ts-migrate'
import {
  transformDepositBeneficiaryForManagedByChange,
  transformDepositBeneficiaryForTransferChange,
} from 'views/components/organisms/FormFragments/FundDistribution/DepositBeneficiary/depositBeneficiaryUtils'
import { existingInvoiceTypes, invoiceTypes } from 'utils/invoiceTypes'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  agencyId: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
  invoiceTypes: PropTypes.arrayOf(PropTypes.object),
  invoiceParties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  defaultBeneficiaries: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  getInvoiceTypeNameByValue: PropTypes.func,
  invoice: PropTypes.object,
  index: PropTypes.number,
  toggleEdit: PropTypes.func,
  autoDeliver: PropTypes.bool,
  handleInvoiceTemplateRemoved: PropTypes.func,
  handleInvoiceTemplateChanged: PropTypes.func,
  getPartyTagsById: PropTypes.func,
  /** List of party tags for selection */
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  easyPayData: PropTypes.object,
  fetchEasyPayData: PropTypes.func,
  agencyName: PropTypes.string,
  agencyBankDetails: PropTypes.object,
  propertyOwnerId: PropTypes.string,
  propertyOwnerName: PropTypes.string,
  propertyOwnerBankDetails: PropTypes.object,
  getPartyBankDetails: PropTypes.func,
  portfolioId: PropTypes.string,
  getPartyNameById: PropTypes.func,
  getInvoiceTypeByValue: PropTypes.func,
  isCurrentAgencyGlobalVatEnabled: PropTypes.bool,
  // Formik helpers
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  arrayHelpers: PropTypes.object,
  errors: PropTypes.any,
  isSubmitting: PropTypes.bool,
  validateForm: PropTypes.func,
}

const defaultProps = {
  toggleEdit: (index: number) => index,
  autoDeliver: false,
}

class InvoicePanel extends Component<$TSFixMe, $TSFixMe> {
  namePrefix: any

  constructor(props: any) {
    super(props)
    this.namePrefix = `invoices[${this.props.index}]`
    this.state = {
      newBeneficiaryValue: '',
      isAutoDeliverOpen: false,
      paymentRules: [],
      paymentRulesHash: null,
      displayErrors: false,
      fetchingEasyPayData: false,
      currentBeneficiaryIndex: null,
      currentBeneficiaryReference: null,
      currentBeneficiaryType: null,
    }
    this.toggleEdit = this.toggleEdit.bind(this)
    this.openAutoDeliverTooltip = this.openAutoDeliverTooltip.bind(this)
    this.closeAutoDeliverTooltip = this.closeAutoDeliverTooltip.bind(this)
  }

  componentDidMount(): void {
    this.setState({
      paymentRulesHash: hashCode(JSON.stringify(get(this.props, 'invoice.paymentRules', ''))),
    })
  }

  componentDidUpdate(prevProps: any): void {
    /**
     * @hack workaround for Formik arrayHelpers and validation quircks.
     */
    if (!prevProps.isSubmitting && this.props.isSubmitting) {
      this.setState({ displayErrors: true })
    }

    const { easyPayData, setFieldValue, invoice } = this.props
    const easyPayReferenceData = pathOr(null, [this.state.currentBeneficiaryReference], easyPayData)
    const easyPayError = pathOr(null, ['request', 'response_message'], easyPayReferenceData)

    if (easyPayReferenceData && this.state.fetchingEasyPayData) {
      const isEasyPayReferenceDataValid = pathOr(false, ['request', 'success'], easyPayReferenceData)
      this.setState({ fetchingEasyPayData: false })

      if (isEasyPayReferenceDataValid) {
        this.handleBeneficiaryReferenceChange(
          this.state.currentBeneficiaryIndex,
          this.state.currentBeneficiaryReference,
          this.state.currentBeneficiaryType,
        )
      }
    }

    if (easyPayError && this.state.fetchingEasyPayData) {
      this.setState({ fetchingEasyPayData: false })
      setFieldValue(
        `${this.namePrefix}.paymentRules`,
        invoice.paymentRules.map((beneficiary: any, i: any) => {
          if (i === this.state.currentBeneficiaryIndex) {
            beneficiary = {
              ...beneficiary,
              beneficiary: {
                ...beneficiary.beneficiary,
                errors: [{ key: 'easypay_ref', message: easyPayError }],
              },
            }
          }

          return beneficiary
        }),
      )
    }
  }

  toggleEdit(e: any, index: number) {
    e.preventDefault()
    const { toggleEdit } = this.props
    if (typeof toggleEdit === 'function') {
      toggleEdit(index)
    }
  }

  openAutoDeliverTooltip() {
    this.setState({ isAutoDeliverOpen: true })
  }

  closeAutoDeliverTooltip() {
    this.setState({ isAutoDeliverOpen: false })
  }

  /**
   * Sets rental income payment rule to 0 if any other payment rule is not set
   * @returns {Array} updated payment rules
   */
  maybeResetRentalIncomePaymentRule = (paymentRules: $TSFixMe[]) => {
    const { invoice } = this.props
    if (invoice?.category === 'RentInvoice') {
      const isAllSet = paymentRules.filter(({ beneficiary }) => beneficiary?.value?.amount === 0).length === 0
      const isOwnerRentalIncome = ({ beneficiary }: $TSFixMe) =>
        beneficiary?.value?.partyTag === PARTY_TAGS.owner && beneficiary?.value?.reference === 'Rental Income'

      if (!isAllSet) {
        const rentalIncomePaymentRule = paymentRules.find(pr => isOwnerRentalIncome(pr))

        if (rentalIncomePaymentRule) {
          const updatedRentalIncomeRule = {
            ...rentalIncomePaymentRule,
            beneficiary: {
              ...rentalIncomePaymentRule.beneficiary,
              value: {
                ...rentalIncomePaymentRule.beneficiary.value,
                amount: 0,
              },
            },
          }
          paymentRules = paymentRules.map(pr => (isOwnerRentalIncome(pr) ? updatedRentalIncomeRule : pr))
        }
      }
    }

    return paymentRules
  }

  handleNewBeneficiaryAdded = (paymentRule: any, index: number): void => {
    const { invoice, setFieldValue, isCurrentAgencyGlobalVatEnabled } = this.props
    const vatApplied =
      existingInvoiceTypes.includes(invoice.category) &&
      paymentRule.beneficiary.value.partyTag === 'Agency' &&
      isCurrentAgencyGlobalVatEnabled
        ? true
        : false

    let paymentRulesWithNewPaymentRule: $TSFixMe = arrayUtils.insert(
      index,
      {
        ...paymentRule,
        beneficiary: {
          ...paymentRule.beneficiary,
          value: {
            ...paymentRule.beneficiary.value,
            vat: vatApplied,
          },
        },
        clearance: {
          type: paymentRuleClearanceTypes.fixed,
          value: { grossAmount: 0, vat: false },
        },
      },
      invoice.paymentRules,
    )
    paymentRulesWithNewPaymentRule = this.maybeResetRentalIncomePaymentRule(paymentRulesWithNewPaymentRule)
    setFieldValue(`${this.namePrefix}.paymentRules`, paymentRulesWithNewPaymentRule)
    this.onInvoiceTemplateChanged()
  }

  handleBeneficiaryRemoved = (index: number): void => {
    const { setFieldValue, invoice } = this.props
    setFieldValue(`${this.namePrefix}.paymentRules`, arrayUtils.removeByIndex(index, invoice.paymentRules))
    this.onInvoiceTemplateChanged()
  }

  getPaymentRuleByIndex = (index: number) => this.props.invoice.paymentRules.find((_: any, i: any) => i === index)

  handleBeneficiaryAmountChange = (index: number, e: any): void => {
    const { invoice, setFieldValue } = this.props
    setFieldValue(
      `${this.namePrefix}.paymentRules`,
      invoice.paymentRules.map((pr: any, i: any) => {
        if (i === index) {
          const value = get(e, ['target', 'value'], '')
          pr.clearance = pr.clearance || {
            type: paymentRuleClearanceTypes.fixed,
            value: {
              grossAmount: '',
              vat: false,
            },
          }
          pr = {
            ...pr,
            beneficiary: {
              ...pr.beneficiary,
              value: {
                ...pr.beneficiary.value,
                amount: value,
              },
            },
            clearance: {
              ...pr.clearance,
              value: {
                ...pr.clearance.value,
                grossAmount: value,
              },
            },
          }
        }
        return pr
      }),
    )
    this.props.validateForm()
    this.onInvoiceTemplateChanged()
  }

  handleDepositBeneficiaryTransferChange = (index: number, transfer: any): void => {
    const { invoice, setFieldValue, agencyId, ownerId, getPartyNameById, getInvoiceTypeByValue } = this.props

    const paymentRule = invoice.paymentRules.find((_: any, i: any) => i === index)
    const partyName = getPartyNameById(invoice.invoicePartyId)
    const invoiceTypeObj = getInvoiceTypeByValue(invoice.category || '')
    const invoiceTypeName = pathOr('', ['name'], invoiceTypeObj)

    const transformedBeneficiary = transformDepositBeneficiaryForTransferChange(
      paymentRule,
      transfer,
      agencyId,
      partyName,
      invoiceTypeName,
      ownerId,
    )

    setFieldValue(`${this.namePrefix}.paymentRules[${index}]`, transformedBeneficiary)
    this.onInvoiceTemplateChanged()
  }

  handleBeneficiariesOrderChange = (newOrder: any): void => {
    const updatedPaymentRules = newOrder.map((o: any) => this.props.invoice.paymentRules[o])
    this.props.setFieldValue(`${this.namePrefix}.paymentRules`, updatedPaymentRules)
    this.setState({ paymentRulesHash: hashCode(JSON.stringify(updatedPaymentRules)) })
    this.onInvoiceTemplateChanged()
  }

  onInvoiceTemplateRemoved = (index: number) => {
    const { invoice, handleInvoiceTemplateRemoved, arrayHelpers } = this.props
    const { id = false } = invoice
    id && handleInvoiceTemplateRemoved(id)
    arrayHelpers.remove(index)
  }

  onInvoiceTemplateChanged = () => {
    const { invoice, handleInvoiceTemplateChanged } = this.props
    const { id = false } = invoice
    id && handleInvoiceTemplateChanged(id)
  }

  getDefaultPartySearchResults = (beneficiaryPartyTag: any) => {
    const { invoice, getPartyTagsById, defaultBeneficiaries } = this.props
    const invoicePartyTag = getPartyTagsById(invoice.invoicePartyId)
    const newDefaults = get(defaultBeneficiaries, '[0]', [])

    if (beneficiaryPartyTag === 'Owner') {
      return [newDefaults.filter((b: any) => b.partyTag !== 'agency')]
    } else if (first(invoicePartyTag) === 'Owner') {
      return [newDefaults.filter((b: any) => b.partyTag !== 'Owner')]
    }

    return defaultBeneficiaries
  }

  getPaymentRuleAmountsSum = () =>
    pipe(rMap(pathOr(0, ['beneficiary', 'value', 'amount'])), sum)(this.props.invoice.paymentRules)

  handleInvoiceCategoryChange = (data: any, categoryName: any): void => {
    const { setFieldValue, invoice, agencyId, agencyName, isCurrentAgencyGlobalVatEnabled } = this.props
    this.onInvoiceTemplateChanged()
    setFieldValue(categoryName, data.value)

    if (invoiceTypes.includes(data.value) && isCurrentAgencyGlobalVatEnabled) {
      setFieldValue(`${this.namePrefix}.vatable`, true)
    } else {
      setFieldValue(`${this.namePrefix}.vatable`, false)
    }

    if (depositInvoiceTypes.includes(data.value)) {
      const beneficiary = {
        beneficiary: {
          type: 'DepositBeneficiary',
          value: {
            amount: 0,
            beneficiaryTag: 'TenantDepositAccount',
            reference: 'Transfer to managed deposit wallet on reOS',
            transfer: true,
            vat: false,
          },
        },
      }
      setFieldValue(`${this.namePrefix}.paymentRules`, [beneficiary])
    } else if (defaultAgencyInvoiceTypes.includes(data.value)) {
      const beneficiary = {
        beneficiary: {
          type: 'PartyBeneficiary',
          value: {
            amount: 0,
            name: agencyName,
            partyId: agencyId,
            partyTag: 'Agency',
            reference: `${data.label}`,
            vat: isCurrentAgencyGlobalVatEnabled,
          },
        },
      }
      setFieldValue(`${this.namePrefix}.paymentRules`, [beneficiary])
    } else if (
      invoice.paymentRules.some((b: any) => b.beneficiary.partyTag === 'Agency') ||
      !defaultAgencyInvoiceTypes.includes(data.value)
    ) {
      setFieldValue(`${this.namePrefix}.paymentRules`, [])
    } else if (invoice.paymentRules.some((b: any) => b.beneficiary.type === 'DepositBeneficiary')) {
      setFieldValue(`${this.namePrefix}.paymentRules`, [])
    }
  }

  handleAmountChange = (e: any): void => {
    const { setFieldValue, invoice } = this.props
    const amount = e.target.value
    setFieldValue(`${this.namePrefix}.netAmount`, amount)
    if (depositInvoiceTypes.includes(invoice.category)) {
      const paymentRule = head(invoice.paymentRules)
      const updatedPaymentRule = set(lensPath(['beneficiary', 'value', 'amount']), amount, paymentRule)
      setFieldValue(`${this.namePrefix}.paymentRules[0]`, updatedPaymentRule)
    }
    this.onInvoiceTemplateChanged()
  }

  handleDescriptionBlur = ({ target }: any) => {
    const { setFieldValue } = this.props
    setFieldValue(`${this.namePrefix}.description`, target.value)
    this.onInvoiceTemplateChanged()
  }

  handleDescriptionChange = ({ target }: any) => {
    const { setFieldValue } = this.props
    setFieldValue(`${this.namePrefix}.description`, target.value)
  }

  handleBeneficiaryReferenceChange = (beneficiaryIndex: number, reference: any, type: any): void => {
    const { fetchEasyPayData, easyPayData, setFieldValue, invoice } = this.props

    setFieldValue(
      `${this.namePrefix}.paymentRules`,
      invoice.paymentRules.map((beneficiary: any, i: any) => {
        if (i === beneficiaryIndex) {
          beneficiary = {
            ...beneficiary,
            beneficiary: {
              ...beneficiary.beneficiary,
              value: {
                ...beneficiary.beneficiary.value,
                ...(type !== 'EasyPayBeneficiary' ? { reference } : {}),
                ...(type === 'EasyPayBeneficiary' ? { easyPayReference: reference } : {}),
              },
              errors: [],
            },
          }
        }

        return beneficiary
      }),
    )

    if (type === 'EasyPayBeneficiary') {
      const easyPayReferenceData = pathOr(null, [reference], easyPayData)
      const isEasyPayReferenceDataValid = pathOr(false, ['request', 'success'], easyPayReferenceData)

      if (!easyPayReferenceData || !isEasyPayReferenceDataValid) {
        this.setState({
          fetchingEasyPayData: true,
          currentBeneficiaryIndex: beneficiaryIndex,
          currentBeneficiaryReference: reference,
          currentBeneficiaryType: type,
        })
        fetchEasyPayData(reference)
      } else {
        this.onInvoiceTemplateChanged()
      }
    } else {
      this.onInvoiceTemplateChanged()
    }
  }

  getBeneficiariesAllocationsSum = () =>
    pipe(pathOr([], ['paymentRules']), rMap(pathOr(0, ['beneficiary', 'value', 'amount'])), sum)(this.props.invoice)

  getUnallocatedAmount = () => {
    const netAmount = pathOr(null, ['invoice', 'netAmount'], this.props)
    return netAmount ? netAmount - this.getBeneficiariesAllocationsSum() || 0 : 0
  }

  buildFundDistributionFooter = () => {
    const netAmount = pathOr(0, ['invoice', 'netAmount'], this.props)
    return (
      <FundDistributionFooter
        text={'Unallocated funds'}
        amount={this.getUnallocatedAmount()}
        isComplete={this.getUnallocatedAmount() === 0}
        progress={{
          value: 100 - ((netAmount - this.getBeneficiariesAllocationsSum()) / netAmount) * 100,
          style: 'default',
        }}
      />
    )
  }

  render(): null | React.ReactElement {
    const {
      invoiceTypes,
      invoiceParties,
      getInvoiceTypeNameByValue,
      invoice,
      index,
      partyTags,
      easyPayData,
      fetchEasyPayData,
      agencyName,
      agencyBankDetails,
      // Formik helpers
      // handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      errors,
      propertyOwnerId,
      propertyOwnerName,
      propertyOwnerBankDetails,
      portfolioId,
    } = this.props

    const { displayErrors } = this.state
    const categoryName = `${this.namePrefix}.category`
    const isDeposit = depositInvoiceTypes.filter((type: any) => type !== 'DepositTopUp').includes(invoice.category)
    const invoiceType = {
      name: getInvoiceTypeNameByValue(invoice.category),
      value: invoice.category,
    }

    return (
      <div className={styles.root}>
        <Segment.Group key={index}>
          <Segment.Group horizontal>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; style: { width: string;... Remove this comment to see the full error message */}
            <Segment style={{ width: '85%' }}>
              {invoice.editing ? (
                <Select
                  isSearchable
                  name={categoryName}
                  placeholder="Select invoice type"
                  value={invoiceTypes.find((type: any) => type.value === invoice.category)}
                  error={displayErrors && errors.category}
                  onChange={(data: any) => this.handleInvoiceCategoryChange(data, categoryName)}
                  onBlur={handleBlur}
                  options={invoiceTypes}
                />
              ) : (
                <h3 style={{ fontSize: '20px' }}>{invoiceType.name}</h3>
              )}
            </Segment>
            {invoice.category !== 'RentInvoice' && ((isDeposit && invoice?.isEmpty) || !isDeposit) && (
              <Segment>
                {invoice.editing ? (
                  <FormFieldButton state="danger" onClick={this.onInvoiceTemplateRemoved.bind(this, index)}>
                    <Trash width="34px" height="34px" />
                  </FormFieldButton>
                ) : (
                  <FormFieldButton onClick={(e: any) => this.toggleEdit(e, index)}>
                    <EditOff width="34px" height="34px" />
                  </FormFieldButton>
                )}
              </Segment>
            )}
          </Segment.Group>

          <Segment.Group>
            <Segment horizontal>
              <Grid>
                <Grid.Column xs={12} md={5}>
                  <FormField>
                    <Select
                      name={`${this.namePrefix}.invoiceParty`}
                      value={invoiceParties.find((party: any) => party.value === invoice.invoicePartyId)}
                      label="Invoice"
                      error={displayErrors && errors.invoicePartyId}
                      options={invoiceParties}
                      onBlur={setFieldTouched}
                      onChange={(data: any) => {
                        setFieldValue(`${this.namePrefix}.invoicePartyAccountType`, data.tag)
                        setFieldValue(`${this.namePrefix}.invoicePartyId`, data.value)
                        this.onInvoiceTemplateChanged()
                      }}
                    />
                  </FormField>
                </Grid.Column>

                <Grid.Column xs={12} md={1}>
                  <FormField>
                    {invoice.interval !== 'OnceOff' && (
                      <AutoDeliverControl
                        isOpen={this.state.isAutoDeliverOpen}
                        active={invoice.autoDeliver}
                        onChange={(data: any) => {
                          setFieldValue(`${this.namePrefix}.interval`, data.value)
                          this.onInvoiceTemplateChanged()
                        }}
                        onClose={this.closeAutoDeliverTooltip}
                        value={invoice.interval}
                      >
                        <FormFieldButton
                          // active={invoice.autoDeliver} /** @todo invoice-automation: re-add when autodeliver is enabled again **/
                          style={{ marginTop: '27px', marginLeft: '8px' }}
                          onClick={(e: any) => {
                            e.preventDefault()
                            this.openAutoDeliverTooltip()
                          }}
                        >
                          <CalendarOff width="34px" height="34px" />
                        </FormFieldButton>
                      </AutoDeliverControl>
                    )}
                  </FormField>
                </Grid.Column>

                <Grid.Column split xs={12} md={4}>
                  <FormField>
                    <CurrencyField
                      includeVatControl
                      disabled={invoice.category === 'RentInvoice' || (isDeposit && !invoice.isEmpty)}
                      vatApplied={invoice.vatable}
                      name={`${this.namePrefix}.netAmount`}
                      label={`Amount ${invoice.vatable ? '(incl. VAT)' : ''}`}
                      placeholder="Set on send"
                      value={invoice.netAmount}
                      error={displayErrors && errors.netAmount}
                      onChange={this.handleAmountChange}
                      onToggleChange={(vatApplied: any, value: any) => {
                        setFieldValue(`${this.namePrefix}.vatable`, vatApplied)
                        this.onInvoiceTemplateChanged()
                      }}
                      handleBlur={handleBlur}
                    />
                  </FormField>
                </Grid.Column>
              </Grid>
            </Segment>
            <Segment horizontal>
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    value={invoice.description}
                    onBlur={this.handleDescriptionBlur}
                    onChange={this.handleDescriptionChange}
                  />
                }
                label="Description"
              />
            </Segment>
          </Segment.Group>

          <FundDistribution
            state={displayErrors && errors.paymentRules ? 'danger' : 'default'}
            errors={displayErrors && { beneficiaries: errors.paymentRules }}
            headerText="Payment allocation"
            invoiceType={invoiceType}
            invoiceStatus={null}
            balance={parseFloat(invoice.netAmount)}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ state: string; errors: any; headerText: st... Remove this comment to see the full error message
            grossAmount={parseFloat(invoice.netAmount)}
            onNewBeneficiaryAdded={this.handleNewBeneficiaryAdded}
            onBeneficiaryRemoved={this.handleBeneficiaryRemoved}
            onNewBeneficiaryAmountChange={this.handleBeneficiaryAmountChange}
            onBeneficiaryReferenceChange={this.handleBeneficiaryReferenceChange}
            onDepositBeneficiaryTransferChange={this.handleDepositBeneficiaryTransferChange}
            onBeneficiariesOrderChange={this.handleBeneficiariesOrderChange}
            beneficiaries={invoice.paymentRules}
            partySearchTags=""
            partyTags={partyTags}
            getDefaultResultSets={this.getDefaultPartySearchResults}
            beneficiaryAmountPlaceholder="Set on send"
            onVatChange={(i, vat, amount) => {
              setFieldValue(`${this.namePrefix}.paymentRules[${i}].beneficiary.value.vat`, vat)
            }}
            Footer={this.buildFundDistributionFooter}
            easyPayData={easyPayData}
            fetchEasyPayData={fetchEasyPayData}
            agencyName={agencyName}
            agencyBankDetails={agencyBankDetails}
            beneficiariesKey={this.state.paymentRulesHash}
            invoiceVatEnabled={invoice.vatable}
            propertyOwnerId={propertyOwnerId}
            propertyOwnerName={propertyOwnerName}
            propertyOwnerBankDetails={propertyOwnerBankDetails}
            unallocatedAmount={parseFloat(invoice.netAmount) - this.getPaymentRuleAmountsSum() || 0}
            portfolioId={portfolioId}
          />
        </Segment.Group>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
InvoicePanel.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
InvoicePanel.defaultProps = defaultProps

export default InvoicePanel

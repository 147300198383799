import React, { FC } from 'react'
import { TTpnConsumerEnquiryResponse } from 'modules/api/integrations/types'
import { $TSFixMe } from 'types/ts-migrate'
import styles from './TpnConsumerReport.module.scss'
import { Button } from 'views/components'

type Props = {
  personalInfo: $TSFixMe
  selectedModulesByName: string[]
  enquiryResponse: TTpnConsumerEnquiryResponse
}

const TpnConsumerReport: FC<Props> = ({ personalInfo, selectedModulesByName, enquiryResponse }) => {
  return (
    <div className={styles.root}>
      <div className="rbn--row">
        <div className="rbn--col-lg-6">
          <h3>Personal information</h3>
          <p>Full Name: {enquiryResponse.fullName}</p>
          <p>ID Number: {enquiryResponse.nationalId}</p>
          <p>Cell Number: {personalInfo[3]}</p>
        </div>
        <div className="rbn--col-lg-6">
          <h3>Included reports</h3>
          {selectedModulesByName.map((module: string, i: number) => (
            <div key={`pill-${i}`} className={styles.pill}>
              {module}
            </div>
          ))}
        </div>
      </div>
      <div className={styles['report-background']}>
        <div className="rbn--row">
          <div className="rbn--col-lg-6">
            <p>Active Companies: {enquiryResponse.activeCompanies}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Inactive Companies: {enquiryResponse.inactiveCompanies}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Accounts: {enquiryResponse.accounts}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>TPN Payments: {enquiryResponse.tpnPayments}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Judgments: {enquiryResponse.judgments}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Defaults: {enquiryResponse.defaults}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Notices: {enquiryResponse.notices}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Trace Alerts: {enquiryResponse.traceAlerts}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Enquiries: {enquiryResponse.enquiries}</p>
          </div>
          <div className="rbn--col-lg-6">
            <p>Credex: {enquiryResponse.credex}</p>
          </div>
        </div>
      </div>
      <Button
        className={styles.button}
        size="sm"
        onClick={() => window.open(enquiryResponse.reportPdfUrl, '_blank')}
        disabled={!enquiryResponse.reportPdfUrl}
      >
        Get PDF Report
      </Button>
      <Button
        className={styles.button}
        size="sm"
        secondary
        onClick={() => window.open(enquiryResponse.reportURL, '_blank')}
        disabled={!enquiryResponse.reportURL}
      >
        View on TPN
      </Button>
    </div>
  )
}

export default TpnConsumerReport

import React, { FC } from 'react'
import { isEmpty, pathOr } from 'ramda'
import { FastField } from 'formik'
import { TextField, FormField, TextInput } from '../..'
import { PartySearch } from '../../../containers'
import { formUtils } from '../../../../utils'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'
import PhoneField from 'views/components/atoms/PhoneField/PhoneField'
import { ParsedCountry } from 'react-international-phone'

type TProps = {
  values?: $TSFixMe
  tags?: $TSFixMe
  handleChange?: (e: $TSFixMe) => void
  handleBlur?: (e: $TSFixMe) => void
  handleSelect?: (e: $TSFixMe) => void
  setFieldValue?: $TSFixMe
  errors?: $TSFixMe
  touched?: $TSFixMe
  allowPartySearch?: boolean
  disableFields?: $TSFixMe
  submitCount?: number
  companiesOnly?: boolean
}

const EntityDetails: FC<TProps> = ({
  values,
  tags,
  handleChange,
  handleBlur,
  handleSelect,
  setFieldValue,
  errors,
  touched,
  allowPartySearch = true,
  disableFields = false,
  submitCount,
  companiesOnly = false,
}: $TSFixMe) => {
  const [dialCode, setDialCode] = React.useState('+27')

  const getFieldError = formUtils.getFieldError(submitCount, touched, errors)
  const getFieldValue = (name: any) => pathOr('', [name], values)

  const contactNumber = getFieldValue('telNumber')
  const contactNumberReplaced = contactNumber.replace(/\s/g, '')

  return (
    <>
      <div className="rbn--row">
        <div className="rbn--col-md-4">
          <FormField>
            <FastField
              name="companyName"
              label="Entity Name"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableFields}
              render={({ field }: any) =>
                allowPartySearch ? (
                  <PartySearch
                    {...field}
                    includeIdentification
                    label="Entity Name"
                    placeholder="e.g. Wayne Enterprises, Inc"
                    disabled={disableFields}
                    onResultSelect={handleSelect}
                    tags={tags}
                    value={getFieldValue('companyName')}
                    error={getFieldError('companyName')}
                    limitResults={companiesOnly ? 'company' : null}
                  />
                ) : (
                  <TextField
                    {...field}
                    inputComponent={
                      <TextInput
                        id={field.name}
                        name={field.name}
                        type={TextFieldTypes.text}
                        placeholder="e.g. Wayne Enterprises, Inc"
                        value={getFieldValue('companyName')}
                        disabled={disableFields}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Entity Name"
                    error={getFieldError('companyName')}
                  />
                )
              }
            />
          </FormField>
        </div>
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="tradingAs"
                  placeholder="e.g. WayneCorp"
                  value={getFieldValue('tradingAs')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Trading Name"
              error={getFieldError('tradingAs')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="vatNumber"
                  placeholder="e.g. 4000012345"
                  value={getFieldValue('vatNumber')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Entity VAT Number"
              error={getFieldError('vatNumber')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="companyRegistration"
                  placeholder="e.g. 2018/123456/07"
                  value={getFieldValue('companyRegistration')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Entity Registration Number"
              error={getFieldError('companyRegistration')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.email}
                  name="emailAddress"
                  placeholder="admin@wenterprises.com"
                  value={getFieldValue('emailAddress')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Email Address"
              error={getFieldError('emailAddress')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <PhoneField
                  defaultCountry="za"
                  value={contactNumber.includes(dialCode) ? contactNumber : [dialCode, contactNumberReplaced].join(' ')}
                  onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                    setFieldValue('telNumber', meta.inputValue)
                    setDialCode(meta.country.dialCode)
                  }}
                  disabled={disableFields}
                />
              }
              label="Contact Number"
              error={getFieldError('telNumber')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="contactPersonFirstName"
                  placeholder="e.g. Bruce"
                  value={getFieldValue('contactPersonFirstName')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Contact Person First Name"
              error={getFieldError('contactPersonFirstName')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="contactPersonLastName"
                  placeholder="e.g. Wayne"
                  value={getFieldValue('contactPersonLastName')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Contact Person Last Name"
              error={getFieldError('contactPersonLastName')}
            />
          </FormField>
        </div>
      </div>
    </>
  )
}

export default EntityDetails

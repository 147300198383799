import { isNil } from 'ramda'
import { currencyUtils } from '../../../../../utils'

type TProps = {
  children: string | number
}

const CurrencyText = ({ children }: TProps) => {
  const formatted = !isNil(children) ? currencyUtils.formatCurrency(children) : ''
  return formatted
}

export default CurrencyText

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { BadReferencesPage, BadReferencesProvider } from '..'
import { SupportStaffProvider } from '../../supportStaff'

const SegmentsRoute = (props: any) => (
  <BadReferencesProvider {...props}>
    <SupportStaffProvider>
      <SearchFilterProvider location={props.location}>
        <BadReferencesPage />
      </SearchFilterProvider>
    </SupportStaffProvider>
  </BadReferencesProvider>
)

export default SegmentsRoute

import React from 'react'

const SvgCsv = ({ stroke = '#6C6C72', ...props }): React.ReactElement => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M9.251 27.254h-3a1.5 1.5 0 01-1.5-1.5v-19.5a1.5 1.5 0 011.5-1.5H16.88a1.5 1.5 0 011.06.439l5.872 5.871a1.5 1.5 0 01.439 1.061v4.629" />
      <path d="M24.251 12.254h-6a1.5 1.5 0 01-1.5-1.5v-6m-1.5 15a3 3 0 00-3 3v1.5a3 3 0 003 3m6-7.5h-1.5a1.5 1.5 0 00-1.5 1.5c0 2.25 3 2.25 3 4.5a1.5 1.5 0 01-1.5 1.5h-1.5m6-7.5V22.3c0 1.764.522 3.488 1.5 4.955a8.935 8.935 0 001.5-4.955v-2.546" />
    </g>
  </svg>
)

export default SvgCsv

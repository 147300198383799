import { get, setWith } from 'lodash-es'

export const omitIfEmpty = (name: any, object: any, isEmptyTest: any) => {
  if (isEmptyTest === undefined) {
    isEmptyTest = (v: any) => v.length > 0
  }
  const value = get(object, name)
  if (value !== undefined && value !== null && isEmptyTest(value)) {
    return setWith({}, name.split('.').reverse()[0], value)
  }
  return {}
}

export default omitIfEmpty

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Grid.module.scss'

const propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  style: PropTypes.object,
  flexOptions: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /** Visually split columns - aligns this and subsequent columns right */
  split: PropTypes.bool,
}

const Column = ({ xs, sm, md, style, flexOptions, className, children, split }: any) => {
  const classNames: string[] = []
  xs ? classNames.push(styles[`col-xs-${xs}`]) : classNames.push(`${styles['col-xs']}`)
  sm ? classNames.push(styles[`col-sm-${sm}`]) : classNames.push(`${styles['col-sm']}`)
  md ? classNames.push(styles[`col-md-${md}`]) : classNames.push(`${styles['col-md']}`)
  split && classNames.push(`${styles['col-split']}`)
  classNames.push(className)
  flexOptions?.split(' ').forEach((opt: any) => classNames.push(styles[opt]))

  return <div className={classNames.join(' ')}>{children}</div>
}

Column.propTypes = propTypes

export default Column

import React from 'react'
import { Table } from '../../../../views/components'
import PropTypes from 'prop-types'
import RemoveIcon from '../../../../views/components/atoms/Icons/Controls/Close'
import styles from './SegmentForm.module.scss'

const SegmentFormPortfoliosTable = ({ data, onRemove }: any) => {
  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell head>Property Address</Table.Cell>
            <Table.Cell head>Lease Details</Table.Cell>
            <Table.Cell head>&nbsp;</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map(({ id, propertyAddress, leaseDetails }: any) => (
            <Table.Row key={id}>
              <Table.Cell>{propertyAddress.mainText}</Table.Cell>
              <Table.Cell>{leaseDetails.term}</Table.Cell>
              <Table.Cell className={styles['action-cell']}>
                <button className={styles['remove-button']} type="button" onClick={() => onRemove(id)}>
                  <RemoveIcon />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

SegmentFormPortfoliosTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      propertyAddress: PropTypes.shape({
        mainText: PropTypes.string,
        secondaryText: PropTypes.string,
      }),
      leaseDetails: PropTypes.shape({
        rent: PropTypes.string,
        term: PropTypes.string,
      }),
    }),
  ),
  onRemove: PropTypes.func.isRequired,
}

export default SegmentFormPortfoliosTable

import React, { useRef } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useEffectOnce } from 'react-use'
import { isEmpty, mergeRight, path } from 'ramda'

import { FormErrors, FormField, FormLoader, TextField, TextInput } from '../../../../../views/components'
import { userApiEvents } from '../../../../api/user'
import { apiStateSelectors } from '../../../../api/apiState'
import styles from './InvitationForm.module.scss'
import { formUtils } from '../../../../../utils'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Email address is required.'),
  password: yup.string().required('Password is required.').min(9, 'Password must be at least 9 characters.'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const InvitationForm = ({ agencyName, firstName, lastName, token }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const passwordRef = useRef<any>()

  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([userApiEvents.signup_request, userApiEvents.signupFromInvite_request]),
  )
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  useEffectOnce(() => {
    passwordRef.current?.focus()
  })

  return (
    <div>
      <h2>Accept invitation</h2>
      <p>You were invited to join {agencyName}.</p>
      <p>Please confirm your name and enter a password below to get started.</p>
      <Formik
        initialValues={{ firstName, lastName }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'password' does not exist on type '{ firs... Remove this comment to see the full error message
        onSubmit={({ firstName, lastName, password }) => {
          dispatch(userApiEvents.signupFromInvite_request({ firstName, lastName, password, encryptedValue: token }))
        }}
      >
        {({ handleSubmit, handleBlur, handleChange, errors, values, touched, submitCount }) => {
          const registerEvent = userApiEvents.signupFromInvite_request({ ...values, encryptedValue: token })
          const fieldErrors = getFormFieldErrorsByEvent(registerEvent)
          const generalErrors = getGeneralFormErrorsByEvent(registerEvent) || []
          const emailError: $TSFixMe = path(['email'], fieldErrors)
          /**
           * @todo temp hack because there is no 'email' field in this form.
                  The API returns email exists error under errors.email instead of errors.general
           */
          if (emailError) {
            generalErrors.push(`${emailError}`)
          }
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

          return (
            <>
              <FormErrors errors={generalErrors} />
              <FormLoader
                onSubmit={handleSubmit}
                ButtonContainer={ButtonContainer}
                buttonProps={{ children: 'Submit' }}
                state={formLoaderState}
                errorText={hasApiErrors ? 'Oops, something went wrong' : 'Resolve errors'}
                persistErrorMessage={!hasApiErrors}
              >
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.text}
                        name="firstName"
                        placeholder="First name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    error={getFieldError('firstName')}
                  />
                </FormField>
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.text}
                        name="lastName"
                        placeholder="Last name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    error={getFieldError('lastName')}
                  />
                </FormField>
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.password}
                        name="password"
                        placeholder="Choose a password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ref={(ref: any) => {
                          passwordRef.current = ref
                        }}
                      />
                    }
                    error={getFieldError('password')}
                  />
                </FormField>
              </FormLoader>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

InvitationForm.propTypes = {
  agencyName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

export default InvitationForm

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Heading from '../../atoms/Typography/Heading/Heading'
import styles from './Header.module.scss'

const propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  trailingIcon: PropTypes.node,
  className: PropTypes.string,
}

const defaultProps = {
  tag: 'h1',
}

const Header = ({ icon, tag, text, trailingIcon, className = '' }: any) => {
  return (
    <div className={cx(styles.root, className)}>
      {icon && <i>{icon}</i>}
      <Heading as={tag} className={styles.heading}>
        {text}
      </Heading>
      {trailingIcon && <i>{trailingIcon}</i>}
    </div>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NAMESPACE } from './constants'
import {
  TIntegration,
  TIntegrationsState,
  TTpnAuthorizeRequest,
  TTpnAuthorizeResponse,
  TTpnConsumerEnquiryRequest,
  TTpnConsumerEnquiryResponse,
  TTpnConsumerEnquiryHistoryResponse,
  TTpnConsumerModule,
  TIntegrationOauthDetailsResponse,
} from './types'

const initialState: TIntegrationsState = {
  integrations: [],
  integrationOauthDetails: {},
  tpnCanUserAccessCredex: {},
  tpnConsumerEnquiry: {},
  tpnConsumerEnquiryHistory: [],
  tpnConsumerModules: [],
}

const integrationsApiSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    integrations_request: state => {},
    integrations_success: (state, { payload }: PayloadAction<TIntegration[]>) => {
      state.integrations = payload
    },
    integrationOauthDetails_request: (state, { payload }: PayloadAction<{ syncExistingProperties: boolean }>) => {},
    integrationOauthDetails_success: (state, { payload }: PayloadAction<TIntegrationOauthDetailsResponse>) => {
      state.integrationOauthDetails = payload
    },
    activateIntegration_request: (
      state,
      {
        payload,
      }: PayloadAction<{ integrationType: string; code: any; syncExistingProperties: boolean; redirectUrl: string }>,
    ) => {},
    activateIntegration_success: (state, { payload }: PayloadAction<{}>) => {
      state.integrations = state.integrations.map(integration => {
        if (integration.type === 'PropertyInspect') {
          return { ...integration, enabled: true }
        } else {
          return integration
        }
      })
    },
    deactivateIntegration_request: (state, { payload }: PayloadAction<{ integrationType: string }>) => {},
    deactivateIntegration_success: (state, { payload }: PayloadAction<{}>) => {
      state.integrations = state.integrations.map(integration => {
        if (integration.type === 'PropertyInspect') {
          return { ...integration, enabled: false }
        } else {
          return integration
        }
      })
    },
    tpnAuthorize_request: (state, { payload }: PayloadAction<TTpnAuthorizeRequest>) => {},
    tpnAuthorize_success: (state, { payload }: PayloadAction<TTpnAuthorizeResponse>) => {
      state.integrations = state.integrations.map(integration => {
        if (integration.name === 'TPN Connect') {
          return { ...integration, enabled: true }
        } else {
          return integration
        }
      })
    },
    tpnConsumerCanAccessCredex_request: state => {},
    tpnConsumerCanAccessCredex_success: (state, { payload }: PayloadAction<{ data: boolean }>) => {
      state.tpnCanUserAccessCredex = payload
    },
    tpnConsumerEnquiry_request: (state, { payload }: PayloadAction<TTpnConsumerEnquiryRequest>) => {},
    tpnConsumerEnquiry_success: (state, { payload }: PayloadAction<TTpnConsumerEnquiryResponse>) => {
      state.tpnConsumerEnquiry = payload
    },
    tpnConsumerEnquiryHistory_request: (state, { payload }: PayloadAction<{ id: string }>) => {},
    tpnConsumerEnquiryHistory_success: (state, { payload }: PayloadAction<TTpnConsumerEnquiryHistoryResponse>) => {
      state.tpnConsumerEnquiryHistory = state.tpnConsumerEnquiryHistory.map(history => {
        return history
      })
    },
    tpnConsumerModules_request: (state, { payload }: PayloadAction<{ nationalId: string; RSAId: boolean }>) => {},
    tpnConsumerModules_success: (state, { payload }: PayloadAction<TTpnConsumerModule[]>) => {
      state.tpnConsumerModules = payload
    },
  },
})

const { reducer, actions: events } = integrationsApiSlice

export { events, reducer }

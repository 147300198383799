import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Dimmer.module.scss'

interface IDimmerProps {
  active?: boolean
  fadeStyle?: 'flat' | 'gradient'
  bgColor?: 'appBg' | 'white' | 'dark' | 'transparent'
  disabled?: boolean
  children?: React.ReactNode | null
}

const Dimmer = ({ active = false, fadeStyle = 'flat', bgColor = 'appBg', disabled, children }: IDimmerProps) => {
  const classNames = cx(styles.root, {
    [styles.active]: active,
    [styles.gradient]: fadeStyle === 'gradient',
    [styles['bg-white']]: bgColor === 'white',
    [styles['bg-dark']]: bgColor === 'dark',
    [styles.disabled]: disabled,
  })

  return <div className={classNames}>{children}</div>
}

Dimmer.propTypes = {
  active: PropTypes.bool,
  fadeStyle: PropTypes.oneOf(['flat', 'gradient']),
  bgColor: PropTypes.oneOf(['appBg', 'white', 'dark', 'transparent']),
  disabled: PropTypes.bool,
}

export default Dimmer

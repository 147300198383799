import React, { useEffect, useMemo, useState } from 'react'
import { useMedia } from 'react-use'
import { subscribe } from 'react-contextual'
import { concat, filter as rFilter, map, path, pathOr, pipe } from 'ramda'
import { format, getUnixTime } from 'date-fns'
import styles from './PortfoliosTable.module.scss'
import { dateUtils, stringUtils } from '../../../../utils'
import { SearchFilterProvider } from '../../../../views/providers'
import PortfoliosProvider from '../../PortfoliosProvider'
import { AggregateTable, DataFilters, Header } from '../../../../views/components'
import PropertyIcon from '../../../../views/components/atoms/Svgs/Property'
import NameCell from './NameCell'
import ActiveLeaseCell from './ActiveLeaseCell'
import PortfolioStatusLabel from '../PortfolioStatusLabel'
import { ItemListCell } from '../../../../views/components/organisms/Table'
// import { formatCurrency } from '../../../../utils/currency'
import { useBreakpoints } from '../../../../views/hooks'
import { filterData } from 'components/organisms/DataFilters/dataFiltersUtils'
import { $TSFixMe } from 'types/ts-migrate'

const PortfoliosTable = ({
  filters,
  openPortfolio,
  portfolioViewId,
  primaryPanel,
  searchQuery,
  activeFilterIndex,
  getPortfolioStatusLabel,
  getPortfolioStatusDescription,
  isPortfolioExpiring,
  isPortfolioExpired,
  isDraftPortfolio,
  isActivePortfolio,
}: any): React.ReactElement => {
  const [data, setData] = useState([])
  const filter: $TSFixMe = pathOr({}, [activeFilterIndex], filters)
  const [tableFilters, setTableFilters] = useState<$TSFixMe>([])

  useEffect(() => {
    const newData = filter.portfolios.map((row: $TSFixMe) => ({
      ...row,
      // Fields for sorting and filtering
      meta: {
        propertyAddress: row.mainText,
        leaseType: row?.leaseType,
        leaseDetails: row?.leaseTerms?.endDate ? getUnixTime(new Date(row.leaseTerms.endDate)).toString() : '', // sort leaseDetails column by end date
        tenant: row?.primaryTenantName,
        owner: row?.primaryOwnerName,
      },
    }))
    setData(newData)
    setTableFilters([
      {
        label: 'Lease type',
        value: 'leaseType',
        options: [
          { label: 'Managed', isActive: false },
          { label: 'Unmanaged', isActive: false },
        ],
      },
    ])
  }, [filter.portfolios])

  const filteredPortfolios = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const addressColumn = {
    Header: 'Property Address',
    accessor: 'propertyAddress',
    style: {
      minWidth: '400px',
      textAlign: 'left',
      justifyContent: 'flex-start',
      paddingLeft: '16px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const { breakpoints } = useBreakpoints()
  const isDesktop = useMedia(`(min-width: ${breakpoints.xl}px)`)
  const isDraft = filter.key === 'draft'

  const columns = path(['open'], primaryPanel)
    ? [{ ...addressColumn }]
    : [
        { ...addressColumn },
        {
          Header: 'Owner(s)',
          accessor: 'owner',
          sort: {
            enabled: true,
            order: false,
          },
        },
        {
          Header: 'Tenant(s)',
          accessor: 'tenant',
          style: {
            visibility: isDesktop ? 'visible' : 'hidden',
          },
          sort: {
            enabled: true,
            order: false,
          },
        },
        {
          Header: 'Application(s)',
          accessor: 'applications',
          style: {
            display: isDraft ? 'flex' : 'none',
          },
          sort: {
            enabled: true,
            order: false,
          },
        },
        {
          Header: 'Lease Details',
          accessor: 'leaseDetails',
          sort: {
            enabled: true,
            order: false,
          },
        },
      ]

  if (filter.key === 'all') {
    columns.push({
      Header: '',
      accessor: 'tag',
      style: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ maxWidth: string; textAlign: string; justi... Remove this comment to see the full error message
        maxWidth: '110px',
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: '16px',
      },
    })
  }

  const orderedParties = (parties: any): $TSFixMe =>
    pipe(
      rFilter((t: any) => !t.isPrimary),
      // @ts-expect-error
      concat(rFilter((t: any) => t.isPrimary)(parties)),
      map((t: any) => t.name),
    )(parties)

  const portfoliosData = useMemo(() => {
    const buildContactObj = (p: any): $TSFixMe => {
      const startDate = path(['leaseTerms', 'startDate'], p)
      const endDate = path(['leaseTerms', 'endDate'], p)
      // @ts-expect-error
      const startDateFormatted = startDate && format(new Date(startDate), 'dd MMM yyyy')
      // @ts-expect-error
      const endDateFormatted = endDate && format(new Date(endDate), 'dd MMM yyyy')

      const statusDescription = getPortfolioStatusDescription(p.portfolioId, true)
      const leasePeriod =
        (isActivePortfolio(p) && !isPortfolioExpiring(p)) || (isDraftPortfolio(p) && startDate && endDate)
          ? `${startDateFormatted} -> ${endDateFormatted}`
          : p.endDate && `Expires ${dateUtils.timeAgo(new Date(p.endDate))}`

      return {
        id: p.portfolioId,
        meta: p.meta /** @todo rename this to sortAndFilterMeta? */,
        highlighted: portfolioViewId === p.portfolioId,
        propertyAddress: <NameCell name={p.mainText} meta={p.secondaryText} />,
        owner: <ItemListCell items={orderedParties(p.landlords)} />,
        tenant: <ItemListCell items={orderedParties(p.tenants)} />,
        applications: <ItemListCell items={p.applications.map((a: any) => a.partyName)} />,
        leaseDetails: (
          <ActiveLeaseCell
            primaryText={leasePeriod}
            // @ts-expect-error
            meta={
              <>
                {p.leaseType} <br />
                {statusDescription}
              </>
            }
          />
        ),
        /** @todo re-add when rentAmount is available on portfolio summaries */
        // leaseDetails: <ActiveLeaseCell
        //   primaryText=' ' // {`Rent: ${formatCurrency(p.rentAmount)}`}
        //   meta={meta}
        // />,
        tag: <PortfolioStatusLabel useSummary portfolioId={p.portfolioId} />,
      }
    }

    return searchQuery
      ? pipe(map(buildContactObj), filterData(tableFilters))(filteredPortfolios)
      : pipe(map(buildContactObj), filterData(tableFilters))(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filteredPortfolios, searchQuery, portfolioViewId, tableFilters])

  return useMemo(
    () => (
      <div className={styles.root}>
        <Header icon={<PropertyIcon />} text="Leases" />
        <DataFilters filters={tableFilters} onFiltersChanged={setTableFilters} />
        <AggregateTable
          rowHeight={80}
          columns={columns}
          // @ts-expect-error
          data={portfoliosData}
          onRowClick={data => openPortfolio(data.id)}
        />
      </div>
    ),
    [columns, portfoliosData, openPortfolio, tableFilters],
  )
}

export default subscribe(
  [PortfoliosProvider, SearchFilterProvider],
  (
    {
      filters,
      openPortfolio,
      portfolioViewId,
      primaryPanel,
      getPortfolioStatusLabel,
      getPortfolioStatusDescription,
      isPortfolioExpiring,
      isPortfolioExpired,
      isDraftPortfolio,
      isActivePortfolio,
    }: any,
    { searchQuery, activeFilterIndex }: any,
  ) => ({
    filters,
    openPortfolio,
    portfolioViewId,
    primaryPanel,
    searchQuery,
    activeFilterIndex,
    getPortfolioStatusLabel,
    getPortfolioStatusDescription,
    isPortfolioExpiring,
    isPortfolioExpired,
    isDraftPortfolio,
    isActivePortfolio,
  }),
)(PortfoliosTable)

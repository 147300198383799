import createSelector from 'selectorator'
import { find, identity, pathOr } from 'ramda'
import { NAMESPACE, userRoles } from './constants'
import { agencyApiSelectors } from '../agency'

/**
 * Get a slice of the portfolios API state
 * EG: getSlice('byId')
 *
 * @param {string} key Portfolios API state object key
 */
export const getSlice = (key: any) => createSelector([`api.${NAMESPACE}.${key}`], identity)

export const isAuthenticated = getSlice('isAuthenticated')

export const getAgencies = getSlice('agencies')

export const userAgencyCount = createSelector([getAgencies], agencies => agencies.length)

export const getUserId = getSlice('userId')

export const hasSessionTimedOut = getSlice('hasSessionTimedOut')

export const getUserEmail = getSlice('email')

export const getUserFirstName = getSlice('firstName')

export const getUserLastName = getSlice('lastName')

export const emailVerified = getSlice('emailVerified')

export const cellVerified = getSlice('cellVerified')

// TODO: this is blank in state, not sure if its safe to use
export const getCurrentAgencyId = getSlice('currentAgencyId')

export const getUserRole = getSlice('currentAgencyRole')

export const isOwnerRole = createSelector([getUserRole], role => role === userRoles.Owner)

export const isTeamMemberRole = createSelector([getUserRole], role => role === userRoles.TeamMember)

export const isReadOnlyRole = createSelector([getUserRole], role => role === userRoles.ReadOnly)

export const getMisc = getSlice('misc')

export const getCellNumber = getSlice('cellNumber')

export const getOnboardingProgress = getSlice('onboardingProgress')

export const isValidSupportStaff = getSlice('isValidSupportStaff')

export const getOtpErrors = getSlice('otpErrors')

export const isCurrentAgencyVatRegistered = createSelector([identity], state => {
  const currentAgency = agencyApiSelectors.getCurrentAgency(state)
  return pathOr(false, ['agencyDetails', 'vatNumber'], currentAgency)
})

export const getAuthToken = getSlice('authToken')

export const getRefreshToken = getSlice('refreshToken')

export const users = getSlice('users')

export const getUserById = createSelector([users], users => (id: any) => find((user: any) => user.id === id, users))

export const getTeam = getSlice('team')

export const getTeamMemberByUserId = createSelector(
  [getTeam],
  team => (userId: any) => find((member: any) => member.userId === userId, team),
)

export const getCurrentSegments = getSlice('currentSegments')

export const getCurrentSegmentsWithoutDefault = createSelector([identity, getCurrentSegments], (state, segments) => {
  const defaultSegmentId = getCurrentAgencyId(state)
  return segments.filter((id: any) => id !== defaultSegmentId)
})

export const getSegmentMemberships = getSlice('segmentMemberships')

export const getSegmentMembershipsWithoutDefault = createSelector(
  [identity, getSegmentMemberships],
  (state, segments) => {
    const defaultSegmentId = getCurrentAgencyId(state)
    return segments.filter((id: any) => id !== defaultSegmentId)
  },
)

import { combineReducers } from 'redux'
import { accountingApiReducer } from './accounting'
import { agencyApiReducer } from './agency'
import { integrationsApiReducer } from './integrations'
import { invoiceApiReducer } from './invoice'
import { reconApiReducer } from './recon'
import { reportingApiReducer } from './reporting'
import { userApiReducer } from './user'
import { walletApiReducer } from './wallet'
import { partyApiReducer } from './party'
import { portfolioApiReducer } from './portfolio'
import { propertyApiReducer } from './property'
import { bankingApiReducer } from './banking'
import { communicationsApiReducer } from '../communication/state'

export default combineReducers({
  accounting: accountingApiReducer,
  agency: agencyApiReducer,
  banking: bankingApiReducer,
  communications: communicationsApiReducer,
  integrations: integrationsApiReducer,
  invoice: invoiceApiReducer,
  party: partyApiReducer,
  portfolio: portfolioApiReducer,
  property: propertyApiReducer,
  recon: reconApiReducer,
  reporting: reportingApiReducer,
  user: userApiReducer,
  wallet: walletApiReducer,
})

import React, { useCallback, useEffect, useState } from 'react'
import { OtpVerification } from '../../../views/components'
import { useDispatch, useSelector } from 'react-redux'
import { userApiEvents, userApiSelectors } from '../../api/user'
import { smsApiEvents } from '../../api/sms'
import { useEffectOnce } from 'react-use'

type TProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit?: (otp: any) => void
}

const StatefulOtpVerification = ({ isOpen, onClose, onSubmit }: TProps) => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(userApiEvents.currentUserDetails_request())
  })

  const cellnumber = useSelector(userApiSelectors.getCellNumber)

  const getOtp = useCallback(() => {
    dispatch(smsApiEvents.getOtp_request({ cellnumber }))
  }, [cellnumber, dispatch])

  useEffect(() => {
    isOpen && getOtp()
  }, [isOpen, getOtp])

  const [otpValidationFailed, setOtpValidationFailed] = useState(false)

  const handleResend = (): void => {
    getOtp()
    setOtpValidationFailed(false)
  }

  const handleSubmit = (otp: any): void => {
    typeof onSubmit === 'function' ? onSubmit({ otp }) : dispatch(userApiEvents.validateOtp_request({ otp }))
  }

  return (
    <OtpVerification
      isOpen={isOpen}
      onClose={onClose}
      cellNumber={cellnumber}
      resend={handleResend}
      onSubmit={handleSubmit}
      failed={otpValidationFailed}
    />
  )
}

export default StatefulOtpVerification

import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './Switch.module.scss'

type TProps = {
  className?: string
  label?: string
  id?: string
  name?: string
  checked: boolean
  error?: string
  disabled?: boolean
  onChange: (e: any) => void
}

const Switch = ({ className, label, id, name, checked, error, disabled, onChange }: TProps): React.ReactElement => {
  const [focused, setFocused] = useState(false)
  const inputId = id || name

  const classes = classNames([styles.root, className], {
    [styles['is-focused']]: focused,
    [styles['is-checked']]: checked,
    [styles['has-error']]: error,
    [styles['is-disabled']]: disabled,
  })

  const handleChange = (e: any): void => {
    onChange({ ...e, checked: !checked })
  }

  const toggleFocus = (): void => setFocused(!focused)

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={inputId}>
          <input
            id={inputId}
            disabled={disabled}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            onFocus={toggleFocus}
            onBlur={toggleFocus}
          />
          <strong>{label}</strong>
          <span>
            <span>Off</span>
            <span>On</span>
            <button disabled={disabled} type="button" onClick={handleChange} />
          </span>
          {error && ` - ${error as string}`}
        </label>
      )}
    </div>
  )
}

export default Switch

import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { path } from 'ramda'
import { PartySearch } from '../../../../containers'
import Select from '../../../atoms/Select/Select'
import TextField from '../../../atoms/TextField/TextField'
import Button from '../../../atoms/Button/Button'
import styles from './EftBeneficiary.module.scss'
import { partyApiSelectors } from '../../../../../modules/api/party'
import CurrencyField from '../../CurrencyField/CurrencyField'
import CurrencyText from '../../../atoms/Typography/CurrencyText/CurrencyText'
import TextInput from 'views/components/atoms/TextField/TextInput'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  state: PropTypes.shape({
    partyId: PropTypes.string,
    name: PropTypes.string,
    partyTag: PropTypes.string,
    reference: PropTypes.string,
  }),
  setState: PropTypes.func,
  onNewBeneficiaryAdded: PropTypes.func.isRequired,
  getDefaultResultSets: PropTypes.func,
  tags: PropTypes.string,
  propertyOwnerName: PropTypes.string,
  unallocatedAmount: PropTypes.number.isRequired,
}

const EftBeneficiary = (props: any) => {
  const {
    partyTags,
    onNewBeneficiaryAdded,
    state,
    setState,
    getDefaultResultSets,
    tags,
    propertyOwnerName,
    unallocatedAmount,
  } = props
  const [nextStep, setNextStep] = useState('partyTag')

  /**
   * Skip straight to party search if partyTags weren't grouped.
   * EG: Municipality: Electricity, Municipality: Sewerage
   */
  useEffect(() => {
    if (partyTags.length === 1) {
      setState({ partyTag: partyTags[0].value })
      setNextStep('partySearch')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const agencyParty = useSelector(partyApiSelectors.getAgencyParty)
  const defaultResultSets = getDefaultResultSets ? getDefaultResultSets(state.partyTag) : []

  // Skip party search if partyTag is 'Agency' and set party to agency
  useEffect(() => {
    if (state.partyTag === 'Agency' && nextStep !== 'amount') {
      if (agencyParty) {
        setState({
          name: path(['companyName'], agencyParty),
          partyId: path(['id'], agencyParty),
        })
        setNextStep('reference')
      }
    }
  }, [state.partyTag])

  const renderNextField = () => {
    if (nextStep === 'partyTag') {
      return (
        <Select
          defaultMenuIsOpen={true}
          onChange={({ value }: any) => {
            setState({ partyTag: value })
            setNextStep('partySearch')
          }}
          name="partyTag"
          placeholder="Select Beneficiary Tag"
          options={partyTags}
        />
      )
    }

    if (nextStep === 'partySearch') {
      return (
        <PartySearch
          name="beneficiary"
          placeholder="Search beneficiaries"
          value={
            state.partyTag === 'Owner' ? (defaultResultSets[0]?.length === 1 ? propertyOwnerName : '') : state.name
          }
          onResultSelect={(data: any) => {
            setState({ partyId: data.id, name: data.text })
            setNextStep('reference')
          }}
          defaultResultSets={defaultResultSets}
          tags={tags}
          focusOnMount={true}
        />
      )
    }

    if (nextStep === 'reference') {
      return (
        <TextField
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              name="reference"
              onChange={({ target }: any) => setState({ reference: target.value })}
              placeholder="Reference"
              ref={(ref: any) => {
                ref?.focus()
              }}
            />
          }
        />
      )
    }

    if (nextStep === 'amount') {
      return (
        <CurrencyField
          name="amount"
          placeholder="Amount to pay beneficiary"
          value={state.amount}
          onChange={({ target }: any) => {
            setState({ amount: target.value || 0 })
          }}
          error={
            state.amount > unallocatedAmount ? (
              <span>
                Can&apos;t exceed unallocated amount of <CurrencyText>{unallocatedAmount}</CurrencyText>
              </span>
            ) : (
              false
            )
          }
        />
      )
    }
  }

  const isStateValid = () => state.partyId && state.partyTag && state.reference && state.amount <= unallocatedAmount

  const buttonClasses = cx({
    [styles['add-beneficiary-button']]: state.amount > unallocatedAmount,
  })

  return (
    <div className={styles.root}>
      <div className={styles.input}>{renderNextField()}</div>

      {nextStep === 'reference' && (
        <Button disabled={!state.reference} secondary size="sm" onClick={() => setNextStep('amount')}>
          Add amount
        </Button>
      )}

      {nextStep === 'amount' && (
        <Button
          disabled={!isStateValid()}
          secondary
          size="sm"
          onClick={() => onNewBeneficiaryAdded({ type: 'PartyBeneficiary', value: state })}
          className={buttonClasses}
        >
          Add Beneficiary
        </Button>
      )}
    </div>
  )
}

EftBeneficiary.propTypes = propTypes

export default EftBeneficiary

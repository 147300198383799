import React from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import FilterIcon from '../../atoms/Icons/Controls/Configure'
import styles from './DataFilters.module.scss'
import Pill from '../../atoms/Pill/Pill'
import DataFiltersMenu from './DataFiltersMenu/DataFiltersMenu'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  // activeFilters: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          isActive: PropTypes.bool,
        }),
      ),
    }),
  ),
  onFiltersChanged: PropTypes.func.isRequired,
}

interface IOption {
  label: string
  isActive: boolean
}

const DataFilters = ({ filters, onFiltersChanged }: $TSFixMe) => {
  const handleFilterSelect = (filterLabel: string, option: IOption) => {
    const filterOption = filters.find((filter: $TSFixMe) => filter.label === filterLabel)
    const newFilter = {
      ...filterOption,
      options: filterOption.options.map((fo: $TSFixMe) => ({
        ...fo,
        isActive: fo.label === option.label ? true : fo.isActive,
      })),
    }
    if (equals(newFilter, filterOption)) {
      handleRemoveFilter(filterLabel, option.label)
    } else {
      const newFilters = filters.map((filter: $TSFixMe) => (filter.label === newFilter.label ? newFilter : filter))
      onFiltersChanged(newFilters)
    }
  }

  const activeFiltersLength = filters.flatMap((filter: $TSFixMe) =>
    filter.options.filter((option: IOption) => option.isActive),
  ).length

  const handleRemoveFilter = (filterLabel: string, optionLabel: string) => {
    const filterOption = filters.find((filter: $TSFixMe) => filter.label === filterLabel)
    const newFilter = {
      ...filterOption,
      options: filterOption.options.map((fo: $TSFixMe) => ({
        ...fo,
        isActive: fo.label === optionLabel ? false : fo.isActive,
      })),
    }
    const newFilters = filters.map((filter: $TSFixMe) => (filter.label === newFilter.label ? newFilter : filter))
    onFiltersChanged(newFilters)
  }

  return (
    <div className={styles.root}>
      <div className={styles['active-filters-container']}>
        <span>Filters: </span>
        {activeFiltersLength > 0 ? (
          <div className={styles['active-filters']}>
            {filters.flatMap((filter: $TSFixMe) =>
              filter.options
                .filter((option: $TSFixMe) => option.isActive)
                .map((option: IOption, index: number) => (
                  <Pill
                    key={`pill-${index}`}
                    className={styles.pill}
                    onRemove={() => handleRemoveFilter(filter.label, option.label)}
                  >
                    {`${filter.label}: ${option.label}`}
                  </Pill>
                )),
            )}
          </div>
        ) : (
          <>
            Click on the filter button <FilterIcon /> to the right to add filters
          </>
        )}
      </div>
      <DataFiltersMenu filters={filters} onFilterSelect={handleFilterSelect} />
    </div>
  )
}

DataFilters.propTypes = propTypes

export default DataFilters

import React from 'react'
import classNames from 'classnames'
import styles from './VoiceUser.module.scss'

type TProps = {
  disabled?: boolean
  children?: React.ReactNode
  onClick?: () => void
  type?: 'default' | 'submit'
}

const VoiceUser = ({ disabled = false, children, onClick = () => {}, type = 'default' }: TProps) => {
  const styleNames = classNames(styles.root, {
    [styles.disabled]: disabled,
  })

  let ComponentTag = 'button'
  const props = { className: styleNames, onClick }

  if (type === 'submit') {
    ComponentTag = 'button'
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{ classNam... Remove this comment to see the full error message
    props.type = 'submit'
  }

  return (
    // @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: any; className: string; onClick:... Remove this comment to see the full error message
    <ComponentTag {...props}>{children}</ComponentTag>
  )
}

export default VoiceUser

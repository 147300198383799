import { createSelector } from 'reselect'
import { get, find } from 'lodash-es'
import { path } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

const state = (state: any): $TSFixMe => state

const walletState = (state: any): $TSFixMe => state.api.wallet

const getPreferredAsset = (balance: any): $TSFixMe => {
  if (!balance) {
    return null
  }
  if (balance.exists) {
    const dummyAsset = balance.assets.find((p: any) => p.name === 'zar_dummy#phoenix.coin')
    if (dummyAsset) {
      return dummyAsset
    }
    const asset = balance.assets.find((p: any) => p.name === 'zar#phoenix.coin')
    if (asset) {
      return asset
    }
  }
  return false
}

export const getPayments = createSelector(walletState, ws => ws.paymentHistory.payments)

export const getPaymentById = createSelector(
  getPayments,
  payments => (id: any) => payments.find((p: any) => p.payment === id),
)

export const walletExists = createSelector(walletState, walletState => (partyId: any) => {
  return get(walletState, ['balances', partyId, 'globalBalance', 'exists'], false)
})

export const getGlobalBalanceAsset = createSelector(walletState, walletState => (partyId: any) => {
  return getPreferredAsset(get(walletState, ['balances', partyId, 'globalBalance'], false))
})

export const getGlobalBalance = createSelector(state, s => (partyId: any) => {
  const asset = getGlobalBalanceAsset(s)(partyId)
  return !asset ? 0 : asset.balance
})

/**
 * A lot of this code below could be simplified and shared
 */
export const getBalanceAsset = createSelector(
  walletState,
  walletState =>
    (partyId: any, accountId: any, accountType = 'Tenant') => {
      const partyBalances = get(walletState, ['balances', partyId, 'accountBalances'], false)
      const asset = accountId
        ? getPreferredAsset(find(partyBalances, (b: any) => b.account === accountId && b.accountType === accountType))
        : false
      return asset
    },
)

export const getBalance = createSelector(state, state => (partyId: any, accountId: any, accountType = 'Tenant') => {
  const asset = getBalanceAsset(state)(partyId, accountId, accountType)
  if (asset === false) {
    return 0
  }
  if (asset === null) {
    return null
  }
  return asset.balance
})

export const getPaymentsByCategory = createSelector(
  state,
  state => (category: any) => getPayments(state).filter((p: any) => p.category === category),
)

export const hasDepositAccount = createSelector(walletState, walletState => (partyId: any, accountId: any) => {
  const partyBalances = get(walletState, ['balances', partyId, 'accountBalances'], false)
  const depositAccount = accountId
    ? getPreferredAsset(find(partyBalances, (b: any) => b.account === accountId && b.accountType === 'Trust'))
    : false
  return !!depositAccount
})

export const getDepositAccountBalance = createSelector(walletState, walletState => (partyId: any, accountId: any) => {
  const partyBalances = get(walletState, ['balances', partyId, 'accountBalances'], false)
  const asset = accountId
    ? getPreferredAsset(find(partyBalances, (b: any) => b.account === accountId && b.accountType === 'Trust'))
    : false

  if (asset === false) {
    return 0
  }
  if (asset === null) {
    return null
  }
  return asset.balance
})

export const getWithdrawalModalStatus = createSelector(
  walletState,
  walletState => () => get(walletState, ['isWithdrawalModalOpen'], false),
)

export const isLoading = createSelector(walletState, ws => (action: any) => path(['loading', action], ws))

/**
 * Bad References
 */
export const getUnallocatedBadReferences = createSelector(
  walletState,
  walletState => walletState.unallocatedBadReferences,
)

export const getAllocatedBadReferences = createSelector(walletState, walletState => walletState.allocatedBadReferences)

export const getReferenceByTxId = createSelector(
  walletState,
  walletState => (txId: any) =>
    [...walletState.unallocatedBadReferences, ...walletState.allocatedBadReferences].find(ref => ref.txId === txId),
)

export const getReferenceType = createSelector(state, state => (txId: any) => {
  const reference = getReferenceByTxId(state)(txId)
  return reference?.allocatedAt ? 'allocated' : 'unallocated'
})

export const getReferenceLookups = createSelector(walletState, ws => ws.referenceLookups)

export const getReferenceLookupByReference = createSelector(
  getReferenceLookups,
  lookups => (reference: any) => path([reference], lookups),
)

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { SegmentsPage, SegmentsProvider } from '..'
import SegmentFocusMode from './SegmentFocusMode/SegmentFocusMode'

const SegmentsRoute = (props: any) => (
  <SegmentsProvider {...props}>
    <SearchFilterProvider location={props.location}>
      <SegmentsPage />
    </SearchFilterProvider>
    <SegmentFocusMode />
  </SegmentsProvider>
)

export default SegmentsRoute

import { connect } from 'react-redux'
import { chatbotSelectors, chatbotEvents } from '../../../modules/ui/ChatBot'
import { PortfolioRentAndFeesForm } from '../../components'
import { portfolioApiSelectors } from '../../../modules/api/portfolio'
import { userApiSelectors } from '../../../modules/api/user'

const mapStateToProps = (state: any) => {
  const initialValues = chatbotSelectors.getLeaseRentAndFeesDialog(state)
  const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)
  return {
    initialValues,
    isActivePortfolio: portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId),
    isReadOnly: userApiSelectors.isReadOnlyRole(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSubmit: (values: any) => {
      dispatch(chatbotEvents.rentValuesSubmitted(values))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioRentAndFeesForm)

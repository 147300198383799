import React from 'react'
import { $TSFixMe } from 'types/ts-migrate'
import { matchPath } from 'react-router-dom'
import { FocusMode } from 'views/components'
import EnableUserForm from './EnableUserForm'
import history from '../../../../state/history'

const EnableUserRoute = ({ match }: $TSFixMe) => {
  const enableUserMatchPath = matchPath(match.url, {
    path: '/enable-user',
    exact: true,
    strict: false,
  })

  return (
    <FocusMode
      isOpen={enableUserMatchPath !== null}
      title="Enable User"
      ContentComponent={EnableUserForm}
      onClose={() => history.goBack()}
    />
  )
}

export default EnableUserRoute

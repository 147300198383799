import React from 'react'
import PropTypes from 'prop-types'
import { SearchInputOverlay } from '../../../../../views/components'
import { propTypes as searchPropTypes } from '../../../../../views/components/molecules/Search/Search'

const SearchSegments = ({ onResultSelect, onSearchChange, resultSets }: any) => {
  return (
    <SearchInputOverlay
      name="search-portfolio-segments"
      placeholder="Search segments..."
      icon="add"
      useSearchComponent={true}
      searchComponentProps={{
        isLoading: false,
        resultSets,
        onResultSelect,
        onSearchChange,
        minCharacters: 0,
      }}
    >
      <h2>Lease Segment</h2>
    </SearchInputOverlay>
  )
}

SearchSegments.propTypes = {
  /**
   * Called when a result is selected.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onResultSelect: PropTypes.func,

  /**
   * Called when search input changes.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onSearchChange: PropTypes.func,

  /**
   * Results:
   * - Group results by arrays i.e. [[{...}, {...}], {...}, {...}]
   * - One group - [[{...}, {...}]]
   */
  resultSets: searchPropTypes.resultSets,
}

export default SearchSegments

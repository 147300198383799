import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { get, pick } from 'lodash-es'
import { FastField, FieldArray, Formik } from 'formik'
import * as yup from 'yup'
import CurrencyField from '../../../../views/components/molecules/CurrencyField/CurrencyField'
import FormField from '../../../../views/components/atoms/FormField/FormField'
import Select from '../../../../views/components/atoms/Select/Select'
import CurrencyText from '../../../../views/components/atoms/Typography/CurrencyText/CurrencyText'
import FormLoader from '../../../../views/components/molecules/FormLoader/FormLoader'
import styles from './CreditNoteForm.module.scss'
import { currencyUtils } from '../../../../utils'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import { reconApiEvents } from '../../../api/recon'
import { $TSFixMe } from 'types/ts-migrate'
import TextField from '../../../../views/components/atoms/TextField/TextField'
import { TextArea, TextInput } from 'views/components'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  invoiceId: PropTypes.string.isRequired,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiaryId: PropTypes.string,
      name: PropTypes.string,
      reference: PropTypes.string,
      balance: PropTypes.number,
    }),
  ),
  creditNoteReasons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  createCreditNote: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

const validationSchema = yup.object().shape({
  reason: yup.string().required(),
  amount: yup
    .number()
    .required()
    .test('amount', function (val) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'parent' does not exist on type 'Validate... Remove this comment to see the full error message
      const { parent } = this.options
      const balance = parent.invoiceBalance
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return val > balance
        ? new yup.ValidationError(
            `can't exceed invoice balance of ${currencyUtils.formatCurrency(balance)}`,
            val,
            'amount',
          )
        : true
    }),
  description: yup.string().test('description', function (description) {
    if (description && description.length > 256) {
      return new yup.ValidationError(`Can't exceed character limit of 256`, description, 'description')
    }
    return true
  }),
  beneficiaries: yup
    .array(
      yup.object().shape({
        beneficiaryId: yup.string(),
        name: yup.string(),
        reference: yup.string(),
        balance: yup.number(),
        amount: yup.mixed().test('amount', "Can't exceed balance", function (val) {
          const {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'parent' does not exist on type 'Validate... Remove this comment to see the full error message
            parent: { balance },
          } = this.options
          const amount = val === undefined ? 0 : parseFloat(val)
          if (amount > balance) {
            return new yup.ValidationError(
              `Can't exceed beneficiary balance of ${currencyUtils.formatCurrency(balance)}`,
              amount,
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'path' does not exist on type 'ValidateOp... Remove this comment to see the full error message
              this.options.path,
            )
          }
          return true
        }),
      }),
    )
    .test('beneficiaries', '', function (val) {
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'parent' does not exist on type 'Validate... Remove this comment to see the full error message
        parent: { amount },
      } = this.options
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const beneficiariesTotal = val.reduce((acc, val) => {
        let amount = parseFloat(val.amount)
        if (amount === undefined || isNaN(amount)) {
          amount = 0
        }
        return acc + amount
      }, 0)
      return beneficiariesTotal > amount
        ? new yup.ValidationError(
            `Beneficiaries allocation can't exceed credit note amount of ${currencyUtils.formatCurrency(amount)}`,
            amount,
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'path' does not exist on type 'ValidateOp... Remove this comment to see the full error message
            this.options.path,
          )
        : true
    }),
})

// eslint-disable-next-line react/display-name
const withButtonContainer =
  ({ submitForm }): $TSFixMe =>
  // eslint-disable-next-line react/display-name
  ({ children }: any): $TSFixMe => {
    // Overwrite child type='submit' prop to type='button'
    // Some strange behaviour where a submit button submits the payment form instead of credit note form :/
    return (
      <FormField>
        {Children.map(children, child => {
          return cloneElement(
            child,
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            null,
            Children.map(child.props.children, grandChild => {
              const props =
                grandChild.props?.type === 'submit'
                  ? {
                      type: 'button',
                      onMouseDown: (e: $TSFixMe) => {
                        e.preventDefault()
                      },
                      onClick: submitForm,
                    }
                  : null
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              return grandChild && cloneElement(grandChild, props)
            }),
          )
        })}
      </FormField>
    )
  }

const CreditNoteForm = ({
  invoiceId,
  creditNoteReasons,
  invoiceBalance,
  beneficiaries,
  createCreditNote,
  isSubmitting,
}: any) => {
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        amount: '',
        reason: '',
        description: '',
        invoiceBalance: invoiceBalance,
        beneficiaries: beneficiaries,
        userUnderstandsRisk: true,
      }}
      onSubmit={values => {
        const data = pick(values, ['amount', 'reason', 'description', 'userUnderstandsRisk', 'beneficiaries'])
        createCreditNote(invoiceId, {
          ...data,
          beneficiaries: data.beneficiaries.map((b: any) => ({
            ...b,
            amount: b.amount || 0,
          })),
        })
      }}
    >
      {({ handleSubmit, setFieldValue, handleChange, errors, submitCount, submitForm }) => {
        const event = reconApiEvents.creditNote_request
        const generalErrors = getGeneralFormErrorsByEvent(event)
        const fieldErrors = getFormFieldErrorsByEvent(event)
        const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

        const formLoaderState = isSubmitting
          ? 'submitting'
          : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
          ? 'error'
          : undefined

        return (
          <div className={styles.root}>
            <FormLoader
              state={formLoaderState}
              ButtonContainer={withButtonContainer({ submitForm })}
              buttonProps={{ children: 'Create credit note' }}
              onSubmit={handleSubmit}
              submittingText="Creating credit note..."
              errorText={hasApiErrors ? 'Oops, something went wrong' : 'Resolve errors'}
              persistErrorMessage={!hasApiErrors}
            >
              <>
                <div className="rbn--row">
                  <div className="rbn--col-sm-4 rbn--col-md-8 rbn--col-lg-8">
                    <FastField name="reason">
                      {({ field }: any) => (
                        <Select
                          {...field}
                          value={creditNoteReasons.find((r: any) => r.value === field.value)}
                          label="Reason"
                          error={get(errors, 'reason')}
                          onChange={(data: any) => setFieldValue('reason', data.value)}
                          options={creditNoteReasons}
                        />
                      )}
                    </FastField>
                  </div>

                  <div className="rbn--col-sm-4 rbn--col-md-8 rbn--col-lg-8">
                    <FastField name="amount">
                      {({ field }: any) => (
                        <CurrencyField
                          {...field}
                          label="Amount"
                          error={get(errors, 'amount')}
                          onChange={(e: any) => setFieldValue('amount', e.target.value)}
                        />
                      )}
                    </FastField>
                  </div>
                </div>

                <div className="rbn--row">
                  <div className="rbn--col">
                    <FastField name="description">
                      {({ field }) => (
                        <TextField
                          className={styles.description}
                          error={get(errors, 'description')}
                          label="Description"
                          inputComponent={
                            // @todo - Figure out a way to use <TextArea /> for this without getting an error
                            <textarea
                              className={styles.description}
                              {...field}
                              error={get(errors, 'description')}
                              label="Description"
                              onChange={handleChange}
                            />
                          }
                        />
                      )}
                    </FastField>
                  </div>
                </div>
              </>
            </FormLoader>
          </div>
        )
      }}
    </Formik>
  )
}

CreditNoteForm.propTypes = propTypes

export default CreditNoteForm

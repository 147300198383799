import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { components } from 'react-select'
import LeftChevronIcon from '../../atoms/Icons/Arrows/ChevronLeft'
import styles from './ContextMenu.module.scss'
import { Checkbox } from '../..'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  data: PropTypes.any, // from react-select
  onOptionClick: PropTypes.func.isRequired,
  onSubmenuOptionClick: PropTypes.func.isRequired,
  activeMenu: PropTypes.shape({
    label: PropTypes.string,
    height: PropTypes.number,
  }).isRequired,
}

const Option = (props: $TSFixMe) => {
  const { label, element } = props.data
  const optionItem = (
    <span className={styles['option-item']}>
      {label}
      {element}
    </span>
  )

  return (
    <span>
      <components.Option {...props}>{optionItem}</components.Option>
    </span>
  )
}

const SubMenuOption = (props: $TSFixMe) => {
  const { data, onOptionClick, onSubmenuOptionClick, activeMenu } = props

  const handleOptionClick = (e: $TSFixMe, label: string) => {
    if (e.target.classList.contains('option')) {
      onOptionClick(e, label)
    }
  }

  const handleSubOption = (option: string) => {
    onSubmenuOptionClick(data.label, option)
  }

  return (data?.subOptions || []).length > 0 ? (
    <>
      <div onClick={e => handleOptionClick(e, data.label)} className="option">
        <LeftChevronIcon /> {data.label}
        {activeMenu.label === data.label && (
          <div className="dropdown-submenu" style={{ top: `${activeMenu.height}px` }}>
            {data.subOptions.map((option: $TSFixMe) => {
              const classes = classNames('sub-option', {
                'sub-option--active': option.isActive,
              })
              return (
                <div key={option.label} className={classes} onClick={() => handleSubOption(option)}>
                  <Checkbox name={option.label} checked={option.isActive} /> {option.label}
                </div>
              )
            })}
          </div>
        )}
      </div>
      <style>{`
        .option {
          display: flex;
          align-items: center;
          padding: 8px 12px 8px 0;
          margin: 0 8px;
          height: 50px;
          border-radius: 4px;
          position: relative;
          cursor: pointer;
        }
        .option:hover,
        .option:focus {
          background-color: #ebebeb;
        }

        .dropdown-submenu {
          position: fixed;
          padding: 8px 0;
          background: white;
          box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          right: 260px;
          min-height: 36px;
          overflow: auto;
          border: none;
          border-radius: 4px;
          color: #212529;
        }

        .sub-option {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px;
          margin: 0 8px;
          width: auto;
          min-width: 250px;
          height: 50px;
          background-color: white;
          color: #5d5d5d;
          border-radius: 4px;
        }
        .sub-option:hover,
        .sub-option:focus,
        .sub-option:active {
          background-color: #ebebeb;
        }

        .sub-option.sub-option--active {
          background-color: #ebebeb;
        }
      `}</style>
    </>
  ) : (
    <Option {...props} />
  )
}

SubMenuOption.propTypes = propTypes

export default SubMenuOption

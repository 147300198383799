import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Loader } from '../..'
import styles from './ListingTemplate.module.scss'

const propTypes = {
  loading: PropTypes.bool,
  Header: PropTypes.node,
  Body: PropTypes.node,
  Footer: PropTypes.node,
}

const ListingTemplate = ({ loading, Header, Body, Footer }: any) => {
  const bodyClasses = useMemo(
    () =>
      cx(styles.body, {
        [styles.loading]: loading,
      }),
    [loading],
  )

  return (
    <div className={styles.root}>
      {Header && <div className={styles.header}>{Header}</div>}
      {Body && <div className={bodyClasses}>{loading ? <Loader /> : Body}</div>}
      {Footer && <div className={styles.footer}>{Footer}</div>}
    </div>
  )
}

ListingTemplate.propTypes = propTypes

export default ListingTemplate

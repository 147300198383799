import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'
// import OutsideClickHandler from 'react-outside-click-handler'
import Close from '../../atoms/Svgs/Close'
import ModalTitle from './ModalTitle'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import styles from './Modal.module.scss'

const propTypes = {
  isOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.oneOf(['sm', 'md', 'lg']),
  padding: PropTypes.oneOf(['sm', 'md', 'lg']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  height: PropTypes.oneOf(['auto', 'full']),
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
}

const defaultProps = {
  theme: 'light',
  width: 'sm',
  padding: 'sm',
  align: 'center',
  height: 'auto',
  showClose: true,
  onClose: () => {},
}

ReactModal.setAppElement('#root')

class Modal extends Component {
  static Title: any
  static Body: any
  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
      isOpen,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'theme' does not exist on type 'Readonly<... Remove this comment to see the full error message
      theme,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
      width,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'padding' does not exist on type 'Readonl... Remove this comment to see the full error message
      padding,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'align' does not exist on type 'Readonly<... Remove this comment to see the full error message
      align,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'height' does not exist on type 'Readonly... Remove this comment to see the full error message
      height,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showClose' does not exist on type 'Reado... Remove this comment to see the full error message
      showClose,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
      onClose,
      children,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
    } = this.props

    const overlayClasses = classNames([`Modal_Align--${align}`])

    const contentClasses = classNames([
      'Modal',
      `Modal_Theme--${theme}`,
      `Modal_Width--${width}`,
      `Modal_Padding--${padding}`,
      `Modal_Height--${height}`,
      className,
    ])

    return (
      <ReactModal
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        isOpen={isOpen}
        className={contentClasses}
        closeTimeoutMS={280}
        overlayClassName={overlayClasses}
      >
        {/* <OutsideClickHandler onOutsideClick={onClose}> */}
        {showClose && (
          <span className={styles.close}>
            <Close width="32px" height="32px" onClick={onClose} />
          </span>
        )}
        {children}
        {/* </OutsideClickHandler> */}
      </ReactModal>
    )
  }
}

Modal.Title = ModalTitle
// @ts-expect-error ts-migrate(2339) FIXME: Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message
Modal.Header = ModalHeader
Modal.Body = ModalBody

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Modal.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Modal.defaultProps = defaultProps

export default Modal

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({
  getUserConfirmation: () => {
    /*
     * Empty callback to block the default browser prompt
     * See `src/views/components/stateful/RouterPrompt.tsx`
     */
  },
})

export default history

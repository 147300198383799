import React, { useCallback } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInMonths } from 'date-fns'
import { getRentAmount } from 'modules/ui/ChatBot/Dialogs/LeaseRentAndFees'
import { partyApiSelectors } from '../../../../api/party'
import { chatbotEvents, chatbotSelectors } from '../../../../ui/ChatBot'
import { portfolioApiSelectors } from '../../../../api/portfolio'
import { formUtils } from '../../../../../utils'
import { Button } from '../../../../../views/components'
import { buildCommissionValidationObject } from '../../../utils/commissionUtils'
import { leaseTypes } from 'modules/api/portfolio/constants'
import { getCommissionInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseCommission'
import { getLeaseTermInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseTerm'
import CommissionSegment from '../CommissionSegment/CommissionSegment'
import styles from './UnmanagedCommissionForm.module.scss'
import { userApiSelectors } from '../../../../api/user'

const validationSchema = yup.object().shape({
  procurementFee: buildCommissionValidationObject('procurementFee', ['once-off'], leaseTypes.unmanaged),
})

const UnmanagedCommissionForm = () => {
  const dispatch = useDispatch()
  const rentAmount = useSelector(getRentAmount)
  const initialValues = useSelector(getCommissionInitialValues)
  const currentPortfolioId = useSelector(chatbotSelectors.getCurrentPortfolioId)
  const { startDate, endDate } = useSelector(getLeaseTermInitialValues)
  const termInMonths = differenceInMonths(new Date(endDate), new Date(startDate)) + 1

  const defaultResultSets = useSelector(state => partyApiSelectors.partiesResultsByTags(state)(['Agent', 'Agency']))
  const isActivePortfolio = useSelector(state => portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId))
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const handleSubmit = useCallback(
    values => {
      dispatch(chatbotEvents.commissionValuesSubmitted(values))
    },
    [dispatch],
  )

  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isReadOnly}>
          <div className={styles.body}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                setFieldTouched,
                setFieldValue,
                handleChange,
                handleBlur,
                submitForm,
                values,
                errors,
                submitCount,
                touched,
              }) => {
                const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

                return (
                  <>
                    <CommissionSegment
                      name="procurementFee"
                      label="Procurement Fee"
                      rentAmount={rentAmount}
                      leaseType={leaseTypes.unmanaged}
                      leaseTermInMonths={termInMonths}
                      values={values.procurementFee}
                      defaultResultSets={[defaultResultSets]}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      getFieldError={getFieldError}
                      errors={errors}
                    />
                    <div className={styles.footer}>
                      <Button type="button" onClick={submitForm}>
                        {isActivePortfolio ? 'Save' : "Done, let's continue"}
                      </Button>
                    </div>
                  </>
                )
              }}
            </Formik>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default UnmanagedCommissionForm

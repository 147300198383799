/**
 * Convert a File object to data url
 *
 * @param {File} file file object
 */
export const toDataUrl = async (file: any) =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const toBase64 = async (file: any): Promise<string> =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    reader.onload = () => resolve(btoa(reader.result))
    reader.onerror = error => reject(error)
  })

export const downloadFile = (url: any, fileName: any) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = fileName
  downloadLink.click()
}

export const downloadBase64 = (base64String: any, fileFormat: any, fileName: any) => {
  const url = `data:${fileFormat};base64,${base64String}`
  downloadFile(url, fileName)
}

/**
 * Prompts a file download from array buffer
 * @param {ArrayBuffer} data Array buffer data
 * @param {string} type `Content-Type` header
 */
export const downloadFileFromArrayBuffer = (data: any, type: any, fileName: any) => {
  const blob = new Blob([data], { type })
  const url = window.URL.createObjectURL(blob)
  downloadFile(url, fileName)
}

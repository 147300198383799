import React, { FC } from 'react'
import { useHover } from 'react-use'
import Tooltip, { Props as TooltipProps } from './Tooltip'

interface Props extends Omit<TooltipProps, 'isOpen' | 'onOuterAction'> {
  toggleOnFocus: boolean
  isFocused: boolean
  children: React.ReactElement
}

const ControlledTooltip: FC<Props> = ({ toggleOnFocus, isFocused, children, ...props }) => {
  const [hoverableChildren, hovered] = useHover(children)

  return (
    <Tooltip isOpen={toggleOnFocus ? isFocused : hovered} {...props}>
      {hoverableChildren}
    </Tooltip>
  )
}

export default ControlledTooltip

import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, mapTo, filter, mergeMap, pluck, ignoreElements } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { round } from 'lodash-es'
import { userApiEvents, userApiSelectors } from '../../api/user'
import { chatbotEvents, chatbotSelectors } from '../../ui/ChatBot'
import { uiEvents } from '../../ui'
import { agencyApiEvents } from '../../api/agency'
import { filterDialogTypes, ofDialogType } from '../../ui/ChatBot/Dialogs/ChatbotDialog'
import { userDetailsDialog } from '../../ui/ChatBot/Dialogs/UserDetails'
import { agencyDetailsDialog } from '../../ui/ChatBot/Dialogs/AgencyDetails'
import { portfolioApiEvents } from '../../api/portfolio'
import { onboardingSelectors } from '..'
import { pathOr } from 'ramda'
import { ROUTES } from '../../../constants/routes'

export const verifyEmail = (action$: any, state$: any) =>
  action$.pipe(ofType(userApiEvents.verifyEmail_success), mapTo(uiEvents.redirect('/settings/profile')))

export const maybeRedirectWhenProfileIsSaved = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.saveDetails_success),
    filter(() => {
      const isProfileVerificationComplete =
        onboardingSelectors.getProfileVerificationStatus(state$.value) === 'complete'
      const isAgencyProfileDetailsComplete = onboardingSelectors.getAgencyDetailsStatus(state$.value) === 'complete'
      return isProfileVerificationComplete && !isAgencyProfileDetailsComplete
    }),
    mapTo(uiEvents.redirect('/settings/business-details')),
    tag('onboarding/epic/maybeRedirectWhenProfileIsSaved'),
  )

export const maybeRedirectWhenBusinessDetailsIsSave = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.createAgency_success),
    filter(() => onboardingSelectors.getAgencyBrandingStatus(state$.value) !== 'complete'),
    mapTo(uiEvents.redirect('/settings/branding')),
    tag('onboarding/epic/maybeRedirectWhenBusinessDetailsIsSave'),
  )

export const submitAgencyDetails = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.agencyDetailsSubmitted),
    pluck('payload'),
    mergeMap(values => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const events: any[] = []
      if (currentAgencyId) {
        events.push(agencyApiEvents.updateAgency_request({ id: currentAgencyId, values }))
      } else {
        events.push(userApiEvents.createAgency_request(values))
      }
      events.push(uiEvents.redirect('/customise'))
      return events
    }),
    tag('onboarding/epic/submitAgencyDetails'),
  )

export const submitBrandingDetails = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.brandingDetailsSubmitted),
    pluck('payload'),
    mergeMap(values => {
      const events = [agencyApiEvents.updateBranding_request(values)]
      const onboardingProgress = userApiSelectors.getOnboardingProgress(state$.value)
      if (onboardingProgress?.propertyProgress < 100) {
        events.push(uiEvents.redirect(ROUTES.dashboard))
      }
      return events
    }),
    tag('onboarding/epic/submitBrandingDetails'),
  )

/**
 * @todo move API request to command to avoid time travel side-effects
 */
export const createAgencyEpic = (action$: any, state$: any) =>
  action$.pipe(
    filterDialogTypes([agencyDetailsDialog]),
    filter(e => {
      const type = pathOr('', ['payload', 'event', 'type'], e)
      // @ts-expect-error
      return type === 'details_submitted'
    }),
    map(_ => {
      const { emailAddress, ...payload } = chatbotSelectors.getAgencyDetailsDialog(state$.value)
      return payload
    }),
    map(payload => {
      const id = userApiSelectors.getCurrentAgencyId(state$.value)
      if (id) {
        return agencyApiEvents.updateAgency_request({ id, values: payload })
      }
      /**
       * @todo impl
       */
      // createAgency.invoke(payload)
      return null
    }),
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    filter(x => x),
    tag('onboarding/epic/createAgencyEpic'),
  )

export const redirectAfterSignup = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.signup_success),
    map(_ => uiEvents.redirect('/dashboard')),
    tag('onboarding/epic/redirectAfterSignup'),
  )

export const redirectAfterUserDetailsSubmitted = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(userDetailsDialog),
    ofType(userDetailsDialog.events.detailsSubmitted),
    map(_ => uiEvents.redirect('/business-details')),
    tag('onboarding/epic/redirectAfterUserDetailsSubmitted'),
  )

export const redirectAfterAgencyDetailsSubmitted = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(agencyDetailsDialog),
    ofType(agencyDetailsDialog.events.detailsSubmitted),
    mergeMap(() => {
      const id = userApiSelectors.getCurrentAgencyId(state$.value)
      return of(agencyApiEvents.agency_request({ id }), uiEvents.redirect('/customise'))
    }),
    tag('onboarding/epic/redirectAfterAgencyDetailsSubmitted'),
  )

export const fetchOnboardingProgressOnLoginOrSignup = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.login_success, userApiEvents.signup_success),
    mapTo(userApiEvents.onboardingProgress_request(null)),
    tag('onboarding/epic/fetchOnboardingProgressOnLoginOrSignup'),
  )

export const emailVerifiedProfileProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.verifyEmail_success),
    map(() => {
      const cellVerified = userApiSelectors.cellVerified(state$.value)
      const progress = cellVerified ? 100 : 50
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        profileVerification: progress,
      })
    }),
    tag('onboarding/epic/emailVerifiedProfileProgress'),
  )

export const validateOtpProfileProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.validateOtp_success),
    map(() => {
      const emailVerified = userApiSelectors.emailVerified(state$.value)
      const progress = emailVerified ? 100 : 50
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        profileVerification: progress,
      })
    }),
    tag('onboarding/epic/validateOtpProfileProgress'),
  )

export const updateVerifiedProfileProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.saveDetails_success),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => {
      const requiredKeys = ['cellVerified', 'emailVerified']
      const complete = requiredKeys.filter(k => !!payload[k]).length
      const progress = round((complete / requiredKeys.length) * 100)
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        profileVerification: progress,
      })
    }),
    tag('onboarding/epic/updateVerifiedProfileProgress'),
    ignoreElements(),
  )

export const updateBusinessDetailsProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.createAgency_success, agencyApiEvents.updateAgency_success),
    map(() => {
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        agencyDetails: 100,
      })
    }),
    tag('onboarding/epic/updateBusinessDetailsProgress'),
  )

export const updateAgencyBrandingProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(agencyApiEvents.updateBranding_request),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => {
      const objKeys = Object.keys(payload)
      const length = objKeys.length
      const complete = objKeys.filter(k => !!payload[k]).length
      const progress = round((complete / length) * 100)
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        agencyBranding: progress,
      })
    }),
    tag('onboarding/epic/updateAgencyBrandingProgress'),
  )

export const updatePropertyProgress = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.approve_success),
    map(() => {
      return userApiEvents.onboardingProgress_success({
        ...userApiSelectors.getOnboardingProgress(state$.value),
        propertyProgress: 100,
      })
    }),
    tag('onboarding/epic/updatePropertyProgress'),
  )

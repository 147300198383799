import React from 'react'
import { BulkImportInvoicesPage, BulkImportInvoicesProvider } from '..'

const BulkImportInvoicesRoute = (props: any) => (
  <BulkImportInvoicesProvider {...props}>
    <BulkImportInvoicesPage />
  </BulkImportInvoicesProvider>
)

export default BulkImportInvoicesRoute

import React from 'react'
import { subscribe } from 'react-contextual'
import { ListingTemplate } from '../../../views/components'
import { useEffectOnce } from 'react-use'
import { useDispatch } from 'react-redux'
import { BadReferencesSearchFilter, BadReferencesTable } from '..'
import BadReferencesProvider from '../BadReferencesProvider'
import { walletApiEvents } from '../../api/wallet'
import { partyApiEvents } from '../../api/party'

function BadReferencesContent({ isLoading }: any) {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(walletApiEvents.unallocatedBadReferences_request())
    dispatch(walletApiEvents.allocatedBadReferences_request())
    // remove once API is depoloyed - temp fetches all parties so mocks can fake data
    dispatch(partyApiEvents.quickSearch_request({ query: '', tags: '' }))
  })

  return <ListingTemplate loading={isLoading} Header={<BadReferencesSearchFilter />} Body={<BadReferencesTable />} />
}

export default subscribe([BadReferencesProvider], ({ isLoading }: any) => ({
  isLoading,
}))(BadReferencesContent)

import { connect } from 'react-redux'
import { hacks } from '../../../utils'
import { propertySearchEvents, propertySearchSelectors } from '../../../modules/ui/PropertySearch'
import { propertyApiEvents, propertyApiSelectors } from '../../../modules/api/property'
import { propertyDetailsDialog } from 'modules/ui/ChatBot/Dialogs/PropertyDetails'
import { Search } from '../../components'
import debounce from 'lodash-es/debounce'
import { $TSFixMe } from 'types/ts-migrate'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: any, { newProperty = false, value = '', isReadonly }: $TSFixMe) => {
  const isLoading = propertySearchSelectors.isSearching(state)
  const name = hacks.generateNameToFoolChromeAutofill('propertySearch-')
  const searchTerm = propertySearchSelectors.getCurrentSearchQuery(state)
  const resultSets = propertyApiSelectors.getSearchResultsByQuery(state)(searchTerm)
  const propertyResults = resultSets[0]
  const googleResults = resultSets[1]
  const placeholder = newProperty
    ? 'Search Google for street address, e.g. 7 Main Rd...'
    : 'Search by unit number, building name or street address...'
  const label = newProperty ? 'Street Address' : ''

  return {
    isLoading,
    name,
    resultSets: newProperty ? [googleResults] : [propertyResults],
    placeholder,
    label,
    value,
    disabled: isReadonly,
  }
}

const debouncedPropertySearch = debounce((dispatch: any, value: any) => {
  dispatch(propertySearchEvents.propertySearchQuery(value))
}, 500)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch, { onChange = () => {}, onResultSelect, value }: any) => ({
  value,

  onSearchChange: (e: any) => {
    onChange(e)
    debouncedPropertySearch(dispatch, e.target.value)
  },

  onResultSelect: (e: any, { result }: any) => {
    if (typeof onResultSelect === 'function') {
      onResultSelect(result)
    } else {
      const { id, placeId } = result
      if (id !== undefined) {
        dispatch(propertyApiEvents.property_request({ id }))
        dispatch(propertyDetailsDialog.proceed((events: any) => events.propertySelected(id)))
      } else if (placeId !== undefined) {
        dispatch(propertyApiEvents.place_request({ placeId }))
        dispatch(propertyDetailsDialog.proceed((events: any) => events.googlePlaceSelected(placeId)))
      }
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)

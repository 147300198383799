import {
  complement,
  curry,
  eqProps,
  filter,
  flatten,
  length,
  map,
  mapObjIndexed,
  not,
  pipe,
  prop,
  propEq,
  props,
  values,
} from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

export const transformFilters = pipe(
  map(props(['value', 'options'])),
  map(([value, options]) =>
    pipe(
      filter(propEq('isActive', true)),
      // @ts-expect-error
      map(({ label }: $TSFixMe) => ({ [value]: label })),
    )(options),
  ),
  flatten,
)

export const hasActiveFilters = (filters: $TSFixMe) => transformFilters(filters).length > 0

export const metaObjMatchesAtLeastOneFilterObj = (transformedFilters: $TSFixMe, row: $TSFixMe) => {
  const matches = mapObjIndexed(
    (rowVal, rowKey, rowObj) =>
      map(
        mapObjIndexed((filterVal, filterKey, filterObj) => eqProps(rowKey, rowObj, filterObj)),
        transformedFilters,
      ),
    prop('meta', row),
  )

  return pipe(values, flatten, map(values), flatten, filter(complement(not)), length)(matches) > 0
}

export const filterData = curry((filters, data): unknown[] => {
  const transformedFilters = transformFilters(filters)
  return hasActiveFilters(filters)
    ? filter(row => metaObjMatchesAtLeastOneFilterObj(transformedFilters, row), data)
    : data
})

import React from 'react'
import styles from './FormErrors.module.scss'

type TProps = {
  errors: string[]
}

const FormErrors = ({ errors }: TProps): null | React.ReactElement => {
  return errors.length === 0 ? null : (
    <p className={styles.errors}>
      {errors.map((e: any, i: any) => (
        <React.Fragment key={i}>
          {e} <br />
        </React.Fragment>
      ))}
    </p>
  )
}

export default FormErrors

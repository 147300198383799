import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DashboardModal } from '@uppy/react'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Uppy from '@uppy/core'
import Button from '../../views/components/atoms/Button/Button'
import styles from './UppyWrapper.module.scss'

import * as Requests from './Requests'

const UppyWrapper = (props: any) => {
  const {
    parentProps: { authToken, documentServiceEndpoint, forOwner },
    onComplete,
  } = props

  const uppyReference = React.useRef(new Uppy({}))

  // Refresh Uppy instance when forOwner changes
  useEffect(() => {
    uppyReference.current = new Uppy({})
  }, [forOwner])

  const [isOpen, setIsOpen] = useState(false)

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const __setIsOpen = (b: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type 'Uppy'.
    uppyReference.current.open = true
    setIsOpen(b)
  }

  useEffect(() => {
    const parentProps = { authToken, documentServiceEndpoint, forOwner }
    const current = uppyReference.current
    if (current.getPlugin('AwsS3Multipart') == null) {
      current.use(AwsS3Multipart, {
        createMultipartUpload: Requests.createMultipartUpload.bind(null, parentProps),
        listParts: Requests.listParts.bind(null, parentProps),
        prepareUploadParts: Requests.prepareUploadParts.bind(null, parentProps),
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(args_0: any, args_1: any) => Promise<null>'... Remove this comment to see the full error message
        abortMultipartUpload: Requests.abortMultipartUpload.bind(null, parentProps),
        completeMultipartUpload: Requests.completeMultipartUpload.bind(null, parentProps),
      })
      current.on('complete', files => {
        // hide upload window if no failures
        setIsOpen(files.failed.length !== 0)
        if (files.failed.length === 0) {
          current.reset()
        }
        onComplete(files)
      })
    }
  }, [authToken, documentServiceEndpoint, onComplete, forOwner, uppyReference])

  useEffect(() => {
    const current = uppyReference.current
    return () => current.close()
  }, [uppyReference])

  return (
    <div className={styles.root}>
      <Button size="sm" onClick={() => __setIsOpen(true)}>
        Upload
      </Button>
      <DashboardModal
        target={'body'}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        inline={false}
        uppy={uppyReference.current}
        closeModalOnClickOutside
        open={isOpen}
        onRequestClose={() => __setIsOpen(false)}
        plugins={[]}
      />
    </div>
  )
}

UppyWrapper.propTypes = {
  forOwner: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }),
  leaseId: PropTypes.string,
  onUploadCompleted: PropTypes.func,
}

export default UppyWrapper

import React, { createContext, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useBulkSelect } from '../../../hooks'

export const TableContext = createContext({})

const TableProvider = ({ context, children }: any) => {
  const [selection, setSelection] = useBulkSelect()
  const [selectAll, setSelectAll] = useState(false)

  useEffectOnce(() => {
    setSelection([])
    setSelectAll(false)
  })

  const toggleAll = (data: any) => {
    const _selection: any = []
    if (selectAll) {
      data.forEach((d: any) => _selection.push(d.id))
    }
    setSelection(_selection)
  }

  const store = {
    selection,
    setSelection,
    selectAll,
    setSelectAll,
    toggleAll,
  }

  return <TableContext.Provider value={store}>{children}</TableContext.Provider>
}

export default TableProvider

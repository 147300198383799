import React from 'react'
import { SupportStaffProvider } from '../../../supportStaff'
import WalletTransferPage from './WalletTransferPage'
import WalletTransferProvider from './WalletTransferProvider'

const WalletTransferRoute = (props: any) => (
  <WalletTransferProvider {...props}>
    <SupportStaffProvider>
      <WalletTransferPage />
    </SupportStaffProvider>
  </WalletTransferProvider>
)
export default WalletTransferRoute

import React from 'react'
// import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/images/logo.... Remove this comment to see the full error message
import logo from '../../../../theme/images/logo.svg'
import { Card, Layout, LayoutHeader, LayoutBody, ForgotPasswordForm } from '../../../components'
import styles from './ForgotPasswordPage.module.scss'

const propTypes = {}

const ForgotPasswordPage = (props: any) => {
  return (
    <div className={styles.root}>
      <Helmet>
        <title>reOS | Forgot password</title>
      </Helmet>
      <Layout>
        <LayoutHeader>
          <div className={styles.header}>
            <img width="96px" src={logo} alt="reOS" />
          </div>
        </LayoutHeader>
        <LayoutBody>
          <div className={styles['form-container']}>
            <Card on style={{ width: '360px' }}>
              <ForgotPasswordForm />
            </Card>
          </div>
        </LayoutBody>
      </Layout>
    </div>
  )
}

ForgotPasswordPage.propTypes = propTypes

export default ForgotPasswordPage

import React, { FC } from 'react'
import cx from 'classnames'
import Select, { Props as SelectProps } from '../../atoms/Select/Select'
import styles from './AccountSelector.module.scss'
import PortfolioStatusLabel from 'modules/portfolios/components/PortfolioStatusLabel'
import { OptionProps } from 'react-select'

export type TAccount = {
  id: string
  label: string
  value: string
  tag: string
  portfolioId?: string
  paymentReference: string
}

interface Props extends SelectProps<TAccount> {}

const CustomOption: FC<OptionProps<TAccount>> = props => {
  const { data, getStyles, innerProps, isDisabled, isSelected, isFocused } = props
  const classes = cx(styles.option, {
    [styles['option--is-selected']]: isSelected,
    [styles['option--is-focused']]: isFocused,
  })

  return !isDisabled ? (
    // @ts-expect-error
    <div {...innerProps} style={getStyles('option', props)} className={classes}>
      <div className={styles.info}>
        <strong>{data.label}</strong> <br />
        {data.tag} &bull; {data.paymentReference}
      </div>
      {data.portfolioId && <PortfolioStatusLabel portfolioId={data.portfolioId} useSummary />}
    </div>
  ) : null
}

const AccountSelector: FC<Props> = ({ name = 'account-selector', options = [], onChange, ...props }) => {
  return (
    <div className={styles.root}>
      <Select {...props} name={name} options={options} components={{ Option: CustomOption }} onChange={onChange} />
    </div>
  )
}

export default AccountSelector

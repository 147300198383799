import decode from 'jwt-decode'
import { getJWTToken, isTokenExpired } from '../../utils/auth'
import { restfulErrorEvent } from '../../utils/restful'
import { userApiEvents } from '../../modules/api/user'

export default (store: any) => (next: any) => (action: any) => {
  if (action.type === restfulErrorEvent.toString()) {
    const authToken = getJWTToken()
    if (authToken) {
      const decodedAuthToken = decode(authToken)
      if (isTokenExpired(decodedAuthToken)) {
        /**
         * @todo emit expired action here
         */
        store.dispatch(userApiEvents.logout_request())
      }
    }
  }
  return next(action)
}

import { connect } from 'react-redux'
import { OnboardingAgencyDetailsForm } from '../../components'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { getAgencyDetailsInitialValues } from '../../../modules/ui/ChatBot/Dialogs/AgencyDetails'

const mapStateToProps = (state: any) => ({
  initialValues: getAgencyDetailsInitialValues(state),
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSubmit: (values: any) => dispatch(chatbotEvents.agencyDetailsSubmitted(values)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingAgencyDetailsForm)

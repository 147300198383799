import { createSelector } from 'reselect'
import { format, addMonths, lastDayOfMonth } from 'date-fns'
import { get } from 'lodash-es'
import { getLeaseTermsDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'
import { chatbotEvents } from '../state'
import { portfolioApiEvents } from '../../../api/portfolio'

const initialState = {
  term: null,
}

export const { leaseTermDialog } = createChatbotDialog('leaseTerm')
  .withInitialState(initialState)
  .withEvents({
    selectLeaseTerms: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: {
        term: get(payload, 'leaseTerms', null),
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendTerms_success]: ({ payload }: any) => ({
      $merge: {
        term: payload.leaseTerms,
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when('selectLeaseTerms', ({ payload }: any) => ({
    $merge: {
      term: payload,
    },
  }))
  .setup()

export const getLeaseTermInitialValues = createSelector(getLeaseTermsDialog, ({ term }) => {
  if (term) {
    return {
      startDate: format(new Date(term.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(term.endDate), 'yyyy-MM-dd'),
      rolloverMonthToMonth: term.rolloverMonthToMonth,
    }
  }

  const now = Date.now()
  return {
    startDate: format(now, 'yyyy-MM-dd'),
    endDate: format(lastDayOfMonth(addMonths(now, 11)), 'yyyy-MM-dd'),
    rolloverMonthToMonth: false,
  }
})

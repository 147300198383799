import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { round } from 'lodash-es'
import CurrencySymbol from '../../atoms/CurrencySymbol/CurrencySymbol'
import TextField from '../../atoms/TextField/TextField'
import VatIcon from '../../atoms/VatIcon/VatIcon'
import ToggleTooltip from '../../molecules/ToggleTooltip/ToggleTooltip'
import styles from './VATControl.module.scss'
import { OnOff, TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  vatApplied: PropTypes.bool.isRequired,
  onToggleChange: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const defaultProps = {
  onToggleChange: () => {},
  onChange: (e: any) => e,
  onClose: () => {},
}

class VATControl extends Component {
  /**
   * @hack - Component is mounted and hidden,
   * so let's implement faux mount logic
   */
  fauxMounted = false

  constructor(props: any) {
    super(props)
    this.calculateVAT = this.calculateVAT.bind(this)
    this.handleToggleChange = this.handleToggleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.renderBody = this.renderBody.bind(this)
  }

  /**
   * @todo dynamically fetch VAT
   * @todo add support for numeral.js shorthands i.e. "1k"
   * */
  calculateVAT(value: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'vatApplied' does not exist on type 'Read... Remove this comment to see the full error message
    const { vatApplied } = this.props
    if (vatApplied) {
      const excluding = round(value / 1.15, 2)
      return {
        excluding,
        including: value,
      }
    } else {
      const including = round(value * 1.15, 2)
      return {
        including,
        excluding: value,
      }
    }
  }

  handleClose() {
    this.fauxMounted = false
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.onClose()
  }

  handleChange(e: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'vatApplied' does not exist on type 'Read... Remove this comment to see the full error message
    const { vatApplied, onChange } = this.props
    const { including, excluding } = this.calculateVAT(e.target.rawValue)

    onChange({
      ...e,
      ...{
        target: {
          ...e.target,
          ...{
            value: vatApplied ? including : excluding,
          },
        },
      },
    })
  }

  handleToggleChange() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'vatApplied' does not exist on type 'Read... Remove this comment to see the full error message
    const { vatApplied, value, onToggleChange } = this.props
    onToggleChange(!vatApplied, this.calculateVAT(value))
  }

  renderBody() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, vatApplied } = this.props
    const { including, excluding } = this.calculateVAT(value)

    const exclStyleNames = classNames(styles.excluding, {
      active: !vatApplied,
    })

    const incStyleNames = classNames(styles.including, {
      active: vatApplied,
    })

    return (
      <React.Fragment>
        <div className={exclStyleNames}>
          <TextField
            noOutline
            type={TextFieldTypes.currency}
            leadingIcon={<CurrencySymbol>R</CurrencySymbol>}
            name="excluding"
            placeholder="0 000.00"
            onChange={this.handleChange}
            value={excluding}
            autoComplete={OnOff.off}
          />
          <small>
            Excluding
            <VatIcon enabled={false} />
          </small>
        </div>
        <div className={incStyleNames}>
          <TextField
            noOutline
            type={TextFieldTypes.currency}
            leadingIcon={<CurrencySymbol>R</CurrencySymbol>}
            name="including"
            placeholder="0 000.00"
            onChange={this.handleChange}
            value={including}
            autoComplete={OnOff.off}
          />
          <small>
            Including
            <VatIcon enabled={true} />
          </small>
        </div>
      </React.Fragment>
    )
  }

  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'vatApplied' does not exist on type 'Read... Remove this comment to see the full error message
    const { vatApplied, children, isOpen } = this.props
    const title = vatApplied ? 'VAT applied' : 'VAT not applied'

    return (
      <ToggleTooltip
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: ReactNode; isOpen: any; onChange... Remove this comment to see the full error message
        isOpen={isOpen}
        onChange={this.handleToggleChange}
        active={vatApplied}
        body={this.renderBody()}
        title={title}
        onClose={this.handleClose}
        style={{ width: '360px' }}
      >
        {children}
      </ToggleTooltip>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
VATControl.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
VATControl.defaultProps = defaultProps

export default VATControl

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTransition, animated } from 'react-spring'
import Label from '../../../atoms/Label/Label'
import styles from './Filters.module.scss'

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      count: PropTypes.number,
      onClick: PropTypes.func,
      hideCount: PropTypes.bool,
    }),
  ),
  activeFilter: PropTypes.number,
  setActiveFilter: PropTypes.func,
}

const defaultProps = {}

const Filters = ({ filters, activeFilter, setActiveFilter }: any): React.ReactElement => {
  const transitions = useTransition(filters, filter => filter.key, {
    config: { mass: 1, tension: 300, friction: 30 },
    from: { transform: 'translate3d(0,-40px,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-40px,0)' },
  })
  return (
    <ul className={styles.filters}>
      {transitions.map(({ item, props, key }, i) => {
        const classes = classNames(styles['filter-item'], {
          [styles.active]: activeFilter === i,
        })

        return (
          <li className={classes} key={key}>
            <animated.div key={key} style={props}>
              <button onClick={setActiveFilter.bind(null, i, item.onClick)}>
                {!item.hideCount && (
                  <Label circular size="sm" active={activeFilter === i}>
                    {item.count}
                  </Label>
                )}
                {item.label}
              </button>
            </animated.div>
          </li>
        )
      })}
    </ul>
  )
}

Filters.propTypes = propTypes
Filters.defaultProps = defaultProps

export default Filters

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pathOr } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'

const sliceName = `${NAMESPACE}Api`

const initialState: $TSFixMe = {
  isAuthenticated: false,
  isRefreshingToken: false,
  authToken: null,
  refreshToken: null,
  hasSessionTimedOut: false,
  userId: null,
  email: '',
  emailVerified: false,
  cellVerified: false,
  firstName: '',
  lastName: '',
  cellNumber: '',
  currentAgencyId: null,
  currentAgencyName: '',
  currentAgencyRole: null,
  agencies: [],
  users: [],
  misc: {},
  onboardingProgress: {},
  otpErrors: [],
  isValidSupportStaff: false,
  team: [],
  currentSegments: [],
  segmentMemberships: [],
  userEnabled: false,
  userDisabled: false,
}

const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    userResumed: (state, { payload }) => {},
    refreshToken_request: (state, { payload }) => {},
    refreshToken_success: (state, { payload }) => {
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
    },
    login_request: (state, { payload }) => {},
    login_success: (state, { payload }) => {
      state.isAuthenticated = true
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
      state.hasSessionTimedOut = false
      state.misc = pathOr({}, ['miscellaneous'], payload)
    },
    logout_request: () => {},
    logout_success: (state, { payload }: $TSFixMe) => {
      state.userId = initialState.userId
      state.firstName = initialState.firstName
      state.lastName = initialState.lastName
      state.cellNumber = initialState.cellNumber
      state.currentAgencyId = initialState.currentAgencyId
      state.email = initialState.email
      state.isAuthenticated = initialState.isAuthenticated
      state.hasSessionTimedOut = pathOr(false, ['timeOut'], payload)
    },
    agencyLogin_request: (state, { payload }) => {},
    agencyLogin_success: (state, { payload }) => {
      state.isAuthenticated = true
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
      state.hasSessionTimedOut = false
    },
    created: (state, { payload }) => {},
    updated: (state, { payload }) => {},
    resetPasswordLink_request: (state, { payload }) => {},
    resetPasswordLink_success: (state, { payload }) => {},
    passwordReset_request: (state, { payload }) => {},
    passwordReset_success: (state, { payload }) => {
      state.isAuthenticated = true
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
      state.hasSessionTimedOut = false
      state.misc = pathOr({}, ['miscellaneous'], payload)
    },
    agencySwitched: (state, { payload }) => {
      state.isAuthenticated = true
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
      state.hasSessionTimedOut = false
    },
    saveDetails_request: (state, { payload }) => {},
    saveDetails_success: (state, { payload }) => ({ ...state, ...payload }),
    currentUserDetails_request: state => {},
    currentUserDetails_success: (state, { payload }) => {
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.idNumber = payload.idNumber
      state.passport = payload.passport
      state.dateOfBirth = payload.dateOfBirth
      state.cellNumber = payload.cellNumber
      state.cellVerified = payload.cellVerified
      state.emailVerified = payload.emailVerified
    },
    userDetails_request: (state, { payload }) => {},
    userDetails_success: (state, { payload }) => {
      state.users.push(payload)
    },
    validateOtp_request: (state, { payload }) => {},
    validateOtp_success: (state, { payload }) => {
      state.cellVerified = true
    },
    signup_request: (state, { payload }) => {},
    signupFromInvite_request: (state, { payload }) => {},
    signup_success: (state, { payload }) => {
      state.isAuthenticated = true
      state.authToken = payload.authToken
      state.refreshToken = payload.refreshToken
      state.hasSessionTimedOut = false
    },
    verifyEmail_request: (state, { payload }) => {},
    verifyEmail_success: (state, { payload }) => {},
    resendEmailVerification_request: (state, { payload }) => {},
    resendEmailVerification_success: (state, { payload }) => {},
    createAgency_request: (state, { payload }) => {},
    createAgency_success: (state, { payload }) => {},
    userAuthTokenDecoded: (state, { payload }) => {
      state.userId = pathOr(null, ['value', 'userId'], payload)
      state.firstName = pathOr('', ['value', 'firstName'], payload)
      state.currentAgencyId = pathOr(null, ['value', 'agencyMembership', 'agencyId'], payload)
      state.currentAgencyName = pathOr('', ['value', 'agencyMembership', 'agencyName'], payload)
      state.currentAgencyRole = pathOr(null, ['value', 'agencyMembership', 'role'], payload)
      state.email = pathOr('', ['value', 'email'], payload)
      state.isValidSupportStaff = pathOr(false, ['value', 'validSupportStaff'], payload)
      state.currentSegments = pathOr([], ['value', 'agencyMembership', 'segment', 'currentSegments'], payload)
      state.segmentMemberships = pathOr([], ['value', 'agencyMembership', 'segment', 'segmentMemberships'], payload)
    },
    userAuthTokenExpired: (state, { payload }) => {},
    updateMisc_request: (state, { payload }) => {},
    updateMisc_success: (state, { payload }) => {
      state.misc = payload
    },
    onboardingProgress_request: (state, { payload }) => {},
    onboardingProgress_success: (state, { payload }) => {
      state.onboardingProgress = payload
    },
    team_request: state => {},
    team_success: (state, { payload }) => {
      state.team = payload
    },
    revokeAccess_request: (state, { payload }) => {},
    revokeAccess_success: {
      reducer: (state, { payload, meta: { userId } }) => {
        state.team = state.team.filter(tm => tm.userId !== userId)
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    addSegmentMembership_request: (state, { payload }) => {},
    addSegmentMembership_success: (state, { payload }) => {
      // there's a slight delay between creating a segment and the segmentId being added to team member on the backend.
      // add to state via params passed through a successful request
      state.team = state.team.map(member =>
        member.userId === payload.userId
          ? {
              ...member,
              segments: [...member.segments, payload.segmentId],
            }
          : member,
      )
    },
    deleteSegmentMembership_request: (state, { payload }) => {},
    deleteSegmentMembership_success: (state, { payload }) => {},
    changePermissions_request: (
      state,
      { payload }: PayloadAction<{ body: { role: string; agencyId: string }; params: { userId: string } }>,
    ) => {},
    changePermissions_success: (
      state,
      { payload }: PayloadAction<{ body: { role: string; agencyId: string }; params: { userId: string } }>,
    ) => {
      state.team = state.team.map((member: { userId: string }) =>
        member.userId === payload.params.userId
          ? {
              ...member,
              role: payload.body.role,
            }
          : member,
      )
    },
    enableUser_request: (state, { payload }) => {},
    enableUser_success: state => {
      state.userEnabled = true
    },
    disableUser_request: (state, { payload }) => {},
    disableUser_success: state => {
      state.userDisabled = true
    },
  },
  extraReducers: builder => {
    /** @todo figure out a better way to use action.toString instead of hard coding strings */
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'Action<... Remove this comment to see the full error message
    builder.addCase('http_error', (state, { payload, meta }) => {
      if (meta.initAction === `${sliceName}/validateOtp_request`) {
        state.otpErrors = payload.response
      }
    })
  },
})

const { reducer, actions: events } = userSlice

export { events, initialState, reducer }

import React from 'react'
import ContactsProvider from '../../state/ContactsProvider'
import { NewContact } from '../..'

const NewContactRoute = (props: any) => (
  <ContactsProvider {...props}>
    <NewContact />
  </ContactsProvider>
)

export default NewContactRoute

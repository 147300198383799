import { of } from 'rxjs'
import { PayloadAction } from '@reduxjs/toolkit'
import { map, mergeMap, pluck } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { integrationsApiEvents } from '.'
import { ENDPOINTS } from './constants'
import { AnyAction } from 'redux'
import { uiEvents } from 'modules/ui'
import { ROUTES } from 'constants/routes'
import {
  TIntegration,
  TIntegrationOauthDetailsResponse,
  TTpnAuthorizeResponse,
  TTpnConsumerEnquiryResponse,
  TTpnConsumerModule,
} from './types'
import { $TSFixMe } from 'types/ts-migrate'
import { setItem } from 'utils/localStorage'

export const apiFetchIntegrations = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(integrationsApiEvents.integrations_request),
    mergeMap((action: { payload: TIntegration[] }) =>
      get(ENDPOINTS.INTEGRATIONS_LIST, state$, action.payload).pipe(
        pluck('response'),
        map(res => integrationsApiEvents.integrations_success(res as TIntegration[])),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiFetchIntegrations'),
  )

export const apiFetchIntegrationOauthDetails = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(integrationsApiEvents.integrationOauthDetails_request),
    mergeMap((action: PayloadAction<{ syncExistingProperties: boolean }>) =>
      get(ENDPOINTS.INTEGRATION_OAUTH_DETAILS, state$, action.payload).pipe(
        pluck('response'),
        mergeMap((res: TIntegrationOauthDetailsResponse) => {
          setItem('state', res.state)
          setItem('syncExistingProperties', action.payload.syncExistingProperties)
          const redirectUrl = `${res.url}&redirect_uri=${window.location.origin}/integrations`
          window.location.href = redirectUrl
          return []
        }),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiFetchIntegrationOauthDetails'),
  )

export const apiActivateIntegration = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(integrationsApiEvents.activateIntegration_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.ACTIVATE_INTEGRATION, state$, action.payload).pipe(
        pluck('response'),
        mergeMap((res: $TSFixMe) => [integrationsApiEvents.activateIntegration_success(res)]),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiActivateIntegration'),
  )
}

export const apiDeactivateIntegration = (action$: any, state$: any, { remove, catchRestError }: any) => {
  return action$.pipe(
    ofType(integrationsApiEvents.deactivateIntegration_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.DEACTIVATE_INTEGRATION, state$, action.payload).pipe(
        pluck('response'),
        map((res: $TSFixMe) => integrationsApiEvents.deactivateIntegration_success(res)),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiDeactivateIntegration'),
  )
}

export const apiTpnAuthorize = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(integrationsApiEvents.tpnAuthorize_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.TPN_AUTHORIZE, state$, action.payload).pipe(
        pluck('response'),
        mergeMap((res: TTpnAuthorizeResponse) => [
          integrationsApiEvents.tpnAuthorize_success({ ...res }),
          uiEvents.redirect(ROUTES.integrations),
          integrationsApiEvents.tpnConsumerCanAccessCredex_request(),
        ]),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiTpnAuthorize'),
  )
}

export const apiTpnConsumerCanAccessCredex = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(integrationsApiEvents.tpnConsumerCanAccessCredex_request),
    mergeMap((action: PayloadAction<{ id: string }>) =>
      get(ENDPOINTS.TPN_CONSUMER_CAN_ACCESS_CREDEX, state$, action.payload).pipe(
        pluck('response'),
        map((res: $TSFixMe) => integrationsApiEvents.tpnConsumerCanAccessCredex_success({ ...res })),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/'),
  )

export const apiTpnConsumerEnquiry = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(integrationsApiEvents.tpnConsumerEnquiry_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.TPN_CONSUMER_ENQUIRY, state$, action.payload).pipe(
        pluck('response'),
        map((res: TTpnConsumerEnquiryResponse) => integrationsApiEvents.tpnConsumerEnquiry_success(res)),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiTpnConsumerEnquiry'),
  )
}

export const apiTpnConsumerEnquiryHistory = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(integrationsApiEvents.tpnConsumerEnquiryHistory_request),
    mergeMap((action: PayloadAction<{ id: string }>) =>
      get(ENDPOINTS.TPN_CONSUMER_ENQUIRY_HISTORY, state$, action.payload).pipe(
        pluck('response'),
        map((res: $TSFixMe) => integrationsApiEvents.tpnConsumerEnquiryHistory_success(res)),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiTpnConsumerEnquiryHistory'),
  )

export const apiTpnConsumerModules = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(integrationsApiEvents.tpnConsumerModules_request),
    mergeMap((action: PayloadAction<{ id: string }>) =>
      get(ENDPOINTS.TPN_CONSUMER_MODULES, state$, action.payload).pipe(
        pluck('response'),
        map(res => integrationsApiEvents.tpnConsumerModules_success(res as TTpnConsumerModule[])),
        catchRestError(action),
      ),
    ),
    tag('integrations/epics/apiTpnConsumerModules'),
  )

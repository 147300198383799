import React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import { DimmerLoader } from '../../../../views/components'
import styles from './BulkImportOptions.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  onCsvDownload: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  file: PropTypes.object,
  isDisabled: PropTypes.bool,
}

const BulkImportOptions = ({ onCsvDownload, onFileUpload, isLoading, isDisabled }: $TSFixMe) => {
  return (
    <div className={styles.root}>
      <div className={styles['download-buttons']}>
        <p>
          Bulk import invoices for utilities from a CSV file.{' '}
          <button onClick={onCsvDownload}>Download a CSV template</button> containing all your active leases, fill in
          the amounts and then upload the file below.
        </p>
        {/* <Button link>
          <DocumentDownloadIcon />
          Download previous import as CSV template
        </Button> */}
      </div>

      <div className={styles.dropzone}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; loading: any; }' is not... Remove this comment to see the full error message */}
        <DimmerLoader loading={isLoading} disabled={isDisabled}>
          <Dropzone onDrop={onFileUpload} multiple={false} style={null} className="drop-zone">
            <p>
              Drag and drop a CSV file here or <button>browse to choose a file</button>
            </p>
          </Dropzone>
        </DimmerLoader>
      </div>
    </div>
  )
}

BulkImportOptions.propTypes = propTypes

export default BulkImportOptions

import Dinero from 'dinero.js'
import { round } from 'lodash-es'

Dinero.defaultCurrency = 'ZAR'
Dinero.globalLocale = 'en-ZA'
Dinero.defaultPrecision = 2

const VAT = 1.15

/**
 * Convert a rounded or decimal amount to cents
 * @param {Number} amount decimal or rounded amount
 * @returns {Object} Dinero
 */
export const toCents = (amount: any) => new Dinero({ amount: round(amount ? amount * 100 : 0) })

/**
 * Convert cents to decimal
 * @param {Number} amount amount in cents
 * @returns {Number} rounded decimal
 */
export const fromCents = (amount: any) => round(amount / 100, 2)

/**
 * Add VAT to an amount in cents
 * @param {Number} amount amount in cents
 * @returns {Object} Dinero
 */
export const addVat = (amount: any) => Dinero({ amount }).multiply(VAT)

/**
 * Subtract VAT from an amount in cents
 * @param {Number} amount amount in cents
 * @returns {Object} Dinero
 */
export const sutractVat = (amount: any) => Dinero({ amount }).divide(VAT)

export const formatCurrency = (cents: any, omitSymbol = false) => {
  const dinero = Dinero({ amount: typeof cents === 'string' ? parseInt(cents) : cents })
  const formatted = omitSymbol ? dinero.toFormat('0,0.00') : dinero.toFormat('$0,0.00')
  return formatted.replace(/(,)/g, ' ')
}

/**
 * @todo fix hacky implementation
 */
export const formatCurrencyRounded = (cents: any, omitSymbol = false) => {
  return formatCurrency(cents, omitSymbol).split(',')[0]
}

export { Dinero }

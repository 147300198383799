import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { ContactsProvider, ContactsPage } from '..'
import CustomerStatement from '../../accounting/components/CustomerStatement/CustomerStatement'
import CustomerDepositStatement from '../../accounting/components/CustomerDepositStatement/CustomerDepositStatement'

const ContactsRoute = (props: any) => (
  <ContactsProvider {...props}>
    <SearchFilterProvider location={props.location}>
      <ContactsPage />
      <CustomerStatement />
      <CustomerDepositStatement />
    </SearchFilterProvider>
  </ContactsProvider>
)

export default ContactsRoute

import React, { useState } from 'react'
import classNames from 'classnames'
import ReactSelect, { GroupBase, Props as SelectProps } from 'react-select'
import styles from './Select.module.scss'

export interface Props<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
  extends SelectProps<Option, IsMulti, Group> {
  className?: string
  label?: string
  name: string
  id?: string
  error?: string | boolean
  noOutline?: boolean
  style?: object
}

type State = {
  isOpen: boolean
}

function Select<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  className,
  id,
  label,
  name,
  isSearchable,
  options = [],
  isDisabled,
  placeholder = 'Select...',
  error,
  style = {},
  noOutline,
  ...props
}: Props<Option, IsMulti, Group>) {
  const _id = id || name

  const [state, setState] = useState<State>({
    isOpen: false,
  })

  const classes = classNames([styles.root, className], {
    [styles['no-outline']]: noOutline,
    [styles['has-error']]: !!error,
    [styles['is-open']]: state.isOpen,
  })

  return (
    <div className={classes} style={style}>
      {label && (
        <label htmlFor={_id}>
          {label}
          {error && ` - ${error as string}`}
        </label>
      )}

      <ReactSelect
        {...props}
        id={_id}
        onMenuClose={() => setState({ ...state, isOpen: false })}
        onMenuOpen={() => setState({ ...state, isOpen: true })}
        name={name}
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        isClearable={false}
        isSearchable={isSearchable}
        classNamePrefix="react-select"
      />
    </div>
  )
}

export default Select

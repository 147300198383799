import { slice } from 'ramda'

export const replaceAt = (string: any, index: number, replace: any) =>
  string.substr(0, index) + replace + string.substr(index + 1)

export const parseQueryString = (query: any) => {
  const string = query[0] === '?' ? query.replace('?', '') : query
  const vars = string.split('&')
  const queryString = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    const key = decodeURIComponent(pair[0])
    const value = decodeURIComponent(pair[1])
    // If first entry with this name
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value)
      // If second entry with this name
    } else if (typeof queryString[key] === 'string') {
      const arr = [queryString[key], decodeURIComponent(value)]
      queryString[key] = arr
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value))
    }
  }
  return queryString
}

export const jsonStringSearch = (searchQuery: any, json: any) => {
  const searchString = typeof json === 'string' ? json : JSON.stringify(json)
  return searchString
    .replace(/\{/g, '')
    .replace(/\}/g, '')
    .replace(/"/g, '')
    .replace(/,/g, ' ')
    .toLowerCase()
    .includes(searchQuery.toLowerCase())
}

export const obfuscate = (string: any, length: any) => `*****${slice(string.length - length, string.length)(string)}`

/**
 * @link https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export const hashCode = (string: any) => {
  let hash = 0
  if (string.length === 0) return hash
  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export const splitCamelCase = (string: any) => {
  return string?.split(/(?=[A-Z])/).join(' ')
}

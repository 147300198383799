import React, { useCallback } from 'react'
import { useSelector, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { userApiEvents, userApiSelectors } from '../api/user'
import { events, selectors } from './state'
import { agencyApiEvents, agencyApiSelectors } from '../api/agency'
import { $TSFixMe } from 'types/ts-migrate'

const SupportStaffProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = useStore()
  const { dispatch } = store

  const currentUserEmail = useSelector(userApiSelectors.getUserEmail)
  const agencyListing: $TSFixMe[] = useSelector(agencyApiSelectors.getAgencyListing)

  const supportStaffStore = {
    isValidSupportStaff: useSelector(userApiSelectors.isValidSupportStaff),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    isAccessAllowed: (emails = []) => emails.includes(currentUserEmail),
    isAgencySelectionModalOpen: useSelector(selectors.isAgencySelectionModalOpen),
    agencyListing: agencyListing.filter(agency => agency.isActive),

    openAgencySelectionModal: useCallback(() => dispatch(events.open()), [dispatch]),
    closeAgencySelectionModal: useCallback(() => dispatch(events.close()), [dispatch]),
    fetchAgencyListing: useCallback(() => dispatch(agencyApiEvents.agencyListing_request()), [dispatch]),
    agencyLogin: useCallback(
      ({ otp, agencyId, agencyName }) => {
        dispatch(userApiEvents.agencyLogin_request({ otp, agencyId, agencyName }))
      },
      [dispatch],
    ),
    ...props,
  }
  return <context.Provider value={supportStaffStore}>{children}</context.Provider>
})

export default SupportStaffProvider

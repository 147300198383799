import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { anyPass, equals, isEmpty, isNil, omit, reject } from 'ramda'
import { subscribe } from 'react-contextual'
import { PartyForm } from '../../../../views/components'
import ContactsProvider from '../../state/ContactsProvider'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import { partyApiEvents } from '../../../api/party'
import { PARTY_TYPES } from 'modules/api/party/constants'
import { userApiSelectors } from '../../../api/user'
import { rejectObjectEmptyOrNullValues } from 'modules/api/party/utils'

const propTypes = {
  createParty: PropTypes.func,
  onSubmit: PropTypes.func,
  allowSearchable: PropTypes.bool,
  partyType: PropTypes.string,
  getButtonText: PropTypes.func,
}

const transformValues = (partyTypeIndex: number, formValues: any) => {
  let values
  const accounts = formValues.accounts || []
  const tags = formValues.tags || []
  const isIdNumberEmpty = formValues.idNumber === ''
  if (partyTypeIndex === PARTY_TYPES.PERSON && isIdNumberEmpty) {
    values = {
      person: omit(['partyType', 'idNumber'], { ...rejectObjectEmptyOrNullValues(formValues), accounts, tags }),
    }
  } else if (partyTypeIndex === PARTY_TYPES.PERSON) {
    values = { person: omit(['partyType'], { ...rejectObjectEmptyOrNullValues(formValues), accounts, tags }) }
  } else {
    values = {
      company: omit(['partyType'], {
        ...rejectObjectEmptyOrNullValues(formValues),
        accounts,
        tags,
      }),
    }
  }
  return values
}

const NewContactForm = ({ createParty, onSubmit, allowSearchable = false, getButtonText }: any) => {
  const _onSubmit = useCallback(
    values => {
      typeof onSubmit === 'function' ? onSubmit(values) : createParty(values)
    },
    [onSubmit, createParty],
  )

  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const isSubmitting = useSelector(state => apiStateSelectors.isLoading(state)([partyApiEvents.createParty_request]))
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  const getGeneralErrors = (payload: any) => getGeneralFormErrorsByEvent(partyApiEvents.createParty_request(payload))
  const getFieldErrors = (payload: any) => getFormFieldErrorsByEvent(partyApiEvents.createParty_request(payload))

  const initialValues = {
    firstName: '',
    lastName: '',
    idNumber: '',
    passport: '',
    dateOfBirth: '',
    emailAddress: '',
    cellNumber: '',
    telNumber: '',
    tags: [],
  }

  return (
    <PartyForm
      contactInitialValues={initialValues}
      partyType={'person'}
      handleSubmit={_onSubmit}
      isSubmitting={isSubmitting}
      getGeneralErrors={getGeneralErrors}
      getFieldErrors={getFieldErrors}
      transformValues={transformValues}
      allowSearchable={allowSearchable}
      getButtonText={getButtonText}
      isDisabled={isReadOnly}
    />
  )
}

NewContactForm.propTypes = propTypes

export default subscribe(ContactsProvider, ({ createParty }: any) => ({
  createParty,
}))(NewContactForm)

import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from '../../../molecules/DatePicker/DatePicker'
import styles from './DateSelectorPanel.module.scss'

const propTypes = {
  label: PropTypes.string,
  onDateChange: PropTypes.func,
}

const defaultProps = {}

const startDate = new Date(Date.now())

const DateSelectorPanel = (props: any) => {
  return (
    <div className={styles.root}>
      <DatePicker inline label={props.label} date={startDate} onChange={props.onDateChange} />
    </div>
  )
}

DateSelectorPanel.propTypes = propTypes
DateSelectorPanel.defaultProps = defaultProps

export default DateSelectorPanel

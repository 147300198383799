import React from 'react'
import { subscribe } from 'react-contextual'
import { get } from 'lodash-es'
import { InvoicesProvider, SearchFilterProvider } from '../../../../providers'
import { BulkActions } from '../../..'
import { useTable } from '../../../organisms/Table'
import Dimmer from '../../../atoms/Dimmer/Dimmer'

const OpenBulkActions = ({
  bulkActionNudgeInvoices,
  bulkActionsApprovePayments,
  activeInvoiceFilters,
  activeFilterIndex,
  userRole,
  isReadOnly,
}: any) => {
  const filterObj = get(activeInvoiceFilters, `[${[activeFilterIndex]}]`, {})
  const filter = get(filterObj, 'key', '')
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selection' does not exist on type '{}'.
  const { selection = [], setSelection } = useTable()

  const nudgeAction = {
    text: 'Nudge',
    count: selection.length,
    danger: true,
    confirmation: {
      title: 'Nudge overdue invoices',
      content: <p>You are about to nudge {selection.length} overdue invoices.</p>,
      onConfirm: () => {
        setSelection([])
        bulkActionNudgeInvoices(selection)
      },
    },
  }

  const approvePaymentsAction = {
    text: 'Approve payments',
    count: selection.length,
    confirmation: {
      title: 'Approve payments',
      content: <p>You are about to approve payments for {selection.length} invoices.</p>,
      onConfirm: () => {
        setSelection([])
        bulkActionsApprovePayments(selection)
      },
    },
  }

  const getActions = () => {
    switch (filter) {
      case 'overdue':
      case 'short':
        return [nudgeAction]
      case 'unallocated':
        return []
      case 'ready':
        return userRole === 'Owner' ? [approvePaymentsAction] : []
      default:
        return []
    }
  }

  return (
    <Dimmer active={isReadOnly}>
      <BulkActions isOpen={selection.length > 0 && getActions().length > 0} actions={getActions()} />
    </Dimmer>
  )
}

export default subscribe(
  [InvoicesProvider, SearchFilterProvider],
  (
    { bulkActionNudgeInvoices, bulkActionsApprovePayments, activeInvoiceFilters, userRole, isReadOnly }: any,
    { activeFilterIndex }: any,
  ) => ({
    bulkActionNudgeInvoices,
    bulkActionsApprovePayments,
    activeInvoiceFilters,
    activeFilterIndex,
    userRole,
    isReadOnly,
  }),
)(OpenBulkActions)

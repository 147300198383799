import React, { useState } from 'react'
import ReactSelect, { components } from 'react-select'
import Options from '../../atoms/Icons/Controls/Options'
import Close from '../../atoms/Icons/Controls/Close'
import styles from './ContextMenu.module.scss'
import cx from 'classnames'
import SubMenuOption from './SubMenuOption'
import { $TSFixMe } from 'types/ts-migrate'

type TProps = {
  name?: string
  items: Array<{
    label: string
    element?: React.ReactNode
    onSelect: Function
    isHidden: boolean
    subMenuOptions?: Array<string>
  }>
  darkMode?: boolean
  dropDownOpenIcon?: React.ReactNode
  dropDownCloseIcon?: React.ReactNode
  position?: string
  showInput?: boolean
  closeMenuOnSelect?: boolean
  handleMenuClose?: Function
  onSubmenuOptionClick?: Function
  hideIfNoOptions?: boolean
}

const ContextMenu = ({
  name,
  items,
  dropDownOpenIcon = <Options />,
  dropDownCloseIcon = <Close />,
  position = 'right',
  darkMode = false,
  showInput = true,
  closeMenuOnSelect = true,
  handleMenuClose,
  onSubmenuOptionClick,
  hideIfNoOptions = false,
}: TProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const customStyles = {
    control: () => ({}),
    dropdownIndicator: () => ({
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({}),
    input: () => ({
      position: 'absolute',
      height: '0px',
      width: '0px',
      opacity: 0,
      cursor: 'default',
    }),
    menu: (provided: any) => ({
      ...provided,
      minWidth: '250px',
      maxHeight: '350px',
      marginTop: '0px',
      padding: showInput ? '34px 0px 4px 0px' : '4px 0px',
      right: position === 'left' ? '0px' : 'auto',
      backgroundColor: darkMode ? '#0a0a0a' : 'white',
      boxShadow: '0 20px 40px 10px rgba(0,0,0,0.10), 0 5px 10px 0 rgba(0,0,0,0.20)',
    }),
    option: (provided, { isDisabled, isFocused, data }: any) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      cursor: !isDisabled ? 'pointer' : 'default',
      margin: '0px 8px',
      width: 'auto',
      height: '50px',
      borderRadius: isFocused ? '5px' : '0px',
      backgroundColor: isFocused ? (darkMode ? '#595959' : '#ebebeb') : darkMode ? '#0a0a0a' : 'white',
      color: darkMode ? '#fafafa' : '#5d5d5d',
      opacity: !isDisabled ? 1 : 0.6,

      ':active': {
        ...provided[':active'],
        backgroundColor: !isDisabled ? (darkMode ? '#a9a9a9' : '#d3d3d3') : data.color,
      },
    }),
    placeholder: () => ({
      display: 'none',
    }),
    singleValue: () => ({
      display: 'none',
    }),
  }

  if (showInput) {
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
    customStyles.input = () => ({})
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'valueContainer' does not exist on type '... Remove this comment to see the full error message
    customStyles.valueContainer = (provided: any) => ({
      ...provided,
      position: 'absolute',
      borderBottom: isMenuOpen ? `1px solid ${darkMode ? '#a9a9a9' : '#d3d3d3'}` : '0px',
      top: '40px',
      left: position === 'left' ? 'auto' : '10px',
      right: position === 'left' ? '10px' : 'auto',
      width: isMenuOpen ? '230px' : '0px',
      height: isMenuOpen ? '30px' : '0px',
      zIndex: 100,
      lineHeight: isMenuOpen ? '15px' : '0px',
      color: darkMode ? '#fafafa' : '#5d5d5d',
    })
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isMenuOpen ? dropDownCloseIcon : dropDownOpenIcon}
      </components.DropdownIndicator>
    )
  }

  const Menu = (props: $TSFixMe) => {
    const classes = cx(styles.caret, styles[`position-${position}`])

    return (
      <>
        <components.Menu {...props}>
          <svg className={classes} width="10" height="5">
            <polyline
              points="0,0 5,5, 10,0"
              style={{
                fill: darkMode ? '#0a0a0a' : 'white',
                strokeWidth: 1,
                stroke: '#efefef',
              }}
            />
          </svg>
          {props.children}
        </components.Menu>
      </>
    )
  }

  const handleChange = (selectedOption: any): void => {
    if (selectedOption.onSelect) {
      selectedOption.onSelect()
    }
  }

  const filteredItems = items.filter((x: any) => !x.isHidden)

  const [activeMenu, setActiveMenu] = useState({
    label: '',
    height: 0,
  })

  const handleOptionClick = (e: $TSFixMe, option: $TSFixMe) => {
    setActiveMenu({
      label: option,
      height: e.target.getBoundingClientRect().top,
    })
  }

  const SubMenuOptionWrapper = (props: $TSFixMe) => {
    return (
      <SubMenuOption
        {...props}
        onOptionClick={handleOptionClick}
        onSubmenuOptionClick={onSubmenuOptionClick}
        activeMenu={activeMenu}
      />
    )
  }

  if (hideIfNoOptions && filteredItems.length === 0) {
    return null
  }

  return (
    <div>
      <ReactSelect
        name={name}
        options={filteredItems}
        classNamePrefix="react-select"
        // @ts-expect-error
        styles={customStyles}
        components={{ DropdownIndicator, Option: SubMenuOptionWrapper, Menu }}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => {
          setActiveMenu({
            label: '',
            height: 0,
          })
          if (handleMenuClose) {
            handleMenuClose()
          }
          setIsMenuOpen(false)
        }}
        onChange={handleChange}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </div>
  )
}

export default ContextMenu

import React from 'react'
import PropTypes from 'prop-types'
import Column from './Column'
import styles from './Grid.module.scss'

const propTypes = {
  flexOptions: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
}

const Grid = ({ flexOptions, style, children }: any) => {
  const classNames = [styles.grid]
  flexOptions?.split(' ').forEach((opt: any) => classNames.push(styles[opt]))

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

Grid.propTypes = propTypes
Grid.Column = Column

export default Grid

import React, { useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { FocusMode } from '../../../../views/components'
import ViewStatementSidebar from './ViewStatementSidebar/ViewStatementSidebar'
import ViewStatementProvider from './ViewStatementProvider'
import ViewStatementContent from './ViewStatementContent/ViewStatementContent'

const ViewStatement = ({ onClose, isOpen, title = 'View Statement' }: any): React.ReactElement => {
  return useMemo(
    () => (
      <FocusMode
        title={title}
        ContentComponent={() => <ViewStatementContent />}
        SidebarComponent={() => <ViewStatementSidebar />}
        isOpen={isOpen}
        onClose={onClose}
      />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [isOpen],
  )
}

export default subscribe([ViewStatementProvider], ({ onClose, isOpen }: any) => ({ onClose, isOpen }))(ViewStatement)

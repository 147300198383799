export type FieldError = object | string | boolean

export enum OnOff {
  on = 'on',
  off = 'off',
}

export enum ErrorPosition {
  top = 'top',
  bottom = 'bottom',
}

export interface ITextField {
  id?: string
  className?: string
  disabled?: boolean
  label?: string | any
  name?: string
  type?: TextFieldTypes
  textarea?: boolean
  value?: number | string
  placeholder?: string
  inputComponent?: any
  leadingIcon?: any
  onLeadingIconClick?: Function
  trailingIcon?: any
  onTrailingIconClick?: Function
  error?: FieldError
  onChange?: Function
  onClick?: Function
  onFocus?: Function
  onBlur?: Function
  autoComplete?: OnOff
  noOutline?: boolean
  setRef?: Function
  dark?: boolean
  allowNegative?: boolean
  errorPosition?: ErrorPosition
}
export interface ICurrencyField {
  value?: number | string
  disabled?: boolean
  leadingIcon?: any
  trailingIcon?: any
  placeholder?: string
  autoComplete?: OnOff
  allowNegative?: boolean
  onChange?: Function
  inputTooltipText?: string
}

export enum TextFieldTypes {
  email = 'email',
  hidden = 'hidden',
  number = 'number',
  password = 'password',
  tel = 'tel',
  text = 'text',
  url = 'url',
  currency = 'currency',
}

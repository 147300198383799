import React from 'react'

const SvgStatement = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M24 26H8a1 1 0 01-1-1V7a1 1 0 011-1h16a1 1 0 011 1v18a1 1 0 01-1 1zm-1-2V8H9v16h14zM12 13h8v2h-8v-2zm0 4h8v2h-8v-2z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgStatement

import { createSlice } from '@reduxjs/toolkit'
import { remove } from 'ramda'

const notificationCentreSlice = createSlice({
  name: 'notificationCentre',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotification: {
      reducer: (state, { payload, meta }) => {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        state.notifications.push({ autoDismiss: meta?.autoDismiss, ...payload })
      },
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(payload: any, { autoDismiss }: any) => { pa... Remove this comment to see the full error message
      prepare: (payload, { autoDismiss = true }) => ({ payload, meta: { autoDismiss } }),
    },
    clearNotification: (state, { payload }) => {
      state.notifications = remove(payload, 1, state.notifications)
    },
  },
})

const { reducer, actions: events } = notificationCentreSlice

export { events, reducer as notificationsReducer }

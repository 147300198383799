import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Formik } from 'formik'
import { useEffectOnce } from 'react-use'
import { isEmpty, pathOr } from 'ramda'
import * as yup from 'yup'
import { useSelector } from 'react-redux'

import {
  Button,
  CleaveField,
  Divider,
  FormField,
  FormLoader,
  OtpVerification,
  Radio,
  RadioGroup,
  Svg,
  TextField,
  TextInput,
} from '../../../../components'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { userApiEvents } from '../../../../../modules/api/user'
import styles from './OnboardingVerificationForm.module.scss'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { identityNumberValidation } from 'utils/yup'
import { $TSFixMe } from 'types/ts-migrate'
import { format, isValid } from 'date-fns'
import PhoneField from 'views/components/atoms/PhoneField/PhoneField'
import { ParsedCountry } from 'react-international-phone'
import { transformTwoDigitYearToFour } from 'utils/date'

const propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  otpErrors: PropTypes.arrayOf(PropTypes.object),
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  isLoading: PropTypes.boolean,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'boolean' does not exist on type 'typeof ... Remove this comment to see the full error message
  isSubmitting: PropTypes.boolean,
  getOtp: PropTypes.func.isRequired,
  validateOtp: PropTypes.func,
  resendEmailVerification: PropTypes.func.isRequired,
  getCurrentUserDetails: PropTypes.func.isRequired,
}

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  lastName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  cellNumber: yup.string().required('required.'),
  dateOfBirth: yup.string().when('passport', {
    is: (val: $TSFixMe) => val && val.length > 0,
    then: yup.string().required('required.'),
  }),
})

const validationSchemaWithSaIdNumber = validationSchema.shape({
  idNumber: identityNumberValidation,
})

const validationSchemaWithPassportNumber = validationSchema.shape({
  passport: yup.string(),
})

const OnboardingVerificationForm = ({
  handleSubmit,
  initialValues,
  otpErrors,
  isLoading,
  isSubmitting,
  getOtp,
  validateOtp,
  resendEmailVerification,
  getCurrentUserDetails,
}: any): React.ReactElement => {
  const [isOtpVerificationOpen, setOtpVerificationOpen] = useState(false)
  const [otpValidationFailed, setOtpValidationFailed] = useState(false)

  const handleFailedOtpValidation = (): void => {
    setOtpVerificationOpen(true)
  }

  const handleOtpResend = (cellNumber: any): void => {
    getOtp(cellNumber)
    setOtpValidationFailed(false)
  }

  const sendOtp = (cellNumber: any): void => {
    getOtp(cellNumber)
    setOtpVerificationOpen(true)
  }

  const handleClose = (): void => setOtpVerificationOpen(false)

  // Effects

  useEffectOnce(() => {
    getCurrentUserDetails()
  })

  useEffect(() => {
    if (initialValues.cellVerified) {
      setOtpVerificationOpen(false)
    }
  }, [initialValues.cellVerified])

  useEffect(() => {
    if (!isEmpty(otpErrors)) {
      setOtpValidationFailed(true)
    }
  }, [initialValues.cellVerified, otpErrors])

  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  const getCurrentIdentificationType = initialValues.passport !== '' ? 'passportNumber' : 'saIdNumber'
  const [stateIdentificationType, setStateIdentificationType] = useState(getCurrentIdentificationType)

  const getValidationSchema = () => {
    return stateIdentificationType === 'saIdNumber'
      ? validationSchemaWithSaIdNumber
      : validationSchemaWithPassportNumber
  }

  return (
    <>
      <Helmet>
        <title>reOS | Settings | Profile</title>
      </Helmet>
      <Formik
        validationSchema={getValidationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, touched, handleChange, setFieldValue, handleBlur, errors, handleSubmit }) => {
          const submitEvent = userApiEvents.saveDetails_request(values)
          const generalErrors = getGeneralFormErrorsByEvent(submitEvent)
          const fieldErrors = getFormFieldErrorsByEvent(submitEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const getFieldValue = (name: any) => pathOr('', [name], values)

          const yy = getFieldValue('idNumber').substring(0, 2)
          const mm = getFieldValue('idNumber').substring(2, 4)
          const dd = getFieldValue('idNumber').substring(4, 6)

          const fourDigitYear = transformTwoDigitYearToFour(yy)
          const dob = new Date(parseInt(fourDigitYear ?? ''), parseInt(mm) - 1, parseInt(dd))
          const dateOfBirthFormatted = isValid(dob) ? format(dob, 'yyyy-MM-dd') : ''

          const [cellDialCode, setCellDialCode] = useState('+27')

          const cellNumber = getFieldValue('cellNumber')
          const cellNumberReplaced = cellNumber.replace(/\s/g, '')

          const formLoaderState = isLoading
            ? 'loading'
            : isSubmitting
            ? 'submitting'
            : !isEmpty(errors) || hasApiErrors
            ? 'error'
            : undefined

          return (
            <>
              <OtpVerification
                isOpen={isOtpVerificationOpen}
                cellNumber={values.cellNumber}
                onSubmit={(otp: any) => validateOtp(otp.pin, values)}
                onTimeOut={handleFailedOtpValidation}
                resend={handleOtpResend.bind(null, values.cellNumber)}
                failed={otpValidationFailed}
                onClose={handleClose}
              />
              <FormLoader onSubmit={handleSubmit} state={formLoaderState} buttonProps={{ children: 'Update' }}>
                <FormErrors errors={generalErrors} />
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="firstName"
                            value={values.firstName}
                            placeholder="e.g. Bruce"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="First Name"
                        error={touched.firstName && errors.firstName}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="lastName"
                            value={values.lastName}
                            placeholder="e.g. Wayne"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Last Name"
                        error={touched.lastName && errors.lastName}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-8">
                    <RadioGroup
                      name="identificationType"
                      className="radio-group"
                      stackRadios={false}
                      label="Identification Type"
                      defaultSelected={stateIdentificationType}
                      onChange={(e: $TSFixMe) => {
                        setStateIdentificationType(e.target.value)
                        if (e.target.value === 'passportNumber') {
                          setFieldValue('idNumber', '')
                          setFieldValue('dateOfBirth', '')
                        } else {
                          setFieldValue('passport', '')
                          setFieldValue('dateOfBirth', '')
                        }
                      }}
                    >
                      <Radio
                        name="saIdNumber"
                        value="saIdNumber"
                        label="South African ID Number"
                        allowUncheck={false}
                      />
                      <Radio
                        name="passportNumber"
                        value="passportNumber"
                        label="Non South African Passport Number"
                        allowUncheck={false}
                      />
                    </RadioGroup>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          stateIdentificationType === 'saIdNumber' ? (
                            <TextInput
                              type={TextFieldTypes.text}
                              name="idNumber"
                              value={values.idNumber}
                              placeholder="e.g. 8605030000080"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          ) : (
                            <TextInput
                              type={TextFieldTypes.text}
                              name="passport"
                              placeholder="e.g. AA123456"
                              value={getFieldValue('passport')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )
                        }
                        label={stateIdentificationType === 'saIdNumber' ? 'ID Number' : 'Passport Number'}
                        error={
                          stateIdentificationType === 'saIdNumber'
                            ? touched.idNumber && errors.idNumber
                            : touched.passport && errors.passport
                        }
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CleaveField
                        name="dateOfBirth"
                        type="date"
                        id="dateOfBirth"
                        label="Date of Birth"
                        placeholder="e.g 1986-05-03"
                        value={
                          stateIdentificationType === 'saIdNumber' && getFieldValue('idNumber').length > 6
                            ? dateOfBirthFormatted
                            : values.dateOfBirth
                        }
                        onChange={handleChange}
                        error={errors.dateOfBirth}
                        disabled={stateIdentificationType === 'saIdNumber'}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-8">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.email}
                            disabled
                            name="emailAddress"
                            value={values.emailAddress}
                            placeholder="e.g. bruce.wayne@gmail.com"
                          />
                        }
                        label="Email Address"
                        trailingIcon={values.emailVerified && <Svg name="tick" width="14px" />}
                      />
                    </FormField>
                  </div>
                </div>

                {!values.emailVerified && (
                  <p>
                    We sent you an email to this address, please click the button in the email to complete the
                    verification process. Haven’t received it yet? &nbsp;
                    <button
                      className={styles.resend}
                      onClick={e => {
                        e.preventDefault()
                        resendEmailVerification()
                      }}
                    >
                      Resend verification.
                    </button>
                  </p>
                )}

                <Divider>
                  <h3>Contact Number Verification</h3>
                </Divider>

                <p>
                  <Svg name="otp-shield" rootStyle={{ float: 'left', marginRight: '20px' }} />
                  <strong>This number will be used to enhance the security of your account.</strong> <br />
                  Once entered, a 6-digit verification code will be sent via <strong>SMS</strong> for confirmation.
                </p>

                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <PhoneField
                            defaultCountry="za"
                            value={
                              cellNumber.includes(cellDialCode)
                                ? cellNumber
                                : [cellDialCode, cellNumberReplaced].join(' ')
                            }
                            onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                              setFieldValue('cellNumber', meta.inputValue)
                              setCellDialCode(meta.country.dialCode)
                            }}
                            disabled={values.cellVerified}
                          />
                        }
                        label="Contact Number"
                        error={touched.cellNumber && errors.cellNumber}
                        trailingIcon={values.cellVerified && <Svg name="tick" width="14px" />}
                      />
                    </FormField>
                  </div>

                  <div className="rbn--col-md-4">
                    <FormField>
                      {!values.cellVerified && (
                        <Button secondary style={{ marginTop: '27px' }} onClick={sendOtp.bind(null, values.cellNumber)}>
                          Send OTP
                        </Button>
                      )}
                    </FormField>
                  </div>
                </div>
                <Divider />
              </FormLoader>
            </>
          )
        }}
      </Formik>
    </>
  )
}

OnboardingVerificationForm.propTypes = propTypes

export default OnboardingVerificationForm

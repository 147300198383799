import React, { useCallback, useMemo, useState } from 'react'
import { map } from 'ramda'
import { AggregateTable, Button, Header, Loader, Select } from '../../../../views/components'
// import PropTypes from 'prop-types'
import styles from './AgenciesTable.module.scss'
import SettingIcon from 'components/atoms/Icons/Controls/Settings'
import { subscribe } from 'react-contextual'
import { SearchFilterProvider } from '../../../../views/providers'
import AgenciesProvider from '../../AgenciesProvider'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import { agencyApiEvents, agencyApiSelectors } from '../../../api/agency'
import { stringUtils } from '../../../../utils'
import ConfirmationDeactivationModal from '../ConfirmationDeactivationModal/ConfirmationDeactivationModal'
import ConfirmationActivationModal from '../ConfirmationActivationModal/ConfirmationActivationModal'
import { $TSFixMe } from 'types/ts-migrate'
import GroupRoyaltiesModal from '../GroupRoyaltiesModal/GroupRoyaltiesModal'

const confirmationStateTypes = {
  activate: 'activate',
  deactivate: 'deactivate',
  groupRoyalties: 'groupRoyalties',
}

const AgenciesTable = ({ searchQuery, agenciesList }: $TSFixMe) => {
  const agencyId = {
    Header: 'Agency ID',
    accessor: 'agencyId',
    style: {
      maxWidth: '700px',
    },
  }

  const companyName = {
    Header: 'Agency Name',
    accessor: 'agencyName',
    style: {
      maxWidth: '250px',
    },
  }

  const tradingAs = {
    Header: 'Trading As',
    accessor: 'tradingAs',
    style: {
      maxWidth: '250px',
    },
  }

  const emailAddress = {
    Header: 'Email Address',
    accessor: 'emailAddress',
    style: {
      maxWidth: '250px',
    },
  }

  const groupRoyaltiesColumn = {
    Header: 'Group Royalties',
    accessor: 'groupRoyalties',
    style: {
      maxWidth: '150px',
      justifyContent: 'flex-end',
    },
  }

  const actionColumn = {
    Header: 'Activation',
    accessor: 'action',
    style: {
      maxWidth: '150px',
      justifyContent: 'flex-end',
    },
  }

  // Previous const columns = [{}]
  const columns = [agencyId, companyName, tradingAs, emailAddress, groupRoyaltiesColumn, actionColumn]

  const intialConfirmationState = {
    type: '',
    isOpen: false,
    agencyName: '',
    agencyId: '',
  }

  const initialGroupRoyaltyConfirmationState = {
    groupRoyaltiesSelection: { label: 'None', value: 'none' },
    isOpen: false,
    agencyId: '',
    agencyName: '',
  }

  const [confirmationState, setConfirmationState] = useState<{
    type: string
    isOpen: boolean
    agencyName: string
    agencyId: string
  }>(intialConfirmationState)

  const [groupRoyaltiesState, setGroupRoyaltiesState] = useState<{
    groupRoyaltiesSelection: { label: string; value: string }
    isOpen: boolean
    agencyId: string
    agencyName: string
  }>(initialGroupRoyaltyConfirmationState)

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([agencyApiEvents.agencyListing_request]))
  const agencyName = useSelector(state => agencyApiSelectors.getAgencyName(state)(agencyId))

  const handleDeactivate = useCallback((agencyId, agencyName) => {
    setConfirmationState({
      type: confirmationStateTypes.deactivate,
      isOpen: true,
      agencyName,
      agencyId,
    })
  }, [])

  const handleActivate = useCallback((agencyId, agencyName) => {
    setConfirmationState({
      type: confirmationStateTypes.activate,
      isOpen: true,
      agencyName,
      agencyId,
    })
  }, [])

  const handleGroupRoyaltiesSelection = useCallback((agencyId, agencyName, selection) => {
    setGroupRoyaltiesState({
      groupRoyaltiesSelection: { label: selection.label, value: selection.value },
      isOpen: true,
      agencyId,
      agencyName,
    })
  }, [])

  const buildRow = (agency: $TSFixMe) => {
    return {
      ...agency,
      groupRoyalties: agency?.isActive && (
        <Select
          key={agency.agencyId}
          className={styles.groupRoyalties}
          name="groupRoyalties"
          value={
            agency.agencyGroupId
              ? { label: agency.agencyGroupName, value: agency.agencyGroupId }
              : initialGroupRoyaltyConfirmationState.groupRoyaltiesSelection
          }
          options={[
            { label: 'None', value: 'none' },
            { label: 'RRF W/CAPE (PTY) LTD', value: 'rawson' },
          ]}
          onChange={(option: $TSFixMe) => handleGroupRoyaltiesSelection(agency.agencyId, agency.agencyName, option)}
        />
      ),
      action: agency?.isActive ? (
        <Button
          onClick={() => handleDeactivate(agency.agencyId, agency.agencyName)}
          className={styles.deactivate}
          size="sm"
        >
          Deactivate
        </Button>
      ) : (
        <Button
          onClick={() => handleActivate(agency.agencyId, agency.agencyName)}
          className={styles.activate}
          size="sm"
        >
          Activate
        </Button>
      ),
    }
  }

  const data = agenciesList

  const filteredData = useMemo(() => {
    return data.filter((agency: $TSFixMe) => stringUtils.jsonStringSearch(searchQuery, agency))
  }, [searchQuery, data])

  const tableData = searchQuery ? map(buildRow, filteredData) : map(buildRow, data)

  return (
    <>
      <div className={styles.root}>
        <Header icon={<SettingIcon />} text="Agencies" />
        {isLoading ? (
          <Loader />
        ) : (
          <AggregateTable rowHeight={80} columns={columns} data={tableData} interactive={false} />
        )}
      </div>
      <ConfirmationDeactivationModal
        isOpen={confirmationState.isOpen && confirmationState.type === confirmationStateTypes.deactivate}
        onClose={() => setConfirmationState(intialConfirmationState)}
        agencyName={confirmationState.agencyName}
        agencyId={confirmationState.agencyId}
      />
      <ConfirmationActivationModal
        isOpen={confirmationState.isOpen && confirmationState.type === confirmationStateTypes.activate}
        onClose={() => setConfirmationState(intialConfirmationState)}
        agencyName={confirmationState.agencyName}
        agencyId={confirmationState.agencyId}
      />
      <GroupRoyaltiesModal
        isOpen={groupRoyaltiesState.isOpen}
        onClose={() => setGroupRoyaltiesState(initialGroupRoyaltyConfirmationState)}
        agencyGroupName={groupRoyaltiesState.groupRoyaltiesSelection.label}
        agencyGroupValue={groupRoyaltiesState.groupRoyaltiesSelection.value}
        agencyId={groupRoyaltiesState.agencyId}
        agencyName={groupRoyaltiesState.agencyName}
      />
    </>
  )
}

AgenciesTable.propTypes = {}

export default subscribe(
  [SearchFilterProvider, AgenciesProvider],
  ({ searchQuery }: $TSFixMe, { agenciesList }: $TSFixMe) => ({
    searchQuery,
    agenciesList,
  }),
)(AgenciesTable)

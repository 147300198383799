import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { map, reduceBy, reject, omit, pipe, filter } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { partyApiEvents, partyApiSelectors } from '../../../../modules/api/party'
import { userApiSelectors } from '../../../../modules/api/user'
import Button from '../../atoms/Button/Button'
import Select from '../../atoms/Select/Select'
import ClearRed from '../../atoms/Svgs/ClearRed'
import EasyPayBeneficiary from './EasyPayBeneficiary/EasyPayBeneficiary'
import EftBeneficiary from './EftBeneficiary/EftBeneficiary'
import styles from './NewBeneficiary.module.scss'

const propTypes = {
  /** callback called when a new beneficiary is added successfully */
  onNewBeneficiaryAdded: PropTypes.func.isRequired,
  /** cancel callback */
  onCancel: PropTypes.func,
  /** party search tags - EG: 'agent, tenant' */
  tags: PropTypes.string,
  /** Function to retrieve default party search results */
  getDefaultResultSets: PropTypes.func,
  /** List of party tags for selection */
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  fetchEasyPayData: PropTypes.func.isRequired,
  easyPayData: PropTypes.object.isRequired,
  propertyOwnerName: PropTypes.string,
  unallocatedAmount: PropTypes.number.isRequired,
}

const defaultProps = {
  onCancel: () => {},
  tags: '',
}

const NewBeneficiary = (props: any) => {
  const {
    onNewBeneficiaryAdded,
    onCancel,
    tags,
    getDefaultResultSets,
    partyTags,
    fetchEasyPayData,
    easyPayData,
    propertyOwnerName,
    unallocatedAmount,
  } = props

  const dispatch = useDispatch()

  const [selection, setSelection] = useState(null)

  const defaultEasyPayState = {
    easyPayReference: '',
    beneficiaryTag: '',
    amount: 0,
  }
  const [easyPayState, setEasyPayState] = useState(defaultEasyPayState)

  /**
   * Fetch agency party if not in state, used to auto select agency on subsequent step.
   * Also fetch on invoice and portfolio epics, but adding as a precaution to ensure the agency party exists in state.
   */
  const currentAgencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const agencyParty = useSelector(partyApiSelectors.getAgencyParty)
  useEffect(() => {
    if (!agencyParty) {
      dispatch(partyApiEvents.party_request(currentAgencyId))
    }
  }, [dispatch, currentAgencyId, agencyParty])

  const defaultEftState = {
    partyId: '',
    partyTag: '',
    name: '',
    reference: '',
    amount: 0,
  }
  const [eftState, setEftState] = useState(defaultEftState)

  const groupedPartyTags = () => {
    const groupTags = (acc: any, tag: any) => acc.concat(tag)
    const toGroup = ({ label }: any) => label.split(/:(.+)/)[0]
    return {
      EasyPay: partyTags,
      ...reduceBy(groupTags, [], toGroup, partyTags),
    }
  }

  const groupedPartyTagsForSelect = () =>
    pipe(
      map((key: any) => ({
        value: key,
        label: key,
      })),
      filter(({ value }: any) => value !== 'Agent'),
    )(Object.keys(groupedPartyTags()))

  const handleCancel = (): void => {
    setSelection(null)
    setEasyPayState(defaultEasyPayState)
    setEftState(defaultEftState)
    onCancel()
  }

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Button link size="sm" onClick={handleCancel}>
          <ClearRed width="26px" height="24px" />
        </Button>

        <div className={styles.input}>
          {!selection ? (
            <Select
              isSearchable
              onChange={(val: any) => setSelection(val.value)}
              name="selection"
              placeholder="Select Beneficiary Type"
              options={groupedPartyTagsForSelect()}
            />
          ) : selection === 'EasyPay' ? (
            <EasyPayBeneficiary
              beneficiaryTagGroups={omit(['EasyPay'], groupedPartyTags())}
              beneficiaryTagGroupsForSelect={reject(
                ({ value }: any) => value === 'EasyPay',
                groupedPartyTagsForSelect(),
              )}
              onNewBeneficiaryAdded={onNewBeneficiaryAdded}
              state={easyPayState}
              setState={val => setEasyPayState({ ...easyPayState, ...val })}
              easyPayData={easyPayData}
              fetchEasyPayData={fetchEasyPayData}
              unallocatedAmount={unallocatedAmount}
            />
          ) : (
            <EftBeneficiary
              partyTags={groupedPartyTags()[selection]}
              onNewBeneficiaryAdded={onNewBeneficiaryAdded}
              state={eftState}
              setState={val => setEftState({ ...eftState, ...val })}
              getDefaultResultSets={getDefaultResultSets}
              tags={tags}
              propertyOwnerName={propertyOwnerName}
              unallocatedAmount={unallocatedAmount}
            />
          )}
        </div>
      </div>
    </div>
  )
}

NewBeneficiary.propTypes = propTypes
NewBeneficiary.defaultProps = defaultProps

export default memo(NewBeneficiary)

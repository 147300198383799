import React, { useMemo, useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { InvoicesProvider } from '../../../providers'
import {
  NoContent,
  Layout,
  LayoutBody,
  LayoutContent,
  LayoutPrimaryPanel,
  LayoutSecondaryPanel,
  LayoutProvider,
} from '../../../components'
import DraftInvoices from './DraftInvoices/DraftInvoices'
import ActiveInvoices from './ActiveInvoices/ActiveInvoices'
import History from './History/History'
import ViewInvoice from './ViewInvoice/ViewInvoice'
import ViewActiveInvoice from './ViewActiveInvoice/ViewActiveInvoice'
import Snapshot from './SecondaryPanel/Snapshot/Snapshot'
import ViewReconciledInvoice from './ViewReconciledInvoice/ViewReconciledInvoice'
import { useEffectOnce } from 'react-use'

const Content = ({ invoiceType }: any) =>
  useMemo(() => {
    switch (invoiceType) {
      case 'drafts':
      case 'new':
        return <DraftInvoices />
      case 'active':
        return <ActiveInvoices />
      case 'history':
        return <History />
      default:
        return (
          <NoContent
            heading="Nothing to see here."
            subHeading="reOS will let you know when you need to check in again."
          />
        )
    }
  }, [invoiceType])

const PrimaryPanel = ({ invoiceType }: any) =>
  useMemo(() => {
    switch (invoiceType) {
      case 'drafts':
        return <ViewInvoice />
      case 'active':
        return <ViewActiveInvoice />
      case 'history':
        return <ViewReconciledInvoice />
      default:
        return null
    }
  }, [invoiceType])

const SecondaryPanel = ({ invoiceType }: any) => {
  switch (invoiceType) {
    case 'new':
    case 'drafts':
      return null
    case 'active':
    case 'history':
      return <Snapshot />
    default:
      return null
  }
}

const InvoicePage = ({ invoiceType, openInvoice, match: { params } }: any) => {
  useEffectOnce(() => {
    if (params.id) {
      openInvoice(params.id, params.type, false)
    }
  })

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="invoices"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: params.id, width: 600 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Invoices</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutSecondaryPanel>
            <SecondaryPanel invoiceType={invoiceType} />
          </LayoutSecondaryPanel>
          <LayoutPrimaryPanel>
            <PrimaryPanel invoiceType={invoiceType} />
          </LayoutPrimaryPanel>
          <LayoutContent>
            <Content invoiceType={invoiceType} />
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(InvoicesProvider, ({ match, invoiceType, openInvoice }: any) => ({
  match,
  invoiceType,
  openInvoice,
}))(InvoicePage)

import React from 'react'
import PropTypes from 'prop-types'
import { useMedia } from 'react-use'
import { useBreakpoints } from '../../../../views/hooks'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../images/mobile-skeleton.p... Remove this comment to see the full error message
import mobileSkeleton from '../../images/mobile-skeleton.png'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../images/tablet-skeleton.p... Remove this comment to see the full error message
import tabletSkeleton from '../../images/tablet-skeleton.png'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../images/desktop-skeleton.... Remove this comment to see the full error message
import desktopSkeleton from '../../images/desktop-skeleton.png'
import styles from './SkeletonChart.module.scss'

const propTypes = {
  onClick: PropTypes.func,
}

const SkeletonChart = ({ onClick }: any) => {
  const { breakpoints } = useBreakpoints()
  const isTablet = useMedia(`(min-width: ${breakpoints.md}px`)
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg}px)`)

  const backgroundImage = isDesktop ? desktopSkeleton : isTablet ? tabletSkeleton : mobileSkeleton

  return (
    <div className={styles.root}>
      <img src={backgroundImage} alt="No chart data" />
    </div>
  )
}

SkeletonChart.propTypes = propTypes

export default SkeletonChart

import React, { FC, ReactElement } from 'react'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import styles from './DatePicker.module.scss'

type TProps = {
  label?: string
  name?: string
  date: any
  inline?: boolean
  onChange: (date: any) => void
  value?: any
  disabled?: boolean
}

const DatePicker: FC<TProps> = ({
  label,
  date,
  onChange = (date: any) => {},
  value,
  disabled = false,
  ...optionsProps
}): ReactElement => {
  const classes = classNames(styles.root, {
    [styles.inline]: !!optionsProps.inline,
  })

  const options = {
    ...optionsProps,
    dateFormat: 'Y-m-d',
  }

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <Flatpickr value={date} onChange={(date: any) => onChange(date[0])} disabled={disabled} options={options} />
    </div>
  )
}

export default DatePicker

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { useSelector } from 'react-redux'
import { Segment, CurrencyField, FundDistribution } from '../../../../../views/components'
import styles from './PaymentAllocation.module.scss'
import { subscribe } from 'react-contextual'
import { customPropTypes } from '../../../../../views/utils'
import BulkImportInvoicesProvider from '../../../state/BulkImportInvoicesProvider'
import { agencyApiSelectors } from 'modules/api/agency'

const propTypes = {
  invoiceType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  amount: PropTypes.string.isRequired,
  onAmountChange: PropTypes.func,
  onVatChange: PropTypes.func,
  amountVatApplied: PropTypes.bool,
  onNewBeneficiaryAdded: PropTypes.func,
  onBeneficiaryRemoved: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onDepositBeneficiaryTransferChange: PropTypes.func.isRequired,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiary: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.shape({
          name: PropTypes.string,
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any, propName: any, componentName: a... Remove this comment to see the full error message
          partyId: PropTypes.oneOfType([customPropTypes.uuid, PropTypes.string]),
          partyTag: PropTypes.string,
          reference: PropTypes.string,
          amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          vat: PropTypes.bool,
        }),
      }),
    }),
  ),
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  propertyOwnerName: PropTypes.string,
}

const defaultProps = {
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  beneficiaries: [],
}

class Amount extends Component {
  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'amount' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { amount, invoiceType, onAmountChange, onVatChange, amountVatApplied } = this.props

    return (
      <Segment horizontal state={'default'}>
        <Segment.Label text={invoiceType} />
        <div className={styles.amount}>
          <CurrencyField
            includeVatControl
            name="amount"
            placeholder="Enter amount"
            onChange={onAmountChange}
            value={amount}
            onToggleChange={onVatChange}
            vatApplied={amountVatApplied}
          />
        </div>
      </Segment>
    )
  }
}

const PaymentAllocation = (props: any) => {
  const {
    invoiceType,
    amount,
    onAmountChange,
    beneficiaries,
    invoiceStatus,
    onNewBeneficiaryAdded,
    onBeneficiaryRemoved,
    onNewBeneficiaryAmountChange,
    onDepositBeneficiaryTransferChange,
    onBeneficiariesOrderChange,
    errors,
    onVatChange,
    amountVatApplied,
    onBeneficiaryVatChange,
    partyTags,
    easyPayData,
    fetchEasyPayData,
    propertyOwnerName,
    ...rest
  } = props

  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)

  return (
    <div className={styles.root}>
      <FundDistribution
        invoiceType={invoiceType}
        headerText="Payment allocation"
        beforeContent={
          <Amount
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            amount={amount}
            invoiceType={invoiceType.name}
            onVatChange={onVatChange}
            onAmountChange={onAmountChange}
            amountVatApplied={amountVatApplied}
          />
        }
        balance={parseFloat(amount)}
        onNewBeneficiaryAdded={onNewBeneficiaryAdded}
        onBeneficiaryRemoved={onBeneficiaryRemoved}
        onNewBeneficiaryAmountChange={onNewBeneficiaryAmountChange}
        onDepositBeneficiaryTransferChange={onDepositBeneficiaryTransferChange}
        onBeneficiariesOrderChange={onBeneficiariesOrderChange}
        invoiceStatus={'Ready'}
        beneficiaries={beneficiaries}
        partySearchTags=""
        partyTags={partyTags}
        errors={errors}
        onVatChange={onBeneficiaryVatChange}
        easyPayData={easyPayData}
        fetchEasyPayData={fetchEasyPayData}
        agencyName={agencyName}
        propertyOwnerName={propertyOwnerName}
        {...rest}
      />
    </div>
  )
}

PaymentAllocation.propTypes = propTypes
PaymentAllocation.defaultProps = defaultProps

export default subscribe([BulkImportInvoicesProvider], ({ fetchEasyPayData, easyPayData, propertyOwnerName }: any) => ({
  fetchEasyPayData,
  easyPayData,
  propertyOwnerName,
}))(PaymentAllocation)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { append, defaultTo, equals, isEmpty, map, pathOr, remove } from 'ramda'
import { Field, FieldArray } from 'formik'
import { round } from 'lodash'
import { portfolioApiConstants } from '../../../../../modules/api/portfolio'
import { PartySearch } from '../../../../containers'
import { FormField, InfoBox, PercentageField, TextField, TextInput } from '../../../../components'
import Segment from '../../../../components/atoms/Segment/Segment'
import CurrencyText from '../../../../components/atoms/Typography/CurrencyText/CurrencyText'
import CurrencyField from '../../../../components/molecules/CurrencyField/CurrencyField'
import DraggableList from '../../../molecules/DraggableList/DraggableList'
import { currencyUtils } from '../../../../../utils'
import { leaseTypes } from 'modules/api/portfolio/constants'
import styles from './CommissionSplit.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const { commissionUndertakings } = portfolioApiConstants

const propTypes = {
  /** Fee name - @todo make dynamic */
  feeName: PropTypes.string.isRequired,
  defaultResultSets: PropTypes.arrayOf(PropTypes.array),
  /** Used to calculate split value over term if unmanaged */
  leaseType: PropTypes.oneOf([leaseTypes.managed, leaseTypes.unmanaged]).isRequired,
  /** Use to calculate unmanaged commission */
  leaseTermInMonths: PropTypes.number,
  /** Formik `setFieldTouched` */
  setFieldTouched: PropTypes.func,
  /** Formik `setFieldValue` */
  setFieldValue: PropTypes.func,
  /** Formik `handleChange` */
  handleChange: PropTypes.func,
  /** Formik `handleBlur` */
  handleBlur: PropTypes.func,
  /** Formik `errors */
  errors: PropTypes.object,
  /** Formik values */
  values: PropTypes.shape({
    type: PropTypes.oneOf([commissionUndertakings.fixed, commissionUndertakings.variable]),
    monthlyRentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    netAmount: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    percentage: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        agent: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        splitPercentage: PropTypes.number,
      }),
    ),
  }),
}

const defaultProps = {}

class CommissionSplit extends Component<$TSFixMe, $TSFixMe> {
  constructor(props: any) {
    super(props)
    this.state = {
      newAgentValue: null,
    }
    this.getRemainingSplit = this.getRemainingSplit.bind(this)
    this.resetNewAgentValue = this.resetNewAgentValue.bind(this)
    this.getCommissionAmount = this.getCommissionAmount.bind(this)
    this.calculatePercentageValue = this.calculatePercentageValue.bind(this)
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !equals(nextProps, this.props) || !equals(nextState, this.state)
  }

  getRemainingSplit() {
    const splitSum = this.props.values.splits.reduce((acc: any, s: any) => {
      return acc + defaultTo(0, parseFloat(s.splitPercentage))
    }, 0)
    return 100 - splitSum
  }

  resetNewAgentValue() {
    this.setState({ newAgentValue: null })
  }

  calculatePercentageValue() {
    const {
      leaseType,
      leaseTermInMonths,
      values: { type, percentage, monthlyRentAmount },
    } = this.props
    return type === commissionUndertakings.variable
      ? leaseType === leaseTypes.unmanaged
        ? monthlyRentAmount * (pathOr(0, ['value'], percentage) / 100) * leaseTermInMonths
        : monthlyRentAmount * (pathOr(0, ['value'], percentage) / 100)
      : 0
  }

  getCommissionAmount(split: any) {
    const {
      values: { type, netAmount, percentage },
    } = this.props
    const percentageValue = this.calculatePercentageValue()
    const commissionAmount =
      type === commissionUndertakings.variable
        ? percentageValue
          ? round((percentageValue * split.splitPercentage) / 100)
          : 0
        : netAmount.value
        ? round((netAmount.value * split.splitPercentage) / 100)
        : 0
    const vatable = type === commissionUndertakings.variable ? percentage?.vatable : netAmount?.vatable
    const netCommission = vatable ? currencyUtils.sutractVat(commissionAmount || 0).getAmount() : commissionAmount
    return !isNaN(netCommission) ? netCommission : 0
  }

  hasAmount = () => {
    const { type, netAmount, percentage } = this.props.values
    return type === portfolioApiConstants.commissionUndertakings.variable ? percentage?.value > 0 : netAmount?.value > 0
  }

  getVatAmount() {
    const {
      values: { type, netAmount, percentage },
    } = this.props
    const percentageValue = this.calculatePercentageValue()
    const grossCommission = type === commissionUndertakings.variable ? round(percentageValue) : round(netAmount.value)
    const vatable = type === commissionUndertakings.variable ? percentage?.vatable : netAmount?.vatable
    const netCommission = vatable ? currencyUtils.sutractVat(grossCommission || 0).getAmount() : grossCommission
    const vatAmount = grossCommission - netCommission
    return !isNaN(vatAmount) ? vatAmount : 0
  }

  handleRemoveRow(index: number) {
    const { feeName, values, setFieldValue } = this.props
    const newSplit = remove(index, 1, values.splits)

    setFieldValue(`${feeName}.splits`, newSplit)
  }

  handleAddRow() {
    const { feeName, values, setFieldValue } = this.props
    const newRow = {
      agent: {
        value: '',
        label: '',
      },
      splitPercentage: this.getRemainingSplit(),
    }

    setFieldValue(`${feeName}.splits`, append(newRow, values.splits))
  }

  render(): null | React.ReactElement {
    const { feeName, defaultResultSets, setFieldTouched, setFieldValue, handleChange, handleBlur, values, errors } =
      this.props

    if (values.splits.length === 0) {
      this.handleAddRow()
    }

    const vatAmount = this.getVatAmount()

    // Exclude existing selected agents from results
    const agents = values.splits.map((s: any) => s.agent.value)
    const filteredResultSets = defaultResultSets.map((resultSet: any) =>
      resultSet.filter((result: any) => !agents.includes(result.id)),
    )

    return (
      <div className={styles.root}>
        {vatAmount > 0 && (
          <Segment>
            <div className={styles.vat}>
              VAT Amount: <CurrencyText>{vatAmount}</CurrencyText>
            </div>
          </Segment>
        )}
        <FieldArray
          name={`${feeName}.splits`}
          render={arrayHelpers => {
            const rows = values.splits.map((s: any, i: any) => {
              const commissionAmount = this.getCommissionAmount(s)
              const blankLabel = { __html: '&nbsp;' }
              const agentName = pathOr('', ['agent', 'label'], s)
              const splitPercentage = pathOr('', ['splitPercentage'], s)

              return {
                itemProps: {
                  disableRemove: values.splits.length === 1,
                },
                children: (
                  <div className="rbn--row" key={i}>
                    <div className="rbn--col-md">
                      <FormField>
                        {!isEmpty(agentName) ? (
                          <TextField
                            inputComponent={
                              <TextInput
                                type={TextFieldTypes.text}
                                name={`${feeName}.splits.${i}.agent`}
                                value={agentName}
                                disabled
                              />
                            }
                            label={i === 0 ? <div dangerouslySetInnerHTML={blankLabel} /> : null}
                          />
                        ) : (
                          <Field
                            name={`${feeName}-add-beneficiary`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            render={({ field, form }: any) => {
                              return (
                                <PartySearch
                                  {...field}
                                  name={`${field.name}-${i}`}
                                  label={i === 0 ? 'Agent' : null}
                                  placeholder="Add Agent"
                                  tags=""
                                  minCharacters={0}
                                  defaultResultSets={filteredResultSets}
                                  onResultSelect={(data: any) => {
                                    setFieldValue(`${feeName}.splits.${i}`, {
                                      splitPercentage,
                                      agent: {
                                        value: data.id,
                                        label: data.text,
                                      },
                                    })
                                  }}
                                  orderByTags="Agent,Agency"
                                />
                              )
                            }}
                          />
                        )}
                      </FormField>
                    </div>
                    <div className="rbn--col-md">
                      <FormField>
                        <PercentageField
                          name={`${feeName}.splits.${i}.splitPercentage`}
                          label={i === 0 ? 'Split' : null}
                          value={s.splitPercentage}
                          onFocus={setFieldTouched}
                          onChange={(e: any) => setFieldValue(`${feeName}.splits.${i}.splitPercentage`, e.floatValue)}
                          handleBlur={handleBlur}
                          max={100} /** @todo add support for number props */
                          onToggleChange={(vatApplied: any) => setFieldValue('percentage.vatable', vatApplied)}
                          vatApplied={values?.percentage?.vatable || false}
                          includeVatCalculator={false}
                        />
                      </FormField>
                    </div>
                    <div className="rbn--col-md">
                      <FormField>
                        <CurrencyField
                          includeVatCalculator={false}
                          disabled
                          name={`${feeName}.splits.${i}.commission`}
                          label={i === 0 ? 'Commission' : null}
                          value={commissionAmount}
                          onChange={(e: any) => setFieldValue(e.target.name, e.target.value)}
                          onToggleChange={(vatApplied: any) => setFieldValue('netAmount.vatable', vatApplied)}
                          vatApplied={values?.netAmount?.vatable || false}
                        />
                      </FormField>
                    </div>
                  </div>
                ),
              }
            })

            const allowNewRow = this.getRemainingSplit() > 0 ? () => this.handleAddRow() : false

            return (
              this.hasAmount() && (
                <>
                  {!isEmpty(errors) && (
                    <InfoBox type="error">
                      {Array.isArray(errors) ? map(err => <span>{err}</span>, errors) : errors}
                    </InfoBox>
                  )}
                  <DraggableList
                    items={rows}
                    itemStyle={{ height: '90px' }}
                    onRemove={e => this.handleRemoveRow(e)}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message
                    onAdd={allowNewRow}
                  />
                </>
              )
            )
          }}
        />
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CommissionSplit.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CommissionSplit.defaultProps = defaultProps

export default CommissionSplit

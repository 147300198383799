import { connect } from 'react-redux'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { layoutEvents } from '../../../modules/Layout/state'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ['Lease activated! boom boom', 'What would you like to do next?'],

  actions: [
    {
      text: 'Create another lease',
      action: (dispatch: any) => dispatch(chatbotEvents.newPortfolioRequested()),
    },
    {
      text: 'Take me home',
      action: (dispatch: any) => {
        dispatch(chatbotEvents.portfolioRedirect('/dashboard'))
        dispatch(layoutEvents.openSidebar('global'))
      },
    },
  ],
})

const mapDisptchToProps = (d: any) => ({
  actionClickHandler: (action: any) => action(d),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { parseQueryString } from '../../../utils/string'
import { userApiSelectors } from '../../../modules/api/user'
// import { uiEvents } from '../../../modules/ui'
import { uiEvents } from '../../../modules/ui'
import { LoginPage } from '../../components'
import { $TSFixMe } from 'types/ts-migrate'

const mapStateToProps = (state: any, { location: { search = '' } }): $TSFixMe => {
  const parsedQueryString = parseQueryString(search)
  const passwordReset = get(parsedQueryString, 'reset', false)
  const isAuthenticated = userApiSelectors.isAuthenticated(state)
  const hasSessionTimedOut = userApiSelectors.hasSessionTimedOut(state)

  return { passwordReset, isAuthenticated, hasSessionTimedOut }
}

const mapDispatchToProps = (dispatch: any): $TSFixMe => ({
  redirect: (path: any) => dispatch(uiEvents.redirect(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)

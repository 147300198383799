import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { filter, map, path, pipe, sum } from 'ramda'
import { Button, ManagementFeeForm } from '../../../../components'
import styles from './CommissionForm.module.scss'
import { formatCurrency } from 'utils/currency'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  rentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultResultSets: PropTypes.arrayOf(PropTypes.array),
  isActivePortfolio: PropTypes.bool,
  portfolioRentInvoice: PropTypes.object,
}

const defaultProps = {}

// @ts-expect-error
const getSplitPercentageTotal = pipe(map(path(['splitPercentage'])), sum)

const validationSchema = yup.object().shape({
  managementFee: yup.object().shape({
    type: yup.string().required('required.'),
    monthlyRentAmount: yup.number().required('required.'),
    /** @todo implement conditional validation i.e. netAmount|percentage  */
    // netAmount: yup.object().shape({
    //   value: yup.string().required('required.')
    //   // vatable: yup.boolean()
    // }),
    splits: yup
      .array()
      .of(
        yup.object().shape({
          agent: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
          }),
          split: yup.number(),
        }),
      )
      .test('managementFee.splits', function (splits: $TSFixMe) {
        const commAmount = this.parent.managementFee?.netAmount?.value
        const commPercentage = this.parent.managementFee?.percentage?.value
        const splitPercentageTotal = getSplitPercentageTotal(splits)
        // @ts-expect-error
        if (splitPercentageTotal > 100) {
          return this.createError({
            path: this.path,
            message: "Commission splits can't exceed a total of 100%",
          })
        }
        // @ts-expect-error
        if (splitPercentageTotal < 100) {
          return this.createError({
            path: this.path,
            message: 'Commission splits must add up to 100%',
          })
        }
        const noPercentageSplits = filter((split: any) => !split.splitPercentage, splits)
        if (noPercentageSplits.length > 0 && (commAmount > 0 || commPercentage > 0)) {
          return this.createError({
            path: this.path,
            message: 'All commission splits must have a value',
          })
        }
        const noAgentSplits = filter((split: any) => !split.agent.value || !split.agent.label, splits)
        if (noAgentSplits.length > 0 && (commAmount > 0 || commPercentage > 0)) {
          return this.createError({
            path: this.path,
            message: 'All commission splits must have an agent',
          })
        }
        const agentIds = splits.map((split: $TSFixMe) => split.agent.value)
        const hasDuplicateAgents = new Set(agentIds).size !== agentIds.length
        if (hasDuplicateAgents) {
          return this.createError({
            path: this.path,
            message: 'Duplicate agents are not allowed',
          })
        }
        return true
      }),
    netAmount: yup.object().test('managementFee.netAmount', function (val) {
      const { monthlyRentAmount, paymentRulesTotal = 0, paymentRulesTotalExCommission = 0 } = this.parent
      if (paymentRulesTotal > monthlyRentAmount) {
        return this.createError({
          path: this.path,
          message: `Commission cannot exceed ${formatCurrency(
            monthlyRentAmount - paymentRulesTotalExCommission,
          )}, the remaining funds after rental payments`,
        })
      }
      return true
    }),
    percentage: yup.object().test('managementFee.percentage', function (val) {
      const { monthlyRentAmount, paymentRulesTotal = 0, paymentRulesTotalExCommission = 0 } = this.parent
      if (paymentRulesTotal > monthlyRentAmount) {
        return this.createError({
          path: this.path,
          message: `Commission cannot exceed ${formatCurrency(
            monthlyRentAmount - paymentRulesTotalExCommission,
          )}, the remaining funds after rental payments`,
        })
      }
      return true
    }),
  }),
  procurementFee: yup.object().shape({
    netAmount: yup.object().test('procurementFee.netAmount', function () {
      const { monthlyRentAmount, paymentRulesTotal = 0, paymentRulesTotalExCommission = 0 } = this.parent
      if (paymentRulesTotal > monthlyRentAmount) {
        return this.createError({
          path: this.path,
          message: `Commission cannot exceed ${formatCurrency(
            monthlyRentAmount - paymentRulesTotalExCommission,
          )}, the remaining funds after rental payments`,
        })
      }
      return true
    }),
    splits: yup
      .array()
      .of(
        yup.object().shape({
          agent: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
          }),
          split: yup.number(),
        }),
      )
      .test('procurementFee.splits', function (splits: $TSFixMe) {
        const commAmount = this.parent.procurementFee?.netAmount?.value
        const commPercentage = this.parent.procurementFee?.percentage?.value
        const splitPercentageTotal: $TSFixMe = getSplitPercentageTotal(splits)
        if (splitPercentageTotal > 100) {
          return this.createError({
            path: this.path,
            message: "Commission splits can't exceed a total of 100%",
          })
        }
        const noPercentageSplits = filter((split: $TSFixMe) => !split.splitPercentage, splits)
        if (noPercentageSplits.length > 0 && (commAmount > 0 || commPercentage > 0)) {
          return this.createError({
            path: this.path,
            message: 'All commission splits must have a value',
          })
        }
        const noAgentSplits = filter((split: $TSFixMe) => !split.agent.value || !split.agent.label, splits)
        if (noAgentSplits.length > 0 && (commAmount > 0 || commPercentage > 0)) {
          return this.createError({
            path: this.path,
            message: 'All commission splits must have an agent',
          })
        }
        return true
      }),
  }),
})

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  validationSchema,
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.handleSubmit(values)
  },
})
class CommissionForm extends Component<$TSFixMe, $TSFixMe> {
  render(): null | React.ReactElement {
    const {
      handleSubmit,
      rentAmount,
      defaultResultSets,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      isActivePortfolio,
      submitCount,
      portfolioRentInvoice,
      isReadOnly,
    } = this.props

    return (
      <div className={styles.root}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <fieldset disabled={isReadOnly}>
            <div className={styles.body}>
              <ManagementFeeForm
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                values={values.managementFee}
                procurementValues={values.procurementFee}
                rentAmount={rentAmount}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
                touched={touched}
                defaultResultSets={defaultResultSets}
                submitCount={submitCount}
                portfolioRentInvoice={portfolioRentInvoice}
              />
            </div>
            <div className={styles.footer}>
              <Button type="submit">{isActivePortfolio ? 'Save' : "Done, let's continue"}</Button>
            </div>
          </fieldset>
        </form>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CommissionForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CommissionForm.defaultProps = defaultProps

export default CommissionForm

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { uiEvents } from '../ui'

const ReportingProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props

  const reportingStore = {
    match,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    ...props,
  }
  return <context.Provider value={reportingStore}>{children}</context.Provider>
})

export default ReportingProvider

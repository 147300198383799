import { createSelector } from 'reselect'
import { get } from 'lodash-es'

const state = (state: any) => state

export const layoutState = (state: any) => state.layout

export const getLayoutByKey = createSelector(layoutState, ls => (key: any) => get(ls, [key]))

export const getSidebar = createSelector(layoutState, ls => (key: any) => get(ls, [key, 'sidebar']))

export const getPrimaryPanel = createSelector(layoutState, ls => (key: any) => get(ls, [key, 'primaryPanel']))

export const getSecondaryPanel = createSelector(layoutState, ls => (key: any) => get(ls, [key, 'secondaryPanel']))

export const isSidebarOpen = createSelector(state, s => (key: any) => get(getSidebar(s)(key), 'open'))

export const isPrimaryPanelOpen = createSelector(state, s => (key: any) => get(getPrimaryPanel(s)(key), 'open'))

export const isSecondaryPanelOpen = createSelector(state, s => (key: any) => get(getSecondaryPanel(s)(key), 'open'))

const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'communications'

const BASE = `${REACT_APP_API_URL}/emails`

export const ENDPOINTS = {
  FETCH_EMAILS: `${BASE}`,
  RESEND_EMAIL: `${REACT_APP_API_URL}/email/:id/resend`,
}

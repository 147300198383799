import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { $TSFixMe } from 'types/ts-migrate'
import { agencyApiSelectors } from '../api/agency'
import { uiEvents } from '../ui'

const AgenciesProvider = moduleContext()(({ context, children, ...props }: $TSFixMe) => {
  // const state = store.getState()

  const dispatch = useDispatch()

  const { match } = props

  const agenciesList = useSelector(agencyApiSelectors.getAgencyListing)

  const agenciesStore = {
    match,
    redirect: (path: string) => dispatch(uiEvents.redirect(path)),
    agenciesList,
    ...props,
  }
  return <context.Provider value={agenciesStore}>{children}</context.Provider>
})

export default AgenciesProvider

import { createSelector } from 'reselect'
import { find, get } from 'lodash-es'
import { state, getPropertyDetailsDialog, getCurrentPortfolioId } from '../selectors'
import { propertyApiSelectors } from '../../../api/property'
import { createChatbotDialog } from './ChatbotDialog'
import { portfolioApiEvents } from '../../../api/portfolio'
import { chatbotEvents } from '../state'
import { searchPlaceDetails } from 'modules/api/property/state'

const initialState = {
  selectedProperty: null,
  selectedPlace: null,
  buildingName: '',
  unitNumber: '',
}

export const { propertyDetailsDialog } = createChatbotDialog('propertyDetails')
  .withInitialState(initialState)
  .withEvents({
    googlePlaceSelected: (placeId, buildingName, unitNumber) => ({
      placeId,
      buildingName,
      unitNumber,
    }),
    propertySelected: (id, buildingName, unitNumber) => ({
      id,
      buildingName,
      unitNumber,
    }),
    propertyConfirmed: () => ({}),
    clearSelection: () => ({}),
  })
  .reduce({
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendProperty_success]: ({ payload: { propertyId, buildingName, unitNumber } }: any) => ({
      $merge: {
        selectedProperty: propertyId,
        selectedPlace: null,
        buildingName,
        unitNumber,
      },
    }),
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: {
        selectedProperty: get(payload, 'propertyId', null),
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when(['googlePlaceSelected', 'clearSelection'], ({ payload: { id, placeId, buildingName, unitNumber } }: any) => ({
    $merge: {
      selectedProperty: id !== undefined ? id : null,
      selectedPlace: placeId !== undefined && id === undefined ? placeId : null,
      buildingName,
      unitNumber,
    },
  }))
  .when('propertySelected', ({ payload: { id, buildingName, unitNumber } }: any) => ({
    $merge: {
      selectedProperty: id,
      selectedPlace: null,
      buildingName,
      unitNumber,
    },
  }))
  .setup()

export const getSelectedPlace = createSelector(getPropertyDetailsDialog, dialog => dialog.selectedPlace)
export const getSelectedProperty = createSelector(getPropertyDetailsDialog, dialog => dialog.selectedProperty)
export const getBuildingName = createSelector(getPropertyDetailsDialog, dialog => dialog.buildingName)
export const getUnitNumber = createSelector(getPropertyDetailsDialog, dialog => dialog.unitNumber)

export const isPlaceSelected = createSelector(getSelectedPlace, place => !!place)
export const isPropertySelected = createSelector(getPropertyDetailsDialog, dialog => !!dialog.selectedProperty)

export const getPropertyInitialValues = createSelector(state, s => {
  const dialog = getPropertyDetailsDialog(s)
  const empty = {
    buildingName: '',
    unitNumber: '',
    streetAddress: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
  }

  const placeDetails = propertyApiSelectors.getGooglePlaceById(s)(dialog.selectedPlace)
  const fullProperty = propertyApiSelectors.getPropertyById(s)(dialog.selectedProperty)

  const values = (details: any) => {
    const streetNumber = searchPlaceDetails(details, 'StreetNumber')
    const streetName = searchPlaceDetails(details, 'StreetName')
    const sublocality = searchPlaceDetails(details, 'Sublocality')
    const streetAddress = `${streetNumber} ${streetName}, ${sublocality}`
    const city = searchPlaceDetails(details, 'Locality')
    const province = searchPlaceDetails(details, 'AdministrativeArea', (v: any) => v.level === 1)
    const postalCode = searchPlaceDetails(details, 'PostalCode')
    const country = searchPlaceDetails(details, 'Country')
    const buildingName = searchPlaceDetails(details, 'BuildingName') || getBuildingName(s)
    const unitNumber = searchPlaceDetails(details, 'UnitNumber') || getUnitNumber(s)

    return {
      buildingName,
      unitNumber,
      streetAddress,
      city,
      province,
      postalCode,
      country,
    }
  }

  if (placeDetails !== undefined) {
    return values(placeDetails)
  }

  if (fullProperty !== undefined) {
    return values(fullProperty)
  }

  return empty
})

export const getChatbotSelector = createSelector(
  state,
  getPropertyDetailsDialog,
  (s, { selectedProperty, selectedPlace }) => {
    const portfolioId = getCurrentPortfolioId(s)
    if (!selectedProperty && !selectedPlace && !portfolioId) {
      return {
        messages: ['Property address'],
        actions: [],
      }
    }
    return {
      actions: [],
      messages: ['Does this address look good?'],
    }
  },
)

import { ofType } from 'redux-observable'
import { map, debounceTime, mergeMap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { partyApiEvents, partyApiSelectors } from '../../api/party'
import { contactsEvents } from './'
import { uiEvents } from 'modules/ui'
import { adhocInvoiceEvents } from 'modules/adhocInvoice/state'

export const searchPartiesOnSearchQueryChange = (action$: any, state$: any) =>
  action$.pipe(
    ofType(contactsEvents.searchQueryUpdated),
    debounceTime(400),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => payload),
    map(query => partyApiEvents.quickSearch_request({ query })),
    tag('module/contacts/searchPartiesOnSearchQueryChange'),
  )

export const openDepositTopupRequestModal = (action$: any, state$: any) =>
  action$.pipe(
    ofType(contactsEvents.depositTopupRequestOpened),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => {
      const { partyId, portfolioId } = payload
      const state = state$.value
      const partyName = partyApiSelectors.getPartyNameById(state)(partyId)
      return {
        isDepositInvoice: true,
        customer: {
          id: partyId,
          text: partyName,
          partyTag: 'Tenant',
        },
        portfolioId,
        invoiceType: {
          label: 'Deposit Top Up',
          value: 'DepositTopUp',
        },
        dueDate: '',
        vat: false,
        amount: '',
        beneficiaries: [
          {
            beneficiary: {
              type: 'DepositBeneficiary',
              value: {
                partyId,
                reference: `${partyName} Deposit Top Up`,
                beneficiaryTag: 'TenantDepositAccount',
                amount: '',
                vat: false,
                transfer: true,
              },
            },
          },
        ],
      }
    }),
    mergeMap(initialValues => [
      uiEvents.redirect('/invoices/new'),
      adhocInvoiceEvents.initialValuesUpdateRequested(initialValues),
    ]),
    tag('module/contacts/openDepositTopupRequestModal'),
  )

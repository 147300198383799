import React, { useCallback } from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { apiStateSelectors } from '../api/apiState'
import { userApiSelectors } from '../api/user'
import { uiEvents } from '../ui'
import { communicationsApiEvents, communicationsApiSelectors } from './state'

const CommunicationsProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props

  const communicationsStore = {
    match,
    redirect: useCallback(path => dispatch(uiEvents.redirect(path)), [dispatch]),

    // Selectors
    userRole: useSelector(state => userApiSelectors.getUserRole(state)),
    isReadOnly: useSelector(userApiSelectors.isReadOnlyRole),
    emailFilters: useSelector(state => communicationsApiSelectors.getEmailFilters(state)),
    emailPeriod: useSelector(state => communicationsApiSelectors.getCommunicationsPeriod(state)),
    isLoading: useSelector(state => apiStateSelectors.isLoading(state)([communicationsApiEvents.emails_request])),
    getPeriod: useSelector(state => communicationsApiSelectors.getCommunicationsPeriod(state)),
    getEmails: useSelector(state => communicationsApiSelectors.getCommunicationsEmails(state)),

    // API dispatch
    fetchEmails: useCallback(
      (startDate, endDate) =>
        dispatch(communicationsApiEvents.emails_request({ start_date: startDate, end_date: endDate })),
      [dispatch],
    ),
    resendEmail: useCallback(
      (id, toEmailAddresses) =>
        dispatch(communicationsApiEvents.resend_request({ params: { id }, body: { toEmailAddresses } })),
      [dispatch],
    ),
    ...props,
  }

  return <context.Provider value={communicationsStore}>{children}</context.Provider>
})

export default CommunicationsProvider

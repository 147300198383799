import React from 'react'

export enum PortfolioWizardStepNames {
  property = 'property',
  applications = 'applications',
  tenants = 'tenants',
  owners = 'owners',
  lease = 'lease',
  rent = 'rent',
  settings = 'settings',
  commission = 'commission',
  invoices = 'invoices',
  renewal = 'renewal',
  termination = 'termination',
  activation = 'activation',
}

export type PortfolioWizardStep = {
  name: PortfolioWizardStepNames
  dependencies: PortfolioWizardStepNames[]
  route: string
  label: string
  activeWhen?: string[]
  hasCheckmark: boolean
  progress: number
  Icon: React.FC
  isVisible: boolean
}

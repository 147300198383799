import React, { useEffect } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { walletApiEvents, walletApiSelectors } from '../../../../../../modules/api/wallet'

const propTypes = {
  accountId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
}

const ContactBalanceProvider = moduleContext()(({ context, children, ...props }: any) => {
  const { accountId, customerId, accountType } = props
  const dispatch = useDispatch()

  // Fetch party balances
  useEffect(() => {
    dispatch(walletApiEvents.balance_request({ partyId: customerId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  const store = {
    balance: useSelector(state => walletApiSelectors.getBalance(state)(customerId, accountId, accountType)),
  }

  return <context.Provider value={store}>{children}</context.Provider>
})

ContactBalanceProvider.propTypes = propTypes

export default ContactBalanceProvider

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Image, Transformation } from 'cloudinary-react'
import AvatarEditor from 'react-avatar-editor'
import Slider from 'rc-slider'
import { round } from 'lodash-es'
import classNames from 'classnames'
import { Button, Svg, LinearProgress } from '../../../../../components'
import styles from './LogoEditor.module.scss'

const propTypes = {
  uploadProgress: PropTypes.number,
  uploadedFileCloudinaryUrl: PropTypes.string,
  uploadedFile: PropTypes.object,
  editing: PropTypes.bool,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  cropZoom: PropTypes.number,
  cloudinaryUploadPreset: PropTypes.string,
  onPositionChange: PropTypes.func,
  onImageDrop: PropTypes.func,
  onEdit: PropTypes.func,
  onclear: PropTypes.func,
  onSave: PropTypes.func,
  onZoomChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  error: PropTypes.string,
}

const defaultProps = {
  onPositionChange: ({ x, y }: any) => {},
  onImageDrop: (files: any) => {},
  onEdit: () => {},
  onClear: () => {},
  onSave: (editor: any, setFieldValue: any) => {},
  onZoomChange: (zoom: any) => {},
}

class LogoEditor extends Component {
  editor: any
  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'uploadProgress' does not exist on type '... Remove this comment to see the full error message
      uploadProgress,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'uploadedFileCloudinaryUrl' does not exis... Remove this comment to see the full error message
      uploadedFileCloudinaryUrl,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'uploadedFile' does not exist on type 'Re... Remove this comment to see the full error message
      uploadedFile,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'editing' does not exist on type 'Readonl... Remove this comment to see the full error message
      editing,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'position' does not exist on type 'Readon... Remove this comment to see the full error message
      position,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cropZoom' does not exist on type 'Readon... Remove this comment to see the full error message
      cropZoom,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cloudinaryUploadPreset' does not exist o... Remove this comment to see the full error message
      cloudinaryUploadPreset,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onPositionChange' does not exist on type... Remove this comment to see the full error message
      onPositionChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onImageDrop' does not exist on type 'Rea... Remove this comment to see the full error message
      onImageDrop,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onEdit' does not exist on type 'Readonly... Remove this comment to see the full error message
      onEdit,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClear' does not exist on type 'Readonl... Remove this comment to see the full error message
      onClear,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSave' does not exist on type 'Readonly... Remove this comment to see the full error message
      onSave,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onZoomChange' does not exist on type 'Re... Remove this comment to see the full error message
      onZoomChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFieldValue' does not exist on type 'R... Remove this comment to see the full error message
      setFieldValue,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
      error,
    } = this.props

    const classes = classNames(styles.root, {
      [styles['has-errors']]: !!error,
    })

    return (
      <div className={classes}>
        <div className={styles['image-container']}>
          {uploadProgress ? (
            <div className={styles['progress-wrapper']}>
              <div className={styles['upload-progress']}>
                <LinearProgress
                  percent={uploadProgress}
                  strokeColor="#000000"
                  trailColor="rgba(0, 0, 0, 0.1)"
                  strokeWidth={1.2}
                />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {uploadedFileCloudinaryUrl && !editing ? (
                <div className={styles['saved-pic']}>
                  <Image cloudName={cloudinaryUploadPreset} publicId={uploadedFileCloudinaryUrl}>
                    <Transformation width="400" height="400" gravity="faces" crop="fill" />
                    <Transformation width="400" height="400" gravity="faces" crop="scale" />
                  </Image>
                </div>
              ) : (
                <div className={styles['file-upload']}>
                  {uploadedFile ? (
                    <AvatarEditor
                      ref={(editor: any) => {
                        this.editor = editor
                      }}
                      image={uploadedFile.preview}
                      width={400}
                      height={400}
                      border={0}
                      position={position}
                      scale={cropZoom}
                      rotate={0}
                      onPositionChange={onPositionChange}
                    />
                  ) : (
                    <Dropzone onDrop={onImageDrop} multiple={false} accept="image/*" style={null} className="drop-zone">
                      <Svg name="image-icon" />
                      <p>
                        <strong>Upload your logo.</strong>
                        <br />
                        Drag and drop a file here
                      </p>
                      <Button>Choose a file</Button>
                    </Dropzone>
                  )}
                </div>
              )}
            </React.Fragment>
          )}

          <div className={styles['context-info']}>
            {uploadProgress ? (
              <p className={styles['uploading-text']}>Uploading</p>
            ) : (
              <React.Fragment>
                {uploadedFileCloudinaryUrl && !editing ? (
                  <div className={styles.edit}>
                    <Button link onClick={onEdit}>
                      Edit
                    </Button>
                  </div>
                ) : (
                  <React.Fragment>
                    {uploadedFile ? (
                      <React.Fragment>
                        <Slider value={round(cropZoom * 25, 0)} onChange={onZoomChange} />
                        <div className={styles['crop-actions']}>
                          <Button link onClick={onClear}>
                            Clear
                          </Button>
                          <Button link onClick={() => onSave(this.editor, setFieldValue)}>
                            Save
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <p>
                        Your image should be a minimum of 400 x 400 px. For best results trying using a similar image to
                        a Facebook profile picture.
                      </p>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
LogoEditor.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
LogoEditor.defaultProps = defaultProps

export default LogoEditor

import { prop } from 'ramda'

export const transformTeamMemberForSearch = (member: any) => ({
  id: prop('userId', member),
  text: `${prop('firstName', member)} ${prop('lastName', member)}`,
  secondaryText: prop('emailAddress', member),
})

export const transformPortfolioSummaryForSearch = (summary: any) => ({
  id: prop('portfolioId', summary),
  text: prop('mainText', summary),
  secondaryText: prop('secondaryText', summary),
})

export const transformTeamMemberForTable = (member: any) => ({
  id: member.userId,
  name: `${member.firstName} ${member.lastName}`,
  lastLoggedIn: member.lastLoggedIn,
  email: member.emailAddress,
})

export const transformPortfolioFormTable = (summary: any) => ({
  id: summary.portfolioId,

  propertyAddress: {
    mainText: summary.mainText,
    secondaryText: summary.secondaryText,
  },

  leaseDetails: {
    rent: '',
    term: summary?.leaseTerms ? `${summary.leaseTerms.startDate} -> ${summary.leaseTerms.endDate}` : '-',
  },
})

const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'user'

export const ENDPOINTS = {
  GET_CURRENT_USER: `${REACT_APP_API_URL}/agency/users/current`,
  REGISTER: `${REACT_APP_API_URL}/user`,
  REGISTER_FROM_INVITE: `${REACT_APP_API_URL}/user/register_from_invite`,
  LOGIN: `${REACT_APP_API_URL}/user/login`,
  AGENCY_LOGIN: `${REACT_APP_API_URL}/user/login_to_agency`,
  LOGIN_TO_AGENCY: `${REACT_APP_API_URL}/user/login_to_agency`,
  REFRESH_TOKEN: `${REACT_APP_API_URL}/user/token`,
  FORGOT_PASSWORD: `${REACT_APP_API_URL}/user/forgot_password`,
  RESET_PASSWORD: `${REACT_APP_API_URL}/user/reset_password`,
  GET_AGENCIES: `${REACT_APP_API_URL}/user/agencies`,
  SWITCH_AGENCY: `${REACT_APP_API_URL}/user/switch_agency`,
  INVITE_USER: `${REACT_APP_API_URL}/user/invite`,
  GET_INVITATIONS: `${REACT_APP_API_URL}/user/invites`,
  ACCEPT_INVITATION: `${REACT_APP_API_URL}/user/invites/:invitation_id/accept`,
  DECLINE_INVITATION: `${REACT_APP_API_URL}/user/invites/:invitation_id/decline`,
  VALIDATE_OTP: `${REACT_APP_API_URL}/user/validate_otp`,
  GET_CURRENT_USER_DETAILS: `${REACT_APP_API_URL}/user/details`,
  GET_USER_DETAILS: `${REACT_APP_API_URL}/user/:id/details`,
  VERIFY_EMAIL: `${REACT_APP_API_URL}/user/:id/verify_email/:verifyId`,
  RESEND_VERIFICATION: `${REACT_APP_API_URL}/user/resend_verification`,
  CREATE_AGENCY: `${REACT_APP_API_URL}/user/create_agency`,
  UPDATE_MISC: `${REACT_APP_API_URL}/user/miscellaneous`,
  ONBOARDING_PROGRESS: `${REACT_APP_API_URL}/user/onboarding`,
  TEAM: `${REACT_APP_API_URL}/user/team`,
  REVOKE_ACCESS: `${REACT_APP_API_URL}/user/revoke_team_member`,
  SEGMENTS: `${REACT_APP_API_URL}/user/:userId/segments/:segmentId`,
  PERMISSIONS: `${REACT_APP_API_URL}/user/:userId/permissions`,
  ENABLE_USER: `${REACT_APP_API_URL}/user/enable`,
  DISABLE_USER: `${REACT_APP_API_URL}/user/disable`,
}

export const userRoles = {
  Owner: 'Owner',
  TeamMember: 'TeamMember',
  ReadOnly: 'ReadOnlyMember',
}

export const userRoleOptions = [
  {
    value: 'Owner',
    label: 'Account Owner',
  },
  {
    value: 'TeamMember',
    label: 'Team Member',
  },
  {
    value: 'ReadOnlyMember',
    label: 'Read Only',
  },
]

export const DEBOUNCE_TIME = 1000

export const agenciesWithOutstandingAccount: string[] = [
  '39cd1e89-e612-45e5-a5a1-79369c1d33c4', // ASAP Rentals
]

import { connect } from 'react-redux'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ["We've sent a request for the lease to be approved", 'What would you like to do next?'],

  actions: [
    {
      text: 'Create a new lease',
      action: () => chatbotEvents.portfolioRedirect('/leases/new/edit'),
    },
    {
      text: 'Take me home',
      action: () => chatbotEvents.portfolioRedirect('/'),
    },
  ],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

const { REACT_APP_BASE_URL, REACT_APP_INSIGHTS_URL, REACT_APP_API_URL } = process.env

export const NAMESPACE = 'reportingApi'

const reportingAPIEndpointV1 = `${REACT_APP_BASE_URL}/reporting/v1`
const reportingAPIEndpointV2 = `${REACT_APP_INSIGHTS_URL}/reports`

export const ENDPOINTS = {
  BASE: reportingAPIEndpointV1,
  ACCOUNTS_RECEIVABLE: `${reportingAPIEndpointV2}/accounts-receivable`,
  COMMISSION_SPLIT: `${REACT_APP_API_URL}/accounting/commission/report`,
  RENT_ROLL: `${reportingAPIEndpointV2}/rent-roll`,
  CASH_FLOW: `${reportingAPIEndpointV2}/cash-flow`,
  DEPOSITS: `${reportingAPIEndpointV2}/deposit-summary`,
  DEPOSIT_SUMMARY_STATS: `${reportingAPIEndpointV2}/deposit-summary-stats`,
  TENANCY_SCHEDULE: `${reportingAPIEndpointV2}/tenancy-schedule`,
  AGENCY_GROUP_COMMISSION_SPLIT: `${reportingAPIEndpointV1}/agency_group_commission_split`,
  BILLING: `${reportingAPIEndpointV1}/billing_report`,
  TPN_REPORT: `${reportingAPIEndpointV2}/tpn`,
  AGENCY_SUMMARY_CHART: `${reportingAPIEndpointV2}/agency-summary`,
  AGENT_COMMISSION_REPORT: `${REACT_APP_API_URL}/accounting/agent_commission/report`,
  DEPOSIT_FEE_STATEMENT: `${REACT_APP_API_URL}/accounting/receipt/report`,
  PROPERTIES: `${reportingAPIEndpointV2}/properties`,
  PROPERTY_TAX_STATEMENT: `${REACT_APP_API_URL}/accounting/reports/property-tax-statement-pdf`,
  FRANCHISE_PERFORMANCE: `${reportingAPIEndpointV2}/franchise-performance`,
  ACTIVE_LEASES: `${reportingAPIEndpointV2}/active-leases`,
  AVAILABLE_FUNDS: `${reportingAPIEndpointV2}/available-funds`,
  WALLETS: `${reportingAPIEndpointV2}/wallets`,
}

import React from 'react'

const CalendarOff = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g opacity={0.5} fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M23 7h-1V5h-2v2h-8V5h-2v2H9c-1.11 0-1.99.9-1.99 2L7 23a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm0 16H9V12h14v11z"
      />
    </g>
  </svg>
)

export default CalendarOff

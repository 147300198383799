import React from 'react'
import classNames from 'classnames'
import styles from './Paragraph.module.scss'

interface ParagraphProps {
  size?: 'fine' | 'caption' | 'body2' | 'body1'
  weight?: 'heavy' | 'medium' | 'regular'
  children: React.ReactNode
}

const Paragraph = ({ size, weight, children }: ParagraphProps) => {
  const classes = classNames(styles.root, {
    [styles[`size-${size}`]]: size,
    [styles[`weight-${weight}`]]: weight,
  })
  return <p className={classes}>{children}</p>
}

export default Paragraph

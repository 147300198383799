import { createSlice } from '@reduxjs/toolkit'

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name: "appRefreshApi"; reducer... Remove this comment to see the full error message
const appRefreshApiSlice = createSlice({
  name: 'appRefreshApi',
  reducers: {
    versionMismatched: () => {},
  },
})

const { reducer, actions: events } = appRefreshApiSlice

export { events, reducer }

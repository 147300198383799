import React from 'react'
import cx from 'classnames'
import Button from '../../atoms/Button/Button'
import styles from './AnimatedButton.module.scss'

type TProps = {
  state?: 'loading' | 'success' | 'error'
  children: React.ReactNode
  className?: string
}

const AnimatedButton = ({ state, children, className, ...props }: TProps) => {
  return (
    <span className={styles.root}>
      <Button className={cx([styles.button, styles[`button--${state}`], className])} {...props}>
        <span className={styles.text}>{children}</span>
      </Button>
    </span>
  )
}

export default AnimatedButton

import React from 'react'

const Close = ({ fill = '#000', ...props }) => (
  <svg width={32} height={32} {...props}>
    <path
      fill={fill}
      fillRule="nonzero"
      d="M21.438 10.56a.547.547 0 0 0-.773 0l-4.666 4.667-4.666-4.667a.547.547 0 0 0-.773.773L15.226 16l-4.666 4.667a.547.547 0 0 0 .774.773L16 16.773l4.666 4.667a.55.55 0 0 0 .774 0 .547.547 0 0 0 0-.773L16.774 16l4.666-4.667a.547.547 0 0 0-.002-.773z"
      opacity={0.6}
    />
  </svg>
)

export default Close

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, ControlledTooltip, FocusMode, Dimmer } from '../../../../../views/components'
import { NewContactForm } from '../../../../contacts'
import MultiPartyTable from '../../MultiPartyTable/MultiPartyTable'
import styles from './PartiesTable.module.scss'
import { keys, pipe, props as _props } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { partyApiSelectors } from '../../../../api/party'
import { portfolioApiSelectors } from '../../../../api/portfolio'
import { portfolioEvents, portfolioSelectors } from '../../../state'
import { userApiSelectors } from '../../../../api/user'

const propTypes = {
  portfolioId: PropTypes.string,
  partyType: PropTypes.string,
  parties: PropTypes.shape({
    lastFetched: PropTypes.string,
    primary: PropTypes.shape({
      partyId: PropTypes.string,
    }),
    secondary: PropTypes.arrayOf(
      PropTypes.shape({
        partyId: PropTypes.string,
      }),
    ),
  }),
  redirect: PropTypes.func,
  removePartyFromLease: PropTypes.func,
  makePartyPrimary: PropTypes.func,
  addPartyToLease: PropTypes.func,
  portfolioStatus: PropTypes.string,
}

const PartiesTable = (props: any) => {
  const {
    portfolioId,
    partyType,
    parties,
    redirect,
    removePartyFromLease,
    makePartyPrimary,
    addPartyToLease,
    portfolioStatus,
  } = props

  const dispatch = useDispatch()

  const getPartyName = useSelector(partyApiSelectors.getPartyNameById)
  const getPartyEmail = useSelector(partyApiSelectors.getPartyEmailById)
  const getIsPortfolioActive = useSelector(portfolioApiSelectors.isActivePortfolio)
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const isNewPartyModalOpen = useSelector(portfolioSelectors.isNewPartyModalOpen)

  const header = partyType === 'owner' ? 'Owners' : 'Tenants'

  const onSubmit = (values: any) => {
    const personOrCompany = keys(values)[0]
    const partyValues = {
      [personOrCompany]: {
        ...values[personOrCompany],
        tags:
          partyType === 'owner'
            ? [...values[personOrCompany].tags, 'Owner']
            : [...values[personOrCompany].tags, 'Tenant'],
      },
    }

    return addPartyToLease(partyValues)
  }

  const WrappedNewContactForm = useMemo(
    () =>
      function PartiesTableContactForm() {
        return (
          <NewContactForm
            onSubmit={onSubmit}
            allowSearchable
            getButtonText={(values: any) =>
              values?.id ? `Add ${partyType} to lease` : `Create contact and add ${partyType} to lease`
            }
          />
        )
      },
    [],
  )

  const transformParty = (id, isPrimary) => ({
    id: id,
    name: getPartyName(id),
    email: getPartyEmail(id),
    isPrimary,
  })

  const partyDetails = pipe(_props(['primary', 'secondary']), (parties: any) => {
    const [primary, secondary] = parties
    return primary && secondary
      ? [transformParty(primary.partyId, true), ...secondary.map((s: any) => transformParty(s.partyId, false))]
      : []
  })(parties)

  return (
    <>
      <div className={styles.root}>
        <Dimmer active={isReadOnly} bgColor="white">
          <div>
            <div className={styles.header}>
              <strong>{header}</strong>
              {/* @ts-expect-error */}
              <ControlledTooltip body={`Add ${partyType}`} place="above">
                <Button ghost className={styles.add} onClick={() => dispatch(portfolioEvents.togglePartyModal())} />
              </ControlledTooltip>
            </div>
            <MultiPartyTable
              data={partyDetails}
              onEdit={partyId => redirect(`/contacts/${partyId}/edit`)}
              onRemove={id => removePartyFromLease(id)}
              onMakePrimary={makePartyPrimary}
              allowEdit={portfolioStatus !== 'terminated'}
            />
          </div>
          <div className={styles.footer}>
            {partyDetails.length === 0 ? (
              <Button onClick={() => dispatch(portfolioEvents.togglePartyModal())}>{`Add first ${partyType}`}</Button>
            ) : (
              <Button
                onClick={() =>
                  redirect(`/leases/${portfolioId}/edit/${partyType === 'owner' ? 'applications' : 'term'}`)
                }
              >
                {getIsPortfolioActive(portfolioId) ? 'Save' : "Done, let's continue"}
              </Button>
            )}
          </div>
        </Dimmer>
      </div>
      {isNewPartyModalOpen && (
        <FocusMode
          isOpen={isNewPartyModalOpen}
          title={`New ${partyType}`}
          ContentComponent={WrappedNewContactForm}
          onClose={() => dispatch(portfolioEvents.togglePartyModal())}
        />
      )}
    </>
  )
}

PartiesTable.propTypes = propTypes

export default PartiesTable

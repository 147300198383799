import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import TextField from '../../../atoms/TextField/TextField'
import SearchIcon from '../../../atoms/Svgs/SearchOff'
import CloseIcon from '../../../atoms/Svgs/Close'
import styles from './SearchField.module.scss'
import TextInput from 'views/components/atoms/TextField/TextInput'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  query: PropTypes.string,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  setRef: PropTypes.func,
}

const defaultProps = {}

class SearchField extends PureComponent {
  toggle = (e: any): void => {
    e.preventDefault()
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOpen' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { isOpen, onOpen, onClose } = this.props
    const callback = isOpen ? onClose : onOpen
    callback()
  }

  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { query, onChange, isOpen, setRef } = this.props
    const classes = cx(styles.root, {
      [styles['is-open']]: isOpen,
    })

    return (
      <div className={classes}>
        <TextField
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              name="search"
              placeholder="Search..."
              onChange={onChange}
              value={query}
              ref={setRef}
            />
          }
        />
        {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: any; href: s... Remove this comment to see the full error message */}
        <button className={styles['open-button']} href="#" onClick={this.toggle}>
          {isOpen ? <CloseIcon /> : <SearchIcon />}
        </button>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SearchField.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
SearchField.defaultProps = defaultProps

export default SearchField

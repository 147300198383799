import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash-es'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'
import { getTaggablePartyId } from '../party/utils'
import { availableFunds } from './epics'

const initialState: $TSFixMe = {
  accountsReceivable: {},
  commissionSplit: {},
  depositSummaryStats: {},
  performanceSummary: {
    daily: [],
    weekly: [],
    monthly: [],
    yearly: [],
  },
  properties: [],
  parties: {},
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    // dataType: 'excel' | 'json'
    accountsReceivable_request: (state, { payload: { dataType = 'excel' } }) => {},
    accountsReceivable_success: (state, { payload }) => {},
    commissionSplit_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    commissionSplit_success: (state, { payload }) => {},
    agentCommissionReport_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    agentCommissionReport_success: (state, { payload }) => {},
    rentRoll_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    rentRoll_success: (state, { payload }) => {},
    cashFlow_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    cashFlow_success: (state, { payload }) => {},
    deposits_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    deposits_success: (state, { payload }) => {},
    depositSummaryStats_request: state => {},
    depositSummaryStats_success: (state, { payload }) => {
      state.depositSummaryStats = payload
    },
    tenancySchedule_request: (state, { payload: { dataType = 'excel', shouldIncludeDrafts } }) => {},
    tenancySchedule_success: (state, { payload }) => {},
    agencyGroupCommissionSplit_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    agencyGroupCommissionSplit_success: (state, { payload }) => {},
    tpnReport_request: (state, { payload: { dataType = 'excel', start, end } }) => {},
    tpnReport_success: (state, { payload }) => {},
    cashDepositStatement_request: (state, { payload: { dataType = 'pdf', start, end } }) => {},
    cashDepositStatement_success: (state, {}) => {},
    billingReport_request: (state, { payload: { dataType = 'excel', startDate, endDate } }) => {},
    billingReport_success: (state, { payload }) => {},
    agencyPerformanceSummaryChart_request: (state, { payload: { granularity = 'monthly' } }) => {},
    agencyPerformanceSummaryChart_success: (state, { payload: { bucketType = 'monthly', buckets = [] } }) => {
      state.performanceSummary[bucketType] = buckets
    },
    properties_request: state => {},
    properties_success: (state, { payload }) => {
      state.properties = payload
    },
    quickSearch_request: {
      reducer: () => {},
      prepare: ({ query = '', tags = '', offset = 0, limit = 1000 }) => ({ payload: { query, tags, offset, limit } }),
    },
    quickSearch_success: (state, { payload: { results, params } }) => {
      state.parties = {
        ...state.parties,
        ...results.reduce((acc: any, party: any) => {
          const id = getTaggablePartyId(party)
          // @ts-expect-error
          acc[id] = { id, ...party }
          return acc
        }, {}),
      }
    },
    propertyTaxStatement_request: (state, { payload: { start, finish, ownerId, ownerName } }) => {},
    propertyTaxStatement_success: (state, { payload }) => {},
    franchisePerformance_request: (state, { payload: { dataType = 'excel', start, end, groupId } }) => {},
    franchisePerformance_success: (state, { payload }) => {},
    activeLeases_request: (state, { payload: { dataType = 'excel', start, finish } }) => {},
    activeLeases_success: (state, { payload }) => {},
    availableFunds_request: (state, { payload: { dataType = 'excel' } }) => {},
    availableFunds_success: (state, { payload }) => {},
    wallets_request: (state, { payload: { dataType = 'excel' } }) => {},
    wallets_success: (state, { payload }) => {},
  },
})

const { reducer, actions: events } = slice

export { events, reducer }

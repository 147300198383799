import React, { useState } from 'react'
import cx from 'classnames'
import { currencyUtils } from '../../../../../../utils'
import styles from './EditBeneficiaryAmount.module.scss'
import EditIcon from '../../../../atoms/Svgs/Edit'
import CheckIcon from '../../../../atoms/Icons/Controls/Check'
import ControlledTooltip from '../../../../atoms/Tooltip/ControlledTooltip'
import { $TSFixMe } from 'types/ts-migrate'
import { NumericFormat } from 'react-number-format'

const EditBeneficiaryAmount = (props: $TSFixMe): React.ReactElement => {
  const { amount, balance, onAmountUpdate, isDisabled, isEditable = true } = props

  const [editingAmount, setEditingAmount] = useState(false)
  const [amountPayable, setAmountPayable] = useState(amount)
  const [displayAmountPayable, setDisplayAmountPayable] = useState(amount)

  const getPaidStatusClass = (): string =>
    amount - balance === 0 ? 'error-text' : amount - balance > 0 && balance !== 0 ? 'partial-text' : 'paid-text'

  const saveAmountPayable = (): void => {
    setEditingAmount(!editingAmount)
    setDisplayAmountPayable(amountPayable)
    onAmountUpdate(amountPayable)
  }

  const onAmountPayableChange = (e: any): void => {
    const { floatValue } = e
    if (!isNaN(parseFloat(floatValue))) {
      const valueInCents = currencyUtils.toCents(floatValue).getAmount()
      setAmountPayable(valueInCents)
    }
  }

  return (
    <span className={cx([styles['amount-text'], styles[getPaidStatusClass()]])}>
      {editingAmount ? (
        <span>
          {currencyUtils.formatCurrency(amount - balance)} /
          <NumericFormat
            className={styles['inline-input']}
            name="viewOpenInvoice.editBeneficiaryAmount"
            thousandSeparator=" "
            value={currencyUtils.fromCents(amount)}
            placeholder="0,00"
            onValueChange={onAmountPayableChange}
            decimalScale={2}
            fixedDecimalScale
            defaultValue={0}
            allowNegative={false}
          />
          paid
        </span>
      ) : (
        `${currencyUtils.formatCurrency(amount - balance) as string} / ${
          currencyUtils.formatCurrency(displayAmountPayable) as string
        } paid`
      )}
      {isEditable && (
        <>
          {editingAmount ? (
            <CheckIcon className={styles['edit-button']} width={20} height={20} onClick={() => saveAmountPayable()} />
          ) : (
            // @ts-expect-error
            <ControlledTooltip body="Edit payment rule amount">
              <button
                type="button"
                className={styles['edit-button']}
                onClick={() => setEditingAmount(!editingAmount)}
                disabled={isDisabled}
              >
                <EditIcon
                  className={styles['edit-button']}
                  width={20}
                  height={20}
                  onClick={() => setEditingAmount(!editingAmount)}
                />
              </button>
            </ControlledTooltip>
          )}
        </>
      )}
    </span>
  )
}

export default EditBeneficiaryAmount

import React, { FC } from 'react'
import IntegrationCardBody from './IntegrationCardBody'

type Props = {
  integration: {
    id: string
    available: boolean
    enabled: boolean
    name: string
    short: string
    logo: string
    orderNo: number
  }
}

const IntegrationCard: FC<Props> = ({ integration }) => {
  return (
    <IntegrationCardBody
      id={integration.id}
      available={integration.available}
      enabled={integration.enabled}
      name={integration.name}
      short={integration.short}
      logo={integration.logo}
      orderNo={integration.orderNo}
    />
  )
}

export default IntegrationCard

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { AgenciesPage, AgenciesProvider } from '..'
import { SupportStaffProvider } from '../../supportStaff'
import { $TSFixMe } from 'types/ts-migrate'

const SegmentsRoute = (props: $TSFixMe) => (
  <AgenciesProvider {...props}>
    <SupportStaffProvider>
      <SearchFilterProvider location={props.location}>
        <AgenciesPage />
      </SearchFilterProvider>
    </SupportStaffProvider>
  </AgenciesProvider>
)

export default SegmentsRoute

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { AdminReportingPage, AdminReportingProvider } from '..'
import { SupportStaffProvider } from '../../supportStaff'

const AdminReportingRoute = (props: any) => (
  <AdminReportingProvider {...props}>
    <SupportStaffProvider>
      <SearchFilterProvider location={props.location}>
        <AdminReportingPage />
      </SearchFilterProvider>
    </SupportStaffProvider>
  </AdminReportingProvider>
)

export default AdminReportingRoute

import React, { FC } from 'react'
import styles from './RadialProgress.module.scss'

type TProps = {
  size: number | string
  percentage: number
  strokeWidth?: number
  progressColor?: string
}

const RadialProgress: FC<TProps> = ({ size, percentage, strokeWidth, progressColor = 'rgba(0,0,0,.8)' }) => {
  percentage = percentage > 100 ? 100 : percentage
  if (typeof strokeWidth === 'undefined') {
    strokeWidth = Number(size) / 10
  }

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (Number(size) - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100

  const style = {
    strokeDasharray: dashArray,
    strokeDashoffset: dashOffset,
  }

  if (progressColor) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'stroke' does not exist on type '{ stroke... Remove this comment to see the full error message
    style.stroke = progressColor
  }

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        className={styles.background}
        cx={Number(size) / 2}
        cy={Number(size) / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={styles.progress}
        cx={Number(size) / 2}
        cy={Number(size) / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${Number(size) / 2} ${Number(size) / 2})`}
        style={style}
      />
    </svg>
  )
}

export default RadialProgress

import React from 'react'

const EditOff = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fill="#000"
      d="M9 24l5.756-2.16L23 11.796l-1.044-1.79-.39-.299-1.022-.803-.38-.298L18.14 8l-8.17 9.95L9 24zm1.74-5.505a.76.76 0 0 1 .231-.051c.352 0 .633.24.844.72.154-.048.27-.072.348-.072.246 0 .481.106.706.319.225.213.337.436.337.669 0 .055-.003.127-.01.216.583.116.875.425.875.926l-1.624.545c-.358-.946-1.008-1.454-1.95-1.523l.242-1.749zm.569-.638l7.337-8.941.907.278-7.506 9.147a1.024 1.024 0 0 0-.738-.484zm1.402.751l7.411-9.023.864.679-7.421 9.044a1.168 1.168 0 0 0-.854-.7zm1.254 1.287l7.538-9.179.474.803-7.39 9.003c-.07-.288-.277-.497-.622-.627z"
      opacity={0.5}
      fillRule="evenodd"
    />
  </svg>
)

export default EditOff

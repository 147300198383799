import React from 'react'

const SvgDelete = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M8 12h16v13a1 1 0 01-1 1H9a1 1 0 01-1-1V12zm2 2v10h12V14H10zm3 2h2v6h-2v-6zm4 0h2v6h-2v-6zm-6-7V7a1 1 0 011-1h8a1 1 0 011 1v2h5v2H6V9h5zm2-1v1h6V8h-6z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgDelete

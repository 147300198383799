import React, { useEffect, useMemo, useState } from 'react'
import { subscribe } from 'react-contextual'
import { get } from 'lodash-es'
import { AccountSelector } from '../../../../../views/components'
import AccountDetails from './AccountDetails/AccountDetails'
import { ContactsProvider } from '../../..'
import styles from './AccountPanel.module.scss'
import ActiveInvoicesProvider from './ActiveInvoices/ActiveInvoicesProvider'
import ActiveInvoices from './ActiveInvoices/ActiveInvoices'
import ContactBalanceProvider from './ContactBalance/ContactBalanceProvider'
import ContactBalance from './ContactBalance/ContactBalance'
import RecentActivityProvider from './RecentActivity/RecentActivityProvider'
import RecentActivity from './RecentActivity/RecentActivity'
import { RefundModal } from '../../../../depositManagement/components'
import RefundProvider from '../../../../depositManagement/RefundProvider'
import { $TSFixMe } from 'types/ts-migrate'
import { TAccount } from 'views/components/molecules/AccountSelector/AccountSelector'
import { useSelector } from 'react-redux'
import { portfolioApiSelectors } from 'modules/api/portfolio'
import { groupBy } from 'ramda'
import DepositAccount from './DepositAccount/DepositAccount'
import DepositAccountProvider from './DepositAccount/DepositAccountProvider'

const mapAccountFields = (account: $TSFixMe): TAccount => ({
  id: account.accountId,
  value: account.accountId,
  label: account.propertyAddress ? account.propertyAddress : 'No attached lease',
  tag: account.tag,
  portfolioId: account?.portfolioId,
  paymentReference: account.paymentReference,
})

type TAccountWithStatus = TAccount & { status: string }

const AccountPanel = ({ id, currentParty, setCurrentAccountId, partyHasBankDetails }: any) => {
  const [selectedAccount, setSelectedAccount] = useState<$TSFixMe>(null)
  const accounts = get(currentParty, 'accounts', []).map(mapAccountFields)

  const statusOrder = ['active', 'expiring', 'renewal', 'draft', 'expired', 'terminated', 'deleted']
  const getPortfolioStatusLabel = useSelector(portfolioApiSelectors.getPortfolioStatusLabel)
  const accountsWithPortfolioStatus = accounts.map(account => ({
    ...account,
    status: getPortfolioStatusLabel(account.portfolioId, true),
  }))

  const groupedAccounts = groupBy((account: TAccountWithStatus) => account.status, accountsWithPortfolioStatus)

  const sortedAccounts = statusOrder
    .map(status => groupedAccounts[status || ''])
    .filter(Boolean)
    .flat()

  const moveApplicationAccountsToBottom = (accounts: TAccountWithStatus[]) => {
    const applicationAccounts = accounts.filter(account => account.tag === 'Application')
    const nonApplicationAccounts = accounts.filter(account => account.tag !== 'Application')
    return [...nonApplicationAccounts, ...applicationAccounts]
  }

  const movedApplicationAccounts = moveApplicationAccountsToBottom(sortedAccounts)

  useEffect(() => {
    const selectedAccount = get(currentParty, 'accounts', []).find(
      (acc: $TSFixMe) => acc.accountId === movedApplicationAccounts[0]?.id,
    )
    if (selectedAccount) {
      setSelectedAccount(mapAccountFields(selectedAccount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParty.id])

  useEffect(() => {
    if (selectedAccount?.id) {
      setCurrentAccountId(selectedAccount.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount])

  const account = useMemo(() => {
    return accounts?.find(acc => acc.id === get(selectedAccount, 'id'))
  }, [accounts, selectedAccount])

  return !account ? null : (
    <div className={styles.root}>
      <section className="account-selector">
        <AccountSelector
          name="active-account"
          options={movedApplicationAccounts}
          onChange={(data: any) => setSelectedAccount(data)}
          value={account}
        />
      </section>
      <section>
        <RefundProvider entity="party" id={id} tenantAccountId={selectedAccount?.id}>
          <AccountDetails
            paymentReference={get(account, 'paymentReference')}
            accountTag={get(account, 'tag')}
            accountAddress={get(account, 'label')}
            // accountMeta='@todo ? Months remaining'
            status="good"
            partyHasBankDetails={partyHasBankDetails}
            portfolioId={selectedAccount?.portfolioId}
          />
          <RefundModal />
        </RefundProvider>
      </section>

      <ActiveInvoicesProvider customerId={currentParty.id} accountId={account.id}>
        <ActiveInvoices />
      </ActiveInvoicesProvider>

      <ContactBalanceProvider customerId={currentParty.id} accountId={account.id} accountType={account.tag}>
        <ContactBalance />
      </ContactBalanceProvider>

      <RecentActivityProvider customerId={currentParty.id} accountId={account.id}>
        <RecentActivity />
      </RecentActivityProvider>

      <DepositAccountProvider partyId={currentParty.id} accountId={account.id} accountType={account.tag}>
        <RefundProvider entity="party" id={id} tenantAccountId={selectedAccount?.id}>
          <DepositAccount
            isTenant={currentParty.tags.includes('Tenant')}
            partyId={id}
            portfolioId={selectedAccount?.portfolioId}
            accountId={selectedAccount?.id}
            accountType={selectedAccount.tag}
          />
          <RefundModal />
        </RefundProvider>
      </DepositAccountProvider>
    </div>
  )
}

export default subscribe(ContactsProvider, ({ id, currentParty, setCurrentAccountId, partyHasBankDetails }: any) => ({
  id,
  currentParty,
  setCurrentAccountId,
  partyHasBankDetails,
}))(AccountPanel)

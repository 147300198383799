import React from 'react'
import { moduleContext } from 'react-contextual'

const ApiStateProvider = moduleContext()(({ context, children, ...props }: any) => {
  const { match } = props

  const _store = {
    match,
    ...props,
  }
  return <context.Provider value={_store}>{children}</context.Provider>
})

export default ApiStateProvider

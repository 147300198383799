import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pathOr, isEmpty } from 'ramda'
import { Segment, FormField, Select, CommissionSplit, CurrencyField, PercentageField } from '../../../../components'
import { portfolioApiConstants, portfolioApiUtils } from '../../../../../modules/api/portfolio'
import { formUtils } from '../../../../../utils'
import { leaseTypes } from '../../../../../modules/api/portfolio/constants'

const { commissionUndertakings } = portfolioApiConstants

const propTypes = {
  /** Formik `setFieldTouched` */
  setFieldTouched: PropTypes.func,
  /** Formik `setFieldValue` */
  setFieldValue: PropTypes.func,
  /** Formik `handleChange` */
  handleChange: PropTypes.func,
  /** Formik `handleBlur` */
  handleBlur: PropTypes.func,
  defaultResultSets: PropTypes.arrayOf(PropTypes.array),
  /** Formik values */
  values: PropTypes.shape({
    type: PropTypes.oneOf([commissionUndertakings.fixed, commissionUndertakings.variable]),
    netAmount: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    percentage: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        agent: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      }),
    ),
  }),
  procurementValues: PropTypes.shape({
    netAmount: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      vatable: PropTypes.bool,
    }),
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        agent: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      }),
    ),
  }),
  rentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.object,
  portfolioRentInvoice: PropTypes.object,
}

const defaultProps = {}

class ManagementFeeForm extends Component {
  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFieldTouched' does not exist on type ... Remove this comment to see the full error message
      setFieldTouched,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFieldValue' does not exist on type 'R... Remove this comment to see the full error message
      setFieldValue,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleChange' does not exist on type 'Re... Remove this comment to see the full error message
      handleChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleBlur' does not exist on type 'Read... Remove this comment to see the full error message
      handleBlur,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      values,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'rentAmount' does not exist on type 'Read... Remove this comment to see the full error message
      rentAmount,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'errors' does not exist on type 'Readonly... Remove this comment to see the full error message
      errors,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultResultSets' does not exist on typ... Remove this comment to see the full error message
      defaultResultSets,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touched' does not exist on type 'Readonl... Remove this comment to see the full error message
      touched,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'submitCount' does not exist on type 'Rea... Remove this comment to see the full error message
      submitCount,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'portfolioRentInvoice' does not exist on ... Remove this comment to see the full error message
      portfolioRentInvoice,
    } = this.props

    const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

    const getRentInvoicePaymentRulesTotal = (commissionAmount: any) => {
      const paymentRules = pathOr([], ['paymentRules'], portfolioRentInvoice)
      const paymentRulesTotal = portfolioApiUtils.getPaymentRulesAmountExRent(paymentRules, commissionAmount)
      const paymentRulesTotalExCommission = portfolioApiUtils.getPaymentRulesAmountExRent(paymentRules, 0)

      setFieldValue('managementFee.paymentRulesTotal', paymentRulesTotal)
      setFieldValue('managementFee.paymentRulesTotalExCommission', paymentRulesTotalExCommission)
      setFieldValue('procurementFee.paymentRulesTotal', paymentRulesTotal)
      setFieldValue('procurementFee.paymentRulesTotalExCommission', paymentRulesTotalExCommission)
      setFieldValue('procurementFee.monthlyRentAmount', rentAmount)
    }

    const options = [
      {
        value: commissionUndertakings.variable,
        label: 'Percentage',
      },
      {
        value: commissionUndertakings.fixed,
        label: 'Fixed amount',
      },
    ]

    return (
      <Segment>
        <Segment.Group>
          <Segment.Group horizontal>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; style: { width: string;... Remove this comment to see the full error message */}
            <Segment style={{ width: '40%' }}>
              <h3 style={{ fontSize: '20px' }}>Management Fee</h3>
            </Segment>
            <Segment>
              <Select
                noOutline
                style={{ marginTop: '10px' }}
                name="managementFee.type"
                error={getFieldError('managementFee.type')}
                value={options.find((option: any) => option.value === values.type)}
                onChange={(data: any) => {
                  setFieldValue('managementFee.type', data.value)
                  if (!isEmpty(rentAmount)) {
                    if (data.value === commissionUndertakings.fixed) {
                      const netAmount = rentAmount * (values.percentage.value / 100)
                      setFieldValue('managementFee.netAmount.value', netAmount)
                    } else if (data.value === commissionUndertakings.variable) {
                      const percentage = (values.netAmount.value / rentAmount) * 100
                      setFieldValue('managementFee.percentage.value', percentage)
                    }
                  }
                }}
                onBlur={setFieldTouched}
                options={options}
              />
            </Segment>
          </Segment.Group>
          <Segment.Group horizontal>
            <Segment>
              <div className="rbn--row">
                <div className="rbn--col-md">
                  <FormField>
                    <CurrencyField
                      disabled
                      name="managementFee.rentAmount"
                      label="Rental amount"
                      error={getFieldError('managementFee.rentAmount')}
                      value={rentAmount}
                      handleBlur={handleBlur}
                    />
                  </FormField>
                </div>
                <div className="rbn--col-md">
                  <FormField>
                    {/** @todo convert to VAT Input Control */}
                    {values.type === commissionUndertakings.fixed ? (
                      <CurrencyField
                        includeVatControl
                        vatApplied={values.netAmount.vatable}
                        name="managementFee.netAmount"
                        label={`Commission ${values.netAmount.vatable ? '(incl. VAT)' : ''}`}
                        error={getFieldError('managementFee.netAmount')}
                        onFocus={() => setFieldTouched('managementFee.netAmount')}
                        value={values.netAmount.value}
                        onToggleChange={(vatApplied: any, value: any) => {
                          setFieldValue('managementFee.netAmount.vatable', vatApplied)
                        }}
                        onChange={(e: any) => {
                          setFieldValue('managementFee.netAmount.value', e.target.value)
                          getRentInvoicePaymentRulesTotal(e.target.value)
                        }}
                        handleBlur={handleBlur}
                        errorPosition="bottom"
                      />
                    ) : (
                      <PercentageField
                        includeVatControl
                        vatApplied={values.percentage.vatable}
                        name="managementFee.percentage"
                        label={`Percentage ${values.percentage.vatable ? '(incl. VAT)' : ''}`}
                        amount={rentAmount}
                        error={getFieldError('managementFee.percentage')}
                        onFocus={() => setFieldTouched('managementFee.percentage')}
                        value={values.percentage.value}
                        onChange={(e: any) => {
                          setFieldValue('managementFee.percentage.value', parseFloat(e.floatValue))
                          const commissionAmount = (values.monthlyRentAmount / 100) * parseFloat(e.floatValue)
                          getRentInvoicePaymentRulesTotal(commissionAmount)
                        }}
                        onToggleChange={(vatApplied: any, value: any) =>
                          setFieldValue('managementFee.percentage.vatable', vatApplied)
                        }
                        handleBlur={handleBlur}
                        errorPosition="bottom"
                      />
                    )}
                  </FormField>
                </div>
              </div>
            </Segment>
          </Segment.Group>
          <Segment.Group>
            <Segment>
              <CommissionSplit
                feeName="managementFee"
                values={values}
                leaseType={leaseTypes.managed}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={pathOr({}, ['managementFee', 'splits'], errors)}
                defaultResultSets={defaultResultSets}
              />
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Segment>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ManagementFeeForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ManagementFeeForm.defaultProps = defaultProps

export default ManagementFeeForm

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import ReportingProvider from '../ReportingProvider'
import ReportingPage from './ReportingPage'

const SegmentsRoute = (props: any) => (
  <ReportingProvider {...props}>
    <SearchFilterProvider location={props.location}>
      <ReportingPage />
    </SearchFilterProvider>
  </ReportingProvider>
)

export default SegmentsRoute

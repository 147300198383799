import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const badReferencesSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  /** @todo add actions and reducers */
  reducers: {
    unallocatedBadReferences_request: (state, { payload }) => {},
    unallocatedBadReferences_success: (state, { payload }) => {},
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = badReferencesSlice

export { events as badReferencesEvents, reducer as badReferencesReducer }

import React, { useCallback, useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { Button, ControlledTooltip, FocusMode, Dimmer } from '../../../../../views/components'
import MultiApplicationsTable from '../../MultiApplicationsTable/MultiApplicationsTable'
import styles from './ApplicationsTable.module.scss'
import { keys, props as _props } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { portfolioApiSelectors } from '../../../../api/portfolio'
import { portfolioEvents, portfolioSelectors } from '../../../state'
import { userApiSelectors } from '../../../../api/user'
import PortfoliosProvider from 'modules/portfolios/PortfoliosProvider'
import { TApplication, TParty, TTenant } from 'modules/api/portfolio/types'
import { NewContactForm } from 'modules/contacts'

type Props = {
  portfolioId: string
  applications: TApplication[]
  tenants: TTenant
  redirect(redirectTo: string): void
  addApplicationToLease(partyValues: any, applicationFee: number): void
  portfolioStatus: string
}

const ApplicationsTable = ({
  portfolioId,
  applications,
  tenants,
  redirect,
  addApplicationToLease,
  portfolioStatus,
}: Props) => {
  const dispatch = useDispatch()

  const getIsPortfolioActive = useSelector(portfolioApiSelectors.isActivePortfolio)
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const isNewPartyModalOpen = useSelector(portfolioSelectors.isNewPartyModalOpen)

  const onSubmit = useCallback((values: TParty, applicationFee) => {
    const personOrCompany = keys(values)[0]
    const partyValues = {
      [personOrCompany]: values[personOrCompany],
    }
    addApplicationToLease(partyValues, applicationFee)
  }, [])

  const WrappedNewContactForm = useMemo(
    () =>
      function PartiesTableContactForm() {
        return (
          <NewContactForm
            onSubmit={(values: TParty) => onSubmit(values, 0)}
            allowSearchable
            getButtonText={(values: TParty) =>
              values?.id ? `Select contact and add application to lease` : `Create contact and add application to lease`
            }
          />
        )
      },
    [],
  )

  const isAnApplicationAccepted = applications.find((application: TApplication) => application.status === 'Accepted')

  return (
    <>
      <div className={styles.root}>
        <Dimmer active={isReadOnly} bgColor="white">
          <div>
            <div className={styles.header}>
              <strong>Applications</strong>
              {/* @ts-expect-error */}
              <ControlledTooltip body="Add application" place="above">
                <Button
                  ghost
                  disabled={isAnApplicationAccepted !== undefined || tenants.primary !== undefined}
                  className={styles.add}
                  onClick={() => dispatch(portfolioEvents.togglePartyModal())}
                />
              </ControlledTooltip>
            </div>
            <MultiApplicationsTable
              applicationDetails={applications}
              portfolioId={portfolioId}
              onEdit={partyId => redirect(`/contacts/${partyId}/edit`)}
              allowEdit={portfolioStatus !== 'terminated'}
              tenants={tenants}
            />
          </div>
          <div className={styles.footer}>
            <Button onClick={() => redirect(`/leases/${portfolioId}/edit/tenants`)}>
              {getIsPortfolioActive(portfolioId) ? 'Save' : "Done, let's continue"}
            </Button>
          </div>
        </Dimmer>
      </div>
      {isNewPartyModalOpen && (
        <FocusMode
          isOpen={isNewPartyModalOpen}
          title="New application"
          ContentComponent={WrappedNewContactForm}
          onClose={() => dispatch(portfolioEvents.togglePartyModal())}
        />
      )}
    </>
  )
}

export default subscribe([PortfoliosProvider], ({ tenants, addApplicationToLease, applications }) => ({
  tenants,
  addApplicationToLease,
  applications,
}))(ApplicationsTable)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { get } from 'lodash-es'
import { Button, CurrencyField, FormField, Divider } from '../../../../components'
import styles from './PortfolioRentAndFeesForm.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

const currencyPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])
const propTypes = {
  initialValues: PropTypes.shape({
    keyDepositAmount: currencyPropType,
    serviceDepositAmount: currencyPropType,
    damageDepositAmount: currencyPropType,
    monthlyRentAmount: currencyPropType,
    leaseFee: PropTypes.shape({
      netAmount: currencyPropType,
      vatable: PropTypes.bool,
    }),
    applicationFee: PropTypes.shape({
      netAmount: currencyPropType,
      vatable: PropTypes.bool,
    }),
    firstMonthRentAmount: currencyPropType,
  }),
  handleSubmit: PropTypes.func,
  isActivePortfolio: PropTypes.bool,
  isReadOnly: PropTypes.bool,
}

const defaultProps = {}

const validationSchema = yup.object().shape({
  monthlyRentAmount: yup.string().required('required.'),
})

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  validationSchema,
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.handleSubmit(values)
  },
})
class PortfolioRentAndFeesForm extends Component<$TSFixMe, $TSFixMe> {
  getFieldErrors = (name: any) => get(this.props.touched, name, '') && get(this.props.errors, name, '')

  render(): null | React.ReactElement {
    const { handleSubmit, values, handleBlur, setFieldValue, isActivePortfolio, isReadOnly } = this.props

    return (
      <div className={styles.root}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <fieldset disabled={isReadOnly}>
            <div className={styles.body}>
              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      name="monthlyRentAmount"
                      label="Rental amount"
                      value={values.monthlyRentAmount}
                      error={this.getFieldErrors('monthlyRentAmount')}
                      onChange={(e: any) => setFieldValue('monthlyRentAmount', e.target.value)}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      name="damageDepositAmount"
                      label="Damage deposit"
                      error={this.getFieldErrors('damageDepositAmount')}
                      value={values.damageDepositAmount}
                      onChange={(e: any) => setFieldValue('damageDepositAmount', e.target.value)}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
              </div>
              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      name="serviceDepositAmount"
                      label="Service deposit"
                      error={this.getFieldErrors('serviceDepositAmount')}
                      value={values.serviceDepositAmount}
                      onChange={(e: any) => setFieldValue('serviceDepositAmount', e.target.value)}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      name="keyDepositAmount"
                      label="Key deposit"
                      error={this.getFieldErrors('keyDepositAmount')}
                      value={values.keyDepositAmount}
                      onChange={(e: any) => setFieldValue('keyDepositAmount', e.target.value)}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
              </div>

              <Divider>
                <h3>Additional fees (Optional)</h3>
              </Divider>

              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      includeVatControl
                      vatApplied={values.applicationFee.vatable}
                      name="applicationFee.netAmount"
                      label={`Application fee ${values.applicationFee.vatable ? '(incl. VAT)' : ''}`}
                      error={this.getFieldErrors('applicationFee.netAmount')}
                      value={values.applicationFee.netAmount}
                      onChange={(e: any) => setFieldValue('applicationFee.netAmount', e.target.value)}
                      onToggleChange={(vatApplied: any) => {
                        setFieldValue('applicationFee.vatable', vatApplied)
                      }}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      includeVatControl
                      vatApplied={values.leaseFee.vatable}
                      name="leaseFee.netAmount"
                      label={`Lease fee ${values.leaseFee.vatable ? '(incl. VAT)' : ''}`}
                      value={values.leaseFee.netAmount}
                      error={this.getFieldErrors('leaseFee.netAmount')}
                      onChange={(e: any) => setFieldValue('leaseFee.netAmount', e.target.value)}
                      onToggleChange={(vatApplied: any) => {
                        setFieldValue('leaseFee.vatable', vatApplied)
                      }}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
              </div>
              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <CurrencyField
                      name="firstMonthRentAmount"
                      label="Pro-rata rental amount"
                      placeholder="0 000.00"
                      value={values.firstMonthRentAmount}
                      error={this.getFieldErrors('firstMonthRentAmount')}
                      onChange={(e: any) => setFieldValue('firstMonthRentAmount', e.target.value)}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button type="submit">{isActivePortfolio ? 'Save' : "Done, let's continue"}</Button>
            </div>
          </fieldset>
        </form>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PortfolioRentAndFeesForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PortfolioRentAndFeesForm.defaultProps = defaultProps

export default PortfolioRentAndFeesForm

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { format } from 'date-fns'
import { subscribe } from 'react-contextual'
import { isEmpty, path, pathOr } from 'ramda'
import { currencyUtils } from '../../../../../utils'
import DepositProvider from '../../../../depositManagement/DepositProvider'
import TransactionListItem from '../../../../../views/components/atoms/TransactionListItem/TransactionListItem'
import FileTable from '../../../../documents/FileTable'
import PortfolioSelector from './PortfolioSelector/PortfolioSelector'
import RecurringInvoices from './RecurringInvoices/RecurringInvoices'
import PortfolioDetails from './PortfolioDetails/PortfolioDetails'
import { DepositTransferModal, RefundModal } from '../../../../depositManagement/components'
import RecentActivity from './RecentActivity/RecentActivity'
import DepositAccount from './DepositAccount/DepositAccount'
import styles from './PortfolioInfo.module.scss'
import { useSelector } from 'react-redux'
import { walletApiEvents, walletApiSelectors } from '../../../../api/wallet'
import { apiStateSelectors } from '../../../../api/apiState'
import PortfoliosProvider from '../../../PortfoliosProvider'
import RefundProvider from '../../../../depositManagement/RefundProvider'
import { matchPath } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { ContactsProvider } from 'modules/contacts'
import { partyApiSelectors } from 'modules/api/party'

const propTypes = {
  redirect: PropTypes.func,
  selectedPortfolio: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onLeaseChange: PropTypes.func,
  leaseSelectorOptions: PropTypes.array,
  portfolio: PropTypes.object,
  tenantId: PropTypes.string,
}

const defaultProps = {}

const PortfolioInfo = ({
  redirect,
  selectedPortfolio,
  onLeaseChange,
  leaseSelectorOptions,
  portfolio,
  // context
  pathname,
  getPortfolioStatusDescription,
  getTerminatedPortfolioVacateDate,
  getTerminatedPortfolioTerminatedReason,
  getTerminatedPortfolioTerminatedDate,
  isReadOnly,
  depositTransferModal,
  closeDepositTransferModal,
  handleTransfer,
  openContactFromNonContactsRoute,
  tenantId,
}: any) => {
  const portfolioId = get(portfolio, 'id')
  const portfolioStatusDescription = getPortfolioStatusDescription(portfolioId)
  const terminatedPortfolioVacateDate = getTerminatedPortfolioVacateDate(portfolioId)
  const terminatedPortfolioTerminatedReason = getTerminatedPortfolioTerminatedReason(portfolioId)
  const terminatedPortfolioTerminatedDate = getTerminatedPortfolioTerminatedDate(portfolioId)
  const startDate = get(portfolio, ['leaseDetails', 'term', 0])
  const endDate = get(portfolio, ['leaseDetails', 'term', 1])
  const startDateFormatted = startDate && format(new Date(startDate), 'dd MMM yyyy')
  const endDateFormatted = endDate && format(new Date(endDate), 'dd MMM yyyy')
  const damage = get(portfolio, 'leaseDetails.deposit.damage', false)
  const service = get(portfolio, 'leaseDetails.deposit.service', false)
  const key = get(portfolio, 'leaseDetails.deposit.key', false)
  const leaseUrl = get(portfolio, 'id') && `/leases/${portfolio.id}/edit/property`
  const tenantName = get(portfolio, 'leaseDetails.tenant', '...')
  const propertyAddress = get(portfolio, 'leaseDetails.propertyAddress', '...')
  const portfolioNotes = path(['leaseDetails', 'notes'], portfolio)
  const leaseType = get(portfolio, 'leaseDetails.leaseType')
  const managementFeeSplits = get(portfolio, 'leaseDetails.managementFeeSplits', [])
  const procurementFeeSplits = get(portfolio, 'leaseDetails.procurementFeeSplits', [])
  const getPartyNameById = useSelector(partyApiSelectors.getPartyNameById)

  const leaseDetails = [
    {
      label: 'Tenant',
      value: tenantName,
      onClick: () => openContactFromNonContactsRoute(tenantId),
    },
    {
      label: 'Term',
      value: (
        <>
          {startDate ? `${startDateFormatted} -> ${endDateFormatted}` : 'Not Set'}
          <small>
            {!isEmpty(portfolioStatusDescription) ? portfolioStatusDescription : null}
            {terminatedPortfolioTerminatedDate ? terminatedPortfolioTerminatedDate : null}
            {terminatedPortfolioTerminatedDate && <br />}
            {terminatedPortfolioVacateDate ? terminatedPortfolioVacateDate : null}
            {terminatedPortfolioVacateDate && <br />}
            {terminatedPortfolioTerminatedReason ? terminatedPortfolioTerminatedReason : null}
            {terminatedPortfolioTerminatedReason && <br />}
          </small>
        </>
      ),
    },
    {
      label: 'Rental',
      value: currencyUtils.formatCurrency(get(portfolio, 'leaseDetails.rental', 0)),
    },
    {
      label: 'Deposit',
      value: (damage || service || key) && (
        <>
          <TransactionListItem label="Damage" value={currencyUtils.formatCurrency(pathOr(0, ['netAmount'], damage))} />
          <TransactionListItem
            label="Service"
            value={currencyUtils.formatCurrency(pathOr(0, ['netAmount'], service))}
          />
          <TransactionListItem label="Key" value={currencyUtils.formatCurrency(pathOr(0, ['netAmount'], key))} />
        </>
      ),
    },
    {
      label: 'Notes',
      value: portfolioNotes,
    },
    {
      label: 'Agents',
      value:
        leaseType === 'Unmanaged' ? (
          <>
            {procurementFeeSplits.map((split: any, index: number) => (
              <p key={index}>{getPartyNameById(split.agentPartyId)}</p>
            ))}
          </>
        ) : (
          <>
            {managementFeeSplits.map((split: any, index: number) => (
              <p key={index}>{getPartyNameById(split.agentPartyId)}</p>
            ))}
          </>
        ),
    },
  ]

  const partyId = get(depositTransferModal, ['partyId'])
  const accountId = get(depositTransferModal, ['account'])
  const depositAccountBalance = useSelector(state =>
    walletApiSelectors.getDepositAccountBalance(state)(partyId, accountId),
  )
  const isTransferSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletApiEvents.transfer_request]),
  )
  const isTransferLoading = useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.balance_request]))

  const portfilioViewMatchPath = matchPath(pathname, {
    path: ROUTES.portfolioView,
    exact: true,
    strict: false,
  })

  const isPortfolioViewExact = path(['isExact'], portfilioViewMatchPath)

  return (
    <div className={styles.root}>
      <PortfolioSelector
        name="leases"
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; value: any; onChange: any; l... Remove this comment to see the full error message
        value={selectedPortfolio}
        onChange={onLeaseChange}
        leases={leaseSelectorOptions}
      />

      <PortfolioDetails
        portfolioLoaded={!!portfolioId}
        leaseDetails={leaseDetails}
        redirect={redirect}
        leaseUrl={leaseUrl}
      />

      {/* @ts-expect-error ts-migrate(2786) FIXME: 'RecurringInvoices' cannot be used as a JSX component. */}
      <RecurringInvoices
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ invoices: any; }' is not assignable to typ... Remove this comment to see the full error message
        invoices={get(portfolio, 'smartInvoices', [])}
      />

      <RecentActivity portfolioId={portfolioId} />

      <RefundProvider entity="portfolio" id={portfolioId}>
        <DepositAccount portfolioId={portfolioId} />
        <RefundModal />
      </RefundProvider>

      <DepositTransferModal
        isOpen={!!depositTransferModal}
        onClose={closeDepositTransferModal}
        contactName={tenantName}
        propertyAddress={propertyAddress}
        onTransfer={handleTransfer}
        partyId={partyId}
        account={accountId}
        asset={get(depositTransferModal, ['asset'])}
        depositAccountBalance={depositAccountBalance}
        isSubmitting={isTransferSubmitting}
        isLoading={isTransferLoading}
      />

      {isPortfolioViewExact ? (
        <FileTable
          showDateTime={true}
          showDateTimeBeneath={true}
          allowRenaming={!isReadOnly}
          allowRemove={!isReadOnly}
          displayAndEditExtension={false}
          onError={() => null}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; classId: any; }' is not... Remove this comment to see the full error message
          forOwner={{ className: 'lease', classId: portfolioId }}
        />
      ) : null}
    </div>
  )
}

PortfolioInfo.propTypes = propTypes
PortfolioInfo.defaultProps = defaultProps

export default subscribe(
  [PortfoliosProvider, DepositProvider, ContactsProvider],
  (
    {
      getPortfolioStatusDescription,
      getTerminatedPortfolioVacateDate,
      getTerminatedPortfolioTerminatedReason,
      getTerminatedPortfolioTerminatedDate,
      location,
      isReadOnly,
      currentAgency,
    }: any,
    { depositTransferModal, closeDepositTransferModal, handleTransfer }: any,
    { openContactFromNonContactsRoute },
  ) => ({
    pathname: location.pathname,
    getPortfolioStatusDescription,
    getTerminatedPortfolioVacateDate,
    getTerminatedPortfolioTerminatedReason,
    getTerminatedPortfolioTerminatedDate,
    isReadOnly,
    currentAgency,
    depositTransferModal,
    closeDepositTransferModal,
    handleTransfer,
    openContactFromNonContactsRoute,
  }),
)(PortfolioInfo)

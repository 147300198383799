import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { tag } from 'rxjs-spy/operators/tag'
import { map, mergeMap, pluck } from 'rxjs/operators'
import { RootState } from 'src/state/store'
import { $TSFixMe } from 'types/ts-migrate'
import { downloadBase64 } from 'utils/fileUtils'
import { ENDPOINTS } from './constants'
import { emailApiEvents, InvoicePDFRequestAction, InvoicePDFResponse } from './state'

export const apiDownloadInvoicePdf = (
  action$: Observable<InvoicePDFRequestAction>,
  state$: RootState,
  { get, catchRestError }: $TSFixMe,
) =>
  action$.pipe(
    ofType(emailApiEvents.invoicePdf_request.toString()),
    mergeMap(action =>
      get(ENDPOINTS.INVOICE_PDF, state$, action.payload).pipe(
        pluck('response'),
        map((res: InvoicePDFResponse) => {
          downloadBase64(res.pdfBase64, 'pdf', `INV-${action.payload.invoiceNumber}.pdf`)
          return emailApiEvents.invoicePdf_success(res)
        }),
        catchRestError(action),
      ),
    ),
    tag('accounting/epics/apiDownloadInvoicePdf'),
  )

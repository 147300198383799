import React, { useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { PaymentsProvider } from '../../../providers'
import { ListingTemplate } from '../..'
import PaymentsTable from './PaymentsTable/PaymentsTable'
import SearchFilter from './SearchFilter'
import PaymentsBulkActions from './PaymentsBulkActions'
import { TableProvider } from '../../organisms/Table'
import { format, subMonths } from 'date-fns'

const Payments = ({ getPayments, isPaymentsLoading }: any) => {
  useEffect(() => {
    getPayments(format(subMonths(new Date(), 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>reOS | Payments</title>
      </Helmet>
      <TableProvider>
        <ListingTemplate
          loading={isPaymentsLoading}
          Header={<SearchFilter />}
          Body={<PaymentsTable />}
          Footer={<PaymentsBulkActions />}
        />
      </TableProvider>
    </>
  )
}

export default subscribe([PaymentsProvider], ({ getPayments, isPaymentsLoading }: any) => ({
  getPayments,
  isPaymentsLoading,
}))(Payments)

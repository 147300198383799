import React from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilter } from '../../../views/components'
import PortfoliosProvider from '../../portfolios/PortfoliosProvider'

const PropertiesSearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([PortfoliosProvider], ({ location, redirect, propertyFilters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters: propertyFilters,
}))(PropertiesSearchFilter)

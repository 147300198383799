import { ofType } from 'redux-observable'
import { map, pluck, mergeMap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { smsApiEvents } from '.'
import { ENDPOINTS } from './constants'

export const apiSendSms = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(smsApiEvents.sendSms_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.SEND_SMS, state$, action.payload).pipe(
        pluck('response'),
        map(smsApiEvents.sendSms_success),
        catchRestError(action),
      ),
    ),
    tag('sms/epics/apiSendSms'),
  )

export const apiGetOtp = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(smsApiEvents.getOtp_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.GET_OTP, state$, action.payload).pipe(
        pluck('response'),
        map(smsApiEvents.getOtp_success),
        catchRestError(action),
      ),
    ),
    tag('sms/epics/apiGetOtp'),
  )

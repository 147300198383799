import React from 'react'
import styles from './LinearProgress.module.scss'

type TProps = {
  percent: number
  strokeColor?: string
  strokeWidth?: number
  trailColor?: string
  gradient?: boolean
  gradientFills?: string[]
  gradientOpacity?: string
  style?: object
}

const LinearProgress = ({
  percent,
  strokeColor = '#048FF2',
  strokeWidth = 1,
  trailColor = 'rgba(0, 0, 0, 0.05)',
  gradient = false,
  gradientFills = ['#FED766', '#FF0734', '#048FF2', '#33CC75'],
  gradientOpacity = '0.2',
  style,
  ...props
}: TProps) => {
  const viewBox = `0 0 100 ${strokeWidth}`
  const strokeLinecap = 'square'

  const pathStyle = {
    strokeDasharray: '100px, 100px',
    strokeDashoffset: `${100 - percent}px`,
    transition: 'stroke-dashoffset 0.3s ease 0s, stroke 0.3s linear',
  }

  const center = strokeWidth / 2
  const right = 100 - center
  const pathString = `
    M ${center}, ${center}
    L ${right}, ${center}`

  return (
    <svg className={styles.line} viewBox={viewBox} preserveAspectRatio="none" style={style} {...props}>
      {gradient && (
        <defs>
          <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient" gradientTransform="rotate(-50)">
            {gradientFills.map((f: any, i: any) => (
              <stop key={i} stopColor={f} offset={`${(100 / gradientFills.length) * i}%`} />
            ))}
          </linearGradient>
        </defs>
      )}

      <path
        className={styles['line-trail']}
        d={pathString}
        strokeLinecap={strokeLinecap}
        stroke={trailColor}
        strokeWidth={strokeWidth}
        fillOpacity="0"
      />

      {gradient ? (
        <g fill="url(#linearGradient)" fillOpacity={gradientOpacity}>
          <rect x="0" y="0" width={percent} height={strokeWidth} />
        </g>
      ) : (
        <path
          className={styles['line-path']}
          d={pathString}
          strokeLinecap={strokeLinecap}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fillOpacity="0"
          // ref={path => { this.path = path }}
          style={pathStyle}
        />
      )}
    </svg>
  )
}

export default LinearProgress

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Portal from '@reach/portal'
import Notification, { propTypes as notificationPropTypes } from '../../molecules/Notification/Notification'
import styles from './NotificationCentre.module.scss'
import { useTransition, animated } from 'react-spring'
import { range, last, isNil, remove } from 'ramda'

const propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape(notificationPropTypes)),
  onDismiss: PropTypes.func,
}

const NotificationCentre = (props: any) => {
  const { notifications, onDismiss } = props
  const [keys, setKeys] = useState([])

  const currentKeys = keys.length > 0 ? keys : range(0, notifications.length)

  const keyedNotifications = notifications.map((n: any, i: any) => {
    if (isNil(currentKeys[i])) {
      // @ts-expect-error
      currentKeys.push(last(currentKeys) + 1)
    }
    return { key: currentKeys[i], ...n }
  })

  const animatedNotifications = useTransition(keyedNotifications, item => item.key, {
    config: { duration: 250 },
    from: { transform: 'translate3d(-110%, 0, 0) scale(0)' },
    enter: { transform: 'translate3d(0, 0, 0) scale(1)' },
    leave: { transform: 'translate3d(-110%, 0, 0) scale(0)' },
    unique: true,
  })

  return (
    <Portal>
      <div className={styles.root}>
        {animatedNotifications.map(({ item, props, key }, i) => (
          <animated.div key={key} style={props}>
            <Notification
              {...item}
              onDismiss={(e): void => {
                // @ts-expect-error
                setKeys(remove(i, 1, currentKeys))
                onDismiss(e, i)
              }}
              key={i}
            />
          </animated.div>
        ))}
      </div>
    </Portal>
  )
}

NotificationCentre.propTypes = propTypes

export default NotificationCentre

import React from 'react'

const SvgCalendar = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M21 7h4a1 1 0 011 1v16a1 1 0 01-1 1H7a1 1 0 01-1-1V8a1 1 0 011-1h4V5h2v2h6V5h2v2zm-2 2h-6v2h-2V9H8v4h16V9h-3v2h-2V9zm5 6H8v8h16v-8z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgCalendar

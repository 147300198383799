import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flatten, forEach, map, pipe, prop, uniq } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { propertyApiEvents } from '../property'
import { NAMESPACE } from './constants'
import { TApplicationsResponse, TCreateApplicationRequest, TPortfolioState } from './types'

const initialState: TPortfolioState = {
  allIds: [], // Used for ordering
  byId: {},
  summaries: [],
  /**
   * @todo introduce when we use search API
   * EG: `bySearchQuery: { 'query': ['portfolioId1', 'portfolioId2', 'portfolioId3'] }
   */
  // bySearchQuery: {},
  terminationReasons: [],
  invoiceSchedules: {},
  bulkInvoicesCsvTemplate: '',
  stats: {},
  status: [],
  applications: {},
  complete: {
    renewal: false,
  },
}

const handlePortfolioResponse = (state, { payload }) => {
  state.byId[payload.id] = payload
  state.allIds = uniq(state.allIds.concat(payload.id))
}

const portfolioApiSlice = createSlice({
  name: `${NAMESPACE}Api`,
  initialState,
  reducers: {
    portfolios_request: (state, { payload }: $TSFixMe) => {},
    portfolios_success: (state, { payload }) => {
      /** @todo refactor when API is updated with flatten portfolios */
      const portfolios: $TSFixMe[] = pipe(map(prop('portfolios')), flatten)(payload)
      state.allIds = map(prop('id'), portfolios)
      forEach((p: any) => {
        state.byId[p.id] = p
      }, portfolios)
    },
    portfolioStats_request: state => {},
    portfolioStats_success: (state, { payload }) => {
      state.stats = payload
    },
    portfolioSummaries_request: () => {},
    portfolioSummaries_success: (state, { payload }) => {
      state.summaries = payload
    },
    portfolio_request: (state, { payload }: { payload: string }) => {},
    portfolio_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    createPortfolio_request: (state, { payload }: $TSFixMe) => {},
    createPortfolio_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    amendProperty_request: (state, { payload }: $TSFixMe) => {},
    amendProperty_success: {
      reducer: (state, { payload }: $TSFixMe) => {
        handlePortfolioResponse(state, { payload })
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    amendTerms_request: (state, { payload }: $TSFixMe) => {},
    amendTerms_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    amendRentAndFees_request: (state, { payload }: $TSFixMe) => {},
    amendRentAndFees_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    amendCommission_request: (state, { payload }) => {},
    amendCommission_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    amendSettings_request: (state, { payload }: $TSFixMe) => {},
    amendSettings_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    amendMetaData_request: (state, { payload }: { payload: { params: $TSFixMe; body: { data: string } } }) => {},
    amendMetaData_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    invoiceSchedules_request: (state, { payload }: { payload: string }) => {},
    invoiceSchedules_success: (state, { payload: { params, response } }) => {
      state.invoiceSchedules[params.id] = response.schedules
    },
    approvalRequest_request: (state, { payload }: $TSFixMe) => {},
    approvalRequest_success: (state, { payload }: $TSFixMe) => {},
    approve_request: (state, { payload }: $TSFixMe) => {},
    approve_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    /** @todo re-introduce when multi-user is added */
    // declineRequested: () => {},
    // declined => () => {},
    addInvoiceTemplate_request: (state, { payload }: $TSFixMe) => {},
    addInvoiceTemplate_success: {
      reducer: (state, { payload, meta }) => {
        handlePortfolioResponse(state, { payload })
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    updateInvoiceTemplate_request: (state, { payload }: $TSFixMe) => {},
    updateInvoiceTemplate_success: {
      reducer: (state, { payload, meta }) => {
        handlePortfolioResponse(state, { payload })
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    invoiceTemplateChanged: (state, { payload }: $TSFixMe) => {},
    invoiceTemplateRemoved: (state, { payload }: $TSFixMe) => {},
    /** @todo will probably need to stage removed templates so ID's can be posted on save */
    removeInvoiceTemplate_request: (state, { payload }: { payload: { id: string; invoiceId: string } }) => {},
    removeInvoiceTemplate_success: (state, { payload }: $TSFixMe) => {
      handlePortfolioResponse(state, { payload })
    },
    searchResults_request: (state, { payload }: $TSFixMe) => {},
    searchResults_success: (state, { payload }: $TSFixMe) => {},
    terminationReasons_request: () => {},
    terminationReasons_success: (state, { payload }) => {
      state.terminationReasons = payload
    },
    terminateApprovedLease_request: (state, { payload }) => {},
    terminateApprovedLease_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    deleteDraftLease_request: (state, { payload }: { payload: string }) => {},
    deleteDraftLease_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    renewal_request: (state, { payload }: $TSFixMe) => {},
    renewal_success: {
      reducer: (state, { payload, meta }) => {
        handlePortfolioResponse(state, { payload }),
          (state.byId[payload.id].renewal = payload.renewal),
          (state.complete.renewal = true)
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    cancelRenewal_request: (state, { payload }: $TSFixMe) => {},
    cancelRenewal_success: (state, { payload }: $TSFixMe) => {
      delete state.byId[payload.id].renewal
    },
    amendSegments_request: (state, { payload }) => {},
    amendSegments_success: (state, { payload }) => {
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        segments: payload.segments,
      }
      state.summaries = state.summaries.map(summary =>
        summary.portfolioId === payload.id ? { ...summary, segments: payload.segments } : summary,
      )
    },
    updateTenantParties_request: (state, { payload }: { payload: { params: $TSFixMe; body: $TSFixMe } }) => {},
    updateTenantParties_success: {
      reducer: (state, { payload, meta }) => {
        /** @todo update this? */
        state.byId[meta.id] = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    updateOwnerParties_request: (state, { payload }: { payload: { params: $TSFixMe; body: $TSFixMe } }) => {},
    updateOwnerParties_success: {
      reducer: (state, { payload, meta }) => {
        /** @todo update this? */
        state.byId[meta.id] = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    addPartyAndLinkToLease_request: (
      state,
      { payload }: { payload: { id: string; values: $TSFixMe; type: 'tenant' | 'landlord' } },
    ) => {},
    addPartyAndLinkToLease_success: () => {},
    bulkInvoicesCsvTemplate_request: () => {},
    bulkInvoicesCsvTemplate_success: (state, { payload }) => {
      state.bulkInvoicesCsvTemplate = payload.csvBase64
    },
    getStatus_request: (state, action: PayloadAction<string[]>) => {},
    getStatus_success: (state, { payload }: PayloadAction<{ portfolioId: string; status: string }[]>) => {
      state.status = payload
    },

    /**
     * Applications
     */

    getApplications_request: (state, { payload }: PayloadAction<{ id: string }>) => {},
    getApplications_success: (state, { payload }: PayloadAction<TApplicationsResponse>) => {
      state.applications[payload.portfolioId] = payload.applications
    },
    addApplication_request: (
      state,
      { payload }: PayloadAction<{ params: { id: string }; body: TCreateApplicationRequest }>,
    ) => {},
    addApplication_success: (state, { payload }: PayloadAction<TApplicationsResponse>) => {
      state.applications[payload.portfolioId] = payload.applications
    },
    deleteApplication_request: (state, { payload }: PayloadAction<{ id: string; applicationId: string }>) => {},
    deleteApplication_success: (state, { payload }: PayloadAction<TApplicationsResponse>) => {
      state.applications[payload.portfolioId] = payload.applications
    },
    addApplicationAndLinkToLease_request: (
      state,
      { payload }: PayloadAction<{ id: string; values: $TSFixMe; applicationFee: number }>,
    ) => {},
    addApplicationAndLinkToLease_success: () => {},
    updateApplication_request: (
      state,
      { payload }: PayloadAction<{ id: string; values: $TSFixMe; applicationId: string }>,
    ) => {},
    updateApplication_success: (state, { payload }: PayloadAction<TApplicationsResponse>) => {},
    acceptApplication_request: (state, { payload }: PayloadAction<{ id: string; applicationId: string }>) => {},
    acceptApplication_success: (state, { payload }) => {
      handlePortfolioResponse(state, { payload })
    },
    sendApplicationInvoice_request: (
      state,
      { payload }: PayloadAction<{ portfolioId: string; applicationId: string }>,
    ) => {},
    sendApplicationInvoice_success: (
      state,
      { payload }: PayloadAction<{ portfolioId: string; applicationId: string; invoiceId: string; status: string }>,
    ) => {},
  },
  extraReducers: {
    // @ts-expect-error
    [propertyApiEvents.propertyPortfolios_success]: (state, { payload, meta }) => {
      const ids = payload.response.map((r: any) => r.id)
      state.allIds = uniq([...state.allIds, ...ids])
      payload.response.forEach((portfolio: any) => {
        state.byId[portfolio.id] = portfolio
      })
    },
  },
})

const { reducer, actions: events } = portfolioApiSlice

export { events, initialState, reducer }

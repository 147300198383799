import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { pathOr } from 'ramda'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { uiEvents } from '../../../ui'
import { RootState } from 'src/state/store'

const CommissionStatement = ({ match }: any): React.ReactElement => {
  const dispatch = useDispatch()

  const fetchCommissionStatement = useCallback(
    (startDate, endDate, event = accountingApiEvents.commissionStatementJson_request) => {
      dispatch(
        event({
          start_date: startDate,
          end_date: endDate,
        }),
      )
    },
    [dispatch],
  )

  const onClose = useCallback(() => dispatch(uiEvents.redirect('/dashboard')), [dispatch])

  const fetchCommissionStatementPdf = useCallback(
    (startDate, endDate) =>
      fetchCommissionStatement(startDate, endDate, accountingApiEvents.commissionStatementPdf_request),
    [fetchCommissionStatement],
  )

  const fetchCommissionStatementCsv = useCallback(
    (startDate, endDate) =>
      fetchCommissionStatement(startDate, endDate, accountingApiEvents.commissionStatementCsv_request),
    [fetchCommissionStatement],
  )

  const statementData = useSelector((state: RootState) => accountingApiSelectors.getCommissionStatementJson(state))
  const statementPdf = useSelector((state: RootState) => accountingApiSelectors.getCommissionStatementPdf(state))
  const statementCsv = useSelector((state: RootState) => accountingApiSelectors.getCommissionStatementCsv(state))
  const fileName = `${pathOr('', ['context', 'agency', 'name'], statementData) as string} Commission Statement`

  return useMemo(() => {
    return (
      <ViewStatementProvider
        isOpen
        onClose={onClose}
        onParamChange={fetchCommissionStatement}
        statementData={statementData}
        downloadStatement={fetchCommissionStatementPdf}
        statementPdf={statementPdf}
        statementCsv={statementCsv}
        pdfFileName={fileName}
        csvFileName={fileName}
        downloadStatementCsv={fetchCommissionStatementCsv}
      >
        <ViewStatement title="Commission Statement" />
      </ViewStatementProvider>
    )
  }, [
    onClose,
    fetchCommissionStatement,
    fetchCommissionStatementPdf,
    fetchCommissionStatementCsv,
    statementData,
    statementPdf,
    statementCsv,
    fileName,
  ])
}

export default CommissionStatement

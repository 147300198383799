import React from 'react'
import PropTypes from 'prop-types'
import { AggregateListItem, Divider, List } from '../../../../../views/components'
import { formatCurrency } from '../../../../../utils/currency'

const propTypes = {
  releaseDeposit: PropTypes.oneOf(['true', 'false']).isRequired,
  depositAccountBalance: PropTypes.number.isRequired,
}

const NextSteps = ({ releaseDeposit, depositAccountBalance = 0 }: any) => {
  const housingActCopy = (
    <>
      reOS recommends following{' '}
      <a href="https://www.gov.za/documents/rental-housing-act" target="_blank" rel="noopener noreferrer">
        Rental Housing Act
      </a>{' '}
      regulations when handling deposits.
    </>
  )
  const nextSteps = [
    {
      mainText: 'The owner will received notification of lease termination',
      checked: true,
      isVisible: true,
    },
    {
      mainText: 'The tenant will receive notification of lease termination',
      checked: true,
      isVisible: true,
    },
    {
      mainText:
        releaseDeposit === 'true' ? (
          <span>
            The tenant&apos;s deposit ({formatCurrency(parseInt(depositAccountBalance || 0))}) will be released
            immediately. {housingActCopy}
          </span>
        ) : (
          <span>
            The tenant&apos;s deposit ({formatCurrency(parseInt(depositAccountBalance || 0))}) will not be released
            immediately. Funds will stay in the deposit wallet potentially earning interest. {housingActCopy}
          </span>
        ),
      checked: true,
      isVisible: depositAccountBalance,
    },
  ].filter(step => step.isVisible)

  return (
    <>
      <Divider style={{ marginBottom: '20px' }}>
        <h3 style={{ marginBottom: 0 }}>What happens next?</h3>
      </Divider>

      <List type="checklist">
        {nextSteps.map((item, i) => (
          <AggregateListItem
            key={i}
            active={!!item.checked}
            text={item.mainText}
            // secondaryText={item.secondaryText}
          />
        ))}
      </List>
    </>
  )
}

NextSteps.propTypes = propTypes

export default NextSteps

import React from 'react'
import { moduleContext } from 'react-contextual'

const CreditNoteProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = {
    ...props,
  }
  return <context.Provider value={store}>{children}</context.Provider>
})

export default CreditNoteProvider

import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'
import styles from './FormFieldButton.module.scss'

interface FormFieldButtonProps extends HTMLAttributes<HTMLButtonElement> {
  noOutline?: boolean
  active?: boolean
  state?: 'default' | 'danger'
  children: React.ReactNode
}
const FormFieldButton = ({
  noOutline = false,
  active = false,
  state = 'default',
  children,
  ...props
}: FormFieldButtonProps) => {
  const classes = classNames([styles.root, styles[`state--${state}`]], {
    [styles['no-outline']]: noOutline,
    [styles.active]: active,
  })

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  )
}

export default FormFieldButton

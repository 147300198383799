import React from 'react'

const ArrowNext = (props: any) => (
  <svg width={16} height={16} {...props}>
    <path
      fill="#000"
      fillOpacity={0.8}
      d="M1.637 0h5.415a1 1 0 0 1 0 2H2.167A.167.167 0 0 0 2 2.167v8.813c0 .092.075.167.167.167h9.148a.167.167 0 0 0 .167-.167V8.961a.667.667 0 0 1 1.138-.471l3.185 3.185c.26.26.26.683 0 .943L12.62 15.8a.667.667 0 0 1-.727.15.667.667 0 0 1-.411-.617v-2.02a.167.167 0 0 0-.167-.166H1.637A1.639 1.639 0 0 1 0 11.509V1.635A1.64 1.64 0 0 1 1.637 0z"
      fillRule="evenodd"
    />
  </svg>
)

export default ArrowNext

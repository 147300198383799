import React from 'react'
import { filter, map, mapTo, mergeMap, pluck } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { pathOr, pipe, map as Rmap, path, flatten } from 'ramda'
import { events } from './state'
import { invoiceApiConstants, invoiceApiEvents, invoiceApiTransformers } from '../../api/invoice'
import { restfulErrorEvent } from '../../../utils/restful'
import { bulkImportInvoicesSelectors } from '.'
import { notificationEvents } from '../../notificationCenter'
import { reconApiEvents } from '../../api/recon'
import { partyApiEvents } from '../../api/party'
import { $TSFixMe } from 'types/ts-migrate'

export const bulkCreateInvoices = (action$: any, state$: any, { post, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(events.bulkCreateInvoices),
    mergeMap((action: $TSFixMe) => {
      return post(invoiceApiConstants.ENDPOINTS.BULK_INVOICES, state$, {
        invoices: action.payload.map(invoiceApiTransformers.transformNewInvoiceRequest),
      })
        .pipe(
          pluck('response'),
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          mapTo(invoiceApiEvents.saveBulkInvoices_success()),
          catchRestError(invoiceApiEvents.saveBulkInvoices_request),
        )
        .pipe(
          mergeMap(responseAction => {
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            if (responseAction.type === restfulErrorEvent.toString()) {
              return [responseAction]
            }
            return action.payload.map((invoice: any) =>
              invoiceApiEvents.createOnceOffInvoice_request(
                { values: invoice, sendAfterCreate: false },
                { id: invoice.id, fromBulkImport: true },
              ),
            )
          }),
        )
    }),
    tag('bulkImportInvoices/epics/bulkCreateInvoices'),
  )

export const handleSuccessfulImport = (action$: any, state$: any): void =>
  action$.pipe(
    ofType(invoiceApiEvents.createOnceOffInvoice_success),
    filter((action: $TSFixMe) => action?.meta?.fromBulkImport === true),
    filter(
      () =>
        !bulkImportInvoicesSelectors.isImporting(state$.value) &&
        bulkImportInvoicesSelectors.getFailed(state$.value).length === 0,
    ),
    map(() => {
      return notificationEvents.addNotification(
        {
          type: 'success',
          heading: 'Import successful!',
          children: <p>All your invoices were imported.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: true },
      )
    }),
    tag('bulkImportInvoices/epics/handleSuccessfulImport'),
  )

export const handleFailedImports = (action$: any, state$: any): $TSFixMe =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(
      (action: $TSFixMe) => action?.meta?.initAction.type === invoiceApiEvents.createOnceOffInvoice_request.toString(),
    ),
    filter(() => !bulkImportInvoicesSelectors.isImporting(state$.value)),
    map(() => {
      return notificationEvents.addNotification(
        {
          type: 'error',
          heading: 'Error',
          children: <p>There was a problem importing some of the invoices, please try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('bulkImportInvoices/epics/handleFailedImports'),
  )

export const prefetchBeneficiaryParties = (action$: any, state$: any): $TSFixMe =>
  action$.pipe(
    ofType(invoiceApiEvents.fetchBulkInvoices_success, reconApiEvents.bulkInvoiceUpload_success),
    pluck('payload', 'invoices'),
    mergeMap(
      // @ts-expect-error
      pipe(
        Rmap(pathOr([], ['beneficiaries'])),
        Rmap(Rmap(path(['beneficiary', 'value', 'partyId']))),
        // @ts-expect-error
        flatten,
        Rmap(partyApiEvents.party_request),
      ),
    ),
    tag('bulkImportInvoices/epics/prefetchBeneficiaryParties'),
  )

import React from 'react'
import ContactsProvider from '../../state/ContactsProvider'
import { EditContact } from '../..'

const EditContactRoute = (props: any) => (
  <ContactsProvider {...props}>
    <EditContact />
  </ContactsProvider>
)

export default EditContactRoute

import { morphism } from 'morphism'
import { $TSFixMe } from 'types/ts-migrate'
import { currencyUtils } from '../../../utils'

const { toCents, fromCents } = currencyUtils

const invoiceResponseSchema = {
  id: 'id',
  agencyId: 'agencyId',
  invoiceType: 'invoiceType',
  accountId: 'accountId',
  customerId: 'customerId',
  customerName: 'customerName',
  customerAvailableFunds: ({ customerAvailableFunds }: any) => toCents(customerAvailableFunds).getAmount(),
  dueDate: 'dueDate',
  grossAmount: ({ grossAmount }: any) => toCents(grossAmount).getAmount(),
  vatAmount: ({ vatAmount }: any) => toCents(vatAmount).getAmount(),
  netAmount: ({ netAmount }: any) => toCents(netAmount).getAmount(),
  balance: ({ balance }: any) => toCents(balance).getAmount(),
  portfolioId: 'portfolioId',
  beneficiaries: ({ beneficiaries }: any) =>
    beneficiaries.map((b: any) => ({
      ...b,

      beneficiary: {
        ...b.beneficiary,
        value: {
          ...b.beneficiary.value,
          amount: toCents(b.beneficiary.value.amount).getAmount(),
        },
      },

      balance: toCents(b.balance).getAmount(),
    })),
  payments: 'payments',
  creditNotes: ({ creditNotes = [] }) =>
    creditNotes.map((b: $TSFixMe) => ({
      ...b,
      amount: toCents(b.amount).getAmount(),
      beneficiaries: b.beneficiaries.map((bb: $TSFixMe) => ({
        ...bb,
        amount: toCents(bb.amount).getAmount(),
      })),
    })),
  invoiceStatus: 'invoiceStatus',
  reconStatus: 'reconStatus',
  invoiceNumber: 'invoiceNumber',
  property: 'property',
  description: 'description',
}

export const transformInvoiceResponse = (source: any): $TSFixMe => morphism(invoiceResponseSchema, source)

const creditNoteRequestSchema = {
  amount: ({ amount }: any) => fromCents(amount),
  reason: 'reason',
  description: 'description',
  userUnderstandsRisk: 'userUnderstandsRisk',
  beneficiaries: ({ beneficiaries }: any) =>
    beneficiaries.map((b: any) => ({
      beneficiaryId: b.beneficiaryId,
      amount: fromCents(b.amount),
    })),
}

export const transformCreditNoteRequest = (source: any): $TSFixMe => morphism(creditNoteRequestSchema, source)

const customerAccountActivitySchema = {
  customerName: 'customerName',
  availableFunds: ({ availableFunds }: any) => toCents(availableFunds).getAmount(),
  amountOutstanding: ({ amountOutstanding }: any) => toCents(amountOutstanding).getAmount(),
  openInvoices: ({ openInvoices }: any) =>
    openInvoices.map((invoice: any) => ({
      ...invoice,
      balance: toCents(invoice.balance).getAmount(),
    })),
  recentActivity: ({ recentActivity }: any) =>
    recentActivity.map((ra: any) => ({
      paymentReference: ra.paymentReference,
      sourceId: ra.sourceId,
      sourceType: ra.sourceType,
      customerId: ra.customerId,
      accountId: ra.accountId,
      date: ra.date,
      description: ra.description,
      isCredit: ra.isCredit,
      amount: toCents(ra.amount).getAmount(),
    })),
}

export const transformCustomerAccountActivity = (source: any): $TSFixMe =>
  morphism(customerAccountActivitySchema, source)

const activeInvoiceSchema = {
  invoiceId: 'invoiceId',
  description: 'description',
  balance: ({ balance }: any) => toCents(balance).getAmount(),
  invoiceStatus: 'invoiceStatus',
}

export const transformActiveInvoices = (source: any): $TSFixMe => morphism(activeInvoiceSchema, source)

const createPaymentRequestSchema = {
  beneficiaryId: 'beneficiaryId',
  amount: ({ amount }: any) => fromCents(amount),
}

export const transformCreatePaymentRequest = (source: any): $TSFixMe => morphism(createPaymentRequestSchema, source)

const updateBeneficiariesRequestSchema = {
  type: 'beneficiary.type',
  value: (b: any) => {
    const {
      beneficiary: { type, value },
    } = b
    switch (type) {
      case 'EasyPayBeneficiary':
        return {
          beneficiaryTag: value.beneficiaryTag,
          easyPayReference: value.easyPayReference,
          vat: value.vat || false,
          amount: fromCents(value.amount),
          id: value.id,
        }
      case 'DepositBeneficiary':
        return {
          partyId: value.partyId,
          beneficiaryTag: value.beneficiaryTag,
          accountId: value.accountId,
          reference: value.reference,
          amount: fromCents(value.amount),
          transfer: value.transfer,
          vat: value.vat,
          id: value.id,
        }
      default:
        return {
          partyId: value.partyId,
          partyTag: value.partyTag,
          reference: value.reference,
          amount: fromCents(value.amount),
          vat: value.vat,
          id: value.id,
        }
    }
  },
}

export const transformUpdateBeneficiariesRequest = (source: any): $TSFixMe =>
  morphism(updateBeneficiariesRequestSchema, source)

const updateBeneficiariesResponseSchema = {
  status: 'status',
  invoices: ({ invoices }) => invoices?.map((inv: $TSFixMe) => transformInvoiceResponse(inv)),
  beneficiaries: ({ beneficiaries }: any) =>
    beneficiaries.map((b: any) => {
      const {
        beneficiary: { value },
      } = b
      return {
        ...b,
        beneficiary: {
          ...b.beneficiary,
          value: {
            ...value,
            amount: toCents(value.amount).getAmount(),
          },
        },
        balance: toCents(b.balance).getAmount(),
      }
    }),
}

export const transformUpdateBeneficiariesResponse = (source: any): $TSFixMe =>
  morphism(updateBeneficiariesResponseSchema, source)

const transformStatsSchema = {
  settledPercentageLast60Days: 'settledPercentageLast60Days',
  unallocatedFunds: ({ unallocatedFunds }: any) => toCents(unallocatedFunds).getAmount(),
  availableFunds: ({ availableFunds }: any) => toCents(availableFunds).getAmount(),
}

export const transformStats = (source: any): $TSFixMe => morphism(transformStatsSchema, source)

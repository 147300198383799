import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { apiStateSelectors } from 'modules/api/apiState'
import { portfolioApiEvents } from 'modules/api/portfolio'
import { partyApiSelectors } from 'modules/api/party'
import { reconApiEvents, reconApiSelectors } from 'modules/api/recon'
import { TApplication, TTenant } from 'modules/api/portfolio/types'
import { ContextMenu, Table, Label, ControlledTooltip, Button } from 'views/components'
import ConfirmAcceptApplicationModal from '../ConfirmAcceptApplicationModal/ConfirmAcceptApplicationModal'
import SendApplicationInvoiceModal from '../SendApplicationInvoiceModal/SendApplicationInvoiceModal'
import ConfirmDeleteApplicationModal from '../ConfirmDeleteApplicationModal/ConfirmDeleteApplicationModal'
import RefundApplicationFeeModal from '../RefundApplicationFeeModal/RefundApplicationFeeModal'
import ApplicationFeeControl from '../ApplicationFeeControl/ApplicationFeeControl'
import { splitCamelCase } from 'utils/string'
import { uiEvents } from 'modules/ui'
import styles from './MultiApplicationsTable.module.scss'
import { currencyUtils } from 'utils/index'
import { invoiceApiSelectors } from 'modules/api/invoice'
import { agencyApiSelectors } from 'modules/api/agency'

type Props = {
  applicationDetails: TApplication[]
  portfolioId: string
  onEdit(id: string): void
  allowEdit: boolean
  tenants: TTenant
}

type ApplicationAction = {
  label: string
  onSelect: () => void
  isHidden: boolean
}

const MultiApplicationsTable = ({ applicationDetails, portfolioId, allowEdit, onEdit, tenants }: Props) => {
  const dispatch = useDispatch()
  const getPartyName = useSelector(partyApiSelectors.getPartyNameById)
  const getPartyEmail = useSelector(partyApiSelectors.getPartyEmailById)

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isSendInvoiceModalOpen, setIsSendInvoiceModalOpen] = useState(false)
  const [isRefundFeeModalOpen, setIsRefundFeeModalOpen] = useState(false)
  const [selectedParty, setSelectedParty] = useState({ partyName: '', applicationId: '', reconStatus: '' })
  const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(-1)
  const [selectedApplicationFeeIndex, setSelectedApplicationFeeIndex] = useState(-1)

  const getSelectedApplication = (index: number) => applicationDetails.find((a, i) => i === index)

  const fetchApplicationInvoiceStatusByPortfolioId = useCallback(() => {
    dispatch(reconApiEvents.applicationInvoiceByPortfolio_request({ portfolioId }))
  }, [portfolioId, dispatch])

  useEffectOnce(() => {
    fetchApplicationInvoiceStatusByPortfolioId()
  })

  const applicationInvoicesStatus = useSelector(state =>
    reconApiSelectors.getApplicationInvoicesStatusByPortfolioId(state)(portfolioId),
  )

  const getInvoiceById = useSelector(invoiceApiSelectors.getInvoiceById)

  const isAcceptApplicationLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([portfolioApiEvents.acceptApplication_request]),
  )

  const getCurrentAgencyGlobalVatEnabled = useSelector(state =>
    agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state),
  )

  const transformParty = (id: string) => ({
    id: id,
    name: getPartyName(id),
    email: getPartyEmail(id),
  })

  const handleAcceptApplication = (id: string, applicationId: string) => {
    dispatch(portfolioApiEvents.acceptApplication_request({ id, applicationId }))
  }

  const acceptedApplication = applicationDetails.find((application: TApplication) => application.status === 'Accepted')

  const isAnAcceptedApplication = acceptedApplication !== undefined

  const handleDeleteApplication = (id: string, applicationId: string) => {
    dispatch(portfolioApiEvents.deleteApplication_request({ id, applicationId }))
  }

  const getReconStatusByApplicationId = (applicationId: string) => {
    const reconStatus = applicationInvoicesStatus.find(
      applicationInvoice => applicationInvoice.applicationId === applicationId,
    )
    const invoice = getInvoiceById(applicationId)
    const isInvoiceSent = invoice && invoice.tags.status === 'Open'
    const newStatus = reconStatus ? reconStatus.status : isInvoiceSent ? 'InvoiceSent' : 'NoFeeSet'
    switch (newStatus) {
      case 'CreditNoted':
        return 'CreditNote'
      default:
        return newStatus
    }
  }

  const partiesData = applicationDetails.map((application: TApplication, index: number) => {
    const partyId = application.partyId
    const partyDetails = transformParty(partyId)
    const reconStatus =
      application.applicationFee > 0 && getReconStatusByApplicationId(application.applicationId) === 'NoFeeSet'
        ? 'DraftInvoice'
        : getReconStatusByApplicationId(application.applicationId)

    let applicationActions: ApplicationAction[] = []
    let acceptedApplicationActions: ApplicationAction[] = []

    const viewInvoiceAction = {
      label: 'View Invoice',
      onSelect: () => {
        if (reconStatus === 'CreditNoted' || reconStatus === 'CreditNote' || reconStatus === 'Paid') {
          dispatch(uiEvents.redirect(`/invoices/history/${application.applicationId}`))
        } else {
          dispatch(uiEvents.redirect(`/invoices/active/${application.applicationId}`))
        }
      },
      isHidden: reconStatus === 'NoFeeSet' || application.applicationFee === 0,
    }

    const acceptApplication = {
      label: 'Accept',
      onSelect: () => {
        setIsAcceptModalOpen(true)
        setSelectedParty({ partyName: partyDetails.name, applicationId: application.applicationId, reconStatus })
      },
      isHidden: !allowEdit,
    }

    const refundInvoiceAction = {
      label: 'Refund',
      onSelect: () => {
        setIsRefundFeeModalOpen(true)
        // setSelectedApplication(index)
      },
      isHidden: !allowEdit,
    }

    const deleteAction = {
      label: 'Delete',
      onSelect: () => {
        setIsDeleteModalOpen(true)
        setSelectedParty({ partyName: partyDetails.name, applicationId: application.applicationId, reconStatus })
      },
      isHidden: !allowEdit,
    }

    switch (reconStatus) {
      case 'NoFeeSet':
        applicationActions = [acceptApplication, deleteAction]
        break
      case 'DraftInvoice':
        applicationActions = [
          {
            label: 'Send Invoice',
            onSelect: () => {
              setIsSendInvoiceModalOpen(true)
              setSelectedApplicationIndex(index)
            },
            isHidden: application.applicationFee === 0,
          },
          acceptApplication,
          deleteAction,
        ]
        break
      case 'Pending':
      case 'InvoiceSent':
      case 'PartiallyPaid':
      case 'CreditNoted':
      case 'CreditNote':
        applicationActions = [viewInvoiceAction, acceptApplication]
        break
      case 'Paid':
        applicationActions = [
          viewInvoiceAction,
          acceptApplication,
          // refundInvoiceAction,
        ]
        break
      default:
        applicationActions = []
    }

    switch (reconStatus) {
      case 'Paid':
        acceptedApplicationActions = [
          // refundInvoiceAction,
          viewInvoiceAction,
        ]
        break
      default:
        acceptedApplicationActions = []
    }

    return {
      applicationDetails: (
        <span className={styles['applications-details']}>
          <strong>{partyDetails.name}</strong>
          <span className={styles.subtext}>{partyDetails.email}</span>
        </span>
      ),
      applicationFeeControl: (
        <div className={styles['application-fee']}>
          <ApplicationFeeControl
            partyId={partyId}
            portfolioId={portfolioId}
            applicationId={application.applicationId}
            isVatActive={getCurrentAgencyGlobalVatEnabled ? true : application.applicationFeeVat}
            value={application.applicationFee}
            isOpen={selectedApplicationFeeIndex === index}
            active={selectedApplicationFeeIndex === index}
            onClose={() => setSelectedApplicationFeeIndex(-1)}
          >
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body="Edit application fee" place="above">
              <Button
                disabled={
                  reconStatus === 'InvoiceSent' ||
                  reconStatus === 'PartiallyPaid' ||
                  reconStatus === 'CreditNoted' ||
                  reconStatus === 'CreditNote' ||
                  reconStatus === 'Paid' ||
                  isAnAcceptedApplication
                }
                ghost
                className={styles['edit-application-fee']}
                onClick={() => {
                  setSelectedApplicationFeeIndex(index)
                }}
              >
                {currencyUtils.formatCurrency(application?.applicationFee || 0)}
              </Button>
            </ControlledTooltip>
          </ApplicationFeeControl>
        </div>
      ),
      reconStatus: (
        <span className={styles['recon-status']}>
          <Label circular className={styles[`status-${reconStatus.toLowerCase()}`]}>
            {splitCamelCase(reconStatus)}
          </Label>
        </span>
      ),
      actions:
        acceptedApplication && application.partyId === acceptedApplication.partyId ? (
          <>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body={application.status} place="above">
              <Button disabled={false} ghost className={styles['application-status-accepted']} />
            </ControlledTooltip>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body="Edit contact" place="above">
              <Button disabled={false} ghost className={styles.edit} onClick={() => onEdit(application.partyId)} />
            </ControlledTooltip>
          </>
        ) : acceptedApplication ? (
          <>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body="Rejected" place="above">
              <Button disabled={false} ghost className={styles['application-status-rejected']} />
            </ControlledTooltip>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body="Edit contact" place="above">
              <Button disabled={false} ghost className={styles.edit} onClick={() => onEdit(application.partyId)} />
            </ControlledTooltip>
            <ContextMenu
              name="applicationContextMenu"
              items={acceptedApplicationActions}
              position="left"
              showInput={false}
              hideIfNoOptions={true}
            />
          </>
        ) : (
          <>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <ControlledTooltip body="Edit contact" place="above">
              <Button disabled={false} ghost className={styles.edit} onClick={() => onEdit(application.partyId)} />
            </ControlledTooltip>
            <ContextMenu
              name="applicationContextMenu"
              items={applicationActions}
              position="left"
              showInput={false}
              hideIfNoOptions={true}
            />
          </>
        ),
    }
  })

  return (
    <div className={styles.root}>
      {partiesData.length > 0 ? (
        <>
          <Table type="default">
            <Table.Body>
              {partiesData.map((party: any, i: any) => (
                <Table.Row key={i}>
                  <Table.Cell style={{ paddingLeft: '15px' }}>{party.applicationDetails}</Table.Cell>
                  <Table.Cell style={{ paddingRight: '15px', justifyContent: 'flex-end' }}>
                    {party.applicationFeeControl}
                    {party.reconStatus}
                    {party.actions}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <SendApplicationInvoiceModal
            portfolioId={portfolioId}
            application={getSelectedApplication(selectedApplicationIndex)}
            isOpen={isSendInvoiceModalOpen}
            onCancel={() => setIsSendInvoiceModalOpen(false)}
          />
          <ConfirmAcceptApplicationModal
            reconStatus={selectedParty.reconStatus}
            party={selectedParty.partyName}
            isOpen={isAcceptModalOpen}
            isLoading={isAcceptApplicationLoading}
            onAccept={() => {
              handleAcceptApplication(portfolioId, selectedParty.applicationId)
              setIsAcceptModalOpen(false)
            }}
            onCancel={() => setIsAcceptModalOpen(false)}
          />
          <ConfirmDeleteApplicationModal
            party={selectedParty.partyName}
            isOpen={isDeleteModalOpen}
            isLoading={false}
            onDelete={() => {
              handleDeleteApplication(portfolioId, selectedParty.applicationId)
              setIsDeleteModalOpen(false)
            }}
            onCancel={() => setIsDeleteModalOpen(false)}
          />
          {/* <RefundApplicationFeeModal
            partyName={selectedApplicationIndex.partyName}
            applicationFee={selectedApplicationIndex.applicationFee}
            isOpen={isRefundFeeModalOpen}
            onCancel={() => setIsRefundFeeModalOpen(false)}
          /> */}
        </>
      ) : (
        <div className={styles['no-content']}>
          {tenants.primary !== undefined ? (
            <p>Applications cannot be added once a tenant is selected.</p>
          ) : (
            <p>No applications yet. Add one by clicking the plus icon to the top right.</p>
          )}
        </div>
      )}
    </div>
  )
}

export default MultiApplicationsTable

import { createSelector } from 'reselect'
import { addHours, format } from 'date-fns'
import { get } from 'lodash-es'
import { sortBy, prop, pipe, map } from 'ramda'
import queryString from 'query-string'
import { hoursToThrottleNudge } from '../../api/recon/utils'
import { invoiceApiSelectors } from '../../api/invoice'
import { reconApiSelectors } from '../../api/recon'
import {
  getInvoiceTypes,
  getInvoiceTypesForAdhocInvoices,
  getInvoiceTypesForNonAdhocInvoices,
} from 'modules/api/invoice/selectors'

const state = (state: any) => state

const invoiceApiState = (state: any) => state.api.invoice

const uiInvoiceState = (state: any) => state.ui.invoices

export const animations = createSelector(uiInvoiceState, s => s.animations)

/**
 * Draft Invoices
 */
export const draftInvoiceFilters = createSelector(state, state => {
  const { portfolioId } = queryString.parse(queryString.extract(window.location.href))
  const extraKeys = portfolioId ? { portfolioId } : {}

  const notSetInvoices = invoiceApiSelectors.getDraftInvoicesByLightStatus(state)('Amber', portfolioId)
  const readyInvoices = invoiceApiSelectors.getDraftInvoicesByLightStatus(state)('Green', portfolioId)
  const deletedDraftInvoices = invoiceApiSelectors.getRemovedDraftInvoicesByLightStatus(state)('Red', portfolioId)

  const filters = [
    {
      extraKeys,
      key: 'notset',
      label: 'Not set',
      status: 'Amber',
      count: notSetInvoices.length,
      invoices: notSetInvoices,
    },
    {
      extraKeys,
      key: 'ready',
      label: 'Ready',
      status: 'Green',
      count: readyInvoices.length,
      invoices: readyInvoices,
    },
  ]

  if (deletedDraftInvoices.length > 0) {
    filters.push({
      extraKeys,
      key: 'deleted',
      label: 'Deleted',
      status: 'Red',
      count: deletedDraftInvoices.length,
      invoices: deletedDraftInvoices,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ extraKeys: { portfolioId: stri... Remove this comment to see the full error message
      hideCount: true,
    })
  }

  return filters
})

/**
 * Active Invoices
 */

export const activeInvoiceFilters = createSelector(state, state => {
  /**
   * @todo Remove injectId and make sure everything refers to inv.id instead
   */
  const injectId = (inv: any) => ({
    ...inv,
    invoiceId: inv.id,
  })
  const { portfolioId } = queryString.parse(queryString.extract(window.location.href))
  const extraKeys = portfolioId ? { portfolioId } : {}

  const overdueInvoices = reconApiSelectors
    .getActiveInvoicesByInvoiceStatus(state)('Overdue', portfolioId)
    .map(injectId)
  const shortInvoices = reconApiSelectors.getActiveInvoicesByInvoiceStatus(state)('Short', portfolioId).map(injectId)
  const unallocatedInvoices = reconApiSelectors
    .getActiveInvoicesByInvoiceStatus(state)('NotSet', portfolioId)
    .map(injectId)
  const readyInvoices = reconApiSelectors.getActiveInvoicesByInvoiceStatus(state)('Ready', portfolioId).map(injectId)
  const openInvoices = reconApiSelectors.getActiveInvoicesByInvoiceStatus(state)('Out', portfolioId).map(injectId)

  /**
   * @todo open
   */
  return [
    {
      extraKeys,
      key: 'open',
      label: 'Open',
      count: openInvoices.length,
      status: 'Blue',
      invoices: openInvoices,
    },
    {
      extraKeys,
      key: 'overdue',
      label: 'Overdue',
      description: 'Insufficient funds, due date lapsed',
      count: overdueInvoices.length,
      status: 'Red',
      invoices: overdueInvoices,
    },
    {
      extraKeys,
      key: 'unallocated',
      label: 'Unallocated',
      description: 'Sufficient funds, allocation not set',
      count: unallocatedInvoices.length,
      status: 'Amber',
      invoices: unallocatedInvoices,
    },
    {
      extraKeys,
      key: 'short',
      label: 'Short',
      description: 'Insufficient funds',
      count: shortInvoices.length,
      status: 'Yellow',
      invoices: shortInvoices,
    },
    {
      extraKeys,
      key: 'ready',
      label: 'Ready',
      description: 'Sufficient funds available & allocation set',
      count: readyInvoices.length,
      status: 'Green',
      invoices: readyInvoices,
    },
  ]
})

export const getNewInvoice = createSelector(uiInvoiceState, s => s.newInvoice)

export const getInvoiceView = createSelector(uiInvoiceState, s => s.invoiceView)

const transformInvoiceTypesForSelect = pipe(
  map(({ name, value }: { name: string; value: string }) => ({ label: name, value })),
  sortBy(prop('label')),
)

export const getInvoiceTypesForSelect = createSelector(getInvoiceTypes, transformInvoiceTypesForSelect)

export const getNonAdhocInvoiceTypesForSelect = createSelector(
  getInvoiceTypesForNonAdhocInvoices,
  transformInvoiceTypesForSelect,
)

export const getAdhocInvoiceTypesForSelect = createSelector(
  getInvoiceTypesForAdhocInvoices,
  transformInvoiceTypesForSelect,
)

export const isNewInvoiceModalOpen = createSelector(getNewInvoice, newInvoice => newInvoice.modalOpen)

export const trySubmitNewInvoice = createSelector(getNewInvoice, newInvoice => newInvoice.trySubmit)

export const isEditingInvoice = createSelector(getInvoiceView, invoiceView => invoiceView.editing)

/**
 * Nudged Invocies
 */
export const getNudgedInvoices = createSelector(uiInvoiceState, is => get(is, 'nudgedInvoices', []))

export const getNudgedInvoiceById = createSelector(
  getNudgedInvoices,
  nudgedInvoices => (id: any) => nudgedInvoices.find((ni: any) => ni.id === id) || false,
)

export const canInvoiceBeNudged = createSelector(state, s => (id: any) => {
  const invoice = getNudgedInvoiceById(s)(id)
  const nextNudge = addHours(invoice.lastNudged, hoursToThrottleNudge).getTime()
  return !invoice || nextNudge <= Date.now()
})

export const getLastNudgeTime = createSelector(state, s => (id: any) => {
  const invoice = getNudgedInvoiceById(s)(id)
  return format(invoice.lastNudged, 'DD MMM YYYY, hh:mm')
})

/**
 * Draft Invoices
 */
export const getDraftInvoices = createSelector(uiInvoiceState, s => s.draftInvoices)

export const getDraftInvoicesInSetMode = createSelector(getDraftInvoices, drafts => get(drafts, 'setMode', []))

export const getCurrentInvoice = createSelector(uiInvoiceState, s => s.currentInvoice)

import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const initialState = {
  period: {
    from: '',
    to: '',
  },
  emails: [],
}

const communicationsApiSlice = createSlice({
  name: `${NAMESPACE}Api`,
  initialState,
  reducers: {
    emails_request: (state, { payload }) => {},
    emails_success: (state, { payload }) => {
      const {
        response: { period, emails },
      } = payload

      state.period = period
      state.emails = emails
    },
    resend_request: (state, { payload }) => {},
    resend_success: (state, { payload }) => {},
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = communicationsApiSlice

export { events as communicationsApiEvents, reducer as communicationsApiReducer }

import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import { CurrencyText, DimmerLoader, SummaryWidget, SummaryWidgetGroup } from '../../../../views/components'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { apiStateSelectors } from '../../../api/apiState'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import { reconApiSelectors } from '../../../api/recon'
const DashboardSummaryWidgets = () => {
  const portfolioStats = useSelector(portfolioApiSelectors.getStats)
  const accountingStats = useSelector(accountingApiSelectors.getStats)
  const reconStats = useSelector(reconApiSelectors.getStats)
  const settledPercentageLast60Days = reconStats?.settledPercentageLast60Days
  const unallocatedFunds = reconStats?.unallocatedFunds
  const availableFunds = reconStats?.availableFunds
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const managedAndOccupied = portfolioStats?.managedAndOccupied
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const unmanagedAndOccupied = portfolioStats?.unmanagedAndOccupied
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const startingInNext30Days = portfolioStats?.startingInNext30Days
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const endingInNext30Days = portfolioStats?.endingInNext30Days
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const fundsCollectedInLast30Days = accountingStats?.fundsCollectedInLast30Days
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  const commissionLast30Days = accountingStats?.commissionLast30Days

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([
      portfolioApiEvents.portfolioStats_request,
      accountingApiEvents.accountingStats_request,
    ]),
  )

  const managedDraftCount = managedAndOccupied?.drafts || 0
  const unmanagedDraftCount = unmanagedAndOccupied?.drafts || 0
  const renewalCount = startingInNext30Days?.allRenewals || 0

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; loading: any; }' is not... Remove this comment to see the full error message
    <DimmerLoader loading={isLoading}>
      <SummaryWidgetGroup>
        <div>
          <h3>Properties</h3>
          <SummaryWidget
            title="Managed &amp; Occupied"
            amount={managedAndOccupied?.count || 0}
            percentageDiff={managedAndOccupied?.percentageChange30Days || 0}
            actions={[
              {
                label: `${managedDraftCount} ${pluralize('Draft', managedDraftCount)}`,
              },
            ]}
          />
          <SummaryWidget
            title="Unmanaged &amp; Occupied"
            amount={unmanagedAndOccupied?.count || 0}
            percentageDiff={managedAndOccupied?.percentageChange30Days || 0}
            actions={[
              {
                label: `${unmanagedDraftCount} ${pluralize('Draft', unmanagedDraftCount)}`,
              },
            ]}
          />
        </div>
        <div>
          <h3>Leases</h3>
          <SummaryWidget
            title="New Starting Next 30 Days"
            amount={startingInNext30Days?.count || 0}
            percentageDiff={startingInNext30Days?.percentageChange30Days || 0}
            actions={[
              {
                label: `${renewalCount || 0} ${pluralize('Renewals', renewalCount)}`,
              },
            ]}
          />
          <SummaryWidget
            title="Ending Next 30 Days"
            amount={endingInNext30Days?.count || 0}
            percentageDiff={endingInNext30Days?.percentageChange30Days || 0}
            actions={[
              {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                label: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />,
              },
            ]}
          />
        </div>
        <div>
          <h3>Collections</h3>
          <SummaryWidget
            title="Funds Collected Last 30 Days"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string |... Remove this comment to see the full error message
            amount={<CurrencyText>{fundsCollectedInLast30Days?.total || 0}</CurrencyText>}
            percentageDiff={fundsCollectedInLast30Days?.percentageChange30Days || 0}
            actions={[
              {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                label: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />,
              },
            ]}
          />
          <SummaryWidget
            title="Invoice Settled % Last 60 Days"
            amount={`${settledPercentageLast60Days || 0}%`}
            // percentageDiff={<CurrencyText>{unallocatedFunds || 0}</CurrencyText>}
            actions={[
              {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                label: (
                  <>
                    <CurrencyText>{availableFunds || 0}</CurrencyText> Available Funds
                  </>
                ),
              },
            ]}
          />
        </div>
        <div>
          <h3>Commission</h3>
          <SummaryWidget
            title="Commission Last 30 Days"
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string |... Remove this comment to see the full error message
            amount={<CurrencyText>{commissionLast30Days?.total || 0}</CurrencyText>}
            percentageDiff={commissionLast30Days?.percentageChange30Days || 0}
            actions={[
              {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                label: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />,
              },
            ]}
          />
        </div>
      </SummaryWidgetGroup>
    </DimmerLoader>
  )
}

export default DashboardSummaryWidgets

import { depositBeneficiaryReferences } from 'modules/depositManagement/constants'
import { isEmpty, pathOr } from 'ramda'
import { DepositBeneficiary } from 'types/finance'
import { $TSFixMe } from 'types/ts-migrate'

type TPartialBankDetails = { accountNumber: string; [key: string]: any }

const getLastFour = (bankDetails: TPartialBankDetails) => {
  const accountNumber: $TSFixMe = pathOr('', ['accountNumber'], bankDetails)
  return accountNumber.substring(accountNumber.length - 4, accountNumber.length)
}

export const transformDepositBeneficiaryForTransferChange = (
  beneficiary: DepositBeneficiary,
  transfer: boolean,
  agencyId: string,
  partyName: string,
  invoiceTypeName: string,
  ownerPartyId?: string,
): DepositBeneficiary => {
  const isNoTransferWithOwner = !transfer && typeof ownerPartyId !== 'undefined'
  const isNoTransferWithoutOwner = !transfer && typeof ownerPartyId === 'undefined'

  // default to transfer to reOS
  let beneficiaryTag = 'TenantDepositAccount'
  let partyId = agencyId
  let reference = `${partyName} ${invoiceTypeName}`

  // override if no transfer and owner account is passed
  if (isNoTransferWithOwner) {
    beneficiaryTag = 'Owner'
    partyId = ownerPartyId
    // override if no transfer and owner account is not passed
  } else if (isNoTransferWithoutOwner) {
    beneficiaryTag = 'TenantDepositAccount' // Setting to 'Agency' transfers to the commission wallet.
    partyId = agencyId
  }

  return {
    ...beneficiary,
    beneficiary: {
      ...beneficiary.beneficiary,
      value: {
        ...beneficiary.beneficiary.value,
        transfer,
        beneficiaryTag,
        partyId,
        reference,
      },
    },
  }
}

export const transformDepositBeneficiaryForManagedByChange = (
  beneficiary: DepositBeneficiary,
  option: string,
  agencyId: string,
  partyName: string,
  invoiceTypeName: string,
  ownerPartyId?: string,
): DepositBeneficiary => {
  const isManagedByOwner = option.toLowerCase() === 'owner' && typeof ownerPartyId !== 'undefined'

  const reference = `${partyName} ${invoiceTypeName}`

  // prettier-ignore
  return {
    ...beneficiary,
    beneficiary: {
      ...beneficiary.beneficiary,
      value: {
        ...beneficiary.beneficiary.value,
        beneficiaryTag: isManagedByOwner ? 'Owner' : 'TenantDepositAccount', // Setting to 'Agency' transfers to the commission wallet.
        partyId: isManagedByOwner ? ownerPartyId : agencyId,
        reference,
      },
    },
  }
}

export const getDepositBeneficiaryBankDetails = (
  option: string,
  agencyBankDetails: TPartialBankDetails,
  ownerPartyId?: string,
  ownerBankDetails?: TPartialBankDetails,
): $TSFixMe => {
  const isManagedByOwner = option.toLowerCase() === 'owner' && typeof ownerPartyId !== 'undefined'

  return isManagedByOwner && !isEmpty(ownerBankDetails?.bank) && !isEmpty(ownerBankDetails?.accountNumber)
    ? `${depositBeneficiaryReferences.ownerBankName}  ${ownerBankDetails?.bank} ${
        depositBeneficiaryReferences.ownerAccountNumber
      }${getLastFour(ownerBankDetails as TPartialBankDetails)}`
    : isManagedByOwner
    ? 'No Bank Account Details'
    : `${depositBeneficiaryReferences.depositAccountBankName} ${agencyBankDetails.bank} ${
        depositBeneficiaryReferences.depositAccountNumber
      }${getLastFour(agencyBankDetails)}`
}

import { morphism } from 'morphism'
import { omit, path, pathOr } from 'ramda'
import { currencyUtils } from '../../../utils'

const { toCents, fromCents } = currencyUtils

const transformBeneficiariesResponse = ({ beneficiaries }: any) =>
  beneficiaries.map((b: any) => ({
    ...b,

    beneficiary: {
      ...b.beneficiary,
      value: {
        ...b.beneficiary.value,
        amount: toCents(b.beneficiary.value.amount).getAmount(),
      },
    },
  }))

const invoiceResponseSchema = {
  id: 'id',
  tags: 'tags',
  number: 'number',
  customer: 'customer',
  portfolioId: 'portfolioId',
  property: 'property',
  reoccurring: 'reoccurring',
  invoiceType: 'invoiceType',
  invoiceTypeName: 'invoiceTypeName',
  dueDate: 'dueDate',
  vat: 'vat',
  amount: ({ amount }: any) => toCents(amount).getAmount(),
  balance: ({ balance }: any) => toCents(balance).getAmount(),
  beneficiaries: transformBeneficiariesResponse,
  description: 'description',
}

export const transformInvoiceResponse = (source: any) => morphism(invoiceResponseSchema, source)

const transformBeneficiaryPost = ({ beneficiaries }: any) =>
  beneficiaries.map(({ beneficiary }: any) => ({
    type: beneficiary.type,
    value: {
      ...beneficiary.value,
      amount: fromCents(beneficiary.value.amount),
    },
  }))

const updateInvoiceSchema = {
  id: 'id',
  tags: 'tags',
  number: 'number',
  customer: 'customer',
  portfolioId: 'portfolioId',
  customerTag: 'customerTag',
  property: 'property',
  reoccurring: 'reoccurring',
  invoiceType: 'invoiceType',
  invoiceTypeName: 'invoiceTypeName',
  dueDate: 'dueDate',
  vat: 'vat',
  amount: ({ amount }: any) => fromCents(amount),
  beneficiaries: transformBeneficiaryPost,
  description: 'description',
}

export const transformUpdateInvoiceRequest = (source: any) => morphism(updateInvoiceSchema, source)

const transformNewInvoiceRequestSchema = {
  id: 'id',
  customer: 'customer',
  customerName: 'customerName',
  invoiceType: 'invoiceType',
  dueDate: 'dueDate',
  propertyAddress: 'propertyAddress',
  vat: 'vat',
  customerTag: 'customerTag',
  portfolioId: (r: any) => pathOr(false, ['portfolioId'], r),
  amount: ({ amount }: any) => fromCents(amount),
  beneficiaries: transformBeneficiaryPost,
  description: 'description',
}

export const transformNewInvoiceRequest = (source: any) => {
  const request = morphism(transformNewInvoiceRequestSchema, source)
  return path(['portfolioId'], request) ? request : omit(['portfolioId'], request)
}

const transformNewInvoiceResponseSchema = {
  id: 'id',
  tags: 'tags',
  customer: 'customer',
  reoccurring: 'reoccurring',
  invoiceType: 'invoiceType',
  invoiceTypeName: 'invoiceTypeName',
  dueDate: 'dueDate',
  vat: 'vat',
  amount: ({ amount }: any) => toCents(amount).getAmount(),
  balance: ({ balance }: any) => toCents(balance).getAmount(),
  beneficiaries: ({ beneficiaries }: any) =>
    beneficiaries.map((b: any) => ({
      ...b,

      beneficiary: {
        ...b.beneficiary,
        value: {
          ...b.beneficiary.value,
          amount: toCents(b.beneficiary.value.amount).getAmount(),
        },
      },
    })),
}

export const transformNewInvoiceResponse = (source: any) => morphism(transformNewInvoiceResponseSchema, source)

export const transformSaveBulkInvoiceRequest = (source: any) =>
  morphism(
    {
      invoices: ({ invoices }) => invoices.map(morphism(transformNewInvoiceRequest)),
    },
    source,
  )

const transformGetBulkInvoicesResponseSchema = {
  id: 'id',
  customer: 'customer',
  customerName: 'customerName',
  propertyAddress: 'propertyAddress',
  invoiceType: 'invoiceType',
  dueDate: 'dueDate',
  vat: 'vat',
  customerTag: 'customerTag',
  portfolioId: (r: any) => pathOr(false, ['portfolioId'], r),
  amount: ({ amount }: any) => toCents(amount).getAmount(),
  beneficiaries: ({ beneficiaries }: any) =>
    beneficiaries.map((b: any) => ({
      beneficiary: {
        ...b,
        value: {
          ...b.value,
          amount: b.value?.amount ? toCents(b.value.amount).getAmount() : '',
        },
      },
    })),
  description: 'description',
}

export const transformGetBulkInvoicesResponse = (source: any) =>
  morphism(
    {
      invoices: ({ invoices }) => invoices.map(morphism(transformGetBulkInvoicesResponseSchema)),
    },
    source,
  )

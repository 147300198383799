import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import keyboardKey from 'keyboard-key'
import { animated, useSpring } from 'react-spring'
// import { animationConfig } from '../../../constants'
import Heading from '../../atoms/Typography/Heading/Heading'
import ContextMenu from '../../molecules/ContextMenu/ContextMenu'
import CloseIcon from '../../atoms/Svgs/Close'
import styles from './FocusMode.module.scss'
import { eventStack } from '../../../../utils'

export const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  ContentComponent: PropTypes.any,
  SidebarComponent: PropTypes.any,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  disableScroll: PropTypes.bool,
}

const defaultProps = {
  isOpen: false,
  menuOptions: [],
  disableScroll: false,
}

function FocusMode({ isOpen, title, onClose, ContentComponent, SidebarComponent, menuOptions, disableScroll }: any) {
  const maybeCloseFocusMode = useCallback(
    e => {
      if (keyboardKey.getCode(e) === keyboardKey.Escape) {
        onClose()
      }
    },
    [onClose],
  )

  const subShortcuts = useCallback(() => {
    eventStack.sub('keydown', maybeCloseFocusMode)
  }, [maybeCloseFocusMode])

  const unsubShortcuts = useCallback(() => {
    eventStack.unsub('keydown', maybeCloseFocusMode)
  }, [maybeCloseFocusMode])

  useEffect(() => {
    if (isOpen) {
      subShortcuts()
    } else {
      unsubShortcuts()
    }
  }, [isOpen, subShortcuts, unsubShortcuts])

  const style = useSpring({
    reverse: !isOpen,
    config: { mass: 1, tension: 50, friction: 10, duration: 150 },
    to: { transform: 'translateY(0)' },
    from: { transform: 'translateY(100%)' },
  })

  const classes = cx(styles.root, {
    [styles['has-sidebar']]: SidebarComponent,
    [styles['disable-scroll']]: disableScroll,
  })

  return (
    <animated.div className={classes} style={style}>
      <div className={styles.container}>
        <div className={styles.header}>
          {menuOptions.length > 0 ? (
            <div className={styles.menu}>
              <ContextMenu items={menuOptions} position="left" />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: '<span>&nbsp;</span>' }} />
          )}
          {title && <Heading as="h3">{title}</Heading>}
          <button className={styles.close} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className={styles.body}>
        {ContentComponent && (
          <div className={styles.content}>
            {typeof ContentComponent === 'function' ? <ContentComponent /> : ContentComponent}
          </div>
        )}
        {SidebarComponent && <aside className={styles.sidebar}>{<SidebarComponent />}</aside>}
      </div>
    </animated.div>
  )
}

FocusMode.propTypes = propTypes
FocusMode.defaultProps = defaultProps

export default FocusMode

import React from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilter } from '../../../views/components'
import PortfoliosProvider from '../PortfoliosProvider'

const PropertiesSearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([PortfoliosProvider], ({ location, redirect, filters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters,
}))(PropertiesSearchFilter)

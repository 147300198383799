import { ofType } from 'redux-observable'
import { map, ignoreElements, filter, pluck, mergeMap, concatMap, switchMap, take } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { includes } from 'ramda'
import queryString from 'query-string'
import { events } from './state'
import { reconApiEvents, reconApiUtils, reconApiSelectors } from '../../api/recon'
import { userApiSelectors } from '../../api/user'
import { partyApiEvents, partyApiSelectors } from '../../api/party'
import { invoiceApiEpics, invoiceApiEvents, invoiceApiSelectors } from '../../api/invoice'
import { forkEpic } from '../../../utils/epics'
import { of } from 'rxjs'
import { uiInvoiceEvents, uiInvoiceSelectors } from '.'
import { layoutEvents } from '../../Layout/state'
import { uiEvents } from '..'
import { notificationEvents } from '../../notificationCenter'
import { $TSFixMe } from 'types/ts-migrate'
import { portfolioApiEvents } from 'modules/api/portfolio'

export const saveNudgedInvoice = (action$: any, state$: any) =>
  action$.pipe(
    ofType(reconApiEvents.invoicesNudge_success),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => reconApiUtils.saveNudgedInvoice(payload)),
    tag('ui/epic/invoice/saveNudgedInvoice'),
    ignoreElements(),
  )

export const fetchAgencyIfNotInStateWhenInvoiceOpened = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.invoiceOpened),
    filter(() => !!partyApiSelectors.getAgencyParty(state$.value)),
    map(() => partyApiEvents.party_request(userApiSelectors.getCurrentAgencyId(state$.value))),
  )

export const fetchDraftInvoiceOnOpen = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.invoiceOpened),
    pluck('payload'),
    filter(({ type }) => includes(type, ['drafts'])),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ invoiceId }: { invoiceId: any... Remove this comment to see the full error message
    map(({ invoiceId }) => invoiceApiEvents.invoice_request({ id: invoiceId })),
    tag('ui/epic/invoice/fetchDraftInvoiceOnOpen'),
  )

export const fetchDraftInvoiceDataWhenFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(invoiceApiEvents.invoice_success),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => { part... Remove this comment to see the full error message
    map(({ id }) => ({
      partyIds: invoiceApiSelectors.getInvoiceParties(state$.value)(id),
      easyPayReferences: invoiceApiSelectors.getInvoiceEasyPayBeneficiaryReferences(state$.value)(id),
    })),
    mergeMap(({ partyIds, easyPayReferences }) => {
      const events: any[] = []
      if (easyPayReferences.length > 0) {
        easyPayReferences.forEach((reference: any) => events.push(reconApiEvents.easypayData_request({ reference })))
      }
      if (partyIds.length > 0) {
        events.push(partyApiEvents.parties_request(partyIds))
      }
      return events
    }),
    tag('ui/epic/invoice/fetchDraftInvoiceDataWhenFetched'),
  )

export const fetchReconInvoiceWhenOpened = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.invoiceOpened),
    pluck('payload'),
    filter(({ type }) => includes(type, ['active'])),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ invoiceId }: { invoiceId: any... Remove this comment to see the full error message
    map(({ invoiceId }) => reconApiEvents.invoice_request({ id: invoiceId })),
    tag('ui/epic/invoice/fetchReconInvoiceWhenOpened'),
  )

export const fetchPartiesWhenArchivedInvoiceOpened = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.invoiceOpened),
    pluck('payload'),
    filter(({ type }) => includes(type, ['history'])),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ invoiceId }: { invoiceId: any... Remove this comment to see the full error message
    map(({ invoiceId }) => ({
      parties: reconApiSelectors.getInvoiceParties(state$.value)(invoiceId),
    })),
    mergeMap(({ parties }) => {
      const events: any[] = []
      if (parties.length > 0) {
        events.push(partyApiEvents.parties_request(parties))
      }
      return events
    }),
    tag('ui/epic/invoice/fetchPartiesWhenArchivedInvoiceOpened'),
  )

export const fetchReconInvoiceData = (action$: any, state$: any) =>
  action$.pipe(
    ofType(reconApiEvents.invoice_success),
    pluck('payload'),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => { part... Remove this comment to see the full error message
    map(({ id }) => ({
      parties: reconApiSelectors.getInvoiceParties(state$.value)(id),
      easyPayReferences: reconApiSelectors.getInvoiceEasyPayBeneficiaryReferences(state$.value)(id),
    })),
    mergeMap(({ parties, easyPayReferences }) => {
      const events: any[] = []
      if (easyPayReferences.length > 0) {
        easyPayReferences.forEach((reference: any) => events.push(reconApiEvents.easypayData_request({ reference })))
      }
      if (parties.length > 0) {
        events.push(partyApiEvents.parties_request(parties))
      }
      return events
    }),
    tag('ui/epic/invoice/fetchReconInvoiceData'),
  )

export const saveAndSendInvoice = (action$: any, state$: any, dependencies: any) =>
  action$.pipe(
    ofType(events.saveAndSendInvoice),
    pluck('payload'),
    mergeMap(({ id, values }) =>
      forkEpic(
        invoiceApiEpics.updateInvoice,
        dependencies,
        state$,
        invoiceApiEvents.invoiceUpdate_request({ id, values }),
      ).pipe(
        concatMap(res =>
          of(res).pipe(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'OperatorFunction<unknown, unknow... Remove this comment to see the full error message
            pluck('payload'),
            mergeMap(({ id, tags }) => {
              const _events = [events.removeFromDraftSetMode(id)]

              /**
               * @todo error-handling: move this to error notifications once implemented
               */
              if (!id) {
                const updateFailedNotification = notificationEvents.addNotification(
                  {
                    type: 'error',
                    heading: 'Invoice update failed',
                    message: 'There was an error updating this invoice. Please try again or contact support.',
                  },
                  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
                  { autoDismiss: false },
                )

                _events.push(updateFailedNotification)
              }

              const draftInvoicesInSetMode = uiInvoiceSelectors.getDraftInvoicesInSetMode(state$.value)

              const { portfolioId } = queryString.parse(queryString.extract(window.location.href))

              const type = 'drafts'

              if (draftInvoicesInSetMode.length >= 2) {
                _events.push(uiInvoiceEvents.invoiceOpened({ type, invoiceId: draftInvoicesInSetMode[1] }))
                _events.push(layoutEvents.openPrimaryPanel('invoices'))
                _events.push(layoutEvents.closeSidebar('global'))
                _events.push(
                  uiEvents.redirect(`/invoices/${type}/${id}${portfolioId ? `?portfolioId=${portfolioId}` : ''}`),
                )
              } else {
                _events.push(uiInvoiceEvents.closeInvoice({ id }))
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                _events.push(uiInvoiceEvents.resetDraftSetMode())
                _events.push(layoutEvents.openSidebar('global'))
                _events.push(layoutEvents.closePrimaryPanel('invoices'))
                _events.push(uiEvents.redirect(`/invoices/${type}${portfolioId ? `?portfolioId=${portfolioId}` : ''}`))
              }

              if (tags?.lightStatus === 'Green') {
                _events.push(invoiceApiEvents.sendInvoices_request([id]))
              }

              return _events
            }),
          ),
        ),
      ),
    ),
    tag('ui/epic/invoice/saveAndSendInvoice'),
  )

/**
 * We need to fetch the portfolio when an invoice panel is opened so we have access
 * To the portfolio's primary owner ID.
 */
export const maybeFetchDepositInvoicePortfolio = (action$: $TSFixMe, state$: $TSFixMe) =>
  action$.pipe(
    ofType(uiInvoiceEvents.invoiceOpened),
    switchMap((openedAction: $TSFixMe) => {
      const { type } = openedAction.payload

      const actionToWaitFor = type === 'drafts' ? invoiceApiEvents.invoice_success : reconApiEvents.invoice_success

      return action$.pipe(
        ofType(actionToWaitFor),
        take(1),
        mergeMap((invoiceSuccessAction: $TSFixMe) => {
          const invoice = invoiceSuccessAction.payload

          if (!['KeyDeposit', 'ServiceDeposit', 'DamageDeposit', 'DepositTopUp'].includes(invoice?.invoiceType)) {
            return []
          }

          const portfolioId = invoice?.portfolioId
          if (!portfolioId) return []

          return [portfolioApiEvents.portfolio_request(portfolioId)]
        }),
      )
    }),
    tag('ui/epic/invoice/maybeFetchDepositInvoicePortfolio'),
  )

import { createSlice } from '@reduxjs/toolkit'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'

const initialState: $TSFixMe = {
  balances: {},
  paymentHistory: {
    payments: [],
    period: '',
  },
  referenceLookups: {}, // { 'somereference': {....} }
  unallocatedBadReferences: [],
  allocatedBadReferences: [],
  isWithdrawalModalOpen: false,
  loading: {
    pop_request: false,
  },
}

const walletSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    balance_request: (state, { payload }: $TSFixMe) => {},
    balance_success: {
      reducer: (state, { payload, meta }) => {
        state.balances[meta.partyId] = payload
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    payout_request: (state, { payload }: $TSFixMe) => {},
    payout_success: (state, { payload }) => {
      const { amount, party, asset } = payload
      state.balances[party] = {
        ...state.balances[party],
        globalBalance: {
          ...state.balances[party].globalBalance,
          assets: state.balances[party].globalBalance.assets.map((a: any) =>
            a.name === asset ? { ...a, balance: a.balance - amount } : a,
          ),
        },
      }
      state.isWithdrawalModalOpen = false
    },
    payments_request: (state, { payload }: $TSFixMe) => {},
    payments_success: (state, { payload }) => {
      state.paymentHistory = payload
    },
    reversePayments_request: (state, { payload }) => {
      state.paymentHistory.payments = state.paymentHistory.payments.map(p => ({
        ...p,
        animateDanger: payload.includes(p.payment),
      }))
    },
    reversePayments_success: (state, { payload }) => {
      const reversedPayments = state.paymentHistory.payments.filter(p => payload.includes(p.payment))
      reversedPayments.forEach(payment => {
        payment.paymentStatus = 'Reversed'
      })
    },
    demandPayments_request: (state, { payload }) => {
      state.paymentHistory.payments = state.paymentHistory.payments.map(p => ({
        ...p,
        animateSuccess: payload.includes(p.payment),
      }))
    },
    demandPayments_success: (state, { payload }) => {
      const demandedPayments = state.paymentHistory.payments.filter(p => payload.includes(p.payment))
      demandedPayments.forEach(payment => {
        payment.paymentStatus = 'Demanded'
      })
    },
    /** @todo rename deposit transfer */
    transfer_request: (state, { payload }) => {},
    transfer_success: (state, { payload }) => {
      const { meta } = payload
      if (meta.partyId && meta.account && meta.amount) {
        state.balances[meta.partyId].accountBalances.find(
          (b: any) => b.account === meta.account && b.accountType === 'Trust',
        ).assets[0].balance -= meta.amount
      }
    },
    walletTransfer_request: (state, { payload }) => {},
    walletTransfer_success: (state, { payload }) => {},
    bulkWalletTransfer_request: (state, { payload }) => {},
    bulkWalletTransfer_success: (state, { payload }) => {},
    refund_request: (state, { payload }) => {},
    refund_success: (state, { payload }) => {},
    pop_request: (state, { payload }) => {
      state.loading.pop_request = true
    },
    pop_success: (state, { payload }) => {
      state.loading.pop_request = false
    },
    createWallet_request: (state, { payload }) => {},
    createWallet_success: (state, { payload }) => {},
    unallocatedBadReferences_request: state => {},
    unallocatedBadReferences_success: (state, { payload }) => {
      state.unallocatedBadReferences = payload
    },
    allocatedBadReferences_request: state => {},
    allocatedBadReferences_success: (state, { payload }) => {
      state.allocatedBadReferences = payload
    },
    allocateBadReference_request: (state, { payload }: $TSFixMe) => {},
    allocateBadReference_success: {
      reducer: (state, { payload, meta }) => {
        if (meta?.txId) {
          state.unallocatedBadReferences = state.unallocatedBadReferences.filter(ref => ref.txId !== meta.txId)
        }
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    referenceLookup_request: (state, { payload: { reference = '' } }) => {}, // dispatch(referenceLookup_request({ reference: 'm6yreference' }))
    referenceLookup_success: (state, { payload }) => {
      /**
       state = {
         referenceLookups: {}
       }
       */
      if (payload?.reference) {
        state.referenceLookups[payload.reference] = payload
      }
      /**
       * state = {
        referenceLookups: {
          1435657265: {
            accountId: '8778c86d-ae8e-48fe-bce1-c126faacd3ee',
            agencyId: '8fd4726e-a1d8-4fb6-ad9e-25bd699d1b93',
            partyId: '43969d3f-01a6-4f14-8a03-8aadb209850a',
            accountType: 'Tenant',
            reference: '1435657265',
            portfolioId: '5ecdea91-6eed-40b3-949b-c6b582d16cce',
            propertyAddress: '21 Palmer Road, Muizenberg Cape Town',
            walletRef: 'b3680q977meg0cqh5gbaipxl67e6ac@uuid8fd4726e-a1d8-4fb6-ad9e-25bd699d1b93.agency'
          }
        }
       * }
       */
    },
    /**
     * @todo: move this to ui module
     */
    withdrawalModalRequestOpened: state => {
      state.isWithdrawalModalOpen = true
    },
    withdrawalModalRequestClosed: state => {
      state.isWithdrawalModalOpen = false
    },
  },
})

const { reducer, actions: events } = walletSlice

export { events, reducer }

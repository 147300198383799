import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { get } from 'lodash-es'
import { customPropTypes } from '../../../../utils'
import Segment from '../../../atoms/Segment/Segment'
import NewBeneficiary from '../../../molecules/NewBeneficiary/NewBeneficiary'
import ArrowNext from '../../../atoms/Svgs/ArrowNext'
import DraggableList from '../../../molecules/DraggableList/DraggableList'
import Beneficiary from './Beneficiary/Beneficiary'
import DepositBeneficiary from './DepositBeneficiary/DepositBeneficiary'
import styles from './FundDistribution.module.scss'
import { partyBeneficiary, depositBeneficiary, easyPayBeneficiary } from '../../../../../modules/finance'
import { forEach, isNil, map, pathOr } from 'ramda'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import EditReference from './EditReference/EditReference'
import { depositBeneficiaryReferences } from 'modules/depositManagement/constants'

const propTypes = {
  state: PropTypes.string,
  beforeContent: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  afterHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  headerText: PropTypes.string,
  invoiceType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  invoiceStatus: PropTypes.string,
  balance: PropTypes.number,
  unallocatedAmount: PropTypes.number.isRequired,
  amountPlaceholderWhenBalanceNotSet: PropTypes.string,
  includeVatCalculatorOnPaymentRules: PropTypes.bool,
  onNewBeneficiaryAdded: PropTypes.func,
  onBeneficiaryRemoved: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onBeneficiaryReferenceChange: PropTypes.func,
  onBeneficiariesOrderChange: PropTypes.func,
  onDepositBeneficiaryTransferChange: PropTypes.func.isRequired,
  // Optional key for DraggableList. Used to handle order persistence.
  beneficiariesKey: PropTypes.string,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiary: PropTypes.shape({
        type: PropTypes.oneOf(['DepositBeneficiary', 'EasyPayBeneficiary', 'PartyBeneficiary']),
        value: PropTypes.oneOfType([
          PropTypes.shape({
            name: PropTypes.string,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any, propName: any, componentName: a... Remove this comment to see the full error message
            partyId: customPropTypes.uuid,
            partyTag: PropTypes.string,
            reference: PropTypes.string,
            amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            /** @todo improve this, shouldn't support multiple implementations */
            amountToPay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            vat: PropTypes.bool,
          }),
          PropTypes.shape({
            easyPayReference: PropTypes.string,
            beneficiaryTag: PropTypes.string,
            amount: PropTypes.number,
            vat: PropTypes.bool,
          }),
        ]),
      }),
      Meta: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
    }),
  ),
  partySearchTags: PropTypes.string,
  /** List of party tags for selection */
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  getDefaultResultSets: PropTypes.func,
  errors: PropTypes.object,
  beneficiaryAmountPlaceholder: PropTypes.string,
  onVatChange: PropTypes.func,
  readonly: PropTypes.bool,
  isNewBeneficiaryAllowed: PropTypes.bool,
  fetchEasyPayData: PropTypes.func.isRequired,
  easyPayData: PropTypes.object.isRequired,
  agencyName: PropTypes.string.isRequired,
  agencyBankDetails: PropTypes.object,
  propertyOwnerId: PropTypes.string,
  propertyOwnerName: PropTypes.string,
  propertyOwnerBankDetails: PropTypes.object,
  invoiceVatEnabled: PropTypes.bool,
  disableDepositEdit: PropTypes.bool,
  Footer: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  inputTooltipText: PropTypes.string,
  portfolioId: PropTypes.string,
}

const defaultProps = {
  headerText: 'Payment allocation',
  invoiceType: {
    name: null,
    value: null,
  },
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  includeVatCalculatorOnPaymentRules: true,
  beneficiaries: [],
  partySearchTags: '',
  onVatChange: () => {},
  Footer: null,
  inputTooltipText: null,
  isNewBeneficiaryAllowed: true,
}

const FundDistribution = (props: any): React.ReactElement => {
  const {
    state,
    beforeContent,
    afterHeader,
    headerText,
    invoiceType,
    beneficiariesKey,
    beneficiaries,
    invoiceStatus,
    includeVatCalculatorOnPaymentRules = true,
    onNewBeneficiaryAmountChange,
    onBeneficiaryReferenceChange,
    onDepositBeneficiaryTransferChange,
    onVatChange,
    partySearchTags,
    getDefaultResultSets,
    partyTags,
    errors,
    disableDepositEdit,
    easyPayData,
    fetchEasyPayData,
    agencyName,
    agencyBankDetails,
    invoiceVatEnabled,
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
    onBeneficiaryRemoved,
    onNewBeneficiaryAdded,
    onBeneficiariesOrderChange,
    readonly,
    isNewBeneficiaryAllowed,
    balance,
    unallocatedAmount,
    amountPlaceholderWhenBalanceNotSet,
    beneficiaryAmountPlaceholder,
    Footer,
    inputTooltipText,
    portfolioId,
  } = props

  const [newBeneficiaryIndex, setNewBeneficiaryIndex] = useState(-1)
  const [showAddButton, setShowAddButton] = useState(true)

  const isDeposit = ['ServiceDeposit', 'DamageDeposit', 'DepositTopUp', 'KeyDeposit'].includes(invoiceType.value)
  const readOnlyMode = isDeposit || readonly
  const errorItems: any = []

  useEffect(() => {
    if (isDeposit || invoiceStatus === 'Done') {
      setShowAddButton(false)
    } else {
      setShowAddButton(true)
    }
  }, [isDeposit])

  const handleAdd = (): void => {
    setNewBeneficiaryIndex(beneficiaries.length)
    setShowAddButton(false)
  }

  const handleBeneficiaryReorder = (newOrder: any): void => {
    if (typeof onBeneficiariesOrderChange === 'function') {
      onBeneficiariesOrderChange(newOrder)
    }
  }

  const handleRemove = (index: number): void => {
    onBeneficiaryRemoved(index)
  }

  const resetNewBeneficiaryIndex = (): void => {
    setNewBeneficiaryIndex(-1)
    setShowAddButton(true)
  }

  const handleNewBeneficiaryAdded = (beneficiary: any): void => {
    onNewBeneficiaryAdded({ beneficiary }, beneficiaries.length)
    resetNewBeneficiaryIndex()
  }

  const isBeneficiarySettled = (beneficiary: any): boolean => {
    return beneficiary?.balance === 0 && beneficiary.originalAmount > 0
  }

  const getAmountPlaceholder = (index: number): string => {
    let placeholder

    if (isBeneficiarySettled(get(beneficiaries, [index]))) {
      placeholder = 'Settled'
    } else if (beneficiaryAmountPlaceholder) {
      placeholder = beneficiaryAmountPlaceholder
    } else if (!!balance && amountPlaceholderWhenBalanceNotSet) {
      placeholder = amountPlaceholderWhenBalanceNotSet
    } else if (get(beneficiaries, [index]).beneficiary.value.amount === 0) {
      placeholder = 'Set payment rule'
    } else {
      /** Remove balance placeholder for now until auto distribution logic is re-introduced */
      // placeholder = (index + 1 === beneficiaries.length) ? 'Balance' : 'Set amount'
      placeholder = '0.00'
    }

    return placeholder
  }

  const classes = cx(styles.root, {
    [styles['status-ready']]: invoiceStatus === 'Ready',
  })

  /**
   * Beneficiary items for DraggableList
   */
  const beneficiaryItems = beneficiaries.map((beneficiary: any, i: number) => {
    const prefix = `beneficiaries[${i}]`
    const {
      Meta = false,
      isRemovable = true,
      isDraggable = true,
      beneficiary: { type, value: beneficiaryValue },
    } = beneficiary

    const { beneficiaryName, referenceContext, reference, vatApplied } = (() =>
      type === 'EasyPayBeneficiary'
        ? {
            beneficiaryName: get(
              easyPayData,
              [beneficiaryValue.easyPayReference, 'request', 'receiver'],
              `EasyPay - ${beneficiaryValue.beneficiaryTag as string}`,
            ),
            referenceContext: 'EasyPay Payment',
            reference: beneficiaryValue.easyPayReference,
            vatApplied: !isNil(beneficiaryValue.vat) ? beneficiaryValue.vat : invoiceVatEnabled,
          }
        : beneficiaryValue.partyTag === 'Agency'
        ? {
            beneficiaryName: beneficiaryValue.name,
            referenceContext: 'Commission Wallet Transfer',
            reference: beneficiaryValue.transfer ? depositBeneficiaryReferences.reos : beneficiaryValue.reference,
            vatApplied: !isNil(beneficiaryValue.vat) ? beneficiaryValue.vat : invoiceVatEnabled,
          }
        : {
            beneficiaryName: beneficiaryValue.name,
            referenceContext: beneficiaryValue.partyId,
            reference: beneficiaryValue.transfer ? depositBeneficiaryReferences.reos : beneficiaryValue.reference,
            vatApplied: !isNil(beneficiaryValue.vat) ? beneficiaryValue.vat : invoiceVatEnabled,
          })()

    const { amount, amountToPay } = beneficiaryValue

    const value = typeof amountToPay === 'number' ? amountToPay : amount > 0 ? amount.toString() : ''

    const sharedProps = {
      id: i,
      index: i,
      includeVatCalculator: includeVatCalculatorOnPaymentRules,
      onNewBeneficiaryAmountChange: onNewBeneficiaryAmountChange.bind(null, i),
      placeholder: getAmountPlaceholder(i),
      amountName: `${prefix}.amount`,
      value: isBeneficiarySettled(beneficiary) ? '' : value,
      disableAmount: readOnlyMode || isBeneficiarySettled(beneficiary) || beneficiary.originalAmount === 0,
      onVatChange: onVatChange.bind(null, i),
      referenceContext,
      reference:
        typeof onBeneficiaryReferenceChange === 'function' && !beneficiaryValue.transfer ? (
          <EditReference
            reference={reference}
            onReferenceUpdate={(updatedReference: any) => onBeneficiaryReferenceChange(i, updatedReference, type)}
          />
        ) : (
          reference
        ),
      beneficiaryName,
      vatApplied,
      isBeneficiarySettled: isBeneficiarySettled(beneficiary),
      amountPayable: beneficiary.originalAmount,
      Meta,
      inputTooltipText,
    }

    const preValidationErrors = pathOr([], ['beneficiary', 'errors'], beneficiary)

    if (preValidationErrors.length > 0) {
      errorItems.push(i)
    }

    const errorClasses = cx(styles['item-wrapper'], {
      [styles['error-item-wrapper']]: preValidationErrors.length > 0,
    })

    const calcItemMarginTop = (): string => {
      let margin = 10 + i * 2
      margin += preValidationErrors.length > 0 ? 4 : 0

      let totalErrorsAbove = 0
      map((errorItemIndex: number) => {
        totalErrorsAbove += errorItemIndex < i ? 75 : 0
      }, errorItems)

      margin += totalErrorsAbove

      return `${margin}px`
    }

    switch (type) {
      case partyBeneficiary.type:
      case easyPayBeneficiary.type:
        return {
          itemProps: {
            disableRemove:
              (!isRemovable && 'Unable to remove a partially allocated beneficiary') ||
              (isDeposit && 'Unable to remove a deposit beneficiary'),
            isDraggable,
            style: {
              marginTop: i === 0 ? 0 : calcItemMarginTop(),
            },
          },
          children: (
            <div className={errorClasses}>
              {preValidationErrors.length > 0 && (
                <span className={styles.error}>
                  {preValidationErrors.map((error: any, i: any) => (
                    <span key={i}>{error.message}</span>
                  ))}
                </span>
              )}
              <Beneficiary {...sharedProps} />
            </div>
          ),
        }
      case depositBeneficiary.type:
        return {
          itemProps: { disableRemove: 'Unable to remove a deposit beneficiary', isDraggable },
          children: (
            <DepositBeneficiary
              {...sharedProps}
              transfer={beneficiaryValue.transfer}
              disableTransferToggle={!isRemovable}
              onTransferChange={onDepositBeneficiaryTransferChange}
              agencyName={agencyName}
              agencyBankDetails={agencyBankDetails}
              propertyOwnerId={propertyOwnerId}
              propertyOwnerName={propertyOwnerName}
              propertyOwnerBankDetails={propertyOwnerBankDetails}
              disableCurrencyField={disableDepositEdit}
              managedByValue={beneficiaries?.[0]?.beneficiary?.value?.beneficiaryTag === 'Owner' ? 'owner' : 'agency'}
            />
          ),
        }
      default:
        return null
    }
  })

  const calcListHeight = (): number => {
    let listHeight = 0

    forEach(() => {
      listHeight += 120
    }, beneficiaryItems)

    listHeight = listHeight + errorItems.length * 75

    return listHeight
  }

  return (
    <div className={classes}>
      <Segment.Group state={state}>
        {beforeContent && beforeContent}

        <Segment horizontal>
          <Segment.Label icon={<ArrowNext />} text={headerText} />
        </Segment>

        <Segment>
          {afterHeader && afterHeader}

          {errors?.general && <FormErrors errors={[errors.general]} />}
          {errors?.beneficiaries && <FormErrors errors={[errors.beneficiaries]} />}

          <DraggableList
            key={beneficiariesKey} // used to reset order state, undefined won't do anyhing AFAIK
            items={beneficiaryItems}
            onMove={handleBeneficiaryReorder}
            onAdd={!readOnlyMode && isNewBeneficiaryAllowed ? handleAdd : () => {}}
            onRemove={handleRemove}
            listHeight={calcListHeight()}
            addItemText="Add Payment Rule"
            showAddButton={isNewBeneficiaryAllowed && showAddButton}
          />

          {newBeneficiaryIndex !== -1 && (
            <NewBeneficiary
              onNewBeneficiaryAdded={handleNewBeneficiaryAdded}
              tags={partySearchTags}
              onCancel={resetNewBeneficiaryIndex}
              getDefaultResultSets={getDefaultResultSets}
              // @link https://app.clickup.com/t/28u39z7
              partyTags={partyTags.filter(tag => !['AgencyDepositAccount', 'TenantDepositAccount'].includes(tag.value))}
              easyPayData={easyPayData}
              fetchEasyPayData={fetchEasyPayData}
              propertyOwnerName={propertyOwnerName}
              unallocatedAmount={unallocatedAmount}
            />
          )}
        </Segment>

        {Footer && <Footer />}
      </Segment.Group>
    </div>
  )
}

FundDistribution.propTypes = propTypes
FundDistribution.defaultProps = defaultProps

export default FundDistribution

import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'
import { matchPath } from 'react-router'
import { ROUTES } from '../../../../constants/routes'
import { FocusMode } from '../../../../views/components'
import SegmentForm from '../SegmentForm/SegmentForm'
import { goBack } from 'connected-react-router'
import { $TSFixMe } from 'types/ts-migrate'

const SegmentFocusMode = (props: any) => {
  const dispatch = useDispatch()
  const url: $TSFixMe = useSelector(path(['router', 'location', 'pathname']))
  const onClose = useCallback(() => {
    dispatch(goBack())
  }, [dispatch])

  const match = matchPath(url, {
    path: ROUTES.segmentView,
    exact: true,
    strict: false,
  })

  const id = path(['params', 'id'], match)

  // eslint-disable-next-line react/display-name
  const WrappedSegmentForm = useMemo(
    () =>
      function SegmentFocusModeForm() {
        return (
          // @ts-expect-error
          <SegmentForm id={id} />
        )
      },
    [id],
  )

  return (
    <FocusMode
      isOpen={match !== null}
      title={id === 'new' ? 'New Segment' : 'Edit Segment'}
      ContentComponent={WrappedSegmentForm}
      onClose={onClose}
    />
  )
}

SegmentFocusMode.propTypes = {}

export default SegmentFocusMode

import React, { useMemo } from 'react'
import { Statement, Loader } from '../../../../../views/components'
import styles from './ViewStatementContent.module.scss'
import { subscribe } from 'react-contextual'
import ViewStatementProvider from '../ViewStatementProvider'
import { path } from 'ramda'

const ViewStatementContent = ({ isLoading, statementData }: any): React.ReactElement => {
  return useMemo(
    () => (
      <div className={styles.root}>
        {isLoading ? (
          <Loader />
        ) : !path(['context'], statementData) ? null : (
          <Statement context={statementData.context} />
        )}
      </div>
    ),
    [statementData, isLoading],
  )
}

export default subscribe([ViewStatementProvider], ({ isLoading, statementData }: any) => ({
  isLoading,
  statementData,
}))(ViewStatementContent)

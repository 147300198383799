import React, { FC, useCallback, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { FastField, Formik } from 'formik'
import {
  Button,
  CleaveField,
  CurrencyField,
  DatePicker,
  FormField,
  Select,
  TextField,
  TextInput,
} from 'views/components'
import * as yup from 'yup'
import { PartySearch } from 'views/containers'
import { partyApiSelectors } from 'modules/api/party'
import { userApiEvents, userApiSelectors } from 'modules/api/user'
import styles from './TpnConsumerInfoEntry.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { prop, sortBy, pathOr } from 'ramda'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { identityNumberValidation } from 'utils/yup'
import { format } from 'date-fns'
import { TTpnConsumerEnquiryRequest } from 'modules/api/integrations/types'
import PhoneField from 'views/components/atoms/PhoneField/PhoneField'
import { ParsedCountry } from 'react-international-phone'
import { PhoneNumberUtil } from 'google-libphonenumber'

type Props = {
  handleSave: (values: any) => void
}

const phoneUtil = PhoneNumberUtil.getInstance()

const validationSchema = yup.object().shape({
  nationalId: identityNumberValidation,
  rsaId: yup.bool().required('required'),
  cellNumber: yup.string().test('cellNumber', function (val) {
    let isValid = false
    try {
      isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(val))
    } catch (error) {
      isValid = false
    }
    return !isValid ? new yup.ValidationError('is invalid.', val, 'cellNumber') : true
  }),
  firstName: yup.string().required('required'),
  surname: yup.string().required('required'),
  addressLine1: yup.string().required('required'),
  creditAmount: yup.number().required('required'),
  city: yup.string().required('required'),
  postalCode: yup.string().required('required'),
  province: yup.string().required('required'),
  suburb: yup.string().required('required'),
})

const initialValues = {
  moduleList: [],
  rsaId: true,
  dateOfBirth: '',
  cellNumber: '',
  firstName: '',
  nationalId: '',
  surname: '',
  addressLine1: '',
  enquiryReason: 40,
  enquiryContactName: '',
  enquiryContactEmail: '',
  creditAmount: '',
  enquiryContactNumber: '',
  city: '',
  postalCode: '',
  province: '',
  suburb: '',
}

const TpnConsumerInfoEntry: FC<Props> = ({ handleSave }) => {
  const dispatch = useDispatch()
  const [selectedPartyId, setSelectedPartyId] = useState(null)
  const selectedParty = useSelector(state => partyApiSelectors.getPartyDetailsById(state)(selectedPartyId))

  useEffectOnce(() => {
    dispatch(userApiEvents.currentUserDetails_request())
  })

  const userFirstName = useSelector(userApiSelectors.getUserFirstName)
  const userLastName = useSelector(userApiSelectors.getUserLastName)
  const userEmail = useSelector(userApiSelectors.getUserEmail)
  const userCellNumber = useSelector(userApiSelectors.getCellNumber)

  // Update enquirer information from current user details
  initialValues.enquiryContactName = userFirstName + ' ' + userLastName
  initialValues.enquiryContactEmail = userEmail
  initialValues.enquiryContactNumber = userCellNumber

  const getInitialValues = useCallback(() => {
    return selectedParty
      ? {
          ...initialValues,
          firstName: selectedParty.firstName,
          surname: selectedParty.lastName,
          nationalId: selectedParty.idNumber,
          cellNumber: selectedParty.cellNumber,
        }
      : initialValues
  }, [selectedParty])

  const provinces = [
    {
      label: 'Eastern Cape',
      value: 'EasternCape',
    },
    {
      label: 'Free State',
      value: 'FreeState',
    },
    {
      label: 'Gauteng',
      value: 'Gauteng',
    },
    {
      label: 'KwaZulu-Natal',
      value: 'KwaZuluNatal',
    },
    {
      label: 'Limpopo',
      value: 'Limpopo',
    },
    {
      label: 'Mpumalanga',
      value: 'Mpumalanga',
    },
    {
      label: 'Northern Cape',
      value: 'NorthernCape',
    },
    {
      label: 'North West',
      value: 'NorthWest',
    },
    {
      label: 'Western Cape',
      value: 'WesternCape',
    },
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values: TTpnConsumerEnquiryRequest) => handleSave(values)}
    >
      {({ handleSubmit, setFieldValue, handleChange, values, errors }) => {
        const getFieldValue = (name: any) => pathOr('', [name], values)

        const [cellDialCode, setCellDialCode] = useState('+27')

        return (
          <form className={styles.form} onSubmit={handleSubmit}>
            <fieldset>
              <div className={styles.body}>
                <div className="rbn--row">
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <FastField
                        name="firstName"
                        onChange={handleChange}
                        render={({ field }: any) => (
                          <PartySearch
                            {...field}
                            label="First Name"
                            placeholder="e.g. Barbara"
                            value={values.firstName}
                            onResultSelect={({ id }: any) => setSelectedPartyId(id)}
                            tags={''}
                            error={errors?.firstName}
                            focusOnMount
                            limitResults="person"
                          />
                        )}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="surname"
                            type={TextFieldTypes.text}
                            name="surname"
                            value={values.surname}
                            placeholder="e.g. Gordon"
                            onChange={handleChange}
                          />
                        }
                        label="Last Name"
                        error={errors?.surname}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="nationalId"
                            type={TextFieldTypes.text}
                            name="nationalId"
                            value={values.nationalId}
                            placeholder="e.g. 8605030000080"
                            onChange={handleChange}
                          />
                        }
                        label="South African ID Number"
                        onChange={handleChange}
                        error={errors?.nationalId}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <CleaveField
                        name="dateOfBirth"
                        type="date"
                        id="dateOfBirth"
                        label="Date of birth"
                        placeholder="e.g 1986-05-03"
                        value={values.dateOfBirth}
                        onChange={handleChange}
                        error={errors?.dateOfBirth}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="addressLine1"
                            type={TextFieldTypes.text}
                            name="addressLine1"
                            value={values.addressLine1}
                            placeholder="e.g. 27 Street Name"
                            onChange={handleChange}
                          />
                        }
                        label="Address"
                        error={errors?.addressLine1}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="suburb"
                            type={TextFieldTypes.text}
                            name="suburb"
                            value={values.suburb}
                            placeholder="e.g. Gardens"
                            onChange={handleChange}
                          />
                        }
                        label="Suburb"
                        error={errors?.suburb}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="city"
                            type={TextFieldTypes.text}
                            name="city"
                            value={values.city}
                            placeholder="e.g. Cape Town"
                            onChange={handleChange}
                          />
                        }
                        label="City"
                        error={errors?.city}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <Select
                        name="province"
                        label="Province"
                        options={sortBy(prop('label'), provinces)}
                        onChange={(province: $TSFixMe) => setFieldValue('province', province.value)}
                        value={provinces.find(province => province.value === values.province)}
                        error={errors?.province}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            id="postalCode"
                            type={TextFieldTypes.text}
                            name="postalCode"
                            value={values.postalCode}
                            placeholder="e.g. 7990"
                            onChange={handleChange}
                          />
                        }
                        label="Postal Code"
                        error={errors?.postalCode}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <PhoneField
                            defaultCountry="za"
                            value={values.cellNumber}
                            onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                              setFieldValue('cellNumber', meta.inputValue)
                              setCellDialCode(meta.country.dialCode)
                            }}
                          />
                        }
                        label="Contact Number"
                        error={errors?.cellNumber}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-lg-4">
                    <FormField>
                      <CurrencyField
                        name="creditAmount"
                        label="Credit Amount"
                        value={values.creditAmount}
                        error={errors?.creditAmount}
                        onChange={({ target }: any) => setFieldValue('creditAmount', target.value)}
                      />
                    </FormField>
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button type="submit">Save</Button>
                </div>
              </div>
            </fieldset>
          </form>
        )
      }}
    </Formik>
  )
}

export default TpnConsumerInfoEntry

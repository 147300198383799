import React from 'react'
import PropTypes from 'prop-types'
import Segment from '../../../../atoms/Segment/Segment'
import styles from './FundDistributionFooter.module.scss'
import Valid from '../../../../atoms/Svgs/Valid'
import RadialProgress from '../../../../atoms/RadialProgress/RadialProgress'
import CurrencyText from '../../../../atoms/Typography/CurrencyText/CurrencyText'

const FundDistributionFooter = ({ text, amount, isComplete, progress }: any) => {
  const progressColor = () => {
    switch (progress.style) {
      case 'error':
        return '#FF1900'
      case 'warning':
        return '#FF6D21'
      default:
        return 'rgba(0,0,0,.8)'
    }
  }

  return (
    <Segment.Group horizontal>
      <Segment>
        <strong>{text}</strong>
      </Segment>
      <Segment>
        <div className={styles.balance}>
          <span className={styles['balance-amount']}>
            <CurrencyText>{amount}</CurrencyText>
          </span>
          <span className={styles['balance-icon']}>
            {isComplete ? (
              <Valid width="20px" height="20px" />
            ) : (
              <RadialProgress size="20" percentage={progress.value} progressColor={progressColor()} />
            )}
          </span>
        </div>
      </Segment>
    </Segment.Group>
  )
}

FundDistributionFooter.propTypes = {
  text: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  isComplete: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    value: PropTypes.number.isRequired,
    style: PropTypes.oneOf(['error', 'warning', 'default']),
  }),
}

export default FundDistributionFooter

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash-es'
import { Helmet } from 'react-helmet'
import { stringUtils } from '../../../../utils'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/images/logo.... Remove this comment to see the full error message
import logo from '../../../../theme/images/logo.svg'
import { Card, Layout, LayoutHeader, LayoutBody, SignupForm } from '../../../components'
import styles from './SignupPage.module.scss'

const propTypes = {
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  loginRedirect: PropTypes.func.isRequired,
  getInvite: PropTypes.func,
  signupInvite: PropTypes.oneOf([PropTypes.object, undefined]),
}

class SignupPage extends Component {
  constructor(props: any) {
    super(props)
    this.getToken = this.getToken.bind(this)
  }

  getToken(): undefined | string {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const query = stringUtils.parseQueryString(this.props.location.search.replace('?', ''))
    const token = get(query, 'token', false)
    /**
     * @@todo HACK - re-add '==' because parseQueryString splits string by '='
     */
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return token && `${token as string}==`
  }

  componentDidMount(): void {
    const token = this.getToken()
    if (token !== undefined) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getInvite' does not exist on type 'Reado... Remove this comment to see the full error message
      this.props.getInvite(token)
    }
  }

  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isAuthenticated' does not exist on type ... Remove this comment to see the full error message
    const { isAuthenticated, location, loginRedirect, signupInvite } = this.props
    // const from = location && location.state && location.state.from
    const from = location?.state?.from !== undefined ? location.state.from : '/'

    const firstName = get(signupInvite, 'firstName')
    const email = get(signupInvite, 'email')

    // eslint-disable-next-line no-extra-boolean-cast
    return Boolean(isAuthenticated) ? (
      <Redirect to={from} />
    ) : (
      <div className={styles.root}>
        <Helmet>
          <title>reOS | Sign up</title>
        </Helmet>
        <Layout>
          <LayoutHeader>
            <div className={styles.header}>
              <img width="96px" src={logo} alt="reOS" />
            </div>
          </LayoutHeader>
          <LayoutBody>
            <div className={styles['form-container']}>
              <div style={{ width: '360px' }}>
                <Card on>
                  <SignupForm firstName={firstName} email={email} encryptedValue={this.getToken()} />
                </Card>
                <p className={styles.footer}>
                  Already have an account?{' '}
                  <button type="button" onClick={loginRedirect}>
                    Sign in
                  </button>
                </p>
              </div>
            </div>
          </LayoutBody>
        </Layout>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
SignupPage.propTypes = propTypes

export default SignupPage

import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import TextField from '../../atoms/TextField/TextField'
import SearchIcon from '../../atoms/Svgs/SearchOff'
import AddIcon from '../../atoms/Icons/Controls/Plus'
import CloseIcon from '../../atoms/Svgs/Close'
import Search, { propTypes as searchPropTypes } from '../../molecules/Search/Search'
import styles from './SearchInputOverlay.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import TextInput from 'views/components/atoms/TextField/TextInput'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const SearchInputOverlay = ({
  onChange,
  name = 'search',
  placeholder = 'Search...',
  iconFill = '#141719',
  icon = 'search',
  className,
  useSearchComponent,
  searchComponentProps = {},
  children,
}: any): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState('')
  const inputRef = useRef<$TSFixMe>()

  const toggle = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
      setQuery('')
    } else {
      setIsOpen(true)
    }
  }, [isOpen])

  const classes = cx([styles.root, className], {
    [styles['is-open']]: isOpen,
  })

  useEffect(() => {
    if (isOpen && !useSearchComponent) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 300)
    }
  }, [isOpen, useSearchComponent])

  const handleChange = useCallback(
    e => {
      const val = e.target.value
      setQuery(val)
      typeof onChange === 'function' && onChange(val)
    },
    [setQuery, onChange],
  )

  const Icon = icon === 'search' ? SearchIcon : AddIcon

  return (
    <div className={classes}>
      <div className={styles.children}>{children}</div>
      {useSearchComponent ? (
        <Search
          name={name}
          placeholder={placeholder}
          value={query}
          className={styles['search-wrapper']}
          {...searchComponentProps}
        />
      ) : (
        <TextField
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              name={name}
              placeholder={placeholder}
              onChange={handleChange}
              value={query}
              ref={(ref: any) => {
                inputRef.current = ref
              }}
            />
          }
        />
      )}
      {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; type: "button"; classNa... Remove this comment to see the full error message */}
      <button type="button" className={styles['open-button']} href="#" onClick={toggle}>
        {isOpen ? <CloseIcon fill={iconFill} /> : <Icon fill={iconFill} />}
      </button>
    </div>
  )
}

SearchInputOverlay.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  iconFill: PropTypes.string,
  icon: PropTypes.oneOf(['search', 'add']),
  className: PropTypes.string,
  children: PropTypes.node,
  useSearchComponent: PropTypes.bool,
  searchComponentProps: PropTypes.shape(searchPropTypes),
}

export default SearchInputOverlay

import React from 'react'
import styles from './VoiceRobin.module.scss'

type TProps = {
  text: string
}

const VoiceRobin = ({ text }: TProps) => <div className={styles.root}>{text}</div>

export default VoiceRobin

import { of } from 'rxjs'
import { switchMap, concat, merge, take, map } from 'rxjs/operators'
import { downloadFileFromArrayBuffer as downloadFileFromArrayBufferUtil, downloadBase64 } from './fileUtils'

/**
 * Exactly like the existing concat operator but only takes n frames for each stream
 */
export function concatN(n: any) {
  return (...streams: any[]) => {
    return (input$: any) =>
      input$.pipe(
        switchMap(elem => input$.pipe(concat(...[of(elem)].concat(streams.map(stream$ => stream$.pipe(take(n))))))),
      )
  }
}

/**
 * Async flavour of concatN, will take n from all streams regardless
 */
export function mergeN(n: any) {
  return (...streams: any[]) => {
    return (input$: any) =>
      input$.pipe(
        switchMap(elem => input$.pipe(merge(...[of(elem)].concat(streams.map(stream$ => stream$.pipe(take(n))))))),
      )
  }
}

export const downloadFileFromArrayBuffer = (type: any, fileName: any) => (source$: any) =>
  source$.pipe(
    map(s => {
      if (process.env.NODE_ENV !== 'test') {
        downloadFileFromArrayBufferUtil(s, type, fileName)
      }
      return s
    }),
  )

export const downloadFileFromBase64 = (type: any, fileName: any) => (source$: any) =>
  source$.pipe(
    map(s => {
      if (process.env.NODE_ENV !== 'test') {
        downloadBase64(s, type, fileName)
      }
      return s
    }),
  )

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { InfoList } from '../../../../../../views/components'
import { portfolioApiSelectors } from '../../../../../api/portfolio'
import { propertyApiSelectors } from '../../../../../api/property'
import { partyApiSelectors } from '../../../../../api/party'
import { formatCurrency } from '../../../../../../utils/currency'
import styles from './LeaseDetails.module.scss'

const propTypes = {
  portfolioId: PropTypes.string.isRequired,
}

const LeaseDetails = ({ portfolioId }: any) => {
  const propertyId = useSelector(state =>
    portfolioApiSelectors.getPortfolioFieldById(state)(portfolioId, ['propertyId']),
  )
  const streetAddress = useSelector(state => propertyApiSelectors.getPropertyStreetAddress(state)(propertyId))
  const tenantId = useSelector(state => portfolioApiSelectors.getTenantByPortfolioId(state)(portfolioId))
  const tenantName = useSelector(state => partyApiSelectors.getPartyNameById(state)(tenantId))
  const rentAmount = useSelector(state => portfolioApiSelectors.getMonthlyRentAmount(state)(portfolioId))

  const items = [
    {
      label: 'Street address',
      value: streetAddress,
    },
    {
      label: 'Tenant name',
      value: tenantName,
    },
    {
      label: 'Rental amount',
      value: formatCurrency(rentAmount),
    },
    /** @todo add commission */
    // {
    //   label: 'Commission amount',
    //   value: 'R10 000'
    // }
  ]

  return (
    // @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component.
    <InfoList spacing="sm" items={items} className={styles.root} />
  )
}

LeaseDetails.propTypes = propTypes

export default LeaseDetails

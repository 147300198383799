import React from 'react'

const SvgWithdraw = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g
      stroke="#000"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      opacity={0.64}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M23.629 23.5H10.371a.75.75 0 01-.742-.861L11.75 8.5h10.5l2.121 14.139a.75.75 0 01-.742.861zM9.5 26.5h15" />
      <path d="M18.5 13h-2.033a1.342 1.342 0 00-.5 2.587l2.064.826a1.342 1.342 0 01-.5 2.587H15.5M17 13v-1.5M17 20.5V19" />
      <path d="M8 13H6.5a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75h21a.75.75 0 01.75.75v6a.75.75 0 01-.75.75H26" />
    </g>
  </svg>
)

export default SvgWithdraw

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { omit } from 'lodash-es'
import { currencyUtils } from '../../../../utils'
import TextField, { propTypes as TextFieldPropTypes } from '../../atoms/TextField/TextField'
import CurrencySymbol from '../../atoms/CurrencySymbol/CurrencySymbol'
import VatIcon from '../../atoms/VatIcon/VatIcon'
import styles from './CurrencyField.module.scss'
import { ITextField } from 'components/atoms/TextField/text-field.types'
import VATCalculator from '../VATControl/VATCalculator'
import { $TSFixMe } from 'types/ts-migrate'
import CurrencyInput from 'views/components/atoms/TextField/CurrencyInput'

const propTypes = {
  ...omit(TextFieldPropTypes, [
    'textarea',
    'leadingIcon',
    'trailingIcon',
    'onLeadingIconClick',
    'onTrailingIconClick',
    'type',
    'values',
  ]),
  ...{
    className: PropTypes.string,
    currencySymbol: PropTypes.string,
    includeVatControl: PropTypes.bool,
    includeVatCalculator: PropTypes.bool,
    vatApplied: PropTypes.bool,
    isEditVatDisabled: PropTypes.bool,
    onToggleChange: PropTypes.func,
    onChange: PropTypes.func,
    setPaymentRuleAmount: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowNegative: PropTypes.bool,
    inputTooltipText: PropTypes.string,
  },
}

const defaultProps = {
  currencySymbol: 'R',
  includeVatControl: false,
  includeVatCalculator: true,
  vatApplied: false,
  isEditVatDisabled: false,
  onToggleChange: (vatApplied: boolean, value: $TSFixMe) => ({
    vatApplied,
    value,
  }),
  onChange: (e: any) => e,
  setPaymentRuleAmount: (e: any) => e,
  allowNegative: false,
}

type TState = {
  editExAmount: boolean
  value: number | string
  initialValue: number | string
}

class CurrencyField extends Component<$TSFixMe, TState> {
  constructor(props: any) {
    super(props)
    this.state = {
      editExAmount: false,
      value: 0,
      initialValue: 0,
    }
  }

  componentDidMount(): void {
    this.setFormattedValue()

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const value = this.props.value ? parseFloat(currencyUtils.fromCents(this.props.value)).toFixed(2) : ''
    this.setState({ initialValue: value })
  }

  componentDidUpdate(prevProps: any): void {
    if (!isNaN(this.props.value)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      if (isNaN(this.state.value) && this.props.value) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
        this.setState({ value: parseFloat(currencyUtils.fromCents(this.props.value)).toFixed(2) })
      }
      if (this.props.value !== prevProps.value) {
        this.setFormattedValue()
      }
    }
  }

  setFormattedValue = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const value = this.props.value ? parseFloat(currencyUtils.fromCents(this.props.value)).toFixed(2) : ''
    this.setState({ value })
  }

  handleChange = (e: any) => {
    const { floatValue } = e

    this.setState({
      value: floatValue,
      initialValue: floatValue,
    })

    const value = currencyUtils.toCents(parseFloat(floatValue)).getAmount()
    this.props.onChange({
      ...e,
      ...{ target: { ...e.target, value } },
    })
  }

  handleToggleChange = () => {
    const { vatApplied } = this.props
    this.props.onToggleChange(!vatApplied)
    this.setState({ editExAmount: false })

    if (vatApplied) {
      this.setState({ value: this.state.initialValue })
    }
  }

  handleDisabledToggleChange() {
    const { vatApplied } = this.props
    this.props.onToggleChange(!vatApplied)
    this.setState({ editExAmount: false })
  }

  render(): null | React.ReactElement {
    const {
      className,
      currencySymbol,
      includeVatControl,
      includeVatCalculator = true,
      vatApplied,
      isEditVatDisabled,
      onChange,
      placeholder,
      allowNegative,
      inputTooltipText,
      ...rest
    } = this.props

    const { value } = this.state

    const componentProps: ITextField = {
      ...rest,
      placeholder: placeholder || '0 000.00',
      leadingIcon: <CurrencySymbol>{currencySymbol}</CurrencySymbol>,
      value,
      allowNegative,
    }

    const vatIconProps = {
      onClick: this.props.disabled ? () => this.handleDisabledToggleChange() : () => this.handleToggleChange(),
    }
    componentProps.trailingIcon = !includeVatControl ? null : <VatIcon enabled={vatApplied} {...vatIconProps} />

    return (
      <div className={cx([styles.root, className])}>
        <TextField
          id={componentProps.id}
          className={componentProps.className}
          disabled={componentProps.disabled}
          label={componentProps.label}
          name={componentProps.name}
          error={componentProps.error}
          noOutline={componentProps.noOutline}
          dark={componentProps.dark}
          inputComponent={
            <CurrencyInput
              value={componentProps.value}
              disabled={componentProps.disabled}
              leadingIcon={componentProps.leadingIcon}
              trailingIcon={componentProps.trailingIcon}
              placeholder={componentProps.placeholder}
              autoComplete={componentProps.autoComplete}
              allowNegative={componentProps.allowNegative}
              onChange={this.handleChange}
              inputTooltipText={inputTooltipText}
            />
          }
        />
        {includeVatCalculator && vatApplied && (
          <VATCalculator
            vatApplied={vatApplied}
            disabled={isEditVatDisabled}
            currencySymbol="R"
            amount={this.props.value}
            onChange={(amountInclVat: number) => {
              onChange({ target: { value: amountInclVat } })
            }}
          />
        )}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CurrencyField.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
CurrencyField.defaultProps = defaultProps

export default CurrencyField

import React from 'react'
import { matchPath } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { FocusMode } from '../../../../views/components'
import { ContactsProvider, NewContactForm } from '../..'

const NewContact = ({ match, goBack }: any) => {
  const newContatctMatchPath = matchPath(match.url, {
    path: '/contacts/new',
    exact: true,
    strict: false,
  })

  return (
    <FocusMode
      isOpen={newContatctMatchPath !== null}
      title="New Contact"
      ContentComponent={NewContactForm}
      onClose={() => goBack()}
    />
  )
}

export default subscribe(ContactsProvider, ({ match, goBack }: any) => ({
  match,
  goBack,
}))(NewContact)

import * as querystring from 'query-string'
import { Observable, of } from 'rxjs'
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax'
import * as Sentry from '@sentry/react'
import { AnyAction, createAction } from '@reduxjs/toolkit'
import { catchError, map } from 'rxjs/operators'
import { userApiSelectors } from '../modules/api/user'
import { StatusCodes } from 'http-status-codes'
import { concat, difference, isEmpty, join, keys, map as Rmap, match, omit, pipe, reduce, replace } from 'ramda'
import { IS_PRODUCTION, IS_TEST_MODE, NODE_ENV, SENTRY_ENV } from 'constants/general'
import { hashCode } from './string'
import { trimAllObjectSpaces } from './object'
import { $TSFixMe } from 'types/ts-migrate'
import { StateObservable } from 'redux-observable'

type TParams = {
  [param: string]: string
}

type THeaders = {
  [header: string]: string
}

const ajaxSettings: AjaxRequest = {
  crossDomain: !!process.env.REACT_APP_API_URL,
  responseType: 'json',
}

export const extractTokens = match(/:([A-Za-z]+)/g)

export const stripColons = Rmap(replace(/:/g, ''))

export const validateParams = (tokens: readonly string[]): ((params: TParams) => string[]) => {
  const strippedTokens = stripColons(tokens)
  return pipe(keys, difference(strippedTokens))
}

/**
 * Format error message
 * @param {array} params missing params
 */
export const getParamErrors = (params: string[]) => {
  const missingParams = pipe(Rmap(concat(':')), join(', '))(params)
  return new Error(`You are missing the following endpoint parameters "${missingParams}"`)
}

/**
 * Replace token with param and remove from params object
 */
export const injectUriParams = ({
  endpoint,
  params,
}: {
  endpoint: string
  params: TParams
}): {
  endpoint: string
  params: TParams
} => {
  const tokens = stripColons(extractTokens(endpoint))
  endpoint = reduce(
    (acc: any, val: any) => {
      return replace(`:${val}`, params[val], acc)
    },
    endpoint,
    keys(params),
  )
  params = omit(tokens, params)

  return { endpoint, params }
}

export const appendQueryStringParams = ({ endpoint, params }: any) =>
  isEmpty(params) ? endpoint : `${endpoint}?${querystring.stringify(params)}`

export const buildEndpoint = ({
  endpoint,
  params,
}: {
  endpoint: string
  params: {
    [param: string]: string
  }
}): string => {
  const tokens = pipe(extractTokens, stripColons)(endpoint)
  const paramsValidationResult = validateParams(tokens)(params)
  const areParamsValid = paramsValidationResult.length === 0
  return areParamsValid
    ? pipe(injectUriParams, appendQueryStringParams)({ endpoint, params })
    : getParamErrors(paramsValidationResult)
}

const getAuthHeaders = (
  authorizeWith: any,
  extendedHeaders?: THeaders,
): {
  [header: string]: string
} => {
  const headers: THeaders = {
    'Content-Type': 'application/json',
  }
  if (authorizeWith) {
    const bearerToken =
      typeof authorizeWith === 'string'
        ? authorizeWith
        : userApiSelectors.getAuthToken(authorizeWith.value ? authorizeWith.value : authorizeWith.getState())

    headers.Authorization = `Bearer ${bearerToken}`
  }

  return {
    ...headers,
    ...extendedHeaders,
  }
}

export const restfulErrorEvent = createAction('http_error', (payload: $TSFixMe, meta: $TSFixMe) => {
  const { status, response } = payload
  return { payload: { response }, meta: { ...meta, status } }
})

/**
 * RXJS operator for catching rest errors
 */
export const catchRestError = (
  initAction: AnyAction,
  reduceByPayload: boolean = true,
  optionalHandler?: (err: $TSFixMe) => Observable<$TSFixMe>,
) =>
  catchError(err => {
    if (err.status === undefined || err.status === null) {
      throw err
    }

    if (typeof optionalHandler === 'function') {
      const stream$ = optionalHandler(err)
      if (stream$) {
        return stream$
      }
    }

    return of(restfulErrorEvent(err, { initAction, reduceByPayload }))
  })

export const validStatusOrFail = (result: $TSFixMe, authorizeWith: $TSFixMe, request: $TSFixMe) => {
  const successCodes = [StatusCodes.OK, StatusCodes.CREATED, StatusCodes.ACCEPTED, StatusCodes.IM_A_TEAPOT]
  if (successCodes.filter(s => result.status === s).length >= 1) {
    if ((result === null || result.response === null) && !IS_PRODUCTION) {
      console.warn('Response is null, this could mean we failed to decode the response from the server')
    }
    return result
  }

  if (!['development', 'test'].includes(NODE_ENV ?? '')) {
    const state = authorizeWith.value ? authorizeWith.value : authorizeWith.getState()
    const id = userApiSelectors.getUserId(state)
    const email = userApiSelectors.getUserEmail(state)
    const username = userApiSelectors.getUserFirstName(state)
    const ex = new Error(result.status + ' - ' + result.statusText)
    Sentry.captureException(ex, {
      user: { id, email, username },
      extra: {
        request,
        response: result,
      },
    })
  }

  throw result
}

export const get = (
  endpoint: string,
  authorizeWith: string | StateObservable<$TSFixMe>,
  params: {
    [param: string]: string
  },
  extendedHeaders = {},
  extendedAjaxSettings = {},
): Observable<AjaxResponse> => {
  const method = 'get'
  const url = buildEndpoint({ endpoint, params })
  const headers = getAuthHeaders(authorizeWith, extendedHeaders)
  const _ajaxSettings = { ...ajaxSettings, ...extendedAjaxSettings }

  return ajax({ url, method, headers, ..._ajaxSettings }).pipe(
    map(r => validStatusOrFail(r, authorizeWith, { method, url, headers })),
  )
}

export const post = (
  endpoint: string,
  authorizeWith: string | StateObservable<$TSFixMe>,
  payload: any = {},
  params: any,
): Observable<AjaxResponse> => {
  const builtEndpoint = buildEndpoint({ endpoint, params })
  const url = IS_TEST_MODE ? `${builtEndpoint}?hash=${hashCode(JSON.stringify(payload))}` : builtEndpoint
  const method = 'post'
  const body = payload
  const headers = getAuthHeaders(authorizeWith)

  return ajax({ url, method, headers, body: trimAllObjectSpaces(body), ...ajaxSettings }).pipe(
    map(r => validStatusOrFail(r, authorizeWith, { method, url, headers, data: body })),
  )
}

export const put = (
  endpoint: string,
  authorizeWith: string | StateObservable<$TSFixMe>,
  payload: any = {},
  params: any,
): Observable<AjaxResponse> => {
  const builtEndpoint = buildEndpoint({ endpoint, params })
  const url = IS_TEST_MODE ? `${builtEndpoint}?hash=${hashCode(JSON.stringify(payload))}` : builtEndpoint
  const method = 'put'
  const body = payload
  const headers = getAuthHeaders(authorizeWith)

  return ajax({ url, method, headers, body: trimAllObjectSpaces(body), ...ajaxSettings }).pipe(
    map(r => validStatusOrFail(r, authorizeWith, { method, url, headers, data: body })),
  )
}

export const remove = (
  endpoint: string,
  authorizeWith: string | StateObservable<$TSFixMe>,
  params: any,
): Observable<AjaxResponse> => {
  const method = 'delete'
  /** @todo test token exclusion */
  const url = buildEndpoint({ endpoint, params })
  const headers = getAuthHeaders(authorizeWith)

  return ajax({ url, method, headers, ...ajaxSettings }).pipe(
    map(r => validStatusOrFail(r, authorizeWith, { method, url, headers })),
  )
}

export const removeWithBody = (
  endpoint: string,
  authorizeWith: string | StateObservable<$TSFixMe>,
  payload: any,
  params: any,
): Observable<AjaxResponse> => {
  const url = buildEndpoint({ endpoint, params })

  const method = 'delete'
  const body = payload
  const headers = getAuthHeaders(authorizeWith)

  return ajax({ url, method, headers, body, ...ajaxSettings }).pipe(
    map(r => validStatusOrFail(r, authorizeWith, { method, url, headers, data: body })),
  )
}

export default {
  get,
  post,
  put,
  remove,
  removeWithBody,
  catchRestError,
}

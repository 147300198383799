import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import useLayout from '../useLayout'
import styles from './Content.module.scss'
import { animationConfig } from '../../../views/constants'

function Content({ children }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type '{}'.
  const { sidebar, primaryPanel, secondaryPanel } = useLayout()
  const [style, setStyle] = useSpring(() => ({
    config: animationConfig,
    marginLeft: `${sidebar.width}px`,
  }))

  useEffect(() => {
    let marginLeft = '0px'
    if (primaryPanel.open && secondaryPanel.isOpen) {
      marginLeft = `${primaryPanel.width + secondaryPanel.width}px`
    } else if (primaryPanel.open) {
      marginLeft = `${primaryPanel.width}px`
    } else if (secondaryPanel.isOpen) {
      marginLeft = `${secondaryPanel.width}px`
    } else if (sidebar.open) {
      marginLeft = `${sidebar.width}px`
    }
    setStyle({ marginLeft })
  }, [sidebar, setStyle, primaryPanel, secondaryPanel])

  return (
    <animated.div className={styles.root} style={style}>
      {children}
    </animated.div>
  )
}

export default Content

import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const reportingSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  reducers: {},
})

/**
 * Exports
 */
const { reducer, actions: events } = reportingSlice

export { events as reportingEvents, reducer as reportingReducer }

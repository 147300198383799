import { ofType } from 'redux-observable'
import { filter, map, mapTo, mergeMap, mergeMapTo, pluck } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { goBack } from 'connected-react-router'
import { userApiEvents, userApiSelectors } from '../../api/user'
import { pipe as Rpipe, path, pathOr, last } from 'ramda'
import { portfolioApiEvents } from '../../api/portfolio'
import { invoiceApiEvents } from '../../api/invoice'
import { reconApiConstants, reconApiEvents } from '../../api/recon'
import { bookSegmentsEvents } from '.'
import { from, Observable, of, zip } from 'rxjs'
import { ENDPOINTS as AGENCY_ENDPOINTS } from '../../api/agency/constants'
import { ENDPOINTS as USER_ENDPOINTS } from '../../api/user/constants'
import { ENDPOINTS as PORTFOLIO_CONSTANTS } from '../../api/portfolio/constants'
import { agencyApiEvents, agencyApiSelectors } from '../../api/agency'
import { AnyAction } from 'redux'

export const maybeRefetchPortfolioSummariesOnTokenRefresh = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.refreshToken_success),
    filter(() => pathOr('', ['router', 'location', 'pathname'], state$.value).includes('/leases')),
    mapTo(portfolioApiEvents.portfolioSummaries_request()),
    tag('module/bookSegments/maybeRefetchPortfolioSummariesOnTokenRefresh'),
  )

export const maybeRefetchDraftInvoicesOnTokenRefresh = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.refreshToken_success),
    filter(() => pathOr('', ['router', 'location', 'pathname'], state$.value).includes('/invoices/drafts')),
    mapTo(invoiceApiEvents.invoices_request({ tags: '' })),
    tag('module/bookSegments/maybeRefetchDraftInvoicesOnTokenRefresh'),
  )

export const maybeRefetchActiveInvoicesOnTokenRefresh = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.refreshToken_success),
    filter(() => pathOr('', ['router', 'location', 'pathname'], state$.value).includes('/invoices/active')),
    mergeMapTo(reconApiConstants.invoiceStatuses.map(s => reconApiEvents.invoices_request({ tags: s }))),
    tag('module/bookSegments/maybeRefetchActiveInvoicesOnTokenRefresh'),
  )

/**
 * Create a segment. Coordinates various API requests.
 * - Create a segment
 * - Amend segment to portfolios
 * - Add segment memberships for team
 */
export const createSegment = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(bookSegmentsEvents.createSegment_request),
    mergeMap((action: AnyAction) => {
      const {
        payload: { name, portfolios = [], team = [] },
      } = action
      return post(AGENCY_ENDPOINTS.SEGMENT_BASE, state$, { name })
        .pipe(pluck('response'), map(agencyApiEvents.createSegment_success), catchRestError(action))
        .pipe(
          mergeMap((action: AnyAction) => {
            const { payload } = action
            const segmentId = Rpipe(last, path(['id']))(payload)
            const defaultSegmentId = userApiSelectors.getCurrentAgencyId(state$.value)

            const zipActions = [of(action)]

            portfolios.length > 0 &&
              zipActions.push(
                from(portfolios).pipe(
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => any' i... Remove this comment to see the full error message
                  map(({ id }) => {
                    const body = { segments: [defaultSegmentId, segmentId] }
                    const params = { id }
                    /** @todo need to fetch portfolio segments and amend ids once we support portfolios in multiple segments */
                    return post(PORTFOLIO_CONSTANTS.AMEND_SEGMENTS, state$, body, params).pipe(
                      pluck('response'),
                      mapTo(portfolioApiEvents.amendSegments_success({ ...body, ...params })),
                      catchRestError(action),
                    )
                  }),
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Observab... Remove this comment to see the full error message
                  mergeMap((action: AnyAction) => action),
                ),
              )

            team.length > 0 &&
              zipActions.push(
                from(team).pipe(
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => any' i... Remove this comment to see the full error message
                  map(({ id }) => {
                    const params = { userId: id, segmentId }
                    return post(USER_ENDPOINTS.SEGMENTS, state$, null, params).pipe(
                      pluck('response'),
                      mapTo(userApiEvents.addSegmentMembership_success(params)),
                      catchRestError(action),
                    )
                  }),
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Observab... Remove this comment to see the full error message
                  mergeMap((action: AnyAction) => action),
                ),
              )

            zipActions.push(of(goBack()))

            return zip(...zipActions)
          }),
          // @ts-expect-error ts-migrate(2488) FIXME: Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
          mergeMap(actions => [...actions, bookSegmentsEvents.createSegment_success({ name })]),
        )
    }),
    tag('module/bookSegments/createSegment'),
  )

/**
 * Create a segment. Coordinates various API requests.
 * - Update segment
 * - Amend segment to portfolios
 * - Update segment memberships for team
 */
export const updateSegment = (action$: any, state$: any, { post, put, remove, catchRestError }: any) =>
  action$.pipe(
    ofType(bookSegmentsEvents.updateSegment_request),
    mergeMap((action: AnyAction) => {
      const {
        payload: { id, name, portfolios = [], team = [], deletedTeamMemberships, deletedPortfolios },
      } = action

      const defaultSegmentId = userApiSelectors.getCurrentAgencyId(state$.value)
      const segments = agencyApiSelectors.getSegments(state$.value)

      /**
       * only update non-default segments. API throws error when trying to update default segment name.
       * Return faux updateSegment_success action if default segment
       */
      const updateAction$ =
        id !== defaultSegmentId
          ? put(AGENCY_ENDPOINTS.SEGMENT_ENTITY, state$, { name }, { segmentId: id }).pipe(
              pluck('response'),
              map(agencyApiEvents.updateSegment_success),
              catchRestError(action),
            )
          : of(agencyApiEvents.updateSegment_success(segments))

      return updateAction$.pipe(
        mergeMap((action: AnyAction) => {
          const segmentId = id

          const zipActions = [of(action)]

          portfolios.length > 0 &&
            defaultSegmentId !== segmentId &&
            zipActions.push(
              from(portfolios).pipe(
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => any' i... Remove this comment to see the full error message
                map(({ id }) => {
                  const body = { segments: [defaultSegmentId, segmentId] }
                  const params = { id }
                  /** @todo need to fetch portfolio segments and amend ids once we support portfolios in multiple segments */
                  return post(PORTFOLIO_CONSTANTS.AMEND_SEGMENTS, state$, body, params).pipe(
                    pluck('response'),
                    mapTo(portfolioApiEvents.amendSegments_success({ ...body, ...params })),
                    catchRestError(action),
                  )
                }),
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Observab... Remove this comment to see the full error message
                mergeMap((action: AnyAction) => action),
              ),
            )

          team.length > 0 &&
            zipActions.push(
              from(team).pipe(
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ id }: { id: any; }) => any' i... Remove this comment to see the full error message
                map(({ id }) => {
                  const params = { userId: id, segmentId }
                  return post(USER_ENDPOINTS.SEGMENTS, state$, null, params).pipe(
                    pluck('response'),
                    mapTo(userApiEvents.addSegmentMembership_success(params)),
                    catchRestError(action),
                  )
                }),
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Observab... Remove this comment to see the full error message
                mergeMap((action: AnyAction) => action),
              ),
            )

          deletedPortfolios.length > 0 &&
            zipActions.push(
              from(deletedPortfolios).pipe(
                map(id => {
                  const body = { segments: [defaultSegmentId] }
                  const params = { id }
                  return post(PORTFOLIO_CONSTANTS.AMEND_SEGMENTS, state$, body, params).pipe(
                    pluck('response'),
                    mapTo(portfolioApiEvents.amendSegments_success({ ...body, ...params })),
                    catchRestError(action),
                  )
                }),
                mergeMap((action: Observable<AnyAction>) => action),
              ),
            )

          deletedTeamMemberships.length > 0 &&
            zipActions.push(
              from(deletedTeamMemberships).pipe(
                map(id => {
                  const params = { userId: id, segmentId }
                  return remove(USER_ENDPOINTS.SEGMENTS, state$, params).pipe(
                    pluck('response'),
                    mapTo(userApiEvents.addSegmentMembership_success(params)),
                    catchRestError(action),
                  )
                }),
                mergeMap((action: Observable<AnyAction>) => action),
              ),
            )

          zipActions.push(of(goBack()))

          return zip(...zipActions)
        }),
        // @ts-expect-error ts-migrate(2488) FIXME: Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
        mergeMap(actions => [...actions, bookSegmentsEvents.updateSegment_success({ name })]),
      )
    }),
    tag('module/bookSegments/updateSegment'),
  )

import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { join, pick, pipe, values } from 'ramda'
import { ContactsProvider } from '../..'
import styles from './ViewContact.module.scss'
import AccountPanel from './AccountPanel/AccountPanel'
import ContactInfo from './ContactInfo/ContactInfo'
import { Dimmer, DimmerLoader } from '../../../../views/components'
import FileTable from '../../../documents/FileTable'

const propTypes = {}

const ViewContact = ({ currentParty, closeContact, isContactViewLoading, isReadOnly }: any) => {
  const name =
    currentParty && currentParty.tradingAs
      ? currentParty.tradingAs
      : currentParty && currentParty.companyName
      ? currentParty.companyName
      : currentParty
      ? pipe(pick(['firstName', 'lastName']), values, join(' '))(currentParty)
      : 'Loading...'

  return (
    <>
      <Helmet>
        <title>reOS | Contacts | {name}</title>
      </Helmet>
      <header className={styles.header}>
        <button className={styles.close} onClick={closeContact}>
          &nbsp;
        </button>
      </header>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; loading: any; }' is n... Remove this comment to see the full error message */}
      <DimmerLoader loading={isContactViewLoading}>
        <div className={styles.body}>
          <div className="rbn--row">
            <div className="rbn--col rbn-col-md-8">
              <AccountPanel />
            </div>
            <div className="rbn--col rbn-col-md-8">
              <ContactInfo />
              {currentParty?.id && (
                <section>
                  <FileTable
                    showDateTime={true}
                    showDateTimeBeneath={true}
                    allowRenaming={!isReadOnly}
                    allowRemove={!isReadOnly}
                    displayAndEditExtension={false}
                    onError={() => null}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; classId: any; }' is not... Remove this comment to see the full error message
                    forOwner={{ className: 'party', classId: currentParty.id }}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </DimmerLoader>
    </>
  )
}

ViewContact.propTypes = propTypes

export default subscribe([ContactsProvider], ({ currentParty, closeContact, isContactViewLoading, isReadOnly }) => ({
  currentParty,
  closeContact,
  isContactViewLoading,
  isReadOnly,
}))(ViewContact)

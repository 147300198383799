import React from 'react'
import cx from 'classnames'
import styles from './Table.module.scss'

interface HeadProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
}
export const Head = ({ className, children, ...props }: HeadProps) => (
  <div {...props} className={cx([styles.thead, className])}>
    {children}
  </div>
)

interface BodyProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
}
export const Body = ({ className, children, ...props }: BodyProps) => (
  <div {...props} className={cx([styles.tbody, className])}>
    {children}
  </div>
)

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
  head?: boolean
  onClick?: (event?: React.MouseEvent<any>) => void
}
export const Row = ({ className, children, head = false, onClick = () => {}, ...props }: RowProps) => (
  <div {...props} onClick={onClick} className={cx([styles.row, className])}>
    {children}
  </div>
)

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
  head?: boolean
}
export const Cell = ({ className, head = false, children, ...props }: CellProps): React.ReactElement => (
  <div {...props} className={cx([head ? styles.th : styles.td, className])}>
    {children}
  </div>
)

interface TableProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children: React.ReactNode
  type?: 'default' | 'simple'
  interactive?: boolean
}
export const Table = ({ className, type = 'default', children, ...props }: TableProps): React.ReactElement => (
  <div
    {...props}
    className={cx([styles.root, className], {
      [styles['is-simple']]: type === 'simple',
    })}
  >
    {children}
  </div>
)

Table.Head = Head
Table.Body = Body
Table.Row = Row
Table.Cell = Cell

export default Table

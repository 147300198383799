import React from 'react'

const Valid = (props: any) => (
  <svg width={20} height={20} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#128269" d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z" />
      <path d="M3 3h13.867v13.867H3z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#FFF"
        strokeWidth={0.585}
        d="M8.092 12.16L5.696 9.764l-.816.816 3.212 3.205 6.895-6.895-.817-.809z"
      />
    </g>
  </svg>
)

export default Valid

import { connect } from 'react-redux'
import { isOpen, getInvoiceId } from './state/selectors'
import { creditNoteEvents } from './state'
import CreditNoteProvider from './CreditNoteProvider'
import { reconApiSelectors, reconApiEvents } from '../../modules/api/recon'
import { apiStateSelectors } from '../../modules/api/apiState'

const mapStateToProps = (state: any) => ({
  isOpen: isOpen(state),
  invoiceId: getInvoiceId(state),
  creditNoteReasons: reconApiSelectors.getCreditNoteReasons(state),
  isSubmitting: apiStateSelectors.isLoading(state)([reconApiEvents.creditNote_request]),
})

const mapDispatchToProps = (dispatch: any) => ({
  openCreditNote: (invoiceId: any) => dispatch(creditNoteEvents.openCreditNoteRequested(invoiceId)),
  closeCreditNote: () => dispatch(creditNoteEvents.closeCreditNoteRequested()),
  createCreditNote: (id: any, body: any) =>
    dispatch(reconApiEvents.creditNote_request({ body, params: { id } }, { id, amount: body.amount })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteProvider)

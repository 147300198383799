import React from 'react'
import { subscribe } from 'react-contextual'
import { ListingTemplate } from '../../../views/components'
import { SegmentsProvider, SegmentsSearchFilter, SegmentsTable } from '..'
import { useEffectOnce } from 'react-use'
import { useDispatch } from 'react-redux'
import { agencyApiEvents } from '../../api/agency'

function SegmentsContent({ isLoading }: any) {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(agencyApiEvents.getSegments_request())
  })

  return <ListingTemplate loading={isLoading} Header={<SegmentsSearchFilter />} Body={<SegmentsTable />} />
}

export default subscribe([SegmentsProvider], ({ isLoading }: any) => ({
  isLoading,
}))(SegmentsContent)

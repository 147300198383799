import { of, zip } from 'rxjs'
import { concatMap, debounceTime, filter, map, mergeAll, mergeMap, pluck } from 'rxjs/operators'
import { matchPath } from 'react-router-dom'
import { ofType } from 'redux-observable'
import { get as _get, omit } from 'lodash-es'
import { find, path, pathOr } from 'ramda'
import * as Sentry from '@sentry/react'
import { tag } from 'rxjs-spy/operators/tag'
import { forkEpic } from 'utils/epics'
import * as chatbotSelectors from './selectors'
import { chatbotEvents } from './state'
import { getCurrentLeaseType } from './Dialogs/LeaseTypeSelection'
import { leaseConfirmationDialog } from './Dialogs/LeaseConfirmation'
import { getSelectedPlace, getSelectedProperty, propertyDetailsDialog } from './Dialogs/PropertyDetails'
import { ownerDetailsDialog } from './Dialogs/OwnerDetails'
import { partyApiEvents } from '../../api/party'
import { leaseRentAndFeesDialog } from './Dialogs/LeaseRentAndFees'
import { leaseSettingsDialog } from './Dialogs/LeaseSettings'
import { leaseTermDialog } from './Dialogs/LeaseTerm'
import { tenantDetailsDialog } from './Dialogs/TenantDetails'
import { leaseCommissionDialog } from './Dialogs/LeaseCommission'
import { getInvoices, getRemovedInvoices, getUpdatedInvoiceIds, leaseInvoicesDialog } from './Dialogs/LeaseInvoices'
import { portfolioApiEpics, portfolioApiEvents, portfolioApiTransformers } from '../../api/portfolio'
import { filterDialogTypes, ofDialogType } from './Dialogs/ChatbotDialog'
import { hasDupsStrict, uniqObjectsStrict } from 'utils/uniq'
import { userApiSelectors } from 'modules/api/user'
import { uiEvents } from '..'
import { ROUTES } from 'constants/routes'
import { $TSFixMe } from 'types/ts-migrate'

export const maybeOpenPortfolioWhenPropertyIsConfirmed = (action$: any, state$: any, dependencies: any) =>
  action$.pipe(
    ofDialogType(propertyDetailsDialog),
    ofType(propertyDetailsDialog.events.propertyConfirmed),
    filter(() => chatbotSelectors.getCurrentPortfolioId(state$.value) === null),
    map(_ => ({
      propertyId: getSelectedProperty(state$.value),
      placeId: getSelectedPlace(state$.value),
      leaseType: getCurrentLeaseType(state$.value),
    })),
    mergeMap(portfolio => {
      return forkEpic(
        portfolioApiEpics.apiCreatePortfolio,
        dependencies,
        state$,
        portfolioApiEvents.createPortfolio_request(portfolio),
      ).pipe(
        concatMap(res =>
          of(res).pipe(
            mergeMap((action: $TSFixMe) => {
              return action.type === 'http_error'
                ? [action]
                : forkEpic(
                    portfolioApiEpics.apiFetchEntity,
                    dependencies,
                    state$,
                    portfolioApiEvents.portfolio_request(action.payload.portfolioId),
                  ).pipe(
                    pluck('payload'),
                    mergeMap((payload: { id: string }) => [
                      portfolioApiEvents.portfolio_success(
                        portfolioApiTransformers.transformGetPortfolioResponse(payload),
                      ),
                      chatbotEvents.setCurrentPortfolio(payload),
                      chatbotEvents.portfolioRedirect('/leases/:id/edit/property/owner'),
                    ]),
                  )
            }),
          ),
        ),
      )
    }),
    tag('ChatBot/epic/portfolio/maybeOpenPortfolioWhenPropertyIsConfirmed'),
  )

const saveProperty = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([propertyDetailsDialog]),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ portfolioId }: { portfolioId:... Remove this comment to see the full error message
    map(({ portfolioId }) => {
      const { buildingName, unitNumber, selectedProperty } = chatbotSelectors.getPropertyDetailsDialog(state$.value)
      return {
        params: { id: portfolioId },
        body: {
          propertyId: selectedProperty,
          buildingName,
          unitNumber,
        },
      }
    }),
    filter(({ body: { propertyId } }) => propertyId),
    map(portfolioApiEvents.amendProperty_request),
    tag('ChatBot/epic/portfolio/saveProperty'),
  )

export const selectOwnerAfterCreated = (action$: any) =>
  action$.pipe(
    ofType(partyApiEvents.createParty_success),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => Object.values(payload)[0]),
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(party => party.tags.indexOf('Owner') !== -1),
    map(party => ownerDetailsDialog.proceed((events: any) => events.partySaved(party))),
    tag('ChatBot/epic/portfolio/selectOwnerAfterCreated'),
  )

export const selectTenantAfterCreated = (action$: any) =>
  action$.pipe(
    ofType(partyApiEvents.createParty_success),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
    map(({ payload }) => Object.values(payload)[0]),
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(party => party.tags.indexOf('Tenant') !== -1),
    map(party => tenantDetailsDialog.proceed((events: any) => events.partySaved(party))),
    tag('ChatBot/epic/portfolio/selectTenantAfterCreated'),
  )

const saveLeaseTermsOnChanged = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([leaseTermDialog]),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ portfolioId }: { portfolioId:... Remove this comment to see the full error message
    map(({ portfolioId }) => {
      const {
        term: { startDate, endDate, rolloverMonthToMonth },
      } = chatbotSelectors.getLeaseTermsDialog(state$.value)
      return portfolioApiEvents.amendTerms_request({
        body: {
          startDate,
          endDate,
          rolloverMonthToMonth,
        },
        params: { id: portfolioId },
      })
    }),
    tag('ChatBot/epic/portfolio/saveLeaseTermsOnChanged'),
  )

const saveleaseRentAndFees = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([leaseRentAndFeesDialog]),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ portfolioId }: { portfolioId:... Remove this comment to see the full error message
    map(({ portfolioId }) => {
      const formValues = chatbotSelectors.getLeaseRentAndFeesDialog(state$.value)
      const leaseType = getCurrentLeaseType(state$.value)

      return portfolioApiEvents.amendRentAndFees_request({
        body: {
          type: leaseType,
          value: { terms: formValues },
        },
        params: { id: portfolioId },
      })
    }),
    tag('ChatBot/epic/portfolio/saveleaseRentAndFees'),
  )

const saveLeaseSettings = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([leaseSettingsDialog]),
    mergeMap(({ portfolioId }) => {
      const settings = chatbotSelectors.getLeaseSettingsDialog(state$.value)
      const defaultSegmentId = userApiSelectors.getCurrentAgencyId(state$.value)
      const segmentIds = pathOr([], ['segments'], settings).map(({ id }: any) => id)
      const params = { id: portfolioId }

      return [
        portfolioApiEvents.amendSettings_request({
          body: {
            ...settings,
            segments: [defaultSegmentId, ...segmentIds],
          },
          params,
        }),
      ]
    }),
    tag('ChatBot/epic/portfolio/saveLeaseSettings'),
  )

const saveLeaseCommissionData = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([leaseCommissionDialog]),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ portfolioId }: { portfolioId:... Remove this comment to see the full error message
    map(({ portfolioId }) => {
      const payload = chatbotSelectors.getLeaseCommissionDialog(state$.value)
      return portfolioApiEvents.amendCommission_request({
        body: payload,
        params: { id: portfolioId },
      })
    }),
    tag('ChatBot/epic/portfolio/saveLeaseCommissionData'),
  )

const saveLeaseInvoiceTemplateData = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(p => p.portfolioId !== null),
    filterDialogTypes([leaseInvoicesDialog]),
    mergeMap(dialog => {
      const id = _get(dialog, 'portfolioId')
      const invoices = getInvoices(state$.value)
      const newTemplates = invoices.filter((inv: any) => inv.id === undefined)
      const updatedTemplateIds = getUpdatedInvoiceIds(state$.value)
      const removedTemplateIds = getRemovedInvoices(state$.value)
      const updatedTemplates = updatedTemplateIds.map((id: any) => find((inv: any) => inv.id === id, invoices))
      const updatedTemplatesFiltered = updatedTemplates.filter((t: any) => t !== undefined)
      const omitUnsetAmount = (tmpl: any) => {
        if (!tmpl) {
          return tmpl
        }

        return !tmpl.netAmount ? omit(tmpl, ['netAmount']) : tmpl
      }

      const templateEvents = [
        ...newTemplates.map((t: any) =>
          portfolioApiEvents.addInvoiceTemplate_request({ body: omitUnsetAmount(t), params: { id } }),
        ),
        ...updatedTemplatesFiltered.map((t: any) =>
          portfolioApiEvents.updateInvoiceTemplate_request({
            body: omitUnsetAmount(t),
            params: { id, invoiceId: t.id },
          }),
        ),
        ...removedTemplateIds.map((invoiceId: any) =>
          portfolioApiEvents.removeInvoiceTemplate_request({ id, invoiceId: invoiceId }),
        ),
      ]

      // post to sentry to track occurances
      if (hasDupsStrict(templateEvents)) {
        const id = userApiSelectors.getUserId(state$.value)
        const email = userApiSelectors.getUserEmail(state$.value)
        const username = userApiSelectors.getUserFirstName(state$.value)
        Sentry.captureEvent({
          message: JSON.stringify(templateEvents),
          environment: process.env.NODE_ENV,
          user: { id, email, username },
        })
      }

      /**
       * Remove strictly duplicate events as a precaution
       * https://app.clickup.com/t/5wjtr3
       */
      return zip(...uniqObjectsStrict(templateEvents).map((a: any) => of(a)))
    }),
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Observab... Remove this comment to see the full error message
    mergeMap(actions => actions),
    tag('ChatBot/epic/portfolio/saveLeaseInvoiceTemplateData'),
  )

export const savePortfolio = (action$: any, state$: any, { get, post, catchRestError }: any) =>
  action$.pipe(
    ofType(chatbotEvents.dialogStateChanged),
    map(event => ({
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...event,
      portfolioId: chatbotSelectors.getCurrentPortfolioId(state$.value),
    })),
    mergeMap(e => [
      saveProperty(of(e), state$),
      // saveOwner(of(e), state$, { get, post, catchRestError }),
      // addLeasePrimaryTenant(of(e), state$),
      saveLeaseTermsOnChanged(of(e), state$),
      saveleaseRentAndFees(of(e), state$),
      saveLeaseSettings(of(e), state$),
      saveLeaseCommissionData(of(e), state$),
      saveLeaseInvoiceTemplateData(of(e), state$, { post, catchRestError }),
    ]),
    mergeAll(),
    tag('ChatBot/epic/portfolio/savePortfolio'),
  )

export const fetchPortfolioOnSetRedirect = (action$: any, state$: any, { windowLocation }: any) =>
  action$.pipe(
    ofType(chatbotEvents.setCurrentPortfolio),
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    map(p => p.payload.id),
    filter(p => p !== null && p !== false),
    debounceTime(1000),
    map(id => windowLocation().pathname.replace(/\bnew\b/, ':id')),
    map(chatbotEvents.portfolioRedirect),
    tag('ChatBot/epic/portfolio/fetchPortfolioOnSetRedirect'),
  )

export const redirectToRentAndFees = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseTermDialog),
    ofType(leaseTermDialog.events.selectLeaseTerms),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/rent-and-fees')),
    tag('ChatBot/epic/portfolio/redirectToRentAndFees'),
  )

export const redirectToSettings = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseRentAndFeesDialog),
    ofType(leaseRentAndFeesDialog.events.rentAndFeesSaved),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/settings')),
    tag('ChatBot/epic/portfolio/redirectToSettings'),
  )

export const redirectToCommission = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseSettingsDialog),
    ofType(leaseSettingsDialog.events.settingsSaved),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/commission')),
    tag('ChatBot/epic/portfolio/redirectToCommission'),
  )

export const redirectToInvoicesMaybe = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseCommissionDialog),
    ofType(leaseCommissionDialog.events.commissionSaved),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/invoices')),
    tag('ChatBot/epic/portfolio/redirectToInvoicesMaybe'),
  )

export const redirectToApproved = (action$: any, state$: any) =>
  action$.pipe(
    ofType(portfolioApiEvents.approve_success),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/activated')),
    tag('ChatBot/epic/portfolio/redirectToApproved'),
  )

// export const redirectToDeclined = (action$, state$) => action$.pipe(
//   ofType(portfolioApiEvents.declined),
//   map(_ => events.portfolioRedirect('/leases/:id/edit/declined')),
//   tag('ChatBot/epic/portfolio/redirectToDeclined')
// )

export const requestLeaseApprovalSent = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseConfirmationDialog),
    ofType(leaseConfirmationDialog.events.approvalRequested),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/approval-sent')),
    tag('ChatBot/epic/portfolio/requestLeaseApprovalSent'),
  )

/**
 * @todo Temp code please remove later
 */
export const invoiceRedirectToActivate = (action$: any, state$: any) =>
  action$.pipe(
    ofDialogType(leaseInvoicesDialog),
    ofType(leaseInvoicesDialog.events.leaseInvoiceTemplatesSaved),
    map(_ => chatbotEvents.portfolioRedirect('/leases/:id/edit/activation')),
    tag('ChatBot/epic/portfolio/invoiceRedirectToActivate'),
  )

export const loadCommissionParties = (action$: any, state$: any) =>
  action$.pipe(
    ofType(uiEvents.redirect),
    map(action => {
      const route: $TSFixMe = path(['meta', 'pushToRoute'], action)
      const isCommissionPage = matchPath(route, {
        path: `${ROUTES.portfolioEdit}/commission`,
        exact: true,
        strict: false,
      })
      return isCommissionPage ? partyApiEvents.quickSearch_request({ tags: 'Agent, Agency' }) : false
    }),
    filter(action => !!action),
  )

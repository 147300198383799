import { prop } from 'ramda'
import { uiEvents } from '../../modules/ui'

export default (store: any) => (next: any) => (action: any) => {
  if (action.type === uiEvents.redirect.toString()) {
    if (prop('Intercom', window)) {
      window.Intercom('update')
    }
  }
  return next(action)
}

import React from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilter } from '../../../views/components'
import BadReferencesProvider from '../BadReferencesProvider'

const BadReferencesSearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([BadReferencesProvider], ({ location, redirect, filters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters,
}))(BadReferencesSearchFilter)

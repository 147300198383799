import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { map, prop, uniqBy } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'

const initialState: $TSFixMe = {
  invoices: [],
  invoiceTypes: [],
  notifications: [],
  draftInvoicesById: {},
}

const invoiceApiSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    invoices_request: (state, { payload }) => {},
    invoices_success: (state, { payload }) => {
      state.invoices = payload
    },
    invoice_request: (state, { payload }) => {},
    invoice_success: (state, { payload }) => {
      state.invoices = uniqBy(prop('id'), [...state.invoices, payload])
    },
    invoiceUpdate_request: (state, { payload }) => {},
    invoiceUpdate_success: (state, { payload }) => {
      state.invoices = state.invoices.map(inv => (inv.id === payload.id ? payload : inv))
    },
    createOnceOffInvoice_request: {
      prepare: (payload, meta) => ({ payload, meta, error: null }),
      reducer: (state, { payload }) => {},
    },
    createOnceOffInvoice_success: {
      prepare: (payload, meta) => ({ payload, meta, error: null }),
      reducer: (state, { payload }) => {
        state.invoices.push(payload)
      },
    },
    invoiceTypes_request: state => {},
    invoiceTypes_success: (state, { payload }) => {
      state.invoiceTypes = payload
    },
    sendInvoices_request: (state, { payload }) => {},
    sendInvoices_success: (state, { payload }) => {
      state.invoices = state.invoices.filter((inv: $TSFixMe) => {
        const invoiceResult = payload.find(({ id }) => inv.id === id)
        return !invoiceResult || (!invoiceResult.sent && !invoiceResult.result.includes('has already been sent'))
      })
    },
    deleteInvoices_request: (state, { payload }) => {},
    deleteInvoices_success: (state, { payload }) => {
      const ids = payload.map(({ id }: $TSFixMe) => id)
      state.invoices = state.invoices.filter((inv: $TSFixMe) => !ids.includes(inv.id))
    },
    restoreInvoices_request: (state, { payload }) => {},
    restoreInvoices_success: (state, { payload }) => {
      const ids: string[] = map(prop('id'), payload)
      ids.forEach((id: string) => {
        const invoiceIndex = state.invoices.findIndex(inv => inv.id === id)
        if (invoiceIndex !== -1) {
          state.invoices[invoiceIndex] = {
            ...state.invoices[invoiceIndex],
            tags: {
              ...state.invoices[invoiceIndex].tags,
              lightStatus: 'Green' /** @todo check if amount is set. The API doesn't return the actual invoice :| */,
              status: 'Draft',
            },
          }
        }
      })
    },
    notifications_request: (state, { payload }) => {},
    notifications_success: (state, { payload }) => {
      state.notifications = payload
    },
    draftInvoices_request: (state, { payload }) => {},
    draftInvoices_success: (state, { payload }) => {
      state.draftInvoicesById[payload.id] = payload.draftInvoices
    },
    saveBulkInvoices_request: (state, { payload }) => {},
    saveBulkInvoices_success: (state, { payload }) => {},
    fetchBulkInvoices_request: state => {},
    fetchBulkInvoices_success: (state, { payload }) => {},
    deleteBulkInvoices_request: (state, { payload }) => {},
    deleteBulkInvoices_success: (state, { payload }) => {},
  },
})

const { reducer, actions: events } = invoiceApiSlice

export { events, reducer }

import { connect } from 'react-redux'
import { LeaseTermForm } from '../../components'
import { chatbotEvents, chatbotSelectors } from '../../../modules/ui/ChatBot'
import { getLeaseTermInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseTerm'
import { portfolioApiSelectors } from '../../../modules/api/portfolio'
import { userApiSelectors } from '../../../modules/api/user'

const mapStateToProps = (state: any) => {
  const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)
  return {
    initialValues: getLeaseTermInitialValues(state),
    isActivePortfolio: portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId),
    isReadOnly: userApiSelectors.isReadOnlyRole(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSubmit: (values: any) => {
      dispatch(chatbotEvents.termValuesSubmitted(values))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseTermForm)

import { ofType } from 'redux-observable'
import { zip } from 'rxjs'
import { map, mergeMap, pluck } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { partyApiEvents } from '../party'
import { userApiSelectors } from '../user'
import { agencyApiEvents } from '.'
import { ENDPOINTS } from './constants'
import { multiUserOperators } from '../../multiUser/state'
import { restfulErrorEvent } from 'utils/restful'
import { $TSFixMe } from 'types/ts-migrate'
import { AnyAction } from 'redux'

/**
 * @todo This could be a agency invite feature
 */
export const fetchPartiesWhenMembersFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(agencyApiEvents.users_success),
    map((action: AnyAction) => action.payload.map((p: any) => p.userId)),
    map(partyApiEvents.parties_request),
    tag('agency/epics/fetchPartiesWhenMembersFetched'),
  )

export const fetchAgency = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.agency_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_AGENCY, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.agency_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/apiFetchAgency'),
  )

export const fetchInvitations = (action$: any, state$: any, { get, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.invitations_request),
    multiUserOperators.filterOnboardedAndOwner(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_INVITATIONS, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.invitations_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/apiFetchInvitations'),
  )
}

export const getInvite = (action$: any, state$: any, { get, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.invite_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_INVITE, state$, action.payload).pipe(
        pluck('response'),
        map(res => agencyApiEvents.invite_success(res, action.payload)),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/getInvite'),
  )
}

export const getSignupInvite = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.signupInvite_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_INVITE, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.signupInvite_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/getSignupInvite'),
  )

export const sendInvite = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.sendInvite_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.SEND_INVITE, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.sendInvite_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/sendInvite'),
  )
}

export const resendInvite = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.resendInvite_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.RESEND_INVITE, state$, null, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.resendInvite_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/resendInvite'),
  )
}

export const sendPartyInvite = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.sendPartyInvite_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.SEND_PARTY_INVITE, state$, action.payload.body, action.payload.params).pipe(
        pluck('response'),
        map(agencyApiEvents.sendPartyInvite_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/sendPartyInvite'),
  )
}

export const revokeInvite = (action$: any, state$: any, { remove, catchRestError }: any) => {
  return action$.pipe(
    ofType(agencyApiEvents.revokeInvite_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.REVOKE_INVITE, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.revokeInvite_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/revokeInvite'),
  )
}

export const getUsers = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.users_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_USERS, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.users_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/getUsers'),
  )

export const getThemes = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.themes_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_THEMES, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.themes_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/getThemes'),
  )

/**
 * @todo This could be a agency settings
 */
export const updateAgencyTheme = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.updateBranding_request),
    mergeMap((action: AnyAction) => {
      const {
        logo,
        theme: { name },
      } = action.payload
      return zip(
        put(ENDPOINTS.SET_THEME, state$, { name }).pipe(
          pluck('response'),
          map(agencyApiEvents.themeSet),
          catchRestError(action),
        ),
        put(ENDPOINTS.UPDATE_LOGO, state$, { logoUrl: logo }).pipe(
          pluck('response'),
          map(agencyApiEvents.logoUpdated),
          catchRestError(action),
        ),
      ).pipe(
        mergeMap(events => {
          const hasErrors = events.filter((e: $TSFixMe) => e.type === restfulErrorEvent.toString()).length
          const _events: any[] = []

          events.forEach(e => _events.push(e))

          if (!hasErrors) {
            _events.push(agencyApiEvents.updateBranding_success(null))
            _events.push(agencyApiEvents.agency_request({ id: userApiSelectors.getCurrentAgencyId(state$.value) }))
          }
          return _events
        }),
      )
    }),
    tag('agency/epics/updateAgencyTheme'),
  )

/**
 * @todo This could be a agency settings
 */
export const updateAgency = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.updateAgency_request),
    mergeMap((action: AnyAction) =>
      put(ENDPOINTS.UPDATE_AGENCY, state$, action.payload.values, { id: action.payload.id }).pipe(
        pluck('response'),
        map(agencyApiEvents.updateAgency_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/updateAgency'),
  )

export const fetchAgencyListing = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.agencyListing_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.AGENCY_LISTING, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.agencyListing_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/fetchAgencyListing'),
  )

export const fetchInvitableParties = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.invitableParties_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.INVITABLE_PARTIES, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.invitableParties_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/fetchInvitableParties'),
  )

export const getSegments = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.getSegments_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.SEGMENT_BASE, state$).pipe(
        pluck('response'),
        map(agencyApiEvents.getSegments_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/getSegments'),
  )

export const createSegment = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.createSegment_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.SEGMENT_BASE, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.createSegment_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/createSegment'),
  )

export const deleteSegment = (action$: any, state$: any, { remove, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.deleteSegment_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.SEGMENT_ENTITY, state$, action.payload).pipe(
        pluck('response'),
        map(agencyApiEvents.deleteSegment_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/deleteSegment'),
  )

export const deactivateAgency = (action$: any, state$: any, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(agencyApiEvents.deactivateAgency_request),
    mergeMap((action: $TSFixMe) =>
      post(ENDPOINTS.DEACTIVATE, state$, {}, action.payload).pipe(
        pluck('response'),
        map(() => agencyApiEvents.deactivateAgency_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/deactivateAgency'),
  )

export const activateAgency = (action$: any, state$: any, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(agencyApiEvents.activateAgency_request),
    mergeMap((action: $TSFixMe) =>
      post(ENDPOINTS.ACTIVATE, state$, {}, action.payload).pipe(
        pluck('response'),
        map(() => agencyApiEvents.activateAgency_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/activateAgency'),
  )

export const addToGroup = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.addToGroup_request),
    mergeMap((action: $TSFixMe) => {
      const { params, body } = action.payload
      return post(ENDPOINTS.AGENCY_TO_AGENCY_GROUP, state$, body, params).pipe(
        pluck('response'),
        map(agencyApiEvents.addToGroup_success),
        catchRestError(action),
      )
    }),
    tag('agency/epics/addToGroup'),
  )

export const removeFromGroup = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(agencyApiEvents.removeFromGroup_request),
    mergeMap((action: $TSFixMe) =>
      post(ENDPOINTS.REMOVE_AGENCY_FROM_AGENCY_GROUP, state$, {}, action.payload.params).pipe(
        pluck('response'),
        map(agencyApiEvents.removeFromGroup_success),
        catchRestError(action),
      ),
    ),
    tag('agency/epics/removeFromGroup'),
  )

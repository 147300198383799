import React, { FC } from 'react'
import cx from 'classnames'
import Dimmer from '../../atoms/Dimmer/Dimmer'
import Loader from '../../atoms/Loader/Loader'
import styles from './DimmerLoader.module.scss'

interface IDimmerLoaderProps {
  className?: string
  loading?: boolean
  fadeStyle?: 'flat' | 'gradient'
  bgColor: 'appBg' | 'white' | 'dark' | 'transparent'
  renderChildren?: boolean
  disabled?: boolean
  children: React.ReactChild
}

const DimmerLoader: FC<IDimmerLoaderProps> = ({
  children,
  className,
  loading = false,
  bgColor = 'appBg',
  fadeStyle,
  renderChildren = true,
  disabled = false,
  ...props
}) => {
  return (
    <div className={cx([styles.root, className, { [styles.loading]: loading }])} {...props}>
      {loading && <Loader className={styles.loader} theme={bgColor === 'dark' ? 'dark' : 'light'} />}
      {renderChildren ? (
        <Dimmer active={loading} bgColor={bgColor} fadeStyle={fadeStyle} disabled={false}>
          {children}
        </Dimmer>
      ) : (
        !loading && children
      )}
    </div>
  )
}

export default DimmerLoader

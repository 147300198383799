import React from 'react'
import PropTypes from 'prop-types'
import ModalTitle from './ModalTitle'

const propTypes = {
  title: PropTypes.string,
}

const ModalHeader = ({ title = '' }) => (
  <header className="Modal_Header">
    <ModalTitle>{title}</ModalTitle>
  </header>
)

ModalHeader.propTypes = propTypes

export default ModalHeader

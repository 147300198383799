import React from 'react'

const Trash = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      d="M21.444 9.25h-2.333V8.5c0-.827-.698-1.5-1.555-1.5h-3.112c-.857 0-1.555.673-1.555 1.5v.75h-2.333C9.698 9.25 9 9.923 9 10.75v1.125c0 .207.174.375.389.375H22.61a.382.382 0 0 0 .389-.375V10.75c0-.827-.698-1.5-1.556-1.5zm-7-.75h3.112v.75h-3.112V8.5zm7.39 4.5H10.166a.382.382 0 0 0-.39.375V23.5c0 .827.699 1.5 1.556 1.5h9.334c.857 0 1.555-.673 1.555-1.5V13.375a.382.382 0 0 0-.389-.375z"
      fill="#ED7161"
      fillRule="nonzero"
    />
  </svg>
)

export default Trash

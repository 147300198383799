import { addDays, format, isBefore, isValid } from 'date-fns'

export const get10thOfLeaseEndMonth = (leaseEndDate: Date): Date => {
  const leaseEndYear = format(leaseEndDate, 'yyyy')
  const leaseEndMonth = format(leaseEndDate, 'MM')

  return new Date(`${leaseEndYear}-${leaseEndMonth}-10`)
}

export const calculateLeaseRenewsAtDate = (leaseEndDate: Date, isExpired = false, now = new Date()): string => {
  const dateFormat = 'yyyy-MM-dd'

  if (isExpired || !isValid(leaseEndDate)) {
    return format(now, dateFormat)
  }

  const the10thOfLeaseEndMonth = get10thOfLeaseEndMonth(leaseEndDate)

  if (isBefore(now, addDays(the10thOfLeaseEndMonth, 1))) {
    return format(the10thOfLeaseEndMonth, dateFormat)
  }

  return format(now, dateFormat)
}

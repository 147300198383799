export const invoiceTypes = [
  'Commission',
  'ProcurementFee',
  'ApplicationFee',
  'LeaseFee',
  'RenewalFee',
  'IncomingInspection',
  'OutgoingInspection',
  'AccountingFees',
  'AdministrationFee',
  'AuditFees',
  'CancellationFee',
  'CashDepositFee',
  'CreditCheckFee',
  'DebtCollectionFee',
  'LatePaymentFee',
]

export const existingInvoiceTypes = [
  'Commission',
  'ProcurementFee',
  'ApplicationFee',
  'LeaseFee',
  'RenewalFee',
  'IncomingInspection',
  'OutgoingInspection',
  'AccountingFees',
  'AdministrationFee',
  'AuditFees',
  'CancellationFee',
  'CashDepositFee',
  'CreditCheckFee',
  'DebtCollectionFee',
  'LatePaymentFee',
  'ProRataRent',
  'RentInvoice',
]

import { of } from 'rxjs'
import { map, pluck, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { get as _get } from 'lodash-es'
import { partyApiEvents, partyApiSelectors } from '.'
import { ENDPOINTS } from './constants'
import { AnyAction } from 'redux'
import { $TSFixMe } from 'types/ts-migrate'

export const apiPartyQuickSearch = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.quickSearch_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.QUICK_SEARCH, state$, action.payload).pipe(
        pluck('response'),
        map(results => partyApiEvents.quickSearch_success({ results, params: action.payload })),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiPartyQuickSearch'),
  )

export const apiFetchParty = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.party_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.PARTY_ENTITY, state$, { id: action.payload }).pipe(
        pluck('response'),
        map((e: $TSFixMe) => partyApiEvents.party_success({ ...e, id: _get(e, 'company', e.person).id })),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiFetchParty'),
  )

export const apiCreateParty = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.createParty_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.PARTY_BASE, state$, action.payload).pipe(
        pluck('response'),
        map(response => partyApiEvents.createParty_success(response)),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiCreateParty'),
  )

export const apiUpdateParty = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.updateParty_request),
    mergeMap((action: AnyAction) => {
      const { id, values } = action.payload
      return put(ENDPOINTS.PARTY_ENTITY, state$, values, { id }).pipe(
        pluck('response'),
        map(partyApiEvents.updateParty_success),
        catchRestError(action),
      )
    }),
    tag('party/epics/apiUpdateParty'),
  )

export const apiFetchPartyTags = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.partyTags_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.PARTY_TAGS, state$).pipe(
        pluck('response'),
        map(partyApiEvents.partyTags_success),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiFetchPartyTags'),
  )

export const apiFetchAccounts = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.accounts_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.ACCOUNTS, state$).pipe(
        pluck('response'),
        map(partyApiEvents.accounts_success),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiFetchAccounts'),
  )

export const fetchPartiesIfNotInState = (action$: any, state$: any) =>
  action$.pipe(
    ofType(partyApiEvents.parties_request),
    pluck('payload'),
    mergeMap((partyIds: string[]) => {
      const unfetchedParties = partyIds.filter((id: any) => !partyApiSelectors.getPartyById(state$.value)(id))
      return of(...unfetchedParties).pipe(map(id => partyApiEvents.party_request(id)))
    }),
    tag('party/epics/fetchPartiesIfNotInState'),
  )

export const apiBadReferenceFuzzyMatches = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.badReferenceFuzzyMatches_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.BAD_REFERENCE_FUZZY_MATCHES, state$, action.payload).pipe(
        pluck('response'),
        map(res => partyApiEvents.badReferenceFuzzyMatches_success(res, action.payload)),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiBadReferenceFuzzyMatches'),
  )

export const apiBulkImport = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(partyApiEvents.bulkImport_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.BULK_IMPORT, state$, action.payload).pipe(
        pluck('response'),
        map((response: $TSFixMe) => partyApiEvents.bulkImport_success(response)),
        catchRestError(action),
      ),
    ),
    tag('party/epics/apiBulkImport'),
  )

import React, { FC } from 'react'
import cx from 'classnames'
import styles from './InitialsBadge.module.scss'

type Props = {
  name: string
  size?: 'sm' | 'md' | 'lg'
  contrast?: 'light' | 'normal' | 'dark'
  colour?: 'red' | 'green' | 'blue' | 'none'
}

const InitialsBadge: FC<Props> = ({ name = '', size = 'md', contrast = 'normal', colour = 'none' }) => {
  const names = name.split(' ')
  const firstName = names[0]
  let initials

  if (names.length === 1) {
    initials = firstName.slice(0, 1)
  } else {
    const lastName = names.slice(-1)[0]
    initials = firstName.slice(0, 1) + lastName.slice(0, 1)
  }

  const classes = cx([
    styles.root,
    styles[`size-${size}`],
    styles[`contrast-${contrast}`],
    styles[`border-colour-${colour}`],
  ])

  return <div className={classes}>{initials}</div>
}

export default InitialsBadge

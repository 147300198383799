import { toDate } from 'date-fns'
import { setItem, getItem } from '../../../utils/localStorage'

const NUDGED_INVOICES = '__NUDGED_INVOICES__'

export const hoursToThrottleNudge = 24

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | false' is not assignabl... Remove this comment to see the full error message
export const getNudgedInvoices = (): $TSFixMe => JSON.parse(getItem(NUDGED_INVOICES)) || []

export const saveNudgedInvoice = (invoiceId: any): void => {
  const nudgedInvoices = getNudgedInvoices()
  const updatedNudgedInvoice = { id: invoiceId, lastNudged: toDate(Date.now()).getTime() }
  const invoiceExists = nudgedInvoices.find((ni: any) => ni.id === invoiceId)
  if (!invoiceExists) {
    setItem(NUDGED_INVOICES, JSON.stringify([...nudgedInvoices, updatedNudgedInvoice]))
  } else {
    setItem(
      NUDGED_INVOICES,
      JSON.stringify(nudgedInvoices.map((ni: any) => (ni.id === invoiceId ? updatedNudgedInvoice : ni))),
    )
  }
}

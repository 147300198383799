import { auth } from '../../utils'
const APP_ROOT_URL = process.env.REACT_APP_API_URL
const { fetch } = window

const getRootURL = (props: any) => {
  return props.documentServiceEndpoint ? props.documentServiceEndpoint : `${APP_ROOT_URL}/document`
}

const getAuthToken = (props: any) => {
  if (!props.authToken) {
    return auth.getJWTToken()
  }
  return props.authToken
}

export const createMultipartUpload = async (props: any, file: any) => {
  const data = {
    document: {
      name: file.name,
      filetype: file.type,
    },
    attachTo: [],
  }
  const response = await fetch(`${getRootURL(props)}/multipart_create`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify(data),
  })

  const json = await response.json()
  return { uploadId: json.document.uploadId, key: json.document.fileId }
}

export const listParts = async (props: any, file: any, { key }: any) => {
  const response = await fetch(`${getRootURL(props)}/${key}/multipart_list`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
  })

  const json = await response.json()
  return json.parts.map(({ partNumber, size, etag }: any) => ({ PartNumber: partNumber, Size: size, ETag: etag }))
}

export const prepareUploadParts = async (props: any, file: any, { key, partNumbers }: any) => {
  const data = {
    partNumbers,
    attachTo: [],
  }
  return await fetch(`${getRootURL(props)}/${key}/multipart_prepare`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify(data),
  })
    .then(async response => await response.json())
    .then(response => {
      return response
    })
}

export const abortMultipartUpload = async (props: any, file: any, { key }: any) => {
  return await fetch(`${getRootURL(props)}/${key}/multipart_abort`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
  }).then(fetched => {
    // const response = fetched.json()
    return null
  })
}

export const completeMultipartUpload = async (props: any, file: any, { key, parts }: any) => {
  const data = {
    parts: parts.map(({ ETag, PartNumber }: any) => ({ etag: ETag, partNumber: PartNumber })),
    attachTo: [props.forOwner],
  }
  const response = await fetch(`${getRootURL(props)}/${key}/multipart_complete`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  return json
}

export const detach = async (abortControllerSignal: any, props: any, file: any) => {
  const data = {
    className: props.forOwner.className,
    id: props.forOwner.classId,
  }
  await fetch(`${getRootURL(props)}/${file.id}/detach`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    signal: abortControllerSignal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify(data),
  })
}

export const remove = async (props: any, file: any) => {
  await fetch(`${getRootURL(props)}/${file.id}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
  })
}

export const attach = async (abortControllerSignal: any, props: any, file: any, forOwner: any) => {
  const data = {
    className: forOwner.className,
    id: forOwner.id,
  }
  const response = await fetch(`${getRootURL(props)}/${file.id}/attach`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    signal: abortControllerSignal,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify(data),
  })
  const json = await response.json()
  return json
}

export const download = async (props: any, file: any) => {
  const response = await fetch(`${getRootURL(props)}/${file.id}/download`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
  })

  const result = {
    status: response.status,
    json: null,
  }

  try {
    result.json = await response.json()
  } catch (err) {}

  return result
}

export const rename = async (props: any, file: any) => {
  const response = await fetch(`${getRootURL(props)}/${file.id}/rename`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
    body: JSON.stringify({
      name: file.name,
    }),
  })

  const result = {
    status: response.status,
  }

  return result
}

export const listFiles = async (props: any, forOwner: any) => {
  const response = await fetch(`${getRootURL(props)}/class/${forOwner.className}/${forOwner.classId}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthToken(props),
    },
  })

  const result = {
    status: response.status,
    json: null,
  }

  try {
    result.json = await response.json()
  } catch (err) {}

  return result
}

import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { useInterval } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'

import { apiStateSelectors } from '../../../../api/apiState'
import { walletApiEvents, walletApiSelectors } from 'modules/api/wallet'
import { FormLoader, TextField, TextInput } from '../../../../../views/components'
import { walletTransferEvents } from '../../state'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  from: yup.string().required('Required.'),
})

const BulkTransferReferenceLookup = ({ onSuccess }: any) => {
  const dispatch = useDispatch()
  const [wallet, setWallet] = useState({ from: null })
  const isLookingUpWallet = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletApiEvents.referenceLookup_request]),
  )

  const getReferenceLookupByReference = useSelector(walletApiSelectors.getReferenceLookupByReference)
  const getGlobalBalanceAsset = useSelector(walletApiSelectors.getGlobalBalanceAsset)
  const fromWallet: $TSFixMe = getReferenceLookupByReference(wallet.from)
  const fromBalance = getGlobalBalanceAsset(fromWallet?.walletRef) || { balance: 0 }
  const fromWalletExists = useSelector(state => walletApiSelectors.walletExists(state)(fromWallet?.walletRef))

  useEffect(() => {
    if (wallet.from) {
      dispatch(walletApiEvents.referenceLookup_request({ reference: wallet.from }))
    }
  }, [dispatch, wallet])

  useEffect(() => {
    if (fromWallet && fromBalance && fromWalletExists) {
      onSuccess({ fromWallet, fromBalance })
    }
  }, [onSuccess, fromWallet, fromBalance, fromWalletExists])

  // poll balances in case of wallet creation
  useInterval(
    () => {
      dispatch(walletApiEvents.balance_request({ partyId: fromWallet.walletRef }))
    },
    wallet.from && !fromWalletExists ? 2000 : null,
  )

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
    <Formik initialValues={{ from: '' }} validationSchema={validationSchema} onSubmit={setWallet}>
      {({ handleChange, handleSubmit, submitCount, errors, values }) => {
        const searchFormLoaderState = isLookingUpWallet
          ? 'submitting'
          : submitCount > 0 && !isEmpty(errors)
          ? 'error'
          : undefined

        return (
          <FormLoader
            onSubmit={handleSubmit}
            state={searchFormLoaderState}
            submittingText="Fetching wallet for confirmation"
            buttonProps={{ children: 'Lookup Wallet' }}
          >
            <div className="rbn--row" style={{ marginBottom: '20px' }}>
              <div className="rbn--col rbn-col-md-4">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="from"
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'from' does not exist on type 'FormikErro... Remove this comment to see the full error message
                      value={values.from}
                      onChange={handleChange}
                    />
                  }
                  label="From Wallet"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'from' does not exist on type 'FormikErro... Remove this comment to see the full error message
                  error={errors.from}
                />
              </div>
              <div className="rbn--col rbn-col-md-4"></div>
              <div className="rbn--col rbn-col-md-4"></div>
            </div>
          </FormLoader>
        )
      }}
    </Formik>
  )
}

export default BulkTransferReferenceLookup

import { connect } from 'react-redux'
import { getUserFirstName } from '../../../modules/api/user/selectors'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: [
    'Ok, ' + getUserFirstName(state),
    'I will save our progress here and we can finish up these steps, next time.',
  ],

  actions: [
    {
      text: 'Great, take me home!',
      action: () => chatbotEvents.portfolioRedirect('/'),
    },
  ],
})

const mapDispatchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot)

import createSelector from 'selectorator'
import { find, identity, pathOr } from 'ramda'
import { NAMESPACE } from '../constants'

export const bulkImportInvoicesState = createSelector([NAMESPACE], identity)

const getSlice = (key: any) => createSelector([`${NAMESPACE}.${key}`], identity)

export const getInvoices = createSelector([bulkImportInvoicesState], state => pathOr([], ['invoices'], state))

export const hasSavedInvoices = createSelector([bulkImportInvoicesState], state =>
  pathOr(false, ['hasSavedInvoices'], state),
)

export const getInvoiceById = createSelector(
  [getInvoices],
  invoices => (id: any) => find((invoice: any) => invoice.id === id, invoices),
)

export const getImporting = getSlice('importing')

export const getImported = getSlice('imported')

export const getFailed = getSlice('failed')

export const isImporting = createSelector([getImporting], (importing = []) => importing.length > 0)

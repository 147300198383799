import React, { Component } from 'react'
import PropTypes from 'prop-types'
import VoiceLoading from '../../atoms/VoiceLoading/VoiceLoading'
import Button from '../../atoms/Button/Button'
import VoiceRobin from '../../atoms/VoiceRobin/VoiceRobin'
import styles from './ChatBot.module.scss'

const propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  messages: PropTypes.arrayOf(PropTypes.string),
  isTyping: PropTypes.bool,
  actionClickHandler: PropTypes.func,
}

const defaultProps = {
  isTyping: false,
}

class ChatBot extends Component {
  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'actions' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { actions, children, messages, isTyping, actionClickHandler } = this.props
    return (
      <div className={styles.root}>
        <div className={styles.container}>
          {messages && (
            <div className={styles.messages}>
              {messages.map((m: any, i: any) => (
                <div className={styles.message} key={i}>
                  <VoiceRobin text={m} />
                </div>
              ))}
              {isTyping && <VoiceLoading />}
            </div>
          )}
          {children && <div className={styles.body}>{children}</div>}
          {actions && (
            <div className={styles.actions}>
              {actions.map((a: any, i: any) => (
                <div className={styles.action} key={i}>
                  <Button disabled={a.disabled} onClick={() => !a.disabled && actionClickHandler(a.action)}>
                    {a.text}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ChatBot.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
ChatBot.defaultProps = defaultProps

export default ChatBot

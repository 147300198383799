import React, { useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../views/components'
import PortfoliosProvider from '../PortfoliosProvider'
import { Portfolios, PortfolioView } from '.'
import DepositProvider from '../../depositManagement/DepositProvider'

const PortfoliosPage = ({
  match: { params },
  openPortfolio,
  resetLayout,
  pathname,
  currentProperty,
  selectedPortfolio,
  portfolioViewId,
}: any) => {
  useEffect(() => {
    const leasesViewPath = /^\/leases\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/g
    if (params.id && pathname.match(leasesViewPath)) {
      openPortfolio(params.id)
    } else {
      resetLayout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** @todo Make initial props more flexible. */
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="portfolios"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: false, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Leases</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutPrimaryPanel>
            <DepositProvider
              currentProperty={currentProperty}
              selectedPortfolio={selectedPortfolio}
              portfolioViewId={portfolioViewId}
            >
              {params.id ? <PortfolioView /> : null}
            </DepositProvider>
          </LayoutPrimaryPanel>
          <LayoutContent>
            <TableProvider>
              <Portfolios />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(
  PortfoliosProvider,
  ({ match, openPortfolio, resetLayout, currentProperty, selectedPortfolio, portfolioViewId, pathname }: any) => ({
    match,
    openPortfolio,
    resetLayout,
    currentProperty,
    selectedPortfolio,
    portfolioViewId,
    pathname,
  }),
)(PortfoliosPage)

import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from './constants'
import { pathOr, split } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

const initialState: $TSFixMe = {
  byId: {},
}

const bankingApiSlice = createSlice({
  name: `${NAMESPACE}Api`,
  initialState,
  reducers: {
    partyErrors_request: (state, action: { payload: { partyId: string } }) => {
      const { partyId } = action.payload
      if (state.byId[partyId]) {
        state.byId[partyId] = null
      }
    },
    partyErrors_success: (state, { payload }) => {
      const {
        params: { partyId },
      } = payload

      if (state.byId[partyId]) {
        state.byId[partyId] = {
          ...state.byId[partyId],
          errors: [],
        }
      }
    },
    partiesErrors_request: (state, arg: { payload: { party_ids: string } }) => {},
    partiesErrors_success: (state, { payload }) => {
      const partyIds = split(',', pathOr('', ['params', 'party_ids'], payload))

      partyIds.forEach((id: any) => {
        if (state.byId[id]) {
          state.byId[id] = {
            ...state.byId[id],
            errors: [],
          }
        }
      })
    },
  },
  extraReducers: {
    http_error: (state, { payload, meta }) => {
      const {
        initAction: { type },
      } = meta
      const { status = 500 } = payload

      if (type === 'bankingApi/partyErrors_request' && status === 422) {
        const {
          initAction: {
            payload: { partyId },
          },
        } = meta
        const {
          response: { errors },
        } = payload

        state.byId[partyId] = {
          errors,
        }
      }

      if (type === 'bankingApi/partiesErrors_request' && status === 422) {
        const partyIds = split(',', pathOr('', ['initAction', 'payload', 'party_ids'], meta))
        const { response } = payload

        partyIds.forEach((id: any) => {
          if (response[id]) {
            state.byId[id] = {
              errors: response[id].errors,
            }
          }
        })
      }
    },
  },
})

const { reducer, actions: events } = bankingApiSlice

export { events, initialState, reducer }

import React, { FC } from 'react'
import cx from 'classnames'
import styles from './InfoBox.module.scss'

type Props = {
  type?: 'success' | 'info' | 'warning' | 'error'
  className?: string
  [key: string]: any
}

const InfoBox: FC<Props> = ({ type = 'info', className, children, ...props }) => {
  const classes = cx([styles.root, styles[`type--${type}`], className])
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  )
}

export default InfoBox

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { PropertyApiProvider } from '../../api/property'
import { PropertiesPage } from '.'
import PortfoliosProvider from '../../portfolios/PortfoliosProvider'

const PropertiesRoute = (props: any) => {
  return (
    <PortfoliosProvider {...props}>
      <PropertyApiProvider>
        <SearchFilterProvider location={props.location}>
          <PropertiesPage />
        </SearchFilterProvider>
      </PropertyApiProvider>
    </PortfoliosProvider>
  )
}

export default PropertiesRoute

import React, { useMemo, useCallback } from 'react'
import { subscribe } from 'react-contextual'
import { matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr, path } from 'ramda'
import { ROUTES } from 'constants/routes'
import { ContactsProvider } from '../../../contacts'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { $TSFixMe } from 'types/ts-migrate'
import { StringParam, useQueryParam } from 'use-query-params'
import { RootState } from 'src/state/store'

const CustomerDepositStatement = ({ match, redirect }: $TSFixMe): null | React.ReactElement => {
  const customerDepositMatchPath = matchPath(match.url, {
    path: ROUTES.customerDepositStatement,
    exact: true,
    strict: false,
  })

  const isOpen = path(['isExact'], customerDepositMatchPath)

  const [redirectTo] = useQueryParam('redirectTo', StringParam)

  const dispatch = useDispatch()

  const partyId: string | undefined = path(['params', 'id'], customerDepositMatchPath)
  const accountId: string | undefined = path(['params', 'accountId'], customerDepositMatchPath)

  const fetchCustomerDepositStatement = useCallback(
    (startDate, endDate, json = true) => {
      const event = json
        ? accountingApiEvents.depositStatementJson_request
        : accountingApiEvents.depositStatementPdf_request

      if (partyId && accountId) {
        dispatch(
          event({
            id: partyId,
            accountId,
            start_date: startDate,
            end_date: endDate,
          }),
        )
      }
    },
    [dispatch, partyId, accountId],
  )

  const onClose = useCallback(() => {
    redirect(redirectTo || `/contacts/${partyId}`)
  }, [redirect, redirectTo, partyId])

  const fetchCustomerDepositStatementPdf = useCallback(
    (startDate, endDate) => {
      fetchCustomerDepositStatement(startDate, endDate, false)
    },
    [fetchCustomerDepositStatement],
  )

  const statementData = useSelector((state: RootState) =>
    accountingApiSelectors.getDepositStatementJsonById(state)(accountId as string),
  )
  const statementPdf = useSelector((state: RootState) =>
    accountingApiSelectors.getDepositStatementPdfById(state)(accountId as string),
  )
  const pdfFileName = `${pathOr('', ['context', 'customer', 'name'], statementData) as string} Deposit Statement`

  return useMemo(() => {
    return isOpen ? (
      <ViewStatementProvider
        isOpen
        onClose={onClose}
        onParamChange={fetchCustomerDepositStatement}
        statementData={statementData}
        downloadStatement={fetchCustomerDepositStatementPdf}
        statementPdf={statementPdf}
        pdfFileName={pdfFileName}
      >
        <ViewStatement title="Deposit Statement" />
      </ViewStatementProvider>
    ) : null
  }, [
    onClose,
    fetchCustomerDepositStatement,
    fetchCustomerDepositStatementPdf,
    statementData,
    statementPdf,
    pdfFileName,
    isOpen,
  ])
}

const Provider = subscribe([ContactsProvider], ({ match, redirect }: $TSFixMe) => ({
  match,
  redirect,
}))

const WrappedComponent = Provider(CustomerDepositStatement)

export default WrappedComponent

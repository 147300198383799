import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from './Radio.module.scss'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  allowUncheck?: boolean
}

const Radio = ({ label, error, onChange, checked, allowUncheck = true, ...props }: Props) => {
  const { disabled, id } = props
  const [isChecked, setIsChecked] = useState(checked)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleChange = (e: any) => {
    const { checked } = e.target
    if (!isChecked || allowUncheck) {
      setIsChecked(!checked)
      if (typeof onChange === 'function') {
        const newEvent = { ...e, checked }
        onChange(newEvent)
      }
    }
  }

  const handleFocus = (e: any) => {
    if (props.onFocus) {
      props.onFocus(e)
    }

    setIsFocused(true)
  }

  const handleBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e)
    }

    setIsFocused(false)
  }

  const classes = classNames(styles.root, {
    [styles['is-focused']]: isFocused,
    [styles['is-checked']]: isChecked,
    [styles['has-error']]: error,
    [styles['is-disabled']]: disabled,
  })

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={id}>
          <input
            {...props}
            type="radio"
            checked={isChecked}
            onChange={handleChange}
            onClick={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {label}
          {error && ` - ${error}`}
        </label>
      )}
    </div>
  )
}

export default Radio

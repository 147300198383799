import { connect } from 'react-redux'
import { ChatBot } from '../../components'
import { chatbotEvents } from '../../../modules/ui/ChatBot'

const mapStateToProps = (state, { dispatch, push }: any) => ({
  actions: [
    {
      text: 'Continue',
      action: () => chatbotEvents.portfolioRedirect('/progress'),
    },
  ],

  messages: ['You can invite others to join you or continue solo for now.'],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

import React from 'react'
import { goBack } from 'connected-react-router'
import { FocusMode } from '../../../../views/components'
import InviteUserForm from '../InviteUserForm/InviteUserForm'
import { useDispatch } from 'react-redux'

function InviteUserRoute(props: any) {
  const dispatch = useDispatch()

  return (
    <div className="root">
      <FocusMode isOpen onClose={() => dispatch(goBack())} title="Invite a User" ContentComponent={InviteUserForm} />
    </div>
  )
}

InviteUserRoute.propTypes = {}

export default InviteUserRoute

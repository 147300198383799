import React, { useState } from 'react'
import { isEmpty } from 'ramda'

import { ListingTemplate, Header } from '../../../../views/components'
import SendIcon from 'components/atoms/Icons/Controls/Send'
import styles from './WalletTransferContent.module.scss'
import TransferReferenceLookup from './WalletTransfer/TransferReferenceLookup'
import WalletTransferConfrimation from './WalletTransfer/WalletTransferConfirmation'
import BulkTransferReferenceLookup from './BulkWalletTransfer/BulkTransferReferenceLookup'
import BulkWalletTransferConfirmation from './BulkWalletTransfer/BulkWalletTransferConfirmation'

const WalletTransfer = () => {
  const [data, setData] = useState({})

  return (
    <div className={styles.root}>
      <Header icon={<SendIcon />} text="Wallet Transfer" />
      {isEmpty(data) ? <TransferReferenceLookup onSuccess={setData} /> : <WalletTransferConfrimation data={data} />}
    </div>
  )
}

const BulkWalletTransfer = () => {
  const [bulkData, setBulkData] = useState({ fromWallet: '', fromBalance: 0 })

  return (
    <div className={styles.root}>
      <Header icon={<SendIcon />} text="Bulk Wallet Transfer" />
      {isEmpty(bulkData.fromWallet) ? (
        <BulkTransferReferenceLookup onSuccess={setBulkData} />
      ) : (
        <BulkWalletTransferConfirmation fromWallet={bulkData.fromWallet} fromBalance={bulkData.fromBalance} />
      )}
    </div>
  )
}

const WalletTransferContent = () => {
  return (
    <ListingTemplate
      loading={false}
      /** @todo replace temp height with better spacing solution */
      Header={<div style={{ minHeight: '71px' }}></div>}
      Body={
        <>
          <WalletTransfer />
          <BulkWalletTransfer />
        </>
      }
    />
  )
}

export default WalletTransferContent

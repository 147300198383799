import React, { memo } from 'react'
import { path } from 'ramda'
import { matchPath } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { FocusMode } from '../../../../views/components'
import { AdhocInvoiceProvider } from '../..'
import AdhocInvoiceForm from '../AdhocInvoiceForm/AdhocInvoiceForm'

const AdhocInvoiceFocusMode = (props: any): React.ReactElement => {
  const { match, goBack, initialValues } = props
  const editMatchPath = matchPath(match.url, {
    path: '/invoices/new',
    exact: true,
    strict: false,
  })

  return (
    <FocusMode
      isOpen={editMatchPath !== null}
      title={
        path(['invoiceType', 'value'], initialValues) === 'DepositTopUp'
          ? 'Deposit Top Up Ad Hoc Invoice'
          : 'Ad hoc Invoice'
      }
      ContentComponent={AdhocInvoiceForm}
      onClose={goBack}
    />
  )
}

export default subscribe(AdhocInvoiceProvider, ({ match, goBack, initialValues }: any) => ({
  match,
  goBack,
  initialValues,
}))(memo(AdhocInvoiceFocusMode))

import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isEmpty, path, pathOr } from 'ramda'
import { useEffectOnce } from 'react-use'
import { StringParam, useQueryParam } from 'use-query-params'

import { userApiSelectors } from '../../../api/user'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/images/logo.... Remove this comment to see the full error message
import logo from '../../../../theme/images/logo.svg'
import { Card, Layout, LayoutBody, LayoutHeader, Loader } from '../../../../views/components'
import { agencyApiEvents, agencyApiSelectors, agencyApiConstants } from '../../../api/agency'

import ExpiredInvitation from './ExpiredInvitation/ExpiredInvitation'
import RevokedInvitation from './RevokedInvitation/RevokedInvitation'
import InvitationForm from './InvitationForm/InvitationForm'
import styles from './InvitationRoute.module.scss'
import { apiStateSelectors } from '../../../api/apiState'
import FormErrors from '../../../../views/components/molecules/FormErrors/FormErrors'
import { $TSFixMe } from 'types/ts-migrate'

const InvitationRoute = ({ location }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(userApiSelectors.isAuthenticated)
  const from = pathOr('/', ['state', 'from'], location)

  const [token] = useQueryParam('token', StringParam)

  useEffectOnce(() => {
    dispatch(agencyApiEvents.invite_request({ token }))
  })

  const invitationObj = useSelector(state => agencyApiSelectors.getInvitationByToken(state)(token))
  const { invitationStatuses } = agencyApiConstants
  const invitationErrors = useSelector(state =>
    apiStateSelectors.getGeneralFormErrorsByEvent(state)(agencyApiEvents.invite_request({ token })),
  )

  const invitation: $TSFixMe = path(['invitation'], invitationObj)
  const agencyName: $TSFixMe = path(['agencyName'], invitationObj)

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return isAuthenticated ? (
    <Redirect to={from} />
  ) : (
    <div className={styles.root}>
      <Helmet>
        <title>reOS | Invitation</title>
      </Helmet>
      <Layout>
        <LayoutHeader>
          <div className={styles.header}>
            <img width="96px" src={logo} alt="reOS" />
          </div>
        </LayoutHeader>
        <LayoutBody>
          <div className={styles['form-container']}>
            <div style={{ width: '360px' }}>
              {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                !isEmpty(invitationErrors) ? (
                  <FormErrors errors={invitationErrors} />
                ) : (
                  <>
                    <Card on>
                      {
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        !invitation ? (
                          <Loader />
                        ) : invitation.status === invitationStatuses.revoked ? (
                          // @ts-expect-error ts-migrate(2741) FIXME: Property 'adminEmail' is missing in type '{}' but ... Remove this comment to see the full error message
                          <RevokedInvitation />
                        ) : invitation.status === invitationStatuses.expired ? (
                          <ExpiredInvitation />
                        ) : (
                          <InvitationForm
                            agencyName={agencyName}
                            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
                            token={token}
                            firstName={invitation.firstName}
                            lastName={invitation.lastName}
                          />
                        )
                      }
                    </Card>
                  </>
                )
              }
            </div>
          </div>
        </LayoutBody>
      </Layout>
    </div>
  )
}

export default InvitationRoute

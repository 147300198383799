import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ListingTemplate } from '../../../../views/components'
import { portfolioApiEvents } from '../../../api/portfolio'
import { propertyApiEvents } from '../../../api/property'
import { apiStateSelectors } from '../../../api/apiState'
import { PropertiesSearchFilter, PropertiesTable } from '..'

function Properties() {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(propertyApiEvents.properties_request())
    dispatch(portfolioApiEvents.portfolioSummaries_request())
  })

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([propertyApiEvents.properties_request]))

  return <ListingTemplate loading={isLoading} Header={<PropertiesSearchFilter />} Body={<PropertiesTable />} />
}

export default Properties

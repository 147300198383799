import React, { PureComponent } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { SettingsProvider, SearchFilterProvider } from '../../../providers'
import { SettingsPageProfile, SettingsBusinessDetails, SettingsCustomise } from '../../../containers'
import { Header, UsersPage, PrivateRoute } from '../..'
import ContentContainer from './ContentContainer/ContentContainer'
import { SegmentsRoute } from '../../../../modules/bookSegments'
import { $TSFixMe } from 'types/ts-migrate'

interface IContentRoutesProps {
  url: string
  match: $TSFixMe
  location: $TSFixMe
  userRole: string
}

const ContentRoutes = ({ url, match, location, userRole }: IContentRoutesProps) => (
  <>
    <Helmet>
      <title>reOS | Settings</title>
    </Helmet>
    <Route
      path={`${url}/profile`}
      render={() => (
        <ContentContainer>
          <Header tag="h1" text="Profile" />
          <SettingsPageProfile />
        </ContentContainer>
      )}
    />
    <PrivateRoute
      path={`${url}/business-details`}
      isAuthorised={userRole === 'Owner' || userRole === null}
      component={() => (
        <ContentContainer>
          <Header tag="h1" text="Business Details" />
          <SettingsBusinessDetails />
        </ContentContainer>
      )}
    />
    <PrivateRoute
      path={`${url}/branding`}
      isAuthorised={userRole === 'Owner' || userRole === null}
      component={SettingsCustomise}
    />
    <PrivateRoute
      path={`${url}/users`}
      isAuthorised={userRole === 'Owner' || userRole === null}
      component={() => (
        <SearchFilterProvider location={location}>
          <UsersPage />
        </SearchFilterProvider>
      )}
    />
    <PrivateRoute
      path={`${url}/segments`}
      isAuthorised={userRole === 'Owner' || userRole === null}
      component={() => <SegmentsRoute location={location} match={match} />}
    />
  </>
)

interface ISettingsPageProps {
  url?: string
  match?: $TSFixMe
  location?: $TSFixMe
  userRole?: string // fix this
}
@subscribe(SettingsProvider, ({ match, location, userRole }) => ({
  match,
  location,
  userRole,
}))
class SettingsPage extends PureComponent<ISettingsPageProps> {
  render(): null | React.ReactElement {
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
      <ContentRoutes url={this.props.match.url} location={this.props.location} userRole={this.props.userRole} />
    )
  }
}

export default SettingsPage

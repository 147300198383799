import React, { forwardRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { $TSFixMe } from 'types/ts-migrate'
import styles from './CurrencyInput.module.scss'
import classNames from 'classnames'
import ControlledTooltip from '../Tooltip/ControlledTooltip'
import { isNil } from 'ramda'

type TCurrencyInputProps = {
  id?: string
  className?: string
  disabled?: boolean
  name?: string
  value?: number | string
  placeholder?: string
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  autoComplete?: 'on' | 'off'
  allowNegative?: boolean
  inputTooltipText?: string
  onLeadingIconClick?: (e: $TSFixMe) => void
  onTrailingIconClick?: (e: $TSFixMe) => void
  onChange?: (e: $TSFixMe) => void
  onBlur?: (e: $TSFixMe) => void
  onFocus?: (e: $TSFixMe) => void
}

export type Ref = HTMLInputElement

const CurrencyInput = (props: TCurrencyInputProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFocus = (e: $TSFixMe) => {
    setIsFocused(true)
    if (typeof props.onFocus === 'function') {
      props.onFocus(e)
    }
  }

  const handleBlur = (e: $TSFixMe) => {
    setIsFocused(false)
    if (typeof props.onBlur === 'function') {
      props.onBlur(e)
    }
  }

  const handleLeadingIconClick = () => {
    if (typeof props.leadingIcon === 'object') {
      inputRef?.current?.focus()
    }
  }

  const handleTrailingIconClick = (e: $TSFixMe) => {
    if (typeof props.onTrailingIconClick === 'function') {
      props.onTrailingIconClick(e)
      inputRef?.current?.focus()
    }
  }

  const classes = classNames(styles.root, {
    [styles['is-focused']]: isFocused,
    [styles['has-leading-icon']]: props.leadingIcon,
    [styles['leading-icon-interactive']]: typeof props.leadingIcon === 'object',
    [styles['has-trailing-icon']]: props.trailingIcon,
    [styles['trailing-icon-interactive']]: props.onTrailingIconClick,
  })

  const numberFormat = (
    <NumericFormat
      thousandSeparator=" "
      value={props.value}
      getInputRef={inputRef}
      placeholder={props.placeholder}
      onValueChange={props.onChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      decimalScale={2}
      fixedDecimalScale
      autoComplete={props.autoComplete}
      disabled={props.disabled}
      allowNegative={props.allowNegative}
      defaultValue={0}
    />
  )

  return !props.leadingIcon && !props.trailingIcon ? (
    <div className={classes}>{numberFormat}</div>
  ) : (
    <ControlledTooltip
      body={props.inputTooltipText}
      toggleOnFocus={true}
      isFocused={!isNil(props.inputTooltipText) && isFocused}
    >
      <div className={classes}>
        <div className={styles['input-container']}>
          {props.leadingIcon && (
            <i className={`${styles.icon} ${styles['icon-leading']}`} onClick={handleLeadingIconClick}>
              {props.leadingIcon}
            </i>
          )}
          {numberFormat}
          {props.trailingIcon && (
            <i className={`${styles.icon} ${styles['icon-trailing']}`} onClick={handleTrailingIconClick}>
              {props.trailingIcon}
            </i>
          )}
        </div>
      </div>
    </ControlledTooltip>
  )
}

export default CurrencyInput

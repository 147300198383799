import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../atoms/Button/Button'

const propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'children' does not exist on type 'typeof... Remove this comment to see the full error message
  children: PropTypes.children,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
}

const ModalBody = ({ children, actions = [] }: any) => (
  <div className="Modal_Content">
    {children}
    {actions.length > 0 && (
      <div className="Modal_Actions">
        {actions.map(action => (
          <Button key={action.label} link onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </div>
    )}
  </div>
)

ModalBody.propTypes = propTypes

export default ModalBody

import React from 'react'

const Withdraw = (props: any) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={32} height={32} {...props}>
    <title>{props.title}</title>
    <g
      id="Icons-/-Finance-/-Withdraw"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.639999986"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="shape" transform="translate(5.000000, 5.000000)" stroke="#000000" strokeWidth="1.5">
        <path
          d="M18.629,18.5 L5.371,18.5 C5.15266565,18.5 4.94513684,18.4050049 4.80260428,18.2396134 C4.66007173,18.0742218 4.5966865,17.8549299 4.629,17.639 L6.75,3.5 L17.25,3.5 L19.371,17.639 C19.4033135,17.8549299 19.3399283,18.0742218 19.1973957,18.2396134 C19.0548632,18.4050049 18.8473343,18.5 18.629,18.5 Z"
          id="Path"
        ></path>
        <line x1="4.5" y1="21.5" x2="19.5" y2="21.5" id="Path"></line>
        <path
          d="M13.5,8 L11.467,8 C10.8243781,8.00044883 10.2722668,8.45639306 10.1503214,9.08733869 C10.0283759,9.71828432 10.3708266,10.3471242 10.967,10.587 L13.031,11.413 C13.6271734,11.6528758 13.9696241,12.2817157 13.8476786,12.9126613 C13.7257332,13.5436069 13.1736219,13.9995512 12.531,14 L10.5,14"
          id="Path"
        ></path>
        <line x1="12" y1="8" x2="12" y2="6.5" id="Path"></line>
        <line x1="12" y1="15.5" x2="12" y2="14" id="Path"></line>
        <path
          d="M3,8 L1.5,8 C1.08578644,8 0.75,7.66421356 0.75,7.25 L0.75,1.25 C0.75,0.835786438 1.08578644,0.5 1.5,0.5 L22.5,0.5 C22.9142136,0.5 23.25,0.835786438 23.25,1.25 L23.25,7.25 C23.25,7.66421356 22.9142136,8 22.5,8 L21,8"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
)

export default Withdraw

import React from 'react'
import cx from 'classnames'
import { get, pick } from 'lodash-es'
import { childrenUtils, getElementType, getUnhandledProps } from '../../../utils'
import SegmentGroup from './SegmentGroup'
import SegmentLabel from './SegmentLabel'
import styles from './Segment.module.scss'

type TProps = {
  as?: (...args: any[]) => any | string | symbol
  children?: React.ReactNode
  className?: string
  content?: string | React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  state?: 'danger' | 'info' | 'warning' | 'default'
  horizontal?: boolean
}

const Segment: React.FC<TProps> & {
  Group: typeof SegmentGroup
  Label: typeof SegmentLabel
} = props => {
  const { children, className, content, size = 'md', horizontal, state = 'default' } = props
  const rest = getUnhandledProps(Segment, props)
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
  const ElementType = getElementType(Segment, props)

  const classes = cx(styles.segment, styles[`size-${size}`], styles[`state-${state}`], className, {
    [styles.horizontal]: !!horizontal,
    [styles.interactive]: get(rest, 'onClick', false),
  })

  /** @todo create a htmlAttr helper similar to src/views/utils/htmlPropUtils.js `htmlInputAttrs` */
  const spreadProps = pick(props, ['onClick', 'style'])

  return (
    <ElementType {...spreadProps} className={classes}>
      {childrenUtils.isNil(children) ? content : children}
    </ElementType>
  )
}

Segment.Group = SegmentGroup
Segment.Label = SegmentLabel

export default Segment

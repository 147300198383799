import React, { ReactElement, FC, useRef, useState } from 'react'
import { isEmpty, pathOr } from 'ramda'
import { FastField } from 'formik'
import { CleaveField, FormField, Radio, RadioGroup, TextField, TextInput } from '../..'
import { PartySearch } from '../../../containers'
import { formUtils } from '../../../../utils'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'
import { format, isValid } from 'date-fns'
import { transformTwoDigitYearToFour } from 'utils/date'
import PhoneField from 'views/components/atoms/PhoneField/PhoneField'
import { ParsedCountry } from 'react-international-phone/dist/types'

type TProps = {
  values?: any
  tags?: any
  handleChange?: (e: any) => void
  handleBlur?: (e: any) => void
  handleSelect?: (e: any) => void
  setFieldValue?: any
  errors?: any
  touched?: any
  submitCount?: number
  allowPartySearch?: boolean
  disableFields?: any
  personsOnly?: boolean
  handleStateIdentificationType: (e: any) => void
  stateIdentificationType?: string
}

const NaturalPersonDetails: FC<TProps> = ({
  values,
  tags,
  handleChange,
  handleBlur,
  handleSelect,
  setFieldValue,
  errors,
  touched,
  allowPartySearch = true,
  disableFields = false,
  personsOnly = false,
  handleStateIdentificationType,
  stateIdentificationType,
}): ReactElement => {
  const getFieldError = formUtils.getFieldError(0, touched, errors)
  const getFieldValue = (name: any) => pathOr('', [name], values)

  let firstNameRef = useRef(null)
  const yy = getFieldValue('idNumber').substring(0, 2)
  const mm = getFieldValue('idNumber').substring(2, 4)
  const dd = getFieldValue('idNumber').substring(4, 6)

  const fourDigitYear = transformTwoDigitYearToFour(yy)
  const dob = new Date(parseInt(fourDigitYear ?? ''), parseInt(mm) - 1, parseInt(dd))
  const dateOfBirthFormatted = isValid(dob) ? format(dob, 'yyyy-MM-dd') : ''

  const [cellDialCode, setCellDialCode] = useState('+27')
  const [telDialCode, setTelDialCode] = useState('+27')

  const cellNumber = getFieldValue('cellNumber')
  const cellNumberReplaced = cellNumber.replace(/\s/g, '')
  const telNumber = getFieldValue('telNumber')
  const telNumberReplaced = telNumber.replace(/\s/g, '')

  return (
    <>
      <div className="rbn--row">
        <div className="rbn--col-md-3">
          <FormField>
            <FastField
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              render={({ field, form }: any) =>
                allowPartySearch ? (
                  <PartySearch
                    {...field}
                    includeIdentification
                    label="First Name"
                    placeholder="e.g. Barbara"
                    disabled={disableFields}
                    value={getFieldValue('firstName')}
                    onResultSelect={handleSelect}
                    tags={tags}
                    error={getFieldError('firstName')}
                    limitResults={personsOnly ? 'person' : null}
                  />
                ) : (
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.text}
                        name="firstName"
                        placeholder="e.g. Barbara"
                        value={getFieldValue('firstName')}
                        disabled={disableFields}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ref={(ref: any) => {
                          firstNameRef = ref
                        }}
                      />
                    }
                    label="First Name"
                    error={getFieldError('firstName')}
                  />
                )
              }
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="lastName"
                  placeholder="e.g. Gordon"
                  value={getFieldValue('lastName')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Last Name"
              error={getFieldError('lastName')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-6">
          <RadioGroup
            id="identificationType"
            name="identificationType"
            className="radio-group"
            stackRadios={false}
            label="Identification Type"
            defaultSelected={stateIdentificationType}
            onChange={(e: $TSFixMe) => {
              handleStateIdentificationType(e.target.value)
              if (e.target.value === 'passportNumber') {
                setFieldValue('idNumber', '')
                setFieldValue('dateOfBirth', '')
              } else {
                setFieldValue('passport', '')
                setFieldValue('dateOfBirth', '')
              }
            }}
            disabled={disableFields}
          >
            <Radio
              id="saIdNumber"
              name="saIdNumber"
              value="saIdNumber"
              label="South African ID Number"
              allowUncheck={false}
            />
            <Radio
              id="passportNumber"
              name="passportNumber"
              value="passportNumber"
              label="Non South African Passport Number"
              allowUncheck={false}
            />
          </RadioGroup>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                stateIdentificationType === 'saIdNumber' ? (
                  <TextInput
                    type={TextFieldTypes.text}
                    name="idNumber"
                    placeholder="e.g. 8605030000080"
                    value={getFieldValue('idNumber')}
                    disabled={disableFields}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <TextInput
                    type={TextFieldTypes.text}
                    name="passport"
                    placeholder="e.g. AA123456"
                    value={getFieldValue('passport')}
                    disabled={disableFields}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )
              }
              label={stateIdentificationType === 'saIdNumber' ? 'ID Number' : 'Passport Number'}
              error={getFieldError(stateIdentificationType === 'saIdNumber' ? 'idNumber' : 'passport')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <CleaveField
              name="dateOfBirth"
              type="date"
              id="dateOfBirth"
              label="Date of Birth"
              placeholder="e.g 1986-05-03"
              value={
                stateIdentificationType === 'saIdNumber' && getFieldValue('idNumber').length > 6
                  ? dateOfBirthFormatted
                  : values.dateOfBirth
              }
              onChange={handleChange || (() => {})}
              error={getFieldError('dateOfBirth')}
              disabled={disableFields}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.email}
                  name="emailAddress"
                  placeholder="barbara.gordon@gmail.com"
                  value={getFieldValue('emailAddress')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Email Address"
              error={getFieldError('emailAddress')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="taxNumber"
                  placeholder="e.g. 0123456789"
                  value={getFieldValue('taxNumber')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Tax Number"
              error={getFieldError('taxNumber')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <PhoneField
                  defaultCountry="za"
                  value={cellNumber.includes(cellDialCode) ? cellNumber : [cellDialCode, cellNumberReplaced].join(' ')}
                  disabled={disableFields}
                  onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                    setFieldValue('cellNumber', meta.inputValue)
                    setCellDialCode(meta.country.dialCode)
                  }}
                />
              }
              label="Contact Number"
              error={getFieldError('cellNumber')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <PhoneField
                  defaultCountry="za"
                  value={telNumber.includes(telDialCode) ? telNumber : [telDialCode, telNumberReplaced].join(' ')}
                  disabled={disableFields}
                  onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                    setFieldValue('telNumber', meta.inputValue)
                    setTelDialCode(meta.country.dialCode)
                  }}
                />
              }
              label="Alternative Contact Number"
              error={getFieldError('telNumber')}
            />
          </FormField>
        </div>
      </div>
    </>
  )
}

export default NaturalPersonDetails

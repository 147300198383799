import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash-es'
import { addMonths, format, lastDayOfMonth } from 'date-fns'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { Button, Checkbox, Select, FormField, DateRangePicker, Dimmer } from '../../../../components'
import styles from './LeaseTermForm.module.scss'

const propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  isActivePortfolio: PropTypes.bool,
}

const defaultProps = {
  onGoogleSearchSelect: () => {},
}

const validationSchema = yup.object().shape({
  startDate: yup.string().required('required.'),
  endDate: yup.string().required('required.'),
  rolloverMonthToMonth: yup.boolean().required('required.'),
})

const LeaseTermForm = (props: any) => {
  const {
    handleSubmit,
    values,
    // errors,
    // touched,
    // handleChange,
    handleBlur,
    setFieldValue,
    // setFieldTouched,
    // isSubmitting
    isActivePortfolio,
    isReadOnly,
  } = props

  const changeDurationHelper = useCallback((from, value) => lastDayOfMonth(addMonths(new Date(from), value - 1)), [])

  const getDurationOptions = useCallback(
    () =>
      range(1, 13).map((i: any) => ({
        value: i,
        label: `${i} Month${i > 1 ? 's' : ''}`,
      })),
    [],
  )

  const [duration, setDuration] = useState(12)
  const [leaseStartDate, setLeaseStartDate] = useState(new Date(values.startDate))
  const [leaseEndDate, setLeaseEndDate] = useState(new Date(values.endDate))

  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isReadOnly}>
          <div className={styles.body}>
            <div className="rbn--row">
              <div className="rbn--col-md-4">
                <FormField>
                  <Select
                    name="duration"
                    label="Duration"
                    options={getDurationOptions().reverse()}
                    value={getDurationOptions().find((o: any) => o.value === duration)}
                    onChange={({ value }: any) => {
                      const {
                        values: { startDate },
                      } = props
                      const endDate = changeDurationHelper(startDate, value)
                      setDuration(value)
                      setFieldValue('endDate', endDate)
                      setLeaseEndDate(endDate)
                    }}
                  />
                </FormField>
              </div>
            </div>
            <div className="rbn--row">
              <div className="rbn--col-md-8">
                <Dimmer active={isReadOnly} bgColor="white">
                  <FormField>
                    <DateRangePicker
                      startDate={leaseStartDate}
                      endDate={leaseEndDate}
                      startLabel="Start date"
                      endLabel="End date"
                      onChange={(date: any, isStartDate: any) => {
                        if (isStartDate) {
                          setLeaseStartDate(date)
                          setFieldValue('startDate', format(date, 'yyyy-MM-dd'))
                        } else {
                          setLeaseEndDate(date)
                          setFieldValue('endDate', format(date, 'yyyy-MM-dd'))
                        }
                      }}
                      inline
                    />
                  </FormField>
                </Dimmer>
              </div>
            </div>
            <div className="rbn--row">
              <div className="rbn--col-md-4">
                <FormField>
                  <Checkbox
                    name="rolloverMonthToMonth"
                    label="Switch to month-to-month on expiry"
                    checked={values.rolloverMonthToMonth}
                    onChange={(e: any) => setFieldValue('rolloverMonthToMonth', e.checked)}
                    onBlur={handleBlur}
                  />
                </FormField>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button type="submit">{isActivePortfolio ? 'Save' : "Done, let's continue"}</Button>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

LeaseTermForm.propTypes = propTypes
LeaseTermForm.defaultProps = defaultProps

export default withFormik({
  validationSchema,
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.handleSubmit(values)
  },
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ (props: any): JSX.Element; pro... Remove this comment to see the full error message
})(LeaseTermForm)

import { connect } from 'react-redux'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ['Okay, we\ve let %requestorName% know.'],

  actions: [
    {
      text: 'take me home',
      action: () => chatbotEvents.portfolioRedirect('/'),
    },
  ],
})

const mapDispatchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot)

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { uiEvents } from '../ui'

const AdminReportingProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props

  const adminReportingStore = {
    match,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    ...props,
  }
  return <context.Provider value={adminReportingStore}>{children}</context.Provider>
})

export default AdminReportingProvider

import React from 'react'

const SvgContactSettingsFill = ({ fill = '#191923', ...props }): React.ReactElement => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M16 18v8H8a8 8 0 018-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm2.595 5.812a3.51 3.51 0 010-1.623l-.992-.573 1-1.732.992.573c.398-.377.88-.655 1.405-.812V17.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 010 1.622l.992.573-1 1.732-.992-.573c-.398.377-.88.655-1.405.812V26.5h-2v-1.145a3.496 3.496 0 01-1.405-.812l-.992.573-1-1.732.992-.572zM22 21a1 1 0 100 2 1 1 0 100-2z"
        fillOpacity={0.64}
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgContactSettingsFill

import { combineReducers } from 'redux'
import { chatbotModel } from './model'
import { leaseTypeSelectionDialog } from './Dialogs/LeaseTypeSelection'
import { propertyDetailsDialog } from './Dialogs/PropertyDetails'
import { ownerDetailsDialog } from './Dialogs/OwnerDetails'
import { tenantDetailsDialog } from './Dialogs/TenantDetails'
import { leaseSettingsDialog } from './Dialogs/LeaseSettings'
import { leaseConfirmationDialog } from './Dialogs/LeaseConfirmation'
import { leaseTermDialog } from './Dialogs/LeaseTerm'
import { leaseCommissionDialog } from './Dialogs/LeaseCommission'
import { leaseRentAndFeesDialog } from './Dialogs/LeaseRentAndFees'
import { leaseInvoicesDialog } from './Dialogs/LeaseInvoices'
import { userDetailsDialog } from './Dialogs/UserDetails'
import { agencyDetailsDialog } from './Dialogs/AgencyDetails'
import { portfolioApiEvents } from '../../api/portfolio'
import { chatbotPortfolioReducer } from './state'

const chatbotReducer = combineReducers({
  dialogs: combineReducers({
    leaseTypeSelection: leaseTypeSelectionDialog.reducer(chatbotModel),
    propertyDetails: propertyDetailsDialog.reducer(chatbotModel),
    ownerDetails: ownerDetailsDialog.reducer(chatbotModel),
    tenantDetails: tenantDetailsDialog.reducer(chatbotModel),
    leaseSettings: leaseSettingsDialog.reducer(chatbotModel),
    leaseTerms: leaseTermDialog.reducer(chatbotModel),
    leaseCommission: leaseCommissionDialog?.reducer(chatbotModel),
    leaseRentAndFees: leaseRentAndFeesDialog.reducer(chatbotModel),
    leaseConfirmation: leaseConfirmationDialog.reducer(chatbotModel),
    leaseInvoices: leaseInvoicesDialog.reducer(chatbotModel),
    // Onboarding
    userDetails: userDetailsDialog.reducer(chatbotModel),
    agencyDetails: agencyDetailsDialog.reducer(chatbotModel),
  }),
  portfolio: chatbotPortfolioReducer,
})

export default chatbotReducer

import React from 'react'
import cx from 'classnames'
import Image from '../Image/Image'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './spinner.svg' or its correspo... Remove this comment to see the full error message
import spinner from './spinner.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './spinner-dark.svg' or its cor... Remove this comment to see the full error message
import spinnerDark from './spinner-dark.svg'
import styles from './Loader.module.scss'

type TProps = {
  className?: string
  theme?: 'light' | 'dark'
}

const Loader = ({ className, theme = 'light' }: TProps) => {
  return (
    <div className={cx([styles.root, className])}>
      <Image src={theme === 'dark' ? spinnerDark : spinner} alt="Loading..." />
    </div>
  )
}

export default Loader

import React, { forwardRef } from 'react'
import { $TSFixMe } from 'types/ts-migrate'

type Props = {
  id?: string
  disabled?: boolean
  name?: string
  value?: number | string
  placeholder?: string
  autoComplete?: 'on' | 'off'
  onChange?: (e: $TSFixMe) => void
  onBlur?: (e: $TSFixMe) => void
  onClick?: () => void
  onFocus?: () => void
}

export type Ref = HTMLTextAreaElement

const TextArea = forwardRef<Ref, Props>((props, ref) => (
  <textarea
    id={props.id}
    disabled={props.disabled}
    name={props.name}
    value={props.value}
    placeholder={props.placeholder}
    onChange={props.onChange}
    onClick={props.onClick}
    onFocus={props.onFocus}
    onBlur={props.onBlur}
    autoComplete={props.autoComplete}
    ref={ref}
  />
))

TextArea.displayName = 'TextArea'

export default TextArea

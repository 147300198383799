import React from 'react'

const SvgEmailFill = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M7 7h18a1 1 0 011 1v16a1 1 0 01-1 1H7a1 1 0 01-1-1V8a1 1 0 011-1zm9.06 8.683l-6.412-5.445-1.295 1.524 7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439h-.001z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgEmailFill

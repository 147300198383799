import React, { useMemo, createContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useMedia } from 'react-use'
import { useBreakpoints } from '../../views/hooks'

import { layoutEvents } from './state/state'
import * as selectors from './state/selectors'

const propTypes = {
  stateKey: PropTypes.string.isRequired,
}

export const LayoutContext = createContext({})

const LayoutProvider = (props: any) => {
  const dispatch = useDispatch()
  const { breakpoints, isMobile } = useBreakpoints()

  let { initialProps } = props

  const gtmd = useMedia(`(min-width: ${breakpoints.md}px)`)

  if (!initialProps) {
    initialProps = {
      sidebar: { open: !isMobile, width: gtmd ? 240 : window.outerWidth },
      primaryPanel: { open: false, width: 600 },
      secondaryPanel: { open: false, width: 375 },
    }
  }

  const { stateKey } = props
  const layoutStateByKey = useSelector(state => selectors.getLayoutByKey(state)(stateKey))
  useEffect(() => {
    if (stateKey && !layoutStateByKey) {
      dispatch(layoutEvents.init({ key: stateKey, initialState: initialProps }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateKey, layoutStateByKey])

  const isSidebarOpen = useSelector(state => selectors.isSidebarOpen(state)(stateKey))
  const openSidebar = useCallback(() => dispatch(layoutEvents.openSidebar(stateKey)), [dispatch, stateKey])
  const closeSidebar = useCallback(() => dispatch(layoutEvents.closeSidebar(stateKey)), [dispatch, stateKey])

  const isPrimaryPanelOpen = useSelector(state => selectors.isPrimaryPanelOpen(state)(stateKey))
  const openPrimaryPanel = useCallback(() => dispatch(layoutEvents.openPrimaryPanel(stateKey)), [dispatch, stateKey])
  const closePrimaryPanel = useCallback(() => dispatch(layoutEvents.closePrimaryPanel(stateKey)), [dispatch, stateKey])

  const isSecondaryPanelOpen = useSelector(state => selectors.isSecondaryPanelOpen(state)(stateKey))
  const openSecondaryPanel = useCallback(
    () => dispatch(layoutEvents.openSecondaryPanel(stateKey)),
    [dispatch, stateKey],
  )
  const closeSecondaryPanel = useCallback(
    () => dispatch(layoutEvents.closeSecondaryPanel(stateKey)),
    [dispatch, stateKey],
  )

  const layoutStore = useMemo(
    () => ({
      breakpoints,
      sidebar: {
        ...initialProps.sidebar,
        open: isSidebarOpen,
        openSidebar,
        closeSidebar,
      },
      primaryPanel: {
        ...initialProps.primaryPanel,
        open: isPrimaryPanelOpen,
        openPrimaryPanel,
        closePrimaryPanel,
      },
      secondaryPanel: {
        ...initialProps.secondaryPanel,
        isOpen: isSecondaryPanelOpen,
        open: openSecondaryPanel,
        close: closeSecondaryPanel,
      },
    }),
    [
      breakpoints,
      initialProps,
      isSidebarOpen,
      openSidebar,
      closeSidebar,
      isPrimaryPanelOpen,
      openPrimaryPanel,
      closePrimaryPanel,
      isSecondaryPanelOpen,
      openSecondaryPanel,
      closeSecondaryPanel,
    ],
  )

  return <LayoutContext.Provider value={layoutStore}>{props.children}</LayoutContext.Provider>
}

LayoutProvider.propTypes = propTypes

export default LayoutProvider

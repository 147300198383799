import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash-es'
import cx from 'classnames'
import { partyApiSelectors } from 'modules/api/party'
import { getLastFourDigits } from 'utils/general'
import CurrencyField from '../../../../molecules/CurrencyField/CurrencyField'
import SettledBeneficiary from '../SettledBeneficiary/SettledBeneficiary'
import styles from '../Beneficiary.module.scss'

type TProps = {
  className?: string
  placeholder?: string
  value?: number | string
  amountName?: string
  beneficiaryName?: string
  referenceContext?: string
  reference?: string | React.ReactNode
  disableAmount?: boolean
  errors?: any
  vatApplied?: boolean
  includeVatCalculator?: boolean
  isBeneficiarySettled?: boolean
  amountPayable?: number
  inputTooltipText?: string
  Meta?: React.ComponentType
  onVatChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onNewBeneficiaryAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  moveBeneficiary?: (dragindex: number, hoverindex: number) => void
}

const Beneficiary: React.FC<TProps> = ({
  className,
  placeholder,
  value,
  amountName,
  beneficiaryName,
  referenceContext,
  reference,
  disableAmount,
  vatApplied,
  includeVatCalculator = true,
  errors,
  isBeneficiarySettled,
  amountPayable,
  inputTooltipText,
  Meta,
  onVatChange,
  onNewBeneficiaryAmountChange,
  moveBeneficiary,
}): null | React.ReactElement => {
  const classes = cx([styles.root, className])

  const beneficiaryBankDetails = useSelector(partyApiSelectors.getPartyBankDetails)
  const beneficiaryBank = beneficiaryBankDetails(referenceContext).bank
  const beneficiaryAccountNumber = beneficiaryBankDetails(referenceContext).accountNumber

  return (
    <li className={classes}>
      <span className={styles['beneficiary-text']}>
        <strong>{beneficiaryName}</strong> <br />
        {referenceContext === 'EasyPay Payment' || referenceContext === 'Commission Wallet Transfer' ? (
          <span>{referenceContext}</span>
        ) : beneficiaryBank && beneficiaryAccountNumber ? (
          <span>
            {beneficiaryBank}&#x2a;&#x2a;&#x2a;&#x2a;&#x2a;
            {getLastFourDigits(beneficiaryAccountNumber)}
          </span>
        ) : (
          <span>No Bank Account Details</span>
        )}
        <br />
        <span>{reference}</span>
      </span>
      {isBeneficiarySettled ? (
        <SettledBeneficiary amountPayable={amountPayable} />
      ) : (
        <div className={styles['input-wrapper']}>
          <CurrencyField
            disabled={disableAmount}
            includeVatControl
            includeVatCalculator={includeVatCalculator}
            name={amountName}
            placeholder={placeholder}
            value={value}
            onChange={onNewBeneficiaryAmountChange}
            error={get(errors, 'amount') || get(errors, ['clearance', 'value', 'grossAmount'])}
            onToggleChange={onVatChange}
            vatApplied={vatApplied}
            inputTooltipText={inputTooltipText}
          />
          {Meta && (
            <div className={styles.meta}>
              <Meta />
            </div>
          )}
        </div>
      )}
    </li>
  )
}

export default Beneficiary

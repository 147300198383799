import * as Sentry from '@sentry/react'
import { IS_PRODUCTION } from '../../constants/general'
import { agencyApiEvents } from '../../modules/api/agency'
import { userApiEvents, userApiSelectors } from '../../modules/api/user'

export default (store: any) => (next: any) => (action: any) => {
  const state = store.getState()

  if (IS_PRODUCTION && action.type === userApiEvents.login_success.toString()) {
    const { getUserId, getUserFirstName, getUserEmail } = userApiSelectors
    const id = getUserId(state)
    const username = getUserFirstName(state)
    const email = getUserEmail(state)

    Sentry.configureScope(scope => {
      scope.setUser({ id, username, email })
    })
  }

  if (IS_PRODUCTION && action.type === agencyApiEvents.agency_success.toString()) {
    const agencyDetails = action.payload?.agency?.agencyDetails
    if (agencyDetails) {
      const agency = {
        id: action.payload.id,
        ...agencyDetails,
      }
      Sentry.configureScope(scope => {
        scope.setContext('agencyDetails', agency)
      })
    }
  }

  return next(action)
}

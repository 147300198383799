import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { currencyUtils } from '../../../../utils'
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import styles from './CommissionChart.module.scss'
import shortenLargeNumber from '../../../../utils/shortenLargeNumber'
import { createBreakpoint } from 'react-use'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
}

const lineStyles = {
  strokeWidth: 2,
}

const dotStyles = {
  r: 6,
}

const useBreakpoints = createBreakpoint({ xs: 320, sm: 640, md: 768, lg: 1024, xl: 1280, xxl: 1440 })

const CommissionChart = ({ data, className }: $TSFixMe) => {
  const classNames = cx([styles.root, className])
  const breakpoint = useBreakpoints()

  // Trim data based on screen size
  // const _data = useMemo(() => {
  //   switch (breakpoint) {
  //     case 'xs':
  //       return data.slice(-5, data.length)
  //     case 'sm':
  //     case 'md':
  //       return data.slice(-8, data.length)
  //     case 'lg':
  //       return data.slice(-(data.length / 2), data.length)
  //     default:
  //       return data
  //   }
  // }, [breakpoint, data])

  const chartHeight = useMemo(() => {
    switch (breakpoint) {
      case 'xs':
      case 'xm':
      case 'md':
        return 280
      case 'lg':
        return 300
      case 'xl':
        return 400
      case 'xxl':
        return 500
      default:
        return 330
    }
  }, [breakpoint])

  return (
    <div className={classNames}>
      <ResponsiveContainer debounce={300} height={chartHeight}>
        <ComposedChart data={data}>
          <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '32px' }} align="right" height={40} />

          <CartesianGrid stroke="#f5f5f5" />

          <XAxis dataKey="name" tick={true} tickMargin={10} />

          <YAxis
            yAxisId="left"
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            tickFormatter={(value: $TSFixMe) => `R ${shortenLargeNumber(currencyUtils.fromCents(value))}`}
            tick={{ fontSize: '1rem' }}
          />

          {/* Added for scale and hidden */}
          <YAxis hide={true} yAxisId="right" dataKey="activeLeases" orientation="right" />

          <Tooltip
            content={(props: $TSFixMe) => {
              const activeLeases = props.payload.find((i: $TSFixMe) => i.dataKey === 'activeLeases')
              return (
                <div className={styles.tooltip}>
                  <ul>
                    {props.payload
                      .filter((item: $TSFixMe) => item.dataKey !== 'activeLeases')
                      .map((item: $TSFixMe, i: number) => {
                        const formattedItemValue = currencyUtils.fromCents(item.value)
                        return (
                          <li key={`item${i}`} style={{ color: item.color }}>
                            <div>
                              <span>{item.name}: &nbsp;</span>
                              <span>
                                {' '}
                                R {shortenLargeNumber(formattedItemValue, formattedItemValue >= 1000 ? 1 : 0)}
                              </span>
                            </div>
                          </li>
                        )
                      })}
                    {activeLeases && (
                      <li className={styles['active-properties-list-item']} style={{ color: activeLeases.color }}>
                        <span>{activeLeases.name}: &nbsp;</span>
                        <span>{activeLeases.value}</span>
                      </li>
                    )}
                  </ul>
                </div>
              )
            }}
            wrapperStyle={{ backgroundColor: 'white', boxShadow: '0 0 8px rgba(0, 0, 0, .24)' }}
            labelStyle={{ color: 'black' }}
            itemStyle={{
              color: 'rgba(0, 0, 0, .65)',
            }}
          />

          <Bar name="Leases" yAxisId="right" maxBarSize={40} dataKey="activeLeases" fill="rgba(158, 158, 164, .24)" />

          <Line
            name="Commission"
            yAxisId="left"
            type="linear"
            dataKey="commission"
            stroke="#0CACCC"
            dot={dotStyles}
            {...lineStyles}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

CommissionChart.propTypes = propTypes

export default CommissionChart

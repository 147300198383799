import { map, pluck, mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { walletTransferEvents } from '.'
import { walletApiConstants, walletApiEvents, walletApiTransformers } from '../../../api/wallet'
import { of, zip } from 'rxjs'
import { pathOr } from 'ramda'
import { partyApiEvents } from '../../../api/party'
// import { walletApiConstants, walletApiTransformers } from '../../../api/wallet'

/**
 * - Lookup references
 * - Fetch balances
 * - Create wallet if to wallet doesn't exist
 * - return events which updates state and re-renders component for transfer confirmation
 *   - lookups
 *   - balances
 */
export const lookupReferencesAndMaybeCreateWallet = (action$: any, state$: any, { get, post, catchRestError }: any) =>
  action$.pipe(
    ofType(walletTransferEvents.walletLookup_request),
    // wallet reference lookups
    mergeMap(action => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const { from, to } = action.payload
      const lookup = (reference: any) =>
        get(walletApiConstants.ENDPOINTS.REFERENCE_LOOKUP, state$, { reference }).pipe(
          pluck('response'),
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          map(res => walletApiEvents.referenceLookup_success(res.result)),
          catchRestError(action),
        )
      return zip(lookup(from), lookup(to))
    }),
    // fetch balances
    mergeMap(([fromWallet, toWallet]) => {
      const fetchBalance = (action: any) => {
        return get(walletApiConstants.ENDPOINTS.BALANCE, state$, { partyId: action.payload.walletRef }).pipe(
          pluck('response'),
          map(res =>
            walletApiEvents.balance_success(walletApiTransformers.transformBalance(res), {
              partyId: action.payload.walletRef,
            }),
          ),
          catchRestError(action),
        )
      }
      return zip(of(fromWallet), of(toWallet), fetchBalance(fromWallet), fetchBalance(toWallet))
    }),
    // Create wallet if it doesn't exist
    mergeMap(res => {
      // @ts-expect-error ts-migrate(2488) FIXME: Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
      const [, toWallet, , toBalance] = res
      const walletExists = pathOr(false, ['payload', 'globalBalance', 'exists'], toBalance)
      if (walletExists) {
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        return zip(...res.map((r: any) => of(r)))
      } else {
        const createWallet$ = post(walletApiConstants.ENDPOINTS.CREATE_WALLET, state$, {
          account: toWallet.payload.accountId,
          agency: toWallet.payload.agencyId,
          party: toWallet.payload.partyId,
        }).pipe(
          pluck('response'),
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          map(res => walletApiEvents.createWallet_success(res, { walletRef: toWallet.walletRef })),
          catchRestError(toWallet),
        )
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        return zip(createWallet$, ...res.map((r: any) => of(r)))
      }
    }),
    mergeMap((res: any) => res),
    tag('wallet/epics/lookupReferencesAndMaybeCreateWallet'),
  )

import React, { useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ListingTemplate } from '../../../../views/components'
import { ContactsProvider, ContactsTable, ContactsSearchFilter } from '../..'
import { portfolioApiEvents } from 'modules/api/portfolio'

function Contacts({ getContacts, searchQuery, isLoading }: any) {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    getContacts(searchQuery)
    dispatch(portfolioApiEvents.portfolioSummaries_request())
  })

  return <ListingTemplate loading={isLoading} Header={<ContactsSearchFilter />} Body={<ContactsTable />} />
}

export default subscribe([ContactsProvider], ({ getContacts, searchQuery, isLoading }: any) => ({
  getContacts,
  searchQuery,
  isLoading,
}))(Contacts)

import { connect } from 'react-redux'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ['Terminate Lease'],

  actions: [],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

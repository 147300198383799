import React from 'react'
import cx from 'classnames'
import styles from './Card.module.scss'

interface CardProps {
  interactive?: boolean
  on?: boolean
  style?: object
  children: React.ReactChild
}

const Card = ({ interactive, on, style, children }: CardProps): React.ReactElement => {
  const classes = cx([styles.card], {
    [styles.interactive]: interactive,
    [styles.on]: on,
  })

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

export default Card

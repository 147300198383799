import React, { useState } from 'react'
import { Formik } from 'formik'
import { partyApiConstants, partyApiSelectors } from '../../../../../modules/api/party'
import { FormLoader, Heading } from '../../..'
import BankDetails from '../../FormGroups/BankDetails'
import NaturalPersonDetails from '../../FormGroups/NaturalPersonDetails'
import styles from './PartyFormHelper.module.scss'
import { isEmpty, mergeRight } from 'ramda'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { useSelector } from 'react-redux'
import { $TSFixMe } from 'types/ts-migrate'

type TProps = {
  validationSchema: $TSFixMe
  initialValues: $TSFixMe
  isSubmitting: boolean
  isLoading?: boolean
  allowSearchable: boolean
  isDisabled: boolean
  stateIdentificationType: string
  onSubmit: (values: $TSFixMe, resetForm: $TSFixMe) => void
  getGeneralErrors: (values: $TSFixMe) => $TSFixMe
  getFieldErrors: (values: $TSFixMe) => $TSFixMe
  getButtonText: (values: $TSFixMe) => string
  handleStateIdentificationType: (type: string) => void
}

const ButtonContainer = ({ children }: $TSFixMe): React.ReactElement => <div className={styles.footer}>{children}</div>

const PartyNaturalPersonForm = ({
  validationSchema,
  onSubmit,
  initialValues,
  isSubmitting,
  getGeneralErrors,
  getFieldErrors,
  allowSearchable,
  getButtonText,
  isDisabled,
  handleStateIdentificationType,
  stateIdentificationType,
}: TProps): React.ReactElement => {
  const [selectedPartyId, setSelectedPartyId] = useState(null)

  const getPartyValues = useSelector(partyApiSelectors.getPartyDetailsById)

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialTouched={initialValues}
      onSubmit={(values: any, { resetForm }) => {
        onSubmit({ ...values, partyType: partyApiConstants.PARTY_TYPES.PERSON }, resetForm)
        setSelectedPartyId(null)
        resetForm()
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        resetForm,
        submitCount,
        setValues,
      }) => {
        const generalErrors = getGeneralErrors(values)
        const fieldErrors = getFieldErrors(values)
        const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

        const formLoaderState = isSubmitting
          ? 'submitting'
          : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
          ? 'error'
          : undefined

        const handlePartySelect = (id: any): void => {
          const partyValues = getPartyValues(id)
          resetForm()
          const passportNumberRadio = document.getElementById('passportNumber') as HTMLInputElement
          if (partyValues.passport && (partyValues.passport !== undefined || partyValues.passport !== '')) {
            handleStateIdentificationType('passportNumber')
            passportNumberRadio.click()
          }
          setValues(partyValues)
          setSelectedPartyId(id)
        }

        return (
          <FormLoader
            onSubmit={handleSubmit}
            state={formLoaderState}
            buttonProps={{ children: typeof getButtonText === 'function' ? getButtonText(values) : 'Save' }}
            ButtonContainer={ButtonContainer}
            persistErrorMessage={!hasApiErrors && !isEmpty(errors)}
            errorText={hasApiErrors ? 'Oops, something went wrong' : 'Resolve errors'}
            isDisabled={isDisabled}
          >
            <div className={styles.body}>
              <FormErrors errors={generalErrors} />
              <section>
                <NaturalPersonDetails
                  personsOnly
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSelect={({ id }: any) => handlePartySelect(id)}
                  setFieldValue={setFieldValue}
                  errors={mergeRight(errors, fieldErrors)}
                  touched={touched}
                  allowPartySearch={allowSearchable}
                  submitCount={submitCount}
                  disableFields={selectedPartyId}
                  handleStateIdentificationType={handleStateIdentificationType}
                  stateIdentificationType={stateIdentificationType}
                />
              </section>

              <section>
                <Heading as="h2" size="a3" spacing="large" className={styles.heading}>
                  Linked Bank Account
                </Heading>
                <BankDetails
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  accountDescription
                  disableFields={selectedPartyId}
                />
              </section>
            </div>
          </FormLoader>
        )
      }}
    </Formik>
  )
}

export default PartyNaturalPersonForm

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '../../../../views/components/atoms/Button/Button'
import styles from './DepositAccount.module.scss'
import { currencyUtils } from 'utils/index'
import { toCents } from 'utils/currency'

const propTypes = {
  className: PropTypes.string,
  balance: PropTypes.string.isRequired,
  viewStatement: PropTypes.func.isRequired,
}

const DepositAccount = ({ className, balance, viewStatement, disabled }: any) => {
  const classes = cx([styles.root, className])

  return (
    <section className={classes}>
      <header>
        <div className={styles.amounts}>
          <h4 className={styles.title}>Deposit Account</h4>
          <p>
            <strong>{currencyUtils.formatCurrency(toCents(balance).getAmount())}</strong>
          </p>
        </div>
        {/* <div className={styles.actions}>
          <Button disabled={disabled || !balance} onClick={viewStatement}>
            View Statement
          </Button>
        </div> */}
      </header>
    </section>
  )
}

DepositAccount.propTypes = propTypes

export default DepositAccount

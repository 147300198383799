import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTransition, animated, config } from 'react-spring'
import { BackStep } from '../../../../components'
import styles from './SecondaryNav.module.scss'

const propTypes = {
  redirect: PropTypes.func,
  heading: PropTypes.string,
  location: PropTypes.object,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      route: PropTypes.string,
    }),
  ),
}
function SecondaryNav({ redirect, heading, location, navItems }: any) {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(true)
  }, [])

  const items = navItems.map((item: any) => ({
    text: item.text,
    active: item.route.includes(location.pathname),
    onClick: () => redirect(item.route),
  }))

  const transitions = useTransition(show, null, {
    config: config.stiff,
    from: { transform: 'translateX(-230px)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(-230px)' },
  })

  return (
    <div className={styles.root}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <BackStep onClick={() => redirect('/dashboard')} />
              <h2 className={styles.heading}>{heading}</h2>
              <nav>
                <ul>
                  {items.map((item: any, i: any) => {
                    const classes = cx(styles.item, {
                      [styles.active]: item.active,
                    })

                    return (
                      <li key={i}>
                        <button className={classes} onClick={item.onClick}>
                          {item.text}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </animated.div>
          ),
      )}
    </div>
  )
}

SecondaryNav.propTypes = propTypes

export default SecondaryNav

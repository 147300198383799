import React from 'react'
import styles from './CurrencySymbol.module.scss'

interface CurrencySymbolProps {
  children: string
}

const CurrencySymbol = ({ children }: CurrencySymbolProps) => <span className={styles.root}>{children}</span>

export default CurrencySymbol

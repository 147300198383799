import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { path } from 'ramda'
import { format } from 'date-fns'
import { components } from 'react-select'
import { Select } from '../../../../../../views/components'
import styles from './PortfolioSelector.module.scss'
import { dateUtils } from '../../../../../../utils'
import PortfolioStatusLabel from '../../../PortfolioStatusLabel'

const propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  leases: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      term: PropTypes.arrayOf(PropTypes.string),
      updatedAt: PropTypes.number,
    }),
  ),
}

const defaultProps = {
  name: 'account-selector',
}

const ValueContainer = ({ children, ...props }: any) => {
  return (
    <components.ValueContainer {...props}>
      <div className={styles['value-container']}>
        {children}
        <PortfolioStatusLabel portfolioId={path(['selectProps', 'value', 'id'], props)} />
      </div>
    </components.ValueContainer>
  )
}

const Option = (props: any) => {
  const { data, getStyles, innerProps, isDisabled, isSelected, isFocused } = props
  const classes = cx(styles.option, {
    [styles['option--is-selected']]: isSelected,
    [styles['option--is-focused']]: isFocused,
  })

  const startDate = path(['term', 0], data)
  const endDate = path(['term', 1], data)
  // @ts-expect-error
  const startDateFormatted = startDate && format(new Date(startDate), 'dd MMM yyyy')
  // @ts-expect-error
  const endDateFormatted = endDate && format(new Date(endDate), 'dd MMM yyyy')

  const term =
    startDate && endDate
      ? `${startDateFormatted} -> ${endDateFormatted}`
      : `Edited ${dateUtils.timeAgo(new Date(data.updatedAt))}`

  return !isDisabled ? (
    <div {...innerProps} style={getStyles('option', props)} className={classes}>
      <div>
        <strong>{data.label}</strong>
        <span>{term}</span>
      </div>
      <PortfolioStatusLabel portfolioId={data.id} />
    </div>
  ) : null
}

const PortfolioSelector = ({ name, onChange, leases, ...props }: any) => {
  return (
    <section className={styles.root}>
      <Select {...props} name={name} options={leases} components={{ Option, ValueContainer }} onChange={onChange} />
    </section>
  )
}

PortfolioSelector.propTypes = propTypes
PortfolioSelector.defaultProps = defaultProps

export default PortfolioSelector

import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../../../../views/components'
import RemoveIcon from '../../../../../views/components/atoms/Icons/Controls/Close'
import styles from './PortfolioSettingsForm.module.scss'

const PortfolioSettingsSegmentsTable = ({ data, onRemove }: any) => {
  return (
    <>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell head>Segment name</Table.Cell>
            <Table.Cell head>Users</Table.Cell>
            <Table.Cell head>&nbps;</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map(({ id, name, users }: any) => (
            <Table.Row key={id}>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{users}</Table.Cell>
              <Table.Cell className={styles['action-cell']}>
                <button className={styles['remove-button']} type="button" onClick={() => onRemove(id)}>
                  <RemoveIcon />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

PortfolioSettingsSegmentsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      users: PropTypes.string /** @todo update for multiple users */,
    }),
  ),
  onRemove: PropTypes.func.isRequired,
}

export default PortfolioSettingsSegmentsTable

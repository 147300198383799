import React from 'react'

const Accounting = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      opacity={0.56}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M25.75 25.75a1.5 1.5 0 0 1-1.5 1.5H7.75a1.5 1.5 0 0 1-1.5-1.5V6.25a1.5 1.5 0 0 1 1.5-1.5h10.629a1.5 1.5 0 0 1 1.06.439l5.872 5.872a1.5 1.5 0 0 1 .439 1.06V25.75z" />
      <path d="M25.75 12.25h-6a1.5 1.5 0 0 1-1.5-1.5v-6M10.2 15.594c.44.577 1.133.904 1.858.875 1.139 0 2.063-.693 2.063-1.547s-.921-1.546-2.059-1.546c-1.138 0-2.062-.693-2.062-1.548 0-.855.924-1.547 2.062-1.547a2.221 2.221 0 0 1 1.858.875m-1.86 5.314v1.03m0-8.25v1.03M16 19h6m-11.25 4.5H22" />
    </g>
  </svg>
)

export default Accounting

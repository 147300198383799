import { createSlice } from '@reduxjs/toolkit'
import * as selectors from './selectors'
import * as epics from './epics'

const initialState = {
  isOpen: false,
}

const uiSupportStaff = createSlice({
  name: 'uiSupportStaff',
  initialState,
  reducers: {
    open: state => {
      state.isOpen = true
    },
    close: state => {
      state.isOpen = false
    },
  },
})

const { reducer, actions: events } = uiSupportStaff

export { epics as supportStaffEpics, events, reducer as supportStaffReducer, selectors }

import { connect } from 'react-redux'
import { ChatBot } from '../../components'
import { chatbotEvents } from '../../../modules/ui/ChatBot'

const mapStateToProps = (state, { push }: any) => ({
  actions: [
    {
      text: 'Got it!',
      action: () => chatbotEvents.portfolioRedirect('/leases/:id/edit/invoices'),
    },
  ],

  messages: [
    'These are your monthly Recurring Invoices.',
    'You can program how they go out and who to pay when the money comes in. Below is an example',
    'Click "Got it" to continue to setup your recurring invoices.',
  ],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

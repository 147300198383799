import React from 'react'

const Contacts = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g id="Icons-/-User-/-Contacts" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Group-Line" transform="translate(4.000000, 4.000000)">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M2,22.0000012 L2,22.0000012 C2,17.5817212 5.58172,14.0000012 10,14.0000012 C14.41828,14.0000012 18,17.5817212 18,22.0000012 C18,22.0000012 18,22.0000012 18,22.0000012 L16,22.0000012 L16,22.0000012 C16,18.6862912 13.31371,16.0000012 10,16.0000012 C6.68629,16.0000012 4,18.6862912 4,22.0000012 L2,22.0000012 L2,22.0000012 Z M10,13 C6.685,13 4,10.315 4,7 C4,3.685 6.685,1 10,1 C13.315,1 16,3.685 16,7 C16,10.315 13.315,13 10,13 Z M10,11 C12.21,11 14,9.21 14,7 C14,4.79 12.21,3 10,3 C7.79,3 6,4.79 6,7 C6,9.21 7.79,11 10,11 Z M18.284,14.703 L18.2839996,14.7029998 C21.1545696,15.9958298 23.0003196,18.8517298 22.9999996,21.9999998 L20.9999996,21.9999998 L20.9999997,21.9988099 C20.9999997,19.6374799 19.6152097,17.4956399 17.4619097,16.5265099 L18.2819097,14.7025099 L18.284,14.703 Z M17.596,3.41297878 L17.5960001,3.41297878 C19.6565501,4.26235105 21.0008301,6.27126005 21.0000005,8.50000005 L21.0000005,8.49978877 C21.0009821,11.3440988 18.83277,13.7197688 16.00021,13.9779788 L16.00021,11.9649788 L16.00021,11.9649788 C17.91368,11.6909258 19.24269,9.91758878 18.96864,8.00411878 C18.804304,6.85669878 18.082553,5.86506878 17.04121,5.35597878 L17.59621,3.41297878 L17.596,3.41297878 Z"
          id="Shape"
          fill="white"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default Contacts

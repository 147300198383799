import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import { Divider, FormField, FormLoader, Heading, InfoBox, TextField, TextInput } from '../../../../components'
import BankDetails from '../../FormGroups/BankDetails'
import styles from './OnboardingAgencyDetailsForm.module.scss'
import { isEmpty, pathOr } from 'ramda'
import { useSelector } from 'react-redux'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { userApiEvents } from '../../../../../modules/api/user'
import { agencyApiEvents } from '../../../../../modules/api/agency'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { vatNumberValidation } from 'utils/yup'
import PhoneField from 'views/components/atoms/PhoneField/PhoneField'
import { ParsedCountry } from 'react-international-phone'

const propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  isValidSupportStaff: PropTypes.bool,
  currentAgencyId: PropTypes.string,
}

const defaultProps = {}

const validationSchema = yup.object().shape({
  agencyDetails: yup.object().shape({
    companyName: yup.string().required('required.'),
    registrationNumber: yup
      .string()
      .trim()
      .nullable()
      .matches(/([0-9]{4}\/[0-9]{6}\/[0-9]{2})/, {
        message: 'Invalid format. Must be in the format: ****/******/**',
        excludeEmptyString: true,
      }),
    vatNumber: vatNumberValidation('agencyDetails.vatNumber'),
  }),
  agencyAddress: yup.object().shape({
    addressLine1: yup.string().required('required.'),
    addressLine2: yup.string().required('required.'),
    city: yup.string().required('required.'),
    province: yup.string().required('required.'),
    postalCode: yup.string().required('required.'),
    country: yup.string().required('required.'),
  }),
  agencyBankDetails: yup.object().shape({
    bank: yup.string().required('required.'),
    accountNumber: yup
      .string()
      .required('required.')
      .matches(/^[0-9]*$/, {
        message: 'must be a number.',
        excludeEmptyString: true,
      }),
    accountType: yup.string().required('required.'),
  }),
  agencyContactDetails: yup.object().shape({
    emailAddress: yup.string().required('required.').email('Email is invalid.'),
  }),
})

const OnboardingAgencyDetailsForm = ({
  handleSubmit,
  initialValues,
  isSubmitting,
  isLoading,
  currentAgencyId,
  isValidSupportStaff,
}: any): React.ReactElement => {
  const [dialCode, setDialCode] = React.useState('+27')

  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  const isFormDisabled = currentAgencyId && !isValidSupportStaff

  return (
    <>
      {isFormDisabled && <InfoBox>Please contact support to update business details</InfoBox>}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, errors, touched, handleChange, handleBlur, setFieldValue, submitCount }) => {
          const submitEvent = !currentAgencyId
            ? userApiEvents.createAgency_request(values)
            : agencyApiEvents.updateAgency_request({ id: currentAgencyId, values })
          const generalErrors = getGeneralFormErrorsByEvent(submitEvent)
          const fieldErrors = getFormFieldErrorsByEvent(submitEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isLoading
            ? 'loading'
            : isSubmitting
            ? 'submitting'
            : !isEmpty(errors) || hasApiErrors
            ? 'error'
            : undefined

          const getFieldError = (path: any): string => pathOr('', path, touched) && pathOr('', path, errors)

          const telephoneNumber = values.agencyContactDetails.telephoneNumber
          const telephoneNumberReplaced = telephoneNumber.replace(/\s/g, '')

          return (
            <FormLoader onSubmit={handleSubmit} state={formLoaderState} buttonProps={{ children: 'Update' }}>
              <Helmet>
                <title>reOS | Settings | Business Details</title>
              </Helmet>
              <div>
                <FormErrors errors={generalErrors} />
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyDetails.companyName"
                            disabled={isFormDisabled}
                            placeholder="e.g. Gotham Rentals (Pty) Ltd."
                            value={values.agencyDetails.companyName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Entity Name"
                        error={getFieldError(['agencyDetails', 'companyName'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyDetails.trading"
                            disabled={isFormDisabled}
                            value={values.agencyDetails.trading}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Trading Name"
                        error={getFieldError(['agencyDetails', 'trading'])}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyDetails.registrationNumber"
                            disabled={isFormDisabled}
                            placeholder="e.g. 2018/123456/07"
                            value={values.agencyDetails.registrationNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Entity Registration Number"
                        error={getFieldError(['agencyDetails', 'registrationNumber'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyDetails.vatNumber"
                            disabled={isFormDisabled}
                            value={values.agencyDetails.vatNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Entity VAT Number"
                        error={getFieldError(['agencyDetails', 'vatNumber'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyDetails.ffcNumber"
                            disabled={isFormDisabled}
                            value={values.agencyDetails.ffcNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="FFC Number"
                        error={getFieldError(['agencyDetails', 'ffcNumber'])}
                      />
                    </FormField>
                  </div>
                </div>

                <Divider>
                  <Heading as="h3">Business Address</Heading>
                  <p>This address will appear on your invoices.</p>
                </Divider>

                <div className="rbn--row">
                  <div className="rbn--col-md-6">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.addressLine1"
                            disabled={isFormDisabled}
                            placeholder="Address line 1"
                            value={values.agencyAddress.addressLine1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Address"
                        error={getFieldError(['agencyAddress', 'addressLine1'])}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-6">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.addressLine2"
                            disabled={isFormDisabled}
                            placeholder="Address line 2"
                            value={values.agencyAddress.addressLine2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        error={getFieldError(['agencyAddress', 'addressLine2'])}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-3">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.city"
                            disabled={isFormDisabled}
                            value={values.agencyAddress.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="City / Municipality"
                        error={getFieldError(['agencyAddress', 'city'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-2">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.province"
                            disabled={isFormDisabled}
                            value={values.agencyAddress.province}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Province"
                        error={getFieldError(['agencyAddress', 'province'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-2">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.postalCode"
                            disabled={isFormDisabled}
                            value={values.agencyAddress.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Postal Code"
                        error={getFieldError(['agencyAddress', 'postalCode'])}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="agencyAddress.country"
                            disabled={isFormDisabled}
                            value={values.agencyAddress.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Country"
                        error={getFieldError(['agencyAddress', 'country'])}
                      />
                    </FormField>
                  </div>
                </div>

                <Divider>
                  <Heading as="h3">Bank Account</Heading>
                </Divider>

                <BankDetails
                  values={values.agencyBankDetails}
                  valueRoot="agencyBankDetails"
                  disableFields={isFormDisabled}
                  info="This is the account where I should make payments out to you."
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  submitCount={submitCount}
                />
                <Divider>
                  <Heading as="h3">Contact Details</Heading>
                </Divider>

                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.email}
                            name="agencyContactDetails.emailAddress"
                            disabled={isFormDisabled}
                            value={values.agencyContactDetails.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Business Email Address"
                        error={getFieldError(['agencyContactDetails', 'emailAddress'])}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <TextField
                        inputComponent={
                          <PhoneField
                            defaultCountry="za"
                            value={
                              telephoneNumber.includes(dialCode)
                                ? telephoneNumber
                                : [dialCode, telephoneNumberReplaced].join(' ')
                            }
                            onChange={(phone: string, meta: { country: ParsedCountry; inputValue: string }) => {
                              setFieldValue('agencyContactDetails.telephoneNumber', meta.inputValue)
                              setDialCode(meta.country.dialCode)
                            }}
                            disabled={isFormDisabled}
                          />
                        }
                        label="Business Contact Number"
                        error={getFieldError(['agencyContactDetails', 'telephoneNumber'])}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
              <Divider />
            </FormLoader>
          )
        }}
      </Formik>
    </>
  )
}

OnboardingAgencyDetailsForm.propTypes = propTypes
OnboardingAgencyDetailsForm.defaultProps = defaultProps

export default OnboardingAgencyDetailsForm

import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { customPropTypes } from '../../../../../utils'
import { Segment, CurrencyField, FundDistribution } from '../../../..'
import TaskList from '../../../../atoms/Svgs/TaskList'
import styles from './PaymentAllocation.module.scss'
import { subscribe } from 'react-contextual'
import { InvoicesProvider } from '../../../../../providers'
import { useSelector } from 'react-redux'
import { agencyApiSelectors } from 'modules/api/agency'

const propTypes = {
  errors: PropTypes.any,
  invoiceType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  invoiceStatus: PropTypes.string,
  grossAmount: PropTypes.number,
  balance: PropTypes.number,
  availableFunds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onNewBeneficiaryAdded: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onBeneficiaryReferenceChange: PropTypes.func,
  onDepositBeneficiaryTransferChange: PropTypes.func.isRequired,
  onBeneficiariesOrderChange: PropTypes.func,
  onBeneficiaryRemoved: PropTypes.func,
  onVatChange: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiary: PropTypes.shape({
        type: PropTypes.string,
        value: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: PropTypes.Requireable<string>; party... Remove this comment to see the full error message
          name: PropTypes.string,
          partyId: customPropTypes.uuid,
          partyTag: PropTypes.string,
          reference: PropTypes.string,
          amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          vat: PropTypes.bool,
        },
      }),
    }),
  ),
  /** List of party tags for selection */
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  propertyOwnerId: PropTypes.string,
  propertyOwnerName: PropTypes.string,
  propertyOwnerBankDetails: PropTypes.object,
  Footer: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  inputTooltipText: PropTypes.string,
  portfolioId: PropTypes.string,
}

const defaultProps = {
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  beneficiaries: [],
}

const BeforeContent = ({ availableFunds }: any): React.ReactElement => (
  <Segment horizontal>
    <Segment.Label icon={<TaskList />} text="Available Funds" />
    <div className={styles.amount}>
      <CurrencyField
        disabled
        name="availableFunds"
        // onChange={onAmountChange}
        value={availableFunds}
      />
    </div>
  </Segment>
)

const PaymentAllocation = (props: any): React.ReactElement => {
  const {
    errors,
    invoiceStatus,
    availableFunds,
    balance,
    onNewBeneficiaryAdded,
    onNewBeneficiaryAmountChange,
    onBeneficiaryReferenceChange,
    onDepositBeneficiaryTransferChange,
    onBeneficiariesOrderChange,
    onBeneficiaryRemoved,
    onVatChange,
    beneficiaries,
    invoiceType,
    partyTags,
    easyPayData,
    fetchEasyPayData,
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
    Footer,
    inputTooltipText,
    portfolioId,
    ...rest
  } = props

  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)

  const classes = cx('root', {
    'status-ready': invoiceStatus === 'Ready',
  })

  return (
    <div className={classes}>
      <FundDistribution
        invoiceType={invoiceType}
        errors={errors}
        state={invoiceStatus === 'Short' || invoiceStatus === 'NotSet' ? 'warning' : 'default'}
        beforeContent={<BeforeContent availableFunds={availableFunds} />}
        headerText="Payment allocation"
        invoiceStatus={invoiceStatus}
        balance={balance}
        includeVatCalculatorOnPaymentRules={false}
        onNewBeneficiaryAdded={onNewBeneficiaryAdded}
        onNewBeneficiaryAmountChange={onNewBeneficiaryAmountChange}
        onBeneficiaryReferenceChange={onBeneficiaryReferenceChange}
        onDepositBeneficiaryTransferChange={onDepositBeneficiaryTransferChange}
        onBeneficiariesOrderChange={onBeneficiariesOrderChange}
        beneficiaries={beneficiaries}
        onVatChange={onVatChange}
        partySearchTags=""
        partyTags={partyTags}
        disableDepositEdit={false}
        onBeneficiaryRemoved={onBeneficiaryRemoved}
        easyPayData={easyPayData}
        fetchEasyPayData={fetchEasyPayData}
        agencyName={agencyName}
        agencyBankDetails={agencyBankDetails}
        propertyOwnerId={propertyOwnerId}
        propertyOwnerName={propertyOwnerName}
        propertyOwnerBankDetails={propertyOwnerBankDetails}
        ownerBankDetails={agencyBankDetails}
        Footer={Footer}
        inputTooltipText={inputTooltipText}
        portfolioId={portfolioId}
        {...rest}
      />
    </div>
  )
}

PaymentAllocation.propTypes = propTypes
PaymentAllocation.defaultProps = defaultProps

export default subscribe(
  [InvoicesProvider],
  ({ fetchEasyPayData, easyPayData, propertyOwnerId, propertyOwnerName, propertyOwnerBankDetails }: any) => ({
    fetchEasyPayData,
    easyPayData,
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
  }),
)(PaymentAllocation)

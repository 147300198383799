import React from 'react'
import ViewAllocatedReference from './ViewAllocatedReference'
import ViewUnallocatedReference from './ViewUnallocatedReference'
import BadReferencesProvider from '../../BadReferencesProvider'
import { subscribe } from 'react-contextual'
import { useSelector } from 'react-redux'
import { walletApiSelectors } from '../../../api/wallet'
import styles from './ViewBadReferences.module.scss'

const ViewBadReference = ({ currentReference, openReference, closeReference }: any) => {
  const referenceType = useSelector(state => walletApiSelectors.getReferenceType(state)(currentReference?.txId))
  return (
    <>
      <header className={styles.header}>
        <button className={styles.close} onClick={closeReference}>
          &nbsp;
        </button>
      </header>
      <div className={styles.card}>
        {referenceType === 'allocated' ? (
          <ViewAllocatedReference currentReference={currentReference} />
        ) : (
          <ViewUnallocatedReference currentReference={currentReference} />
        )}
      </div>
    </>
  )
}

ViewBadReference.propTypes = {}

export default subscribe([BadReferencesProvider], ({ currentReference, openReference, closeReference }: any) => ({
  currentReference,
  openReference,
  closeReference,
}))(ViewBadReference)

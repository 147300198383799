import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { agencyApiSelectors } from '../../../api/agency'
import { state, getAgencyDetailsDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'

/**
 * @todo update this, not really a "dialog" anymore
 */
export const { agencyDetailsDialog } = createChatbotDialog('agencyDetails')
  .withInitialState({
    agencyDetails: {
      companyName: '',
      trading: '',
      registrationNumber: '',
      vatNumber: '',
      ffcNumber: '',
    },
    agencyAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
    },
    agencyBankDetails: {
      bank: '',
      accountNumber: '',
      accountType: '',
      branchCode: '',
    },
    agencyContactDetails: {
      emailAddress: '',
      telephoneNumber: '',
    },
  })
  .withEvents({
    detailsSubmitted: (payload: any) => payload,
  })
  .when('detailsSubmitted', ({ payload }: any) => ({
    $merge: {
      ...payload,
    },
  }))
  .setup()

export const getAgencyDetailsInitialValues = createSelector(state, getAgencyDetailsDialog, s => {
  const agency = agencyApiSelectors.getCurrentAgency(s)
  return get(agency, 'agency', {
    agencyDetails: {
      companyName: '',
      trading: '',
      registrationNumber: '',
      vatNumber: '',
      ffcNumber: '',
    },
    agencyAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
    },
    agencyBankDetails: {
      bank: '',
      accountNumber: '',
      accountType: '',
      branchCode: '',
    },
    agencyContactDetails: {
      emailAddress: '',
      telephoneNumber: '',
    },
  })
})

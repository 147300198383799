import { connect } from 'react-redux'

import { chatbotSelectors, chatbotEvents } from '../../../modules/ui/ChatBot'
import { PortfolioExitGateForm } from '../../components'

const mapStateToProps = (state: any) => ({
  unfinishedSteps: chatbotSelectors
    .getPortfolioNavbarItems(state)
    .slice(0, -1)
    .filter(step => !step.hasCheckmark),
})

const mapDispatchToProps = (dispatch: any) => ({
  redirectTo: (route: any) => dispatch(chatbotEvents.portfolioRedirect(route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioExitGateForm)

import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { FormField, FormLoader, Modal, TextField, TextInput } from 'views/components'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { integrationsApiEvents } from 'modules/api/integrations'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import styles from './TpnLoginModal.module.scss'

type Props = {
  isOpen: boolean
  onClose(): void
}

const validationSchema = yup.object().shape({
  loginCode: yup.string().required('Login Code is required'),
  password: yup.string().required('Password is required'),
})

const ButtonContainer = ({ children }: any) => <div className="modal-action">{children}</div>

const TpnLoginModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  return (
    <Modal
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      isOpen={isOpen}
      onClose={onClose}
      width="md"
      padding="sm"
    >
      <h3 className={styles['modal-header']}>TPN Login</h3>
      <div className="modal-content">
        <Formik
          initialValues={{ loginCode: '', password: '', securityCode: 'reos499334801' }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={values => {
            dispatch(integrationsApiEvents.tpnAuthorize_request(values))
            onClose()
          }}
        >
          {({ handleSubmit, handleChange, isSubmitting, values, errors }) => {
            const formLoaderState = isSubmitting ? 'submitting' : !isEmpty(errors) ? 'error' : undefined

            return (
              <FormLoader
                onSubmit={handleSubmit}
                state={formLoaderState}
                buttonProps={{ children: 'Enable' }}
                ButtonContainer={ButtonContainer}
              >
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        id="loginCode"
                        type={TextFieldTypes.text}
                        name="loginCode"
                        value={values.loginCode}
                        placeholder="Login Code"
                        onChange={handleChange}
                      />
                    }
                    error={errors?.loginCode}
                  />
                </FormField>
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        id="password"
                        type={TextFieldTypes.password}
                        name="password"
                        value={values.password}
                        placeholder="Password"
                        onChange={handleChange}
                      />
                    }
                    error={errors?.password}
                  />
                </FormField>
              </FormLoader>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default TpnLoginModal

import React, { Component } from 'react'
import Select from '../../atoms/Select/Select'
import { find, pipe, prop } from 'ramda'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import styles from './AutoDeliverControl.module.scss'

type Props = {
  isOpen: boolean
  active: boolean
  onToggleChange?: (active: boolean) => void
  onChange: (data: any) => void
  onClose: () => void
  value: string
  place?: 'above' | 'right' | 'below' | 'left' | 'row' | 'column' | 'start' | 'end' | undefined
  children?: React.ReactChild
}

const AutoDeliverControl = ({
  isOpen,
  active,
  onToggleChange = () => {},
  onChange = (data: any) => data,
  onClose = () => {},
  value,
  place = 'above',
  children,
}: Props) => {
  const options = [
    {
      label: 'Beginning of each month',
      value: 'BeginningOfMonth',
    },
    {
      label: 'Middle of each month',
      value: 'MiddleOfMonth',
    },
    {
      label: 'End of each month',
      value: 'EndOfMonth',
    },
  ]

  const handleToggleChange = () => {
    onToggleChange(active)
  }

  const renderBody = () => (
    <div className={styles.body}>
      <Select
        name="interval"
        value={options.find((option: any) => option.value === value)}
        label="Due date"
        onChange={onChange}
        options={options}
      />
      <p>Invoices will automatically be generated on the 10th of each month.</p>
    </div>
  )

  return (
    <Tooltip
      isOpen={isOpen}
      contrast="light"
      active={active}
      body={renderBody()}
      // @ts-ignore
      title={'Deliver automatically'}
      onOuterAction={onClose}
      place={place}
    >
      {children}
    </Tooltip>
  )
}

export default AutoDeliverControl

import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import { filterObjectKeys } from '../../../../utils/general'
import { state, getTenantDetailsDialog } from '../selectors'
import { createChatbotDialog } from './ChatbotDialog'
import { partyApiSelectors, partyApiConstants } from 'modules/api/party'
import { chatbotEvents } from '../state'
import { portfolioApiEvents } from 'modules/api/portfolio'

const initialState = {
  selectedParty: null,
}

export const { tenantDetailsDialog } = createChatbotDialog('tenantDetails')
  .withInitialState(initialState)
  .withEvents({
    partySelected: (id: any) => ({
      id,
    }),
    partySaved: (payload: any) => payload,
  })
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: {
        selectedParty: pathOr(null, ['parties', 'tenants', 'primary', 'partyId'], payload),
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateTenantParties_success]: ({ payload }: any) => ({
      $merge: {
        selectedParty: payload.parties.tenants.primary.partyId,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.acceptApplication_success]: ({ payload }: any) => ({
      $merge: {
        selectedParty: payload.parties.tenants.primary.partyId,
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when(['partySelected', 'partySaved'], ({ payload }: any) => ({
    $merge: {
      selectedParty: payload.id,
    },
  }))
  .setup()

export const getSelectedTenant = createSelector(getTenantDetailsDialog, dialog => dialog.selectedParty)

export const getPropertyTenantInitialValues = createSelector(state, s => (partyId: any, userSelectedPartyType: any) => {
  const emptyPersonalDetails = {
    firstName: '',
    lastName: '',
    idNumber: '',
    emailAddress: '',
    cellNumber: '',
    telNumber: '',
  }

  const emptyBankingDetails = {
    bank: '',
    accountNumber: '',
    accountType: '',
    accountName: '',
  }

  const emptyBusinessDetails = {
    companyName: '',
    vatRegistered: '',
    vatNumber: '',
    companyRegistration: '',
    emailAddress: '',
    telNumber: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
  }

  const selectedParty = partyId || false

  const pType = partyApiSelectors.getPartyTypeById(s)(partyId)
  const partyDetails = partyApiSelectors.getPartyDetailsById(s)(partyId)
  const partyType = (() => {
    if (userSelectedPartyType !== null) {
      return userSelectedPartyType
    }
    if (pType === 'company') {
      return partyApiConstants.PARTY_TYPES.COMPANY
    }
    return partyApiConstants.PARTY_TYPES.PERSON
  })()

  switch (partyType) {
    case partyApiConstants.PARTY_TYPES.COMPANY:
      if (pType === 'company') {
        return filterObjectKeys(['createdAt', 'updatedAt', 'id'], { ...partyDetails, ...{ partyType }, selectedParty })
      }
      if (pType === 'person') {
        return {
          ...emptyBusinessDetails,
          ...{
            emailAddress: partyDetails.emailAddress,
            contactPersonFirstName: partyDetails.firstName,
            contactPersonLastName: partyDetails.lastName,
            telNumber: partyDetails.cellNumber,
            bank: partyDetails.bank,
            branchCode: partyDetails.branchCode,
            accountName: partyDetails.accountName,
            accountNumber: partyDetails.accountNumber,
            accountType: partyDetails.accountType,
            convertedFrom: partyApiConstants.PARTY_TYPES.PERSON,
            partyType,
            selectedParty,
          },
        }
      }
      return { ...emptyBusinessDetails, ...emptyBankingDetails, selectedParty }
    case partyApiConstants.PARTY_TYPES.PERSON:
    default:
      if (pType === 'person') {
        return filterObjectKeys(['createdAt', 'updatedAt', 'id'], { ...partyDetails, ...{ partyType }, selectedParty })
      }
      if (pType === 'company') {
        return {
          ...emptyPersonalDetails,
          ...{
            emailAddress: partyDetails.emailAddress,
            firstName: partyDetails.contactPersonFirstName,
            lastName: partyDetails.contactPersonLastName,
            cellNumber: partyDetails.telephone,
            bank: partyDetails.bank,
            branchCode: partyDetails.branchCode,
            accountName: partyDetails.accountName,
            accountNumber: partyDetails.accountNumber,
            accountType: partyDetails.accountType,
            convertedFrom: partyApiConstants.PARTY_TYPES.COMPANY,
            partyType,
            selectedParty,
          },
        }
      }
      return { ...emptyPersonalDetails, ...emptyBankingDetails, ...{ partyType }, selectedParty }
  }
})

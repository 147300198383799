/**
 * @todo replace in favour of @redux/toolkit
 */
import { combineActions, createActions, handleActions } from 'redux-actions'
import { tag } from 'rxjs-spy/operators/tag'
import update from '../../../../utils/immutabilityHelper'
import { decamelize } from 'humps'
import { get, keys, flatMap, mapKeys, transform, values } from 'lodash-es'
import { chatbotEvents } from '../state'
import { ofType } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { $TSFixMe } from 'types/ts-migrate'
import { createAction } from '@reduxjs/toolkit'

export class ChatbotDialog {
  actionHandlers: $TSFixMe
  events: $TSFixMe
  initialState: $TSFixMe
  name: $TSFixMe
  reducer: $TSFixMe
  constructor(dialogName: $TSFixMe) {
    this.name = dialogName
    this.initialState = {}
    this.events = null
    this.actionHandlers = {}
    this.reducer = {}
  }

  withEvents(events: $TSFixMe) {
    const decamelizedEvents = mapKeys(events, (v: $TSFixMe, k: $TSFixMe) => decamelize(k))
    this.events = createActions(decamelizedEvents)
    // this.events = decamelizedEvents.map((event: string) => createAction(event))
    return this
  }

  withInitialState(state: $TSFixMe) {
    this.initialState = state
    return this
  }

  when(_eventNames: $TSFixMe, handler: $TSFixMe) {
    const eventNames = typeof _eventNames === 'string' ? [_eventNames] : _eventNames
    const events = eventNames.map((name: $TSFixMe) => this.events[name])
    this.actionHandlers = {
      ...this.actionHandlers,
      [combineActions.apply(undefined, events)]: (state = this.initialState, event: $TSFixMe) =>
        update(state, handler(event, state)),
    }
    return this
  }

  reduce(handlers: $TSFixMe) {
    this.reducer = {
      ...this.reducer,
      ...transform(handlers, (reducers: $TSFixMe, eventHandler: $TSFixMe, action: $TSFixMe) => {
        reducers[action] = (state = this.initialState, event: $TSFixMe) => update(state, eventHandler(event, state))
      }),
    }
    return this
  }

  setup() {
    return {
      [this.name + 'Dialog']: {
        name: this.name,
        events: this.events,
        model: this.initialState,
        reducesEvents: flatMap(keys(this.reducer), (event: $TSFixMe) => (event + '').split('||')),
        proceed: (withEvent: $TSFixMe) =>
          chatbotEvents.dialogEvent({ dialog: this.name, event: withEvent(this.events) }),
        reducer: (model: $TSFixMe) => {
          const defaultState = model.dialogs[this.name]
          if (defaultState === undefined) {
            throw new Error('Dialog ' + this.name + ' does not have a default state in ChatbotModel')
          }
          const dialogEventReducer = (dialogState = defaultState, event: $TSFixMe) => {
            if (this.name === event.payload.dialog) {
              return handleActions(this.actionHandlers, this.initialState)(dialogState, event.payload.event)
            }
            return dialogState
          }
          return handleActions(
            {
              ...this.reducer,
              // @ts-expect-error
              [chatbotEvents.dialogEvent]: dialogEventReducer,
            },
            defaultState,
          )
        },
      },
    }
  }
}

export const createChatbotDialog = (name: $TSFixMe) => {
  const dialog = new ChatbotDialog(name)
  return dialog
}

export const filterDialogTypes = (dialogs: $TSFixMe) => (source$: $TSFixMe) =>
  source$.pipe(
    filter(event => {
      const dialogNames = values(dialogs).map((e: $TSFixMe) => e.name)
      const payloadIncludesDialog = dialogNames.includes(get(event, 'payload.dialog'))
      return payloadIncludesDialog
    }),
    tag('ChatbotDialog/operator/filterDialogTypes'),
  )

export const ofDialogTypes = (dialogs: $TSFixMe) => (source$: $TSFixMe) =>
  source$.pipe(
    ofType(chatbotEvents.dialogEvent),
    filterDialogTypes(dialogs),
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    map(event => event.payload.event),
    tag('ChatbotDialog/operator/ofDialogTypes'),
  )

export const ofDialogType = (dialog: $TSFixMe) => (source$: $TSFixMe) =>
  source$.pipe(ofDialogTypes([dialog]), tag('ChatbotDialog/operator/ofDialogType'))

import React from 'react'

const SvgChevronLeft = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
        d="M14.828 16l4.95 4.95-1.414 1.414L12 16l6.364-6.364 1.414 1.414z"
      />
    </g>
  </svg>
)

export default SvgChevronLeft

import React from 'react'

const PaymentsAlt = (props: any) => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" width={32} height={32} {...props}>
    <title>{props.title}</title>
    <g id="Icons-/-Descriptive-/-Payments" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Exchange-Box-Line" transform="translate(4.000000, 4.000000)">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M4,5 L4,19 L20,19 L20,5 L4,5 Z M2.99999991,3 L20.9999999,3 L20.9999999,3 C21.552285,3 21.9999999,3.447715 21.9999999,4 L21.9999999,20 L21.9999999,20 C21.9999999,20.552285 21.552285,21 20.9999999,21 L2.99999991,21 L2.99999991,21 C2.44771491,21 1.99999991,20.552285 1.99999991,20 C1.99999991,20 1.99999991,20 1.99999991,20 L1.99999991,4 L1.99999991,4 C1.99999991,3.44771515 2.44771491,3 2.99999991,3 L2.99999991,3 Z M12,9 L12,6 L17,11 L8,11 L8,9 L12,9 Z M7,13 L16,13 L16,15 L12,15 L12,18 L7,13 Z"
          id="Shape"
          fillOpacity="0.64"
          fill="#191923"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default PaymentsAlt

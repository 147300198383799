import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Label from '../../../atoms/Label/Label'
import AddOn from '../../../atoms/Svgs/AddOn'
import Segments from '../../../atoms/Icons/Segments/Segments'
import styles from './FABs.module.scss'

const propTypes = {
  className: PropTypes.string,
  onRobinClick: PropTypes.func,
  onShortcutsClick: PropTypes.func,
  onSegmentsClick: PropTypes.func,
  isShortcutsDrawerOpen: PropTypes.bool,
  isSegmentsDrawerOpen: PropTypes.bool,
  isRobinDrawer: PropTypes.bool,
  notificationCount: PropTypes.number,
  segmentFilterCount: PropTypes.number,
  isReadOnly: PropTypes.bool,
}

const FABs = ({
  className,
  onRobinClick,
  onShortcutsClick,
  onSegmentsClick,
  isShortcutsDrawerOpen,
  isSegmentsDrawerOpen,
  isRobinDrawerOpen,
  notificationCount = 0,
  segmentFilterCount = 0,
  isReadOnly,
}: any) => {
  const classes = cx(styles.root, {
    [styles['shortcuts-drawer-open']]: isShortcutsDrawerOpen,
    [styles['segments-drawer-open']]: isSegmentsDrawerOpen,
    [styles['robin-drawer-open']]: isRobinDrawerOpen,
  })

  return (
    // <div className={classes} className={}>
    <div className={`${classes} ${className}`}>
      <button className={styles.segments} onClick={onSegmentsClick}>
        <Segments stroke="#fff" />
        {segmentFilterCount > 0 && (
          <div className={styles['segment-filters-count']}>
            <Label circular size="sm">
              {segmentFilterCount}
            </Label>
          </div>
        )}
      </button>
      <button className={styles.shortcuts} onClick={onShortcutsClick} disabled={isReadOnly}>
        <AddOn fill="#fff" />
      </button>
      <button className={styles.robin} onClick={onRobinClick}>
        reOS
      </button>
      <div className={styles['notifications-count']}>
        <Label circular size="sm">
          {notificationCount}
        </Label>
      </div>
    </div>
  )
}

FABs.propTypes = propTypes

export default FABs

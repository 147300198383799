import React from 'react'
import { Helmet } from 'react-helmet'
import { TableProvider, LayoutProvider, Layout, LayoutBody, LayoutContent } from '../../../views/components'
import Properties from './Properties/Properties'

const PropertiesPage = () => {
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="properties"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: false, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Properties</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutContent>
            <TableProvider>
              <Properties />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default PropertiesPage

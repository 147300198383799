import { connect } from 'react-redux'
import { ChatBot } from '../../components'
import { getChatbotSelector } from '../../../modules/ui/ChatBot/Dialogs/PropertyDetails'

const mapStateToProps = (state: any) => getChatbotSelector(state)

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => {
    const event = action()
    event && dispatch(event)
  },
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

import React, { useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { get } from 'lodash-es'
import { uiEvents } from '../../ui'
import { layoutEvents, layoutSelectors } from '../../Layout/state'
import { partyApiEvents, partyApiSelectors } from '../../api/party'
import * as selectors from './selectors'
import { contactsEvents } from './'
import { contactsSelectors } from '..'
import history from '../../../state/history'
import { apiStateSelectors } from '../../api/apiState'
import { downloadBase64 } from 'utils/fileUtils'
import { userApiSelectors } from 'modules/api/user'

const ContactsProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = useStore()
  const dispatch = useDispatch()

  const searchQuery = useSelector(selectors.getSearchQuery)
  const { match } = props
  const { params } = match
  const id = get(params, 'id')
  const location = useSelector(state => get(state, 'router.location', ''))

  /** @todo figure out how to curry selectors!?!?!?!?!? */
  const state = store.getState()
  const currentParty = partyApiSelectors.getPartyDetailsById(state)(id)
  const currentPartyType = partyApiSelectors.getPartyTypeById(state)(id)
  const [currentAccountId, setCurrentAccountId] = useState(null)
  const partyHasBankDetails = partyApiSelectors.partyHasBankDetails(state)(currentParty.id)

  const contactsStore = {
    id,
    match,
    searchQuery,
    currentParty,
    currentPartyType,
    partyHasBankDetails,
    currentAccountId,
    setCurrentAccountId,
    currentPartyBalance: 0,
    location,
    isReadOnly: useSelector(userApiSelectors.isReadOnlyRole),
    isLoading: useSelector(state => apiStateSelectors.isLoading(state)([partyApiEvents.quickSearch_request])),
    isContactViewLoading: useSelector(state => apiStateSelectors.isLoading(state)([partyApiEvents.party_request])),
    filters: useSelector(selectors.contactsFilters),
    currentContactInfo: contactsSelectors.getContactInfo(state)(id),
    primaryPanel: layoutSelectors.getPrimaryPanel(state)('contacts'),
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    /** @todo move to general app provider */
    goBack: () => history.goBack(),
    openContact: (id: any) => {
      dispatch(uiEvents.redirect(`/contacts/${id}`))
      dispatch(partyApiEvents.party_request(id))
      dispatch(layoutEvents.openPrimaryPanel('contacts'))
      dispatch(layoutEvents.closeSidebar('global'))
    },
    openContactFromNonContactsRoute: (id: any) => {
      dispatch(uiEvents.redirect(`/contacts/${id}`))
      dispatch(layoutEvents.openPrimaryPanel('contacts'))
      dispatch(layoutEvents.closeSidebar('global'))
    },
    openContactStatement: (accountId: any) => {
      dispatch(uiEvents.redirect(`/contacts/${id}/${accountId}/statement`))
    },
    closeContact: () => {
      dispatch(uiEvents.redirect('/contacts'))
      dispatch(layoutEvents.closePrimaryPanel('contacts'))
      dispatch(layoutEvents.openSidebar('global'))
    },
    downloadStatement: (filename: any, base64: any) => downloadBase64(base64, 'pdf', filename),
    getContacts: (query = '', tags = '') => dispatch(partyApiEvents.quickSearch_request({ query, tags })),
    onQueryChange: (e: any) => dispatch(contactsEvents.searchQueryUpdated(e.target.value)),
    fetchParty: (id: any) => dispatch(partyApiEvents.party_request(id)),
    updateParty: (payload: any) => dispatch(partyApiEvents.updateParty_request(payload)),
    createParty: (payload: any) => dispatch(partyApiEvents.createParty_request(payload)),
    ...props,
  }
  return <context.Provider value={contactsStore}>{children}</context.Provider>
})

export default ContactsProvider

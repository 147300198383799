import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { NAMESPACE } from './constants'

type PanelState = { open: boolean; width?: number }

interface LayoutInstance {
  sidebar: PanelState
  primaryPanel: PanelState
  secondaryPanel: PanelState
}

export interface LayoutState {
  [key: string]: LayoutInstance
}

const defaultState = {
  sidebar: {
    open: true,
    width: 240,
  },
  primaryPanel: {
    open: false,
    width: 600,
  },
  secondaryPanel: {
    open: false,
    width: 375,
  },
}

export const initialState: LayoutState = {
  global: defaultState,
}

type keyPayload = PayloadAction<string>

const open = (state: WritableDraft<LayoutState>, component: keyof LayoutInstance, key: string) => {
  state[key] ??= {} as LayoutInstance
  state[key][component] ??= {} as PanelState
  state[key][component].open = true
}

const close = (state: WritableDraft<LayoutState>, component: keyof LayoutInstance, key: string) => {
  state[key] ??= {} as LayoutInstance
  state[key][component] ??= {} as PanelState
  state[key][component].open = false
}

const layoutSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    init: (state, { payload: { key, initialState } }: { payload: { key: string; initialState: LayoutInstance } }) => {
      state[key] = initialState
    },
    openSidebar: (state, { payload }: keyPayload) => open(state, 'sidebar', payload),
    closeSidebar: (state, { payload }: keyPayload) => close(state, 'sidebar', payload),
    openPrimaryPanel: (state, { payload }: keyPayload) => open(state, 'primaryPanel', payload),
    closePrimaryPanel: (state, { payload }: keyPayload) => close(state, 'primaryPanel', payload),
    openSecondaryPanel: (state, { payload }: keyPayload) => open(state, 'secondaryPanel', payload),
    closeSecondaryPanel: (state, { payload }: keyPayload) => close(state, 'secondaryPanel', payload),
  },
})

const { actions: events, reducer } = layoutSlice

export { events as layoutEvents, reducer as layoutReducer }

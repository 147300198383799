import { connect } from 'react-redux'
import { ChatBot } from '../../components'
// import { chatbotEvents } from '../../../modules/ui/ChatBot'
// import { getUserFirstName } from '../../../modules/api/user/selectors'

const mapStateToProps = (state, { dispatch, push }: any) => ({
  actions: [],

  messages: ["Ok, let's add a logo and select a theme."],
})

const mapDisptchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDisptchToProps)(ChatBot)

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { differenceInMinutes } from 'date-fns'
import { appRefreshApiEvents } from './'
import { NODE_ENV } from '../../constants/general'

export const useAppVersion = () => {
  const dispatch = useDispatch()

  const [lastChecked, setLastChecked] = useState(new Date())
  const [latestVersion, setLatestVersion] = useState('')
  const [fetchingLatestVersion, setFetchingLatestVersion] = useState(false)

  const currentVersion = process.env.REACT_APP_VERSION
  let versionsMatch = true

  if (NODE_ENV !== 'development') {
    if ((differenceInMinutes(new Date(), lastChecked) > 4 || !latestVersion) && !fetchingLatestVersion) {
      setFetchingLatestVersion(true)

      window
        .fetch('/meta.json')
        .then(async response => {
          if (!response.ok) {
            throw new Error('Error retrieving meta.json file')
          } else {
            return await response.json()
          }
        })
        .then(meta => {
          setLastChecked(new Date())
          setLatestVersion(meta.version)
          setFetchingLatestVersion(false)

          versionsMatch = meta.version === currentVersion

          if (!versionsMatch) {
            dispatch(appRefreshApiEvents.versionMismatched())
          }
        })
        .catch(error => {
          console.error('Error retrieving meta.json file: ', error)
        })
    }
  }

  return {
    versionsMatch,
    latestVersion,
    currentVersion,
  }
}

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import styles from './DateRangePicker.module.scss'
import { path } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

type TProps = {
  startLabel?: string
  endLabel?: string
  startDate: string | object
  endDate: string | object
  inline?: boolean
  onChange: (date: $TSFixMe, isStartDate: boolean) => void
  error?: {
    startDate: string | object
    endDate: string | object
  }
}

const DateRangePicker = ({
  startLabel,
  endLabel,
  startDate,
  endDate,
  onChange = (date: $TSFixMe) => {},
  error,
  ...optionsProps
}: TProps) => {
  const classes = classNames(styles.root, {
    [styles.inline]: !!optionsProps.inline,
  })

  const options = {
    ...optionsProps,
    dateFormat: 'Y-m-d',
  }

  const startDateError = path(['startDate'], error)
  const endDateError = path(['endDate'], error)

  return (
    <div className={'rbn--row'}>
      <div className={`rbn--col ${classes}`}>
        {startLabel && startDateError ? (
          <label className={styles.error}>
            {startLabel} - {startDateError}
          </label>
        ) : (
          <label>{startLabel}</label>
        )}
        <Flatpickr
          value={startDate}
          onChange={(date: any) => onChange(date[0], true)}
          options={{
            ...options,
            disable: [(date: any) => date > new Date(endDate as string)],
          }}
        />
      </div>
      <div className={`rbn--col ${classes}`}>
        {endLabel && endDateError ? (
          <label className={styles.error}>
            {endLabel} - {endDateError}
          </label>
        ) : (
          <label>{endLabel}</label>
        )}
        <Flatpickr
          value={endDate}
          onChange={(date: any) => onChange(date[0], false)}
          options={{
            ...options,
            disable: [(date: any) => date < new Date(startDate as string)],
          }}
        />
      </div>
    </div>
  )
}

export default DateRangePicker

import React, { useState, useEffect, useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { format } from 'date-fns'
import { map } from 'lodash-es'
import { AggregateTable, Header } from '../../..'
import { PortfoliosProvider, SearchFilterProvider } from '../../../../providers'
import { stringUtils } from '../../../../../utils'
import PropertyIcon from '../../../../components/atoms/Svgs/Property'
import { $TSFixMe } from 'types/ts-migrate'
import styles from './PortfolioTable.module.scss'

const PortfolioTable = ({ portfolios, openPortfolio, searchQuery }: any): React.ReactElement => {
  const [data, setData] = useState([])
  useEffect(() => {
    setData(portfolios)
  }, [portfolios])

  const filteredPortfolios = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const columns = [
    {
      Header: 'Property',
      accessor: 'streetAddress',
    },
    {
      Header: 'Created',
      accessor: 'created',
    },
    {
      Header: 'Updated',
      accessor: 'updated',
    },
  ]

  const portfolioData = useMemo(() => {
    const buildPortfolioObj = (p: any): $TSFixMe => ({
      id: p.id,
      streetAddress: p.streetAddress,
      created: format(new Date(p.createdAt), 'dd/MM/yyyy'),
      updated: format(new Date(p.updatedAt), 'dd/MM/yyyy'),
    })

    return searchQuery !== '' ? map(filteredPortfolios, buildPortfolioObj) : map(data, buildPortfolioObj)
  }, [data, filteredPortfolios, searchQuery])

  return useMemo(
    () => (
      <div className={styles.root}>
        <Header icon={<PropertyIcon />} text="Properties" />
        <AggregateTable columns={columns} data={portfolioData} onRowClick={data => openPortfolio(data.id)} />
      </div>
    ),
    [columns, openPortfolio, portfolioData],
  )
}

export default subscribe(
  [PortfoliosProvider, SearchFilterProvider],
  ({ portfolios, openPortfolio }: any, { searchQuery }: any) => ({
    portfolios,
    openPortfolio,
    searchQuery,
  }),
)(PortfolioTable)

import React from 'react'
import Modal from '../../../molecules/Modal/Modal'
import Button from '../../../atoms/Button/Button'
import CurrencyText from '../../../atoms/Typography/CurrencyText/CurrencyText'
import DimmerLoader from 'views/components/molecules/DimmerLoader/DimmerLoader'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from 'modules/api/apiState'
import { walletApiEvents } from 'modules/api/wallet'

const WithdrawFundsModal = ({
  isOpen = false,
  balance,
  bankName = '',
  number = '',
  onClose = () => {},
  onCancel = () => {},
  onConfirm = () => {},
}: any) => {
  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.payout_request]))

  return (
    <Modal
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      isOpen={isOpen}
      padding="sm"
      showClose={false}
      onClose={onClose}
      theme="dark"
    >
      <div className="Modal_Header">
        <h3 className="Modal_Title">Confirm</h3>
      </div>
      <div className="Modal_Content">
        <DimmerLoader loading={isLoading} bgColor="dark">
          <p>
            You are about to pay <CurrencyText>{balance}</CurrencyText> to your linked bank account --&gt; <br />
            <strong>
              {bankName} {number}
            </strong>
          </p>
        </DimmerLoader>
      </div>
      <div className="Modal_Actions">
        <Button link onClick={onCancel}>
          Cancel
        </Button>
        <Button link onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default WithdrawFundsModal

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { walletApiEvents, walletApiSelectors } from '../api/wallet'
import { portfolioEvents, portfolioSelectors } from '../portfolios/state'
import { path } from 'ramda'
import { portfolioApiSelectors } from '../api/portfolio'
import { partyApiSelectors } from '../api/party'
import { accountingApiEvents, accountingApiSelectors } from '../api/accounting'
import { RootState } from 'src/state/store'

const propTypes = {
  currentProperty: PropTypes.object,
  selectedPortfolio: PropTypes.number,
}

const DepositProvider = moduleContext()(
  ({ context, children, currentProperty, selectedPortfolio, portfolioViewId, ...props }: any) => {
    const store = useStore()
    const dispatch = useDispatch()
    const state = store.getState()
    const getWalletAsset = (partyId: any, accountId: any) =>
      walletApiSelectors.getBalanceAsset(state)(partyId, accountId, 'Tenant')
    const viewData = useSelector(state => portfolioSelectors.getPortfolioViewData(state)(portfolioViewId))
    const selectedPortfolioId = path(['portfolios', selectedPortfolio, 'id'], viewData)

    const selectedPortfolioTenantId = useMemo<string | null>(() => {
      return portfolioApiSelectors.getTenantByPortfolioId(state)(selectedPortfolioId)
    }, [selectedPortfolioId, state])

    const selectedTenantAccountId = useMemo<string | undefined>(() => {
      const account = partyApiSelectors.getPartyAccountByPortfolioId(state)(
        selectedPortfolioTenantId,
        selectedPortfolioId,
        'Tenant',
      )
      return path(['accountId'], account)
    }, [selectedPortfolioId, selectedPortfolioTenantId, state])

    const depositAccountBalance = walletApiSelectors.getDepositAccountBalance(state)(
      selectedPortfolioTenantId,
      selectedTenantAccountId,
    )

    useEffect(() => {
      if (selectedTenantAccountId) {
        dispatch(walletApiEvents.balance_request({ partyId: selectedPortfolioTenantId }))
        if (selectedPortfolioTenantId && selectedTenantAccountId) {
          dispatch(
            accountingApiEvents.depositStatementJson_request({
              id: selectedPortfolioTenantId,
              accountId: selectedTenantAccountId,
            }),
          )
        }
      }
    }, [selectedTenantAccountId, dispatch, selectedPortfolioTenantId])

    const depositStore = {
      currentProperty,
      depositAccountBalance,
      /**
       * @todo clean this up, should fetch asset before opening transfer modal?
       */
      depositTransferModal: useSelector(portfolioSelectors.depositTransferModal),
      openDepositTransferModal: () => {
        const selectedTenantAccountAsset = path(
          ['name'],
          getWalletAsset(selectedPortfolioTenantId, selectedTenantAccountId),
        )
        if (selectedTenantAccountAsset) {
          dispatch(
            portfolioEvents.openDepositTransferModal({
              partyId: selectedPortfolioTenantId,
              account: selectedTenantAccountId,
              asset: selectedTenantAccountAsset,
            }),
          )
        }
      },
      handleTransfer: ({ body, params }: any) => {
        dispatch(
          walletApiEvents.transfer_request({
            body,
            params,
            meta: {
              partyId: selectedPortfolioTenantId,
              amount: body.amount,
              account: body.account,
            },
          }),
        )
      },
      closeDepositTransferModal: () => dispatch(portfolioEvents.closeDepositTransferModal()),
      depositStatement: useSelector<RootState>(state =>
        accountingApiSelectors.getDepositStatementJsonById(state)(selectedTenantAccountId as string),
      ),

      // Deposit topup request
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      openDepositTopupRequest: (portfolioId: any) => dispatch(portfolioEvents.depositTopupRequestOpened(portfolioId)),
      ...props,
    }
    return <context.Provider value={depositStore}>{children}</context.Provider>
  },
)

DepositProvider.propTypes = propTypes

export default DepositProvider

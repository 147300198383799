import React, { ReactChild, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CurrencyField, Tooltip } from 'views/components'
import { portfolioApiEvents } from 'modules/api/portfolio'
import styles from './ApplicationFeeControl.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { useEffectOnce } from 'react-use'

type Props = {
  partyId: string
  portfolioId: string
  applicationId: string
  isOpen: boolean
  active: boolean
  isVatActive: boolean
  value: number
  onClose: () => void
  children: ReactChild
}

const ApplicationFeeControl = ({
  partyId,
  portfolioId,
  applicationId,
  isOpen,
  active,
  isVatActive,
  value,
  onClose,
  children,
}: Props) => {
  const dispatch = useDispatch()
  const [isVatApplied, setIsVatApplied] = useState(false)
  const [applicationFee, setApplicationFee] = useState(0)

  const handleUpdateApplication = useCallback((id, values: $TSFixMe, applicationId) => {
    dispatch(portfolioApiEvents.updateApplication_request({ id, values, applicationId }))
  }, [])

  useEffectOnce(() => {
    setIsVatApplied(isVatActive)
    setApplicationFee(value)
  })

  const renderBody = () => {
    return (
      <>
        <CurrencyField
          includeVatControl
          name="applicationFee"
          placeholder="Application fee"
          onChange={({ target }: any) => {
            setApplicationFee(target.value || 0)
          }}
          value={applicationFee}
          onToggleChange={(vatApplied: any) => setIsVatApplied(vatApplied)}
          vatApplied={isVatApplied}
          className={styles['application-fee--input']}
        />
        <p>
          A draft invoice will be generated once a fee higher than <strong>R0</strong> is saved.
        </p>
        <Button
          type="submit"
          size="sm"
          onClick={() => {
            handleUpdateApplication(portfolioId, { applicationFee, vat: isVatApplied, partyId }, applicationId)
            onClose()
          }}
        >
          Save
        </Button>
      </>
    )
  }

  return (
    <Tooltip isOpen={isOpen} contrast="light" active={active} body={renderBody()} onOuterAction={onClose} place="above">
      {children}
    </Tooltip>
  )
}

export default ApplicationFeeControl

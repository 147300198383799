import React from 'react'

const SvgConfigure = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M10.17 22a3.001 3.001 0 015.66 0H26v2H15.83a3.001 3.001 0 01-5.66 0H6v-2h4.17zm6-7a3.001 3.001 0 015.66 0H26v2h-4.17a3.001 3.001 0 01-5.66 0H6v-2h10.17zm-6-7a3.001 3.001 0 015.66 0H26v2H15.83a3.001 3.001 0 01-5.66 0H6V8h4.17zM13 10a1 1 0 100-2 1 1 0 100 2zm6 7a1 1 0 100-2 1 1 0 100 2zm-6 7a1 1 0 100-2 1 1 0 100 2z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgConfigure

import React from 'react'
import classNames from 'classnames'
import { PolymorphicComponentProps } from 'types/PolymorphicComponentProps'
import styles from './Heading.module.scss'

interface Props {
  className?: string
  size?: 'a1' | 'a2' | 'a3' | null
  weight?: 'heavy' | 'medium' | 'regular' | null
  spacing?: 'large' | 'medium' | 'regular' | 'small' | null
  children: React.ReactNode
}

type HeadingProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>

const Heading = <C extends React.ElementType = 'h1'>({
  as,
  className,
  size = null,
  weight = null,
  spacing = null,
  children,
  ...props
}: HeadingProps<C>) => {
  const Component = as ?? 'span'
  const classes = classNames([styles.root, className], {
    [styles[`size-${size}`]]: size,
    [styles[`weight-${weight}`]]: weight,
    [styles[`spacing-${spacing}`]]: spacing,
  })

  return (
    <Component {...props} className={classes}>
      {children}
    </Component>
  )
}

export default Heading

import React, { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import NaturalPersonDetails from '../../../../views/components/organisms/FormGroups/NaturalPersonDetails'
import { Button, FormField, FormLoader, Select } from '../../../../views/components'
import { StatefulOtpVerification } from '../../../user'
import { agencyApiEvents, agencyApiSelectors } from '../../../api/agency'
import styles from './InviteUserForm.module.scss'
import { partyApiSelectors } from '../../../api/party'
import { find, identity, isEmpty, memoizeWith } from 'ramda'
import { apiStateSelectors } from '../../../api/apiState'
import FormErrors from '../../../../views/components/molecules/FormErrors/FormErrors'
import { $TSFixMe } from 'types/ts-migrate'
import { userRoles } from 'modules/api/user/constants'
import { identityNumberValidation } from 'utils/yup'
import { goBack } from 'connected-react-router'

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  lastName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  emailAddress: yup.string().required('required.').email('Email is invalid.'),
  dateOfBirth: yup.string().when('passport', {
    is: (val: $TSFixMe) => val && val.length > 0,
    then: yup.string().required('required.'),
  }),
})

const validationSchemaWithSaIdNumber = validationSchema.shape({
  idNumber: identityNumberValidation,
})

const validationSchemaWithPassportNumber = validationSchema.shape({
  passport: yup.string(),
})

// eslint-disable-next-line react/display-name
const createButtonContainer = memoizeWith(identity, (selectedPartyId: any, handleReset: any) => ({ children }: any) => (
  <div className={styles.footer}>
    {children}
    {selectedPartyId && (
      <Button type="button" secondary onClick={handleReset}>
        Reset form
      </Button>
    )}
  </div>
))

const InviteUserForm = (props: any) => {
  const dispatch = useDispatch()
  const [otpOpen, setOtpOpen] = useState(false)
  const [formValues, setFormValues] = useState<$TSFixMe>(null)
  const [selectedPartyId, setSelectedPartyId] = useState(null)
  const [otpPin, setOtpPin] = useState(null)
  const [stateIdentificationType, setStateIdentificationType] = useState('saIdNumber')

  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([agencyApiEvents.sendInvite_request, agencyApiEvents.sendPartyInvite_request]),
  )

  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  useEffectOnce(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(agencyApiEvents.invitations_request())
  })

  /**
   * @todo implement loader
   * @todo close close focus mode on success
   * @todo display errors on error
   */
  const handleOtpSubmit = (otp: any): void => {
    const { pin } = otp
    setOtpPin(pin)
    setOtpOpen(false)
    dispatch(goBack())
    if (selectedPartyId === null) {
      dispatch(agencyApiEvents.sendInvite_request({ ...formValues, otp: pin }))
    } else {
      dispatch(
        agencyApiEvents.sendPartyInvite_request({
          params: { partyId: selectedPartyId },
          body: { otp: pin, role: formValues.role },
        }),
      )
    }
  }

  const handlePartySelect = ({ id }: any) => setSelectedPartyId(id)
  const getPartyFirstName = useSelector(partyApiSelectors.getPartyFirstNameById)
  const getPartyLastName = useSelector(partyApiSelectors.getPartyLastNameById)
  const getPartyEmail = useSelector(partyApiSelectors.getPartyEmailById)
  const getPartyField = useSelector(partyApiSelectors.getPartyPropertyById)
  const agentInvites: $TSFixMe = useSelector(agencyApiSelectors.invitations)

  const getInitialValues = () => {
    const id = selectedPartyId
    return {
      firstName: getPartyFirstName(id),
      lastName: getPartyLastName(id),
      idNumber: getPartyField(id, 'idNumber'),
      emailAddress: getPartyEmail(id),
      cellNumber: getPartyField(id, 'cellNumber'),
      tellNumber: getPartyField(id, 'tellNumber'),
      role: userRoles.TeamMember,
    }
  }

  const getValidationSchema = () => {
    return stateIdentificationType === 'saIdNumber'
      ? validationSchemaWithSaIdNumber
      : validationSchemaWithPassportNumber
  }

  return (
    <div>
      <Formik
        validationSchema={getValidationSchema}
        enableReinitialize={true}
        initialValues={getInitialValues()}
        onSubmit={(values, props) => {
          /**
           * @todo This should be handled on the backend, to be replaced with generic
           * solution when backend returns more detailed exception message
           */
          const existingInviteForEmail = find((invite: any) => invite.emailAddress === values.emailAddress)(
            agentInvites,
          )

          if (existingInviteForEmail) {
            dispatch(agencyApiEvents.existingInviteNotification())
          } else {
            setFormValues(values)
            setOtpOpen(true)
          }
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          errors,
          touched,
          submitCount,
          handleSubmit,
          resetForm,
        }) => {
          const handleReset = (): void => {
            resetForm()
            setSelectedPartyId(null)
          }

          const submitEvent =
            selectedPartyId === null
              ? agencyApiEvents.sendInvite_request({ ...formValues, otp: otpPin })
              : agencyApiEvents.sendPartyInvite_request({ params: { partyId: selectedPartyId }, body: { otp: otpPin } })
          const generalErrors = getGeneralFormErrorsByEvent(submitEvent)
          const fieldErrors = getFormFieldErrorsByEvent(submitEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isSubmitting ? 'submitting' : !isEmpty(errors) || hasApiErrors ? 'error' : undefined

          const options = [
            {
              value: 'Owner',
              label: 'Account Owner',
            },
            {
              value: 'TeamMember',
              label: 'Team Member',
            },
            {
              value: 'ReadOnlyMember',
              label: 'Read Only',
            },
          ]

          const handleStateIdentificationType = (identificationType: string) => {
            setStateIdentificationType(identificationType)
          }

          return (
            <div className={styles.root}>
              <FormLoader
                onSubmit={handleSubmit}
                state={formLoaderState}
                buttonProps={{ children: 'Send Invite' }}
                successText="Invite sent successfully"
                ButtonContainer={createButtonContainer(selectedPartyId, handleReset)}
              >
                <FormErrors errors={generalErrors} />
                <NaturalPersonDetails
                  values={values}
                  tags={''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSelect={handlePartySelect}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  submitCount={submitCount}
                  disableFields={selectedPartyId}
                  allowPartySearch={selectedPartyId === null}
                  handleStateIdentificationType={handleStateIdentificationType}
                  stateIdentificationType={stateIdentificationType}
                />
                <div className="rbn--row">
                  <div className="rbn--col-md-3">
                    <FormField>
                      <Select
                        name="role"
                        label="User Role"
                        value={options.find((option: any) => option.value === values.role)}
                        onChange={(selectedOption: $TSFixMe) => setFieldValue('role', selectedOption.value)}
                        options={options}
                      />
                    </FormField>
                  </div>
                </div>
              </FormLoader>

              <StatefulOtpVerification
                isOpen={otpOpen}
                onClose={() => setOtpOpen(false)}
                onSubmit={({ otp }) => handleOtpSubmit(otp)}
              />
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

InviteUserForm.propTypes = {}

export default InviteUserForm

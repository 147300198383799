import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { FastField, Formik } from 'formik'
import { Divider, FormField, FormLoader, TextField, TextInput } from '../../../../views/components'
import { filter, find, isEmpty, map, mergeRight, pathOr, pipe, prop } from 'ramda'
import { agencyApiEvents, agencyApiSelectors } from '../../../api/agency'
import { useDispatch, useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import SearchPortfolios from './SearchPortfolios'
import SearchTeam from './SearchTeam'
import SegmentFormPortfoliosTable from './SegmentFormPortfoliosTable'
import SegmentFormTeamTable from './SegmentFormTeamTable'
import { formUtils } from '../../../../utils'
import { userApiEvents, userApiSelectors } from '../../../api/user'
import styles from './SegmentForm.module.scss'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import { useEffectOnce } from 'react-use'
import {
  transformPortfolioFormTable,
  transformPortfolioSummaryForSearch,
  transformTeamMemberForSearch,
  transformTeamMemberForTable,
} from './utils'
import { jsonStringSearch } from 'utils/string'
import { bookSegmentsEvents } from '../../state'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  name: yup.string().required('Required'),
})

const SegmentForm = ({ id = '' }: { id: string }): React.ReactElement => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(userApiEvents.team_request())
    dispatch(portfolioApiEvents.portfolioSummaries_request())
    dispatch(portfolioApiEvents.portfolioSummaries_request())
  })

  const defaultSegmentId = useSelector(userApiSelectors.getCurrentAgencyId)
  const segment = useSelector(state => agencyApiSelectors.getSegmentById(state)(id))

  const [portfolioQuery, setPortfolioQuery] = useState('')
  const [teamQuery, setTeamQuery] = useState('')
  const teamMembers = useSelector(userApiSelectors.getTeam)
  const portfolios = useSelector(portfolioApiSelectors.getPortfolioSummaries)

  const handlePortfolioQueryChange = useCallback(
    ({ target }) => {
      setPortfolioQuery(target.value)
    },
    [setPortfolioQuery],
  )

  const handleTeamQueryChange = useCallback(
    ({ target }) => {
      setTeamQuery(target.value)
    },
    [setTeamQuery],
  )

  const initialValues = {
    id,
    name: pathOr('', ['name'], segment),
    portfolios: pipe(
      // @ts-expect-error
      filter((summary: any) => pathOr([], ['segments'], summary).includes(id)),
      map(transformPortfolioFormTable),
    )(portfolios),
    team: pipe(
      // @ts-expect-error
      filter((summary: any) => pathOr([], ['segments'], summary).includes(id)),
      map(transformTeamMemberForTable),
    )(teamMembers),
    deletedPortfolios: [],
    deletedTeamMemberships: [],
  }

  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([
      bookSegmentsEvents.createSegment_request,
      bookSegmentsEvents.updateSegment_request,
    ]),
  )
  const createSegmentEvent = agencyApiEvents.createSegment_request
  const generalErrors = useSelector(state => apiStateSelectors.getGeneralFormErrorsByEvent(state)(createSegmentEvent))
  const fieldErrors = useSelector(state => apiStateSelectors.getFormFieldErrorsByEvent(state)(createSegmentEvent))
  const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

  const handleSubmit = useCallback(
    values => {
      if (id === 'new') {
        dispatch(bookSegmentsEvents.createSegment_request(values))
      } else {
        dispatch(bookSegmentsEvents.updateSegment_request(values))
      }
    },
    [id, dispatch],
  )

  return (
    <div className={styles.root}>
      <Formik
        validateOnBlur
        validateOnChange
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, touched, submitCount, errors, setFieldValue }) => {
          const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

          const formLoaderState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          const portfolioResultSets = [
            pipe(
              filter((result: any) => jsonStringSearch(portfolioQuery, result)),
              // remove portfolios that have already been added from search
              filter(({ portfolioId }: any) => !map(prop('id'), values.portfolios).includes(portfolioId)),
              // filter out portfolios that already have a segment (1 segment = default segment, > 1 = already added to another segment)
              filter((summary: any) => pathOr([], ['segments'], summary).length === 1),
              // filter out terminated portfolios
              // @ts-expect-error
              filter((summary: any) => !summary?.terminatedReason),
              map(transformPortfolioSummaryForSearch),
            )(portfolios),
          ]

          const teamResultSets = [
            pipe(
              map(transformTeamMemberForSearch),
              filter((result: any) => jsonStringSearch(teamQuery, result)),
              // remove team members that have already been added from search
              filter(({ id }: any) => !map(prop('id'), values.team).includes(id)),
            )(teamMembers),
          ]

          const handlePortfolioAdded = (e: any, { result }: any) => {
            // @ts-expect-error
            const portfolio = find((p: any) => p.portfolioId === result.id, portfolios)
            if (portfolio) {
              setFieldValue('portfolios', values.portfolios.concat(transformPortfolioFormTable(portfolio)))
            }
          }

          const handleTeamMemberAdded = (e: any, { result }: any) => {
            const teamMember = find((member: any) => member.userId === result.id, teamMembers)
            if (teamMember) {
              setFieldValue('team', values.team.concat(transformTeamMemberForTable(teamMember)))
            }
          }

          const handleRemovePortfolio = (id: any): void => {
            setFieldValue(
              'portfolios',
              values.portfolios.filter((summary: any) => summary.id !== id),
            )
            setFieldValue('deletedPortfolios', [...values.deletedPortfolios, id])
          }

          const handleRemoveTeamMember = (id: any): void => {
            setFieldValue(
              'team',
              values.team.filter((member: any) => member.id !== id),
            )
            setFieldValue('deletedTeamMemberships', [...values.deletedPortfolios, id])
          }

          return (
            <FormLoader
              onSubmit={handleSubmit}
              state={formLoaderState}
              submittingText={id === 'new' ? 'Creating segment' : 'Updating segment'}
              buttonProps={{ children: id === 'new' ? 'Create segment' : 'Save segment' }}
            >
              <FormField>
                <FastField
                  name="name"
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      inputComponent={
                        <TextInput
                          type={TextFieldTypes.text}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          placeholder="EG: Alpha segment"
                          onBlur={handleBlur}
                        />
                      }
                      label="Segment name"
                      disabled={defaultSegmentId === id}
                      error={getFieldError('name')}
                    />
                  )}
                />
              </FormField>

              <Divider />

              {id !== defaultSegmentId && (
                <>
                  <SearchPortfolios
                    onResultSelect={handlePortfolioAdded}
                    onSearchChange={handlePortfolioQueryChange}
                    // @ts-expect-error
                    resultSets={portfolioResultSets}
                  />

                  {values.portfolios.length > 0 ? (
                    <SegmentFormPortfoliosTable data={values.portfolios} onRemove={handleRemovePortfolio} />
                  ) : (
                    <p>
                      No leases yet. Add one by clicking the plus icon to the right and then search for the lease
                      you&apos;d like to add to this segment.
                    </p>
                  )}
                  <Divider />
                </>
              )}

              <SearchTeam
                onResultSelect={handleTeamMemberAdded}
                onSearchChange={handleTeamQueryChange}
                resultSets={teamResultSets}
              />

              {values.team.length > 0 ? (
                <SegmentFormTeamTable data={values.team} onRemove={handleRemoveTeamMember} />
              ) : (
                <p>
                  No team members yet. Add one by clicking the plus icon to the right and search for the team member
                  you&apos;d like to add to this segment.
                </p>
              )}

              <Divider />
            </FormLoader>
          )
        }}
      </Formik>
    </div>
  )
}

SegmentForm.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SegmentForm

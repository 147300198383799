import React from 'react'
import cx from 'classnames'
import styles from './TransactionListItem.module.scss'

type TProps = {
  label: string
  value: string
  className?: string
}

const TransactionListItem = ({ label, value, className }: TProps) => {
  const classes = cx([styles.root, className])
  return (
    <div className={classes}>
      <span>{label}</span>
      <hr />
      <span>{value}</span>
    </div>
  )
}

export default TransactionListItem

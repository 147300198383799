import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { format, getUnixTime } from 'date-fns'
import { subscribe } from 'react-contextual'
import { get, map } from 'lodash-es'
import { stringUtils } from '../../../../../../utils'
import { AggregateTable, CurrencyText, Header, Label, NoContent } from '../../../..'
import { InvoicesProvider, SearchFilterProvider } from '../../../../../providers'
import styles from './ActiveInvoiceTable.module.scss'
import { find, map as rMap, pipe, prop, reverse, sortBy } from 'ramda'
import { sortByProps } from 'utils/array'
import { useSelector } from 'react-redux'
import { reconApiSelectors } from '../../../../../../modules/api/recon'
import { $TSFixMe } from 'types/ts-migrate'
import SvgInvoiceFill from 'views/components/atoms/Icons/Doc/InvoiceFill'

const statusColorMap = {
  Blue: '#056DBA',
  Green: '#128269',
  Yellow: '#F7B735',
  Amber: '#FF6D21',
  Red: '#FF1900',
}

const ActiveInvoiceTable = ({
  title = 'Active Invoices',
  activeInvoiceFilters,
  openInvoice,
  searchQuery,
  activeFilterIndex,
  animations,
  getInvoiceBeneficiariesForPaymentRequest,
  failedPaymentApprovals,
  getBankingValidationErrorsByPartyId,
  currentInvoice,
}: any): React.ReactElement => {
  const [data, setData] = useState<$TSFixMe[]>([])
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const filter = get(activeInvoiceFilters, `[${[activeFilterIndex]}]`, {
    invoices: [],
  })

  useEffect(() => {
    const defaultSortedData: $TSFixMe[] = pipe(
      rMap((inv: any) => ({
        dueDateSeconds: format(new Date(inv.dueDate), 't'),
        ...inv,
      })),
      (list: any) => sortByProps(['dueDateSeconds', 'invoiceTo', 'property'], list),
    )(filter.invoices)

    setData(
      defaultSortedData.map((invoice: $TSFixMe) => ({
        ...invoice,
        meta: {
          invoiceNumber: invoice.invoiceNumber.toString(),
          invoiceTo: invoice.customerName,
          property: invoice.property,
          type: invoice.invoiceType,
          dueDate: invoice.dueDate ? getUnixTime(new Date(invoice.dueDate)).toString() : '',
          amount: invoice.grossAmount.toString(),
        },
      })),
    )
  }, [filter.invoices])

  const _openInvoice = useCallback((id, type) => {
    openInvoice(id, type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const status = get(filter, 'status', '')
  const filteredInvoices = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const getInvoiceParties = useSelector(reconApiSelectors.getInvoiceParties)

  const columns = [
    {
      Header: '',
      accessor: 'status',
      style: {
        maxWidth: '10px',
        padding: '0 10px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      },
    },
    {
      Header: 'NO.',
      accessor: 'invoiceNumber',
      style: {
        maxWidth: '100px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice to',
      accessor: 'invoiceTo',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Property',
      accessor: 'property',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      style: {
        maxWidth: '150px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Due date',
      accessor: 'dueDate',
      style: {
        maxWidth: '120px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        maxWidth: '120px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
  ]

  const currentInvoiceId = currentInvoice?.id

  const invoiceData: $TSFixMe = useMemo(() => {
    const buildInvoiceObj = (inv: any): any => {
      const hasBeneficiaries = getInvoiceBeneficiariesForPaymentRequest(inv.id).length > 0
      // @ts-expect-error
      const bankingValidationErrors = getInvoiceParties(inv.id)
        .map(getBankingValidationErrorsByPartyId)
        .filter((err: any) => !!err)

      const hasPaymentFailed =
        !!failedPaymentApprovals.find(({ invoiceId }: any) => invoiceId === inv.id) ||
        bankingValidationErrors.length > 0

      return {
        status: (
          <Label circular empty size="sm" style={{ backgroundColor: statusColorMap[status] }}>
            &nbsp;
          </Label>
        ),
        id: inv.id,
        highlighted: currentInvoiceId === inv.id,
        meta: inv.meta, // used for sorting
        enableSelection: filter.key === 'ready' ? hasBeneficiaries : true,
        invoiceNumber: `INV-${inv.invoiceNumber as string}`,
        invoiceTo: inv.customerName,
        property: inv.property,
        type: inv.invoiceType,
        dueDate: format(new Date(inv.dueDate), 'dd MMM yyyy'),
        amount: inv.grossAmount ? <CurrencyText>{inv.grossAmount}</CurrencyText> : <em>No amount set</em>,
        animateSuccess: !hasPaymentFailed && animations?.bulkApprovePayments.includes(inv.id),
        animateDangerFlash: animations?.bulkNudge.includes(inv.id),
        hasError: hasPaymentFailed,
      }
    }

    const tableData = searchQuery ? map(filteredInvoices, buildInvoiceObj) : map(data, buildInvoiceObj)

    return find(prop('hasError'), tableData)
      ? pipe(
          // @ts-expect-error
          sortBy(prop('hasError')),
          reverse,
        )(tableData)
      : tableData
  }, [
    data,
    filteredInvoices,
    currentInvoiceId,
    searchQuery,
    status,
    animations.bulkApprovePayments,
    animations.bulkNudge,
    filter.key,
    getInvoiceBeneficiariesForPaymentRequest,
    failedPaymentApprovals,
    getBankingValidationErrorsByPartyId,
    getInvoiceParties,
  ])

  return useMemo(
    () => (
      <div className={styles.root}>
        <Header icon={<SvgInvoiceFill />} text={title} />
        {invoiceData.length > 0 ? (
          <AggregateTable
            enableSelection={!['unallocated', 'open'].includes(filter.key)}
            columns={columns}
            data={invoiceData}
            onRowClick={data => _openInvoice(data.id, 'active')}
          />
        ) : (
          <NoContent heading="All your invoices have been taken care of." />
        )}
      </div>
    ),
    [_openInvoice, columns, invoiceData, filter.key, title],
  )
}

export default subscribe(
  [InvoicesProvider, SearchFilterProvider],
  (
    {
      activeInvoiceFilters,
      openInvoice,
      animations,
      getInvoiceBeneficiariesForPaymentRequest,
      failedPaymentApprovals,
      getBankingValidationErrorsByPartyId,
      currentInvoice,
    }: any,
    { searchQuery, activeFilterIndex }: any,
  ) => ({
    // InvociesProvider props
    activeInvoiceFilters,
    openInvoice,
    animations,
    getInvoiceBeneficiariesForPaymentRequest,
    failedPaymentApprovals,
    getBankingValidationErrorsByPartyId,
    currentInvoice,
    // SearchFilterProvider props
    searchQuery,
    activeFilterIndex,
  }),
)(ActiveInvoiceTable)

import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import CommunicationsProvider from '../CommunicationsProvider'
import CommunicationsPage from './CommunicationsPage'

const CommunicationsRoute = (props: any) => {
  return (
    <CommunicationsProvider {...props}>
      <SearchFilterProvider location={props.location}>
        <CommunicationsPage />
      </SearchFilterProvider>
    </CommunicationsProvider>
  )
}

export default CommunicationsRoute

import React, { FC } from 'react'
import classNames from 'classnames'
import { PhoneInput } from 'react-international-phone'
import { ParsedCountry } from 'react-international-phone/dist/types'
import 'react-international-phone/style.css'
import styles from './PhoneField.module.scss'

type Props = {
  defaultCountry?: string
  value: string
  disabled?: boolean
  onChange?: (phone: string, meta: { country: ParsedCountry; inputValue: string }) => void
}

const PhoneField: FC<Props> = ({ defaultCountry, value, disabled, onChange }) => {
  const classes = classNames(styles.root)

  return (
    <PhoneInput
      className={classes}
      defaultCountry={defaultCountry}
      value={value}
      onChange={onChange}
      defaultMask=".........."
      forceDialCode={true}
      disabled={disabled}
    />
  )
}

export default PhoneField

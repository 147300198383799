import React from 'react'

const SvgEdit = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M9 23h1.414l9.314-9.314-1.414-1.414L9 21.586V23zm16 2H7v-4.243L20.435 7.322a1 1 0 011.414 0l2.829 2.829a1 1 0 010 1.414L13.243 23H25v2zm-5.272-14.142l1.414 1.414 1.414-1.414-1.414-1.414-1.414 1.414z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgEdit

import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../views/components/atoms/Button/Button'
import Modal from '../../../../views/components/molecules/Modal/Modal'
import styles from './DeleteSegmentConfirmation.module.scss'

const propTypes = {
  isOpen: PropTypes.bool,
  segmentName: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

const defaultProps = {
  isOpen: false,
}

const DeleteSegmentConfirmation = ({ isOpen, segmentName, onConfirm, onCancel }: any) => {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Modal className={styles.root} showClose={false} isOpen={isOpen} theme="dark">
      <h3 className="Modal_Title">Confirm delete</h3>
      <div className="Modal_Content">
        <p>Are you sure you want to delete {segmentName} segment?</p>
      </div>
      <div className="Modal_Actions">
        <Button className={styles['delete-button']} link onClick={onConfirm}>
          Yes, delete
        </Button>
        <Button link onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

DeleteSegmentConfirmation.propTypes = propTypes
DeleteSegmentConfirmation.defaultProps = defaultProps

export default DeleteSegmentConfirmation

import React, { FC } from 'react'
import classNames from 'classnames'
import Popover from 'react-popover'
import styles from './Tooltip.module.scss'

export type Props = {
  isOpen: boolean
  body?: React.ReactChild | string
  size?: 'sm' | 'md' | 'lg'
  padding?: 'none' | 'sm' | 'md' | 'lg'
  contrast?: 'dark' | 'light'
  className?: string
  preferPlace?: 'above' | 'right' | 'below' | 'left' | 'row' | 'column' | 'start' | 'end'
  place?: 'above' | 'right' | 'below' | 'left' | 'row' | 'column' | 'start' | 'end'
  onOuterAction?: () => void
  refreshIntervalMs?: number
  tipSize?: number
  target?: React.ReactNode
  appendTarget?: object
  children: React.ReactChild | React.ReactNode | undefined
  active?: boolean
}

const Tooltip: FC<Props> = ({
  size = 'sm',
  padding = 'sm',
  contrast = 'dark',
  className = '',
  preferPlace = null,
  place = 'above',
  refreshIntervalMs = 200,
  tipSize = 5,
  appendTarget = document.body,
  active = false,
  isOpen,
  body,
  onOuterAction,
  target,
  children,
}) => {
  const classes = classNames([
    className,
    `Popover-contrast-${contrast}`,
    `Popover-padding-${padding}`,
    `Popover-size-${size}`,
  ])

  return (
    <div className={styles.root}>
      <Popover
        isOpen={isOpen}
        body={body}
        preferPlace={preferPlace}
        place={place}
        onOuterAction={onOuterAction}
        refreshIntervalMs={refreshIntervalMs}
        tipSize={tipSize}
        target={target}
        appendTarget={appendTarget}
        className={classes}
        active={active}
      >
        {children}
      </Popover>
    </div>
  )
}

export default Tooltip

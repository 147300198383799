import { connect } from 'react-redux'
import { ChatBot } from '../../components'

const mapStateToProps = (state, { push }: any) => ({
  messages: ['Any notes or tweaks?'],

  actions: [],
})

const mapDispatchToProps = (dispatch: any) => ({
  actionClickHandler: (action: any) => dispatch(action()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot)

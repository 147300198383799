import { morphism } from 'morphism'
import { $TSFixMe } from 'types/ts-migrate'
import { currencyUtils } from '../../../utils'

const { toCents } = currencyUtils

const agencyPerformanceSummarySchema = {
  bucketType: 'bucketType',
  buckets: ({ buckets }: $TSFixMe) => {
    return buckets.map((bucket: $TSFixMe) => ({
      ...bucket,
      invoiced: toCents(bucket?.invoiced || 0).getAmount(),
      collected: toCents(bucket?.collected || 0).getAmount(),
      commission: toCents(bucket?.commission || 0).getAmount(),
    }))
  },
}

export const transformAgencyPerformanceSummary = (source: $TSFixMe) => morphism(agencyPerformanceSummarySchema, source)

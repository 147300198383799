import { useState } from 'react'
import { $TSFixMe } from 'types/ts-migrate'

/**
 * Example usage:
 * const [selection, setSelection] = useBulkSelect()
 *
 * @param {boolean} defaultVal default value
 */
function useBulkSelect(defaultVal = false): $TSFixMe {
  const [selection, setSelection] = useState([])
  return [selection, setSelection]
}

export default useBulkSelect

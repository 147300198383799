import { createSelector } from 'reselect'
import { filter, find, get, sumBy } from 'lodash-es'
import { pipe, path, map, reject, isEmpty, pathOr } from 'ramda'
import { invoiceApiSelectors } from '../invoice'
import { $TSFixMe } from 'types/ts-migrate'
import { TApplicationInvoiceStatus } from './types'

const state = (state: any): $TSFixMe => state

const reconState = (state: any): $TSFixMe => state.api.recon

export const getInvoices = createSelector(reconState, s => get(s, 'invoices', []))

export const getActiveInvoicesByInvoiceStatus = createSelector(
  state,
  state => (invoiceStatus: any, portfolioId: any) => {
    let filteredInvoices = getInvoices(state)
      .filter((inv: any) => inv.reconStatus !== 'Reconciled')
      .filter((inv: any) => inv.invoiceStatus === invoiceStatus)
    if (portfolioId) {
      filteredInvoices = filteredInvoices.filter((inv: any) => inv.portfolioId === portfolioId)
    }
    return filteredInvoices.map((inv: any) => ({
      ...inv,
      invoiceType: invoiceApiSelectors.getInvoiceTypeNameByValue(state)(inv.invoiceType),
    }))
  },
)

export const getInvoicesCount = createSelector(getInvoices, invoices => invoices.length)

export const getCreditNoteReasons = createSelector(reconState, s => get(s, 'creditNoteReasons', []))

export const getInvoiceById = createSelector(
  getInvoices,
  invoices => (id: any) => find(invoices, (invoice: any) => invoice.id === id),
)

export const getInvoiceParties = createSelector(state, s => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  if (!invoice) return []
  return pipe(
    path(['beneficiaries']),
    /** @todo create makeshift enum/const thang for party beneficiary types */
    // @ts-expect-error
    map(reject((b: any) => b.type !== 'PartyBeneficiary')),
    reject(isEmpty),
    pipe(map(path(['beneficiary', 'value', 'partyId']))),
  )(invoice)
})

export const getInvoiceEasyPayBeneficiaryReferences = createSelector(state, s => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  if (!invoice) return []
  const easyPayBeneficiaries: $TSFixMe = pipe(
    path(['beneficiaries']),
    /** @todo create makeshift enum/const thang for party beneficiary types */
    // @ts-expect-error
    map(reject((b: any) => b.type !== 'EasyPayBeneficiary')),
    reject(isEmpty),
  )(invoice)
  return pipe(map(path(['beneficiary', 'value', 'easyPayReference'])))(easyPayBeneficiaries)
})

export const getInvoiceSelectionTotal = createSelector(
  state,
  state => (ids: any) =>
    ids.map((id: any) => getInvoiceById(state)(id)).reduce((acc: number, val: any) => parseFloat(val.balance) + acc, 0),
)

export const getDoneInvoices = createSelector(getInvoices, invoices =>
  filter(invoices, (invoice: any) => invoice.invoiceStatus === 'Done'),
)

export const getInvoiceStatusById = createSelector(state, s => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  return get(invoice, 'invoiceStatus', false)
})

export const getNextInvoice = createSelector(getInvoices, invoices => (id: any) => {
  const invoiceIndex: number = invoices.findIndex((i: any) => i.id === id)
  const nextInvoice = get(invoices, `[${invoiceIndex + 1}]`, false)
  return nextInvoice
})

export const getFilteredInvoices = createSelector(getInvoices, invoices => (query: any) => {
  return invoices
    .map((invoice: { customerName: string; invoiceType: string }) => {
      const { customerName, invoiceType } = invoice
      const searchString = `${customerName} ${invoiceType}`
      if (searchString.toLowerCase().includes(query)) {
        return invoice
      } else {
        return undefined
      }
    })
    .filter((i: any) => i !== undefined)
})

/**
 * Customer Summary
 */
export const getCustomerSummary = createSelector(
  reconState,
  rs => (customerId: any, accountId: any) => get(rs, ['customerAccountActivity', customerId, accountId], []),
)

/**
 * Invoice Activity
 */

export const getCustomerActiveInvoices = createSelector(
  state,
  s => (customerId: any, accountId: any) => get(getCustomerSummary(s)(customerId, accountId), 'openInvoices', []),
)

export const getCustomerRecentActivity = createSelector(
  state,
  s => (customerId: any, accountId: any) => get(getCustomerSummary(s)(customerId, accountId), 'recentActivity', []),
)

export const getCustomerActiveInvoicesTotal = createSelector(
  state,
  s => (customerId: any, accountId: any) =>
    sumBy(getCustomerActiveInvoices(s)(customerId, accountId), (o: any) => o.balance),
)

/**
 * Notifications
 */
export const getNotifications = createSelector(reconState, reconState => reconState.notifications)

export const getNotificationByName = createSelector(
  getNotifications,
  notifications => (name: any) => notifications.find((n: any) => n.name === name),
)

export const getNotificationCountByName = createSelector(state, state => (name: any) => {
  const notification = getNotificationByName(state)(name)
  return get(notification, 'count', 0)
})

export const getNotificationCountTotal = createSelector(state, state => {
  const invoiceReadyNotifications = invoiceApiSelectors.getNotificationCountByName(state)('Ready')
  const invoiceNotSetNotifications = invoiceApiSelectors.getNotificationCountByName(state)('Not Set')
  const reconNotifications = getNotifications(state)

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const notificationsTotal =
    sumBy(reconNotifications, (n: any) => n.count) + invoiceReadyNotifications + invoiceNotSetNotifications

  return notificationsTotal
})

/**
 * Customer Active invoices by account
 */
export const getCustomerActiveInvoicesByAccount = createSelector(
  reconState,
  reconState => (customerId: any, accountId: any) =>
    get(reconState.customerActiveInvoicesByAccount, [customerId, accountId], []),
)

/**
 * EasyPay
 */
export const getEasyPayData = createSelector(reconState, reconState => reconState.easypayData)

export const getEasyPayDataByReference = createSelector(reconState, reconState => (reference: any) => {
  console.log('reference', reference, reconState.easypayData)
  return get(reconState.easypayData, [reference], {})
})

export const getActiveInvoicesForPortfolio = createSelector(
  reconState,
  s => (id: any) => pathOr([], ['openInvoicesById', id], s),
)

/**
 * @returns {array} beneficiaries in shape required for events.payment_request
 */
export const getInvoiceBeneficiariesForPaymentRequest = createSelector(state, state => (invoiceId: any) => {
  const invoice = getInvoiceById(state)(invoiceId)
  return pathOr([], ['beneficiaries'], invoice).map(({ beneficiary }: any) => ({
    beneficiaryId: beneficiary.value.id,
    amount: beneficiary.value.amount,
  }))
})

/**
 * Bulk invoices
 */
export const getBulkInvoiceUploadErrors = createSelector(reconState, pathOr([], ['bulkInvoiceUploadErrors']))

export const getStats = createSelector(reconState, s => get(s, 'stats', {}))

/**
 * Application Fees
 */
export const getApplicationInvoicesStatusByPortfolioId = createSelector(
  reconState,
  state =>
    (portfolioId: any): TApplicationInvoiceStatus[] => {
      return pathOr([], ['applicationInvoicesStatus', portfolioId], state)
    },
)

export const getArchivedPeriod = createSelector(reconState, s => get(s, 'archivedPeriod', {}))

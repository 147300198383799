import React, { Children, useState, useCallback } from 'react'
import cx from 'classnames'
import styles from './RadioGroup.module.scss'
import Radio from '../../atoms/Radio/Radio'
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode
  className?: string
  defaultSelected?: string | number
  label?: string
  stackRadios?: boolean
  error?: string
}

const RadioGroup = ({
  children,
  className,
  defaultSelected,
  label,
  onChange = () => {},
  stackRadios,
  error,
  ...props
}: Props) => {
  const { name } = props
  const [selected, setSelected] = useState(defaultSelected)

  const handleChange = useCallback(
    e => {
      if (e.target.id !== selected) {
        setSelected(e.target.id)
        if (onChange) onChange(e)
      }
    },
    [onChange, selected],
  )

  const hasError = error && error.length > 0

  const classes = cx([styles.root, className], {
    [styles['is-stacked']]: stackRadios,
    [styles['has-error']]: hasError,
  })

  return (
    <div className={classes}>
      {label && (
        <>
          {!hasError && <label>{label}</label>}
          {hasError && (
            <label>
              {label} - {error}
            </label>
          )}
        </>
      )}
      <div className={styles.radios}>
        {Children.map(children, (radioButton: any) => {
          const { value, ...other } = radioButton.props
          return (
            <Radio
              {...other}
              name={name}
              onChange={handleChange}
              id={value}
              key={value}
              value={value}
              checked={value === selected}
              disabled={props.disabled}
            />
          )
        })}
      </div>
    </div>
  )
}

export default RadioGroup

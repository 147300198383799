import React from 'react'
import { withFormik } from 'formik'
import { Button, Column, Grid, TextField, TextInput } from '../../../../views/components'
import styles from './EditPropertyForm.module.scss'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const EditPropertyForm = ({ onCancel, isReadOnly, values, handleSubmit, handleChange, handleBlur }: any) => {
  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isReadOnly}>
          <div className={styles.body}>
            <Grid>
              <Column xs={12} md={8} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="buildingName"
                      placeholder="e.g. Cardiff Castle"
                      value={values.buildingName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Building / Complex name (if applicable)"
                />
              </Column>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
              <Column xs={12} lg={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="unitNumber"
                      placeholder="e.g. 4"
                      value={values.unitNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Unit Number (if applicable)"
                />
              </Column>
              <Column xs={12} md={8} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput type={TextFieldTypes.text} name="streetAddress" value={values.streetAddress} disabled />
                  }
                  label="Street Address"
                  disabled
                />
              </Column>
            </Grid>
            <Grid>
              <Column xs={12} md={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={<TextInput type={TextFieldTypes.text} name="city" value={values.city} disabled />}
                  label="City / municipality"
                  disabled
                />
              </Column>
              <Column xs={12} md={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput type={TextFieldTypes.text} name="province" value={values.province} disabled />
                  }
                  label="Province"
                  disabled
                />
              </Column>
              <Column xs={12} md={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput type={TextFieldTypes.text} name="postalCode" value={values.postalCode} disabled />
                  }
                  label="Postal code"
                  disabled
                />
              </Column>
            </Grid>
            <Grid>
              <Column xs={12} md={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput type={TextFieldTypes.text} name="country" value={values.country} disabled />
                  }
                  label="Country"
                  disabled
                />
              </Column>
            </Grid>
          </div>
          <div className={styles.footer}>
            <Button type="submit">Save</Button>
            <Button secondary onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default withFormik({
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ initialValues }: { initialValues: any; })... Remove this comment to see the full error message
  mapPropsToValues: ({ initialValues }) => initialValues,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  handleSubmit: (values, { props }) => props.handleSubmit(values),
})(EditPropertyForm)

const { REACT_APP_API_URL = '' } = process.env

export const NAME = 'recon'

export const invoiceStatuses = ['NotSet', 'Ready', 'Short', 'Overdue', 'Out']

export const ENDPOINTS = {
  FETCH_INVOICES: `${REACT_APP_API_URL}/v2.0/reconciliations/invoices`,
  FETCH_INVOICE: `${REACT_APP_API_URL}/v2.0/reconciliations/invoices/:id`,
  FETCH_CREDIT_NOTE_REASONS: `${REACT_APP_API_URL}/reconciliations/credit_note_reasons`,
  CREDIT_NOTE_INVOICE: `${REACT_APP_API_URL}/reconciliations/invoices/:id/credit_notes`,
  CREATE_PAYMENT: `${REACT_APP_API_URL}/reconciliations/invoices/:invoiceId/payments`,
  UPDATE_BENEFICIARIES: `${REACT_APP_API_URL}/reconciliations/invoices/:invoiceId/beneficiaries`,
  NUDGE: `${REACT_APP_API_URL}/reconciliations/invoices/nudge`,
  NOTIFICATIONS: `${REACT_APP_API_URL}/reconciliations/notifications`,
  CUSTOMER_OPEN_INVOICES: `${REACT_APP_API_URL}/v2.0/reconciliations/customers/:customerId/invoices/:accountId/open`,
  CUSTOMER_ACCOUNT_ACTIVITY: `${REACT_APP_API_URL}/reconciliations/customers/:customerId/account_activity/:accountId`,
  EASYPAY: `${REACT_APP_API_URL}/reconciliations/easypay`,
  FETCH_ACTIVE_INVOICES: `${REACT_APP_API_URL}/reconciliations/portfolio/:id/unreconciled`,
  BULK_INVOICE_UPLOAD: `${REACT_APP_API_URL}/reconciliations/bulk_invoices`,
  STATS: `${REACT_APP_API_URL}/reconciliations/stats`,
  APPLICATION_INVOICES_STATUS: `${REACT_APP_API_URL}/reconciliations/application-invoices/:portfolioId`,
  ARCHIVED: `${REACT_APP_API_URL}/v2.0/reconciliations/archived`,
}

export const DEBOUNCE_TIME = 1000

import { createSelector } from 'reselect'
import { first, get, map, memoize } from 'lodash-es'
import {
  pipe,
  map as Rmap,
  flatten,
  head,
  sortBy,
  prop,
  find,
  pathOr,
  concat,
  filter,
  ifElse,
  isEmpty,
  groupBy,
  values,
} from 'ramda'
import { PARTY_TYPES } from './constants'
import { $TSFixMe } from 'types/ts-migrate'

const state = (state: any): $TSFixMe => state

export const partyState = (state: any): $TSFixMe => state.api.party

export const getEntities = createSelector(partyState, state => get(state, 'entities', {}))

export const getParties = createSelector(getEntities, entities => get(entities, 'parties', {}))

export const getSearchResults = createSelector(partyState, state =>
  memoize((query: string) => get(state, `entities.bySearchTerms[${query}]`, [])),
)

export const getPartyById = createSelector(partyState, ps => (id: string) => get(ps, `entities.parties[${id}]`, false))

export const getPartyTypeById = createSelector(state, s => (id: any) => {
  const party = getPartyById(s)(id)
  if (!party) {
    return false
  }
  const isPerson = get(party, 'person', false)
  const isCompany = get(party, 'company', false)
  if (isPerson) {
    return 'person'
  }
  if (isCompany) {
    return 'company'
  }
})

/**
 * Get party.person or party.company
 */
export const getPartyDetailsById = createSelector(state, s =>
  memoize((id: any) => {
    const party = getPartyById(s)(id)
    if (!party) {
      return false
    }
    const partyType: $TSFixMe = getPartyTypeById(s)(id)
    return party[partyType]
  }),
)

/**
 * Get a party details property value.
 * Assumes consistent property key i.e. person.emailAddress && company.emailAddress
 */
export const getPartyPropertyById = createSelector(state, s => (id: any, prop: any) => {
  const partyDetails = getPartyDetailsById(s)(id)
  if (!partyDetails) {
    return ''
  }

  return get(partyDetails, prop, '')
})

export const getPartiesByTag = createSelector(
  state,
  s => (tag: any) =>
    Object.values(getParties(s)).filter(p => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const partyTags = getPartyPropertyById(s)(p.id, 'tags')
      return partyTags.includes(tag)
    }),
)

export const getAgencyParty = createSelector(state, s => {
  const partiesWithAgencyTag = getPartiesByTag(s)('Agency')
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  return first(partiesWithAgencyTag.map(p => getPartyDetailsById(s)(p.id)))
})

export const getPartyFirstNameById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'firstName'))

export const getPartyLastNameById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'lastName'))

export const getPartyAddressById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'address'))

export const getPartyNameById = createSelector(state, s => (id: any) => {
  const getProp = (prop: string): $TSFixMe => getPartyPropertyById(s)(id, prop)
  const companyName = getProp('companyName')
  if (companyName) {
    return companyName
  }
  const firstName: string = getProp('firstName')
  const lastName: string = getProp('lastName')
  return `${firstName} ${lastName}`.trim()
})

export const getPartyCompanyNameById = createSelector(
  state,
  s => (id: any) => getPartyPropertyById(s)(id, 'companyName'),
)
export const getPartyTradingAsOrCompanyNameById = createSelector(state, s => (id: any) => {
  const tradingAs = getPartyPropertyById(s)(id, 'tradingAs')
  const companyName = getPartyPropertyById(s)(id, 'companyName')
  return tradingAs || companyName
})

export const getPartyEmailById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'emailAddress'))

export const getPartyTelNumberById = createSelector(
  state,
  s => (id: any) => getPartyPropertyById(s)(id, 'cellNumber') || getPartyPropertyById(s)(id, 'telNumber'),
)

export const getPartyTaxNumberById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'taxNumber'))

export const getPartyVatNumberById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'vatNumber'))

export const getPartyCompanyRegistration = createSelector(
  state,
  s => (id: any) => getPartyPropertyById(s)(id, 'companyRegistration'),
)

export const getPartyTagsById = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'tags') || [])

export const getPartyAccountsById = createSelector(
  state,
  s => (id: any) => getPartyPropertyById(s)(id, 'accounts') || [],
)

export const getPartyAccountByAccountId = createSelector(
  state,
  s => (id: $TSFixMe, accountId: $TSFixMe) =>
    getPartyAccountsById(s)(id).find((account: $TSFixMe) => account.accountId === accountId),
)

export const getPartyUpdatedAt = createSelector(
  state,
  s => (id: any) => getPartyPropertyById(s)(id, 'updatedAt') || null,
)

export const getPersonsIdNumber = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'idNumber'))

export const getPersonsPassportNumber = createSelector(state, s => (id: any) => getPartyPropertyById(s)(id, 'passport'))

export const getPartyBankDetails = createSelector(state, s => (id: any) => ({
  bank: getPartyPropertyById(s)(id, 'bank'),
  accountNumber: getPartyPropertyById(s)(id, 'accountNumber'),
  accountType: getPartyPropertyById(s)(id, 'accountType'),
  accountName: getPartyPropertyById(s)(id, 'accountName'),
}))

export const partyHasBankDetails = createSelector(state, s => (id: any) => {
  const bankDetails = getPartyBankDetails(s)(id)
  return Object.values(bankDetails).filter(b => !b).length === 0
})

export const getPartyTags = createSelector(getEntities, entities => {
  const partyTags = get(entities, 'partyTags', [])
  // @ts-expect-error
  return sortBy(prop('label'), partyTags)
})

export const getPartyAccountByPortfolioId = createSelector(
  state,
  s =>
    (
      partyId: any,
      portfolioId: any,
      preferredTag: string,
    ): { accountId: string; partyId: string; tag: string } | undefined => {
      const party = getPartyDetailsById(s)(partyId)
      const findOp = find((acc: any) => <string | undefined>prop('tag', acc) === preferredTag)
      return <{ accountId: string; partyId: string; tag: string } | undefined>pipe(
        pathOr([], ['accounts']),
        filter((acc: any) => prop('portfolioId', acc) === portfolioId),
        ifElse(pipe(findOp, isEmpty), head, findOp),
      )(party)
    },
)

/**
 * Search selectors
 */

const groupPartyIdsByPartyType = createSelector(
  state,
  state => (partyIds: any) =>
    partyIds.reduce(
      (acc: any, id: any) => {
        if (getPartyTypeById(state)(id) === 'person') {
          acc.people.push(id)
        } else {
          acc.companies.push(id)
        }
        return acc
      },
      { people: [], companies: [] },
    ),
)

export const partiesForSearchTerm = createSelector(state, s => (searchTerm: any) => {
  const partyIds = partyState(s).entities.bySearchTerms[searchTerm] || []
  const { people, companies } = groupPartyIdsByPartyType(s)(partyIds)

  const shapeResult = (partyType: any) => (id: any) => {
    const tags = getPartyTagsById(s)(id)
    const partyTag = head(tags)
    return {
      id,
      partyType,
      // partyTag: partyTag === 'agent' ? 'agency' : partyTag,
      partyTag,
      text: getPartyNameById(s)(id),
      secondaryText: getPartyEmailById(s)(id),
    }
  }
  return [map(people, shapeResult(PARTY_TYPES.PERSON)), map(companies, shapeResult(PARTY_TYPES.COMPANY))]
})

export const getPartyForSearchResult = createSelector(state, s => (id: any) => {
  const party = getPartyDetailsById(s)(id)
  if (!party) {
    return {}
  }
  const text = getPartyNameById(s)(id)
  const secondaryText = getPartyEmailById(s)(id)
  const partyTag = get(getPartyTagsById(s)(id), '[0]', '')
  const partyType = getPartyTypeById(s)(id) === 'person' ? PARTY_TYPES.PERSON : PARTY_TYPES.COMPANY

  return { id, text, secondaryText, partyTag, partyType }
})

export const partiesSearchResultsSplitByTag = createSelector([state, partyState], (s, ps) =>
  memoize((query: any, searchTags = []) => {
    if (!query) {
      return []
    }

    const results = getSearchResults(s)(query)
    const searchResultSets = searchTags.map((searchTag: string) => {
      const ids = results.filter((id: any) => {
        const tags = getPartyTagsById(s)(id)
        return tags.includes(searchTag)
      })
      return ids.map(getPartyForSearchResult(s))
    })

    return searchResultSets.filter((searchResultSet: $TSFixMe) => searchResultSet.length > 0)
  }),
)

type AccountResult = {
  id: string
  text: string
  secondaryText: string
  partyTag: string
  portfolioId?: string
  paymentReference?: string
}

export const partiesSearchResultsSplitByAccount = createSelector([state, partyState], (s, ps) => (query: any) => {
  const results = getSearchResults(s)(query)

  // Split by tags
  const getPartyResults = (party: $TSFixMe): $TSFixMe => {
    const tags = getPartyTagsById(s)(party.id)
    return tags.map((tag: string) => ({
      id: party.id,
      text: getPartyNameById(s)(party.id),
      partyTag: tag,
      secondaryText: `${tag} - no attached lease`,
    }))
  }

  // Split by accounts
  const getAccountResults = (p: $TSFixMe): $TSFixMe => {
    const results: AccountResult[] = Rmap(
      (account: any) => ({
        id: account.partyId,
        text: getPartyNameById(s)(p.id),
        partyTag: account.tag,
        paymentReference: account.paymentReference,
        secondaryText: account.propertyAddress
          ? `${account.tag as string} @ ${account.propertyAddress as string}`
          : account.tag,
        portfolioId: account.portfolioId,
      }),
      getPartyAccountsById(s)(p.id),
    )

    const filteredResults = pipe(
      groupBy((result: AccountResult) => result.paymentReference || ''),
      values,
      Rmap((group: any) => {
        const tags = group.map(g => g.partyTag)
        return filter((item: AccountResult) => {
          return tags.includes('Application') && tags.includes('Tenant') ? item.partyTag !== 'Application' : true
        })(group)
      }),
      flatten,
    )(results)

    return filteredResults
  }

  // Either split by accounts OR party tags
  return [
    pipe(
      Rmap((id: any) => {
        const party = getPartyById(s)(id)
        const accounts = getPartyAccountsById(s)(id)
        const partyResults = getPartyResults(party)
        return accounts.length > 0 ? [...partyResults, ...getAccountResults(party)] : partyResults
      }),
      flatten,
    )(results),
  ]
})

export const partiesResultsByTags = createSelector([state], s =>
  memoize((searchTags = []) => {
    const results = Object.keys(getParties(s))

    let searchResults: any = []
    searchTags.forEach((tag: string) => {
      const ids = results.filter(id => {
        const tags = getPartyTagsById(s)(id)
        return tags.includes(tag)
      })
      searchResults = concat(searchResults, ids.map(getPartyForSearchResult(s)))
    })

    return searchResults
  }),
)

export const partiesSearchResultsByTags = createSelector([state], s =>
  memoize((query: any, searchTags = []) => {
    if (!query) {
      return []
    }

    const results = getSearchResults(s)(query)

    let searchResults: $TSFixMe = []
    searchTags.forEach((tag: $TSFixMe) => {
      const ids = results.filter((id: any) => {
        const tags = getPartyTagsById(s)(id)
        return tags.includes(tag)
      })
      searchResults = concat(searchResults, ids.map(getPartyForSearchResult(s)))
    })

    return searchResults
  }),
)

export const getBadReferenceFuzzyMatches = createSelector([getEntities], ps =>
  memoize((reference: any) => pathOr([], ['badReferenceFuzzyMatches', reference], ps)),
)

export const getBulkImportResult = createSelector([partyState], ps => ps.bulkImportResult)

import { createSelector } from 'reselect'
import { NAME } from './constants'

const ui = (state: any) => state[NAME]

export const getPortfolio = createSelector(ui, ui => ui.portfolio)

export const getPortfolioForms = createSelector(getPortfolio, p => p.forms)

export const getPortfolioPropertyForm = createSelector(getPortfolioForms, forms => forms.property)

export const getPortfolioPropertyOwnerForm = createSelector(getPortfolioForms, forms => forms.owner)

export const getPortfolioTenantForm = createSelector(getPortfolioForms, forms => forms.tenant)

export const getPortfolioTermForm = createSelector(getPortfolioForms, forms => forms.term)

export const getPortfolioRentForm = createSelector(getPortfolioForms, forms => forms.rent)

export const getPortfolioSettingsForm = createSelector(getPortfolioForms, forms => forms.settings)

export const getPortfolioCommissionForm = createSelector(getPortfolioForms, forms => forms.commission)

export const getPortfolioInvoicesForm = createSelector(getPortfolioForms, forms => forms.invoices)

export const getPortfolioPartyForm = createSelector(getPortfolioForms, forms => forms.party)

import { createChatbotDialog } from './ChatbotDialog'
import { chatbotEvents } from '../state'
import { portfolioApiEvents } from '../../../api/portfolio'

const initialState = {
  requestedApproval: false,
  isActivated: false,
}

export const { leaseConfirmationDialog } = createChatbotDialog('leaseConfirmation')
  .withInitialState(initialState)
  .reduce({
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: (payload: any) => {
      const {
        payload: {
          tags: { status },
        },
      } = payload
      return {
        $merge: {
          isActivated: status === 'approved',
        },
      }
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.approve_success]: () => ({
      $merge: {
        isActivated: true,
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({ $merge: initialState }),
  })
  .withEvents({
    approvalRequested: (payload: any) => payload,
  })
  .when('approvalRequested', (_: any) => ({
    $merge: {
      requestedApproval: true,
    },
  }))
  .setup()

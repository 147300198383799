import React, { useMemo, useCallback } from 'react'
import { matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { subscribe } from 'react-contextual'
import { path, pipe, props, join, pathOr } from 'ramda'
import { ROUTES } from 'constants/routes'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import PortfoliosProvider from '../../../portfolios/PortfoliosProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { $TSFixMe } from 'types/ts-migrate'
import { RootState } from 'src/state/store'

const PortfolioStatement = ({ match, redirect }: $TSFixMe): null | React.ReactElement => {
  const editMatchPath = matchPath(match.url, {
    path: ROUTES.portfolioStatement,
    exact: true,
    strict: false,
  })

  const portfolioId: string | undefined = path(['params', 'id'], editMatchPath)

  const dispatch = useDispatch()

  const fetchPortfolioStatement = useCallback(
    (startDate, endDate, json = true) => {
      if (portfolioId) {
        const event = json
          ? accountingApiEvents.portfolioStatement_request
          : accountingApiEvents.portfolioStatementPdf_request
        dispatch(
          event({
            portfolioId,
            start_date: startDate,
            end_date: endDate,
          }),
        )
      }
    },
    [portfolioId, dispatch],
  )

  const fetchPortfolioStatementPdf = useCallback(
    (startDate, endDate) => fetchPortfolioStatement(startDate, endDate, false),
    [fetchPortfolioStatement],
  )
  const statementData = useSelector((state: RootState) =>
    accountingApiSelectors.getPortfolioStatementById(state)(portfolioId as string),
  )
  const statementPdf = useSelector((state: RootState) =>
    accountingApiSelectors.getPortfolioStatementPdfById(state)(portfolioId as string),
  )
  const pdfFileName = pipe(
    props(['addressLine1', 'addressLine2']),
    join(', '),
    // @ts-expect-error
  )(pathOr({}, ['context', 'property'], statementData))
  const isOpen = portfolioId

  return useMemo(() => {
    return isOpen ? (
      <ViewStatementProvider
        onClose={() => redirect(`/leases/${portfolioId}`)}
        onParamChange={fetchPortfolioStatement}
        isOpen={isOpen}
        statementData={statementData}
        downloadStatement={fetchPortfolioStatementPdf}
        statementPdf={statementPdf}
        pdfFileName={pdfFileName}
      >
        <ViewStatement title="Lease Statement" />
      </ViewStatementProvider>
    ) : null
  }, [
    redirect,
    fetchPortfolioStatement,
    fetchPortfolioStatementPdf,
    statementData,
    statementPdf,
    isOpen,
    pdfFileName,
    portfolioId,
  ])
}

export default subscribe([PortfoliosProvider], ({ match, redirect }: $TSFixMe) => ({ match, redirect }))(
  PortfolioStatement,
)

import React, { FC, useCallback, useEffect, useState } from 'react'
import { prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { format, startOfMonth, endOfMonth, subMonths } from 'date-fns'
import { $TSFixMe } from 'types/ts-migrate'
import { DateRangePicker, DimmerLoader, Modal, Tooltip } from '../../../../views/components'
import { apiStateSelectors } from '../../../api/apiState'
import { reportingApiEvents } from '../../../api/reporting'
import styles from './DownloadTPNReport.module.scss'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import Logo from '../../../../theme/images/logo-tpn.png'
import DownloadIcon from '../../../../views/components/atoms/Icons/Doc/DocumentDownload'
import { useHover } from 'react-use'

type Props = {
  name: string
  short: string
}

const DownloadTPNReport: FC<Props> = ({ name, short }) => {
  const dispatch = useDispatch()
  const dateFormat = 'yyyy-MM-dd'

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(format(startOfMonth(subMonths(Date.now(), 3)), dateFormat))
  const [selectedEndDate, setSelectedEndDate] = useState(format(endOfMonth(Date.now()), dateFormat))

  const [hoverableButton, isHovering] = useHover(
    <button type="button" onClick={() => setIsModalOpen(true)}>
      <DownloadIcon />
    </button>,
  )

  const tpnReport = {
    id: 'tpnReport',
    action: reportingApiEvents.tpnReport_request,
    actionArgs: { dataType: 'csv' },
  }

  const downloadReport = useCallback(
    action => {
      dispatch(action)
    },
    [dispatch],
  )

  const loadingEvent = prop('action', tpnReport)
  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([loadingEvent]))

  useEffect(() => {
    if (!isLoading) {
      setIsModalOpen(false)
    }
  }, [isLoading])

  return (
    <>
      <div className={styles.body}>
        <figure>
          <img src={Logo} alt="TPN Report" />
        </figure>
        <div className={styles.content}>
          <h3>{name}</h3>
          <p>{short}</p>
        </div>
        <div className={styles.actions}>
          <Tooltip isOpen={isHovering} body="Click to download">
            {hoverableButton}
          </Tooltip>
        </div>
      </div>
      <Modal
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        isOpen={isModalOpen}
        padding="sm"
        showClose={true}
        onClose={() => setIsModalOpen(false)}
      >
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'Header' does not exist on type 'typeof M... Remove this comment to see the full error message */}
        <Modal.Header title="Download TPN Report" />
        <Modal.Body
          actions={
            isLoading
              ? []
              : [
                  {
                    label: 'Download',
                    onClick: () =>
                      downloadReport(
                        tpnReport?.action({
                          ...tpnReport.actionArgs,
                          dateParams: { start: selectedStartDate, end: selectedEndDate },
                        }),
                      ),
                  },
                ]
          }
        >
          <DimmerLoader loading={isLoading} fadeStyle="flat" bgColor="white">
            <>
              <p>Select a date range and click the button below to download a CSV version of this report.</p>
              <DateRangePicker
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                onChange={(date: $TSFixMe, isStartDate: boolean) => {
                  isStartDate
                    ? setSelectedStartDate(format(new Date(date), dateFormat))
                    : setSelectedEndDate(format(new Date(date), dateFormat))
                }}
                inline={false}
              />
            </>
          </DimmerLoader>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DownloadTPNReport

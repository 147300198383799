import React from 'react'

const SvgSend = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M5.923 13.37c-.51-.205-.504-.51.034-.689l19.086-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.475.553-.717.07L15 17l-9.077-3.63zm4.89-.2l5.636 2.255 3.04 6.082 3.546-12.41-12.223 4.073h.001z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgSend

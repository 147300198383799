import React, { forwardRef } from 'react'
import { $TSFixMe } from 'types/ts-migrate'

type Props = {
  id?: string
  disabled?: boolean
  name?: string
  type: 'email' | 'hidden' | 'number' | 'password' | 'tel' | 'text' | 'url'
  value?: number | string
  placeholder?: string
  autoComplete?: 'on' | 'off'
  onChange?: (e: $TSFixMe) => void
  onBlur?: (e: $TSFixMe) => void
  onClick?: (e: $TSFixMe) => void
  onFocus?: (e: $TSFixMe) => void
}

export type Ref = HTMLInputElement

const TextInput = forwardRef<Ref, Props>((props, ref) => (
  <input
    id={props.id}
    disabled={props.disabled}
    name={props.name}
    type={props.type}
    value={props.value}
    placeholder={props.placeholder}
    onChange={props.onChange}
    onClick={props.onClick}
    onFocus={props.onFocus}
    onBlur={props.onBlur}
    autoComplete={props.autoComplete}
    ref={ref}
  />
))

TextInput.displayName = 'TextInput'

export default TextInput

import React from 'react'
import { connect } from 'react-redux'
import { partyApiSelectors } from '../../../modules/api/party'
import Search from '../../components/molecules/Search/Search'
import { partySearchSelectors, partySearchEvents } from '../../../modules/ui/PartySearch'
import debounce from 'lodash-es/debounce'
import { reject, pipe, insert, map, addIndex, flatten, filter, groupBy, values } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import PortfolioStatusLabel from 'modules/portfolios/components/PortfolioStatusLabel'
import { portfolioApiSelectors } from 'modules/api/portfolio'

const debouncedPartySearch = debounce((dispatch: any, query: any, tags: any) => {
  dispatch(partySearchEvents.partySearchQuery({ query, tags }))
}, 500)

const debouncedPartySearchWithPortfolioStatus = debounce((dispatch: any, query: any, tags: any) => {
  dispatch(partySearchEvents.partySearchWithPortfolioStatus({ query, tags }))
}, 500)

const mapStateToProps = (state: any, ownProps: any): $TSFixMe => {
  const {
    name,
    value,
    tags,
    splitByTag,
    splitByAccount,
    placeholder = 'Search parties...',
    orderByTags,
    limitResults,
    includePortfolioStatus = false,
    includeIdentification = false,
    ...otherProps
  } = ownProps

  const isLoading = partySearchSelectors.isSearching(state)
  const searchTerm = partySearchSelectors.getSearchQueryWithTags(state)(tags)
  let hasIdentification = false

  let resultSets = splitByTag
    ? partyApiSelectors.partiesSearchResultsSplitByTag(state)(searchTerm, tags.split(','))
    : splitByAccount
    ? partyApiSelectors.partiesSearchResultsSplitByAccount(state)(searchTerm)
    : partyApiSelectors
        .partiesForSearchTerm(state)(searchTerm)
        .filter(resultSet => resultSet.length !== 0)

  if (limitResults) {
    resultSets = [
      pipe(
        // @ts-expect-error
        flatten(),
        filter((p: any) => (limitResults === 'person' ? p.partyType === 0 : p.partyType === 1)),
      )(resultSets),
    ]
  }

  if (orderByTags) {
    if (!searchTerm) {
      resultSets = []
    } else {
      const orderByTagsResult = partyApiSelectors.partiesSearchResultsByTags(state)(searchTerm, orderByTags.split(','))

      const agentsAndAgenciesIds = orderByTagsResult.map((a: any) => a.id)
      const mapIndexed = addIndex(map)

      if (orderByTagsResult.length > 0) {
        resultSets = pipe(
          insert(0, orderByTagsResult),
          mapIndexed((resultSet: any, i: any) => {
            return i > 0 ? reject((party: any) => agentsAndAgenciesIds.includes(party.id), resultSet) : resultSet
          }),
          reject((resultSet: any) => resultSet.length === 0),
        )(resultSets)
      }
    }
  }

  if (includePortfolioStatus) {
    const statusOrder = ['active', 'expiring', 'renewal', 'draft', 'expired', 'terminated', 'deleted', 'undefined']
    const getResultStatus = (result: $TSFixMe) => {
      const portfolioId = result?.portfolioId
      return portfolioId && portfolioApiSelectors.getIndependantStatusByPortfolioId(state)(portfolioId).toLowerCase()
    }

    resultSets = resultSets.map((resultSet: $TSFixMe) => {
      const groupedWalletsByStatus = groupBy(getResultStatus, resultSet)
      const sortedResultSet = statusOrder
        .map(status => groupedWalletsByStatus[status || ''])
        .filter(Boolean)
        .flat()

      return sortedResultSet.map((result: $TSFixMe) => {
        const portfolioStatusLabel = getResultStatus(result)
        const portfolioStatus = <PortfolioStatusLabel status={portfolioStatusLabel} />
        return { ...result, children: portfolioStatusLabel ? portfolioStatus : null }
      })
    })
  }

  if (includeIdentification) {
    hasIdentification = true
    resultSets = resultSets.map((resultSet: $TSFixMe) => {
      return resultSet.map((result: $TSFixMe) => {
        const idOrPassportNumber = partyApiSelectors.getPersonsIdNumber(state)(result.id)
          ? partyApiSelectors.getPersonsIdNumber(state)(result.id)
          : partyApiSelectors.getPersonsPassportNumber(state)(result.id)

        return {
          ...result,
          children:
            partyApiSelectors.getPartyTypeById(state)(result.id) === 'person' ? (
              <div className="children">{idOrPassportNumber}</div>
            ) : (
              <span className="children">{partyApiSelectors.getPartyCompanyRegistration(state)(result.id)}</span>
            ),
        }
      })
    })
  }

  return { hasIdentification, name, isLoading, resultSets, placeholder, tags, value, ...otherProps }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSearchChange: (e: any) => {
    if (typeof ownProps.onChange === 'function') {
      ownProps.onChange(e)
    }
    if (ownProps.includePortfolioStatus) {
      // Dispatch custom search function that fetches portfolio from party search results
      debouncedPartySearchWithPortfolioStatus(dispatch, e.target.value, ownProps.tags)
    } else {
      debouncedPartySearch(dispatch, e.target.value, ownProps.tags)
    }
  },

  onResultSelect: (e: any, { result }: any) => {
    if (typeof ownProps.onResultSelect === 'function') {
      ownProps.onResultSelect(result)
    }
  },

  onFocus: () => {
    if (ownProps.value && ownProps.value.length > 0) {
      debouncedPartySearch(dispatch, ownProps.value, ownProps.tags)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)

import { AnyAction } from 'redux'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'
import { userApiSelectors } from '../../api/user'

export const filterCurrentAgencyId =
  (state$: any) =>
  (input$: Observable<AnyAction>): typeof input$ =>
    input$.pipe(
      filter(() => {
        return !!userApiSelectors.getCurrentAgencyId(state$.value)
      }),
    )

export const filterOwnerRole = (state$: any) => (input$: any) =>
  input$.pipe(
    filter(() => {
      return userApiSelectors.getUserRole(state$.value) === 'Owner'
    }),
  )

export const filterOnboardedAndOwner = (state$: any) => (input$: any) =>
  input$.pipe(filterCurrentAgencyId(state$), filterOwnerRole(state$))

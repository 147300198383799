import { filter, map, path, pipe, sum } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import * as yup from 'yup'
import { formatCurrency } from 'utils/currency'
import { commissionUndertakings, leaseTypes } from '../../api/portfolio/constants'

// @ts-expect-error
const getSplitPercentageTotal: $TSFixMe = pipe(map(path(['splitPercentage'])), sum)

export const buildCommissionValidationObject = (
  name: string,
  frequency = ['monthly', 'once-off'],
  leaseType = leaseTypes.managed,
) =>
  yup.object().shape({
    type: yup.string().oneOf([commissionUndertakings.fixed, commissionUndertakings.variable]).required('required.'),
    monthlyRentAmount: yup.number().required('required.'),
    netAmount: yup.object().test(`${name}.netAmount`, function (val) {
      if (val) {
        const { monthlyRentAmount, paymentRulesTotal = 0, paymentRulesTotalExCommission = 0 } = this.parent
        if (val.value > this.parent.monthlyRentAmount && leaseType === leaseTypes.managed) {
          return this.createError({
            path: this.path,
            message: `Commission cannot exceed the monthly rental amount of ${formatCurrency(monthlyRentAmount)}`,
          })
        }
        if (paymentRulesTotal > monthlyRentAmount && leaseType === leaseTypes.managed) {
          return this.createError({
            path: this.path,
            message: `Commission cannot exceed ${formatCurrency(
              monthlyRentAmount - paymentRulesTotalExCommission,
            )}, the remaining funds after rental payments`,
          })
        }
      }
      return true
    }),
    percentage: yup.object().test(`${name}.percentage`, function (val) {
      if (val) {
        const { monthlyRentAmount, paymentRulesTotal = 0, paymentRulesTotalExCommission = 0 } = this.parent
        if (paymentRulesTotal > monthlyRentAmount) {
          return this.createError({
            path: this.path,
            message: `Commission cannot exceed ${formatCurrency(
              monthlyRentAmount - paymentRulesTotalExCommission,
            )}, the remaining funds after rental payments`,
          })
        }
      }
      return true
    }),
    frequency: yup.mixed().oneOf(frequency),
    splits: yup
      .array()
      .of(
        yup.object().shape({
          agent: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
          }),
          split: yup.number(),
        }),
      )
      .test(`${name}.splits`, function (splits: $TSFixMe) {
        if (this.parent?.netAmount?.value > 0 || this.parent?.percentage?.value > 0) {
          const splitPercentageTotal: number = getSplitPercentageTotal(splits)
          if (splitPercentageTotal > 100) {
            return this.createError({
              path: this.path,
              message: "Commission splits can't exceed a total of 100%",
            })
          }
          if (splitPercentageTotal < 100) {
            return this.createError({
              path: this.path,
              message: 'Commission splits must add up to 100%',
            })
          }
          const noPercentageSplits = filter((split: $TSFixMe) => !split.splitPercentage, splits)
          if (noPercentageSplits.length > 0) {
            return this.createError({
              path: this.path,
              message: 'All commission splits must have a value',
            })
          }
          const noAgentSplits = filter((split: $TSFixMe) => !split.agent.value || !split.agent.label, splits)
          if (noAgentSplits.length > 0) {
            return this.createError({
              path: this.path,
              message: 'All commission splits must have an agent',
            })
          }
          const agentIds = splits.map((split: $TSFixMe) => split.agent.value)
          const hasDuplicateAgents = new Set(agentIds).size !== agentIds.length
          if (hasDuplicateAgents) {
            return this.createError({
              path: this.path,
              message: 'Duplicate agents are not allowed',
            })
          }
        }
        return true
      }),
  })

import React from 'react'
import { subscribe } from 'react-contextual'
import { format } from 'date-fns'
import { Button, CurrencyText } from '../../../../../../views/components'
import ContactsProvider from '../../../../state/ContactsProvider'
import RecentActivityProvider from './RecentActivityProvider'
import styles from './RecentActivity.module.scss'

const RecentActivity = ({ recentActivity, accountId, openContactStatement }: any) => {
  const limitedRecentActivity = recentActivity.slice(0, 10)
  return limitedRecentActivity.length > 0 ? (
    <section className={styles.root}>
      <header>
        <span>Recent Activity</span>
        <Button pill tertiary size="sm" onClick={() => openContactStatement(accountId)}>
          View statement
        </Button>
      </header>
      <ul>
        {limitedRecentActivity.map((ra: any, i: any) => (
          <li key={i}>
            <span>{format(new Date(ra.date), 'dd MMM')}</span>
            <span>{ra.description}</span>
            <span>
              <CurrencyText>{ra.amount}</CurrencyText>
            </span>
          </li>
        ))}
      </ul>
    </section>
  ) : (
    <section>
      <header className={styles['view-statement-only']}>
        <Button pill tertiary size="sm" onClick={() => openContactStatement(accountId)}>
          View statement
        </Button>
      </header>
    </section>
  )
}

export default subscribe(
  [RecentActivityProvider, ContactsProvider],
  ({ recentActivity, accountId }: any, { openContactStatement }: any) => ({
    recentActivity,
    accountId,
    openContactStatement,
  }),
)(RecentActivity)

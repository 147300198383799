import { apiStateSelectors } from 'modules/api/apiState'
import { emailApiEvents } from 'modules/api/email/state'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'views/components'
import styles from './DownloadInvoicePdfButton.module.scss'

type Props = {
  invoiceId: string
  invoiceNumber: string
}

const DownloadInvoicePdfButton = ({ invoiceId, invoiceNumber }: Props) => {
  const dispatch = useDispatch()
  const handleDownload = useCallback(() => {
    dispatch(emailApiEvents.invoicePdf_request({ invoiceId, invoiceNumber }))
  }, [invoiceId, dispatch])

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([emailApiEvents.invoicePdf_request]))

  return (
    <Button loading={isLoading} ghost className={styles.root} onClick={handleDownload}>
      Download Invoice PDF
    </Button>
  )
}

export default DownloadInvoicePdfButton

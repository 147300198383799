import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Svg } from '../../../../../components'
import styles from './ThemeSelector.module.scss'

const propTypes = {
  themes: PropTypes.array.isRequired,
  currentTheme: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

const defaultProps = {
  onChange: (theme: any) => {},
}

const ThemeSelector = ({ themes, currentTheme, onChange, error }: any) => {
  const classes = classNames(styles.root, {
    [styles['has-error']]: !!error,
  })
  return (
    <div className={classes}>
      <h3>Select a color theme</h3>
      {themes.length > 0 && (
        <div className={styles.themes}>
          {themes.map((theme: any, i: any) => (
            <button
              key={i}
              type="button"
              onClick={onChange.bind(null, theme)}
              style={{ backgroundColor: theme.color }}
              className={currentTheme && theme.name === currentTheme.name && styles['active-theme']}
            >
              {currentTheme && theme.name === currentTheme.name && (
                <React.Fragment>
                  <Svg name="water-drop" style={{ fontColor: `${theme.fontColor}` }} />
                </React.Fragment>
              )}
              {theme.name}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

ThemeSelector.propTypes = propTypes
ThemeSelector.defaultProps = defaultProps

export default ThemeSelector

import { useMedia } from 'react-use'
import { $TSFixMe } from 'types/ts-migrate'

function useBreakPoints(): $TSFixMe {
  const breakpoints = { xs: 320, sm: 640, md: 767, lg: 1023, xl: 1280, xxl: 1440, xxxl: 1600 }
  const isMobile = useMedia(`(max-width: ${breakpoints.lg}px)`)

  return {
    breakpoints,
    isMobile,
  }
}

export default useBreakPoints

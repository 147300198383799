import createSelector from 'selectorator'
import { path, identity } from 'ramda'
import { NAMESPACE } from './constants'
import { RootState } from 'src/state/store'
import { TAccountingInitialState } from './state'
import { JsonResponse } from './types/JsonResponse'
import { Base64PdfResponse } from './types/Base64PdfResponse'

type TAccountingSliceKeys = keyof TAccountingInitialState
type TAccountingSlice = TAccountingInitialState[keyof TAccountingInitialState]
// const getSlice = (key: TAccountingSliceKeys) =>
//   createSelector<RootState, TAccountingSlice>([`${NAMESPACE}.${key}`], identity)

const getSlice = (key: TAccountingSliceKeys) =>
  createSelector<RootState, TAccountingSlice>([`${NAMESPACE}.${key}`], identity)

export const getBankStatementJson = getSlice('bankStatementJson')
export const getBankStatementPdf = getSlice('bankStatementPdf')

// not used at the moment
// export const getCustomerStatements = getSlice('customerStatements')

export const getCustomerStatementJson = getSlice('customerStatementJson')
export const getCustomerStatementPdf = getSlice('customerStatementPdf')
export const getPortfolioStatements = getSlice('portfolioStatementJson')
export const getPortfolioStatementPdf = getSlice('portfolioStatementPdf')

/** @todo rename these to `customer&*` when introducing general deposit statement */
export const getDepositStatementJson = getSlice('depositStatementJson')
export const getDepositStatementPdf = getSlice('depositStatementPdf')

export const getDepositBankStatementJson = getSlice('depositBankStatementJson')
export const getDepositBankStatementPdf = getSlice('depositBankStatementPdf')

export const getCommissionStatementJson = getSlice('commissionStatementJson')
export const getCommissionStatementPdf = getSlice('commissionStatementPdf')
export const getCommissionStatementCsv = getSlice('commissionStatementCsv')

// not used at the moment
// export const getStatementById = createSelector(
//   [getCustomerStatements],
//   statements => (id: any) => statements.find((stmt: any) => stmt.id === id),
// )

export const getCustomerStatementJsonById = createSelector<
  RootState,
  (customerId: string, accountId: string) => JsonResponse | undefined
>([getCustomerStatementJson], s => (customerId, accountId) => path([`${customerId}${accountId}`], s))

export const getCustomerStatementPdfById = createSelector<
  RootState,
  (customerId: string, accountId: string) => Base64PdfResponse | undefined
>([getCustomerStatementPdf], s => (customerId, accountId) => path([`${customerId}${accountId}`], s))

export const getPortfolioStatementById = createSelector<RootState, (id: string) => JsonResponse | undefined>(
  [getPortfolioStatements],
  s => id => path([id], s),
)

export const getPortfolioStatementPdfById = createSelector<RootState, (id: string) => Base64PdfResponse | undefined>(
  [getPortfolioStatementPdf],
  s => id => path([id], s),
)

export const getDepositStatementJsonById = createSelector<RootState, (id: string) => JsonResponse | undefined>(
  [getDepositStatementJson],
  s => id => path([id], s),
)

export const getDepositStatementPdfById = createSelector<RootState, (id: string) => Base64PdfResponse | undefined>(
  [getDepositStatementPdf],
  s => id => path([id], s),
)

export const getCommissionStatementJsonById = createSelector<RootState, (id: string) => JsonResponse | undefined>(
  [getCommissionStatementJson],
  s => id => path([id], s),
)

export const getStats = getSlice('stats')

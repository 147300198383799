import React, { Component } from 'react'
import PropTypes from 'prop-types'
import request from 'superagent'
import { Formik } from 'formik'
import * as yup from 'yup'
import { pathOr, isEmpty } from 'ramda'
import { Helmet } from 'react-helmet'
import { CLOUDINARY_UPLOAD_PRESET, CLOUDINARY_UPLOAD_URL } from 'constants/general'
import { Header, FormLoader } from '../../../../components'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import LogoEditor from './LogoEditor/LogoEditor'
import ThemeSelector from './ThemeSelector/ThemeSelector'
import ThemePreview from './ThemePreview'
import styles from './OnboardingBrandingForm.module.scss'
import Paragraph from '../../../atoms/Typography/Paragraph/Paragraph'
import { agencyApiEvents } from '../../../../../modules/api/agency'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  getThemes: PropTypes.func.isRequired,
  themes: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
}

const defaultProps = {}

const validationSchema = yup.object().shape({
  logo: yup.string().required('Logo is required, please upload and save.'),
  theme: yup.object().required('required.'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <section className={styles['button-container']}>{children}</section>
)
class OnboardingBrandingForm extends Component<$TSFixMe, $TSFixMe> {
  constructor(props: any) {
    super(props)

    this.state = {
      uploadedFile: null,
      uploadedFileCloudinaryUrl: '',
      cropZoom: 1,
      position: { x: 0.5, y: 0.5 },
      editing: false,
      uploadProgress: null,
    }
    this.handleImageDrop = this.handleImageDrop.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleZoomChange = this.handleZoomChange.bind(this)
    this.handlePositionChange = this.handlePositionChange.bind(this)
  }

  componentDidMount(): void {
    const { getThemes } = this.props
    getThemes()
  }

  handleImageDrop(files: any): void {
    this.setState({
      uploadedFile: files[0],
    })
  }

  handleClear(): void {
    this.setState({ uploadedFile: null })
  }

  /**
   * @todo replace superagent with rxjs Ajax
   */
  handleSave(editor: any, setFieldValue: any): void {
    if (editor) {
      this.setState({ uploadProgress: 1 })
      const { uploadedFile } = this.state
      const canvas = editor.getImageScaledToCanvas()
      canvas.toBlob((blob: any) => {
        const file = new File([blob], uploadedFile.name, { type: uploadedFile.type, lastModified: Date.now() })

        const upload = request
          .post(CLOUDINARY_UPLOAD_URL)
          .field('upload_preset', CLOUDINARY_UPLOAD_PRESET)
          .field('file', file)
          .on('progress', (e: any) => this.setState({ uploadProgress: e.percent }))

        upload.end((err: any, response: any) => {
          if (err) {
            console.error('err', err)
          }

          if (response.body.secure_url !== '') {
            this.setState({
              editing: false,
              uploadProgress: null,
              uploadedFileCloudinaryUrl: response.body.secure_url,
            })
            setFieldValue('logo', response.body.secure_url)
          }
        })
      })
    }
  }

  handleEdit(): void {
    this.setState({ editing: true })
  }

  handleZoomChange(zoom: any): void {
    this.setState({ cropZoom: zoom / 25 })
  }

  handlePositionChange(position: any): void {
    this.setState({ position })
  }

  getFieldError = (path: string): string =>
    // @ts-expect-error
    pathOr('', `touched.${path}`, this.props) && pathOr('', `errors.${path}`, this.props)

  render(): null | React.ReactElement {
    const { editing, uploadedFile, uploadedFileCloudinaryUrl, cropZoom, position, uploadProgress } = this.state

    const {
      themes,
      isSubmitting,
      isLoading,
      handleSubmit,
      initialValues,
      getGeneralFormErrorsByEvent,
      getFormFieldErrorsByEvent,
    } = this.props

    return (
      <Formik
        validationSchema={validationSchema}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          const submitEvent = agencyApiEvents.updateBranding_request(values)
          const generalErrors = getGeneralFormErrorsByEvent(submitEvent)
          const fieldErrors = getFormFieldErrorsByEvent(submitEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isLoading
            ? 'loading'
            : isSubmitting
            ? 'submitting'
            : !isEmpty(errors) || hasApiErrors
            ? 'error'
            : undefined

          return (
            <FormLoader
              onSubmit={handleSubmit}
              state={formLoaderState}
              buttonProps={{ children: 'Save' }}
              ButtonContainer={ButtonContainer}
            >
              <Helmet>
                <title>reOS | Settings | Branding</title>
              </Helmet>
              <section>
                <div className={styles.container}>
                  <Header tag="h1" text="Customise Branding" />
                  <Paragraph size="body1">
                    reOS helps you turn every interaction into a brand moment. Add your logo and select a colour theme
                    to give your brand the credit it deserves.
                  </Paragraph>
                </div>
              </section>

              <section>
                <div className={styles.container}>
                  {this.getFieldError('logo') && <p className={styles.errors}>Logo is {this.getFieldError('logo')}</p>}
                  <LogoEditor
                    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                    uploadProgress={uploadProgress}
                    uploadedFileCloudinaryUrl={values.logo || uploadedFileCloudinaryUrl}
                    uploadedFile={uploadedFile}
                    editing={editing}
                    position={position}
                    cropZoom={cropZoom}
                    cloudinaryUploadPreset={CLOUDINARY_UPLOAD_PRESET}
                    onPositionChange={this.handlePositionChange}
                    onImageDrop={this.handleImageDrop}
                    onEdit={this.handleEdit}
                    onClear={this.handleClear}
                    onSave={this.handleSave}
                    onZoomChange={this.handleZoomChange}
                    setFieldValue={setFieldValue}
                    error={this.getFieldError('logo')}
                  />
                </div>
              </section>

              <section>
                <div className={styles.container}>
                  <ThemeSelector
                    themes={themes}
                    currentTheme={values.theme}
                    onChange={theme => setFieldValue('theme', theme)}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ themes: any; currentTheme: any; onChange: ... Remove this comment to see the full error message
                    onClear={this.handleClear}
                    error={this.getFieldError('theme')}
                  />
                  <div className={styles['theme-preview']}>
                    <ThemePreview
                      color={pathOr('rgba(0, 0, 0, 1.0)', ['theme', 'primary'], values)}
                      name={pathOr('Black', ['theme', 'name'], values)}
                    />
                  </div>
                </div>
                <FormErrors errors={[...generalErrors, ...Object.values(errors)]} />
              </section>
            </FormLoader>
          )
        }}
      </Formik>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
OnboardingBrandingForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
OnboardingBrandingForm.defaultProps = defaultProps

export default OnboardingBrandingForm

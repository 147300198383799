import React from 'react'
import { format } from 'date-fns'
import { AggregateListItem, Divider, List } from '../../../../../views/components'

const NextSteps = ({ oldLeaseEndDate, newLeaseStartDate, newLeaseEndDate }: any) => {
  const nextSteps = [
    {
      mainText: `Old lease expires on ${format(new Date(oldLeaseEndDate), 'dd MMMM yyyy')}`,
      checked: true,
    },
    {
      mainText: `New lease starts on ${format(new Date(newLeaseStartDate), 'dd MMMM yyyy')}`,
      checked: true,
    },
    {
      mainText: `New lease expires on ${format(new Date(newLeaseEndDate), 'dd MMMM yyyy')}`,
      checked: true,
    },
  ]

  return (
    <>
      <Divider>
        <h3>What happens next?</h3>
      </Divider>
      <List type="checklist">
        {nextSteps.map((item, i) => (
          <AggregateListItem key={i} active={!!item.checked} text={item.mainText} />
        ))}
      </List>
    </>
  )
}

export default NextSteps

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchQuery: '',
  depositTransferModal: false,
  isDepositTopupRequestOpen: false,
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    init: () => {},
    searchQueryUpdated: (state, { payload }) => {
      state.searchQuery = payload
    },
    openDepositTransferModal: (state, { payload }) => {
      state.depositTransferModal = payload
    },
    closeDepositTransferModal: state => {
      state.depositTransferModal = false
    },
    depositTopupRequestOpened: state => {
      state.isDepositTopupRequestOpen = true
    },
    depositTopupRequestClosed: state => {
      state.isDepositTopupRequestOpen = false
    },
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = contactsSlice

export { events, reducer }

import React from 'react'
import { subscribe } from 'react-contextual'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import {
  splitEvery,
  map,
  head,
  pipe,
  groupBy,
  splitAt,
  values,
  path,
  sortBy,
  pathOr,
  reduce,
  evolve,
  add,
  tail,
} from 'ramda'
import { DimmerLoader } from '../../../../views/components'
import SkeletonChart from '../SkeletonChart/SkeletonChart'
import { DashboardProvider, DashboardChart } from '../..'
import { apiStateSelectors } from '../../../api/apiState'
import { reportingApiEvents } from '../../../api/reporting'
import { useEffectOnce } from 'react-use'
import CommissionChart from '../CommissionChart/CommissionChart'
import { userApiSelectors } from '../../../api/user'
import { $TSFixMe } from 'types/ts-migrate'

const getChartData = (data: any) => {
  let chartData: $TSFixMe = []

  const sumData = map((vals: any) =>
    reduce(
      (current: any, val: any) =>
        evolve(
          {
            collected: add(pathOr(0, ['collected'], val)),
            commission: add(pathOr(0, ['commission'], val)),
            invoiced: add(pathOr(0, ['invoiced'], val)),
            lease: () => val.leaseSummary,
          },
          current,
        ),
      // @ts-expect-error
      head(vals),
      tail(vals),
    ),
  )

  if (data) {
    let dataPoints: $TSFixMe = []

    if (data.length <= 30) {
      dataPoints = pipe(
        // @ts-expect-error
        sortBy(path(['timestamp'])),
      )(data)
    } else if (data.length > 30 && data.length <= 90) {
      dataPoints = pipe(
        // @ts-expect-error
        sortBy(path(['timestamp'])),
        splitEvery(7),
        sumData,
      )(data)
    } else {
      dataPoints = pipe(
        // @ts-expect-error
        sortBy(path(['timestamp'])),
        // @ts-expect-error
        groupBy((d: any) => splitAt(7, d.timestamp)[0]),
        values,
        sumData,
      )(data)
    }

    chartData = dataPoints.map((month: any) => ({
      name: format(new Date(month.timestamp), 'MMM'),
      commission: month.commission,
      invoiced: month.invoiced,
      collected: month.collected,
      activeLeases: month.leaseSummary?.activeCount || 0,
    }))
  }

  return chartData
}

const DashboardPerformanceSummary = ({
  monthlyAgencyPerformanceSummaryChart,
  fetchAgencyPerformanceSummaryChart,
  redirect,
}: any) => {
  useEffectOnce(() => {
    fetchAgencyPerformanceSummaryChart()
  })

  const hasAgency = useSelector(userApiSelectors.getCurrentAgencyId) !== null

  const transformedData = monthlyAgencyPerformanceSummaryChart.map((d: $TSFixMe) => ({
    ...d,
    invoiced: d.invoiced || 0,
    collected: d.collected || 0,
    commission: d.commission || 0,
  }))
  const chartData = getChartData(transformedData)

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([reportingApiEvents.agencyPerformanceSummaryChart_request]),
  )

  return (
    <>
      <DimmerLoader loading={hasAgency && isLoading} bgColor="appBg">
        {!isLoading && chartData.length > 0 ? (
          <>
            <DashboardChart data={chartData} />
          </>
        ) : (
          <SkeletonChart onClick={() => redirect('/leases/new/edit')} />
        )}
      </DimmerLoader>
      <DimmerLoader loading={hasAgency && isLoading} bgColor="appBg">
        {!isLoading && chartData.length > 0 ? (
          <>
            <CommissionChart data={chartData} />
          </>
        ) : (
          <SkeletonChart onClick={() => redirect('/leases/new/edit')} />
        )}
      </DimmerLoader>
    </>
  )
}

export default subscribe(
  [DashboardProvider],
  ({ monthlyAgencyPerformanceSummaryChart, fetchAgencyPerformanceSummaryChart, redirect }: any) => ({
    monthlyAgencyPerformanceSummaryChart,
    fetchAgencyPerformanceSummaryChart,
    redirect,
  }),
)(DashboardPerformanceSummary)

import createSelector from 'selectorator'
import { identity, path, pathOr, map, find, pipe, head, filter } from 'ramda'
import { format, isValid } from 'date-fns'
import { NAMESPACE } from './constants'
import * as utils from './utils'
import { dateUtils } from '../../../utils'
import { TApplication, TApplications } from './types'
import { userApiSelectors } from '../user'
import { $TSFixMe } from 'types/ts-migrate'
import { RootState } from 'src/state/store'

/**
 * Get a slice of the portfolios API state
 * EG: getSlice('byId')
 *
 * @param {string} key Portfolios API state object key
 */
export const getSlice = (key: string): $TSFixMe => createSelector([`api.${NAMESPACE}.${key}`], identity)

export const getPropertySlice = (key: string): $TSFixMe => createSelector([`api.property.${key}`], identity)

/**
 * Get a list of portfolio IDs
 *
 * @returns {Array} List of portfolio Ids
 */
export const getPortfolioIds = getSlice('allIds')

/**
 * Get a list of portfolio objects
 * Correctly orders portfolios based on API response using `allIds` index
 *
 * @returns {Array} List of portfolio objects
 */
export const getPortfolios = createSelector([identity], state =>
  map((id: any) => path([id], getSlice('byId')(state)), getPortfolioIds(state)),
)

/**
 * Get a list of portfolios with a specific property ID
 *
 * @returns {Array} List of portfolio objects
 */
export const getPortfoliosByPropertyId = createSelector(
  [identity],
  state => (propertyId: any) =>
    filter((portfolio: any) => path(['propertyId'], portfolio) === propertyId, getPortfolios(state)),
)

/**
 * Get a portfolio object by ID
 *
 * @returns {object|undefined}
 */
export const getPortfolioById = createSelector([getSlice('byId')], portfolios => (id: any) => path([id], portfolios))

export const getStats = getSlice('stats')

/**
 * Get a portfolio object field by ID
 * EG: `getPortfolioFieldById(state)(id, ['landlords', 0, 'partyId])
 *
 * @returns {any|undefined}
 */
export const getPortfolioFieldById = createSelector(
  [identity],
  state =>
    (id: any, path: any, defaultValue: $TSFixMe = undefined) =>
      pathOr(defaultValue, path, getPortfolioById(state)(id)),
)

/**
 * Get a portfolio monthly rent amount
 * @todo add test
 *
 * @returns {number}
 */
export const getMonthlyRentAmount = createSelector(
  [identity],
  state => (id: any) =>
    getPortfolioFieldById(state)(id, ['contractContainer', 'value', 'terms', 'monthlyRentAmount'], 0),
)

/**
 * Get a portfolio's propertyId
 *
 * @returns {(string|undefined)} Property ID or undefined
 */
export const getPropertyIdByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioFieldById(state)(id, ['propertyId']),
)

/**
 * Get a portfolio's landlord and tenant `partyId`
 *
 * @returns {Array}
 */
export const selectLandordAndTenantIdsFromPortfolio = createSelector([identity], state => (id: any) => {
  const portfolio = getPortfolioById(state)(id)
  return utils.getTenantAndLandlordIdsFromPortfolio(portfolio)
})

/**
 * Get invoice schedules slice
 *
 * @returns {object}
 */
export const getInvoiceSchedules = getSlice('invoiceSchedules')

/**
 * Get invoice schedules for a portfolio
 *
 * @returns {Array}
 */
export const getInvoiceSchedulesByPortfolioId: $TSFixMe = createSelector(
  [getInvoiceSchedules],
  (schedules: $TSFixMe) => (id: any) => pathOr([], [id], schedules),
)

/**
 * Applications
 */

const getApplications = getSlice('applications')

/**
 * Get a portfolio's applications in portfolio.applications
 */
export const getApplicationsByPortfolioId = createSelector(
  [getApplications],
  applications =>
    (id: string): TApplication[] =>
      applications?.[id] || [],
)

/**
 * Get a portfolio's accepted application in portfolio.applications.parties
 */
export const getAcceptedApplicationByPortfolioId = createSelector<RootState, (id: string) => TApplication | undefined>(
  [identity],
  state => (id: any) => {
    const applications = getApplicationsByPortfolioId(state)(id)
    return applications?.find((app: TApplication) => app.status === 'Accepted')
  },
)

export const getApplicationByPartyId = createSelector<
  RootState,
  (id: string, partyId: string) => TApplication | undefined
>([identity], state => (id, partyId) => {
  const applications = getApplicationsByPortfolioId(state)(id)
  return applications?.find((app: TApplication) => app.partyId === partyId)
})

/**
 * Get a portfolio's owners in portfolio.parties.owners
 */
export const getOwnersByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioFieldById(state)(id, ['parties', 'owners'], {}),
)

/**
 * Get a portfolio's landlord `partyId` by portfolio ID
 *
 * @deprecated use getPrimaryLandlordByPortfolioId
 * @returns {string}
 */
export const getLandlordByPortfolioId = createSelector([identity], state => (id: any) => {
  const owners = getPortfolioFieldById(state)(id, ['parties', 'owners'], {})
  return pathOr(null, ['primary', 'partyId'], owners)
})

/**
 * Get all a portfolio's landlord IDs by portfolio ID
 *
 * @returns {Array}
 */
export const getAllOwnerPartyIdsByPortfolioId = createSelector([identity], state => (id: any) => {
  const landlords = getPortfolioFieldById(state)(id, ['parties', 'owners'], {})
  return utils.getAllPartyIds(landlords)
})

/**
 * Get portfolio's primary landlord by portfolio ID
 *
 * @returns {Array}
 */
export const getPrimaryLandlordByPortfolioId = createSelector([identity], state => (id: any): string | null => {
  const owners = getPortfolioFieldById(state)(id, ['parties', 'owners'], {})
  return pathOr(null, ['primary', 'partyId'], owners)
})

/**
 * Get all a portfolio's applications IDs by portfolio ID
 *
 * @returns {Array}
 */
export const getAllApplicationsByPortfolioId = createSelector([identity], state => (id: any) => {
  const applications = getApplicationsByPortfolioId(state)(id)
  return applications
})

/**
 * Get a portfolio's tenants in portfolio.parties.tenants
 */
export const getTenantsByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioFieldById(state)(id, ['parties', 'tenants'], {}),
)

/**
 * Get a portfolio's tenant `partyId` by portfolio ID
 *
 * @deprecated use getPrimaryTenantByPortfolioId
 * @returns {string}
 */
export const getTenantByPortfolioId = createSelector([identity], state => (id: any): string | null => {
  const tenants = getPortfolioFieldById(state)(id, ['parties', 'tenants'], {})
  return pathOr(null, ['primary', 'partyId'], tenants)
})

/**
 * Get all a portfolio's tenant IDs by portfolio ID
 *
 * @returns {Array}
 */
export const getAllTenantPartyIdsByPortfolioId = createSelector([identity], state => (id: any) => {
  const tenants = getPortfolioFieldById(state)(id, ['parties', 'tenants'], {})
  return utils.getAllPartyIds(tenants)
})

/**
 * Get portfolio's primary tenant by portfolio ID
 *
 * @returns {Array}
 */
export const getPrimaryTenantByPortfolioId = createSelector([identity], state => (id: any): string | null => {
  const tenants = getPortfolioFieldById(state)(id, ['parties', 'tenants'], {})
  return pathOr(null, ['primary', 'partyId'], tenants)
})

/**
 * Get a portfolio tenant by ID
 *
 * @returns {Object} tenant object
 */
export const getTenantById = createSelector([identity], state => (portfolioId: any, tenantId: any) => {
  const tenants = getAllTenantPartyIdsByPortfolioId(state)(portfolioId)
  return find((tenant: any) => path(['id'], tenant) === tenantId)(tenants)
})

/**
 * Get a portfolio's invoice templates by portfolio ID
 *
 * @returns {Array}
 */
export const getInvoiceTemplatesByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioFieldById(state)(id, ['invoiceTemplates'], []),
)

/**
 * Get a portfolio's commission by portfolio ID
 *
 * @returns {object}
 */
export const getCommissionByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioFieldById(state)(id, ['commission'], {}),
)

/**
 * Finds a portfolio's invoice template by category
 *
 * @returns {(object|undefined)} Portfolio invoice template or undefined if not found
 */
export const getPortfolioInvoiceTemplateByCategory = createSelector(
  [identity],
  state => (id: any, category: any) =>
    find((template: any) => template.category === category, getInvoiceTemplatesByPortfolioId(state)(id)),
)

/**
 * Get a portfolio's rent invoice template
 *
 * @returns {(object|undefined)} Portfolio rent invoice template or undefined if not found
 */
export const getRentInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'RentInvoice'),
)

/**
 * Get a portfolio's lease fee invoice template
 *
 * @returns {(object|undefined)} Portfolio lease fee invoice template or undefined if not found
 */
export const getLeaseFeeInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'LeaseFee'),
)

/**
 * Get a portfolio's application fee invoice template
 *
 * @returns {(object|undefined)} Portfolio application fee invoice template or undefined if not found
 */
export const getApplicationFeeInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'ApplicationFee'),
)

/**
 * Get a portfolio's key deposit invoice template
 *
 * @returns {(object|undefined)} Portfolio key deposit invoice template or undefined if not found
 */
export const getKeyDepositInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'KeyDeposit'),
)

/**
 * Get a portfolio's service deposit invoice template
 *
 * @returns {(object|undefined)} Portfolio service deposit invoice template or undefined if not found
 */
export const getServiceDepositInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'ServiceDeposit'),
)

/**
 * Get a portfolio's damage deposit invoice template
 *
 * @returns {(object|undefined)} Portfolio damage deposit invoice template or undefined if not found
 */
export const getDamageDepositInvoiceTemplateByPortfolioId = createSelector(
  [identity],
  state => (id: any) => getPortfolioInvoiceTemplateByCategory(state)(id, 'DamageDeposit'),
)

/**
 * Get a portfolio's rent invoice schedule
 *
 * @returns {object}
 */
export const getRentTemplateScheduleByPortfolioId = createSelector([identity], state => (id: any) => {
  const rentTemplateSchedule = pipe(
    find((s: any) => s.invoiceTemplateId === path(['id'], getRentInvoiceTemplateByPortfolioId(state)(id))),
    pathOr([], ['dates']),
    head,
  )(getInvoiceSchedulesByPortfolioId(state)(id))

  return {
    sendAt: pathOr('', ['sendAt'], rentTemplateSchedule),
    dueDate: pathOr('', ['dueDate'], rentTemplateSchedule),
  }
})

/**
 * Get a portfolio's invoice templates by scheduled invoices
 *
 * @returns {Array}
 */
export const getInvoiceTemplatesByScheduledInvoices = createSelector([identity], state => (id: any) => {
  const templates: $TSFixMe = getInvoiceTemplatesByPortfolioId(state)(id)
  const schedules = getInvoiceSchedulesByPortfolioId(state)(id)
  return schedules.map((s: any) => templates.find((t: any) => s.invoiceTemplateId === t.id))
})

/**
 * Checks if a portfolio is expired
 *
 * @returns {boolean}
 */
export const isPortfolioExpired = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'expired',
)

/**
 * Checks if a portfolio is expiring soon
 *
 * @returns {boolean}
 */
export const isPortfolioExpiring = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'expiring',
)

/**
 * Check if a portfolio is a draft
 *
 * @returns {boolean}
 */
export const isDraftPortfolio = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'draft',
)

/**
 * Check if a portfolio is active
 *
 * @returns {boolean}
 */
export const isActivePortfolio = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'active',
)

/**
 * Check if a portfolio is a deleted draft
 *
 * @returns {boolean}
 */
export const isDeletedDraft = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'deleted',
)

/**
 * Check if a portfolio is terminated
 *
 * @returns {boolean}
 */
export const isPortfolioTerminated = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) =>
      getPortfolioStatusLabel(state)(id, useSummary) === 'terminated',
)

/**
 * Get portfolio status label for use in UI
 *
 * @returns {('renewal|expired'|'active'|'expiring'|'draft'|'terminated')}
 */
export const getPortfolioStatusLabel = createSelector([identity], state => (id: any, useSummary = false) => {
  const status = useSummary
    ? getSummaryFieldById(state)(id, ['status'], 'draft')
    : getPortfolioFieldById(state)(id, ['status'], 'draft')

  return status
})

export const getPortfolioStatusDescription = createSelector([identity], state => (id: any, useSummary = false) => {
  const portfolio = useSummary ? getSummaryById(state)(id) : getPortfolioById(state)(id)

  const endDate: $TSFixMe = path(['leaseTerms', 'endDate'], portfolio)
  const updatedAt: $TSFixMe = path(['updatedAt'], portfolio)

  const statusLabel: $TSFixMe = getPortfolioStatusLabel(state)(id, true)
  switch (statusLabel) {
    case 'expired':
      return `Expired ${dateUtils.timeAgo(new Date(endDate))}`
    case 'expiring':
      return `Expiring ${dateUtils.timeAgo(new Date(endDate))}`
    case 'renewal':
      return isValid(new Date(portfolio?.renewal?.leaseRenewsAt))
        ? `Renewing ${dateUtils.timeAgo(new Date(portfolio.renewal.leaseRenewsAt))}`
        : 'Renewing in ...'
    default:
      return ''
  }
})

export const getTerminatedPortfolioVacateDate = createSelector([identity], state => (id: any, useSummary = false) => {
  const portfolio = useSummary ? getSummaryById(state)(id) : getPortfolioById(state)(id)
  const vacateDate: $TSFixMe = path(['terminationFields', 'vacateDate'], portfolio)

  const statusLabel: $TSFixMe = getPortfolioStatusLabel(state)(id, true)

  if (statusLabel === 'terminated') {
    return `Vacate Date: ${dateUtils.formatStandard(new Date(vacateDate))}`
  }
})

export const getTerminatedPortfolioTerminatedReason = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) => {
      const portfolio = useSummary ? getSummaryById(state)(id) : getPortfolioById(state)(id)
      const terminatedReason: $TSFixMe = path(['terminationFields', 'reason'], portfolio)

      const statusLabel: $TSFixMe = getPortfolioStatusLabel(state)(id, true)

      if (statusLabel === 'terminated') {
        return `Termination Reason: ${terminatedReason.split(/(?=[A-Z])/).join(' ') as string}`
      }
    },
)

export const getTerminatedPortfolioTerminatedDate = createSelector(
  [identity],
  state =>
    (id: any, useSummary = false) => {
      const portfolio = useSummary ? getSummaryById(state)(id) : getPortfolioById(state)(id)
      const terminatedAt: $TSFixMe = path(['terminationFields', 'terminatedAt'], portfolio)

      const statusLabel: $TSFixMe = getPortfolioStatusLabel(state)(id, true)

      if (statusLabel === 'terminated') {
        return `Termination Date: ${dateUtils.formatStandard(new Date(terminatedAt))}`
      }
    },
)

export const getPortfolioMetaData = createSelector([identity], state => (id: any, useSummary = false) => {
  const portfolio = useSummary ? getSummaryById(state)(id) : getPortfolioById(state)(id)
  return path(['metaData'], portfolio) || ''
})

export const isPortfolioMonthToMonth = createSelector([identity], state => (id: string) => {
  const portfolio = getPortfolioById(state)(id)
  return utils.isPortfolioMonthToMonth(portfolio)
})

/**
 * Get a list of lease termination reasons
 *
 * @returns {Array}
 */
export const getLeaseTerminationReasons = getSlice('terminationReasons')

/**
 * Get Bulk Invoices CSV Template base64 string
 */
export const getBulkInvoicesCsvTemplate = getSlice('bulkInvoicesCsvTemplate')

/**
 * ----
 * Summaries
 * ----
 */

/**
 * Get a list of portfolio summary objects
 *
 * @returns {Array} List of portfolio objects summary objects
 */
export const getPortfolioSummaries = getSlice('summaries')

/**
 * Get a list of portfolio summaries filtered by currentSegments
 *
 * @returns {Array} List of portfolio summary objects
 */
export const getPortfolioSummariesFilteredByCurrentSegments = createSelector(
  [identity, getPortfolioSummaries],
  (state, summaries) => {
    const currentSegments = userApiSelectors.getCurrentSegments(state)
    const filteredSegments = filter(
      (summary: any) => filter((segment: any) => currentSegments.includes(segment))(summary.segments).length > 0,
    )(summaries)
    return filteredSegments
  },
)

/**
 * Get a portfolio summary object by ID
 *
 * @returns {object|undefined}
 */
export const getSummaryById = createSelector(
  [getSlice('summaries')],
  summaries => (id: any) => find((summary: any) => summary.portfolioId === id, summaries),
)

/**
 * Get a list of portfolios with a specific property ID
 *
 * @returns {Array} List of portfolio objects
 */
export const getSummariesByPropertyId = createSelector(
  [identity],
  state => (propertyId: any) =>
    filter((portfolio: any) => path(['propertyId'], portfolio) === propertyId, getPortfolioSummaries(state)),
)

/**
 * Get a portfolio summary object field by ID
 * EG: `getSummaryFieldById(state)(id, ['landlords', 0, 'partyId])
 *
 * @returns {any|undefined}
 */
export const getSummaryFieldById = createSelector(
  [identity],
  state =>
    (id: any, path: any, defaultValue: $TSFixMe = undefined) =>
      pathOr(defaultValue, path, getSummaryById(state)(id)),
)

export const getSegmentIdsByPortfolioId = createSelector([identity], state => (portfolioId: any) => {
  const portfolioSummary = getSummaryById(state)(portfolioId)
  const defaultSegmentId = userApiSelectors.getCurrentAgencyId(state)
  return pathOr([], ['segments'], portfolioSummary).filter((s: any) => s !== defaultSegmentId)
})

/**
 * Get a list of property summaries
 *
 * @returns {Array} List of property summary objects
 */
export const getPropertySummaries = getPropertySlice('summaries')

export const getStatuses = getSlice('status')

export const getIndependantStatusByPortfolioId = createSelector(
  [getStatuses],
  (statuses: $TSFixMe) => (portfolioId: string) => {
    const status = statuses.find(
      (status: { portfolioId: string; status: string }) => status.portfolioId === portfolioId,
    )
    return status ? status?.status : ''
  },
)

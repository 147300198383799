import { connect } from 'react-redux'
import { apiStateSelectors } from 'modules/api/apiState'
import { uiEvents } from 'modules/ui'
import { bankingApiEvents, bankingApiSelectors } from 'modules/api/banking'
import { walletApiEvents, walletApiSelectors } from 'modules/api/wallet'
import { uiPaymentsSelectors } from 'modules/ui/payments'
import PaymentsProvider from 'views/providers/PaymentsProvider'
import { path } from 'ramda'

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    isPaymentsLoading: apiStateSelectors.isLoading(state)([walletApiEvents.payments_request]),
    paymentFilters: uiPaymentsSelectors.getPaymentFilters(state),
    paymentPeriod: uiPaymentsSelectors.getPaymentPeriod(state),
    payments: walletApiSelectors.getPayments(state),
    isPopLoading: walletApiSelectors.isLoading(state)('pop_request'),

    // Payment validation
    getBankingPartyById: (id: any) => bankingApiSelectors.getBankingPartyById(state)(id),
    isPaymentValidating: apiStateSelectors.isLoading(state)([bankingApiEvents.partiesErrors_request]),
    getBankingValidationErrorsByPartyId: (partyId: any) =>
      path(['errors'], bankingApiSelectors.getBankingPartyById(state)(partyId)),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  // UI
  redirect: (route: any) => dispatch(uiEvents.redirect(route)),

  getPayments: (startDate: any, endDate: any) => {
    dispatch(walletApiEvents.payments_request({ start_date: startDate, end_date: endDate }))
  },

  reversePayments: (ids: any) => dispatch(walletApiEvents.reversePayments_request(ids)),
  demandPayments: (ids: any) => dispatch(walletApiEvents.demandPayments_request(ids)),
  sendPop: (payload: any) => dispatch(walletApiEvents.pop_request(payload)),

  // Payment validation
  fetchPartyErrors: (partyId: any) => dispatch(bankingApiEvents.partyErrors_request({ partyId })),

  fetchPartiesErrors: (partyIds: any) => dispatch(bankingApiEvents.partiesErrors_request({ party_ids: partyIds })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsProvider)

import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import decode from 'jwt-decode'
import { formUtils } from '../../../../../utils'
import { FormField, FormLoader, TextField, TextInput } from '../../../../components'
import styles from './ResetPasswordForm.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { userApiEvents } from '../../../../../modules/api/user'
import { isEmpty, mergeRight, pathOr } from 'ramda'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const propTypes = {
  token: PropTypes.string,
}

const validationSchema = yup.object().shape({
  password: yup.string().required('required.').min(9, 'Password must be at least 9 characters.'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], "doesn't match password.")
    .required('required.'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const ResetPasswordForm = ({ token }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const decodedToken = decode(token)
  const email = pathOr('', ['value', 'email'], decodedToken)

  const isSubmitting = useSelector(state => apiStateSelectors.isLoading(state)([userApiEvents.passwordReset_request]))
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <div className={styles.root}>
      <h2>Reset your password</h2>
      <p>
        Enter a new password for your {email} account.{' '}
        <a
          href="https://www.howtogeek.com/195430/how-to-create-a-strong-password-and-remember-it/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn how to create a strong password.
        </a>
      </p>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ password: '', passwordConfirmation: '' }}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        onSubmit={payload => dispatch(userApiEvents.passwordReset_request({ payload, token }))}
      >
        {({ handleSubmit, handleChange, handleBlur, submitCount, touched, values, errors }) => {
          const resetEvent = userApiEvents.passwordReset_request({ payload: values, token })
          const generalErrors = getGeneralFormErrorsByEvent(resetEvent)
          const fieldErrors = getFormFieldErrorsByEvent(resetEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)
          const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

          const formLoaderState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          return (
            <FormLoader
              state={formLoaderState}
              onSubmit={handleSubmit}
              buttonProps={{ children: 'Reset password' }}
              submittingText="Resetting password..."
              errorText={hasApiErrors ? 'Problem resetting password' : 'Resolve errors'}
              ButtonContainer={ButtonContainer}
            >
              <FormErrors errors={generalErrors} />
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.password}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Password"
                  error={getFieldError('password')}
                />
              </FormField>
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.password}
                      name="passwordConfirmation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Confirm Password"
                  error={getFieldError('passwordConfirmation')}
                />
              </FormField>
            </FormLoader>
          )
        }}
      </Formik>
    </div>
  )
}

ResetPasswordForm.propTypes = propTypes

export default ResetPasswordForm

import React, { useCallback, useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { BulkImportInvoicesTable, BulkImportInvoiceView, BulkImportOptions } from '../..'
import { fileUtils } from '../../../../utils'
import {
  Button,
  DimmerLoader,
  Header,
  InfoBox,
  Layout,
  LayoutBody,
  LayoutContent,
  LayoutPrimaryPanel,
  LayoutProvider,
  ListingTemplate,
  TableProvider,
} from '../../../../views/components'
import ImportIcon from '../../../../views/components/atoms/Icons/Doc/Csv'
import { agencyApiSelectors } from '../../../api/agency'
import { apiStateSelectors } from '../../../api/apiState'
import { invoiceApiEvents } from '../../../api/invoice'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import { reconApiEvents, reconApiSelectors } from '../../../api/recon'
import { userApiSelectors } from '../../../api/user'
import { bulkImportInvoicesEvents, bulkImportInvoicesSelectors } from '../../state'
import BulkImportInvoicesProvider from '../../state/BulkImportInvoicesProvider'
// import PropTypes from 'prop-types'
import styles from './BulkImportInvoicesPage.module.scss'

const HeaderWrapper = () => <div className={styles.header} dangerouslySetInnerHTML={{ __html: '<p></p>' }} />

const Body = ({ id, onFileUpload, data, hasSavedInvoices }: any) => {
  const dispatch = useDispatch()

  const isImporting = useSelector(bulkImportInvoicesSelectors.isImporting)
  const isApiLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([
      reconApiEvents.bulkInvoiceUpload_request,
      invoiceApiEvents.fetchBulkInvoices_request,
      portfolioApiEvents.bulkInvoicesCsvTemplate_request,
    ]),
  )
  const currentAgencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const agencyName = useSelector(state => agencyApiSelectors.getAgencyName(state)(currentAgencyId))
  const bulkInvoicesCsvTemplate = useSelector(portfolioApiSelectors.getBulkInvoicesCsvTemplate)
  const bulkInvoiceUploadErrors = useSelector(reconApiSelectors.getBulkInvoiceUploadErrors)
  const isLoading = isApiLoading && bulkInvoiceUploadErrors.length === 0
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  useEffectOnce(() => {
    dispatch(portfolioApiEvents.bulkInvoicesCsvTemplate_request())
  })

  const handleSubmit = useCallback(() => {
    dispatch(bulkImportInvoicesEvents.bulkCreateInvoices(data))
  }, [dispatch, data])

  const handleCsvDownload = useCallback(() => {
    if (bulkInvoicesCsvTemplate) {
      fileUtils.downloadBase64(bulkInvoicesCsvTemplate, 'csv', `${agencyName} bulk invoices template.csv`)
    }
  }, [bulkInvoicesCsvTemplate, agencyName])

  const handleSavedInvoicesReset = useCallback(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(invoiceApiEvents.deleteBulkInvoices_request())
  }, [dispatch])

  return (
    <>
      <Header icon={<ImportIcon />} text="Bulk Import Invoices" />
      {data.length > 0 ? (
        <>
          {hasSavedInvoices && (
            <InfoBox type="info" className={styles['info-box']}>
              The previous import wasn&apos;t completed. You can complete the import below or{' '}
              <button onClick={handleSavedInvoicesReset}>click here to reset</button>.
            </InfoBox>
          )}
          {!hasSavedInvoices && (
            <InfoBox type="info" className={styles['info-box']}>
              The following invoices will be created from your CSV import. Ensure everything looks correct, edit if
              necessary and then click the <strong>import invoices</strong> button below or{' '}
              <button onClick={handleSavedInvoicesReset}>click here to reset</button>.
            </InfoBox>
          )}
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: any; loading... Remove this comment to see the full error message */}
          <DimmerLoader className={styles['dimmer-loader']} loading={isLoading || isImporting}>
            <BulkImportInvoicesTable data={data} id={id} />
          </DimmerLoader>
          <Button className={styles.submit} onClick={handleSubmit}>
            Import invoices
          </Button>
        </>
      ) : (
        <>
          {bulkInvoiceUploadErrors.length > 0 && (
            <InfoBox type="error" className={styles.errors}>
              <p>There are errors in the CSV file you uploaded. Please resolve and re-upload.</p>
              <ul>
                {bulkInvoiceUploadErrors.map((err: any, i: any) => (
                  <li key={`biue-${i}`}>{err.message}</li>
                ))}
              </ul>
            </InfoBox>
          )}
          <BulkImportOptions
            onCsvDownload={handleCsvDownload}
            onFileUpload={onFileUpload}
            isLoading={isLoading}
            isDisabled={isReadOnly}
          />
        </>
      )}
    </>
  )
}

/**
 * @todo check for unfishished imports.
 */
const BulkImportInvoicesPage = ({ match, closeInvoice, openInvoice }: any) => {
  const dispatch = useDispatch()
  const id = match?.params?.id

  useEffect(() => {
    if (id) {
      openInvoice(id)
    } else {
      closeInvoice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffectOnce(() => {
    dispatch(invoiceApiEvents.invoiceTypes_request())
    dispatch(invoiceApiEvents.fetchBulkInvoices_request())
    // temp load directly
    // dispatch(reconApiEvents.bulkInvoiceUpload_request({ file: null }))
  })

  const handleFileDrop = async (files: any) => {
    try {
      const base64 = await fileUtils.toBase64(files[0])
      dispatch(reconApiEvents.bulkInvoiceUpload_request({ base64 }))
    } catch (error) {
      console.log('error uploading file', error)
    }
  }

  const invoices = useSelector(bulkImportInvoicesSelectors.getInvoices)
  const hasSavedInvoices = useSelector(bulkImportInvoicesSelectors.hasSavedInvoices)

  return (
    <div className={styles.root}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message */}
      <LayoutProvider
        stateKey="bulkImport"
        initialProps={{
          sidebar: { open: false, width: 0 },
          primaryPanel: { open: id, width: 600 },
        }}
      >
        <Helmet>
          <title>reOS | Bulk Import Invoices</title>
        </Helmet>
        <Layout>
          <LayoutBody>
            <LayoutPrimaryPanel>{id ? <BulkImportInvoiceView /> : null}</LayoutPrimaryPanel>
            <LayoutContent>
              <TableProvider>
                <ListingTemplate
                  Header={<HeaderWrapper />}
                  Body={
                    <Body onFileUpload={handleFileDrop} hasSavedInvoices={hasSavedInvoices} data={invoices} id={id} />
                  }
                />
              </TableProvider>
            </LayoutContent>
          </LayoutBody>
        </Layout>
      </LayoutProvider>
    </div>
  )
}

BulkImportInvoicesPage.propTypes = {}

export default subscribe([BulkImportInvoicesProvider], ({ match, closeInvoice, openInvoice }: any) => ({
  match,
  closeInvoice,
  openInvoice,
}))(BulkImportInvoicesPage)

import React from 'react'
import classNames from 'classnames'
import styles from './List.module.scss'

interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  className?: string
  active?: boolean
  highlighted?: boolean
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<any>) => void
}
export const ListItem = ({
  className,
  active = false,
  highlighted = false,
  children,
  onClick = e => {},
  ...props
}: ListItemProps) => (
  <li
    onClick={onClick}
    className={classNames([styles['list--item'], className], {
      [styles['list--item__active']]: active,
      [styles['list--item__highlighted']]: highlighted,
    })}
    {...props}
  >
    {children}
  </li>
)

interface ListItemTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  children: React.ReactNode
}
export const ListItemText = ({ className, children, ...props }: ListItemTextProps) => (
  <span className={classNames([styles['list--item-text'], className])} {...props}>
    {children}
  </span>
)

interface ListItemSecondaryTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  children: React.ReactNode
}
export const ListItemSecondaryText = ({ className, children, ...props }: ListItemSecondaryTextProps) => (
  <span className={classNames([styles['list--item-secondary-text'], className])} {...props}>
    {children}
  </span>
)

interface ListDividerProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
}
export const ListDivider = ({ className, ...props }: ListDividerProps) => (
  <span className={classNames([styles['list--divider'], className])} {...props} />
)

interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  className?: string
  children: React.ReactNode
  type?: 'default' | 'checklist' | 'navigation'
  interactive?: boolean
}
export const List = ({ className, children, type = 'default', interactive = false }: ListProps) => (
  <ul
    className={classNames([styles.list, className], {
      [styles['list--checklist']]: type === 'checklist',
      [styles['list--navigation']]: type === 'navigation',
      [styles['list--interactive']]: interactive,
    })}
  >
    {children}
  </ul>
)

interface AggregateListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  className?: string
  text: React.ReactNode
  secondaryText?: React.ReactNode
  active?: boolean
  highlighted?: boolean
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<any>) => void
}
export const AggregateListItem = ({
  className,
  text,
  secondaryText,
  children,
  ...props
}: AggregateListItemProps): React.ReactElement => (
  <ListItem {...props}>
    <div className={classNames([className], { [styles['list-item-container']]: !!children })}>
      <div>
        <ListItemText>{text}</ListItemText>
        {secondaryText && <ListItemSecondaryText>{secondaryText}</ListItemSecondaryText>}
      </div>
      {children && <div>{children}</div>}
    </div>
  </ListItem>
)

export default List

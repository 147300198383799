import React, { useEffect } from 'react'
import { subscribe } from 'react-contextual'
import { DimmerLoader, FocusMode } from '../../../../views/components'
import PortfoliosProvider from '../../PortfoliosProvider'
import EditPropertyForm from './EditPropertyForm'
import { useSelector } from 'react-redux'
import { propertyApiEvents } from '../../../api/property'
import { apiStateSelectors } from '../../../api/apiState'
import { userApiSelectors } from 'modules/api/user'

const EditProperty = ({ fetchCurrentProperty, initialValuesForEdit, updateCurrentProperty, goBack }: any) => {
  useEffect(() => {
    fetchCurrentProperty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([propertyApiEvents.property_request]))
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const handleSubmit = (values: any): void => {
    updateCurrentProperty(values)
    goBack()
  }

  return (
    <FocusMode
      isOpen
      title="Edit Property"
      ContentComponent={() => (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; loading: any; }' is not... Remove this comment to see the full error message
        <DimmerLoader loading={isLoading}>
          <EditPropertyForm
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ initialValues: any; handleSubmit: (values:... Remove this comment to see the full error message
            initialValues={initialValuesForEdit}
            handleSubmit={handleSubmit}
            onCancel={() => goBack()}
            isReadOnly={isReadOnly}
          />
        </DimmerLoader>
      )}
      onClose={() => goBack()}
    />
  )
}

export default subscribe(
  PortfoliosProvider,
  ({ fetchCurrentProperty, initialValuesForEdit, updateCurrentProperty, goBack }: any) => ({
    fetchCurrentProperty,
    initialValuesForEdit,
    updateCurrentProperty,
    goBack,
  }),
)(EditProperty)

import { pipe, map, uniq } from 'ramda'

/**
 * Takes an array of objects, converts to string for strict unique comparison
 *
 * @return a unique array of objects
 */
export const uniqObjectsStrict = pipe(map(JSON.stringify), uniq, map(JSON.parse))

export const hasDupsStrict = (arr: any): boolean => arr.length > uniqObjectsStrict(arr).length

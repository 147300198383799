import React from 'react'
import { animated, useSpring } from 'react-spring'
import useLayout from '../useLayout'
import styles from './PrimaryPanel.module.scss'
import { animationConfig } from 'views/constants'

function PrimaryPanel({ children }: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'primaryPanel' does not exist on type '{}... Remove this comment to see the full error message
  const { primaryPanel, secondaryPanel } = useLayout()

  const openWidth = secondaryPanel.isOpen ? secondaryPanel.width : 0

  const style = useSpring({
    reverse: primaryPanel.open,
    config: animationConfig,
    to: { transform: `translateX(-${primaryPanel.width}px)` },
    from: { transform: `translateX(${openWidth}px)` },
  })

  return (
    <animated.div style={{ height: '100%', ...style }}>
      <div className={styles.root} style={{ width: primaryPanel.open ? primaryPanel.width : '0px' }}>
        {children}
      </div>
    </animated.div>
  )
}

export default PrimaryPanel

import React from 'react'

const SvgProperties = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M25 24a1 1 0 01-1 1H8a1 1 0 01-1-1V13.49a1 1 0 01.386-.79l8-6.222a1 1 0 011.228 0l8 6.222a1 1 0 01.386.79V24zm-2-1v-9.022l-7-5.444-7 5.444V23h14z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgProperties

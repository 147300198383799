import React from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import * as selectors from './selectors'
import { uiEvents } from '../../ui'
import { ROUTES } from 'constants/routes'
import { layoutEvents } from '../../Layout/state'
import { reconApiEvents, reconApiSelectors } from '../../api/recon'
import { portfolioApiSelectors } from '../../api/portfolio'
import { partyApiSelectors } from '../../api/party'

const BulkImportInvoicesProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = useStore()
  const dispatch = useDispatch()

  const invoices = useSelector(selectors.getInvoices)

  const invoiceViewId = props?.match?.params?.id
  const invoice = useSelector(state => selectors.getInvoiceById(state)(invoiceViewId))

  const propertyOwnerId = useSelector(state =>
    portfolioApiSelectors.getLandlordByPortfolioId(state)(invoice?.portfolioId),
  )
  const propertyOwnerName = useSelector(state => partyApiSelectors.getPartyNameById(state)(propertyOwnerId))

  const bulkImportInvoicesStore = {
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    invoices,
    invoice,
    invoiceViewId,
    propertyOwnerName,
    openInvoice: (id: any) => {
      dispatch(uiEvents.redirect(ROUTES.importInvoiceView.replace(':id', id)))
      dispatch(layoutEvents.openPrimaryPanel('bulkImport'))
      dispatch(layoutEvents.closeSidebar('global'))
    },
    closeInvoice: () => {
      dispatch(uiEvents.redirect(ROUTES.importInvoices))
      dispatch(layoutEvents.closePrimaryPanel('bulkImport'))
      dispatch(layoutEvents.openSidebar('global'))
    },
    fetchEasyPayData: (reference: any) => dispatch(reconApiEvents.easypayData_request({ reference })),
    easyPayData: useSelector(reconApiSelectors.getEasyPayData),
    ...props,
  }
  return <context.Provider value={bulkImportInvoicesStore}>{children}</context.Provider>
})

export default BulkImportInvoicesProvider

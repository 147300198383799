import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropzone from 'react-dropzone'
import { Button, CurrencyText, DimmerLoader, InfoBox, InfoList } from '../../../../../views/components'
import { agencyApiEvents, agencyApiSelectors } from 'modules/api/agency'
import styles from '../WalletTransferContent.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { fileUtils } from 'utils/index'
import { notificationEvents } from 'modules/notificationCenter'
import { walletApiEvents } from 'modules/api/wallet'
import { apiStateSelectors } from 'modules/api/apiState'

type TProps = {
  fromWallet: $TSFixMe
  fromBalance: $TSFixMe
}

const BulkWalletTransferConfirmation = ({ fromWallet, fromBalance }: TProps) => {
  const dispatch = useDispatch()
  const [csvBase64, setCsvBase64] = useState('')
  const [fileName, setFileName] = useState('')

  const getAgency = useCallback(id => dispatch(agencyApiEvents.agency_request({ id })), [dispatch])
  const fromWalletAgency: $TSFixMe = useSelector(state => agencyApiSelectors.getAgencyById(state)(fromWallet.agencyId))
  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([walletApiEvents.bulkWalletTransfer_request]),
  )

  useEffect(() => {
    if (fromWallet.agencyId) {
      getAgency(fromWallet.agencyId)
    }
  }, [fromWallet.agencyId])

  /**
   * Handle initial file drop
   */
  const handleFileDrop = async (file: any) => {
    try {
      const csvBase64 = await fileUtils.toBase64(file[0])
      setCsvBase64(csvBase64)
      setFileName(file[0].name)
    } catch (error) {
      dispatch(
        notificationEvents.addNotification_request({
          message: 'There was a problem uploading your CSV',
          type: 'error',
        }),
      )
    }
  }

  const handleBulkTransfer = useCallback(() => {
    dispatch(
      walletApiEvents.bulkWalletTransfer_request({
        params: { fromWalletRef: fromWallet.reference },
        body: { base64: csvBase64 as string },
      }),
    )
  }, [dispatch, csvBase64, fromWallet.reference])

  return (
    <div className={styles['transfer-info']}>
      <div className="rbn--row" style={{ marginBottom: '20px' }}>
        <div className="rbn--col rbn-col-md-4">
          <h3>From Wallet</h3>
          {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
          <InfoList
            items={[
              {
                label: 'Account Type',
                value: fromWallet.accountType,
              },
              {
                label: 'Contact',
                value: fromWallet.partyFullName || fromWallet.partyId,
              },
              {
                label: 'Lease',
                value: fromWallet.propertyAddress,
              },
              {
                label: 'Payment Reference',
                value: fromWallet.reference,
              },
              {
                label: 'Balance',
                value: <CurrencyText>{fromBalance.balance}</CurrencyText>,
              },
              {
                label: 'Asset',
                value: fromBalance.name,
              },
              {
                label: 'Agency ID',
                value: fromWalletAgency?.id,
              },
              {
                label: 'Agency T/A Name',
                value: fromWalletAgency?.agency.agencyDetails.trading,
              },
              {
                label: 'Agency Company Name',
                value: fromWalletAgency?.agency.agencyDetails.companyName,
              },
            ]}
          />
        </div>
        <div className="rbn--col rbn-col-md-4">
          <div className={styles.dropzone}>
            <DimmerLoader bgColor="transparent" loading={false} disabled={false}>
              <Dropzone onDrop={handleFileDrop} multiple={false} style={null} className="drop-zone">
                <p>
                  Drag and drop a CSV file here or <button>browse to choose a file</button>
                </p>
              </Dropzone>
            </DimmerLoader>
          </div>
          {fileName && (
            <InfoBox className={styles.fileInfo} type="success">
              <i>
                <strong>{fileName}</strong>
              </i>{' '}
              is ready for transfer.
            </InfoBox>
          )}
          <Button onClick={handleBulkTransfer} loading={isLoading} size="md">
            Transfer
          </Button>
        </div>
        <div className="rbn--col rbn-col-md-4"></div>
      </div>
    </div>
  )
}

export default BulkWalletTransferConfirmation

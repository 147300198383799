import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  name: PropTypes.string,
  meta: PropTypes.string,
}

const defaultProps = {}

const Name = ({ name, meta }: any) => {
  return (
    <div>
      <strong>{name}</strong> <br />
      {meta}
    </div>
  )
}

Name.propTypes = propTypes
Name.defaultProps = defaultProps

export default Name

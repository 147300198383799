import React, { useState, useEffect, useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { SearchFilterProvider } from '../../../../views/providers'
import PortfoliosProvider from '../../../portfolios/PortfoliosProvider'
import styles from './PropertiesTable.module.scss'
import { AggregateTable, Header } from '../../../../views/components'
import NameCell from './NameCell'
import PropertiesIcon from '../../../../views/components/atoms/Icons/Property/ComplexFill'
import { pathOr, map, path } from 'ramda'
import { stringUtils } from '../../../../utils'
import { ItemListCell } from '../../../../views/components/organisms/Table'
import { format } from 'date-fns'
import pluralize from 'pluralize'
import { $TSFixMe } from 'types/ts-migrate'

const PropertiesTable = ({ filters, searchQuery, activeFilterIndex, openEditProperty }: any): React.ReactElement => {
  const [data, setData] = useState([])
  const filter = pathOr({}, [activeFilterIndex], filters)

  useEffect(() => {
    // @ts-expect-error
    setData(filter.properties)
    // @ts-expect-error
  }, [filter.properties])

  const filteredProperties = useMemo(() => {
    return data.filter(p => stringUtils.jsonStringSearch(searchQuery, p))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const columns = [
    {
      Header: 'Property Address',
      accessor: 'address',
    },
    {
      Header: 'Lease Details',
      accessor: 'details',
      sort: {
        enabled: true,
        order: false,
      },
    },
  ]

  const propertiesData = useMemo(() => {
    const buildProperyObj = (p: any): $TSFixMe => {
      const propertyActiveLeases = p.activeLeases

      const leaseTerms = propertyActiveLeases.map((lease: any) => {
        const startDate = path(['leaseTerms', 'startDate'], lease)
        const endDate = path(['leaseTerms', 'endDate'], lease)
        // @ts-expect-error
        const startDateFormatted: string = startDate && format(new Date(startDate), 'dd MMM yyyy')
        // @ts-expect-error
        const endDateFormatted: string = endDate && format(new Date(endDate), 'dd MMM yyyy')

        const leaseDetails =
          startDateFormatted && endDateFormatted ? `${startDateFormatted} -> ${endDateFormatted}` : 'No lease terms'

        return leaseDetails
      })

      return {
        portfolioId: pathOr('', ['portfolioId'], p),
        address: <NameCell name={p.mainText} meta={p.secondaryText} />,
        details: (
          <ItemListCell
            basic
            items={leaseTerms}
            suffix={`more ${pluralize('lease', leaseTerms.length - 1) as string}`}
            blankCharacter="-"
          />
        ),
      }
    }

    return searchQuery ? map(buildProperyObj, filteredProperties) : map(buildProperyObj, data)
  }, [data, filteredProperties, searchQuery])

  return useMemo(
    () => (
      <div className={styles.root}>
        <Header icon={<PropertiesIcon />} text="Properties" />

        <AggregateTable
          rowHeight={80}
          columns={columns}
          data={propertiesData}
          onRowClick={data => openEditProperty(data.portfolioId)}
        />
      </div>
    ),
    [columns, propertiesData, openEditProperty],
  )
}

export default subscribe(
  [PortfoliosProvider, SearchFilterProvider],
  ({ propertyFilters, openEditProperty }: any, { searchQuery, activeFilterIndex }: any) => ({
    filters: propertyFilters,
    openEditProperty,
    searchQuery,
    activeFilterIndex,
  }),
)(PropertiesTable)

import React from 'react'

const SvgSettings = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M6 16c0-.865.11-1.703.316-2.504A3 3 0 008.99 8.867a9.99 9.99 0 014.335-2.505 3 3 0 005.348 0 9.99 9.99 0 014.335 2.505 3 3 0 002.675 4.63 10.036 10.036 0 010 5.007 3 3 0 00-2.675 4.629 9.99 9.99 0 01-4.335 2.505 3 3 0 00-5.348 0 9.99 9.99 0 01-4.335-2.505 3 3 0 00-2.675-4.63A10.056 10.056 0 016 16zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0116 22c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0121.196 19a4.993 4.993 0 012.77-2.25 8.126 8.126 0 000-1.5A4.993 4.993 0 0121.195 13a4.993 4.993 0 01-.564-3.524 7.989 7.989 0 00-1.297-.75A4.993 4.993 0 0116 10a4.993 4.993 0 01-3.335-1.274 7.99 7.99 0 00-1.297.75A4.993 4.993 0 0110.804 13a4.993 4.993 0 01-2.77 2.25 8.126 8.126 0 000 1.5A4.993 4.993 0 0110.805 19h-.001zM16 19a3 3 0 010-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 100 2z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgSettings

import { createSelector } from 'reselect'

const partySearchState = (state: any) => state.ui.partySearch

export const getSearchQueryWithTags = createSelector(
  partySearchState,
  partySearch => (tags: any) => partySearch.queryByTags[tags] || '',
)

export const isSearching = createSelector(partySearchState, s => s.isSearching)

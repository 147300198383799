import React from 'react'

const SvgCheckDouble = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M15.602 17.76l1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412v.001zm.002-2.828l4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L11.364 22 5 15.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgCheckDouble

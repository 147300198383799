import React, { useEffect, useCallback } from 'react'
import { useToggle, useMedia } from 'react-use'
import { get } from 'lodash-es'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { SupportStaffProvider, SupportStaffLogin } from '../../../../modules/supportStaff'
import { userApiSelectors } from '../../../../modules/api/user'
import { walletApiEvents } from '../../../../modules/api/wallet'
import { agencyApiEvents, agencyApiSelectors } from '../../../../modules/api/agency'
import { reconApiSelectors } from '../../../../modules/api/recon'
import { onboardingSelectors } from '../../../../modules/onboarding'
import { uiEvents } from '../../../../modules/ui'
import { partyApiEvents } from '../../../../modules/api/party'
import { layoutEvents, layoutSelectors } from '../../../../modules/Layout/state'
import { useBreakpoints } from '../../../hooks'
import { Layout, LayoutBody, LayoutSidebar, LayoutContent, MobileMenuBar } from '../..'
import { useAppVersion } from 'modules/appRefresh/hooks'
import { ROUTES } from 'constants/routes'
import NotificationCentreProvider from '../../../../modules/notificationCenter/NotifcationCentreProvider'
import { NotificationCentre } from '../../../../modules/notificationCenter'
import RobinDrawer from './RobinDrawer/RobinDrawer'
import ShortcutsDrawer from './ShortcutsDrawer/ShortcutsDrawer'
import Sidebar from './Sidebar/Sidebar'
import FABs from './FABs/FABs'
import SegmentsDrawer from './SegmentsDrawer/SegmentsDrawer'
import styles from './AppShell.module.scss'

const AppShell = ({ children }: any) => {
  useAppVersion()

  const [isRobinDrawerOpen, setIsRobinDrawerOpen] = useToggle(false)
  const [isShortcutsDrawerOpen, setIsShortcutsDrawerOpen] = useToggle(false)
  const [isSegmentsDrawerOpen, setIsSegmentsDrawerOpen] = useToggle(false)

  const dispatch = useDispatch()

  const { isMobile, breakpoints } = useBreakpoints()

  const currentAgencyId = useSelector(userApiSelectors.getCurrentAgencyId)
  const currentAgencyName = useSelector(state => agencyApiSelectors.getAgencyName(state)(currentAgencyId))
  const currentAgencyLogo = useSelector(state => agencyApiSelectors.getAgencyLogo(state)(currentAgencyId))
  const pathname = useSelector(state => get(state, 'router.location.pathname', ''))
  const isAuthenticated = useSelector(userApiSelectors.isAuthenticated)
  const globalNotificationCount = useSelector(reconApiSelectors.getNotificationCountTotal)
  const currentSegments = useSelector(userApiSelectors.getCurrentSegmentsWithoutDefault)
  const isOnboardingComplete = useSelector(onboardingSelectors.isOnboardingComplete)
  const userRole = useSelector(state => userApiSelectors.getUserRole(state))
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const redirect = useCallback(path => dispatch(uiEvents.redirect(path)), [dispatch])
  const getAgency = useCallback(id => dispatch(agencyApiEvents.agency_request({ id })), [dispatch])
  const getAgencyBalance = useCallback(partyId => dispatch(walletApiEvents.balance_request({ partyId })), [dispatch])
  const getPartyTags = useCallback(() => dispatch(partyApiEvents.partyTags_request()), [dispatch])

  pluralize.addIrregularRule('needs', 'need')
  pluralize.addIrregularRule('requires', 'require')

  const isXXXL = useMedia(`(min-width: ${breakpoints.xxxl}px)`)
  useEffect(() => {
    if ((isXXXL && pathname === '/dashboard') || !isOnboardingComplete) {
      if (!isRobinDrawerOpen) {
        setIsRobinDrawerOpen(true)
      }
    } else {
      setIsRobinDrawerOpen(false)
    }
    // Deliberately ignored, don't want isRobinDrawer panel as dependency or the panel is always closed on toggle
    // eslint-disable-next-line
  }, [isXXXL, isOnboardingComplete, pathname])

  useEffect(() => {
    if (isAuthenticated && currentAgencyId) {
      getAgency(currentAgencyId)
      getAgencyBalance(currentAgencyId)
      getPartyTags()
    }
  }, [isAuthenticated, getAgency, getAgencyBalance, getPartyTags, currentAgencyId, dispatch])

  const toggleRobinDrawer = () => {
    if (isShortcutsDrawerOpen) {
      closeShortcutDrawer()
    } else if (isSegmentsDrawerOpen) {
      closeSegmentsDrawer()
    } else {
      if (isXXXL && pathname === '/dashboard') {
        return false
      } else {
        setIsRobinDrawerOpen(!isRobinDrawerOpen)
      }
    }
  }

  const closeRobinDrawer = () => setIsRobinDrawerOpen(false)

  const toggleshortcutDrawer = () => {
    setIsShortcutsDrawerOpen(!isShortcutsDrawerOpen)
  }

  const toggleSegmentsDrawer = () => {
    setIsSegmentsDrawerOpen(!isSegmentsDrawerOpen)
  }

  const closeShortcutDrawer = () => setIsShortcutsDrawerOpen(false)

  const closeSegmentsDrawer = () => setIsSegmentsDrawerOpen(false)

  const isSideBarOpen = useSelector(state => layoutSelectors.isSidebarOpen(state)('global'))

  const handleMenuToggle = useCallback(() => {
    dispatch(isSideBarOpen ? layoutEvents.closeSidebar('global') : layoutEvents.openSidebar('global'))
  }, [isSideBarOpen, dispatch])

  const handleRobinOutsideClick = ({ target }: any) => {
    if (isXXXL && pathname === '/dashboard') {
      return false
    }

    let className = get(target, 'className', '')
    className = typeof className !== 'string' ? '' : className
    if (!className.includes('FAB')) {
      setIsRobinDrawerOpen(false)
    }
  }

  const handleShortcustsOutsideClick = ({ target }: any) => {
    let className = get(target, 'className', '')
    className = typeof className !== 'string' ? '' : className
    if (!className.includes('FAB')) {
      setIsShortcutsDrawerOpen(false)
    }
  }

  const handleSegmentsOutsideClick = ({ target }: any) => {
    let className = get(target, 'className', '')
    className = typeof className !== 'string' ? '' : className
    if (!className.includes('FAB')) {
      setIsSegmentsDrawerOpen(false)
    }
  }

  return (
    <div className={styles.root}>
      {!isAuthenticated ? (
        children
      ) : (
        <NotificationCentreProvider>
          <Layout>
            <OutsideClickHandler onOutsideClick={handleRobinOutsideClick}>
              <RobinDrawer
                getAgencyBalance={getAgencyBalance}
                currentAgencyId={currentAgencyId}
                open={isRobinDrawerOpen}
                // @ts-expect-error ts-mirate FIXME
                onClose={closeRobinDrawer}
                redirect={redirect}
              />
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={handleShortcustsOutsideClick}>
              <ShortcutsDrawer
                open={isShortcutsDrawerOpen}
                onClose={closeShortcutDrawer}
                userRole={userRole}
                redirect={redirect}
                // @ts-expect-error ts-mirate FIXME
                agencyCreated={!!currentAgencyId}
              />
            </OutsideClickHandler>
            <OutsideClickHandler onOutsideClick={handleSegmentsOutsideClick}>
              <SegmentsDrawer
                open={isSegmentsDrawerOpen}
                onClose={closeSegmentsDrawer}
                userRole={userRole}
                redirect={redirect}
                // @ts-expect-error ts-mirate FIXME
                agencyCreated={!!currentAgencyId}
              />
            </OutsideClickHandler>
            {isMobile && (
              <MobileMenuBar
                agencyName={currentAgencyName}
                agencyLogo={currentAgencyLogo}
                onToggle={handleMenuToggle}
                isOpen={isSideBarOpen}
              />
            )}
            <SupportStaffProvider>
              <SupportStaffLogin />
            </SupportStaffProvider>
            <LayoutBody>
              <LayoutSidebar>
                <div id="sidebar" style={{ height: '100%' }}>
                  <SupportStaffProvider>
                    <Sidebar
                      currentAgencyId={currentAgencyId}
                      userRole={userRole}
                      redirect={(path: any) => {
                        if (isXXXL && path === '/dashboard') {
                          toggleRobinDrawer()
                        } else {
                          closeRobinDrawer()
                        }
                        redirect(path)
                      }}
                      pathname={pathname}
                    />
                  </SupportStaffProvider>
                </div>
              </LayoutSidebar>
              <LayoutContent>{children}</LayoutContent>
            </LayoutBody>
            <div id="rbn-fabs">
              <FABs
                className={styles.fabs}
                notificationCount={globalNotificationCount}
                onRobinClick={toggleRobinDrawer}
                onShortcutsClick={toggleshortcutDrawer}
                onSegmentsClick={toggleSegmentsDrawer}
                isShortcutsDrawerOpen={isShortcutsDrawerOpen}
                isSegmentsDrawerOpen={isSegmentsDrawerOpen}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; notificationCount: any; on... Remove this comment to see the full error message
                isRobinDrawerOpen={isRobinDrawerOpen}
                segmentFilterCount={currentSegments.length}
                isReadOnly={isReadOnly}
              />
            </div>
          </Layout>
          <NotificationCentre />
        </NotificationCentreProvider>
      )}
    </div>
  )
}

export default AppShell

import React from 'react'
import PropTypes from 'prop-types'
import styles from './BackStep.module.scss'

const propTypes = {
  onClick: PropTypes.func.isRequired,
}

const defaultProps = {}

const BackStep = ({ children, onClick }: any) => {
  const handleClick = (e: any): void => {
    e.preventDefault()
    onClick(e)
  }

  return (
    <button className={styles.root} onClick={handleClick}>
      {children}
    </button>
  )
}

BackStep.propTypes = propTypes
BackStep.defaultProps = defaultProps

export default BackStep

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { isEmpty } from 'lodash-es'
import * as yup from 'yup'
import { FormField, TextField, TextInput } from '../../../../components'
import styles from './LoginForm.module.scss'
import FormLoader from '../../../molecules/FormLoader/FormLoader'
import { useDispatch, useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { userApiEvents } from '../../../../../modules/api/user'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { useEffectOnce } from 'react-use'
import { formUtils } from '../../../../../utils'
import { mergeRight } from 'ramda'
import { StringParam, useQueryParam } from 'use-query-params'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  passwordReset: PropTypes.bool,
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required.').email('Email is invalid'),
  password: yup.string().required('Password is required.').min(9, 'Password must be at least 9 characters.'),
})

const initialValues = {
  email: '',
  password: '',
}

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const LoginForm = ({ passwordReset }: any): React.ReactElement => {
  const dispatch = useDispatch()
  const emailRef = useRef<$TSFixMe>()

  const handleSubmit = (values: any): void => {
    dispatch(userApiEvents.login_request(values))
  }

  useEffectOnce(() => {
    emailRef.current?.focus()
  })

  // used to pass errors from other pages, like email verification
  const [errorMsg] = useQueryParam('error', StringParam)

  const isSubmitting = useSelector(state => apiStateSelectors.isLoading(state)([userApiEvents.login_request]))
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <div className={styles.root}>
      <h2>Sign in</h2>
      {passwordReset && <p>A password reset link has been sent to your email address.</p>}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, handleBlur, handleSubmit, values, submitCount, touched }) => {
          const loginEvent = userApiEvents.login_request(values)
          const generalErrors = getGeneralFormErrorsByEvent(loginEvent)
          const fieldErrors = getFormFieldErrorsByEvent(loginEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

          const formLoaderState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          const getFieldError = formUtils.getFieldError(submitCount, touched, mergeRight(errors, fieldErrors))

          return (
            <FormLoader
              onSubmit={handleSubmit}
              buttonProps={{ children: 'Sign in' }}
              submittingText="Signing in..."
              errorText={hasApiErrors ? 'Problem signing in' : 'Resolve errors'}
              ButtonContainer={ButtonContainer}
              state={formLoaderState}
              persistErrorMessage={!hasApiErrors}
            >
              <FormErrors errors={errorMsg ? [...generalErrors, errorMsg] : generalErrors} />
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      id="login-email"
                      type={TextFieldTypes.email}
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      ref={(ref: any) => {
                        emailRef.current = ref
                      }}
                    />
                  }
                  error={getFieldError('email')}
                />
              </FormField>
              <FormField>
                <TextField
                  inputComponent={
                    <TextInput
                      id="login-password"
                      type={TextFieldTypes.password}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  }
                  error={getFieldError('password')}
                />
              </FormField>
            </FormLoader>
          )
        }}
      </Formik>
    </div>
  )
}

LoginForm.propTypes = propTypes

export default LoginForm

import React from 'react'
import cx from 'classnames'
import styles from './InfoList.module.scss'

type TProps = {
  className?: string
  spacing?: 'none' | 'sm' | 'md' | 'lg'
  items: {
    label: string
    value: string | React.ReactNode
    isVisible?: boolean
    onClick?: () => void
  }[]
}

const InfoList = ({ spacing = 'lg', items, className }: TProps) => {
  const classes = cx([styles.root, styles[`spacing--${spacing}`], className])
  const isVisible = items.map((item: any) => (item.isVisible ? item.isVisible : true))
  return (
    items.length > 0 && (
      <ul className={classes}>
        {items.map(
          (item: any, i: any) =>
            item.value &&
            isVisible && (
              <li key={i}>
                <span className={styles.label}>{item.label}</span>
                {item.onClick ? (
                  <button className={styles.value} onClick={item.onClick}>
                    {item.value}
                  </button>
                ) : (
                  <span className={styles.value}>{item.value}</span>
                )}
              </li>
            ),
        )}
      </ul>
    )
  )
}

export default InfoList

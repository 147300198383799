import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { subscribe } from 'react-contextual'
import { Button, Checkbox, DatePicker, FormField, Select, Divider } from '../../../../../views/components'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { format } from 'date-fns'
import { find, path } from 'ramda'
import { chatbotSelectors } from '../../../../ui/ChatBot'
import { formUtils } from '../../../../../utils'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../../api/portfolio'
import PortfoliosProvider from '../../../PortfoliosProvider'
import LeaseDetails from './LeaseDetails/LeaseDetails'
import NextSteps from './NextSteps'
import TerminationWarning from './TerminationWarning/TerminationWarning'
import styles from './PortfolioTerminationForm.module.scss'
import DepositFunds from './DepositFunds/DepositFunds'
import { partyApiSelectors } from '../../../../api/party'
import { walletApiSelectors } from '../../../../api/wallet'
import { invoiceApiSelectors } from '../../../../api/invoice'
import { reconApiSelectors } from '../../../../api/recon'
import { apiStateSelectors } from '../../../../api/apiState'
import { userApiSelectors } from '../../../../api/user'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  portfolioId: PropTypes.string.isRequired,
}

const validationSchema = yup.object().shape({
  reason: yup.object().required('Required'),
})

const PortfolioTerminationForm = ({
  openPortfolio,
  fetchDraftInvoices,
  fetchActiveInvoices,
  openActiveInvoices,
  openDraftInvoices,
}: any) => {
  const dispatch = useDispatch()
  const currentPortfolioId = useSelector(chatbotSelectors.getCurrentPortfolioId)
  const isTerminating = useSelector(state =>
    apiStateSelectors.isLoading(state)([portfolioApiEvents.terminateApprovedLease_request]),
  )
  const terminationReasons: $TSFixMe[] = useSelector(portfolioApiSelectors.getLeaseTerminationReasons)
  const isPortfolioTerminated = useSelector(state =>
    portfolioApiSelectors.isPortfolioTerminated(state)(currentPortfolioId),
  )
  const tenantId = useSelector(state => portfolioApiSelectors.getTenantByPortfolioId(state)(currentPortfolioId))
  const tenantAccounts = useSelector(state => partyApiSelectors.getPartyAccountsById(state)(tenantId))
  const portfolioAccount = find((acc: any) => acc.portfolioId === currentPortfolioId, tenantAccounts)
  const depositAccountBalance = useSelector(state =>
    walletApiSelectors.getDepositAccountBalance(state)(tenantId, path(['accountId'], portfolioAccount)),
  )
  const draftInvoices = useSelector(state =>
    invoiceApiSelectors.getDraftInvoicesForPortfolio(state)(currentPortfolioId),
  )
  const activeInvoices = useSelector(state =>
    reconApiSelectors.getActiveInvoicesForPortfolio(state)(currentPortfolioId),
  )
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)
  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)

  const initialValues = {
    vacateDate: new Date(),
    reason: '',
    understandAction: false,
    releaseDeposit: 'false', // string value in form due to the way radios work, cast to bool on submission
  }

  useEffectOnce(() => {
    dispatch(portfolioApiEvents.terminationReasons_request())
    fetchDraftInvoices()
    fetchActiveInvoices()
  })

  useEffect(() => {
    if (!isTerminating && isPortfolioTerminated) {
      openPortfolio(currentPortfolioId)
    }
  }, [isTerminating, isPortfolioTerminated, currentPortfolioId, openPortfolio])

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const payload = {
      body: {
        ...values,
        reason: values.reason.value,
        releaseDeposit: values.releaseDeposit === 'true', // form uses string representation of bools coz radios don't work with bool values
        vacateDate: format(values.vacateDate, 'yyyy-MM-dd'),
      },
      params: { id: currentPortfolioId },
    }
    dispatch(portfolioApiEvents.terminateApprovedLease_request(payload))
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, errors, touched, setFieldValue, setFieldTouched, submitCount }) => {
        const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

        return (
          <form onSubmit={handleSubmit} className={styles.root}>
            <fieldset disabled={isReadOnly}>
              <LeaseDetails portfolioId={currentPortfolioId} />

              <div className="rbn--row">
                <div className="rbn--col-md-4">
                  <FormField>
                    <DatePicker
                      label="Vacate date"
                      name="vacateDate"
                      onChange={(date: any) => setFieldValue('vacateDate', date)}
                      date={values.vacateDate}
                    />
                  </FormField>
                </div>
                <div className="rbn--col-md-4">
                  <FormField>
                    <Select
                      name="reason"
                      value={terminationReasons.find((r: any) => r.value === values.reason)}
                      label="Reason for termination"
                      error={getFieldError('reason')}
                      options={terminationReasons.map((r: any) => ({
                        label: r.name,
                        value: r.value,
                      }))}
                      onBlur={() => setFieldTouched('reason')}
                      onChange={(option: any) => setFieldValue('reason', option)}
                    />
                  </FormField>
                </div>
              </div>

              {isOwnerRole && (
                // @ts-expect-error ts-migrate(2786) FIXME: 'DepositFunds' cannot be used as a JSX component.
                <DepositFunds
                  portfolioId={currentPortfolioId}
                  setFieldValue={setFieldValue}
                  depositAccountBalance={depositAccountBalance}
                />
              )}

              <Divider style={{ marginBottom: '20px' }} />
              <TerminationWarning
                draftInvoices={draftInvoices.length}
                activeInvoices={activeInvoices.length}
                openActiveInvoices={openActiveInvoices}
                openDraftInvoices={openDraftInvoices}
              />

              <NextSteps releaseDeposit={values.releaseDeposit} depositAccountBalance={depositAccountBalance} />

              <Checkbox
                className={styles.understand}
                label="I understand that this action can not be undone."
                name="understandAction"
                checked={values.understandAction}
                onChange={() => setFieldValue('understandAction', !values.understandAction)}
              />

              <div className={styles.footer}>
                <Button type="submit" disabled={!values.understandAction} loading={isTerminating}>
                  Confirm immediate termination
                </Button>
              </div>
            </fieldset>
          </form>
        )
      }}
    </Formik>
  )
}

PortfolioTerminationForm.propTypes = propTypes

export default subscribe(
  [PortfoliosProvider],
  ({ openPortfolio, fetchDraftInvoices, fetchActiveInvoices, openActiveInvoices, openDraftInvoices }: any) => ({
    openPortfolio,
    fetchDraftInvoices,
    fetchActiveInvoices,
    openActiveInvoices,
    openDraftInvoices,
  }),
)(PortfolioTerminationForm)

import { createSlice } from '@reduxjs/toolkit'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from '../constants'

const initialState: $TSFixMe = {
  loading: {},
  errors: {},
}

const getApiActionMatches = ({ type }: any): $TSFixMe => /(.*)_(request|success|error)/.exec(type)

const isApiAction = (action: any): boolean => !(getApiActionMatches(action) == null)

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(isApiAction, (state, { type, payload, meta }) => {
      const matches = getApiActionMatches({ type })
      const error = type === 'http_error'
      const errorInitAction = meta?.initAction
      const reduceByPayload = meta?.reduceByPayload

      if (error && errorInitAction) {
        const actionKey = errorInitAction?.type.replace('_request', '')
        state.loading[actionKey] = false
        state.errors[actionKey] = state.errors[actionKey] || {}
        const payloadError = payload?.response?.errors
          ? payload?.response?.errors
          : payload?.response?.detail
          ? [{ key: 'general', message: payload.response.detail }]
          : [{ key: 'general', message: 'An unknown error occured.' }]

        reduceByPayload
          ? (state.errors[actionKey][JSON.stringify(errorInitAction?.payload)] = payloadError)
          : (state.errors[actionKey] = payloadError)
      } else {
        const [, requestName, requestState] = matches
        state.loading[requestName] = requestState === 'request'
      }
    })
  },
})

const { reducer } = slice

export { initialState, reducer }

import React, { useEffect, useMemo, forwardRef, useState } from 'react'
import cx from 'classnames'
import { path, is, pathOr, reverse, sort } from 'ramda'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import PropTypes from 'prop-types'
import Checkbox from '../../atoms/Checkbox/Checkbox'
import Table from './Table'
import useTable from './useTable'
import styles from './Table.module.scss'
import SortButton from './SortButton/SortButton'
import { $TSFixMe } from 'types/ts-migrate'

/**
 * AggregateTableRow
 */

const AggregateTableRowProps = {
  enableSelection: PropTypes.bool,
  rowHeight: PropTypes.number,
  data: PropTypes.shape({
    rowData: PropTypes.arrayOf(PropTypes.object),
    onRowClick: PropTypes.func,
    interactive: PropTypes.bool,
  }),
  index: PropTypes.number,
  style: PropTypes.object,
}

const AggregateTableRow = ({ data, index, style }: any): React.ReactElement =>
  useMemo(() => {
    const { rowData, onRowClick, columns, selection, interactive } = data
    const handleClick =
      typeof onRowClick === 'function'
        ? (e: any) => {
            if (path(['target', 'type'], e) !== 'checkbox') {
              onRowClick(rowData[index])
            }
          }
        : () => {}

    const row = rowData[index]
    const classes = cx({
      // @ts-expect-error ts-migrate(2774) FIXME: This condition will always return true since the f... Remove this comment to see the full error message
      [styles.interactive]: handleClick && interactive,
      [styles.selected]: selection.includes(row.id),
      [styles.highlighted]: path(['highlighted'], row),
      [styles['animate-danger']]: path(['animateDanger'], row),
      [styles['animate-danger-flash']]: path(['animateDangerFlash'], row),
      [styles['animate-success']]: path(['animateSuccess'], row),
      [styles.error]: path(['hasError'], row),
    })
    return (
      <Table.Row className={classes} key={index} style={style} onClick={handleClick}>
        {columns.map((col: any, i: any) => (
          <Table.Cell
            key={i}
            style={col.style || {}}
            title={is(Object, row[col.accessor]) ? pathOr('', ['props', 'name'], row[col.accessor]) : row[col.accessor]}
          >
            {row[col.accessor]}
          </Table.Cell>
        ))}
      </Table.Row>
    )
  }, [data, index, style])

AggregateTableRow.propTypes = AggregateTableRowProps

/**
 * Adds padding to the bottom of the list which prevents
 * FABs overlapping the bottom row.
 * @link https://github.com/CastleOne/robin-client/issues/465
 */
// @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type '{ childre... Remove this comment to see the full error message
const innerElementType = forwardRef(function ElementType({ style, ...rest }, ref) {
  return (
    <div
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height) + 96}px`,
      }}
      {...rest}
    />
  )
})

/**
 * AggregateTableBody
 */

const AggregateTableBodyProps = {
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelection: PropTypes.func.isRequired,
}

interface IAggregateTableBodyProps {
  tableData: readonly any[]
  onRowClick?: any
  rowHeight?: number
  enableSelection?: boolean
  selection?: any
  setSelection?: any
  columns?: any
  interactive?: boolean
}

const AggregateTableBody = (props: IAggregateTableBodyProps): React.ReactElement => {
  const { tableData, onRowClick, rowHeight, enableSelection, selection, setSelection, columns, interactive } = props

  const toggleSelection = (id: any): void => {
    let _selection = [...selection]
    if (_selection.includes(id)) {
      _selection = _selection.filter(s => s !== id)
    } else {
      _selection = [..._selection, id]
    }
    setSelection(_selection)
  }

  const itemData = useMemo(
    () => ({
      onRowClick,
      selection,
      rowHeight,
      columns: enableSelection
        ? [
            {
              Header: '',
              accessor: 'selected',
            },
            ...columns,
          ]
        : columns,
      rowData: enableSelection
        ? tableData.map((d: any) => ({
            selected: (
              <Checkbox
                disabled={d?.enableSelection === false}
                name="select"
                checked={selection.includes(d.id)}
                onChange={(e: any) => {
                  toggleSelection(d.id)
                }}
              />
            ),

            ...d,
          }))
        : tableData,
      interactive,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [tableData, onRowClick, selection],
  )

  return (
    <Table.Body>
      <AutoSizer>
        {({ height, width }: any) => {
          return (
            <List
              itemCount={tableData.length}
              itemSize={rowHeight}
              itemData={itemData}
              height={height}
              width={width}
              innerElementType={innerElementType}
            >
              {AggregateTableRow}
            </List>
          )
        }}
      </AutoSizer>
    </Table.Body>
  )
}

AggregateTableBody.propTypes = AggregateTableBodyProps

/**
 * AggregateTable
 */

const AggregateTableProps = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      accessor: PropTypes.string.isRequired,
      style: PropTypes.object,
      sort: PropTypes.shape({
        enabled: PropTypes.bool,
        order: PropTypes.oneOf([false, 'asc', 'desc']),
      }),
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  hideHeaderSelection: PropTypes.bool,
  interactive: PropTypes.bool,
  initialSelection: PropTypes.arrayOf(PropTypes.string),
  onSelectionChange: PropTypes.func,
}

export interface IAggregateTableProps {
  enableSelection?: boolean
  hideHeaderSelection?: boolean
  rowHeight?: number
  columns?: any
  data: readonly any[]
  interactive?: boolean
  initialSelection?: any
  onSelectionChange?: Function
  index?: any
}

const AggregateTable = (props: IAggregateTableProps): null | React.ReactElement => {
  const {
    enableSelection,
    hideHeaderSelection,
    rowHeight = 55,
    columns,
    data,
    interactive = true,
    initialSelection,
    onSelectionChange,
  } = props
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectAll' does not exist on type '{}'.
  const { selectAll, toggleAll, setSelectAll, selection = [], setSelection } = useTable()
  const [tableData, setTableData] = useState(data)

  const initialSortOrder = columns.reduce((acc: $TSFixMe, col: $TSFixMe) => {
    acc[col.accessor] = false
    return acc
  }, {})
  const [sortOrder, setSortOrder] = useState(initialSortOrder)

  const handleSortClick = (accessor: string): void => {
    const newSortOrder = {
      ...initialSortOrder,
      [accessor]: sortOrder[accessor] === 'asc' ? 'desc' : 'asc',
    }
    setSortOrder(newSortOrder)
  }

  const sortTableData = (accessor: string, newSortOrder: $TSFixMe) => {
    if (accessor) {
      const sortedAsc = sort((a, b) => {
        const valA = pathOr('', ['meta', accessor], a)
        const valB = pathOr('', ['meta', accessor], b)
        return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' })
      }, tableData)
      const sortedArray = newSortOrder[accessor] === 'asc' ? sortedAsc : reverse(sortedAsc)
      return sortedArray
    } else {
      return tableData
    }
  }

  // Reset selection when data changes, likely a filter change
  useEffect(() => {
    setSelection([])
    setSelectAll(false)
    setTableData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    toggleAll(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll])

  useEffect(() => {
    setSelection(initialSelection)
  }, [initialSelection, setSelection, data])

  useEffect(() => {
    if (typeof onSelectionChange === 'function') {
      onSelectionChange(selection)
    }
  }, [selection, onSelectionChange])

  const cols = enableSelection
    ? [
        hideHeaderSelection
          ? {
              Header: <></>,
              accessor: 'selected',
            }
          : {
              Header: <Checkbox checked={selectAll} name="selectAll" onChange={() => setSelectAll(!selectAll)} />,
              accessor: 'selected',
            },
        ...columns,
      ]
    : columns

  const classes = cx({
    [styles['is-selectable']]: enableSelection,
  })

  const sortedCol = Object.keys(sortOrder).find(key => sortOrder[key] !== false)
  const sortedTableData = sortedCol ? sortTableData(sortedCol, sortOrder) : tableData

  return data.length === 0 ? null : (
    <Table className={classes}>
      <Table.Head>
        <Table.Row>
          {cols.map((col: any, i: any) => (
            <Table.Cell key={i} head style={col.style || {}}>
              {col.Header}
              {col.sort?.enabled && (
                <SortButton onClick={() => handleSortClick(col.accessor)} order={sortOrder[col.accessor]} />
              )}
            </Table.Cell>
          ))}
        </Table.Row>
      </Table.Head>

      <AggregateTableBody
        rowHeight={rowHeight}
        enableSelection={enableSelection}
        selection={selection}
        setSelection={setSelection}
        interactive={interactive}
        tableData={sortedTableData}
        {...props}
      />
    </Table>
  )
}

AggregateTable.propTypes = AggregateTableProps

export default AggregateTable

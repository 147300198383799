import React, { useState } from 'react'
import { Tooltip, Button } from '../../..'

interface INudgeButtonProps {
  canInvoiceBeNudged: boolean
  handleNudge: () => void
}

const NudgeButton = ({ canInvoiceBeNudged, handleNudge }: INudgeButtonProps): React.ReactElement => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const openTooltip = (): void => setIsTooltipOpen(true)

  const closeTooltip = (): void => setIsTooltipOpen(false)

  const onNudge = (): void => {
    openTooltip()
    handleNudge()
  }

  return canInvoiceBeNudged ? (
    <Button size="sm" secondary onClick={onNudge}>
      Nudge
    </Button>
  ) : (
    <Tooltip isOpen={isTooltipOpen} body="Nudge sent" onOuterAction={closeTooltip}>
      <Button size="sm" secondary onClick={openTooltip} disabled={!canInvoiceBeNudged}>
        Nudge
      </Button>
    </Tooltip>
  )
}

export default NudgeButton

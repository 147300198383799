import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { $TSFixMe } from 'types/ts-migrate'
import { AgenciesContent } from '..'
import { superSupportAccessEmails } from 'constants/general'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../views/components'
import { SupportStaffProvider } from '../../supportStaff'
import AgenciesProvider from '../AgenciesProvider'

const AgenciesPage = ({ path, currentReference, isAccessAllowed }: $TSFixMe) => {
  return !isAccessAllowed(superSupportAccessEmails) ? (
    <Redirect
      to={{
        pathname: '/dashboard',
        state: { from: path },
      }}
    />
  ) : (
    // @ts-expect-error
    <LayoutProvider
      stateKey="agenciesPage"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: currentReference !== undefined, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Admin | Agencies</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutPrimaryPanel></LayoutPrimaryPanel>
          <LayoutContent>
            <TableProvider>
              <AgenciesContent />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(
  [AgenciesProvider, SupportStaffProvider],
  ({ match, currentReference }: $TSFixMe, { isAccessAllowed }: $TSFixMe) => ({
    path: match.path,
    currentReference,
    isAccessAllowed,
  }),
)(AgenciesPage)

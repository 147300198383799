import React, { useState, useEffect } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { format, subMonths } from 'date-fns'
import { path } from 'ramda'
import { StringParam, useQueryParam } from 'use-query-params'
import { useEffectOnce } from 'react-use'

const propTypes = {
  downloadStatementCsv: PropTypes.func,
}

const ViewStatementProvider = moduleContext()(({ context, children, ...props }: any) => {
  const currentDate = new Date()
  const [startDate, setStartDate] = useQueryParam('startDate', StringParam)
  const [endDate, setEndDate] = useQueryParam('endDate', StringParam)
  const [isLoading, setIsLoading] = useState(true)

  useEffectOnce(() => {
    if (!startDate) {
      setStartDate(format(subMonths(currentDate, 1), 'yyyy-MM-dd'))
    }
    if (!endDate) {
      setEndDate(format(currentDate, 'yyyy-MM-dd'))
    }
  })

  useEffect(() => {
    setIsLoading(true)
  }, [startDate, endDate])

  useEffect(() => {
    if (path(['statementData', 'context'], props)) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.statementData])

  const store = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isLoading,
    ...props,
  }
  return <context.Provider value={store}>{children}</context.Provider>
})

ViewStatementProvider.propTypes = propTypes

export default ViewStatementProvider

import React, { useEffect, useState } from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import Dimmer from '../../atoms/Dimmer/Dimmer'
import { Form } from 'formik'
import { useDebounce } from 'react-use'
import DimmerLoader from '../DimmerLoader/DimmerLoader'
import styles from './FormLoader.module.scss'
import cx from 'classnames'

interface ButtonProps {
  children: string
  disabled?: boolean
  type?: string
}

interface TProps {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
  state: undefined | 'loading' | 'submitting' | 'error' | 'clear'
  buttonProps?: ButtonProps
  ButtonContainer?: React.ElementType
  submittingText?: string
  successText?: string
  errorText?: string
  persistErrorMessage?: boolean
  persistSuccessMessage?: boolean
  isDisabled?: boolean
  children: React.ReactNode
  alwaysEnabledChildren?: () => React.ReactNode
}

const FormLoader = ({
  onSubmit,
  state,
  buttonProps,
  ButtonContainer,
  submittingText = 'Submitting...',
  successText = 'Submission successful',
  errorText = 'Resolve errors',
  persistErrorMessage,
  persistSuccessMessage,
  isDisabled,
  children,
  alwaysEnabledChildren,
}: TProps): React.ReactElement => {
  const [wasSubmitting, setWasSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [, cancelSuccess] = useDebounce(
    () => {
      if (!persistSuccessMessage) {
        setSuccess(false)
      }
    },
    1500,
    [success],
  )

  const [, cancelError] = useDebounce(
    () => {
      if (!persistErrorMessage) {
        setError(false)
      }
    },
    1500,
    [error],
  )

  useEffect(() => {
    if (state === 'submitting') {
      setWasSubmitting(true)
    } else {
      setWasSubmitting(false)
    }

    if (wasSubmitting && state !== 'error') {
      setWasSubmitting(false)
      setSuccess(true)
      cancelSuccess()
    }

    if (state === 'error') {
      setWasSubmitting(false)
      setError(true)
      cancelError()
    } else {
      setError(false)
    }

    if (state === 'clear') {
      setSuccess(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, cancelSuccess])

  const buttonState = error ? 'error' : success ? 'success' : state === 'submitting' ? 'loading' : undefined

  const stateText = error ? errorText : success ? successText : state === 'submitting' ? submittingText : ''

  const Button = (
    <span className={styles['button-wrapper']}>
      <AnimatedButton type="submit" disabled={state === 'loading'} state={buttonState} {...buttonProps}>
        {buttonProps?.children || 'Submit'}
      </AnimatedButton>
      {stateText && (
        <span className={cx([styles['state-text'], styles[`state-text--${buttonState as string}`]])}>{stateText}</span>
      )}
    </span>
  )

  return (
    <Form onSubmit={onSubmit}>
      {state === 'loading' ? (
        <DimmerLoader loading={true} bgColor={'transparent'}>
          <fieldset disabled={isDisabled}>
            {children}
            {alwaysEnabledChildren ? alwaysEnabledChildren() : null}
          </fieldset>
        </DimmerLoader>
      ) : state === 'submitting' ? (
        <Dimmer active={true}>
          {children}
          {alwaysEnabledChildren ? alwaysEnabledChildren() : null}
        </Dimmer>
      ) : (
        <>
          <fieldset disabled={isDisabled}>{children}</fieldset>
          {alwaysEnabledChildren ? alwaysEnabledChildren() : null}
        </>
      )}
      <fieldset disabled={isDisabled}>
        {ButtonContainer ? <ButtonContainer>{Button}</ButtonContainer> : Button}
      </fieldset>
    </Form>
  )
}

export default FormLoader

import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { userApiSelectors } from '../../api/user'
import { insert, flatten, pipe } from 'ramda'

const state = (state: any) => state

/**
 * Progress
 */
const getProgress = createSelector(state, state => userApiSelectors.getOnboardingProgress(state))

const getProfileVerificationProgress = createSelector(state, s => get(getProgress(s), 'profileVerification', 0))

const getAgencyDetailsProgress = createSelector(state, s => get(getProgress(s), 'agencyDetails', 0))

const getAgencyBrandingProgress = createSelector(state, s => get(getProgress(s), 'agencyBranding', 0))

const getPropertyProgress = createSelector(state, s => get(getProgress(s), 'propertyProgress', 0))

/**
 * Status
 */
const getStatus = createSelector(state, s => (selector: any) => selector(s) === 100 ? 'complete' : 'untouched')

export const getProfileVerificationStatus = createSelector(state, s => getStatus(s)(getProfileVerificationProgress))

export const getAgencyDetailsStatus = createSelector(state, s => getStatus(s)(getAgencyDetailsProgress))

export const getAgencyBrandingStatus = createSelector(state, s => getStatus(s)(getAgencyBrandingProgress))

export const getPropertyStatus = createSelector(state, s => getStatus(s)(getPropertyProgress))

export const getStartedSteps = createSelector(state, s => {
  const isComplete = (selector: any) => selector(s) === 'complete'
  const userRole = userApiSelectors.getUserRole(s)
  const hasAgency = userApiSelectors.getCurrentAgencyId(s) !== null

  let allSteps = [
    {
      title: 'Complete your Profile',
      description: 'We need a few details to help us verify and secure your account.',
      route: '/settings/profile',
      complete: getProfileVerificationProgress(s) >= 50,
    },
  ]

  // null means it's a new user that hasn't been invited.
  if (userRole === 'Owner' || userRole === null) {
    const ownerSteps = [
      {
        title: 'Confirm your Business Details',
        description:
          'Update business details for things like invoicing and reports to ensure your payments run smoothly.',
        route: '/settings/business-details',
        complete: isComplete(getAgencyDetailsStatus),
      },
      {
        title: 'Customise your Branding',
        description: 'Customise your business theme and branding.',
        route: '/settings/branding',
        complete: isComplete(getAgencyBrandingStatus),
        isDisabled: !hasAgency,
      },
      {
        title: 'Add your first Lease',
        description: 'Create your first lease, complete with smart invoicing & management settings.',
        route: '/leases/new/edit',
        complete: isComplete(getPropertyStatus),
        isDisabled: !hasAgency,
      },
    ]

    allSteps = pipe(
      insert(1, ownerSteps),
      flatten,
      // @ts-expect-error
    )(allSteps)
  }

  return allSteps
})

export const isOnboardingComplete = createSelector(state, s => {
  const steps = getStartedSteps(s)
  return steps.every(step => step.complete)
})

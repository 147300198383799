import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Notification.module.scss'
import CloseIcon from '../../atoms/Icons/Controls/Close'
import NotificationHeading from './NotificationHeading'
import NotificationBody from './NotificationBody'

export const propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  heading: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  children: PropTypes.element,
}

const Notification = (props: any) => {
  const { type, heading, message, onDismiss, children } = props
  const classNames = cx([styles.root, styles[type]])

  return (
    <div className={classNames}>
      <button className={styles.closeButton} onClick={onDismiss}>
        <CloseIcon />
      </button>
      {heading && <NotificationHeading>{heading}</NotificationHeading>}
      {message && (
        <NotificationBody>
          <p>{message}</p>
        </NotificationBody>
      )}
      {children}
    </div>
  )
}

Notification.propTypes = propTypes

export default Notification

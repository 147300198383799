import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { map, filter, mergeMap, pluck, switchMap, take, mergeAll } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { get, transform, forEach, isEqual, isObject } from 'lodash-es'
import { chatbotEvents } from './state'
import * as chatbotSelectors from './selectors'
import { uiEvents } from '../state'
import { userApiSelectors } from '../../api/user'
import { portfolioApiEvents } from '../../api/portfolio'
import { propertyApiSelectors, propertyApiEvents } from '../../api/property'
import {
  propertyDetailsDialog,
  isPropertySelected,
  getSelectedProperty,
  isPlaceSelected,
  getSelectedPlace,
} from './Dialogs/PropertyDetails'
import { ownerDetailsDialog } from './Dialogs/OwnerDetails'
import { tenantDetailsDialog } from './Dialogs/TenantDetails'
import { leaseTermDialog } from './Dialogs/LeaseTerm'
import { leaseRentAndFeesDialog } from './Dialogs/LeaseRentAndFees'
import { leaseSettingsDialog } from './Dialogs/LeaseSettings'
import { leaseCommissionDialog } from './Dialogs/LeaseCommission'
import { leaseInvoicesDialog } from './Dialogs/LeaseInvoices'
import { filterDialogTypes } from './Dialogs/ChatbotDialog'
import { leaseConfirmationDialog } from './Dialogs/LeaseConfirmation'
import { userDetailsDialog } from './Dialogs/UserDetails'
import { agencyDetailsDialog } from './Dialogs/AgencyDetails'
import { $TSFixMe } from 'types/ts-migrate'
import { agencyApiSelectors } from 'modules/api/agency'

export const injectPortfolioIdRedirectUrl = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.portfolioRedirect),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload: { path } }: { payloa... Remove this comment to see the full error message
    map(({ payload }) => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      return payload.replace(':id', id || 'new')
    }),
    map(uiEvents.redirect),
    tag('ChatBot/epic/injectPortfolioIdRedirectUrl'),
  )

const dialogsWithChangeDetection = [
  propertyDetailsDialog,
  ownerDetailsDialog,
  tenantDetailsDialog,
  leaseTermDialog,
  leaseRentAndFeesDialog,
  leaseSettingsDialog,
  leaseCommissionDialog,
  leaseInvoicesDialog,
  leaseConfirmationDialog,
  userDetailsDialog,
  agencyDetailsDialog,
]

const dialogDiffSelectors = {
  propertyDetails: chatbotSelectors.getPropertyDetailsDialog,
  ownerDetails: chatbotSelectors.getOwnerDetailsDialog,
  tenantDetails: chatbotSelectors.getTenantDetailsDialog,
  leaseTerm: chatbotSelectors.getLeaseTermsDialog,
  leaseRentAndFees: chatbotSelectors.getLeaseRentAndFeesDialog,
  leaseSettings: chatbotSelectors.getLeaseSettingsDialog,
  leaseCommission: chatbotSelectors.getLeaseCommissionDialog,
  leaseInvoices: chatbotSelectors.getLeaseInvoicesDialog,
  leaseConfirmation: chatbotSelectors.getLeaseConfirmationDialog,
  userDetails: chatbotSelectors.getCurrentUserDetailsDialog,
  agencyDetails: chatbotSelectors.getAgencyDetailsDialog,
  agencyBranding: chatbotSelectors.getAgencyBrandingDialog,
}

const resolveDialogSelector = (dialog: any) => {
  if (dialogDiffSelectors[dialog] === undefined) {
    throw new Error('No selector assigned for dialog ' + dialog + ' in Chatbot/epics.js')
  }
  return dialogDiffSelectors[dialog]
}

const dialogsByReducedEvents = transform(
  dialogsWithChangeDetection,
  (dialogs: any, value: any, key: any) => {
    forEach(value.reducesEvents, (eventName: any) => {
      dialogs[eventName] = (dialogs[eventName] || []).concat(value)
    })
  },
  {},
)

export const dialogCaptureStateOnReducedEvent = (action$: any, state$: any) =>
  action$.pipe(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ type }: { type: any; }) => an... Remove this comment to see the full error message
    map(({ type }) => dialogsByReducedEvents[type]),
    filter(dialogs => dialogs !== undefined),
    mergeMap((dialogs: $TSFixMe[]) =>
      dialogs.map((dialog: any) => {
        const dialogState = resolveDialogSelector(dialog.name)(state$.value)
        return { dialog: dialog.name, initialState: dialog.model, dialogState }
      }),
    ),
    filter(({ dialog, initialState, dialogState }) => !isEqual(initialState, dialogState)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ dialog, dialogState }: { dial... Remove this comment to see the full error message
    map(({ dialog, dialogState }) => chatbotEvents.captureDialogState({ dialog, dialogState })),
    tag('ChatBot/epic/dialogCaptureStateOnReducedEvent'),
  )

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
const difference = (object: any, base: any) => {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value: any, key: any) {
      if (base === undefined) {
        result[key] = value
        return
      }
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export const detectDialogChanges = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.dialogEvent),
    filterDialogTypes(dialogsWithChangeDetection),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload: { dialog } }: { payl... Remove this comment to see the full error message
    map(({ payload: { dialog } }) => ({
      previousState: chatbotSelectors.dialogSavedStateSelector(state$.value)(dialog),
      currentState: resolveDialogSelector(dialog)(state$.value),
      dialog,
    })),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ previousState, currentState, ... Remove this comment to see the full error message
    map(({ previousState, currentState, dialog }) => ({
      previousState:
        previousState ||
        get(
          dialogsWithChangeDetection.find(p => p.name === dialog),
          'model',
        ),
      currentState,
      dialog,
    })),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ previousState, currentState, ... Remove this comment to see the full error message
    map(({ previousState, currentState, dialog }) => ({
      dialog,
      changes: difference(currentState, previousState),
    })),
    filter(({ changes }) => !isEqual(changes, {})),
    // @ts-expect-error
    map(({ changes, dialog }) => chatbotEvents.dialogStateChanged({ dialog, changes })),
    tag('ChatBot/epic/detectDialogChanges'),
  )

export const createNewPortfolio = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.newPortfolioRequested),
    mergeMap(() => of(chatbotEvents.resetCurrentPortfolio(), uiEvents.redirect('/leases/new/edit'))),
    tag('ChatBot/epic/createNewPortfolio'),
  )

export const requestPortfolioOnLoad = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.loadPortfolioRequested),
    pluck('payload'),
    map((id: string) => portfolioApiEvents.portfolio_request(id)),
    tag('ChatBot/epic/loadPortfolioId'),
  )

export const setCurrentPortfolioOnLoadOnceFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.loadPortfolioRequested),
    switchMap(() =>
      action$.pipe(
        ofType(portfolioApiEvents.portfolio_success),
        take(1),
        pluck('payload'),
        map(chatbotEvents.setCurrentPortfolio),
      ),
    ),
    tag('ChatBot/epic/setCurrentPortfolioOnLoadOnceFetched'),
  )

export const confirmPropertyWithValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.propertyConfirmationRequested),
    pluck('payload'),
    mergeMap(payload => [
      of(payload).pipe(
        filter(() => isPropertySelected(state$.value)),
        mergeMap(payload => {
          const state = state$.value
          const id = getSelectedProperty(state)
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'unitNumber' does not exist on type 'unkn... Remove this comment to see the full error message
          const { unitNumber = '', buildingName = '' } = payload
          const placeId = propertyApiSelectors.getPropertyPlaceId(state)(id)
          const existingUnitNumber = propertyApiSelectors.getUnitNumberById(state)(id)
          const existingBuildingName = propertyApiSelectors.getBuildingNameById(state)(id)
          const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)
          const getCurrentAgencyGlobalVatEnabled = agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state)
          // create new property if unit number or building name differ
          if (unitNumber !== existingUnitNumber || buildingName !== existingBuildingName) {
            return [
              chatbotEvents.createPropertyFromConfirmationRequested(currentPortfolioId),
              propertyApiEvents.createProperty_request({ placeId, unitNumber, buildingName }),
              currentPortfolioId === null && chatbotEvents.setGlobalVat(getCurrentAgencyGlobalVatEnabled),
            ].filter(Boolean)
          } else {
            return [
              propertyDetailsDialog.proceed((events: any) => events.propertySelected(id, unitNumber, buildingName)),
              propertyDetailsDialog.proceed((events: any) => events.propertyConfirmed()),
              currentPortfolioId === null && chatbotEvents.setGlobalVat(getCurrentAgencyGlobalVatEnabled),
            ].filter(Boolean)
          }
        }),
      ),
      of(payload).pipe(
        filter(() => isPlaceSelected(state$.value)),
        map(payload => {
          const placeId = getSelectedPlace(state$.value)
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'unitNumber' does not exist on type 'unkn... Remove this comment to see the full error message
          const { unitNumber, buildingName } = payload
          return { placeId, unitNumber, buildingName }
        }),
        mergeMap(({ placeId, unitNumber, buildingName }) => {
          const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state$.value)
          const getCurrentAgencyGlobalVatEnabled = agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state$.value)
          return [
            chatbotEvents.createPropertyFromConfirmationRequested(currentPortfolioId),
            propertyApiEvents.createProperty_request({ placeId, unitNumber, buildingName }),
            currentPortfolioId === null && chatbotEvents.setGlobalVat(getCurrentAgencyGlobalVatEnabled),
          ].filter(Boolean)
        }),
      ),
    ]),
    mergeAll(),
    tag('ChatBot/epic/confirmPropertyWithValues'),
  )

export const submitTermValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.termValuesSubmitted),
    pluck('payload'),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...values, ...{ id } }
    }),
    filter(({ id }) => id !== undefined && id !== null),
    map(values => leaseTermDialog.proceed((e: any) => e.selectLeaseTerms(values))),
    tag('ChatBot/epic/submitTermValues'),
  )

export const submitRentValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.rentValuesSubmitted),
    pluck('payload'),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...values, ...{ id } }
    }),
    filter(({ id }) => id !== undefined && id !== null),
    map(values => leaseRentAndFeesDialog.proceed((e: any) => e.rentAndFeesSaved(values))),
    tag('ChatBot/epic/submitRentValues'),
  )

export const submitSettingsValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.settingsValuesSubmitted),
    pluck('payload'),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...values, ...{ id } }
    }),
    filter(({ id }) => id !== undefined && id !== null),
    map(values => leaseSettingsDialog.proceed((e: any) => e.settingsSaved(values))),
    tag('ChatBot/epic/submitSettingsValues'),
  )

export const submitCommissionValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.commissionValuesSubmitted),
    pluck('payload'),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...values, ...{ id } }
    }),
    filter(({ id }) => id !== undefined && id !== null),
    map(values => leaseCommissionDialog.proceed((e: any) => e.commissionSaved(values))),
    tag('ChatBot/epic/submitCommissionValues'),
  )

export const submitInvoiceValues = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.invoiceValuesSubmitted),
    pluck('payload'),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...values, ...{ id } }
    }),
    filter(({ id }) => id !== undefined && id !== null),
    map(values => leaseInvoicesDialog.proceed((e: any) => e.leaseInvoiceTemplatesSaved(values))),
    tag('ChatBot/epic/submitInvoiceValues'),
  )

export const amendPropertyToPortfolioOnConfirmationCreation = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.createPropertyFromConfirmationRequested),
    switchMap(() =>
      action$.pipe(
        ofType(propertyApiEvents.createProperty_success),
        take(1),
        pluck('payload'),
        mergeMap(({ id, unitNumber, buildingName }) =>
          of(
            propertyDetailsDialog.proceed((events: any) => events.propertySelected(id, buildingName, unitNumber)),
            propertyDetailsDialog.proceed((events: any) => events.propertyConfirmed()),
          ),
        ),
      ),
    ),
    tag('ChatBot/epic/amendPropertyToPortfolioOnConfirmationCreation'),
  )

export const approveCurrentPortfolio = (action$: any, state$: any) =>
  action$.pipe(
    ofType(chatbotEvents.currentPortfolioActivationRequested),
    map(values => {
      const id = chatbotSelectors.getCurrentPortfolioId(state$.value)
      const body = {
        user: userApiSelectors.getUserId(state$.value),
        userEmail: userApiSelectors.getUserEmail(state$.value),
        notificationEmail: userApiSelectors.getUserEmail(state$.value),
      }
      const params = { id }
      return portfolioApiEvents.approve_request({ body, params })
    }),
    tag('ChatBot/epic/approveCurrentPortfolio'),
  )

/**
 * @todo refactor approval related commands when needed.
 */
// export const sendForApproval = command.behaviorOfType('ui/chat_bot/send_for_approval', (query$, store) =>
//   query$.pipe(
//     map(values => {
//       const id = chatbotSelectors.getCurrentPortfolioId(store.getState())
//       return [
//         { id: id },
//         {
//           user: getUserId(store.getState()),
//           userEmail: getUserEmail(store.getState()),
//           notificationEmail: getUserEmail(store.getState())
//         }
//       ]
//     }),
//     /** @todo restful refactor - fix proceed */
//     tap(values => leaseConfirmationDialog.proceed(e => e.approvalRequested(values))),
//     map(p => portfolioCommands.requestApproval.passParams(p)),
//     portfolioCommands.requestApproval(),
//     tag('ChatBot/command/sendForApproval'),
//     ignoreElements()
//   )
// )

// export const declineCurrentPortfolio = command.behaviorOfType('ui/chat_bot/decline', (query$, store) =>
//   query$.pipe(
//     map(query => query.lift(payload => payload)),
//     map(values => {
//       const id = chatbotSelectors.getCurrentPortfolioId(store.getState())
//       return portfolioCommands.declinePortfolio.passParams([{ id }, { reason: values.reason }])
//     }),
//     portfolioCommands.declinePortfolio(),
//     tag('ChatBot/command/declineCurrentPortfolio'),
//     ignoreElements()
//   )
// )

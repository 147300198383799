import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, CurrencyText, TableProvider } from '../../../../views/components'
// import { partyApiEvents } from '../../../api/party'
import styles from './ViewBadReferences.module.scss'
import AllocationModal from '../Modal/AllocationModal/AllocationModal'

const ViewUnallocatedReference = ({ currentReference }: any): React.ReactElement => {
  // const dispatch = useDispatch()
  // const referenceUsed = currentReference?.referenceUsed

  // useEffect(() => {
  //   if (referenceUsed) {
  //     dispatch(partyApiEvents.badReferenceFuzzyMatches_request({ reference: referenceUsed }))
  //   }
  // }, [referenceUsed, dispatch])

  // const getFuzzyMatches = useSelector(partyApiSelectors.getBadReferenceFuzzyMatches)
  // const fuzzyMatches = getFuzzyMatches(referenceUsed)

  // const detailsCol = {
  //   Header: 'Details',
  //   accessor: 'agency',
  //   style: {
  //     maxHeight: '700px'
  //   }
  // }
  // const refCol = {
  //   Header: 'Reference',
  //   accessor: 'paymentReference'
  // }
  // const matchCol = {
  //   Header: 'Match',
  //   accessor: 'percentageMatch'
  // }
  // const columns = [detailsCol, refCol, matchCol]

  // const row = (ref) => {
  //   return {
  //     ...ref,
  //     agency: (
  //       <div>
  //         <strong>tenant @ {ref.agency.tradingAs}</strong> <br />
  //         <small>{ref.propertyAddress}</small>
  //       </div>
  //     ),
  //     percentageMatch: `${ref.percentageMatch}%`
  //   }
  // }
  // const styledFuzzyMatches = fuzzyMatches && map(row, fuzzyMatches)

  const [isOpen, setIsOpen] = useState(false)

  return (
    <TableProvider>
      <div>
        <div className={styles['title-text']}>
          <h2>Reference used: {currentReference?.referenceUsed}</h2>
          <span>
            {' '}
            <strong>Transaction ID:</strong> {currentReference?.txId.substr(0, 9)}...
          </span>
          <span>
            <strong>Amount:</strong> <CurrencyText>{currentReference?.amount || 0}</CurrencyText>
          </span>
        </div>
        {/* <p className={styles.info}>{fuzzyMatches.length === 0
          ? 'There are no bad fuzzy matches on this bad reference.'
          : 'Click on a fuzzy match in the table below to allocate or click the button for manual allocation'}
        </p> */}

        <Button size="sm" onClick={() => setIsOpen(true)}>
          Allocate Bad Reference
        </Button>
        {/* {fuzzyMatches.length !== 0 && <AggregateTable
          className={styles.table}
          columns={columns}
          data={styledFuzzyMatches}
          rowHeight={80}
        />
        } */}
      </div>
      <AllocationModal isOpen={isOpen} onClose={() => setIsOpen(false)} currentReference={currentReference} />
    </TableProvider>
  )
}

ViewUnallocatedReference.propTypes = {
  currentReference: PropTypes.shape({
    txId: PropTypes.string,
    referenceUsed: PropTypes.string,
    amount: PropTypes.number,
    dateReceived: PropTypes.string,
  }),
}

export default ViewUnallocatedReference

import React from 'react'

const SvgInvoiceFill = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M23 26H9a3 3 0 01-3-3V7a1 1 0 011-1h14a1 1 0 011 1v12h4v4a3 3 0 01-3 3zm-1-5v2a1 1 0 102 0v-2h-2zM10 11v2h8v-2h-8zm0 4v2h8v-2h-8zm0 4v2h5v-2h-5z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgInvoiceFill

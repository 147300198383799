import React, { useCallback, useMemo } from 'react'
import { subscribe } from 'react-contextual'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { format, isValid } from 'date-fns'
import { pathOr, path, find } from 'ramda'
import { Button, ControlledTooltip, Header } from '../../../../../../views/components'
import DepositProvider from '../../../../../depositManagement/DepositProvider'
import { uiEvents } from '../../../../../ui'
import { portfolioApiSelectors } from '../../../../../api/portfolio'
import { partyApiSelectors } from '../../../../../api/party'
import { walletApiSelectors } from '../../../../../api/wallet'
import RefundProvider from '../../../../../depositManagement/RefundProvider'
import styles from './DepositAccount.module.scss'
import { userApiSelectors } from '../../../../../api/user'
import { $TSFixMe } from 'types/ts-migrate'

const propTypes = {
  portfolioId: PropTypes.string.isRequired,
}

const DepositAccount = ({
  portfolioId,
  openRefundModal,
  openDepositTransferModal,
  openDepositTopupRequest,
  depositStatement,
  depositAccountBalance,
}: any) => {
  const dispatch = useDispatch()
  const handleDepositTransferRequest = useCallback(() => {
    openDepositTopupRequest(portfolioId)
  }, [portfolioId, openDepositTopupRequest])

  const tenantId = useSelector(state => portfolioApiSelectors.getPrimaryTenantByPortfolioId(state)(portfolioId))
  const tenantAccount = useSelector(state =>
    partyApiSelectors.getPartyAccountByPortfolioId(state)(tenantId, portfolioId, 'Tenant'),
  )

  const partyId = path(['partyId'], tenantAccount)
  const accountId = path(['accountId'], tenantAccount)
  const redirectTo = `/leases/${portfolioId}`
  const handleViewStatement = (): void => {
    dispatch(uiEvents.redirect(`/contacts/${partyId}/${accountId}/deposit-statement?redirectTo=${redirectTo}`))
  }
  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)

  const hasDepositAccount = useSelector(state => walletApiSelectors.hasDepositAccount(state)(partyId, accountId))

  const statement = useMemo(() => depositStatement, [depositStatement])
  const statementItems = pathOr([], ['context', 'statement', 'items'], statement)

  const partyHasBankDetails = useSelector(state => partyApiSelectors.partyHasBankDetails(state)(partyId))

  return (
    hasDepositAccount && (
      <section className={styles.root}>
        <Header
          className={styles.header}
          tag="h4"
          text="Deposit Wallet"
          trailingIcon={
            <Button pill tertiary size="sm" onClick={handleViewStatement}>
              View Statement
            </Button>
          }
        />
        <ul>
          {statementItems.map((item: any, i: any) => (
            <li key={i}>
              <span>{isValid(new Date(item.date)) ? format(new Date(item.date), 'dd MMM') : ''}</span>
              <span>{item.description}</span>
              <span>{item.balance}</span>
            </li>
          ))}
        </ul>
        <div className={styles.actions}>
          <Button pill ghost size="sm" onClick={handleDepositTransferRequest}>
            Request Top up
          </Button>
          {depositAccountBalance > 0 && isOwnerRole && (
            <>
              {partyHasBankDetails ? (
                <Button pill ghost size="sm" onClick={openRefundModal}>
                  Refund deposit
                </Button>
              ) : (
                // @ts-expect-error
                <ControlledTooltip body="No bank details" place="above">
                  <Button pill ghost size="sm" onClick={() => {}}>
                    Refund deposit
                  </Button>
                </ControlledTooltip>
              )}
              <Button pill ghost size="sm" onClick={openDepositTransferModal}>
                Transfer
              </Button>
            </>
          )}
        </div>
      </section>
    )
  )
}

DepositAccount.propTypes = propTypes

export default subscribe(
  [DepositProvider, RefundProvider],
  (
    { openDepositTransferModal, openDepositTopupRequest, depositStatement, depositAccountBalance }: $TSFixMe,
    { refund: { handleOpen } }: $TSFixMe,
  ) => ({
    openDepositTransferModal,
    openDepositTopupRequest,
    depositStatement,
    depositAccountBalance,
    openRefundModal: handleOpen,
  }),
)(DepositAccount)

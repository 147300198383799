import React from 'react'

const Invoice = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M24.605 10.878L19.73 6.366A1.417 1.417 0 0 0 18.777 6H8.35C7.604 6 7 6.56 7 7.25v17.5c0 .69.604 1.25 1.35 1.25h16.2c.249 0 .45-.187.45-.417V11.762c0-.332-.141-.65-.395-.884zm-2.088 7.414c.372 0 .675.28.675.625s-.303.625-.675.625h-4.5c-.373 0-.675-.28-.675-.625 0-.346.302-.625.675-.625h4.5zm-3.375-3.125c0-.346.302-.625.675-.625h2.7c.372 0 .675.28.675.625s-.303.625-.675.625h-2.7c-.373 0-.675-.28-.675-.625zm3.375 6.875c.372 0 .675.28.675.625s-.303.625-.675.625h-9c-.373 0-.675-.28-.675-.625 0-.346.302-.625.675-.625h9zm-12.61-6.498V9h2.545c1.766 0 2.56.91 2.56 2.146 0 .93-.448 1.658-1.408 1.962l1.434 2.436a.303.303 0 0 1-.261.456.92.92 0 0 1-.796-.458l-1.323-2.28c-.062.004-.125.004-.191.004h-1.648v2.278a.456.456 0 0 1-.912 0zm.912-3.044h1.618c1.207 0 1.678-.513 1.678-1.354 0-.84-.471-1.394-1.692-1.394h-1.604V12.5z"
    />
  </svg>
)

export default Invoice

import React from 'react'
import classNames from 'classnames'
import styles from './VatIcon.module.scss'

interface VatIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string
  enabled?: boolean
}

const VatIcon = ({ className, enabled = false, ...props }: VatIconProps) => {
  const classes = classNames([styles.root, className], {
    [styles['is-enabled']]: enabled,
  })

  return (
    <span {...props} className={classes}>
      VAT
    </span>
  )
}

export default VatIcon

import React from 'react'

const SvgSegments = ({ stroke = '#000', ...props }): React.ReactElement => (
  <svg width={32} height={32} {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      opacity={0.64}
    >
      <path d="M15.75 19h5.75a.5.5 0 01.5.5v7.75h-6.75V19.5a.5.5 0 01.5-.5z" />
      <path d="M22 27.25h5.25v-12a.75.75 0 00-.75-.75h-6.75a.75.75 0 00-.75.75V19M15.25 27.25h-1.5M19 22h3M25 19h2.25M25 22h2.25M14.5 4.75v4.5M5.223 11.5l4.282 1.385M11.8 19a5.25 5.25 0 117.011-7.5M11.5 23.78A9.752 9.752 0 1123.78 11.5" />
    </g>
  </svg>
)

export default SvgSegments

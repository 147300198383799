const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'api.accounting'

export const ENDPOINTS = {
  FETCH_BANK_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/banking/statement/json`,
  FETCH_BANK_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/banking/statement/pdf`,
  FETCH_CUSTOMER_STATEMENTS: `${REACT_APP_API_URL}/accounting/customers`,
  FETCH_CUSTOMER_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/customers/:customerId/:accountId/statement/pdf`,
  FETCH_CUSTOMER_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/customers/:customerId/:accountId/statement/json`,
  LEASE_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/lease/statement/:portfolioId/json`,
  LEASE_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/lease/statement/:portfolioId/pdf`,
  DEPOSIT_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/customers/:id/:accountId/deposit_statement/json`,
  DEPOSIT_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/customers/:id/:accountId/deposit_statement/pdf`,
  DEPOSIT_BANK_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/banking/deposit_statement/json`,
  DEPOSIT_BANK_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/banking/deposit_statement/pdf`,
  COMMISSION_STATEMENT_JSON: `${REACT_APP_API_URL}/accounting/commission/statement/json`,
  COMMISSION_STATEMENT_CSV: `${REACT_APP_API_URL}/accounting/commission/statement/csv`,
  COMMISSION_STATEMENT_PDF: `${REACT_APP_API_URL}/accounting/commission/statement/pdf`,
  GET_STATS_ACCOUNTING: `${REACT_APP_API_URL}/accounting/stats`,
}

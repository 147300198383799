import React from 'react'
import { Helmet } from 'react-helmet'
import { LayoutProvider, Layout, LayoutBody, LayoutContent, ListingTemplate } from '../../../views/components'
import IntegrationsContent from './IntegrationsContent/IntegrationsContent'

const IntegrationsPage = () => {
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="integrations"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: false, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Integrations</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutContent>
            <ListingTemplate Body={<IntegrationsContent />} />
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default IntegrationsPage

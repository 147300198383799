import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentContainer.module.scss'

const ContentContainer = ({ children }: any) => <div className={styles.root}>{children}</div>

ContentContainer.propTypes = {
  children: PropTypes.node,
}

export default ContentContainer

import React from 'react'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { agencyApiEvents, agencyApiSelectors } from '../api/agency'
import { apiStateSelectors } from '../api/apiState'
import { uiEvents } from '../ui'

const SegmentsProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props

  const segments = useSelector(agencyApiSelectors.getSegments)

  const filters = [
    {
      key: 'all',
      label: 'All',
      segments: segments,
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      count: segments.length,
    },
  ]

  const segmentsStore = {
    match,
    filters,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    isLoading: useSelector(state => apiStateSelectors.isLoading(state)([agencyApiEvents.createSegment_request])),
    ...props,
  }
  return <context.Provider value={segmentsStore}>{children}</context.Provider>
})

export default SegmentsProvider

import React from 'react'

const Add = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M26 7.304C26 6.584 25.416 6 24.696 6H7.304C6.584 6 6 6.584 6 7.304v17.392C6 25.416 6.584 26 7.304 26h17.392c.72 0 1.304-.584 1.304-1.304V7.304zM10.783 16.87a.87.87 0 1 1 0-1.74h4.13c.12 0 .217-.097.217-.217v-4.13a.87.87 0 1 1 1.74 0v4.13c0 .12.097.217.217.217h4.13a.87.87 0 1 1 0 1.74h-4.13a.217.217 0 0 0-.217.217v4.13a.87.87 0 1 1-1.74 0v-4.13a.217.217 0 0 0-.217-.217h-4.13z"
    />
  </svg>
)

export default Add

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NAMESPACE } from './constants'

const emailApiSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  reducers: {
    invoicePdf_request: (state, action: InvoicePDFRequestAction) => {},
    invoicePdf_success: (state, { payload }: InvoicePDFSuccessAction) => {},
  },
})

export type InvoicePDFRequest = { invoiceId: string; invoiceNumber: string }
export type InvoicePDFRequestAction = PayloadAction<InvoicePDFRequest, string>
export type InvoicePDFResponse = { pdfBase64: string }
export type InvoicePDFSuccessAction = PayloadAction<InvoicePDFResponse, string>

const { actions: emailApiEvents } = emailApiSlice

export { emailApiEvents }

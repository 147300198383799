import React from 'react'
import { subscribe } from 'react-contextual'
import { InvoicesProvider } from '../../../../providers'
import { SearchFilter } from '../../..'
import styles from './SearchFilter.module.scss'

const DraftInvoicesSearchFilter = (props: any) => (
  <SearchFilter className={styles['draft-invoice-filters']} {...props} />
)

export default subscribe([InvoicesProvider], ({ location, redirect, draftInvoiceFilters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters: draftInvoiceFilters,
}))(DraftInvoicesSearchFilter)

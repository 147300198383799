import ReactGA from 'react-ga'
import { IS_PRODUCTION } from '../../constants/general'

const options = {}

const trackPage = (page: any) => {
  ReactGA.set({
    page,
    ...options,
  })
  ReactGA.pageview(page)
}

let currentPage = ''

export default (store: any) => (next: any) => (action: any) => {
  if (IS_PRODUCTION && action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`
    if (currentPage !== nextPage) {
      currentPage = nextPage
      trackPage(nextPage)
    }
  }
  return next(action)
}

import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { agencyApiSelectors } from '../../api/agency'

const state = (state: any) => state

// const settings = state => state.ui.settings

export const getProfileInitialValues = createSelector(state, state => {
  const agency = agencyApiSelectors.getCurrentAgency(state)
  return get(agency, 'agency', {
    agencyDetails: {
      companyName: '',
      trading: '',
      registrationNumber: '',
      vatNumber: '',
      ffcNumber: '',
    },
    agencyAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
      country: '',
    },
    agencyBankDetails: {
      bank: '',
      accountNumber: '',
      accountType: '',
      branchCode: '',
    },
    agencyContactDetails: {
      emailAddress: '',
      telephoneNumber: '',
    },
  })
})

export const getBrandingInitialValues = createSelector(state, state => {
  const themes = agencyApiSelectors.getThemes(state)
  const theme =
    agencyApiSelectors.getCurrentAgencyTheme(state) || themes.find((t: any) => t.name.toLowerCase() === 'black')
  const logo = agencyApiSelectors.getCurrentAgencyLogo(state)
  return { theme, logo }
})

import React from 'react'
import PropTypes from 'prop-types'
import styles from './Shortcut.module.scss'

const propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
}

const Shortcut = ({ icon, title, subtitle, onClick }: any) => (
  <div className={styles.root}>
    <button onClick={onClick}>
      <span className={styles.icon}>{icon}</span>
      <div className={styles.text}>
        <h4>{title}</h4>
        <span>{subtitle}</span>
      </div>
    </button>
  </div>
)

Shortcut.propTypes = propTypes

export default Shortcut

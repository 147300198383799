import React, { useCallback, useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { subscribe } from 'react-contextual'
import { path, pathOr } from 'ramda'
import { ROUTES } from 'constants/routes'
import { ContactsProvider } from '../../../contacts'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { $TSFixMe } from 'types/ts-migrate'
import { RootState } from 'src/state/store'

const CustomerStatement = ({ match, redirect }: $TSFixMe): null | React.ReactElement => {
  const editMatchPath = matchPath(match.url, {
    path: ROUTES.contactStatement,
    exact: true,
    strict: false,
  })

  const customerId: $TSFixMe = path(['params', 'id'], editMatchPath)
  const accountId: $TSFixMe = path(['params', 'accountId'], editMatchPath)
  const dispatch = useDispatch()

  const fetchCustomerStatement = useCallback(
    (startDate, endDate, json = true) => {
      if (customerId && accountId) {
        const event = json
          ? accountingApiEvents.customerStatementJson_request
          : accountingApiEvents.customerStatementPdf_request
        dispatch(
          event({
            customerId,
            accountId,
            start_date: startDate,
            end_date: endDate,
          }),
        )
      }
    },
    [customerId, accountId, dispatch],
  )

  const fetchCustomerStatementPdf = useCallback(
    (startDate, endDate) => fetchCustomerStatement(startDate, endDate, false),
    [fetchCustomerStatement],
  )
  const statementData = useSelector((state: RootState) =>
    accountingApiSelectors.getCustomerStatementJsonById(state)(customerId, accountId),
  )
  const statementPdf = useSelector((state: RootState) =>
    accountingApiSelectors.getCustomerStatementPdfById(state)(customerId, accountId),
  )

  const pdfFileName = pathOr('', ['context', 'customer', 'name'], statementData)
  const isOpen = customerId && accountId

  return useMemo(() => {
    return isOpen ? (
      <ViewStatementProvider
        onClose={() => redirect(`/contacts/${customerId}`)}
        onParamChange={fetchCustomerStatement}
        isOpen={isOpen}
        statementData={statementData}
        downloadStatement={fetchCustomerStatementPdf}
        statementPdf={statementPdf}
        pdfFileName={pdfFileName}
      >
        <ViewStatement title="Customer Statement" />
      </ViewStatementProvider>
    ) : null
  }, [
    redirect,
    customerId,
    fetchCustomerStatement,
    fetchCustomerStatementPdf,
    statementData,
    statementPdf,
    isOpen,
    pdfFileName,
  ])
}

export default subscribe([ContactsProvider], ({ match, redirect }: $TSFixMe) => ({ match, redirect }))(
  CustomerStatement,
)

import React from 'react'

const SvgCheck = (props: any) => {
  const { width = 32, height = 32 } = props

  return (
    <svg width={width} height={height} viewBox="0 0 32 32" {...props}>
      <title>{props.title}</title>
      <path
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
        d="M14 19.172l9.192-9.193 1.415 1.414L14 22l-6.364-6.364 1.414-1.414z"
      />
    </svg>
  )
}

export default SvgCheck

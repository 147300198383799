import { of } from 'rxjs'
import { concatMap, flatMap, ignoreElements, map, mapTo, mergeAll, mergeMap, pluck, tap } from 'rxjs/operators'
import { get } from 'lodash-es'
import deepmerge from 'deepmerge'
import { ofType } from 'redux-observable'
import jwtDecode from 'jwt-decode'
import { tag } from 'rxjs-spy/operators/tag'
import { IS_TEST_MODE } from 'constants/general'
import { agencyApiEvents } from '../agency'
import { userApiEvents, userApiSelectors } from '.'
import { ENDPOINTS } from './constants'
import { deleteTokens, getMisc, isTokenExpired, setMisc, setTokensFromResponse } from 'utils/auth'
import { multiUserOperators } from '../../multiUser/state'
import { uiEvents } from '../../ui/state'
import { agenciesWithOutstandingAccount } from './constants'
import { AnyAction, Observable } from 'redux'
import { $TSFixMe } from 'types/ts-migrate'
import { restfulErrorEvent } from 'utils/restful'
import { PayloadAction } from '@reduxjs/toolkit'

export const prefetchUsersOnAgencyMembersFetched = (action$: any, state$: any) =>
  action$.pipe(
    ofType(agencyApiEvents.users_success),
    flatMap(({ payload }) =>
      payload.map((p: any) => ({
        id: p.userId,
      })),
    ),
    map(userApiEvents.userDetails_request),
    tag('user/epic/prefetchUsersOnAgencyMembersFetched'),
  )

/**
 * @todo should check JWT age based on api funnel incoming events (with a timer)
 */
// const token = getJWTToken()
// if (token && isTokenExpired(token) && !store.getState().user.isRefreshingToken) {
//   store.dispatch(userActions.apiRefreshToken())

export const setJwtTokens = (action$: any) =>
  action$.pipe(
    ofType(
      userApiEvents.signup_success,
      userApiEvents.passwordReset_success,
      userApiEvents.agencySwitched,
      userApiEvents.login_success,
      userApiEvents.agencyLogin_success,
      userApiEvents.refreshToken_success,
    ),
    pluck('payload'),
    tap(setTokensFromResponse),
    tag('user/epic/setJwtTokens'),
    ignoreElements(),
  )

const decodeUserAuthTokens = (event$: any) =>
  event$.pipe(
    mergeMap(event =>
      of(event).pipe(
        map(payload => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'authToken' does not exist on type 'unkno... Remove this comment to see the full error message
          const { authToken, refreshToken } = payload
          const decoded = jwtDecode(authToken)
          if (!IS_TEST_MODE && isTokenExpired(decoded)) {
            return userApiEvents.userAuthTokenExpired({
              source: payload,
              refreshToken,
            })
          }
          return userApiEvents.userAuthTokenDecoded({
            source: payload,
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...decoded,
            // aud: JSON.parse(get(decoded, 'aud.0', '[]')),
            // sub: JSON.parse(get(decoded, 'sub', '[]'))
          })
        }),
      ),
    ),
  )

export const refreshToken = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.refreshToken_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      put(ENDPOINTS.REFRESH_TOKEN, userApiSelectors.getRefreshToken(state$.value), action.payload).pipe(
        pluck('response'),
        mergeMap(payload =>
          of(payload).pipe(
            decodeUserAuthTokens,
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            concatMap(event => [event, userApiEvents.refreshToken_success(event.payload.source)]),
          ),
        ),
        catchRestError(action),
      ),
    ),
    tag('user/epics/refreshToken'),
  )

export const loginUser = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.login_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.LOGIN, null, action.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        concatMap((event: $TSFixMe) => {
          const events = [event]
          if (agenciesWithOutstandingAccount.includes(event.payload?.value?.agencyMembership?.agencyId)) {
            events.push(
              restfulErrorEvent(
                {
                  response: {
                    errors: [
                      {
                        key: 'email',
                        message: 'You have an outstanding balance on your account. Please contact accounts@reos.co.za',
                      },
                    ],
                  },
                },
                {
                  initAction: action,
                  reduceByPayload: true,
                },
              ),
            )
          } else {
            events.push(userApiEvents.login_success(event.payload.source))
          }
          return events
        }),
        catchRestError(action),
      ),
    ),
    tag('user/epics/loginUser'),
  )

export const agencyLogin = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.agencyLogin_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.AGENCY_LOGIN, state$, action.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        concatMap(event => [event, userApiEvents.agencyLogin_success(event.payload.source)]),
        catchRestError(action),
      ),
    ),
    tag('user/epics/agencyLogin'),
  )

export const registerUser = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.signup_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.REGISTER, null, action.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        concatMap(event => [event, userApiEvents.signup_success(event.payload.source)]),
        catchRestError(action),
      ),
    ),
    tag('user/epics/registerUser'),
  )

export const registerUserFromInvite = (action$: any, state$: any, { post, catchRestError }: any) => {
  return action$.pipe(
    ofType(userApiEvents.signupFromInvite_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.REGISTER_FROM_INVITE, null, action.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        concatMap(event => [event, userApiEvents.signup_success(event.payload.source)]),
        catchRestError(action),
      ),
    ),
    tag('user/epics/registerUserFromInvite'),
  )
}

export const resumeUser = (action$: any, state$: any) =>
  action$.pipe(
    ofType(userApiEvents.userResumed),
    pluck('payload'),
    decodeUserAuthTokens,
    concatMap(p => [
      of(p),
      of(p).pipe(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(source: Observable<Action<any>>... Remove this comment to see the full error message
        ofType(userApiEvents.userAuthTokenDecoded),
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        mapTo(userApiEvents.login_success(p.payload.source)),
      ),
    ]),
    mergeAll(),
    tag('user/epics/loginUser'),
  )

export const logoutUser = (action$: any, state$: any): Observable<any> =>
  action$.pipe(
    ofType(userApiEvents.logout_request),
    pluck('payload'),
    tap(deleteTokens),
    mergeMap(payload => {
      const timeOut = get(payload, 'timeOut', false)
      const events: AnyAction[] = []
      if (timeOut) {
        events.push(userApiEvents.logout_success({ timeOut }))
      }
      events.push(userApiEvents.logout_success(null))
      events.push(uiEvents.redirect('/login'))
      return events
    }),
    tag('user/epics/logoutUser'),
  )

export const forgotPassword = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.resetPasswordLink_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.FORGOT_PASSWORD, state$, action.payload).pipe(
        pluck('response'),
        map(userApiEvents.resetPasswordLink_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/forgotPassword'),
  )

export const resetPassword = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.passwordReset_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.RESET_PASSWORD, action.payload.token, action.payload.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        concatMap(event => [event, userApiEvents.passwordReset_success(event.payload.source)]),
        catchRestError(action),
      ),
    ),
    tag('user/epics/resetPassword'),
  )

export const getCurrentUserDetails = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.currentUserDetails_request),
    mergeMap(action =>
      get(ENDPOINTS.GET_CURRENT_USER_DETAILS, state$).pipe(
        pluck('response'),
        map(userApiEvents.currentUserDetails_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/getCurrentUserDetails'),
  )

export const getUserDetails = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.userDetails_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.GET_USER_DETAILS, state$, action.payload).pipe(
        pluck('response'),
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        map(response => userApiEvents.userDetails_success({ ...response, ...action.payload })),
        catchRestError(action),
      ),
    ),
    tag('user/epics/getUserDetails'),
  )

export const saveUserDetails = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.saveDetails_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.GET_CURRENT_USER_DETAILS, state$, action.payload).pipe(
        pluck('response'),
        map(userApiEvents.saveDetails_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/saveUserDetails'),
  )

export const validateOtp = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.validateOtp_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.VALIDATE_OTP, state$, action.payload).pipe(
        pluck('response'),
        map(userApiEvents.validateOtp_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/validateOtp'),
  )

export const verifyEmail = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.verifyEmail_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.VERIFY_EMAIL, state$, action.payload).pipe(
        pluck('response'),
        decodeUserAuthTokens,
        concatMap(event => [
          event,
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          userApiEvents.verifyEmail_success(),
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          userApiEvents.login_success(event.payload.source),
        ]),
        catchRestError(action),
      ),
    ),
    tag('user/epics/verifyEmail'),
  )

export const resendEmailVerification = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.resendEmailVerification_request),
    mergeMap(action =>
      post(ENDPOINTS.RESEND_VERIFICATION, state$).pipe(
        pluck('response'),
        map(userApiEvents.resendEmailVerification_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/resendEmailVerification'),
  )

export const createAgency = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.createAgency_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.CREATE_AGENCY, state$, action.payload).pipe(
        pluck('response'),
        mergeMap(payload =>
          of(payload).pipe(
            decodeUserAuthTokens,
            concatMap((event: $TSFixMe) => [
              event,
              userApiEvents.createAgency_success(event.payload.source),
              userApiEvents.agencySwitched(event.payload.source),
            ]),
          ),
        ),
        catchRestError(action),
      ),
    ),
    tag('user/epics/createAgency'),
  )

export const updateMisc = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.updateMisc_request),
    mergeMap(event =>
      of(event).pipe(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ payload }: { payload: any; })... Remove this comment to see the full error message
        map(({ payload }) => {
          const misc = getMisc()
          return misc ? deepmerge(misc, payload) : payload
        }),
        mergeMap(action =>
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          put(ENDPOINTS.UPDATE_MISC, state$, action.payload).pipe(
            pluck('response'),
            map(userApiEvents.updateMisc_success),
            tap(({ payload }) => setMisc(payload)), // Update storage
            catchRestError(action),
          ),
        ),
      ),
    ),
    tag('user/epics/updateMisc'),
  )

export const getOnboardingProgress = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.onboardingProgress_request),
    mergeMap(action =>
      get(ENDPOINTS.ONBOARDING_PROGRESS, state$).pipe(
        pluck('response'),
        map(userApiEvents.onboardingProgress_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/getOnboardingProgress'),
  )

export const fetchTeam = (action$: any, state$: any, { get, catchRestError }: any) => {
  return action$.pipe(
    ofType(userApiEvents.team_request),
    multiUserOperators.filterOnboardedAndOwner(state$),
    mergeMap(action =>
      get(ENDPOINTS.TEAM, state$).pipe(pluck('response'), map(userApiEvents.team_success), catchRestError(action)),
    ),
    tag('user/epics/fetchTeam'),
  )
}

export const revokeAccess = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.revokeAccess_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.REVOKE_ACCESS, state$, action.payload).pipe(
        pluck('response'),
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        map(res => userApiEvents.revokeAccess_success(res, action.payload)),
        catchRestError(action),
      ),
    ),
    tag('user/epics/revokeAccess'),
  )

export const changePermissions = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.changePermissions_request),
    mergeMap((action: PayloadAction<{ body: { role: string; agencyId: string }; params: { userId: string } }>) =>
      put(ENDPOINTS.PERMISSIONS, state$, action.payload.body, action.payload.params).pipe(
        pluck('response'),
        map((res: $TSFixMe) => userApiEvents.changePermissions_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('user/epics/changePermissions'),
  )

export const enableUser = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.enableUser_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: 'action' is of type 'unknown'.
      post(ENDPOINTS.ENABLE_USER, state$, action.payload).pipe(
        pluck('response'),
        map(userApiEvents.enableUser_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/enableUser'),
  )

export const disableUser = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(userApiEvents.disableUser_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: 'action' is of type 'unknown'.
      post(ENDPOINTS.DISABLE_USER, state$, action.payload).pipe(
        pluck('response'),
        map(userApiEvents.disableUser_success),
        catchRestError(action),
      ),
    ),
    tag('user/epics/disableUser'),
  )

/**
 * Not currenctly in use. Memberships are added/removed in src/modules/bookSegments/state/epics.js
 */
// export const addSegmentMembership = (action$, state$, { post, catchRestError }) =>
//   action$.pipe(
//     ofType(userApiEvents.addSegmentMembership_request),
//     mergeMap(action => {
//       const { body, params } = action.payload
//       return post(ENDPOINTS.SEGMENTS, state$, body, params)
//         .pipe(
//           pluck('response'),
//           map(res => userApiEvents.addSegmentMembership_success(res, action.payload)),
//           catchRestError(action)
//         )
//     }),
//     tag('user/epics/addSegmentMembership')
//   )

/**
 * Not currenctly in use. Memberships are added/removed in src/modules/bookSegments/state/epics.js
 */
// export const deleteSegmentMembership = (action$, state$, { post, catchRestError }) =>
//   action$.pipe(
//     ofType(userApiEvents.deleteSegmentMembership_request),
//     mergeMap(action =>
//       post(ENDPOINTS.SEGMENTS, state$, action.payload)
//         .pipe(
//           pluck('response'),
//           map(res => userApiEvents.deleteSegmentMembership_success(res, action.payload)),
//           catchRestError(action)
//         )
//     ),
//     tag('user/epics/deleteSegmentMembership')
//   )

import { createSlice } from '@reduxjs/toolkit'
import { toDate } from 'date-fns'
import { invoiceApiEvents } from 'modules/api/invoice'
import { $TSFixMe } from 'types/ts-migrate'
import { reconApiEvents } from '../../api/recon'

export const initialState = {
  newInvoice: {
    modalOpen: false,
  },
  invoiceView: {
    editing: false,
  },
  nudgedInvoices: [],
  draftInvoices: {
    setMode: [],
  },
  currentInvoice: null,
  animations: {
    bulkDelete: [],
    bulkRestore: [],
    bulkSend: [],
    bulkSendErrors: [],
    removeFromSetMode: [],
    bulkApprovePayments: [],
    bulkNudge: [],
  },
}

const slice = createSlice({
  name: 'invoiceUi',
  initialState,
  reducers: {
    toggleNewModal: (state, { payload }) => {
      state.newInvoice.modalOpen = !state.newInvoice.modalOpen
    },
    toggleEdit: (state, { payload }) => {
      state.invoiceView.editing = !state.invoiceView.editing
    },
    invoiceOpened: (state, { payload }) => {
      state.currentInvoice = payload.invoiceId
    },
    closeInvoice: (state, { payload }) => {
      state.currentInvoice = null
    },
    // Draft Invoices
    enterDraftSetMode: (state, { payload }) => {
      state.draftInvoices.setMode = payload
    },
    removeFromDraftSetMode: (state, { payload }) => {
      state.draftInvoices.setMode = state.draftInvoices.setMode.filter(id => id !== payload)
    },
    resetDraftSetMode: (state, { payload }) => {
      state.draftInvoices.setMode = []
    },
    bulkActionDelete: (state, { payload }) => {
      state.animations.bulkDelete = payload
    },
    bulkActionRestore: (state, { payload }) => {
      state.animations.bulkRestore = payload
    },
    bulkActionSend: (state, { payload }) => {
      state.animations.bulkSend = payload
    },
    // Open invoices
    bulkActionApprovePayments: (state, { payload }) => {
      state.animations.bulkApprovePayments = payload
    },
    bulkActionNudgeInvoices: (state, { payload }) => {
      state.animations.bulkNudge = payload
    },
    saveAndSendInvoice: (state, { payload }) => {},
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [reconApiEvents.invoicesNudge_success]: (state, { payload }) => {
      const ids: $TSFixMe = payload?.nudgeResult.map(({ id }: any) => id)
      ids.forEach((id: any) => {
        state.nudgedInvoices.push({ id: payload, lastNudged: toDate(Date.now()).getTime() })
      })
      state.animations.bulkNudge = state.animations.bulkNudge.filter((id: $TSFixMe) => !ids.includes(id))
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [invoiceApiEvents.sendInvoices_success]: (state, { payload }) => {
      state.animations.bulkSendErrors = payload.filter(
        (r: $TSFixMe) => !r.sent && !r.result.includes('has already been sent'),
      )
    },
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = slice

export { events, reducer }

import React, { useState } from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { insert, pathOr, remove } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { Button, DatePicker, DimmerLoader, Segment } from '../../../../views/components'
import CalendarOff from '../../../../views/components/atoms/Svgs/CalendarOff'
import BulkImportInvoicesProvider from '../../state/BulkImportInvoicesProvider'
import TaskList from '../../../../views/components/atoms/Svgs/TaskList'
// import PropTypes from 'prop-types'
import FormErrors from '../../../../views/components/molecules/FormErrors/FormErrors'
import PaymentAllocation from './PaymentAllocation/PaymentAllocation'
import { partyApiSelectors } from '../../../api/party'
import { invoiceApiSelectors } from '../../../api/invoice'
import { Formik } from 'formik'
import styles from './BulkImportInvoiceView.module.scss'
import { useEffectOnce } from 'react-use'
import { hashCode } from 'utils/string'
import { bulkImportInvoicesEvents } from '../../state'

const BulkImportInvoiceView = ({ closeInvoice, invoice, invoiceViewId }: any) => {
  const dispatch = useDispatch()
  const [beneficiariesHash, setBeneficiariesHash] = useState(null)
  const getPartyNameById = useSelector(partyApiSelectors.getPartyNameById)
  const partyTags = useSelector(partyApiSelectors.getPartyTags)
  const invoiceType = useSelector(state => invoiceApiSelectors.getInvoiceTypeByValue(state)(invoice?.invoiceType)) || ''

  useEffectOnce(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setBeneficiariesHash(hashCode(JSON.stringify(pathOr('', ['beneficiaries'], invoice))))
  })

  return (
    <div>
      <Helmet>
        <title>reOS | Bulk Import Invoices | View __</title>
      </Helmet>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; className: any; loadi... Remove this comment to see the full error message */}
      <DimmerLoader className={styles.root} loading={!invoice} renderChildren={false}>
        <header className={styles.header}>
          <button className={styles.close} onClick={closeInvoice}>
            &nbsp;
          </button>
          {/* <ContextMenu
            name='leaseContextMenu'
            items={leaseOptions}
            position='left'
            showInput={false}
          /> */}
        </header>
        <div className={styles.body}>
          <Segment.Group>
            <Segment>
              <Segment.Label icon={<TaskList />} text={invoice?.customerName} secondaryText={invoice?.property} />
            </Segment>
          </Segment.Group>

          <FormErrors errors={[]} />

          {invoice && (
            <Formik
              enableReinitialize
              initialValues={invoice}
              onSubmit={values => {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
                dispatch(bulkImportInvoicesEvents.updateBulkInvoice(values, { invoiceViewId }))
              }}
            >
              {({ setFieldValue, handleChange, handleSubmit, values, dirty }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <PaymentAllocation
                      errors={{}}
                      invoiceType={invoiceType}
                      amount={values.amount ? values.amount.toString() : ''}
                      amountVatApplied={values?.vat}
                      onAmountChange={(e: any) => setFieldValue('amount', e.target.value)}
                      onVatChange={(vat: any) => setFieldValue('vat', vat)}
                      onBeneficiaryVatChange={(index: number, vat: any) =>
                        setFieldValue(`beneficiaries[${index}].beneficiary.value.vat`, vat)
                      }
                      onDepositBeneficiaryTransferChange={console.log}
                      beneficiaries={values?.beneficiaries.map((b: any) => {
                        const partyId = pathOr('', ['beneficiary', 'value', 'partyId'], b)
                        return {
                          ...b,
                          beneficiary: {
                            ...b.beneficiary,
                            value: {
                              ...b.beneficiary.value,
                              name: getPartyNameById(partyId),
                            },
                          },
                        }
                      })}
                      onNewBeneficiaryAdded={(beneficiary: any, index: number) => {
                        setFieldValue('beneficiaries', insert(index, beneficiary, values.beneficiaries))
                      }}
                      onBeneficiaryRemoved={(index: number) => {
                        setFieldValue('beneficiaries', remove(index, 1, values.beneficiaries))
                      }}
                      onNewBeneficiaryAmountChange={(beneficiaryIndex: number, e: any) => {
                        const beneficiary = values.beneficiaries.find((v: any, i: any) => i === beneficiaryIndex)
                        setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
                          ...beneficiary,
                          beneficiary: {
                            ...beneficiary.beneficiary,
                            value: {
                              ...beneficiary.beneficiary.value,
                              amount: e.target.value ? e.target.value : 0,
                            },
                          },
                        })
                      }}
                      onBeneficiariesOrderChange={(newOrder: any) => {
                        const updatedBeneficiaries = newOrder.map((o: any) => values.beneficiaries[o])
                        setFieldValue('beneficiaries', updatedBeneficiaries)
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                        setBeneficiariesHash(hashCode(JSON.stringify(updatedBeneficiaries)))
                      }}
                      partyTags={partyTags}
                      beneficiariesKey={beneficiariesHash}
                    />

                    <Segment.Group>
                      {values.reoccurring !== 'OnceOff' && (
                        <Segment horizontal>
                          <Segment.Label icon={<CalendarOff />} text="Payment Due" />
                          <div className={styles['due-date']}>
                            <DatePicker
                              date={values.dueDate ? new Date(values.dueDate) : new Date()}
                              onChange={(date: any) => setFieldValue('dueDate', format(date, 'yyyy-MM-dd'))}
                            />
                          </div>
                        </Segment>
                      )}
                    </Segment.Group>

                    <div className={styles['form-footer']}>
                      <Button disabled={!dirty} type="submit">
                        Save
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          )}
        </div>
      </DimmerLoader>
    </div>
  )
}

BulkImportInvoiceView.propTypes = {}

export default subscribe([BulkImportInvoicesProvider], ({ closeInvoice, invoice, invoiceViewId }: any) => ({
  closeInvoice,
  invoice,
  invoiceViewId,
}))(BulkImportInvoiceView)

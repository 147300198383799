import React from 'react'
import { get } from 'lodash-es'
import { subscribe } from 'react-contextual'
import { InvoicesProvider, SearchFilterProvider } from '../../../../providers'
import { BulkActions } from '../../..'
import useTable from '../../../organisms/Table/useTable'
import Dimmer from '../../../atoms/Dimmer/Dimmer'

const DraftInvoicesBulkActions = ({
  bulkActionDelete,
  bulkActionSend,
  bulkActionRestore,
  enterDraftSetMode,
  openInvoice,
  draftInvoiceFilters,
  activeFilterIndex,
  isReadOnly,
}: any) => {
  const filterObj = get(draftInvoiceFilters, `[${[activeFilterIndex]}]`, {})
  const filter = get(filterObj, 'key', '')
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selection' does not exist on type '{}'.
  const { selection = [], setSelection } = useTable()

  const deleteAction = {
    text: 'Delete',
    count: selection.length,
    danger: true,
    confirmation: {
      title: 'Delete invoices',
      content: <p>You are about to delete {selection.length} invoices.</p>,
      onConfirm: () => {
        bulkActionDelete(selection)
        setSelection([])
      },
    },
  }

  const restoreAction = {
    text: 'Restore',
    count: selection.length,
    confirmation: {
      title: 'Restore invoices',
      content: <p>You are about to restore {selection.length} invoices.</p>,
      onConfirm: () => {
        bulkActionRestore(selection)
        setSelection([])
      },
    },
  }

  const setModeAction = {
    text: 'Set mode',
    count: selection.length,
    confirmation: {
      title: 'Enter set mode',
      content: <p>You are about to enter set mode for {selection.length} invoices.</p>,
      onConfirm: () => {
        enterDraftSetMode(selection)
        openInvoice(selection[0], 'drafts')
        setSelection([])
      },
    },
  }

  const sendAction = {
    text: 'Send',
    count: selection.length,
    confirmation: {
      title: 'Send Invoices',
      content: <p>You are about to send {selection.length} invoices.</p>,
      onConfirm: () => {
        bulkActionSend(selection)
        setSelection([])
      },
    },
  }

  const getActions = () => {
    switch (filter) {
      case 'notset':
        return [setModeAction, deleteAction]
      case 'stuck':
        return [setModeAction, deleteAction]
      case 'ready':
        return [sendAction, deleteAction]
      case 'deleted':
        return [restoreAction]
      default:
        return []
    }
  }

  return (
    <Dimmer active={isReadOnly}>
      <BulkActions isOpen={selection.length > 0 && getActions().length > 0} actions={getActions()} />
    </Dimmer>
  )
}

export default subscribe(
  [InvoicesProvider, SearchFilterProvider],
  (
    {
      bulkActionDelete,
      bulkActionRestore,
      bulkActionSend,
      enterDraftSetMode,
      openInvoice,
      draftInvoiceFilters,
      isReadOnly,
    }: any,
    { activeFilterIndex }: any,
  ) => ({
    bulkActionDelete,
    bulkActionRestore,
    bulkActionSend,
    enterDraftSetMode,
    openInvoice,
    draftInvoiceFilters,
    isReadOnly,
    activeFilterIndex,
  }),
)(DraftInvoicesBulkActions)

const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'partyApi'

export const PARTY_TAGS = {
  owner: 'Owner',
  tenant: 'Tenant',
  agent: 'Agent',
  agency: 'Agency',
}

export const accountTypes = {
  owner: 'Owner',
  tenant: 'Tenant',
  team: 'Team',
  institution: 'Institution',
  supplier: 'Supplier',
}

export const ENDPOINTS = {
  PARTY_BASE: `${REACT_APP_API_URL}/parties`,
  PARTY_ENTITY: `${REACT_APP_API_URL}/parties/:id/entity`,
  SEARCH: `${REACT_APP_API_URL}/parties/search`,
  QUICK_SEARCH: `${REACT_APP_API_URL}/parties/quick_search`,
  PARTY_TAGS: `${REACT_APP_API_URL}/parties/tags`,
  ACCOUNTS: `${REACT_APP_API_URL}/parties/:id/accounts`,
  BAD_REFERENCE_FUZZY_MATCHES: `${REACT_APP_API_URL}/parties/references`,
  BULK_IMPORT: `${REACT_APP_API_URL}/parties/bulk_import`,
}

export const PARTY_TYPES = {
  PERSON: 0,
  COMPANY: 1,
}

export const DEBOUNCE_TIME = 1000

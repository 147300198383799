import React, { FC, useEffect, useState } from 'react'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'
import { Switch, ActivationModal, Button } from 'views/components'
import { integrationsApiEvents, integrationsApiSelectors } from 'modules/api/integrations'
import TpnLoginModal from '../TpnCreditChecks/TpnLoginModal/TpnLoginModal'
import TpnEnquiryModal from '../TpnCreditChecks/TpnEnquiryModal/TpnEnquiryModal'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import Logo from '../../../../theme/images/logo.svg'
import styles from './IntegrationCardBody.module.scss'

type Props = {
  id: string
  available: boolean
  enabled: boolean
  logo: string
  name: string
  short: string
  orderNo: number
}

const IntegrationCardBody: FC<Props> = ({ available, enabled, id, logo, name, short }) => {
  const dispatch = useDispatch()
  const [activationState, setActivationState] = useState(false)
  const [syncExistingProperties, setSyncExistingProperties] = useState(false)
  const [isTpnCustomEnquiryModalOpen, setIsTpnCustomEnquiryModalOpen] = useState(false)
  const [isRefreshTpnConnectOpen, setIsRefreshTpnConnectOpen] = useState(false)

  const classes = cx(styles.body, {
    [styles['body--enabled']]: enabled,
    [styles['body--disabled']]: !enabled,
  })

  const isOwnerRole = useSelector(userApiSelectors.isOwnerRole)
  const isTeamMemberRole = useSelector(userApiSelectors.isTeamMemberRole)
  const isTpnConnectEnabled = name === 'TPN Connect' && enabled
  const getConsumerCanAccessCredex = useSelector(integrationsApiSelectors.getConsumerCanAccessCredex)

  const canUserAccessCredex = Object.values(getConsumerCanAccessCredex)

  useEffect(() => {
    if (isTpnConnectEnabled) {
      dispatch(integrationsApiEvents.tpnConsumerCanAccessCredex_request())
    }
  }, [isTpnConnectEnabled])

  const handleActivate = () => {
    dispatch(integrationsApiEvents.integrationOauthDetails_request({ syncExistingProperties }))
  }

  const handleDeactivate = () => {
    dispatch(integrationsApiEvents.deactivateIntegration_request({ integrationType: 'PropertyInspect' }))
    setActivationState(false)
  }

  return (
    <>
      <div className={classes}>
        <figure>
          <img className={styles.logo} src={logo ? logo : Logo} alt={name} />
        </figure>
        <div className={styles.content}>
          <h3>{name}</h3>
          <p>{short}</p>
        </div>
        {(isOwnerRole || isTeamMemberRole) && (
          <>
            <div className={styles.actions}>
              {available ? (
                <Switch
                  className="integration-toggle"
                  label={enabled ? 'Enabled' : 'Disabled'}
                  checked={enabled}
                  onChange={() => setActivationState(true)}
                />
              ) : name === 'AutoEvict (BETA)' ? (
                <a href="https://autoevict.co.za/" target="_blank" rel="noopener noreferrer">
                  Learn more
                </a>
              ) : (
                <p>Watch this space</p>
              )}
              <div className={styles.tpn}>
                {isTpnConnectEnabled && canUserAccessCredex[0] ? (
                  <Button
                    size="sm"
                    onClick={() => {
                      setIsTpnCustomEnquiryModalOpen(true)
                    }}
                  >
                    Request Credit Check
                  </Button>
                ) : isTpnConnectEnabled && !canUserAccessCredex[0] ? (
                  <div className={styles.tpn}>
                    <Button
                      size="sm"
                      onClick={() => {
                        setIsRefreshTpnConnectOpen(true)
                      }}
                    >
                      Refresh Login
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
      {enabled && !canUserAccessCredex[0] ? (
        <TpnLoginModal isOpen={isRefreshTpnConnectOpen} onClose={() => setIsRefreshTpnConnectOpen(false)} />
      ) : name === 'TPN Connect' && enabled ? (
        <TpnEnquiryModal isOpen={isTpnCustomEnquiryModalOpen} onClose={() => setIsTpnCustomEnquiryModalOpen(false)} />
      ) : name === 'TPN Connect' && !enabled ? (
        <TpnLoginModal isOpen={activationState} onClose={() => setActivationState(false)} />
      ) : null}

      {name === 'Property Inspect' && enabled ? (
        <ActivationModal
          isOpen={activationState}
          title={name}
          consent="Disable this integration?"
          enabled={enabled}
          syncExistingProperties={syncExistingProperties}
          onSkipInitialSyncChange={() => setSyncExistingProperties(!syncExistingProperties)}
          onActivate={handleDeactivate}
          onCancel={() => setActivationState(false)}
        />
      ) : name === 'Property Inspect' && !enabled ? (
        <ActivationModal
          isOpen={activationState}
          title={name}
          consent="Enable this integration?"
          enabled={enabled}
          syncExistingProperties={syncExistingProperties}
          onSkipInitialSyncChange={() => setSyncExistingProperties(!syncExistingProperties)}
          onActivate={handleActivate}
          onCancel={() => setActivationState(false)}
        />
      ) : null}
    </>
  )
}

export default IntegrationCardBody

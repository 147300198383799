import React from 'react'

const People = (props: any) => (
  <svg width={32} height={32} {...props}>
    <path
      fillOpacity={0.64}
      fillRule="evenodd"
      d="M23.727 20.22A3.277 3.277 0 0 1 25 23.068v1.471a.208.208 0 0 1-.208.208h-3.347a.21.21 0 0 1-.208-.235c.024-.171.013-.07.013-2.584a4.146 4.146 0 0 0-2.633-3.873l-2.565-1.017a.208.208 0 0 1-.078-.333 4.297 4.297 0 0 0 1.036-2.25.202.202 0 0 1 .042-.096c.366-.487.56-1.083.548-1.692a2.857 2.857 0 0 0-.272-1.242.205.205 0 0 1-.013-.144c.273-.896.32-1.846.136-2.764a2.467 2.467 0 0 0-.244-.605.21.21 0 0 1 .126-.302 5.333 5.333 0 0 1 1.417-.193c2.162 0 4.775 1.333 5.03 5.104.146 2.148.477 2.48 1.028 3.03.091.091.14.216.135.345a.433.433 0 0 1-.161.32 6.05 6.05 0 0 1-3.881 1.141 11.381 11.381 0 0 1-.379-.005.21.21 0 0 0-.21.208v.446a.812.812 0 0 0 .536.666c1.013.411 1.978.93 2.88 1.548zm-4.144 4.53H5.417A.417.417 0 0 1 5 24.333v-2.398a2.902 2.902 0 0 1 1.838-2.71l3.447-1.372a.207.207 0 0 0 .132-.193v-1.293a.208.208 0 0 0-.09-.171 2.89 2.89 0 0 1-1.145-2.288.21.21 0 0 0-.083-.15 1.328 1.328 0 0 1-.449-1.091c-.003-.27.06-.535.186-.774a.987.987 0 0 1 .172-.232.21.21 0 0 0 .054-.204c-.214-.757-.51-1.994.084-2.635.233-.24.556-.371.89-.36a.21.21 0 0 0 .172-.095c.534-.815 1.84-1.117 2.917-1.117 1.255 0 2.824.41 3.112 1.563a5.17 5.17 0 0 1-.28 2.645.208.208 0 0 0 .05.209c.239.276.362.635.343 1 .029.413-.137.817-.45 1.09a.21.21 0 0 0-.083.151 2.89 2.89 0 0 1-1.144 2.288.206.206 0 0 0-.09.17v1.293a.21.21 0 0 0 .132.193l3.442 1.364A2.903 2.903 0 0 1 20 21.928v2.405c0 .23-.187.417-.417.417z"
    />
  </svg>
)

export default People

import React from 'react'
import { useDispatch } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { events } from './state'

const PropertyApiProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()
  const store = {
    createProperty: (payload: any) => dispatch(events.createProperty_request(payload)),
    fetchProperties: () => dispatch(events.properties_request()),
    fetchSearchResults: (query: any) => dispatch(events.search_request({ query })),
    fetchGooglePlace: (placeId: any) => dispatch(events.place_request({ placeId })),
    fetchProperty: (id: any) => dispatch(events.property_request({ id })),
  }

  return <context.Provider value={store}>{children}</context.Provider>
})

export default PropertyApiProvider

import React from 'react'
import { subscribe } from 'react-contextual'
import { Button, Header } from '../../../../../../views/components'
import PortfoliosProvider from '../../../../PortfoliosProvider'
import styles from './RecentActivity.module.scss'

const RecentActivity = ({ portfolioId, redirect }: any) => {
  return (
    <section className={styles.root}>
      <Header
        className={styles.header}
        tag="h4"
        text="Lease Statement"
        trailingIcon={
          <Button pill tertiary size="sm" onClick={() => redirect(`/leases/${portfolioId}/statement`)}>
            View Statement
          </Button>
        }
      />
    </section>
  )
}

export default subscribe([PortfoliosProvider], ({ redirect }: any) => ({
  redirect,
}))(RecentActivity)

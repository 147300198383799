import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './PortfolioActivationForm.module.scss'
import { Divider, List, AggregateListItem } from '../../../../components'

const propTypes = {
  items: PropTypes.array,
  monthlyItems: PropTypes.array,
  fetchInvoiceSchedules: PropTypes.func,
}

const defaultProps = {}

class PortfolioActivationForm extends Component {
  componentDidMount(): void {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fetchInvoiceSchedules' does not exist on... Remove this comment to see the full error message
    this.props.fetchInvoiceSchedules()
  }

  render(): null | React.ReactElement {
    return (
      <div className={styles.root}>
        <Divider>
          <h3>Immediately on activation</h3>
        </Divider>

        <List type="checklist">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'items' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.props.items
            .filter((i: any) => !!i.checked)
            .map((item: any, i: any) => (
              <AggregateListItem
                key={i}
                active={!!item.checked}
                text={item.mainText}
                secondaryText={item.secondaryText}
              />
            ))}
        </List>

        {/* @ts-expect-error */}
        {this.props.monthlyItems.length > 0 && (
          <>
            <Divider>
              <h3>On a monthly basis</h3>
            </Divider>
            <List type="checklist">
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'monthlyItems' does not exist on type 'Re... Remove this comment to see the full error message */}
              {this.props.monthlyItems
                .filter((i: any) => !!i.checked)
                .map((item: any, i: any) => (
                  <AggregateListItem
                    key={i}
                    active={!!item.checked}
                    text={item.mainText}
                    secondaryText={item.secondaryText}
                  />
                ))}
            </List>
          </>
        )}
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PortfolioActivationForm.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PortfolioActivationForm.defaultProps = defaultProps

export default PortfolioActivationForm

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { subscribe } from 'react-contextual'
import { useEffectOnce } from 'react-use'
import { Helmet } from 'react-helmet'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../views/components'
import { layoutEvents } from '../../Layout/state'
import { ContactsProvider, Contacts, ViewContact } from '..'

const ContactsPage = ({ match: { params }, closeContact }: any) => {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    if (params.id) {
      dispatch(layoutEvents.closeSidebar('global'))
    } else {
      closeContact()
    }
  })

  /** @todo Make initial props more flexible. */
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="contacts"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: params.id, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Contacts</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutPrimaryPanel>{params.id ? <ViewContact /> : null}</LayoutPrimaryPanel>
          <LayoutContent>
            <TableProvider>
              <Contacts />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(ContactsProvider, ({ match, closeContact }: any) => ({
  match,
  closeContact,
}))(ContactsPage)

import { createSlice } from '@reduxjs/toolkit'
import { portfolioApiEvents } from '../../api/portfolio'

const initialState = {
  searchQuery: '',
  depositTransferModal: false,
  isDepositTopupRequestOpen: false,
  isNewPartyModalOpen: false,
}

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    moduleLoaded: () => {},
    openDepositTransferModal: (state, { payload }) => {
      state.depositTransferModal = payload
    },
    closeDepositTransferModal: state => {
      state.depositTransferModal = false
    },
    // eg: dispatch(depositTopupRequestOpened(portfolioId))
    depositTopupRequestOpened: state => {
      state.isDepositTopupRequestOpen = true
    },
    depositTopupRequestClosed: state => {
      state.isDepositTopupRequestOpen = false
    },
    togglePartyModal: state => {
      state.isNewPartyModalOpen = !state.isNewPartyModalOpen
    },
  },
  extraReducers: {
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateTenantParties_success]: state => {
      state.isNewPartyModalOpen = false
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.updateOwnerParties_success]: state => {
      state.isNewPartyModalOpen = false
    },
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.addApplication_success]: state => {
      state.isNewPartyModalOpen = false
    },
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = propertySlice

export { events, reducer }

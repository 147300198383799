import { getItem, setItem, removeItem } from './localStorage'

const TOKEN = '__TOKEN__'
const REFRESH_TOKEN = '__REFRESH_TOKEN__'
const MISC = '__MISC__'

export const setStorageItem = (name: any, value: any) => setItem(name, value)
export const getStorageItem = (name: any) => {
  const fetched = getItem(name)
  if (!fetched) {
    return undefined
  }
  return fetched
}

export const setStorageJSON = (name: any, value: any) => setItem(name, JSON.stringify(value))
export const getStorageJSON = (name: any) => {
  const item = getStorageItem(name)
  if (!item) {
    return undefined
  }
  return JSON.parse(item)
}

export function deleteTokens() {
  removeItem(TOKEN)
  removeItem(REFRESH_TOKEN)
  removeItem(MISC)
}

// @todo improve logic
export const isAuthenticated = () => getStorageItem(TOKEN) !== undefined

export const getJWTToken = () => getStorageItem(TOKEN)

export const getAuthHeaders = () => {
  return { Authorization: 'Bearer ' + getStorageItem(TOKEN) }
}

export const getJWTRefreshToken = () => getStorageItem(REFRESH_TOKEN)

export const getMisc = () => getStorageJSON(MISC)

export const setMisc = (payload: any) => setStorageJSON(MISC, payload)

export function setTokensFromResponse(res: any) {
  const { authToken, refreshToken, miscellaneous } = res
  if (authToken !== undefined) {
    setStorageItem(TOKEN, authToken)
  }
  if (refreshToken !== undefined) {
    setStorageItem(REFRESH_TOKEN, refreshToken)
  }
  if (miscellaneous !== undefined) {
    setStorageJSON(MISC, miscellaneous)
  }
}

export function isTokenExpired(decodedToken: any) {
  const { exp } = decodedToken
  const now = Math.floor(Date.now() / 1000)
  return exp && exp - now <= 0
}

import React from 'react'
import PropTypes from 'prop-types'
import { useHover } from 'react-use'

import Tooltip from '../../atoms/Tooltip/Tooltip'

const propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string, // used as tooltip
  ]),
}

const RemoveButton = ({ className, index, onRemove, disabled }: any) => {
  const [hoverableButton, isHovering] = useHover(
    <button type="button" className={className} onClick={() => !disabled && onRemove(index)} />,
  )

  return !onRemove ? null : typeof disabled === 'string' ? (
    <Tooltip isOpen={isHovering} body={disabled}>
      {hoverableButton}
    </Tooltip>
  ) : (
    hoverableButton
  )
}

RemoveButton.propTypes = propTypes

export default RemoveButton

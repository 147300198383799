import React from 'react'

const SvgInvoice = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h24v24H4z" />
      <path
        d="M23 26H9a3 3 0 01-3-3V7a1 1 0 011-1h14a1 1 0 011 1v12h4v4a3 3 0 01-3 3zm-1-5v2a1 1 0 102 0v-2h-2zm-2 3V8H8v15a1 1 0 001 1h11zM10 11h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h5v2h-5v-2z"
        fillOpacity={0.64}
        fill="#191923"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgInvoice

import { createSelector } from 'reselect'
import { pipe, flatten, filter, uniqBy, map, sort, groupBy, concat, pathOr } from 'ramda'
import { walletApiSelectors } from '../../api/wallet'

const state = (state: any) => state

export const getPaymentFilters = createSelector(state, state => {
  const injectId = (p: any) => ({
    ...p,
    id: p.payment,
  })
  const payments = walletApiSelectors.getPayments(state).map(injectId)

  // substr matches
  // EG: `Agency` matches `Agency` and `AgencyDepositAccount`
  const filterPaymentsByCategory = (...categories: any[]) => {
    const filteredPayments = pipe(
      map((category: any) => filter((payment: any) => payment.beneficiaryTag.includes(category), payments)),
      flatten,
      uniqBy((c: any) => c.payment),
    )(categories)

    return sortAndGroupPayments(filteredPayments)
  }

  const sortAndGroupPayments = (filteredPayments: any) => {
    const sortedPayments = pipe(
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      sort((a: any, b: any) => new Date(b.approvedAt) - new Date(a.approvedAt)),
      groupBy((payment: any) => (payment.paymentStatus === 'Queue' ? 'queue' : 'other')),
    )(filteredPayments)

    return concat(pathOr([], ['queue'], sortedPayments), pathOr([], ['other'], sortedPayments))
  }

  const allPayments = sortAndGroupPayments(payments)
  const commissionPayments = filterPaymentsByCategory('Agency', 'Agent')
  const ownerPayments = filterPaymentsByCategory('Owner')
  const tenantPayments = filterPaymentsByCategory('Tenant')
  const institutionPayments = filterPaymentsByCategory('Municipality', 'Levies', 'Rates')
  const supplierPayments = filterPaymentsByCategory('Contractor', 'Supplier')

  const maybeEnableSelection = (payment: any) => {
    return {
      ...payment,
      enableSelection: payment.paymentStatus === 'Queue' || payment.popAvailable,
    }
  }

  return [
    {
      key: 'all',
      label: 'All',
      payments: map(maybeEnableSelection, allPayments),
      count: allPayments.length,
    },
    {
      key: 'commission',
      label: 'Commission',
      payments: map(maybeEnableSelection, commissionPayments),
      count: commissionPayments.length,
    },
    {
      key: 'owner',
      label: 'Owner',
      payments: map(maybeEnableSelection, ownerPayments),
      count: ownerPayments.length,
    },
    {
      key: 'tenant',
      label: 'Tenant',
      payments: map(maybeEnableSelection, tenantPayments),
      count: tenantPayments.length,
    },
    {
      key: 'institutions',
      label: 'Institutions',
      payments: map(maybeEnableSelection, institutionPayments),
      count: institutionPayments.length,
    },
    {
      key: 'suppliers',
      label: 'Suppliers',
      payments: map(maybeEnableSelection, supplierPayments),
      count: supplierPayments.length,
    },
  ]
})

export const getPaymentPeriod = createSelector(state, state =>
  pathOr({}, ['api', 'wallet', 'paymentHistory', 'period'], state),
)

import React from 'react'
import PropTypes from 'prop-types'
import Svg from '../../atoms/Svg/Svg'
import styles from './NoContent.module.scss'

const propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
}

const defaultProps = {}

const NoContent = ({ heading, subHeading }: any) => {
  return (
    <div className={styles.root}>
      <Svg name="no-content-graphic" />
      <h3>{heading}</h3>
      {subHeading && <p>{subHeading}</p>}
    </div>
  )
}

NoContent.propTypes = propTypes
NoContent.defaultProps = defaultProps

export default NoContent

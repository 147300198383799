import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {},
  reducers: {
    redirect: {
      reducer: () => {},
      prepare: (path: string) => ({ payload: null, meta: { pushToRoute: path } }),
    },
    reloadRequested: () => {},
  },
})

const { actions: uiEvents } = uiSlice

export { uiEvents }

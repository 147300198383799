import React from 'react'
import { subscribe } from 'react-contextual'
import { CurrencyText } from '../../../../../../views/components'
import ContactBalanceProvider from './ContactBalanceProvider'

const ContactBalance = ({ balance }: any) => {
  return (
    <section>
      <header>
        <span>Available Funds</span>
        <span>
          <CurrencyText>{balance || 0}</CurrencyText>
        </span>
      </header>
    </section>
  )
}

export default subscribe(ContactBalanceProvider, ({ balance }: any) => ({ balance }))(ContactBalance)

import React from 'react'
import { ListingTemplate } from '../../../views/components'
import AdminReportingTable from './AdminReportingTable/AdminReportingTable'

const AdminReportingContent = () => {
  return (
    <ListingTemplate
      loading={false}
      /** @todo replace temp height with better spacing solution */
      Header={<div style={{ minHeight: '71px' }}></div>}
      Body={<AdminReportingTable />}
    />
  )
}

export default AdminReportingContent

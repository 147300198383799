import React from 'react'
import PropTypes from 'prop-types'
import styles from './ItemListCell.module.scss'
import { slice, isEmpty } from 'ramda'

const ItemListCellProps = {
  items: PropTypes.arrayOf(PropTypes.string),
  suffix: PropTypes.string,
  basic: PropTypes.bool,
  blankCharacter: PropTypes.string,
}

const SubText = ({ items, suffix }: any) => {
  const subText =
    items.length === 1
      ? items[0]
      : items.length === 2
      ? `${items[0]} and ${items[1]}`
      : `${items[0]}, ${items[1]} and ${items.length - 2} ${suffix}`

  return <span className={styles.subtext}>+ {subText}</span>
}

const ItemListCell = (props: any) => {
  const { items, suffix = 'others', basic = false, blankCharacter = '' } = props

  const primaryItem = items[0] ? items[0] : blankCharacter
  const secondaryItems = slice(1, items.length, items)

  return (
    <div className={styles.root}>
      <strong>{primaryItem}</strong>
      {!isEmpty(secondaryItems) &&
        (basic ? `+ ${secondaryItems.length} ${suffix}` : <SubText items={secondaryItems} suffix={suffix} />)}
    </div>
  )
}

ItemListCell.propTypes = ItemListCellProps

export default ItemListCell

import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../views/components/atoms/Button/Button'
import ControlledTooltip from '../../../../views/components/atoms/Tooltip/ControlledTooltip'
import styles from './MultiPartyTable.module.scss'
import Table from '../../../../views/components/organisms/Table/Table'

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      isPrimary: PropTypes.bool,
    }),
  ),
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onMakePrimary: PropTypes.func,
  allowEdit: PropTypes.bool,
}

const ActionButton = ({ tooltip, icon, action, disabled = false }: any) => (
  // @ts-expect-error
  <ControlledTooltip body={tooltip} place="above">
    <Button disabled={disabled} ghost className={styles[icon]} onClick={action} />
  </ControlledTooltip>
)

const MultiPartyTable = (props: any) => {
  const { data, onEdit, onMakePrimary, onRemove, allowEdit } = props

  const multiPartyData = data.map((party: any) => {
    const { id, name, email, isPrimary } = party

    return {
      id,
      partyDetails: (
        <span className={styles['party-details']}>
          <strong>{name}</strong>
          <span className={styles.subtext}>{email}</span>
        </span>
      ),
      actions: (
        <span className={styles.actions}>
          <ActionButton
            tooltip="Make primary"
            icon={isPrimary ? 'primary-fill' : 'primary'}
            action={isPrimary ? () => {} : () => onMakePrimary(id)}
            disabled={!allowEdit}
          />
          <ActionButton tooltip="Edit contact" icon="edit" action={() => onEdit(id)} />
          <ActionButton
            tooltip="Remove from list"
            icon="remove"
            action={() => onRemove(id)}
            disabled={isPrimary || !allowEdit}
          />
        </span>
      ),
    }
  })

  return (
    <div className={styles.root}>
      {multiPartyData.length > 0 ? (
        <>
          <Table type="default">
            <Table.Body>
              {multiPartyData.map((party: any, i: any) => (
                <Table.Row key={i}>
                  <Table.Cell style={{ paddingLeft: '30px' }}>{party.partyDetails}</Table.Cell>
                  <Table.Cell style={{ paddingRight: '30px', justifyContent: 'flex-end' }}>{party.actions}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <div className={styles['no-content']}>
          <p>No parties yet. Add one by clicking the plus icon to the top right.</p>
        </div>
      )}
    </div>
  )
}

MultiPartyTable.propTypes = propTypes

export default MultiPartyTable

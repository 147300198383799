import React, { useEffect, useRef } from 'react'
import { mergeRight } from 'ramda'
import { PropertySearch } from '../../../../containers'
import { Button, Column, Grid, TextField, TextInput } from '../../..'
import styles from './PropertyForm.module.scss'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { formUtils } from '../../../../../utils'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const NewProperty = ({
  isReadOnly,
  values,
  generalErrors,
  fieldErrors,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
}: any): React.ReactElement => {
  const buildingNameRef = useRef<$TSFixMe>(null)
  useEffect(() => {
    if (buildingNameRef) {
      buildingNameRef.current?.focus()
    }
  }, [buildingNameRef])

  const getFieldError = formUtils.getFieldError(1, true, mergeRight(errors, fieldErrors))
  const text: string = values?.selectedPlace?.text || ''
  const secondaryText: string = values?.selectedPlace?.secondaryText || ''
  const value = text && secondaryText ? `${text}, ${secondaryText}` : text || ''
  return (
    <div className={styles.root}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isReadOnly}>
          <div className={styles.body}>
            <FormErrors errors={generalErrors} />
            <Grid>
              <Column xs={12} md={8} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="buildingName"
                      placeholder="e.g. Cardiff Castle"
                      value={values.buildingName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      ref={(ref: any) => {
                        buildingNameRef.current = ref
                      }}
                    />
                  }
                  label="Building / Complex name (if applicable)"
                  error={getFieldError('buildingName')}
                />
              </Column>
              {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
              <Column xs={12} lg={4} flexOptions="padding-r-1">
                <TextField
                  inputComponent={
                    <TextInput
                      type={TextFieldTypes.text}
                      name="unitNumber"
                      placeholder="e.g. 4"
                      value={values.unitNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Unit Number (if applicable)"
                  error={getFieldError('unitNumber')}
                />
              </Column>
            </Grid>
            <Grid>
              <Column xs={12} md={8} flexOptions="padding-r-1">
                <PropertySearch
                  newProperty
                  value={value}
                  onResultSelect={(result: any) => {
                    setFieldValue('selectedPlace', result)
                  }}
                />
              </Column>
            </Grid>
          </div>
          <div className={styles.footer}>
            <Button type="submit" disabled={isReadOnly}>
              Create property
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default NewProperty

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { $TSFixMe } from 'types/ts-migrate'
import { ActionType } from 'typesafe-actions'
import { AccountingStatsResponse } from './types/AccountingStatsResponse'
import { Base64CsvResponse } from './types/Base64CsvResponse'
import { Base64PdfResponse } from './types/Base64PdfResponse'
import { JsonResponse } from './types/JsonResponse'

type TStatementJsonSlice = {
  [id: string]: JsonResponse | null
}

type TStatementPdfSlice = {
  [id: string]: string
}

export type TAccountingInitialState = {
  bankStatementJson: JsonResponse | null
  bankStatementPdf: string
  customerStatements: $TSFixMe[]
  customerStatementJson: TStatementJsonSlice
  customerStatementPdf: TStatementPdfSlice
  portfolioStatementJson: TStatementJsonSlice
  portfolioStatementPdf: TStatementPdfSlice
  depositStatementJson: TStatementJsonSlice
  depositStatementPdf: TStatementPdfSlice
  depositBankStatementJson: JsonResponse | null
  depositBankStatementPdf: string
  commissionStatementJson: JsonResponse | null
  commissionStatementPdf: string
  commissionStatementCsv: string
  stats: AccountingStatsResponse
}

const initialState: TAccountingInitialState = {
  bankStatementJson: null,
  bankStatementPdf: '',
  customerStatements: [],
  customerStatementJson: {},
  customerStatementPdf: {},
  portfolioStatementJson: {},
  portfolioStatementPdf: {},
  depositStatementJson: {},
  depositStatementPdf: {},
  depositBankStatementJson: null,
  depositBankStatementPdf: '',
  commissionStatementJson: null,
  commissionStatementPdf: '',
  commissionStatementCsv: '',
  stats: {
    fundsCollectedInLast30Days: {
      total: 0,
      percentageChange30Days: 0,
      creditNotes: 0,
    },
    commissionLast30Days: {
      total: 0,
      percentageChange30Days: 0,
    },
    depositsUnderManagement: {
      total: 0,
    },
  },
}

const accountApiSlice = createSlice({
  name: 'accountingApi',
  initialState,
  reducers: {
    accountingStats_request: () => {},
    accountingStats_success: (state, { payload }: PayloadAction<AccountingStatsResponse>) => {
      state.stats = payload
    },
    bankStatementJson_request: state => {
      state.bankStatementJson = null
    },
    bankStatementJson_success: (state, { payload }: PayloadAction<{ response: JsonResponse }>) => {
      state.bankStatementJson = payload.response
    },
    bankStatementPdf_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {
      state.bankStatementPdf = ''
    },
    bankStatementPdf_success: (state, { payload }: PayloadAction<{ response: Base64PdfResponse }>) => {
      state.bankStatementPdf = payload.response.pdfBase64
    },
    // customerStatements_request: state => {
    //   state.customerStatements = []
    // },
    // customerStatements_success: (state, { payload }) => {
    //   state.customerStatements = payload
    // },
    customerStatementJson_request: (state, action: PayloadAction<{ customerId: string; accountId: string }>) => {},
    customerStatementJson_success: (
      state,
      { payload }: PayloadAction<{ response: JsonResponse; customerId: string; accountId: string }>,
    ) => {
      state.customerStatementJson = { [`${payload.customerId}${payload.accountId}`]: payload.response }
    },
    customerStatementPdf_request: (
      state,
      { payload }: PayloadAction<{ customerId: string; accountId: string; start_date: string; end_date: string }>,
    ) => {},
    customerStatementPdf_success: (state, { payload }) => {
      state.customerStatementPdf = { [`${payload.customerId}${payload.accountId}`]: payload.response.pdfBase64 }
    },
    portfolioStatement_request: (
      state,
      action: PayloadAction<{ portfolioId: string; start_date: string; end_date: string }>,
    ) => {},
    portfolioStatement_success: (state, { payload }) => {
      state.portfolioStatementJson[payload.portfolioId] = payload.response
    },
    portfolioStatementPdf_request: (
      state,
      action: PayloadAction<{ portfolioId: string; start_date: string; end_date: string }>,
    ) => {},
    portfolioStatementPdf_success: (
      state,
      { payload }: PayloadAction<{ portfolioId: string; response: Base64PdfResponse }>,
    ) => {
      state.portfolioStatementPdf[payload.portfolioId] = payload.response.pdfBase64
    },
    depositStatementJson_request: (
      state,
      action: PayloadAction<{ id: string; accountId: string; start_date?: string; end_date?: string }>,
    ) => {},
    depositStatementJson_success: (
      state,
      { payload }: PayloadAction<{ accountId: string; response: JsonResponse }>,
    ) => {
      state.depositStatementJson = { [payload.accountId]: payload.response }
    },
    depositStatementPdf_request: (
      state,
      action: PayloadAction<{ id: string; accountId: string; start_date?: string; end_date?: string }>,
    ) => {},
    depositStatementPdf_success: (
      state,
      { payload }: PayloadAction<{ accountId: string; response: Base64PdfResponse }>,
    ) => {
      state.depositStatementPdf = { [payload.accountId]: payload.response.pdfBase64 }
    },
    depositBankStatementJson_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {},
    depositBankStatementJson_success: (state, { payload }: PayloadAction<JsonResponse>) => {
      state.depositBankStatementJson = payload
    },
    depositBankStatementPdf_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {},
    depositBankStatementPdf_success: (state, { payload }: PayloadAction<Base64PdfResponse>) => {
      state.depositBankStatementPdf = payload.pdfBase64
    },
    commissionStatementJson_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {},
    commissionStatementJson_success: (state, { payload }: PayloadAction<{ response: JsonResponse }>) => {
      state.commissionStatementJson = payload.response
    },
    commissionStatementPdf_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {},
    commissionStatementPdf_success: (state, { payload }: PayloadAction<{ response: Base64PdfResponse }>) => {
      state.commissionStatementPdf = payload.response.pdfBase64
    },
    commissionStatementCsv_request: (state, action: PayloadAction<{ start_date?: string; end_date?: string }>) => {},
    commissionStatementCsv_success: (state, { payload }: PayloadAction<{ response: Base64CsvResponse }>) => {
      state.commissionStatementCsv = payload.response.csvBase64
    },
  },
})

const { reducer, actions: events } = accountApiSlice

export type AccountingEvent = ActionType<typeof events>

export { events, reducer }

import { connect } from 'react-redux'
import { OnboardingBrandingForm } from '../../components'
import { userApiSelectors } from '../../../modules/api/user'
import { agencyApiEvents, agencyApiSelectors } from '../../../modules/api/agency'
import { uiSettingsSelectors } from '../../../modules/ui/settings'
import { chatbotEvents } from '../../../modules/ui/ChatBot'
import { apiStateSelectors } from '../../../modules/api/apiState'

const mapStateToProps = (state: any) => {
  return {
    themes: agencyApiSelectors.getThemes(state),
    initialValues: uiSettingsSelectors.getBrandingInitialValues(state),
    currentAgencyId: userApiSelectors.getCurrentAgencyId(state),
    isLoading: apiStateSelectors.isLoading(state)([agencyApiEvents.agency_request]),
    isSubmitting: apiStateSelectors.isLoading(state)([agencyApiEvents.updateBranding_request]),
    getGeneralFormErrorsByEvent: apiStateSelectors.getGeneralFormErrorsByEvent(state),
    getFormFieldErrorsByEvent: apiStateSelectors.getFormFieldErrorsByEvent(state),
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  getThemes: () => dispatch(agencyApiEvents.themes_request()),
  handleSubmit: (values: any) => dispatch(chatbotEvents.brandingDetailsSubmitted(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingBrandingForm)

import React, { useCallback } from 'react'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { useEffectOnce } from 'react-use'
import { isEqual } from 'lodash-es'
// import { memoizeWith, identity } from 'ramda'
import { uiInvoiceSelectors, uiInvoiceEvents } from '../ui/invoices'
import { partyApiSelectors } from '../api/party'
import { invoiceApiEvents, invoiceApiSelectors } from '../api/invoice'
import history from '../../state/history'
import { uiEvents } from '../ui'
import { reconApiEvents, reconApiSelectors } from '../api/recon'
import { emptyInitialValues } from './constants'
import { adhocInvoiceSelectors, adhocInvoiceEvents } from './state'
import { agencyApiSelectors } from '../api/agency'
import { portfolioApiEvents } from '../api/portfolio'
import { apiStateSelectors } from '../api/apiState'
import { userApiSelectors } from 'modules/api/user'

const AdhocInvoiceProvider = moduleContext()(({ context, children, ...props }: any) => {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()

  const fetchInvoiceTypes = useCallback(() => dispatch(invoiceApiEvents.invoiceTypes_request()), [dispatch])

  useEffectOnce(() => {
    fetchInvoiceTypes()
  })

  const { match } = props

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getInitialValues' does not exist on type... Remove this comment to see the full error message
  const initialValues = useSelector(adhocInvoiceSelectors.getInitialValues) || emptyInitialValues

  const currentAgencyId = userApiSelectors.getCurrentAgencyId(state)

  const adhocInvoiceStore = {
    match,
    invoice: {},
    invoiceTypesForSelect: useSelector(uiInvoiceSelectors.getAdhocInvoiceTypesForSelect, isEqual),
    initialValues,
    partyTags: useSelector(partyApiSelectors.getPartyTags),
    fetchInvoiceTypes,
    currentAgencyId,
    fetchPortfolio: useCallback(id => dispatch(portfolioApiEvents.portfolio_request(id)), [dispatch]),
    goBack: useCallback(() => {
      history.goBack()
      dispatch(adhocInvoiceEvents.initialValuesResetRequested())
    }, [dispatch]),
    easyPayData: useSelector(reconApiSelectors.getEasyPayData),
    getPartyNameById: (id: any) => partyApiSelectors.getPartyNameById(state)(id),
    getPartyBankDetails: (id: string) => partyApiSelectors.getPartyBankDetails(state)(id),
    isSubmitting: useSelector(state =>
      apiStateSelectors.isLoading(state)([invoiceApiEvents.createOnceOffInvoice_request]),
    ),
    getPartyAccount: (partyId: string, portfolioId: string, preferredTag: string) => {
      return partyApiSelectors.getPartyAccountByPortfolioId(state)(partyId, portfolioId, preferredTag)
    },
    updateInvoice: useCallback(
      (id, values) => dispatch(invoiceApiEvents.invoiceUpdate_request({ id, values })),
      [dispatch],
    ),
    createInvoice: useCallback(
      values => {
        dispatch(invoiceApiEvents.createOnceOffInvoice_request(values, null))
      },
      [dispatch],
    ),
    closeInvoice: useCallback(
      type => {
        dispatch(uiInvoiceEvents.closeInvoice({ type }))
        dispatch(uiEvents.redirect('/invoices/drafts'))
      },
      [dispatch],
    ),
    onSubmit: useCallback(
      payload => {
        dispatch(invoiceApiEvents.createOnceOffInvoice_request(payload, null))
      },
      [dispatch],
    ),
    fetchEasyPayData: (reference: any) => dispatch(reconApiEvents.easypayData_request({ reference })),
    agencyBankDetails: agencyApiSelectors.getCurrentAgencyBankDetails(state),
    ...props,
  }
  return <context.Provider value={adhocInvoiceStore}>{children}</context.Provider>
})

AdhocInvoiceProvider.whyDidYouRender = true

export default AdhocInvoiceProvider

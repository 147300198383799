import React, { Component } from 'react'
import { Tab, Tabs as RTabs, TabList, TabPanel } from 'react-tabs'
import styles from './Tabs.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

interface IProps {
  tabs: Array<{
    title: string
    content: $TSFixMe
  }>
  selectedTab?: number
  onTabChange?: (index: number, lastIndex: number, event: $TSFixMe) => void
}

interface IState {
  selectedTab: number
}

class Tabs extends Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.onTabChange = this.onTabChange.bind(this)
    this.state = {
      selectedTab: this.props?.selectedTab ?? 0,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any): void {
    if (this.props.selectedTab === nextProps.selectedTab) {
      return
    }

    if (nextProps.selectedTab !== this.state.selectedTab) {
      this.setState({ selectedTab: nextProps.selectedTab })
    }
  }

  onTabChange(index: number, lastIndex: number, event: any): void {
    if (typeof this.props.onTabChange === 'function') {
      this.props.onTabChange(index, lastIndex, event)
    }
    if (index !== this.state.selectedTab) {
      this.setState({
        selectedTab: index,
      })
    }
  }

  render(): null | React.ReactElement {
    const { tabs } = this.props
    const { selectedTab } = this.state

    return (
      <div className={styles.root}>
        <RTabs onSelect={this.onTabChange} selectedIndex={selectedTab}>
          <TabList>
            {tabs.map((tab: any, i: any) => (
              <Tab key={i}>{tab.title}</Tab>
            ))}
          </TabList>
          {tabs.map((tab: any, i: any) => (
            <TabPanel key={i}>{tab.content}</TabPanel>
          ))}
        </RTabs>
      </div>
    )
  }
}

export default Tabs

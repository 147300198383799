import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/icons/menu.s... Remove this comment to see the full error message
import menuIcon from '../../../../theme/icons/menu.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/icons/close.... Remove this comment to see the full error message
import closeIcon from '../../../../theme/icons/close.svg'
import Button from '../../atoms/Button/Button'
import styles from './MobileMenuBar.module.scss'

const propTypes = {
  className: PropTypes.string,
  agencyName: PropTypes.string.isRequired,
  agencyLogo: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

const MobileMenuBar = ({ className, agencyName, agencyLogo, onToggle, isOpen }: any) => {
  const classes = cx([styles.root, className])
  return (
    <div className={classes}>
      <div className={styles.brand}>
        {agencyLogo && <img src={agencyLogo} alt={agencyName} />}
        <h3>{agencyName}</h3>
      </div>
      <Button className={styles['menu-button']} size="sm" link onClick={onToggle}>
        <img src={isOpen ? closeIcon : menuIcon} alt={isOpen ? 'close menu' : 'open menu'} />
      </Button>
    </div>
  )
}

MobileMenuBar.propTypes = propTypes

export default MobileMenuBar

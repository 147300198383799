import React from 'react'
import { get } from 'lodash-es'
import { Redirect } from 'react-router-dom'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../theme/images/logo.... Remove this comment to see the full error message
import logo from '../../../../theme/images/logo.svg'
import { Card, Layout, LayoutHeader, LayoutBody, ResetPasswordForm } from '../../../components'
import styles from './ResetPasswordPage.module.scss'
import { useSelector } from 'react-redux'
import { userApiSelectors } from '../../../../modules/api/user'
import { $TSFixMe } from 'types/ts-migrate'

const ResetPasswordPage = (props: any): $TSFixMe => {
  const isAuthenticated = useSelector(userApiSelectors.isAuthenticated)
  const token = get(props, 'match.params.token', '')
  const from = get(props, 'location.state.from', '/')

  return isAuthenticated ? (
    <Redirect to={from} />
  ) : (
    <div className={styles.root}>
      <Layout>
        <LayoutHeader>
          <div className={styles.header}>
            <img width="96px" src={logo} alt="reOS" />
          </div>
        </LayoutHeader>
        <LayoutBody>
          <div className={styles['form-container']}>
            <Card on style={{ width: '360px' }}>
              <ResetPasswordForm token={token} />
            </Card>
          </div>
        </LayoutBody>
      </Layout>
    </div>
  )
}

export default ResetPasswordPage

import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountingApiEvents, accountingApiSelectors } from '../../../api/accounting'
import { pathOr } from 'ramda'
import ViewStatementProvider from '../ViewStatement/ViewStatementProvider'
import ViewStatement from '../ViewStatement/ViewStatement'
import { uiEvents } from '../../../ui'

const DepositBankStatement = (props: any): React.ReactElement => {
  const dispatch = useDispatch()

  const fetchDepositBankStatement = useCallback(
    (startDate, endDate, json = true) => {
      const event = json
        ? accountingApiEvents.depositBankStatementJson_request
        : accountingApiEvents.depositBankStatementPdf_request
      dispatch(
        event({
          start_date: startDate,
          end_date: endDate,
        }),
      )
    },
    [dispatch],
  )

  const onClose = useCallback(() => dispatch(uiEvents.redirect('/dashboard')), [dispatch])

  const fetchDepositBankStatementPdf = useCallback(
    (startDate, endDate) => fetchDepositBankStatement(startDate, endDate, false),
    [fetchDepositBankStatement],
  )

  const statementData = useSelector(accountingApiSelectors.getDepositBankStatementJson)
  const statementPdf = useSelector(accountingApiSelectors.getDepositBankStatementPdf)
  const pdfFileName = `${pathOr('', ['context', 'agency', 'name'], statementData) as string} Deposit Bank Statement`

  const handleParamChange = useCallback(
    (startDate, endDate) => {
      fetchDepositBankStatement(startDate, endDate)
      fetchDepositBankStatementPdf(startDate, endDate)
    },
    [fetchDepositBankStatement, fetchDepositBankStatementPdf],
  )

  return useMemo(() => {
    return (
      <ViewStatementProvider
        isOpen
        onClose={onClose}
        onParamChange={handleParamChange}
        statementData={statementData}
        downloadStatement={fetchDepositBankStatementPdf}
        statementPdf={statementPdf}
        pdfFileName={pdfFileName}
      >
        <ViewStatement title="Deposit Bank Statement" />
      </ViewStatementProvider>
    )
  }, [onClose, fetchDepositBankStatementPdf, statementData, statementPdf, pdfFileName, handleParamChange])
}

export default DepositBankStatement

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { get } from 'lodash-es'
import { Button, FormField, TextField, TextInput } from '../../../../components'
import styles from './AgencyInvitationForm.module.scss'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const propTypes = {
  handleSubmit: PropTypes.func,
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required.'),
  email: yup.string().required('Email address is required.').email('Email address is invalid'),
})

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  validationSchema,
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.handleSubmit(values)
  },
})
class AgencyInvitationForm extends PureComponent {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'touched' does not exist on type 'Readonl... Remove this comment to see the full error message
  getFieldError = (name: any) => get(this.props.touched, name, '') && get(this.props.errors, name, '')

  render(): null | React.ReactElement {
    const {
      // values,
      // errors,
      // touched,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleChange' does not exist on type 'Re... Remove this comment to see the full error message
      handleChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleBlur' does not exist on type 'Read... Remove this comment to see the full error message
      handleBlur,
      // setFieldValue,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleSubmit' does not exist on type 'Re... Remove this comment to see the full error message
      handleSubmit,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'isSubmitting' does not exist on type 'Re... Remove this comment to see the full error message
      isSubmitting,
    } = this.props

    return (
      <div className={styles.root}>
        <form onSubmit={handleSubmit}>
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={this.getFieldError('firstName')}
            />
          </FormField>
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.email}
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={this.getFieldError('email')}
            />
          </FormField>

          {/**
            <h3>Permissions</h3>
            <p>This is a pending invitation. Below, you can change the permissions that will be assigned if this invitation is accepted.</p>
            */}

          {/** @todo create radio group/list component for improved semantics and better keyboard support */}
          {/** }
            <List type='checklist'>
              <AggregateListItem
                active={values.role === 'Owner'}
                text='Owner'
                secondaryText='Full permission for all features &amp; approvals'
                onClick={() => setFieldValue('role', 'Owner')}
              />
              <AggregateListItem
                active={values.role === 'Manager'}
                text='Manager'
                secondaryText='Create &amp; modify most features but with no approval permissions.'
                onClick={() => setFieldValue('role', 'Manager')}
              />
              <AggregateListItem
                active={values.role === 'Agent'}
                text='Agent'
                secondaryText='Create &amp; view most features but with no approval permissions.'
                onClick={() => setFieldValue('role', 'Agent')}
              />
            </List>
            */}

          <Button block size="lg" type="submit" disabled={isSubmitting}>
            Send Invitation
          </Button>
        </form>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
AgencyInvitationForm.propTypes = propTypes

export default AgencyInvitationForm

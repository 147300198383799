import React from 'react'
import PropTypes from 'prop-types'
import { SearchInputOverlay } from '../../../../views/components'
import { propTypes as searchPropTypes } from '../../../../views/components/molecules/Search/Search'
import styles from './SegmentForm.module.scss'

const SearchPortfolios = ({ onResultSelect, onSearchChange, resultSets }: any) => {
  return (
    <SearchInputOverlay
      name="search-leases"
      placeholder="Search leases..."
      className={styles.search}
      icon="add"
      useSearchComponent={true}
      searchComponentProps={{
        isLoading: false,
        resultSets,
        onResultSelect,
        onSearchChange,
        minCharacters: 0,
      }}
    >
      <h2>Leases</h2>
    </SearchInputOverlay>
  )
}

SearchPortfolios.propTypes = {
  /**
   * Called when a result is selected.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onResultSelect: PropTypes.func,

  /**
   * Called when search input changes.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onSearchChange: PropTypes.func,

  /**
   * Results:
   * - Group results by arrays i.e. [[{...}, {...}], {...}, {...}]
   * - One group - [[{...}, {...}]]
   */
  resultSets: searchPropTypes.resultSets,
}

export default SearchPortfolios

// Original: https://github.com/chenglou/react-motion/tree/master/demos/demo8-draggable-list
import React, { useRef, useState, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { clamp } from 'lodash-es'
import swap from 'lodash-move'
import { pathOr } from 'ramda'
import { useGesture } from 'react-with-gesture'
import { useSprings, animated, interpolate } from 'react-spring'
import RemoveButton from './RemoveButton'
import styles from './DraggableList.module.scss'
import Button from '../../atoms/Button/Button'
import AddIcon from '../../atoms/Icons/Controls/Plus'
import { $TSFixMe } from 'types/ts-migrate'

type TProps = {
  className?: string
  onMove: (order: any) => void
  onAdd?: () => void
  onRemove?: (index: number) => void
  itemStyle?: Record<string, any>
  items: Array<
    [
      | Node
      | {
          children: Node
          itemProps: {
            disableRemove: boolean | string
            isDraggable: boolean
            style: Record<string, any>
          }
        },
    ]
  >
  listHeight?: number
  addItemText?: string
  showAddButton?: boolean
}

// WHEN dragging, this function will be fed with all arguments.
// OTHERWISE, only the list order is relevant.
const fn =
  (order: any, down: any, originalIndex: number, curIndex: number, y: number) =>
  (
    index: number /*
  No need to transition the following properties:
  - z-index, the elevation of the item related to the root of the view; it should pop straight up to 1, from 0.
  - y, the translated distance from the top; it's already being updated dinamically, smoothly, from react-gesture.
  Thus immediate returns `true` for both.
*/,
  ) =>
    down && index === originalIndex
      ? {
          y: curIndex * 100 + y,
          scale: 1.02,
          zIndex: '1',
          shadow: 15,
          immediate: (n: any) => n === 'y' || n === 'zIndex',
        }
      : { y: order.indexOf(index) * 100, scale: 1, zIndex: '0', shadow: 1, immediate: false }

const DraggableList = ({
  className,
  items,
  onMove,
  onAdd,
  onRemove,
  itemStyle = {},
  listHeight,
  addItemText = 'Add Item',
  showAddButton = true,
}: TProps): React.ReactElement => {
  const order = useRef(items.map((_: any, index: number) => index)) // Store indices as a local ref, this represents the item order
  const _items = useRef(items)
  const [isDragging, setIsDragging] = useState(false)
  const [focussedItem, setFocussedItem] = useState(null)

  /*
    Curries the default order for the initial, "rested" list state.
    Only the order array is relevant when the items aren't being dragged, thus
    the other arguments from fn don't need to be supplied initially.
  */
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 1.
  const [springs, setSprings] = useSprings(items.length, fn(order.current))

  /**
   * The `bind` gesture function is memoized, so using a ref
   * for access to current items
   */
  useEffect(() => {
    _items.current = items
    order.current = order.current.length > 0 ? order.current : items.map((_: any, index: number) => index)
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    setSprings(fn(order.current))
  }, [items, setSprings])

  const bind = useGesture(({ args: [originalIndex], down, delta: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, _items.current.length - 1)
    const newOrder = swap(order.current, curIndex, curRow)
    /*
      Curry all variables needed for the truthy clause of the ternary expression from fn,
      so that new objects are fed to the springs without triggering a re-render.
    */
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    setSprings(fn(newOrder, down, originalIndex, curIndex, y))
    // Settles the new order on the end of the drag gesture (when down is false)
    setIsDragging(down)
    if (!down) {
      order.current = newOrder
      onMove(newOrder)
    }
  })

  const rootClasses = cx([styles.root, className])

  const classes = cx({
    [styles['is-dragging']]: isDragging,
  })

  const handleAdd = useCallback(() => {
    // update order when new item is added
    order.current = [...order.current, order.current.length]
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
    setSprings(fn(order.current))
    if (onAdd) {
      onAdd()
    }
  }, [setSprings, onAdd])

  const handleRemove = useCallback(
    index => {
      // update order when an item is removed
      const removedOrderVal = order.current[index]
      const newOrder = order.current
        .filter((o: any) => o !== removedOrderVal)
        .map((o: any) => (o >= removedOrderVal ? o - 1 : o))
      order.current = newOrder

      // invoke callback
      if (onRemove) {
        onRemove(index)
      }
    },
    [onRemove],
  )

  const getItemComponent = (index: number): $TSFixMe => pathOr(items[index], [index, 'children'], items)

  return (
    <div className={rootClasses}>
      <div
        className={styles.list}
        style={{ height: listHeight ? `${listHeight as number}px` : `${items.length * 100}px` }}
      >
        {/** @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'ForwardedPr... Remove this comment to see the full error message */}
        {springs.map(({ zIndex, shadow, y, scale }: any, i: any) => {
          const disableRemove = pathOr(false, [i, 'itemProps', 'disableRemove'], items)
          const isDraggable = pathOr(false, [i, 'itemProps', 'isDraggable'], items)

          return (
            <animated.div
              className={classes}
              key={i}
              style={{
                zIndex: focussedItem === i ? 100 : zIndex,
                boxShadow: shadow.interpolate((s: any) => `rgba(0, 0, 0, 0.02) 0px ${s as string}px ${2 * s}px 0px`),
                transform: interpolate([y, scale], (y, s) => `translate3d(0,${y}px,0) scale(${s})`),
                maxHeight: y,
                ...itemStyle,
                ..._items[i]?.itemProps?.style,
              }}
              onFocus={() => setFocussedItem(i)}
            >
              <div className={styles.controls}>
                {items.length > 1 && isDraggable && (
                  <button type="button" {...bind(i)} className={styles['drag-handle']} />
                )}
                {onRemove && (
                  <RemoveButton
                    className={cx([styles.remove], {
                      [styles['remove--disabled']]: disableRemove,
                    })}
                    onRemove={() => handleRemove(i)}
                    disabled={disableRemove}
                  />
                )}
              </div>
              {getItemComponent(i)}
            </animated.div>
          )
        })}
      </div>
      {onAdd && showAddButton && (
        <Button size="sm" className={styles['add-item']} onClick={handleAdd}>
          {addItemText}
          <AddIcon width={22} height={22} viewBox="0 0 30 30" fill="#fff" />
        </Button>
      )}
    </div>
  )
}

export default DraggableList

import React, { Component, ReactEventHandler } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, sumBy } from 'lodash-es'
import { subscribe } from 'react-contextual'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { map, pathOr, pipe, sum } from 'ramda'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { format } from 'date-fns'
import { arrayUtils, currencyUtils } from '../../../../../utils'
import { InvoicesProvider } from '../../../../providers'
import {
  Button,
  ControlledTooltip,
  DatePicker,
  FormLoader,
  InfoBox,
  InvoiceCard,
  Loader,
  Segment,
} from '../../../../components'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import TaskList from '../../../../components/atoms/Svgs/TaskList'
import CalendarOff from '../../../../components/atoms/Svgs/CalendarOff'
import PaymentAllocation from './PaymentAllocation/PaymentAllocation'
import styles from './ViewInvoice.module.scss'
import { hashCode } from 'utils/string'
import { invoiceApiEvents } from '../../../../../modules/api/invoice'
import FundDistributionFooter from '../../../organisms/FormFragments/FundDistribution/FundDistributionFooter/FundDistributionFooter'
import FileTable from '../../../../../modules/documents/FileTable'
import { $TSFixMe } from 'types/ts-migrate'
import { Prompt } from 'react-router-dom'
import {
  transformDepositBeneficiaryForManagedByChange,
  transformDepositBeneficiaryForTransferChange,
} from 'views/components/organisms/FormFragments/FundDistribution/DepositBeneficiary/depositBeneficiaryUtils'
import { existingInvoiceTypes } from 'utils/invoiceTypes'

const propTypes = {
  invoice: PropTypes.object,
  sendInvoice: PropTypes.func,
  updateInvoice: PropTypes.func,
  dueDate: PropTypes.string,
}

const validationSchema = yup.object().shape({
  beneficiaries: yup
    .array(
      yup.object().shape({
        beneficiary: yup.object().shape({
          type: yup.string(),
          value: yup.object().shape({
            amount: yup.number(),
            /** @todo don't think this is needed */
            balance: yup.number(),
            // beneficiaryCategory: yup.string(),
            id: yup.string(),
            partyId: yup.string(),
            partyTag: yup.string(),
            reference: yup.string(),
            vat: yup.boolean(),
          }),
        }),
      }),
    )
    .test('beneficiaries', function (beneficiaries) {
      const beneficiariesTotal = sumBy(beneficiaries, (b: any) => b.beneficiary.value.amount)
      const activeElementPlaceholder = document?.activeElement?.getAttribute('placeholder')
      if (beneficiariesTotal > this.parent.amount && activeElementPlaceholder !== 'Enter amount') {
        return this.createError({
          path: this.path,
          message:
            this.parent.tags.lightStatus === 'Amber'
              ? `Beneficiary payments can't exceed invoice total of ${
                  currencyUtils.formatCurrency(this.parent.amount) as string
                }`
              : `Beneficiary payments can't exceed invoice total of ${
                  currencyUtils.formatCurrency(this.parent.balance) as string
                }`,
        })
      }
      return true
    }),
})

@subscribe(
  [InvoicesProvider],
  ({
    params,
    redirect,
    currentInvoice,
    isDraftRemoved,
    updateInvoice,
    sendInvoices,
    deleteInvoices,
    restoreInvoices,
    editingInvoice,
    fetchInvoiceTypes,
    invoiceTypes,
    closeInvoice,
    getPartyNameById,
    getInvoiceTypeByValue,
    draftInvoicesInSetMode,
    removeFromDraftSetMode,
    openInvoice,
    partyTags,
    agencyBankDetails,
    getPartyBankDetails,
    fetchPortfolio,
    saveAndSendInvoice,
    isInvoiceViewLoading,
    isDraftInvoiceViewLoading,
    isSaveAndSendSubmitting,
    isSaveSubmitting,
    getGeneralFormErrorsByEvent,
    getFormFieldErrorsByEvent,
    isReadOnly,
    currentAgencyId,
    getOwnerAccountFromDraftInvoiceId,
    getPartyAccount,
    getCurrentAgencyGlobalVatEnabled,
  }: $TSFixMe) => ({
    params,
    redirect,
    currentInvoice,
    isDraftRemoved,
    updateInvoice,
    sendInvoices,
    deleteInvoices,
    restoreInvoices,
    editingInvoice,
    fetchInvoiceTypes,
    invoiceTypes,
    closeInvoice,
    getPartyNameById,
    getInvoiceTypeByValue,
    draftInvoicesInSetMode,
    removeFromDraftSetMode,
    openInvoice,
    partyTags,
    agencyBankDetails,
    getPartyBankDetails,
    fetchPortfolio,
    saveAndSendInvoice,
    isInvoiceViewLoading,
    isDraftInvoiceViewLoading,
    isSaveAndSendSubmitting,
    isSaveSubmitting,
    getGeneralFormErrorsByEvent,
    getFormFieldErrorsByEvent,
    isReadOnly,
    currentAgencyId,
    getOwnerAccountFromDraftInvoiceId,
    getPartyAccount,
    getCurrentAgencyGlobalVatEnabled,
  }),
)
// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withFormik({
  validationSchema,
  validateOnBlur: true,
  validateOnChange: true,
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => props.currentInvoice,
  handleSubmit: (values, { props }) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    props.saveAndSendInvoice(values.id, values)
  },
})
class ViewInvoice extends Component<$TSFixMe, $TSFixMe> {
  state = {
    beneficiaries: [],
    beneficiariesHash: null,
  }

  componentDidMount(): void {
    this.props.fetchInvoiceTypes()
    this.setState({
      beneficiariesHash: hashCode(JSON.stringify(get(this.props, 'values.beneficiaries', ''))),
    })
  }

  componentDidUpdate(prevProps: any): void {
    if (prevProps.dueDate !== this.props.dueDate) {
      this.props.setFieldValue('dueDate', this.props.dueDate)
    }

    const { hasApiErrors } = this.getApiErrors()
    const id = get(this.props, 'values.id')
    const wasNotSet =
      get(prevProps, 'currentInvoice.tags.lightStatus', '') === 'Amber' &&
      get(this.props, 'currentInvoice.tags.lightStatus', '') === 'Green'
    if (wasNotSet && id && prevProps.isSaveAndSendSubmitting && !this.props.isSaveAndSendSubmitting && !hasApiErrors) {
      this.props.closeInvoice(id, 'drafts')
    }

    const { params, openInvoice } = this.props

    // open invoice. Required for the case when user navigates to the invoice
    if (params?.id && prevProps.params?.id !== params.id) {
      openInvoice(params.id, 'drafts', false)
    }
    // close invoice. Required for the case when user navigates away from the invoice
    if (prevProps.params?.id && !params?.id) {
      this.props.closeInvoice(prevProps.params.id, 'drafts', false)
    }
  }

  handleClose = (): void => {
    this.props.redirect(`/invoices/drafts`)
  }

  handleContextMenuItemSelect = (item: any): void => {
    const { currentInvoice, deleteInvoices, closeInvoice, values, restoreInvoices } = this.props
    if (item === 'delete') {
      deleteInvoices([currentInvoice.id])
      closeInvoice(values.id, 'drafts')
    } else if (item === 'restore') {
      restoreInvoices([currentInvoice.id])
      closeInvoice(values.id, 'drafts')
    }
  }

  handleSave: ReactEventHandler = e => {
    e.preventDefault()
    const { values, updateInvoice } = this.props
    const { id = false, ...payload } = values
    if (id) {
      updateInvoice(id, payload)
    }
  }

  getSelectedInvoiceType = (): $TSFixMe => {
    const { invoiceTypes, values } = this.props
    const invoiceType = invoiceTypes.find((t: any) => values.invoiceType === t.value)
    return invoiceType ? invoiceType.value : ''
  }

  getInvoiceStatus = (): string => get(this.props, 'values.tags.subStatus', '').toLowerCase()

  handleNewBeficiary = (beneficiary: any, index: number): void => {
    const { setFieldValue, values, getCurrentAgencyGlobalVatEnabled } = this.props
    const vatApplied =
      existingInvoiceTypes.includes(values.invoiceType) &&
      beneficiary.beneficiary.value.partyTag === 'Agency' &&
      getCurrentAgencyGlobalVatEnabled
        ? true
        : false
    setFieldValue(
      'beneficiaries',
      arrayUtils.insert(
        index,
        {
          ...beneficiary,
          beneficiary: {
            ...beneficiary.beneficiary,
            value: {
              ...beneficiary.beneficiary.value,
              vat: vatApplied,
            },
          },
        },
        values.beneficiaries,
      ),
    )
  }

  handleBeneficiaryRemoved = (index: number): void => {
    const { setFieldValue, values } = this.props
    setFieldValue('beneficiaries', arrayUtils.removeByIndex(index, values.beneficiaries))
  }

  handleBeneficiaryAmountChange = (beneficiaryIndex: number, e: any): void => {
    const { setFieldValue, values } = this.props
    const beneficiary = values.beneficiaries.find((v: any, i: any) => i === beneficiaryIndex)
    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
      ...beneficiary,
      beneficiary: {
        ...beneficiary.beneficiary,
        value: {
          ...beneficiary.beneficiary.value,
          amount: e.target.value ? e.target.value : 0,
        },
      },
    })
  }

  handleDepositBeneficiaryTransferChange = (index: number, transfer: any): void => {
    const { values, setFieldValue, getOwnerAccountFromDraftInvoiceId, currentAgencyId, getInvoiceTypeByValue } =
      this.props
    const beneficiary = values.beneficiaries.find((v: any, i: any) => i === index)
    const portfolioOwnerAccount = getOwnerAccountFromDraftInvoiceId(values.id)
    const invoiceTypeObj = getInvoiceTypeByValue(values.invoiceType || '')
    const invoiceTypeName = pathOr('', ['name'], invoiceTypeObj)

    const transformedBeneficiary = transformDepositBeneficiaryForTransferChange(
      beneficiary,
      transfer,
      currentAgencyId,
      values?.customer.name,
      invoiceTypeName,
      portfolioOwnerAccount.partyId,
    )
    setFieldValue(`beneficiaries[${index}]`, transformedBeneficiary)
  }

  handleBeneficiaryOrderChange = (newOrder: any): void => {
    const { values, setFieldValue } = this.props
    const updatedBeneficiaries = newOrder.map((o: any) => values.beneficiaries[o])
    setFieldValue('beneficiaries', updatedBeneficiaries)
    this.setState({
      beneficiariesHash: hashCode(JSON.stringify(updatedBeneficiaries)),
    })
    // updateInvoice()
  }

  getBeneficiariesAllocationsSum = (): number =>
    pipe(pathOr([], ['beneficiaries']), map(pathOr(0, ['beneficiary', 'value', 'amount'])), sum)(this.props.values)

  getUnallocatedAmount = (): number => {
    const { values } = this.props
    const currentInvoiceAmount = pathOr(null, ['currentInvoice', 'amount'], this.props)
    if (values.tags.lightStatus === 'Amber' || values.tags.lightStatus === 'Green') {
      return values.amount ? values.amount - this.getBeneficiariesAllocationsSum() : 0
    } else {
      return currentInvoiceAmount ? currentInvoiceAmount - this.getBeneficiariesAllocationsSum() || 0 : 0
    }
  }

  isAmountValid = () => {
    if (this.props.values) {
      const { amount } = this.props.values
      return parseFloat(amount) > 0
    }
    return false
  }

  handleIntervalChange = (data: any): void => {
    const { setFieldValue } = this.props
    setFieldValue('interval', data.value)
  }

  handleDescriptionChange = ({ target }: any): void => {
    const { setFieldValue } = this.props
    setFieldValue('description', target.value)
  }

  ButtonContainer = ({ children }: $TSFixMe): React.ReactElement => {
    const { dirty } = this.props
    return (
      <footer>
        <Button type="button" ghost={!dirty} disabled={!dirty} onClick={this.handleSave} size="sm">
          Save
        </Button>
        {this.props.isDraftRemoved ? null : !this.isAmountValid() ? (
          // @ts-expect-error
          <ControlledTooltip body="No amount set">
            <Button disabled ghost type="button" size="sm" as="div">
              <span>Send now</span>
            </Button>
          </ControlledTooltip>
        ) : (
          children
        )}
      </footer>
    )
  }

  DocumentUploads = (): React.ReactElement => {
    const { currentInvoice, isReadOnly } = this.props
    return (
      currentInvoice?.id && (
        <Segment.Group>
          <FileTable
            className={styles.documents}
            showDateTime={true}
            showDateTimeBeneath={true}
            allowRenaming={!isReadOnly}
            allowRemove={!isReadOnly}
            displayAndEditExtension={false}
            onError={() => null}
            // @ts-expect-error
            forOwner={{ className: 'invoice', classId: currentInvoice.id }}
          />
        </Segment.Group>
      )
    )
  }

  getApiErrors = (): $TSFixMe => {
    const { getGeneralFormErrorsByEvent, getFormFieldErrorsByEvent, values } = this.props
    const submitEvent = invoiceApiEvents.sendInvoices_request([values?.id])
    const generalErrors = getGeneralFormErrorsByEvent(submitEvent)
    const fieldErrors = getFormFieldErrorsByEvent(submitEvent)
    const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)
    return { generalErrors, fieldErrors, hasApiErrors }
  }

  areAllFundsAllocated = (): boolean => this.getUnallocatedAmount() === 0

  buildFundDistributionFooter = (): React.ReactElement => {
    return (
      <FundDistributionFooter
        text={'Amount unallocated'}
        amount={this.getUnallocatedAmount()}
        isComplete={this.areAllFundsAllocated()}
        progress={{
          value: 100 - (this.getUnallocatedAmount() / this.props.values.amount) * 100,
          style: 'default',
        }}
      />
    )
  }

  getBeneficiaries = (): $TSFixMe => get(this.props, 'values.beneficiaries', [])

  handleBeneficiaryReferenceChange = (beneficiaryIndex: number, reference: any, type: any): void => {
    const { setFieldValue } = this.props

    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === beneficiaryIndex)

    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
      ...beneficiary,
      beneficiary: {
        ...beneficiary.beneficiary,
        value: {
          ...beneficiary.beneficiary.value,
          ...(type === 'EasyPayBeneficiary' ? { easyPayReference: reference } : { reference }),
        },
      },
    })
  }

  render(): null | React.ReactElement {
    const {
      editingInvoice,
      currentInvoice: invoice,
      isDraftRemoved,
      isDraftInvoiceViewLoading,
      isSaveAndSendSubmitting,
      isSaveSubmitting,
      isReadOnly,
      handleSubmit,
      values,
      setFieldValue,
      getPartyNameById,
      partyTags,
      errors,
      dirty,
    } = this.props

    const classes = classNames(styles.root, {
      editing: editingInvoice,
    })

    const customerName = pathOr('', ['customer', 'name'], values)

    const { hasApiErrors, generalErrors } = this.getApiErrors()

    const formLoaderState = isDraftInvoiceViewLoading
      ? 'loading'
      : isSaveAndSendSubmitting || isSaveSubmitting
      ? 'submitting'
      : !isEmpty(errors) || hasApiErrors
      ? 'error'
      : 'clear'

    const handleAmountChange = (e: $TSFixMe) => {
      const value = e.target.value || 0
      this.props.setFieldValue('amount', value)
      if (
        values.beneficiaries.length === 1 &&
        (values.tags.lightStatus === 'Amber' || values.tags.lightStatus === 'Green')
      ) {
        this.props.setFieldValue('beneficiaries[0].beneficiary.value.amount', value, false)
      }
    }

    return (
      <div className={classes}>
        <Prompt
          when={dirty}
          message={(location: any, action: any) => {
            return `There are unsaved changes, are you sure you want to leave?`
          }}
        />
        {!isEmpty(values) && (
          <Helmet>
            <title>
              reOS | Invoices | Drafts | {`${customerName as string} - ${values.invoiceTypeName as string}`}
            </title>
          </Helmet>
        )}
        <InvoiceCard
          invoice={invoice}
          contextMenuOptions={
            isDraftRemoved
              ? [
                  {
                    label: 'Restore deleted invoice',
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; onSelect: () => void; }[]' ... Remove this comment to see the full error message
                    onSelect: () => this.handleContextMenuItemSelect('restore'),
                    isHidden: isReadOnly,
                  },
                ]
              : [
                  {
                    label: 'Delete invoice',
                    onSelect: () => this.handleContextMenuItemSelect('delete'),
                    isHidden: isReadOnly,
                  },
                ]
          }
          onClose={this.handleClose}
        >
          {isDraftInvoiceViewLoading ? (
            <Loader />
          ) : (
            <FormLoader
              onSubmit={handleSubmit}
              state={formLoaderState}
              buttonProps={{ children: isDraftRemoved ? '' : 'Send now' }}
              ButtonContainer={this.ButtonContainer}
              alwaysEnabledChildren={this.DocumentUploads}
              isDisabled={isReadOnly}
            >
              {isDraftRemoved && (
                <InfoBox className={styles['deleted-info']} type="info">
                  This invoice was deleted.{' '}
                  <Button size="sm" onClick={() => this.handleContextMenuItemSelect('restore')}>
                    Restore deleted invoice
                  </Button>
                </InfoBox>
              )}
              <section className={styles['invoice-data']}>
                {!isEmpty(values) && (
                  <>
                    <Segment.Group>
                      <Segment>
                        <Segment.Label icon={<TaskList />} text={customerName} secondaryText={values.property} />
                      </Segment>
                    </Segment.Group>
                    <FormErrors errors={generalErrors} />
                    <PaymentAllocation
                      errors={errors}
                      invoiceType={{ name: values.invoiceTypeName, value: values.invoiceType }}
                      amount={values.amount ? values.amount.toString() : ''}
                      amountVatApplied={values.vat}
                      onAmountChange={handleAmountChange}
                      onVatChange={(vat: any) => setFieldValue('vat', vat)}
                      onBeneficiaryVatChange={(index: number, vat: any) =>
                        setFieldValue(`beneficiaries[${index}].beneficiary.value.vat`, vat)
                      }
                      onDepositBeneficiaryTransferChange={this.handleDepositBeneficiaryTransferChange}
                      beneficiaries={values.beneficiaries.map((b: any) => {
                        const partyId = pathOr('', ['beneficiary', 'value', 'partyId'], b)
                        return {
                          ...b,
                          beneficiary: {
                            ...b.beneficiary,
                            value: {
                              ...b.beneficiary.value,
                              name: getPartyNameById(partyId),
                            },
                          },
                        }
                      })}
                      invoiceStatus={this.getInvoiceStatus()}
                      onNewBeneficiaryAdded={this.handleNewBeficiary}
                      onBeneficiaryRemoved={this.handleBeneficiaryRemoved}
                      onNewBeneficiaryAmountChange={this.handleBeneficiaryAmountChange}
                      onBeneficiariesOrderChange={this.handleBeneficiaryOrderChange}
                      onBeneficiaryReferenceChange={this.handleBeneficiaryReferenceChange}
                      partyTags={partyTags}
                      beneficiariesKey={this.state.beneficiariesHash}
                      description={values.description}
                      onDescriptionChange={this.handleDescriptionChange}
                      unallocatedAmount={this.getUnallocatedAmount()}
                      Footer={this.buildFundDistributionFooter}
                      portfolioId={values.portfolioId}
                    />

                    <Segment.Group>
                      <Segment horizontal>
                        <Segment.Label icon={<CalendarOff />} text="Payment Due" />
                        <div className={styles['due-date']}>
                          <DatePicker
                            date={values.dueDate ? new Date(values.dueDate) : new Date()}
                            onChange={(date: any) => setFieldValue('dueDate', format(date, 'yyyy-MM-dd'))}
                          />
                        </div>
                      </Segment>
                    </Segment.Group>
                  </>
                )}
              </section>
            </FormLoader>
          )}
        </InvoiceCard>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ViewInvoice.propTypes = propTypes

export default ViewInvoice

import { ofType } from 'redux-observable'
import { map, pluck, mergeMap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { get } from 'lodash-es'
import { events } from './state'
import { ENDPOINTS } from './constants'
import { portfolioApiTransformers } from '../portfolio'
import { multiUserOperators } from '../../multiUser/state'

/**
 * API Requests
 */

export const apiCreateProperty = (action$: any, state$: any, { post, catchRestError }: any) =>
  action$.pipe(
    ofType(events.createProperty_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      post(ENDPOINTS.BASE, state$, action.payload).pipe(
        pluck('response'),
        map(events.createProperty_success),
        catchRestError(action),
      ),
    ),
    tag('property/epics/apiCreateProperty'),
  )

export const apiUpdateProperty = (action$: any, state$: any, { put, catchRestError }: any) =>
  action$.pipe(
    ofType(events.updateProperty_request),
    mergeMap(action => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const { id, values } = action.payload
      return put(ENDPOINTS.UPDATE, state$, values, { id }).pipe(
        pluck('response'),
        map(events.updateProperty_success),
        catchRestError(action),
      )
    }),
    tag('property/epics/apiUpdateProperty'),
  )

export const apiFetchProperties = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.properties_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap(action =>
      get(ENDPOINTS.BASE, state$, {}).pipe(pluck('response'), map(events.properties_success), catchRestError(action)),
    ),
    tag('property/epics/apiFetchProperties'),
  )

export const apiSearchProperties = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.search_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.SEARCH, state$, action.payload).pipe(
        pluck('response'),
        map(response =>
          events.search_success({
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            query: action.payload.query,
            response,
          }),
        ),
        catchRestError(action),
      ),
    ),
    tag('property/epics/apiSearchProperties'),
  )

export const apiFetchPlace = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.place_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.PLACE, state$, action.payload).pipe(
        pluck('response'),
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        map(response => events.place_success({ response, placeId: action.payload.placeId })),
        catchRestError(action),
      ),
    ),
    tag('property/epics/apiFetchPlace'),
  )

export const apiFetchProperty = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.property_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.ENTITY, state$, action.payload).pipe(
        pluck('response'),
        map(events.property_success),
        catchRestError(action),
      ),
    ),
    tag('property/epics/apiFetchProperty'),
  )

/**
 * @todo prefetch portfolios?
 */
export const apiFetchPropertyPortfolios = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.propertyPortfolios_request),
    mergeMap(action =>
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      get(ENDPOINTS.PORTFOLIOS, state$, action.payload).pipe(
        pluck('response'),
        map(response =>
          events.propertyPortfolios_success({
            response: portfolioApiTransformers.transformGetPortfolioResponse(response),
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            propertyId: action.payload.id,
          }),
        ),
        catchRestError(action),
      ),
    ),
    tag('property/epics/apiFetchPropertyPortfolios'),
  )

/**
 * Other Side Effects
 */

export const prefetchGooglePlacesOnResults = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.search_success),
    mergeMap(({ payload }) => get(payload, 'response.googleProperties', [])),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ placeId }: { placeId: any; })... Remove this comment to see the full error message
    map(({ placeId }) => events.place_request({ placeId })),
    tag('property/epics/prefetchGooglePlacesOnResults'),
  )

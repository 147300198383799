import React, { useEffect } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { reconApiEvents, reconApiSelectors } from '../../../../../../modules/api/recon'

const propTypes = {
  customerId: PropTypes.string,
  accountId: PropTypes.string,
}

const RecentActivityProvider = moduleContext()(({ context, children, ...props }: any) => {
  const { customerId, accountId } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(reconApiEvents.customerAccountActivity_request({ customerId, accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, accountId])

  const store = {
    accountId,
    recentActivity: useSelector(state => reconApiSelectors.getCustomerRecentActivity(state)(customerId, accountId)),
  }

  return <context.Provider value={store}>{children}</context.Provider>
})

RecentActivityProvider.propTypes = propTypes

export default RecentActivityProvider

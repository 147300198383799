import React from 'react'
import { subscribe } from 'react-contextual'
import { PaymentsProvider } from '../../../providers'
import { SearchFilter } from '../..'

const PaymentsSearchFilter = (props: any) => <SearchFilter {...props} />

export default subscribe([PaymentsProvider], ({ location, redirect, paymentFilters }: any) => ({
  pathname: location.pathname,
  redirect,
  filters: paymentFilters,
}))(PaymentsSearchFilter)

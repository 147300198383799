import React from 'react'

const TaskList = (props: any) => (
  <svg width={32} height={32} {...props}>
    <g fill="#000" fillRule="nonzero" opacity={0.56}>
      <path d="M26 7.677c0-.92-.746-1.666-1.667-1.666H7.667C6.747 6.01 6 6.757 6 7.677v16.645c0 .921.746 1.667 1.667 1.667h12.52a5.85 5.85 0 0 0 4.11-1.702A5.775 5.775 0 0 0 26 20.177v-12.5zm-18.333.417c0-.23.186-.417.416-.417h15.834c.23 0 .416.187.416.417V19.74a.208.208 0 0 1-.208.208h-2.708a1.46 1.46 0 0 0-1.459 1.459v2.708a.208.208 0 0 1-.208.208H8.083a.417.417 0 0 1-.416-.416V8.094zm13.815 16.02a.208.208 0 0 1-.274-.198v-2.51a.21.21 0 0 1 .209-.209h2.513a.21.21 0 0 1 .167.084c.039.054.05.123.029.186a4.145 4.145 0 0 1-2.647 2.643l.003.003z" />
      <path d="M17.875 18.927h-5.417a.208.208 0 0 1-.208-.208v-1.875c0-.115.093-.208.208-.208h10a.625.625 0 1 0 0-1.25h-10a.208.208 0 0 1-.208-.209v-1.875c0-.115.093-.208.208-.208h10a.625.625 0 1 0 0-1.25h-10a.208.208 0 0 1-.208-.208V9.552a.625.625 0 1 0-1.25 0v2.084a.208.208 0 0 1-.208.208h-1.25a.625.625 0 1 0 0 1.25h1.25c.115 0 .208.093.208.208v1.875a.208.208 0 0 1-.208.209h-1.25a.625.625 0 1 0 0 1.25h1.25c.115 0 .208.093.208.208v1.875a.208.208 0 0 1-.208.208h-1.25a.625.625 0 1 0 0 1.25h1.25c.115 0 .208.094.208.209v2.083a.625.625 0 0 0 1.25 0v-2.083c0-.115.093-.209.208-.209h5.417a.625.625 0 1 0 0-1.25z" />
    </g>
  </svg>
)

export default TaskList

import { ofType } from 'redux-observable'
import { map, pluck, mergeMap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { ENDPOINTS } from './constants'
import { events } from './state'
import { AnyAction } from 'redux'

export const apiFetchPartyErrorsById = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.partyErrors_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_PARTY_ERRORS, state$, action.payload).pipe(
        pluck('response'),
        map(response => events.partyErrors_success({ response, params: action.payload })),
        catchRestError(action),
      ),
    ),
    tag('banking/epics/apiFetchPartyErrorsById'),
  )

export const apiFetchPartiesErrorsByIds = (action$: any, state$: any, { get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.partiesErrors_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_PARTIES_ERRORS, state$, action.payload).pipe(
        pluck('response'),
        map(response => events.partiesErrors_success({ response, params: action.payload })),
        catchRestError(action),
      ),
    ),
    tag('banking/epics/apiFetchPartiesErrorsByIds'),
  )

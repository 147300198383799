import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { invoiceApiEvents } from 'modules/api/invoice'
import { DimmerLoader, Modal } from 'views/components'
import styles from './SendApplicationInvoiceModal.module.scss'
import { currencyUtils } from 'utils/index'
import { format } from 'date-fns'
import { TApplication } from 'modules/api/portfolio/types'
import { partyApiSelectors } from 'modules/api/party'
import { portfolioApiEvents } from 'modules/api/portfolio'

type Props = {
  portfolioId: string
  application?: TApplication
  isOpen: boolean
  onCancel: () => void
}

const SendApplicationInvoiceModal = ({ portfolioId, application, isOpen, onCancel }: Props) => {
  const dispatch = useDispatch()
  const partyName = useSelector(state => partyApiSelectors.getPartyNameById(state)(application?.partyId))

  const isLoading = false

  const handleSend = () => {
    if (application) {
      const { applicationId } = application
      // dispatch(invoiceApiEvents.sendInvoices_request([application.applicationId]))
      dispatch(portfolioApiEvents.sendApplicationInvoice_request({ portfolioId, applicationId }))
      onCancel() /** @todo add prop or change name to `onClose`? */
    }
  }

  return (
    <Modal
      // @ts-expect-error
      className={styles.root}
      isOpen={isOpen}
      padding="sm"
      width="sm"
      theme="dark"
      showClose={false}
    >
      {/* @ts-expect-error */}
      <Modal.Header title="Are you sure?" />
      <Modal.Body
        actions={[
          { label: 'Yes, send invoice', onClick: handleSend },
          { label: 'Cancel', onClick: onCancel },
        ]}
      >
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>
              You are about to send an invoice with the amount of{' '}
              <strong>{currencyUtils.formatCurrency(application?.applicationFee || 0)}</strong> to{' '}
              <strong>{partyName}</strong>
            </div>
          </>
        </DimmerLoader>
      </Modal.Body>
    </Modal>
  )
}

export default SendApplicationInvoiceModal
